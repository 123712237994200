import React from "react";

class TaskDescription extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let isIndented = false;
    let imageCount = 0;
    let newDescription = null;
    let rawDescription = this.props.description;
    while (rawDescription.indexOf("///") >= 0) {
      let newElement;
      let elementLabel = rawDescription.slice(rawDescription.indexOf("///") + 3,
      rawDescription.slice(rawDescription.indexOf("///") + 3).indexOf("///")
      + rawDescription.indexOf("///") + 3);
      switch (elementLabel) {
        case "NEWLINE":
          newElement = null;
          break;
        case "IMAGE":
          let image = this.props.images[imageCount++];
          newElement = <img style={{width: 600, border: '1px solid black'}} src={image}/>;
          break;
        case "TAB":
          newElement = null;
          isIndented = true;
          break;
      }
      if (isIndented && rawDescription.slice(0, rawDescription.indexOf("///" + elementLabel + "///")).length > 0) {
        newDescription = <>{newDescription}<p style={{ marginLeft: '15px' }}>{rawDescription.slice(0, rawDescription.indexOf("///" + elementLabel + "///"))}</p>{newElement}</>;
        isIndented = false;
      } else {
        newDescription = <>{newDescription}<p>{rawDescription.slice(0, rawDescription.indexOf("///" + elementLabel + "///"))}</p>{newElement}</>;
      }
      rawDescription = rawDescription.indexOf("///" + elementLabel + "///") + elementLabel.length + 6 < rawDescription.length ? rawDescription.slice(rawDescription.indexOf("///" + elementLabel + "///") + elementLabel.length + 6) : "";
    }
    if (newDescription === null || rawDescription.length > 0)
      newDescription = <>{newDescription}<p>{rawDescription}</p></>;
    return newDescription;
  }
}

export default TaskDescription;
