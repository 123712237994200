import React from 'react';
import { Form } from 'react-bootstrap';
import {
	InputNumber,
	Checkbox,
	Button,
	message,
	Alert,
	DatePicker
} from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import moment from 'moment';

import LoadScreen from '../../Components/LoadScreen';
import { vendorProvinces } from '../../StringData/VendorObjects';
import RequestContext from '../MaintenanceRequest/RequestContext';
const cancelToken = axios.CancelToken.source();

class EditPropertyDetails extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			propertyId: this.props.propertyId,
			propertyNumber: this.props.propertyNumber,
			propertyStreet: this.props.propertyStreet,
			propertyCity: this.props.propertyCity,
			propertyProvince: this.props.propertyProvince,
			propertyPostalcode: this.props.propertyPostalcode,
			propertyType: this.props.propertyType,
			totalUnits: this.props.totalUnits,
			areTenants: false,
			submitted: false,
			loaded: false,
			provinceList: []
		};
	}
	componentDidMount = () => {
		axios.post('/getProvinces').then(res => {
			console.log(res.data.provinces);
			this.setState({ provinceList: res.data.provinces });
		});
	};

	verify = () => {
		if (this.state.propertyNumber === '') {
			this.setState({ errorMessage: 'Please enter a street address.' });
			return false;
		} else if (
			this.state.propertyStreet === '' ||
			isNaN(parseInt(this.state.streetNum))
		) {
			this.setState({ errorMessage: 'Please enter an street number.' });
			return false;
		} else if (this.state.propertyCity === '') {
			this.setState({ errorMessage: 'Please enter a city.' });
			return false;
		} else if (this.state.propertyProvince === '') {
			this.setState({ errorMessage: 'Please enter a province.' });
			return false;
		} else if (this.state.propertyPostalcode === '') {
			this.setState({ errorMessage: 'Please enter a postal code.' });
			return false;
		} else if (this.state.propertyType === '') {
			this.setState({ errorMessage: 'Please enter a province.' });
			return false;
		} else if (this.state.totalUnits < 1 || this.state.totalUnits > 100) {
			this.setState({ errorMessage: 'Please enter valid units.' });
			return false;
		}

		return true;
	};

	closeError = () => this.setState({ errorMessage: null });

	handleChange = e => {
		console.log(e.target.value);
		this.setState({ [e.target.name]: e.target.value });
	};

	submitForm = () => {
		axios
			.post('/updateProperty', {
				propertyId: this.state.propertyId,
				propertyNumber: this.state.propertyNumber,
				propertyStreet: this.state.propertyStreet,
				propertyCity: this.state.propertyCity,
				propertyProvince: this.state.propertyProvince,
				propertyPostalcode: this.state.propertyPostalcode,
				propertyType: this.state.propertyType,
				totalUnits: this.state.totalUnits,
				propertyName:
					this.state.propertyNumber + ' ' + this.state.propertyStreet
			})
			.then(res => {
				this.setState({ submitted: true });
				window.location.reload();
			});
	};

	render() {
		if (this.state.submitted) {
			return <h3>Property Updated! </h3>;
		}
		return (
			<div>
				<Form>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Property Number </Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='propertyNumber'
								type='text'
								value={this.state.propertyNumber}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Property Street </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='propertyStreet'
								type='text'
								value={this.state.propertyStreet}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Property City </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='propertyCity'
								id='lastName'
								type='text'
								value={this.state.propertyCity}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>
								Property Postal Code
							</Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='propertyPostalcode'
								type='text'
								value={this.state.propertyPostalcode}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label className='form-label'>Property Province</Form.Label>
							<select
								name='propertyProvince'
								className='form-control'
								value={this.state.propertyProvince}
								onChange={e =>
									this.setState({ propertyProvince: e.target.value })
								}
							>
								<option value={''}>Select Province</option>
								{this.state.provinceList.map(province => (
									<option
										value={province.province_shortform}
										key={province.province_id}
									>
										{province.province_name}
									</option>
								))}
							</select>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label className='form-label'>Property Type</Form.Label>
							<select
								name='propertyType'
								className='form-control'
								defaultValue={this.state.propertyType}
								onChange={e => this.setState({ propertyType: e.target.value })}
							>
								<option value='' key=''>
									Select..
								</option>
								<option value='House with apartment' key='withApartment'>
									House with apartment
								</option>
								<option value='Duplex' key='duplex'>
									Duplex
								</option>
								<option value='Multi-unit Residence' key='multiUnit'>
									Multi-unit residence
								</option>
								<option value='Single family' key='single'>
									Single family
								</option>
							</select>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label className='form-label'>Total Units </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='totalUnits'
								value={this.state.totalUnits}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<div className='text-right'>
						<Button
							onClick={this.props.onCancel}
							type='primary'
							className='mr-2'
						>
							Cancel
						</Button>
						<Button onClick={this.submitForm} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default EditPropertyDetails;
