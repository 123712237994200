import React, { Component, useState,useEffect } from "react";
import { Link } from "react-router-dom";
import LoginLayout from "./LoginLayout";
import { Button, Result } from "antd";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import axios from "axios";

import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
const cancelToken = axios.CancelToken.source();

/*  TODO
-Add more password requirements: length,capital,number
-Add 2fa
*/

class VerifyAccount extends Component {

  state = {text : null}
    

    componentDidMount(){
        let id = window.location.href.split("/")[4];
        let token = window.location.href.split("/")[5]
        axios.get("/auth/verifyAccount/"+id+'/'+token)
      .then((response) => {
        if(response.data.ok){
            this.setState({text: true})
        }
        else{ this.setState({text: false})}
      })
    } 

    render(){
        document.title = "Activate Account";
        return(
            <LoginLayout header="Verifying Account">
               {(this.state.text== true) && <Result
            className="flex-60 padding0"
            status="success"
            title={<h4>Your account has been activated.</h4>}
            extra={[
              <Button type="primary">
                <Link to="/login">Login</Link>
              </Button>,
            ]}
          />}

    {(this.state.text== null) && <Result
            className="flex-60 padding0"
            status="error"
            title={<h4>Sorry something is wrong with your account.</h4>}
            extra={[
              <Button type="primary">
                <Link to="/login">Login</Link>
              </Button>,
            ]}
          />}
            </LoginLayout>
        )
    }
  
  
 
}

export default VerifyAccount;
