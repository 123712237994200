import React, { useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	Menu,
	message,
	DatePicker,
	Table,
	Popconfirm,
	Calendar,
	Input,
	Avatar,
	Tooltip,
	Divider,
	Carousel,
	Alert
} from 'antd';
import { Modal, Form } from 'react-bootstrap';
import {
	ArrowRightOutlined,
	FileDoneOutlined,
	CloseOutlined,
	PlusCircleOutlined,
	CloseCircleOutlined,
	CalendarOutlined,
	EditOutlined,
	CheckCircleOutlined,
	ExclamationCircleOutlined,
	DeleteOutlined,
	ClockCircleOutlined,
	MinusOutlined,
	CheckOutlined
} from '@ant-design/icons';

import useWindowDimensions from '../../Context/WindowsDimensions';
import axios from 'axios';
import AssignVendorModal from './actions/modals/AssignVendor';
import AssignEmployeeModal from './actions/modals/AssignEmployee';
import CreateEventModal from './actions/modals/CreateEvent';
import EditEventModal from './actions/modals/EditEvent';
import moment from 'moment';
import RequestContext from './RequestContext';

import CommsBarCalendar from '../../Components/CommsBarCalendar';
import LoadScreen from '../../Components/LoadScreen';
import {
	MaintenanceIcons,
	MaintenanceStatus
} from '../JobRequests/RequestListing';
import { createNoSubstitutionTemplateLiteral } from 'typescript';
import WorkReport from './WorkReport';
import WorkDoneReport from './WorkDoneReport';
import WorkOrderDisplay from './actions/modals/WorkOrderDisplay/WorkOrderDisplay';
import TroubleshootDisplay from './actions/modals/WorkOrderDisplay/WorkOrderDisplay';
import { FieldTimeOutlined } from '@ant-design/icons';
import ExactExpenses from '../../Modals/WorkOrder/Pages/ExactExpenses';
import AddCharge from '../Charge/AddCharge';
import InvoiceDetails from '../Charge/InvoiceDetails';
const cancelToken = axios.CancelToken.source();
const { RangePicker } = DatePicker;

const columns = [
	{
		title: 'Description',
		dataIndex: 'item'
	},
	{
		title: 'Quantity',
		dataIndex: 'quantity'
	},
	{
		title: 'Vendor',
		dataIndex: 'vendor'
	},
	{
		title: 'Price',
		dataIndex: 'est_price'
	},
	{
		title: 'Type',
		key: 'type',
		render: (_, record) => {
			return record.is_labour === 1 ? 'Labour' : 'Material';
		}
	}
];
const jobStatusOrder = {
	'Job Request': 13,
	Scheduling: 12,
	'Work Order - Scheduled': 11,
	'Pending Cost Approval': 10,
	'Work Order - Troubleshoot': 9,
	'Work Order - Work Done': 8,
	'Pending Extension': 7,
	'Work Order - Review': 6,
	Invoicing: 5,
	'In Market': 4,
	'On Hold': 3,
	Rejected: 2,
	Cancelled: 1,
	Complete: 0
};

const userTypeLookup = ['Vendor', 'Tenant', 'Owner', 'Employee'];

//Main container for the vendor and event details.
class RequestVendor extends React.Component {
	static contextType = RequestContext;

	constructor(props) {
		super(props);

		// currentEdit / currentDelete - event objects of the event being changed. // editModal/deleteModal : boolean of the modal open or closed.
		this.state = {
			currentEdit: null,
			editInfo: null,
			editModal: false,
			currentNotice: false,
			noticeModal: false,
			currentDelete: null,
			deleteModal: false,
			calendarModal: false,
			openCal: false,
			taskarray: [],
			doPrevent: false,
			needUpdate: false,
			warnUpdate: false,
			expandEvent: null,
			record: null,
			workReportDetails: false,
			workDoneDetails: false,
			currentWorkReport: '',
			workId: '',
			reworkModal: false,
			addCharges: false,
			chargeModal: false,
			invoiceDetails: []
		};
	}
	componentDidMount() {
		// console.log(this.context.request.workSchedule);
		this.getWorkId();
		this.getInvoiceDetails();
		// axios
		// 	.post('/workOrder/getInvoiceDetails', {
		// 		JobId: this.context.request.job_id
		// 	})
		// 	.then(res => {
		// 		// console.log(res.data.invoiceDetails);
		// 		this.setState({ invoiceDetails: res.data.invoiceDetails });
		// 	});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.currentWorkReport !== this.state.currentWorkReport)
			this.getWorkId();
		// if (prevState.invoiceDetails !== this.state.invoiceDetails)
		// this.getInvoiceDetails();
	}

	// getInvoiceDetails
	getInvoiceDetails = () => {
		axios
			.post('/workOrder/getInvoiceDetails', {
				JobId: this.context.request.job_id
			})
			.then(res => {
				// console.log(res.data.invoiceDetails);
				// console.log(res.data.chargeDetails);
				// this.setState({ invoiceDetails: res.data.invoiceDetails });
				let invoiceDetailsArray = res.data.invoiceDetails;
				let chargeDetailsArray = res.data.chargeDetails;
				invoiceDetailsArray.forEach(invoice => {
					invoice.chargeData = chargeDetailsArray.filter(
						charge => charge.invoice_id === invoice.id
					);
				});

				this.setState({ invoiceDetails: invoiceDetailsArray });
			});
	};
	getAvailability = id => {
		axios
			.post('/get/loadvenderavailability', { jobvenderID: id })
			.then(response => {
				this.setState({ taskarray: response.data.Items });
				return;
			});
	};

	openChargeModal = () => {
		this.setState({ addCharges: !this.state.addCharges });
	};

	openDeleteEventModal = () => {
		this.setState({ deleteModal: !this.state.deleteModal });
	};
	openEditEventModal = (forceClose = false) => {
		if (!this.state.doPrevent) {
			if (this.state.needUpdate && forceClose !== true) {
				this.setState({ warnUpdate: true });
			} else {
				this.setState({
					editModal: !this.state.editModal,
					needUpdate: false,
					warnUpdate: false
				});
			}
		}
	};
	openWorkReport = () => {
		this.setState({ workReportDetails: !this.state.workReportDetails });
	};
	openWorkDone = id => {
		console.log(id);
		let event = this.context.request.workSchedule.find(
			e => e.idcalendar_events === id
		);
		this.setState({
			workReportDetails: !this.state.workReportDetails,
			currentWorkReport: event
		});
	};
	openReworkModal = () => {
		this.setState({ reworkModal: !this.state.reworkModal });
	};
	getWorkId = () => {
		if (this.state.currentWorkReport) {
			axios
				.post('workOrder/get/workOrderDetails', {
					eventId: this.state.currentWorkReport.idcalendar_events
				})
				.then(res => {
					console.log(res.data.details);
					this.setState({ workId: res.data.details[0].wo_id });
				});
		}
	};
	preventClosing = val => {
		this.setState({ doPrevent: val });
	};
	requireUpdate = val => {
		this.setState({ needUpdate: val });
	};
	updateWarn = val => {
		this.setState({ warnUpdate: val });
	};
	afterEvent = event => {
		this.context.updateWork(event);
		message.success('The event has successfully been edited');
	};
	openCreateNoticeModal = () => {
		this.setState({ noticeModal: !this.state.noticeModal });
	};
	openCalendarModal = () => {
		this.setState({ calendarModal: !this.state.calendarModal });
	};
	updateCal = () => {
		this.setState({ openCal: !this.state.openCal });
	};
	setDeleteEvent = event => {
		this.setState({ currentDelete: event }, this.openDeleteEventModal);
	};
	setEditEvent = event => {
		this.setState({ currentEdit: event }, this.openEditEventModal);
	};
	updateEditEvent = event => {
		this.setState({ currentEdit: event });
	};

	submitEditEvent = editInfo => {
		let body = {
			event: this.state.currentEdit,
			editInfo: editInfo
		};
		axios
			.post('/jobRequest/update/event', body)
			.then(response => {
				this.context.editEvent(response.data.updatedEvent);
				this.context.addActivity(response.data.note, null, false);
				this.openEditEventModal();
				message.success(
					`The event "${response.data.updatedEvent.title}" has been updated.`
				);
			})
			.catch(err => console.log(err));
	};

	//Terminates the vendor.
	terminateVendor = id => {
		axios
			.post('jobRequest/update/vendor/terminate', {
				vendor_id: id,
				job_id: this.context.request.job_id
			})
			.then(response => {
				this.context.terminateVendor(id, response.data.endDate);
				message.success('Vendor has been terminated.');
			})
			.catch(err => {
				message.error('Error: Vendor is missing or misplaced.');
			});
	};
	terminateEmployee = id => {
		axios
			.post('jobRequest/update/jremployees/terminate', {
				Employee_ID: id,
				job_id: this.context.request.job_id
			})
			.then(res => {
				this.context.terminateEmployee(id, res.data.endDate);
				message.success('Employee has been terminated.');
			})
			.catch(err => {
				console.log(err);
				message.error('Error: Employee is missing or misplaced.');
			});
	};

	editEvent = id => {
		let event = this.context.request.workSchedule.find(
			e => e.idcalendar_events === id
		);
		this.setState({ editModal: !this.state.editModal, currentEdit: event });
		return;
	};

	completeEvent = id => {
		let body = {
			job_id: this.context.request.job_id,
			event_id: id
		};
		axios
			.post('jobRequest/update/event/complete', body)
			.then(() => {
				this.context.completeEvent(id);
				message.success(`The event has been marked as complete.`);
			})
			.catch(err => console.log(err));
	};

	cancelEvent = id => {
		let body = {
			job_id: this.context.request.job_id,
			event_id: id
		};
		axios
			.post('jobRequest/update/event/cancel', body)
			.then(() => {
				this.context.cancelEvent(id);
				//this.context.addActivity(response.data.note, null, false);
				message.success(`The event has been cancelled.`);
			})
			.catch(err => console.log(err));
	};
	approveWorkOrder = () => {
		axios
			.post('/jobRequest/update/status/workOrder/approve', {
				job_id: this.context.request.job_id,
				// wo_id: this.context.currentWorkOrder.wo_id
				wo_id: this.state.workId
			})
			.then(response => {
				message.success('Work Order Successfully Approved!', 2.5);

				// this.context.updateStatus('Invoicing');
				this.context.updateEventStatus('Work Approved');

				window.location.reload();
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		// console.log(this.state.invoiceDetails);
		let openEvent =
			this.state.expandEvent == null
				? null
				: this.context.request.workSchedule[this.state.expandEvent];
		let eventVendorIndexes = {};
		let eventVendors = [];
		let eventTenants = [];
		let eventOwners = [];
		let currentUserTypeCount = 0;
		let currentUserType = userTypeLookup[currentUserTypeCount];
		let userSortFunctions = [
			(user, userIndex) => {
				eventVendorIndexes[user.user_id] = userIndex;
				eventVendors.push({ ...user, employees: [] });
			},
			(user, userIndex) => {
				eventTenants.push(user);
			},
			(user, userIndex) => {
				eventOwners.push(user);
			},
			(user, userIndex) => {
				if (user.company_id in eventVendorIndexes)
					eventVendors[eventVendorIndexes[user.company_id]].employees.push(
						user
					);
			}
		];
		if (openEvent) {
			openEvent.users.forEach((user, userIndex) => {
				if (user.user_type === currentUserType) {
					userSortFunctions[currentUserTypeCount](user, userIndex);
				} else {
					while (user.user_type !== currentUserType) {
						currentUserTypeCount++;
						currentUserType = userTypeLookup[currentUserTypeCount];
					}
					userSortFunctions[currentUserTypeCount](user, userIndex);
				}
			});
		}
		// if (
		// 	this.context.activeEvent &&
		// 	this.context.request.job_status === 'Invoicing'
		// ) {
		// 	this.context.updateStatus('Work Order - Scheduled');
		// }

		return (
			<>
				<Card bordered={false}>
					<div id='vendorInfoDiv'>
						{this.context.request.vendors !== undefined ? (
							<>
								<div className='JRSubtitle'>Vendors</div>
								{this.props.user.company_id === this.context.request.PM_id &&
								this.props.user.type === 'Manager' &&
								this.context.request.vendors.length > 0 ? (
									<ChangeVendor
										request={this.context.request}
										addActivity={this.context.addActivity}
										updateVendors={this.context.updateVendors}
									/>
								) : (
									''
								)}
								<br />
								<Table
									style={{ width: '100%' }}
									columns={vendorTableCols(
										this.terminateVendor,
										this.state.calendarModal,
										this.openCalendarModal,
										this.state.openCal,
										this.updateCal,
										this.getAvailability,
										this.state.taskarray,
										this.props.user.type
									)}
									dataSource={this.context.request.vendors || []}
									rowKey={v => `${v.vendor_id}+${v.start}`}
									pagination={false}
									size={'small'}
									rowClassName={row =>
										row.terminate === 1 ? 'table-row-disabled' : ''
									}
								/>
								{this.context.request.employees.length > 0 ||
								this.context.request.vendors.some(
									v => v.vendor_id === this.props.user.company_id
								) ? (
									<>
										<br />
										<div className='JRSubtitle'>Your Employees</div>
										{this.props.user.company_id ===
											this.context.request.PM_id &&
										this.props.user.type === 'Manager' ? (
											<ChangeEmployee
												request={this.context.request}
												addActivity={this.context.addActivity}
												updateEmployees={this.context.updateEmployees}
											/>
										) : (
											''
										)}
										<br />
										<Table
											style={{ width: '100%' }}
											columns={[
												{
													title: 'Name',
													dataIndex: 'name',
													width: '30%',
													key: 'name'
												},
												{
													title: 'Start',
													key: 'vendor_start',
													dataIndex: 'startDate',
													width: '20%'
												},
												{
													title: 'End',
													key: 'vendor_end',
													dataIndex: 'endDate',
													width: '20%'
												},
												{
													title: 'Action',
													key: 'actions',
													dataIndex: 'employee_id',
													render: (id, record) => {
														return (
															<div className='text-center'>
																{record.terminate === 0 ? (
																	<Popconfirm
																		title='Terminate this Employee?'
																		onConfirm={() => this.terminateEmployee(id)}
																		okText='Confirm'
																		cancelText='Cancel'
																	>
																		<CloseCircleOutlined />
																	</Popconfirm>
																) : (
																	''
																)}
															</div>
														);
													}
												}
											]}
											dataSource={this.context.request.employees || []}
											rowKey={e => e.employee_id + e.start}
											pagination={false}
											size={'small'}
											rowClassName={row =>
												row.terminate === 1 ? 'table-row-disabled' : ''
											}
										/>
									</>
								) : null}
							</>
						) : (
							''
						)}

						{this.context.request.workSchedule !== undefined ? (
							openEvent == null ? (
								<>
									<br />
									<div>
										<div className='JRSubtitle'>Events</div>
										{this.props.user.company_id ===
											this.context.request.PM_id &&
										this.props.user.type === 'Manager' &&
										jobStatusOrder[this.context.request.job_status] <=
											jobStatusOrder['Scheduling'] ? (
											<AddSchedule
												request={this.context.request}
												addActivity={this.context.addActivity}
												scheduleWork={this.context.scheduleWork}
											/>
										) : (
											''
										)}
									</div>
									{this.context.request.workSchedule.length > 0 ? (
										<div className='scheduledEvents'>
											<Table
												style={{ width: '100%' }}
												columns={eventTableCols(
													this.editEvent,
													this.cancelEvent,
													this.openWorkDone,
													this.completeEvent,
													this.context.request.vendors,
													this.context.user.type
												)}
												// columns={[
												//   {
												//     title:'Purpose',
												//     dataIndex:'title',
												//     key:'title',
												//     width:'38.8%'
												//   },
												//   {
												//     title:'Start Date',
												//     dataIndex:'start',
												//     key:'start',
												//     width:'22.2%',
												//     render: (e) => moment(e ).format("h:mma, ddd MMM D, YYYY"),

												//   },
												//   {
												//     title:'End Date',
												//     dataIndex:'end',
												//     key:'end',
												//     width:'22.2%',
												//     render: (e) => moment(e).format("h:mma, ddd MMM D, YYYY")

												//   }
												// ]}
												dataSource={this.context.request.workSchedule}
												rowKey={e => e.idcalendar_events}
												pagination={false}
												size={'small'}
												rowClassName={row =>
													row.terminate === 1 || row.complete === 1
														? 'table-row-disabled'
														: ''
												}
												// onRow={(record, rowIndex) => {
												// 	return {
												// 		onClick: event => {
												// 			this.setState({
												// 				expandEvent: rowIndex,
												// 				record: record
												// 			});
												// 		},
												// 		style: { cursor: 'pointer' }
												// 	};
												// }}
											/>
										</div>
									) : (
										<div className='mt-2'>
											<div className='g-text-sm'>
												No Events currently scheduled
											</div>
										</div>
									)}
								</>
							) : (
								<>
									<br />
									<div>
										{/* <div className='JRSubtitle'>
											Events
											<Button
												icon={<CloseOutlined />}
												type='text'
												style={{ color: '#778ca2' }}
												onClick={() => {
													this.setState({ expandEvent: null });
												}}
											/>
										</div>
										<br />
										<Table
											columns={eventTableCols(
												this.editEvent,
												this.cancelEvent,
												this.completeEvent,
												this.context.request.vendors,
												this.context.user.type
											)}
											pagination={false}
											style={{ width: '100%' }}
											size={'small'}
											dataSource={[openEvent]}
										/> */}

										{eventVendors.length > 0 ? (
											<>
												<div className='JRSubtitle'>Vendors</div>
												<Table
													size={'small'}
													style={{ width: '100%' }}
													columns={[
														{
															width: '5%',
															title: '',
															dataIndex: 'imageurl',
															key: 'profile',
															render: imageurl => {
																return <Avatar size='large' src={imageurl} />;
															}
														},
														{
															title: 'Name',
															dataIndex: 'fname',
															key: 'fname'
														},
														{
															title: 'Accepted',
															dataIndex: 'has_accepted',
															key: 'accepted',
															render: accepted => {
																return accepted === null ? (
																	<MinusOutlined />
																) : accepted === 1 ? (
																	<CheckOutlined />
																) : (
																	<CloseOutlined />
																);
															}
														}
													]}
													dataSource={eventVendors}
													expandable={{
														expandedRowRender: record => (
															<Table
																columns={[
																	{
																		width: '5%',
																		title: '',
																		dataIndex: 'imageurl',
																		key: 'profile',
																		render: imageurl => {
																			return (
																				<Avatar size='large' src={imageurl} />
																			);
																		}
																	},
																	{
																		title: 'Name',
																		dataIndex: 'user_id',
																		key: 'name',
																		render: (id, record) => {
																			return record.fname + ' ' + record.lname;
																		}
																	},
																	{
																		title: 'Role',
																		dataIndex: 'role',
																		key: 'role'
																	},
																	{
																		title: 'Accepted',
																		dataIndex: 'has_accepted',
																		key: 'accepted',
																		render: accepted => {
																			return accepted === null ? (
																				<MinusOutlined />
																			) : accepted === 1 ? (
																				<CheckOutlined />
																			) : (
																				<CloseOutlined />
																			);
																		}
																	}
																]}
																dataSource={record.employees}
																pagination={false}
															/>
														),
														rowExpandable: record => record.employees.length > 0
													}}
													pagination={false}
												/>
											</>
										) : null}
										{eventOwners.length > 0 ? (
											<>
												<div className='JRSubtitle'>Owners</div>
												<Table
													size={'small'}
													style={{ width: '100%' }}
													columns={[
														{
															width: '5%',
															title: '',
															dataIndex: 'imageurl',
															key: 'profile',
															render: imageurl => {
																return <Avatar size='large' src={imageurl} />;
															}
														},
														{
															title: 'Name',
															dataIndex: 'user_id',
															key: 'name',
															render: (id, record) => {
																return record.fname + ' ' + record.lname;
															}
														},
														{
															title: 'Accepted',
															dataIndex: 'has_accepted',
															key: 'accepted',
															render: accepted => {
																return accepted === null ? (
																	<MinusOutlined />
																) : accepted === 1 ? (
																	<CheckOutlined />
																) : (
																	<CloseOutlined />
																);
															}
														}
													]}
													dataSource={eventOwners}
													pagination={false}
												/>
											</>
										) : null}
										{eventTenants.length > 0 ? (
											<>
												<div className='JRSubtitle'>Tenants</div>
												<Table
													size={'small'}
													style={{ width: '100%' }}
													columns={[
														{
															width: '5%',
															title: '',
															dataIndex: 'imageurl',
															key: 'profile',
															render: imageurl => {
																return <Avatar size='large' src={imageurl} />;
															}
														},
														{
															title: 'Name',
															dataIndex: 'user_id',
															key: 'name',
															render: (id, record) => {
																return record.fname + ' ' + record.lname;
															}
														},
														{
															title: 'Accepted',
															dataIndex: 'has_accepted',
															key: 'accepted',
															render: accepted => {
																return accepted === null ? (
																	<MinusOutlined />
																) : accepted === 1 ? (
																	<CheckOutlined />
																) : (
																	<CloseOutlined />
																);
															}
														}
													]}
													dataSource={eventTenants}
													pagination={false}
												/>
											</>
										) : null}
									</div>
								</>
							)
						) : (
							''
						)}
						<br />
						{this.context.request.job_status === 'Invoicing' ? (
							<div>
								<div className='JRSubtitle'>Charges</div>
								<PlusCircleOutlined
									className='greyActionIcon'
									onClick={this.openChargeModal}
								/>
								{/* <div style={{ float: 'right' }}>
									{this.state.addCharges && (
										<>
											<Form.Label className='form-label'>
												Invoice Date
											</Form.Label>
											<DatePicker
												style={{ width: 150, marginLeft: 5 }}
												onChange={(date, dateString) => {
													this.setState({
														invoiceDate: dateString,
														chargeModal: true
													});
												}}
											/>
										</>
									)}
								</div> */}
								{this.state.invoiceDetails.length > 0 && (
									<InvoiceDetails
										invoiceDetails={this.state.invoiceDetails}
										getInvoiceDetails={this.getInvoiceDetails}
									/>
								)}
							</div>
						) : (
							''
						)}
						<br />
					</div>
				</Card>
				{this.state.addCharges && (
					<Modal
						show={this.state.addCharges}
						onHide={this.openChargeModal}
						size='lg'
						aria-labelledby='add-charges'
						centered
					>
						<AddCharge
							invoiceDetails={this.state.invoiceDetails}
							getInvoiceDetails={this.getInvoiceDetails}
							invoiceDate={this.state.invoiceDate}
							onClose={() => this.setState({ chargeModal: false })}
							openChargeModal={this.openChargeModal}
						/>
					</Modal>
				)}
				<Modal
					show={this.state.deleteModal}
					onHide={this.openDeleteEventModal}
					size='sm'
					aria-labelledby='change-vendor-modal'
					centered
				>
					{/*this.state.deleteModal ? (
            <DeleteEventModal
              onHide={this.openDeleteEventModal}
              deleteEvent={this.submitDeleteEvent}
              event={this.state.currentDelete}
            />
          ) : (
            ""
          )*/}
				</Modal>
				<Modal
					show={this.state.editModal}
					onHide={this.openEditEventModal}
					size='lg'
					centered
				>
					{this.state.editModal ? (
						<EditEventModal
							updateStatus={this.context.updateStatus}
							updateImages={this.context.updateImages}
							preventClosing={this.preventClosing}
							onHide={this.openEditEventModal}
							afterEvent={this.afterEvent}
							vendors={this.context.request.vendors}
							employees={this.context.request.employees}
							tenants={this.context.request.tenants}
							owners={this.context.request.owners}
							job_id={this.context.request.job_id}
							request={this.context.request}
							user={this.context.user}
							event={this.state.currentEdit}
							updateEditEvent={this.updateEditEvent}
							doPrevent={this.state.doPrevent}
							requireUpdate={this.requireUpdate}
							needUpdate={this.state.needUpdate}
							updateWarn={this.updateWarn}
							warnUpdate={this.state.warnUpdate}
						/>
					) : (
						''
					)}
				</Modal>
				<Modal
					show={this.state.workReportDetails}
					onHide={this.openWorkReport}
					title={'Work Done Report'}
					size='lg'
					scrollable={true}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Work Order Report</Modal.Title>
					</Modal.Header>
					<Modal.Body
						style={{
							maxHeight: 'calc(100vh - 210px)',
							overflowY: 'auto'
						}}
					>
						{this.state.workReportDetails ? (
							<WorkDoneReport
								ImageDisplay={ImageDisplay}
								currentWorkEvent={this.state.currentWorkReport}
							/>
						) : (
							''
						)}
					</Modal.Body>
					<Modal.Footer>
						{this.context.user.type === 'Manager' ? (
							this.state.currentWorkReport.complete === 0 &&
							this.state.currentWorkReport.rework === 0 ? (
								<>
									<Button
										type='primary'
										onClick={this.approveWorkOrder}
										className='mr-3'
									>
										Approve
									</Button>
									<Button
										type='primary'
										onClick={this.openReworkModal}
										className='mr-3'
									>
										Rework
									</Button>
									<Button
										type='primary'
										onClick={this.openWorkReport}
										className='mr-3'
									>
										Close
									</Button>
								</>
							) : (
								''
							)
						) : (
							''
						)}
					</Modal.Footer>
				</Modal>
				{this.state.reworkModal && (
					<AddSchedule
						workId={this.state.workId}
						openReworkModal={this.openReworkModal}
						reworkModal={this.state.reworkModal}
						request={this.context.request}
						addActivity={this.context.addActivity}
						scheduleWork={this.context.scheduleWork}
					/>
				)}
			</>
		);
	}
}
const eventTableCols = (
	editEvent,
	cancelEvent,
	openWorkDoneReport,
	completeEvent,
	vendors,
	type
) => {
	return [
		{
			title: 'Employee Name',
			dataIndex: 'employee_name',
			key: 'employee_name',
			width: '20%'
		},
		{
			title: 'Purpose',
			dataIndex: 'title',
			key: 'title',
			width: '25%'
		},
		{
			title: 'Start Date',
			dataIndex: 'start',
			key: 'start',
			width: '22.2%',
			render: e => moment(e).format('h:mma, ddd MMM D, YYYY')
		},
		{
			title: 'End Date',
			dataIndex: 'end',
			key: 'end',
			width: '22.2%',
			render: e => moment(e).format('h:mma, ddd MMM D, YYYY')
		},
		{
			title: 'Actions',
			key: 'actions',
			dataIndex: 'idcalendar_events',
			width: '11.1%',
			render: (id, record) => {
				// console.log(record);
				if (
					record.terminate === 0 &&
					record.complete === 0 &&
					record.start_work === 0 &&
					record.rework === 0
				) {
					if (type === 'Manager') {
						return (
							<div className='text-center'>
								<EditOutlined className='mr-2' onClick={() => editEvent(id)} />
								<Popconfirm
									title='Cancel this Event?'
									onConfirm={() => cancelEvent(id)}
									okText='Confirm'
									cancelText='Cancel'
								>
									<CloseCircleOutlined className='mr-2' />
								</Popconfirm>
								{record.start_work === 0 ? (
									''
								) : (
									// <FileDoneOutlined className='mr-2' disabled={true} />
									<FileDoneOutlined
										className='mr-2'
										onClick={() => openWorkDoneReport(id)}
									/>
								)}
							</div>
						);
					} else return 'Not Started';
				} else {
					if (record.complete === 1 && record.start_work === 1)
						return (
							<>
								<p>Completed</p>
								<FileDoneOutlined
									className='mr-2'
									onClick={() => openWorkDoneReport(id)}
								/>
							</>
						);
					if (record.terminate === 1) return 'Cancelled';
					if (record.rework === 1 && record.start_work === 1)
						return (
							<>
								<p>Rescheduled</p>
								<FileDoneOutlined
									className='mr-2'
									onClick={() => openWorkDoneReport(id)}
								/>
							</>
						);
					if (record.start_work === 1)
						return (
							<>
								<p>In Progress</p>
								<FileDoneOutlined
									className='mr-2'
									onClick={() => openWorkDoneReport(id)}
								/>
							</>
						);
				}
			}
		}
	];
};

const vendorTableCols = (
	terminateVendor,
	calendarModal,
	openCalendarModal,
	openCal,
	updateCal,
	getAvailability,
	taskarray,
	userType
) => {
	return [
		{
			title: 'Name',
			dataIndex: 'name',
			width: '49%',
			key: 'name'
		},
		{
			title: 'Start',
			key: 'vendor_start',
			dataIndex: 'startDate',
			width: '20%'
		},
		{
			title: 'End',
			key: 'vendor_end',
			dataIndex: 'endDate',
			width: '20%'
		},
		{
			title: 'Actions',
			dataIndex: 'vendor_id',
			render: (id, record) => {
				if (record.terminate === 0 && userType === 'Manager')
					return (
						<div className='text-center'>
							<Popconfirm
								title='Terminate this vendor?'
								onConfirm={() => terminateVendor(id)}
								okText='Confirm'
								cancelText='Cancel'
							>
								<CloseCircleOutlined className='mr-2' />
							</Popconfirm>
							<CalendarOutlined
								onClick={() => {
									openCalendarModal();
									updateCal();
								}}
							/>
							<Modal
								show={calendarModal}
								onHide={() => {
									openCalendarModal();
								}}
								size='lg'
								//aria-labelledby="change-vendor-modal"
								centered
								onExited={() => {
									updateCal();
								}}
							>
								{openCal ? (
									<CalendarModal
										record1={record}
										id1={id}
										vendorId='vendor_id'
										taskArray={taskarray}
										onHide={() => {
											openCalendarModal();
										}}
									/>
								) : null}
							</Modal>
						</div>
					);
				else return null;
			},
			width: '11%'
		}
	];
};
const EventCard = ({ mobileView, event, selected = false }) => {
	let startDate =
		event.start < 1263254400000 ? event.start * 1000 : event.start;
	let endDate = event.end < 1263254400000 ? event.end * 1000 : event.end;
	let sameDay = moment(endDate).isSame(moment(startDate), 'day');
	const context = React.useContext(RequestContext);
	const request = context.request;
	return (
		<Card
			bordered={false}
			className={`mb-2 misc-card mrdisplayCard ${
				mobileView ? 'maintenanceMobileView' : 'displayCard'
			} ${selected ? 'maintenanceCardSelected' : ''}`}
			style={{ margin: 'auto' }}
		>
			<div className='cardMain'>
				<Avatar size={38} className='mr-2' style={{ backgroundColor: 'unset' }}>
					<img
						alt='Maintenance Category'
						width={18}
						height={18}
						src={require('../../icons/maintenanceIcons/' +
							MaintenanceIcons(request.job_subcategory))}
					/>
				</Avatar>
				<div className='cardLeft'>
					<div className='cardHeader'>
						<div className='caption titleHover'>{request.job_title}</div>
					</div>
					<div className='cardLeftFooter g-text-sm'>
						<div className='cardFooterItem'>ID JR{request.job_id}</div>
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
						<div
							className='cardFooterItem mr-2'
							style={{ float: 'right', color: 'rgb(93, 140, 241)' }}
						>
							{request.elapsedTime ? (
								<>
									&nbsp;
									<ClockCircleOutlined />
									&nbsp;{request.elapsedTime.value}
									{request.elapsedTime.type[0]}
								</>
							) : (
								''
							)}
						</div>
					</div>
					<div className='cardHeader' style={{ color: '#1890ff' }}>
						{event.title}:&nbsp;
						<span style={{ display: 'inline-block' }}>
							{moment(startDate).format('ddd, MMM D')}
							&nbsp;at&nbsp;
							{`${moment(startDate).format('h:mma')}`}
						</span>
						&nbsp;-&nbsp;
						<span style={{ display: 'inline-block' }}>
							{!sameDay ? (
								<>
									{moment(endDate).format('ddd, MMM D')}
									&nbsp;at&nbsp;
									{moment(endDate).format('h:mma')}
								</>
							) : (
								moment(endDate).format('h:mma')
							)}
						</span>
					</div>
				</div>
			</div>
			<div className={`cardRight`}>
				<div
					className={`cardRightHeader ${
						request.vendor ? '' : 'rightHeaderMxAuto'
					}`}
				>
					<MaintenanceStatus
						className='rq-status d-inline'
						value={request.job_status}
						status={request.job_status}
					/>
					&nbsp;&middot;&nbsp;
					<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
				</div>
			</div>
		</Card>
	);
};
const CreateNoticeModal = props => {
	const context = React.useContext(RequestContext);
	const { width } = useWindowDimensions();
	const [noticePage, setPage] = useState(0);
	const [workersList, setWorkers] = useState([]);
	const [addPeople, setAddPeople] = useState([]);
	const [addPeopleText, setAddPeopleText] = useState('');
	const [prepPdf, setPrepPdf] = useState(null);
	const setUserWorker = id => {
		let newWorkers = [...workersList];
		let workerIndex = newWorkers.indexOf(id);
		if (workerIndex >= 0) newWorkers.splice(workerIndex, 1);
		else newWorkers.push(id);
		setWorkers(newWorkers);
	};
	const pushAddPerson = () => {
		if (addPeopleText.length > 0) {
			let newAddPeople = [...addPeople];
			newAddPeople.push({
				info: addPeopleText,
				id: addPeople.length > 0 ? addPeople[addPeople.length - 1].id + 1 : 0
			});
			setAddPeopleText('');
			setAddPeople(newAddPeople);
		}
	};
	const delAddPerson = index => {
		let newAddPeople = [...addPeople];
		newAddPeople.splice(index, 1);
		setAddPeople(newAddPeople);
	};
	const applyAddPeopleText = e => {
		setAddPeopleText(e.target.value);
	};
	const generatePdf = data => {};
	switch (noticePage) {
		case 0:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Issue Notice of Entry</div>
					</Modal.Header>
					<Modal.Body className='text-center'>
						<EventCard event={props.event} clickable={false} />
						<Button
							type='primary'
							onClick={() => {
								setPage(1);
							}}
							className='vndOpBtns'
						>
							Notice to Enter
						</Button>
						<Button
							type='primary'
							onClick={() => {
								setPage(2);
							}}
							className='vndOpBtns'
						>
							Consent to Enter
						</Button>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={props.onHide}>Close</Button>
					</Modal.Footer>
				</>
			);
		case 1:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Issue Notice of Entry</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						{context.workers.map(worker => (
							<VendorSelectListing
								key={worker.id}
								addFunction={setUserWorker}
								vendList={workersList}
								worker={worker}
							/>
						))}
						{addPeople.map((person, personIndex) => {
							return (
								<PersonInfoListing
									key={person.id}
									delFunction={delAddPerson}
									personList={addPeople}
									person={person}
									personIndex={personIndex}
								/>
							);
						})}
						<div className='mt-2'>
							<div className='person-listing'>
								<div className='d-flex flex-60'>
									<div className='flex-20 listing-icon m-auto'>
										Add Additional Person
										<Input
											style={{
												width: '27%',
												marginLeft: '10px',
												marginRight: '10px'
											}}
											type='text'
											placeholder='Enter info about this person'
											value={addPeopleText}
											onChange={applyAddPeopleText}
										/>
										<Button onClick={pushAddPerson} type='primary'>
											Add
										</Button>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(3);
							}}
						>
							Next
						</Button>
						<Button
							onClick={() => {
								setPage(0);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 2:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Send Request for Consent</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						{context.workers.map(worker => (
							<VendorSelectListing
								key={worker.id}
								addFunction={setUserWorker}
								vendList={workersList}
								worker={worker}
							/>
						))}
						{addPeople.map((person, personIndex) => {
							return (
								<PersonInfoListing
									key={person.id}
									delFunction={delAddPerson}
									personList={addPeople}
									person={person}
									personIndex={personIndex}
								/>
							);
						})}
						<div className='mt-2'>
							<div className='person-listing'>
								<div className='d-flex flex-60'>
									<div className='flex-20 listing-icon m-auto'>
										Add Additional Person
										<Input
											style={{
												width: '27%',
												marginLeft: '10px',
												marginRight: '10px'
											}}
											type='text'
											placeholder='Enter info about this person'
											value={addPeopleText}
											onChange={applyAddPeopleText}
										/>
										<Button onClick={pushAddPerson} type='primary'>
											Add
										</Button>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(4);
							}}
						>
							Next
						</Button>
						<Button
							onClick={() => {
								setPage(0);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 3:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Issue Notice of Entry</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						{prepPdf ? (
							<object height='700' data={prepPdf}></object>
						) : (
							<LoadScreen className='noMarginTop' />
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(1);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 4:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Send Request for Consent</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						{prepPdf ? (
							<object height='700' data={prepPdf}></object>
						) : (
							<LoadScreen className='noMarginTop' />
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(2);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
	}
};
/*const DeleteEventModal = (props) => {
  return (
    <>
      <Modal.Header>Really delete this event?</Modal.Header>
      <Modal.Body>
        <EventCard event={props.event} width100 />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancel</Button>
        <Button type="primary" danger onClick={props.deleteEvent}>
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
};*/

function createEvent(newEvent, record) {
	//let eventID= new Date().getTime()
	axios.post('/post/addvenderavailability', {
		jobvenderID: parseInt(String(record.job_id) + String(record.vendor_id)),
		eventID: newEvent.event_id,
		startTime: newEvent.startTime,
		endTime: newEvent.endTime,
		startDate: newEvent.startDate
	});
}
function changeEvent(newData, record) {
	if (newData.operation == 'delete') {
		axios.post('/deletevenderavailability', {
			jobvenderID: parseInt(String(record.job_id) + String(record.vendor_id)),
			eventID: parseInt(newData.id)
		});
	}
	if (newData.operation == 'edit') {
		axios.post('/updatevenderavailability', {
			jobvenderID: parseInt(String(record.job_id) + String(record.vendor_id)),
			eventID: newData.id,
			NewEndTime: newData.data.endTime,
			NewStartTime: newData.data.startTime,
			NewStartDate: newData.data.startDate
		});
	}
	if (newData.operation == 'merge') {
		for (var i = 0; i < newData.delete_ids.length; i++) {
			axios.post('/deletevenderavailability', {
				jobvenderID: parseInt(String(record.job_id) + String(record.vendor_id)),
				eventID: parseInt(newData.delete_ids[i])
			});
		}
		axios.post('/updatevenderavailability', {
			jobvenderID: parseInt(String(record.job_id) + String(record.vendor_id)),
			eventID: newData.id,
			NewEndTime: newData.data.endTime,
			NewStartTime: newData.data.startTime,
			NewStartDate: newData.data.startDate
		});
	}
}

const CalendarModal = props => {
	const [lockOn, updateLock] = useState(false);
	return (
		<>
			<Modal.Header>Availability</Modal.Header>
			<Modal.Body>
				<CommsBarCalendar
					dataSource={[
						{
							bars: props.taskArray
						}
					]}
					eventStyles={{
						pink: {
							start: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							}
						},
						blue: {
							start: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							}
						},
						default: {
							start: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							}
						}
					}}
					calendarView='month'
					showHeader
					showTimeBar
					orientation='vertical'
					interactMode='editable'
					allowNewEvents
					newEvent={newEvent => {
						createEvent(newEvent, props.record1);
						return newEvent;
					}}
					editEvent={newData => {
						changeEvent(newData, props.record1);
						return;
					}}
					clickEvent={eventData => {}}
					blockWidth={35}
					blockHeight={20}
					lockOn={lockOn}
					updateLock={newLock => {
						updateLock(newLock);
					}}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</>
	);
};

const ChangeVendor = props => {
	const context = React.useContext(RequestContext);
	const [changeVendorModal, openModal] = useState(false);
	const openChangeVendorModal = () => openModal(!changeVendorModal);

	return (
		<>
			<PlusCircleOutlined
				className='greyActionIcon'
				onClick={openChangeVendorModal}
			/>
			<Modal
				show={changeVendorModal}
				onHide={openChangeVendorModal}
				size='lg'
				contentClassName='header-wide-modal'
				aria-labelledby='change-vendor-modal'
				centered
				scrollable
			>
				{changeVendorModal ? (
					<AssignVendorModal
						onHide={openChangeVendorModal}
						request={context.request}
						updateVendors={context.updateVendors}
						updateEmployees={context.updateEmployees}
					/>
				) : (
					''
				)}
			</Modal>
		</>
	);
};

const ChangeEmployee = props => {
	const context = React.useContext(RequestContext);
	const [changeEmployeeModal, openModal] = useState(false);
	const openChangeEmployeeModal = () => openModal(!changeEmployeeModal);

	return (
		<>
			<PlusCircleOutlined
				className='greyActionIcon'
				onClick={openChangeEmployeeModal}
			/>
			<Modal
				show={changeEmployeeModal}
				onHide={openChangeEmployeeModal}
				size='lg'
				dialogClassName='header-wide-modal'
				aria-labelledby='change-vendor-modal'
				centered
				scrollable
			>
				{changeEmployeeModal ? (
					<AssignEmployeeModal
						onHide={openChangeEmployeeModal}
						request={context.request}
						updateEmployees={context.updateEmployees}
					/>
				) : (
					''
				)}
			</Modal>
		</>
	);
};

const AddSchedule = props => {
	const context = React.useContext(RequestContext);
	const [newScheduleModal, openModal] = useState(false);
	const [showScheduleModal, showModal] = useState(false);
	const [preventClosing, setPreventClosing] = useState(false);
	const openBothModal = () => {
		if (!preventClosing) {
			showModal(!showScheduleModal);
			openModal(!newScheduleModal);
		}
	};
	const openNewScheduleModal = () => {
		if (!preventClosing) openModal(!newScheduleModal);
	};
	const openShowModal = () => {
		if (!preventClosing) showModal(!showScheduleModal);
	};

	const afterEvent = event => {
		context.updateWork(event);
		message.success('A new event has been successfully scheduled.');
		openNewScheduleModal();
	};

	if (context.request.vendors.length > 0)
		return !props.reworkModal ? (
			<>
				<PlusCircleOutlined
					className='greyActionIcon'
					onClick={openBothModal}
				/>
				<Modal
					show={newScheduleModal}
					onHide={openNewScheduleModal}
					onExited={openShowModal}
					size='lg'
					centered
				>
					{showScheduleModal ? (
						<CreateEventModal
							updateStatus={context.updateStatus}
							updateImages={context.updateImages}
							preventClosing={setPreventClosing}
							onHide={openNewScheduleModal}
							afterEvent={afterEvent}
							vendors={context.request.vendors}
							employees={context.request.employees}
							tenants={context.request.tenants}
							owners={context.request.owners}
							job_id={context.request.job_id}
							request={context.request}
							user={context.user}
							doPrevent={preventClosing}
						/>
					) : (
						''
					)}
				</Modal>
			</>
		) : (
			<Modal
				show={props.reworkModal}
				onHide={props.openReworkModal}
				// onExited={openShowModal}
				size='lg'
				centered
			>
				{props.reworkModal ? (
					<CreateEventModal
						workId={props.workId}
						reworkModal={props.reworkModal}
						updateStatus={context.updateStatus}
						updateImages={context.updateImages}
						preventClosing={setPreventClosing}
						onClose={props.openReworkModal}
						afterEvent={afterEvent}
						vendors={context.request.vendors}
						employees={context.request.employees}
						tenants={context.request.tenants}
						owners={context.request.owners}
						job_id={context.request.job_id}
						request={context.request}
						user={context.user}
						doPrevent={preventClosing}
					/>
				) : (
					''
				)}
			</Modal>
		);
	else return null;
};

export function VendorSelectListing({ addFunction, vendList, worker }) {
	return (
		<div
			className='mt-2'
			onClick={() => {
				addFunction(worker.id);
			}}
		>
			<div
				className={
					vendList.includes(worker.id)
						? 'vendor-listing selectedBlue'
						: 'vendor-listing'
				}
			>
				<div className=' d-flex flex-60'>
					<div className='flex-20 listing-icon m-auto'>
						<img
							className='circleImage image32 d-inline'
							src={worker.imageurl}
							alt='avatar'
						/>
					</div>
					<div className='listing-info flex-20 d-inline m-auto fs-14 '>
						{worker.fname} {worker.lname}
					</div>
					<div className='d-flex flex-60'>
						<div className='m-auto fs-list-sm'>{worker.email}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function PersonInfoListing({
	delFunction,
	personList,
	person,
	personIndex
}) {
	return (
		<div className='mt-2'>
			<div className='person-listing'>
				<div className='d-flex flex-60'>
					<div className='flex-20 listing-icon m-auto'>
						{person.info}
						<DeleteOutlined
							style={{ marginLeft: '10%' }}
							onClick={() => {
								delFunction(personIndex);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
const ImageDisplay = ({ images }) => {
	const { width } = useWindowDimensions();
	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState(false);

	const download = url => {
		//axios.get(url).then()
	};
	if (!Array.isArray(images)) return <></>;
	var slidesPer;
	if (width > 1300) slidesPer = 4;
	else if (width < 1300 && width > 1130) slidesPer = 3;
	else if (width < 1130 && width > 760) slidesPer = 2;
	else slidesPer = 1;
	const imgdownload = (url, name) => {
		if (!url) {
			throw new Error('Resource URL not provided! You need to provide one');
		}
		setFetching(true);
		fetch(url)
			.then(response => response.blob())
			.then(blob => {
				setFetching(false);
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobURL;
				a.style = 'display: none';

				if (name && name.length) a.download = name;
				document.body.appendChild(a);
				a.click();
			})
			.catch(() => setError(true));
	};

	return images.length > 0 ? (
		<Carousel
			arrows
			slidesPerRow={slidesPer}
			className='maintenanceCarousel'
			prevArrow={
				<img src={require('../../icons/chevronLeft.png')} alt='left' />
			}
			nextArrow={
				<img src={require('../../icons/chevronRight.png')} alt='right' />
			}
		>
			{images.map((i, index) => (
				<div className='imageTab' key={i.id}>
					<a href={i.url} target='_blank' type='application/pdf' download>
						<Tooltip title={i.event_id}>
							{i.type == 'image' && (
								<img
									className='d-block image carouselImages'
									src={require('../../images/pdfIcon.PNG')} //i.url
									alt='First slide'
									style={{ width: 50, height: 50 }}
								/>
							)}
							{i.type == 'pdf' && (
								<img
									className='d-block image carouselImages'
									src={require('../../images/pdfIcon.PNG')}
									alt='First slide'
									style={{ width: 50, height: 50 }}
								/>
							)}
						</Tooltip>
					</a>
				</div>
			))}
		</Carousel>
	) : (
		''
	);
};

export { RequestVendor };
