import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';
import CreateMaintenanceRequest from './CreateMaintenanceRequest';
import { useUserState } from '../../Context/UserContext';

function JobRequest(props) {
	const { user } = useUserState();
	const [page, setPage] = useState(null);

	const backToSelect = () => setPage(null);

	let render;
	switch (page) {
		case 'maintenanceRequest': {
			render = (
				<CreateMaintenanceRequest
					open={props.open}
					history={props.history}
					type='Maintenance Request'
					backToSelect={backToSelect}
					openRequest={props.openRequest}
				/>
			);
			break;
		}
		case 'serviceRequest': {
			render = (
				<CreateMaintenanceRequest
					open={props.open}
					history={props.history}
					type='Service Request'
					backToSelect={backToSelect}
					openRequest={props.openRequest}
				/>
			);
			break;
		}
		default: {
			render = (
				<SelectType
					user={user}
					select={setPage}
					openRequest={props.openRequest}
				/>
			);
			break;
		}
	}

	return (
		<Modal
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={props.open}
			onHide={props.openRequest}
			id='jobRequestModal'
		>
			{render}
		</Modal>
	);
}
function SelectType(props) {
	const select = type => {
		props.select(type);
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>Select Request Type</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Button
					onClick={() => select('maintenanceRequest')}
					type='primary'
					className='rqTypeBtns'
				>
					Maintenance Request
				</Button>
				<Button
					onClick={() => select('serviceRequest')}
					type='primary'
					className='rqTypeBtns'
				>
					Service Request
				</Button>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.openRequest}>Close</Button>
			</Modal.Footer>
		</>
	);
}

export default JobRequest;
