import React from 'react';
import { Button } from 'antd';
import { Modal } from 'react-bootstrap';

/* Modal for uploading new profile picture */

class ChangeImageModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { selectedFile: null, filePreview: null };
		this.fileInput = React.createRef();
	}
	selectFile = files => {
		this.setState({ selectedFile: files });
	};
	readURL = input => {
		this.setState({ selectedFile: input[0] });
		if (input && input[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				document
					.getElementById('previewAva')
					.setAttribute('src', e.target.result);
			};
			reader.readAsDataURL(input[0]); // convert to base64 string
		}
	};
	render() {
		return (
			<>
				<Modal.Header>Change Profile Image</Modal.Header>
				<Modal.Body className='text-center'>
					<div className='ant-avatar previewimg'>
						<img
							src={
								this.props.currImg
									? this.props.currImg
									: require('../../../../images/profileExample1.PNG')
							}
							id='previewAva'
						/>
					</div>
					<br />
					<label for='myfile' className='ant-btn ant-btn-primary mt-4'>
						<span>Upload Profile Picture</span>
					</label>
					<input
						style={{ display: 'none' }}
						type='file'
						id='myfile'
						name='myfile'
						accept='image/gif, image/jpeg, image/jpg, image/png'
						ref={this.fileInput}
						onChange={e => this.readURL(e.target.files)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onHide}>Cancel</Button>
					<Button
						type='primary'
						onClick={() => {
							this.props.changeProfilePicture(this.state.selectedFile);
							this.props.onHide();
						}}
					>
						Submit Changes
					</Button>
				</Modal.Footer>
			</>
		);
	}
}

export default ChangeImageModal;
