import React from "react";
import { Form } from "react-bootstrap";
import axios from "axios";


import { Select, Upload, Button, message } from "antd";
const cancelToken = axios.CancelToken.source();
const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
class GeneralInfoTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      streetAddress: "",
      city: "",
      province: "",
      postalCode: "",
      DOB: "",
    };
  }

  //Submitting form
  submitForm = () => {
    axios
      .post("/registerID", {
        phoneNumber: this.state.phoneNumber,
        streetAddress: this.state.streetAddress,
        city: this.state.city,
        province: this.state.province,
        postalCode: this.state.postalCode,
        DOB: this.state.DOB,
      })
      .then((response) => {
        //Say its complete!
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div>
        <h3 className="title">Register Info</h3>
        <p> Please enter the folling infomation about yourself</p>
        <br />
        <Form>
          <Form.Group>
            <Form.Label className="form-label">Home Address</Form.Label>
            <Form.Control
              required
              className="form-text"
              name="streetAddress"
              type="text"
              defaultValue={this.state.streetAddress}
              onChange={(e) => this.setState({ streetAddress: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label">City</Form.Label>
            <Form.Control
              required
              className="form-text"
              name="city"
              type="text"
              defaultValue={this.state.city}
              onChange={(e) => this.setState({ city: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label">Postal Code</Form.Label>
            <Form.Control
              required
              className="form-text"
              name="postalCode"
              type="text"
              defaultValue={this.state.postalCode}
              onChange={(e) => this.setState({ postalCode: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label d-block">Province</Form.Label>
            <Select
              name="province"
              defaultValue="------------------"
              onChange={(e) => this.setState({ province: e })}
            >
              <Option value="AB"> Alberta </Option>
              <Option value="BC"> British Columbia </Option>
              <Option value="MB"> Manitoba </Option>
              <Option value="NB"> New Brunswick </Option>
              <Option value="NL"> Newfoundland and Labrador </Option>
              <Option value="NT"> Northwest Territories </Option>
              <Option value="NS"> Nova Scotia </Option>
              <Option value="NU"> Nunavut </Option>
              <Option value="ON"> Ontario </Option>
              <Option value="PE"> Prince Edward Island </Option>
              <Option value="QC"> Quebec </Option>
              <Option value="SK"> Saskatchewan </Option>
              <Option value="YT"> Yukon Territory </Option>
            </Select>
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label">Phone Number</Form.Label>
            <Form.Control
              required
              className="form-text"
              name="phoneNumber"
              type="number"
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              defaultValue={this.state.phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label">Date of Birth</Form.Label>
            <Form.Control
              required
              name="DOB"
              type="date"
              defaultValue={this.state.DOB}
              onChange={(e) => this.setState({ DOB: e.target.value })}
            />
          </Form.Group>
          <br></br>
          <Button type="primary" onClick={this.submitForm}>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

//----------------------------------------------------PIC ID UPLOAD STARTS HERE
class GeneralPicID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picID: null,
    };
  }

  //Adding file to the state
  onFileChange = (event) => {
    this.setState({ picID: event.target.files[0] });
  };

  //When user pressed submit
  onFileUpload = () => {
    const formData = new FormData();

    formData.append("picID", this.state.picID);
    const config = {
      headers: {
        "content-type": "multipart/form-data;",
      },
    };

    axios
      .post("/registerSelfie", formData, config)
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  //To print info about the file
  fileData = () => {
    if (this.state.picIDf) {
      return <p> File Name: {this.state.picID.name}</p>;
    }
  };

  handleChange = (fileName) => {
    this.setState({ [fileName]: null });
  };
  handleUpload = (fileName, displayName, file) => {
    getBase64(file.file, (preview) => {
      file.file.preview = preview;
      file.file.fileName = fileName;
      file.file.displayName = displayName;
      file.file.status = "done";
      this.setState({ [fileName]: file.file });
    });
  };

  render() {
    return (
      <div>
        <h3 className="title">Selfie for Identity Verificaiton</h3>
        <Form>
          <Form.Group>
            <p>
              {" "}
              Please take a front-facing selfie of yourself to verify your
              identity.
            </p>
            <Upload
              name="credentialsUpload"
              customRequest={(file) =>
                this.handleUpload("picID", "Front of ID", file)
              }
              listType="picture-card"
              showUploadList={false}
              className="cred-uploader"
              beforeUpload={beforeUpload}
              onChange={() => this.handleChange("picID")}
            >
              {this.state.picID !== null ? (
                <img
                  alt="preview"
                  className="imagePreview"
                  src={this.state.picID.preview}
                />
              ) : (
                "Upload Selfie"
              )}
            </Upload>
            <Button type="primary" onClick={this.onFileUpload}>
              {" "}
              Upload Selfie
            </Button>
            <br />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

//Basically copy and paste of the other one
class GeneralGovID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picIDf: null,
      picIDb: null,
    };
  }

  onFileChange = (event) => {
    this.setState({ picIDf: event.target.files[0] });
  };

  onFileUpload = () => {
    const data = new FormData();
    data.append("picIDf", this.state.picIDf);
    data.append("picIDb", this.state.picIDb);

    const config = {
      headers: {
        "content-type": "multipart/form-data;",
      },
    };

    axios
      .post("/registerPicID", data, config)
      .then((response) => {
      })
      .catch((err) => console.log(err));
  };

  fileData = () => {
    if (this.state.picIDf) {
      return <p> File Name: {this.state.picIDf.name}</p>;
    }
  };

  handleUpload = (fileName, displayName, file) => {
    getBase64(file.file, (preview) => {
      file.file.preview = preview;
      file.file.fileName = fileName;
      file.file.displayName = displayName;
      file.file.status = "done";
      this.setState({ [fileName]: file.file });
    });
  };

  handleChange = (fileName) => {
    this.setState({ [fileName]: null });
  };
  render() {
    return (
      <div>
        <h3 className="title">Register GeneralGovID</h3>
        <p> Please take a picture of your governemnt id</p>
        <p>-- Add reason why so people aren't suspicious --</p>

        <br />
        <Form>
          <Form.Group className="d-inline-block">
            <Form.Label className="form-label" name="picIDf">
              Front
            </Form.Label>
            <Upload
              name="credentialsUpload"
              customRequest={(file) =>
                this.handleUpload("picIDf", "Front of ID", file)
              }
              listType="picture-card"
              showUploadList={false}
              className="cred-uploader"
              beforeUpload={beforeUpload}
              onChange={() => this.handleChange("picIDf")}
            >
              {this.state.picIDf !== null ? (
                <img
                  alt="preview"
                  className="imagePreview"
                  src={this.state.picIDf.preview}
                />
              ) : (
                "Upload Front of Government ID"
              )}
            </Upload>
          </Form.Group>
          <Form.Group className="d-inline-block">
            <Form.Label className="form-label">Back</Form.Label>
            <Upload
              name="credentialsUpload"
              customRequest={(file) =>
                this.handleUpload("picIDb", "Back of ID", file)
              }
              listType="picture-card"
              showUploadList={false}
              className="cred-uploader"
              beforeUpload={beforeUpload}
              onChange={() => this.handleChange("picIDb")}
            >
              {this.state.picIDb !== null ? (
                <img
                  alt="preview"
                  className="imagePreview"
                  src={this.state.picIDb.preview}
                />
              ) : (
                "Upload Front of Government ID"
              )}
            </Upload>
          </Form.Group>
          <br />

          <Button type="primary" onClick={this.onFileUpload}>
            {" "}
            Upload Government ID{" "}
          </Button>
          <br></br>
        </Form>
      </div>
    );
  }
}

export { GeneralInfoTask, GeneralPicID, GeneralGovID };
