import React from 'react';
import {Card,Table} from 'antd'
class Transactions extends React.Component{
    render(){
      let rows = [],amount=0;
      for(let i of this.props.transactions){
        rows.push(<TransactionRow transaction={i} />)
        amount += parseFloat(i.amount);
      }
      amount = amount.toFixed(2);
      return(
        <Card title={
          <div>
            <div className=" fs-18 g-text mr-4">Transactions</div>        
            <div className='fs-14'>Total Expenses: ${amount}</div>
          </div>
      } bordered={false}>

          <Table 
            columns={transCols}
            dataSource={this.props.transactions}
            rowKey={"key"}
            size='small'
            pagination={false}
          />
        </Card>
      )
    }
  }
  class TransactionRow extends React.Component{
    render(){
      let tax;
      if(this.props.transaction.tax === 'Tax Included') tax = "Yes"; else tax = 'No';
      return(
        <tr>
          <td>{this.props.transaction.receipt_id}</td>
          <td>${this.props.transaction.amount}</td>
          <td>{tax}</td>
          <td>{this.props.transaction.vendor_name}</td>
          <td>{this.props.transaction.comment}</td>
        </tr>
      )
    }
  }
  

  const transCols = [
    {
      title:"Date",
      dataIndex:"date",
      width:'20%'
    },
    {
      title:"Vendor",
      dataIndex:"vendor",
      width:"20%"
    },
    {
      title:'Amount',
      dataIndex:'amount',
      width:'20%',
      render: (a) => '$'+a
    },
    {
      title:'Receipt ID',
      dataIndex:'receipt_id',
      width:'20%',
    },
    {
      title:'Details',
      dataIndex:'comment',
      width:'30%',
    },
  ]



export default Transactions;