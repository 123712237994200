import React, { useState } from 'react';
import moment from 'moment';
import { Modal, ModalTitle, DropdownButton, Form, Col } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Link } from 'react-router-dom';
import { Button, DatePicker, Dropdown, message, Checkbox, Menu } from 'antd';
import {
	CloseCircleOutlined,
	CheckCircleOutlined,
	InfoCircleOutlined,
	DownOutlined
} from '@ant-design/icons';
import { MaintenanceRequest } from '../MaintenanceRequest/MaintenanceRequest';
import { PropertyMaintenanceRequest } from '../MaintenanceRequest/PropertyMaintenanceRequest';
import axios from 'axios';

import './eventCalendar.css';
import LoadScreen from '../../Components/LoadScreen';
import SignatureCanvas from 'react-signature-canvas';
import { ColorRow } from '../../Components/CalColorsGrid';

import { UserStateContext } from '../../Context/UserContext';
const cancelToken = axios.CancelToken.source();

const sigPadStyle = {
	display: 'block',
	borderRadius: '.25rem',
	border: '1px solid #ced4da',
	width: '374.5px',
	height: '150px',
	margin: 'auto'
};

class EventCalendarLayout extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super();
		this.sigCanvas = React.createRef();
		this.state = {
			eventclickmodal: false,
			editEventClick: false,
			currEvent: { start: null, end: null },
			openColorInfo: false,
			notice: null,
			clickedEvent: null,
			allEvents: null,
			events: null,
			maintenanceWorkers: null,
			listedProperties: null,
			selectedFilters: [],
			selectedPropertyFilters: []
		};
	}

	clearSig = () => {
		this.sigCanvas.current.clear();
	};

	componentDidMount() {
		document.title = 'Calendar';
		this.getCalData();
		this.getPropertyData();
	}

	getCalData = () => {
		axios.get('/eventCalendar/eventcalendar').then(response => {
			// console.log(response.data.maintenanceList);
			// console.log(response.data.eventsArr);
			this.setState({
				events: response.data.eventsArr,
				allEvents: response.data.eventsArr,
				maintenanceWorkers: response.data.maintenanceList,
				selectedFilters: response.data.maintenanceList.map(worker => {
					return worker.id;
				}),
				selectedPropertyFilters: response.data.eventsArr.map(property => {
					return property.unit_id;
				})
			});
			return;
		});
	};

	getPropertyData = () => {
		axios.get('/eventCalendar/getPropertyDetails').then(res => {
			// console.log(res.data.propertyDetails);
			this.setState({ listedProperties: res.data.propertyDetails });
		});
	};

	seeEvent = () => {
		this.setState({ eventclickmodal: !this.state.eventclickmodal });
	};

	handleEventClick = ({ event }) => {
		this.setState({ clickedEvent: event.extendedProps }, this.seeEvent);
	};

	handleEvents = e => {
		//Change: obj w/ params {years:int, months:int, days:int, milliseconds:int}
		// console.log(e);
		this.setState({
			editEventClick: !this.state.editEventClick,
			currEvent: e.event
		});
	};

	closeEditEvent = () => {
		this.setState({ editEventClick: !this.state.editEventClick });
	};

	closeColorsInfo = () => {
		this.setState({ openColorInfo: !this.state.openColorInfo });
	};

	selectNotice = e => {
		this.setState({ notice: e.target.value });
	};

	reschedule = () => {
		let body = {
			job_id: this.state.currEvent.job_id,
			startTime: new Date(this.state.currEvent.start).getTime(),
			endTime: new Date(this.state.currEvent.end).getTime(),
			sigPad: this.sigCanvas.current.toDataURL(),
			noticeType: this.state.notice,
			event: this.state.currEvent,
			//propertyid: this.state.currEvent.propertyid,
			title: this.state.currEvent.title,
			host: window.location.host
		};
		axios
			.post('/eventCalendar/rescheduleEvent', body)
			.then(() => {
				message.success('Event has been rescheduled');
			})
			.catch(err => console.log(err));
		this.closeEditEvent();
		this.getCalData();
	};

	toggleEmployeeFilter = (filter, toggleOn) => {
		if (toggleOn) {
			if (
				filter == 'All' ||
				this.state.selectedFilters.length ===
					this.state.maintenanceWorkers.length - 1
			) {
				this.setState({
					events: this.state.allEvents,
					selectedFilters: this.state.maintenanceWorkers.map(worker => {
						return worker.id;
					})
				});
			} else {
				this.setState({ events: null });
				let selectedFilters = this.state.selectedFilters;
				selectedFilters.push(filter);
				let filterEvents = this.state.allEvents.filter(event => {
					return event.employees.some(employee => {
						return selectedFilters.includes(employee.employee_id);
					});
					return false;
				});

				this.setState({
					events: filterEvents,
					selectedFilters: selectedFilters
				});
			}
		} else {
			if (filter == 'All') this.setState({ events: [], selectedFilters: [] });
			else {
				this.setState({ events: null });
				let selectedFilters = this.state.selectedFilters.filter(mainFilter => {
					return mainFilter !== filter;
				});
				let filterEvents = this.state.allEvents.filter(event => {
					return event.employees.some(employee => {
						return selectedFilters.includes(employee.employee_id);
					});
					return false;
				});
				this.setState({
					events: filterEvents,
					selectedFilters: selectedFilters
				});
			}
		}
	};

	togglePropertyFilter = (filter, toggleOn) => {
		let listedProperty = this.state.listedProperties.map(
			listedProperty => listedProperty.unit_id
		);
		if (toggleOn) {
			if (
				filter == 'All' ||
				this.state.selectedPropertyFilters.length ===
					this.state.listedProperties.length - 1
			) {
				this.setState({
					events: this.state.allEvents,
					selectedPropertyFilters: this.state.listedProperties.map(property => {
						return property.unit_id;
					})
				});
			} else {
				this.setState({ events: null });
				let selectedPropertyFilters = this.state.selectedPropertyFilters;
				selectedPropertyFilters.push(filter);

				let filterPropertyEvents = this.state.allEvents.filter(property => {
					return selectedPropertyFilters.includes(
						(listedProperty.unit_id = property.unit_id)
					);
				});

				this.setState({
					events: filterPropertyEvents,
					selectedPropertyFilters: selectedPropertyFilters
				});
			}
		} else {
			if (filter == 'All')
				this.setState({ events: [], selectedPropertyFilters: [] });
			else {
				this.setState({ events: null });
				let selectedPropertyFilters = this.state.selectedPropertyFilters.filter(
					mainFilter => {
						return mainFilter !== filter;
					}
				);
				let filterPropertyEvents = this.state.allEvents.filter(property => {
					return selectedPropertyFilters.includes(
						(listedProperty.unit_id = property.unit_id)
					);
				});
				this.setState({
					events: filterPropertyEvents,
					selectedPropertyFilters: selectedPropertyFilters
				});
			}
		}
	};

	render() {
		// console.log(this.context);
		if (this.state.events === null) return <LoadScreen className='m-auto' />;
		//TODO: fix job request modal for mobile view
		return (
			<div>
				<div
					sm={2}
					style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
				>
					<div sm={2} style={{ margin: 'auto' }} title='Status colors'>
						<Button onClick={this.closeColorsInfo}>
							<InfoCircleOutlined />
						</Button>
					</div>
					{this.props.userType === 'Manager' ? (
						<Form.Group controlId='exampleForm.ControlSelect1'>
							<Col sm={2} style={{ margin: 'auto' }}>
								<Dropdown
									trigger='click'
									placement='bottomCenter'
									overlay={
										<Menu style={{ padding: '5px' }}>
											<Menu.Item style={{ cursor: 'default' }} disabled key={0}>
												<Checkbox
													checked={
														this.state.maintenanceWorkers.length ==
														this.state.selectedFilters.length
													}
													onChange={e =>
														this.toggleEmployeeFilter('All', e.target.checked)
													}
												>
													All
												</Checkbox>
											</Menu.Item>
											{this.state.maintenanceWorkers.map((worker, index) => (
												<Menu.Item
													style={{ cursor: 'default' }}
													disabled
													key={index + 1}
												>
													<Checkbox
														checked={this.state.selectedFilters.includes(
															worker.id
														)}
														onChange={e =>
															this.toggleEmployeeFilter(
																worker.id,
																e.target.checked
															)
														}
													>
														{worker.fname} {worker.lname}
													</Checkbox>
												</Menu.Item>
											))}
										</Menu>
									}
								>
									<Button>
										Select Employee
										<DownOutlined />
									</Button>
								</Dropdown>
								<Dropdown
									trigger='click'
									placement='bottomCenter'
									overlay={
										<Menu style={{ padding: '5px' }}>
											<Menu.Item style={{ cursor: 'default' }} disabled key={0}>
												<Checkbox
													checked={
														this.state.listedProperties.length ==
														this.state.selectedPropertyFilters.length
													}
													onChange={e =>
														this.togglePropertyFilter('All', e.target.checked)
													}
												>
													All
												</Checkbox>
											</Menu.Item>
											{this.state.listedProperties.map((property, index) => (
												<Menu.Item
													style={{ cursor: 'default' }}
													disabled
													key={index + 1}
												>
													<Checkbox
														checked={this.state.selectedPropertyFilters.includes(
															property.unit_id
														)}
														onChange={e =>
															this.togglePropertyFilter(
																property.unit_id,
																e.target.checked
															)
														}
													>
														{`${property.propertynumber}${
															property.unit_name === 'default'
																? ''
																: property.unit_name
														}
															${property.propertyStreet} `}
													</Checkbox>
												</Menu.Item>
											))}
										</Menu>
									}
								>
									<Button style={{ marginTop: 5 }}>
										Select Property
										<DownOutlined />
									</Button>
								</Dropdown>
							</Col>
						</Form.Group>
					) : null}
				</div>

				<FullCalendar
					//timeZone='America/St_Johns'
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					headerToolbar={{
						right: 'today,prev,next',
						center: 'title',
						left: 'dayGridMonth,timeGridWeek,timeGridDay'
					}}
					initialView='dayGridMonth'
					editable={this.props.userType === 'Manager'}
					selectable
					eventStartEditable={this.props.userType === 'Manager'}
					eventResizableFromStart
					dayMaxEvents={5}
					eventDragStart={this.handleDragStart}
					eventDrop={this.handleEvents}
					eventResize={this.handleEvents}
					events={this.state.events.map((event, index) => ({
						title: event.title,
						start: new Date(event.start),
						end: new Date(event.end),
						allDay: false,
						id: index,
						backgroundColor: getEventBackgroundColor(event.status),
						borderColor: getEventBorderColor(
							event.overdue,
							event.status,
							event.end
						),
						textColor: 'black',
						display: 'block',
						job_id: event.job_id,
						extendedProps: event
					}))}
					eventClick={this.handleEventClick}
				/>

				<Modal
					show={this.state.eventclickmodal}
					onHide={this.seeEvent}
					centered
					size='lg'
				>
					{this.state.clickedEvent ? (
						<>
							<Modal.Header closeButton>
								<div className='fs-18 g-text d-inline mr-2'>
									{this.state.clickedEvent.title}{' '}
								</div>
								<DatePicker.RangePicker
									inputReadOnly
									allowClear={false}
									open={false}
									className='mr-2'
									value={[
										moment(this.state.clickedEvent.start),
										moment(this.state.clickedEvent.end)
									]}
								/>
							</Modal.Header>
							<Modal.Body
								style={{ padding: 0, minHeight: '300px', maxHeight: '80vh' }}
							>
								<OpenEventModal
									currEvent={this.state.clickedEvent}
									onHide={this.seeEvent}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={this.seeEvent}>Close</Button>
								{this.context.user.type === 'Manager' ||
								this.context.user.type === 'Vendor' ? (
									<Link
										to={
											'/maintenance/request/' + this.state.clickedEvent.job_id
										}
									>
										<Button>Go To Full Request</Button>
									</Link>
								) : this.context.user.type === 'Owner' ? (
									<Link
										to={{
											pathname: '/propertyMaintenance/request/propertyJR',
											state: {
												job_id: this.state.clickedEvent.job_id,
												property_id: this.state.clickedEvent.property_id
											}
										}}
									>
										<Button>Go To Full Request</Button>
									</Link>
								) : (
									<Link
										to={{
											pathname: '/unitMaintenance/request/propertyUnitJR',
											state: {
												unit_id: this.state.clickedEvent.unit_id,
												job_id: this.state.clickedEvent.job_id
											}
										}}
									>
										<Button>Go To Full Request</Button>
									</Link>
								)}
								{/* <Link
									to={{
										pathname: '/maintenance/request/',
										state: { job_id: this.state.clickedEvent.job_id }
									}}
								>
									<Button>Go To Full Request</Button>
								</Link> */}
							</Modal.Footer>
						</>
					) : (
						''
					)}
				</Modal>

				{/* Edit event modal*/}
				<Modal
					show={this.state.editEventClick}
					onHide={this.closeEditEvent}
					centered
					size='lg'
				>
					<>
						<Modal.Header closeButton></Modal.Header>
						<Modal.Body className='assignVendorMBody text-center'>
							<>
								<Form>
									<Form.Group>
										<Form.Label>Event Time Range</Form.Label>
										<br />
										<DatePicker.RangePicker
											showTime
											//size={ width > 760 ? "large" : "small"}
											inputReadOnly
											value={[
												moment(this.state.currEvent.start),
												moment(this.state.currEvent.end)
											]}
											format='YYYY-MM-DD h:mm a'
											//onOk={(value) => setTime(value)}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Select the Notice</Form.Label>
										<Form.Control
											as='select'
											onChange={this.selectNotice}
											className='width50 m-auto'
										>
											<option disabled hidden value={''}>
												Select Vendor
											</option>
											<option key='notice' value='notice'>
												Notice to enter
											</option>
											<option key='consent' value='consent'>
												Consent to enter
											</option>
											<option key='emergency' value='emergency'>
												Emergency
											</option>
										</Form.Control>
									</Form.Group>

									<Form.Group>
										<Form.Label>Draw Signature</Form.Label>
										<div style={sigPadStyle}>
											<SignatureCanvas
												penColor='black'
												canvasProps={{ width: 374.5, height: 150 }}
												ref={this.sigCanvas}
											/>
										</div>
										<Button className='sigButton' onClick={this.clearSig}>
											Clear Signature
										</Button>
									</Form.Group>
								</Form>
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={this.closeEditEvent}>Close</Button>
							<Button type='primary' onClick={this.reschedule}>
								Schedule Work
							</Button>
						</Modal.Footer>
					</>
				</Modal>

				{/*Event Colors Modal */}
				<Modal
					show={this.state.openColorInfo}
					onHide={this.closeColorsInfo}
					centered
					size='lg'
				>
					<>
						<Modal.Header closeButton>
							{' '}
							Status and their events colors
						</Modal.Header>
						<Modal.Body className='assignVendorMBody text-center'>
							<ColorRow cols={['Status', 'Color', 'Border']} />
							<ColorRow
								cols={['Job Request', 'Light Salmon', 'Light Salmon']}
							/>
							<ColorRow cols={['Scheduling', 'Khaki', 'Khaki']} />
							<ColorRow
								cols={['Work Order - Scheduled', 'Light Blue', 'Blue']}
							/>
							<ColorRow
								cols={['Work Order - Work Done', 'Light Blue', 'Green']}
							/>
							<ColorRow
								cols={['Work Order - Review', 'Light Blue', 'Dark Blue']}
							/>
							<ColorRow cols={['Overdue', 'Pink', 'Red']} />
							<ColorRow cols={['Invoicing', 'Green', 'Green']} />
							<ColorRow cols={['Complete', 'Light Grey', 'Light Grey']} />
							<ColorRow cols={['Rejected', 'Light Grey', 'Light Grey']} />
							<ColorRow cols={['Cancelled', 'Light Grey', 'Black']} />
							<ColorRow cols={['On Hold', 'Dark Grey', 'Red']} />
						</Modal.Body>
					</>
				</Modal>
			</div>
		);
	}
}

const getEventBackgroundColor = status => {
	let event = eventBackgroundColor.find(c => c.status === status) || {
		color: 'steelBlue'
	};
	return event.color || 'steelBlue';
};
const getEventBorderColor = (overdue, status, end) => {
	if (
		overdue ||
		(status == 'Work Order - Scheduled' &&
			new Date().getTime() - 86400000 > end)
	) {
		return 'red';
	} else {
		let event = eventBackgroundColor.find(c => c.status === status) || {
			border: 'blue'
		};
		return event.border;
	}
};

const eventBackgroundColor = [
	{ status: 'Job Request', color: 'LightSalmon', border: 'LightSalmon' },
	{ status: 'Scheduling', color: 'khaki', border: 'khaki' },
	{ status: 'Work Order - Scheduled', color: 'LightBlue', border: 'blue ' },
	{ status: 'Work Order - Work Done', color: 'LightBlue', border: 'Green' },
	{ status: 'Work Order - Review', color: 'LightBlue', border: 'darkBlue' },
	{ status: 'Overdue', color: 'Pink', border: 'Red' },
	{ status: 'Invoicing', color: 'Green', border: 'Green' },
	{ status: 'Complete', color: 'lightGrey', border: 'lightGrey' },
	{ status: 'Rejected', color: 'lightGrey', border: 'lightGrey' },
	{ status: 'Cancelled', color: 'lightGrey', border: 'black' },
	{ status: 'On Hold', color: 'darkGrey', border: 'red' }
];

const OpenEventModal = props => {
	const { currEvent } = props;
	const [request, setRequest] = useState(null);
	const getRequestDetails = () => {
		axios
			.post('/eventCalendar/getRequestDetails', { job_id: currEvent.job_id })
			.then(response => {
				setRequest(response.data.request);
			});
	};

	return <MaintenanceRequest job_id={currEvent.job_id} mobile={true} />;
};

export default EventCalendarLayout;
