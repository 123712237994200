import React from 'react';

class CompleteDisplay extends React.Component {
    
  
  
  render(){
      let troubleshoot=[],actions=[],tr,action,materials;
      let workOrder = this.props.complete.workOrder;
      for(let i of this.props.complete.sessions){
        let time = new Date(i.time).toString();
        time = time.slice(4,21).replace(/ /g,'-');
        if(i.tr_description !== "")
            troubleshoot.push({time:time,troubleshoot:i.tr_description});
        if(i.action_desc !== "" && i.action_desc != null)
            actions.push({action_desc:i.action_desc,action_time:time})
      }
      for(let i of this.props.complete.labour){

      }      
      if(troubleshoot.length > 0){
        tr = <div><b>Troubleshooted Problems</b><br/><TroubleshootDisplay troubleshoot={troubleshoot} /></div>;
      }
      if(actions.length > 0){
        action = <div><b>Actions Taken</b><br/><ActionDisplay actions={actions} /></div>;
      }
      if(this.props.complete.materials.length > 0){
        materials = <MaterialDisplay materials={this.props.complete.materials} workOrder={workOrder}/>;
      } else materials = <div>No Materials Entered.</div>

      return(
  
        <div id='container'>
          <div id='mainDiv'>
            <div id='leftDisplay'>
              {tr}
              {action}
            </div>
            <div id='rightDisplay'>
              {materials}
            </div>
          </div>
        </div>
  
      )
    }
}


class TroubleshootDisplay extends React.Component {

    render(){ 
        let rows=[];
        for(let i of this.props.troubleshoot){
        rows.push( <div><div className='timeDisplay d-inline'>{i.time} - </div><div className='dataFont d-inline'>{i.troubleshoot}</div></div>)
        }
        return (

            <div id='TRdisplay'>
                {rows}
            </div>

        )
    }
}

class ActionDisplay extends React.Component {

    render(){ 
        let rows=[];
        for(let i of this.props.actions){
            rows.push( <div><div className='timeDisplay d-inline'>{i.action_time} - </div><div className='dataFont d-inline'>{i.action_desc}</div></div>)
        }
        return (

            <div id='TRdisplay'>
                {rows}
            </div>

        )
    }
}

class MaterialDisplay extends React.Component {
  
  render() {
    let rows = [];
    for(let i of this.props.materials){
      rows.push(<Material material={i} />)
    }
    let cost = this.props.workOrder.total_material_cost.toFixed(2);
    return (
      <div id='materialsDiv'>    
        <b>Materials</b><br/>    
        <table id='materialTable'>
          <MaterialHeader/>
          <tbody>
          {rows}
          </tbody>
        </table>
        <div className='borderLine'></div>
        <div className='fs-14'><b>Total Material Cost: ${cost}</b></div>
      </div>
      )
  }
}

class Material extends React.Component {

  render() {
    let measurement,unit;
    if(this.props.material.measurement === null) measurement = '-'; else measurement = this.props.material.measurement;
    if(this.props.material.unit == null) unit = '-'; else unit = this.props.material.unit;
    return (
      <tr>
        <td>{this.props.material.material_name}</td>
        <td>{this.props.material.quantity}</td>
        <td>{measurement}</td>
        <td>{unit}</td>
        <td>${this.props.material.cost}</td>
        <td>{this.props.material.store}</td>
      </tr>
    )
  }

}

function MaterialHeader(){
  return (

      <thead>
        <tr>
          <th style={{width:'20%'}}>Material</th>
          <th style={{width:'12%'}}>Quantity</th>
          <th style={{width:'18%'}}>Measurement</th>
          <th style={{width:'20%'}}>Unit</th>
          <th style={{width:'15%'}}>Cost</th>
          <th style={{width:'25%'}}>Store</th>
        </tr>
      </thead>

  ) 
} 
function StandardText(props){
    
    return(
    <div className='fixed mr-4 mb-2' >
      <div className='smallLabel'>{props.label}</div>
      <div className='dataFont'>{props.text}</div>
    </div> 
    )
  }
export default CompleteDisplay;