import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import EditUserDetails from '../../IntakeRequest/actions/EditUserDetails';

import { Card, Avatar, Modal } from 'antd';
//Logo and name
const CompanyInfo = ({ company }) => {
	const [editDetails, setEditDetails] = useState(false);
	const openEditModal = () => setEditDetails(!editDetails);

	return (
		<div className='infoShell cardShell text-center'>
			{company.imageurl === 'avatar_default.png' ? (
				<Avatar size={145}>{company.fname[0]}</Avatar>
			) : (
				<Avatar size={145} src={company.imageurl} />
			)}
			<h3 className='mt-2'>
				<Card
					title={
						<div className=' fs-16 g-text'>
							{company.fname} {company.lname}
							<EditOutlined onClick={openEditModal}></EditOutlined>
						</div>
					}
					bordered={false}
				>
					<div className='g-text-sm' htmlFor='userEmail'>
						Email: {company.email}
					</div>

					<div className='g-text-sm' htmlFor='userPhone'>
						Phone Number: {company.phone}
					</div>

					<div className='g-text-sm' htmlFor='userPhone'>
						HST Registration #: {company.hst_number}
					</div>
					<div className='g-text-sm' htmlFor='userPhone'>
						Tax Rate: {company.tax_rate}%
					</div>

					<div className='g-text-sm' htmlFor='userhomeStreet'>
						Address: {company.homeNumber}&nbsp;
						{company.homeStreet},&nbsp;
						{company.homeCity},&nbsp;
						{company.homeProvince},&nbsp;
						{company.homePostalcode}
					</div>
				</Card>
			</h3>
			<Modal
				title='Edit contact details'
				centered
				width='700px'
				open={editDetails}
				onCancel={openEditModal}
				footer={false}
			>
				{editDetails ? (
					<EditUserDetails
						user={company}
						onCancel={openEditModal}
						fname={company.fname}
						lname={company.lname}
						email={company.email}
						phone={company.phone}
						homeNumber={company.homeNumber}
						street={company.homeStreet}
						city={company.homeCity}
						postalCode={company.homePostalcode}
						province={company.homeProvince}
						taxRate={company.tax_rate}
						hst_number={company.hst_number}
						userID={company.id}
					/>
				) : (
					<div></div>
				)}
			</Modal>
		</div>
	);
};

export default CompanyInfo;
