import React, { Component } from "react";
import moment from "moment";
import { Button,DatePicker, Form, Input, Select, message } from "antd";
import WorkOrderContext from '../WorkOrderContext';
import {WorkOrderFooter} from '../WorkOrder'
import axios from "axios";
const cancelToken = axios.CancelToken.source();

const {TimePicker} = DatePicker

class Entry extends Component {
  static contextType = WorkOrderContext;
  constructor(props) {
    super();
    this.state = {
      authType: "",
      timeIn: moment(),
      tenant: "",
      otherTenant: false,
      errormsg: "",
      date:moment()
    };
  }
  
  componentDidMount = () => {
    if(this.context.entryDetails){
      this.setState({
        authType:this.context.entryDetails.authType,
        timeIn:this.context.entryDetails.timeIn,
        tenant:this.context.entryDetails.tenant,
        date:this.context.entryDetails.date,
      })
    }
  }

  //post entry data to the server
  postEntryData = () => {
    axios.post("/workOrder/add/session", {wo_id: this.context.jobData.wo_id, auth_type: this.context.entryData.authType,time_in: this.context.entryData.timeIn, tenant_name: this.context.entryData.tenant})
    .then((res, err)=>{
      if (err) {
        this.setState({
          errormsg:
            "Error starting session. Please try again.",
        });
        return false;
      } else {
        this.context.startWorkOrder();
        return true;
      }
    });
  }

  //Save entry and go back to menu
  saveEntry = (e) => {
    this.context.updateEntryDetails(
      e.authType,
      e.timeIn,
      e.tenant,
      this.state.date
    );
    let postResult = this.postEntryData();
    if (postResult)
      this.context.goToMainMenu();
  }

  updateDate = (e) => this.setState({date:e});
  updateTime = (e) => this.setState({ timeIn: e });
  
  updateTenant = (e) => {
    if (e.target.value === "Other") {
      this.setState({ otherTenant: true });
      return;
    } else {
      let curr = this.props.tenantList.findIndex(
        (x) => x.id === parseInt(e.target.value)
      );
      this.setState({
        tenant: {
          id: this.props.tenantList[curr].id,
          name: this.props.tenantList[curr].name,
        },
      });
      this.setState({ otherTenant: false });
    }
  }
  updateAuth = (e) => {
    this.setState({ authType: e.target.value });
  }

  selectOtherTenant = (e) => {
    this.setState({ tenant: { id: null, name: e.target.value } });
  };
  selectTenant = (e) => {
    this.setState({otherTenant:true})
  }
  verify = () => {
    if (this.state.authType === null) {
      this.setState({
        errormsg:
          "Please select the type of authority you have to enter the property.",
      });
      return false;
    } else if (
      this.state.tenant === null &&
      this.state.authType !== "No Entry Required"
    ) {
      this.setState({
        errormsg: "Please select the name of the tenant present.",
      });
      return false;
    }
    return true;
  };

  render() {
    return(
      <Form
        {...layout} 
        initialValues={this.state}
        onFinish={this.saveEntry}
      >
        <Form.Item
          name='authType'
          label='Authority Type'
        
        >
          <Select placeholder='Please select an authority type'>
            {authtypes.map((type) => (
              <Select.Option key={type} value={type}>{type}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="timeIn" label="Time" {...config}>
          <TimePicker use12Hours format={format} />
        </Form.Item>
        
        <Form.Item name='tenant' label="Tenant Present">
          <Select placeholder='Please select the tenant present'>
            {this.context.tenantList.length > 0 ? this.context.tenantList.map((tenant) => (
              <Select.Option key={tenant.id} value={tenant}>{tenant.name}</Select.Option>
            )) : "" }
            <Select.Option value={"Other"} onChange={this.selectTenant}>Other</Select.Option>
          </Select>
        </Form.Item>

        {this.state.otherTenant ? 

          <Form.Item name='tenant' label='Tenant Name'>
            <Input />
          </Form.Item>
      
      
          : ""
        } 
        <WorkOrderFooter >
          <Button type='primary' htmlType='submit'>Save Entry Details</Button>
        </WorkOrderFooter>
      </Form>
    )
  }
}

const authtypes = [
  "Notice to Enter",
  "Consent to Enter",
  "No Authority Required",
  "No Entry Required",
];
const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please select time!',
    },
  ],
};
const format = "h:mm a";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class OptionGroup extends Component {
  render() {
    return (
      <div className="fixedLine">
        <label className="txtLabel">{this.props.label}</label>
        <br />
        {this.props.body}
      </div>
    );
  }
}

export default Entry;
