import { Table } from 'antd'
import React from 'react'
class AddUnits extends React.Component{

    render(){
        return(

            <Table 
              dataSource={this.state.vendorList}
              columns={unitsColumns}
              rowKey="id"
              rowSelection={{
                type: 'checkbox',
                ...this.rowSelection,
            }}       
            />
        )
    }
}


const unitsColumns = [
    {
      title: 'Unit Name',
      dataIndex: 'name',
      render: (name,record) => record.fname+' '+(record.lname ? record.lname : "")
    },
    {
      title: 'Company',
      dataIndex:'company_id',
      render: (id) => id === 42 ? "Steady Property Management" : ""
    }
  ];


export default AddUnits