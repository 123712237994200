import React, {Component} from 'react';

class ErrorPage extends Component {
render(){
    return(
        <div className='text-center'>
            <h5>Error</h5>
            <br/>
            <h6>Sorry, Something went wrong and we cannot access this work order.</h6>
            <h6>Please try logging out and logging back in.</h6>
        </div>
    )
}
}

export default ErrorPage;
