import React from "react";
import axios from "axios";

import { Button ,Table,  Space, DatePicker, Alert, Checkbox, Select,Popconfirm} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {vendorProvinces} from '../../../StringData/VendorObjects'
import RequestContext from "../../MaintenanceRequest/RequestContext";
import LoadScreen from '../../../Components/LoadScreen'
import { Form } from "react-bootstrap";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
const cancelToken = axios.CancelToken.source();





class AddTenant extends React.Component{
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state ={
			page:"View",
			editIndex:-1,
			unitList: [],
			tenantlist : [],
			fname:"",
			lname:"",
			email:"", 
			phoneNumber: "",	
			lease_id:"",		
			id: null, 
			loading:true
		}
		this.submitform = this.submitform.bind(this)
		this.submitlist = this.submitlist.bind(this)
	}
	
	verify = () => {
		if(this.state.fname === "" || this.state.fname === null ){
			this.setState({errorMessage: "Please enter a First Name for the Tenant"});
		  return false;
		}
		else if(this.state.lname === "" || this.state.lname === null ){
			this.setState({errorMessage: "Please enter a Last Name for the Tenant"});
		  return false;
		}
		else if(this.state.email === "" || this.state.email === null ){
			this.setState({errorMessage: "Please enter a Email for the Tenant"});
		  return false;
		}
		else if(this.state.phoneNumber === "" || this.state.phoneNumber === null ){
			this.setState({errorMessage: "Please enter a Phone Number for the Tenant"});
		  return false;
		}
		else if(this.state.lease_id === "" ){
			this.setState({errorMessage: "Please select an unit"});
		  return false;
		}
		return true
	}


	componentDidMount(){
		axios.post("/intake/get/tenantIntake",{propertyid: this.context.propertyid}).then((response) => {
			this.setState({unitList : response.data.units,loading:false });  
		})
	}

	submitform(){
		if(!this.verify()) return
		if(this.state.page === 'Edit'){
			let newTenant = {fname :this.state.fname,lname :this.state.lname,email :this.state.email,phoneNumber: this.state.phoneNumber,lease_id: this.state.lease_id};
			let newList = [...this.state.tenantlist];

			newList[this.state.editIndex] = newTenant;
			this.setState({tenantlist:newList});
			
		}else{
			this.setState({ tenantlist: [...this.state.tenantlist,{fname :this.state.fname,lname :this.state.lname,email :this.state.email,phoneNumber: this.state.phoneNumber,lease_id: this.state.lease_id}]})
		}
		this.setState({fname:'',lname:'',email:'',phoneNumber:'',lease_id:'',page:"view"})
	}

	submitlist(){
		axios.post("/intake/property/add/tenants",{tenantList: this.state.tenantlist, tasks: this.context.tasks,propertyid:this.context.propertyid})
		.then((response) => {
			this.context.updateTaskList(response.data.tasks);
			this.context.updateTenants(response.data.tenants);
			this.props.onCancel();
		});
	}

	deleterow = (e) => {
		this.setState({
			tenantlist:this.state.tenantlist.filter(element  => element.email !== e.email)
		});
	}

	editRow =(e) => {
		let tenantIndex = this.state.tenantlist.findIndex((ele) => ele.email === e.email);
		let tenant = this.state.tenantlist[tenantIndex]
		this.setState({fname:tenant.fname,lname:tenant.lname,email:tenant.email,phoneNumber:tenant.phoneNumber,lease_id:tenant.lease_id,page:"Edit",editIndex:tenantIndex});
	}

	discardTenant = () => {
		this.setState({fname:'',lname:'',email:'',phoneNumber:'',lease_id:'', page:'View'})
	}

	addNewTenant = () => this.setState({page:"Add"})
	render() {
		const columns = 
		[
			{title: 'First Name',dataIndex: 'fname',key: 'fname',},  
			{title: 'Last Name',  key: 'lname',  dataIndex: 'lname'},
			{title: 'Email',      key: 'email',   dataIndex: 'email'},
			{title: "Unit", key:"lease_id", dataIndex: "lease_id", 
			render:((t,record)=>
			{
				return this.state.unitList.find( u => u.lease_id === parseInt(record.lease_id)).unit_name})},
			{
				title: 'Action',
				key: 'action',
				render: (text,record) => (
				<Space size="middle">
					<Popconfirm
					title="Delete this tenant?"
					onConfirm={() => this.deleterow(record)}
					okText="Confirm"
					cancelText="Cancel"
					>
						<DeleteOutlined/>
					</Popconfirm>
					
					<EditOutlined onClick={() => this.editRow(record)} />
				</Space> ),
			}
		]; 

		if(this.state.loading === true){
			return(<div className='text-center'><LoadScreen small /></div>)
		}

		if(this.state.page === 'Add' || this.state.page === 'Edit'){
		return(
			<div>
			<Form>
				<Form.Row>
					<Form.Group as={Col} sm={4} >
						<Form.Label>Tenant First Name</Form.Label>
						<Form.Control as="input" defaultValue = {this.state.fname} onChange={(e) => this.setState({fname:e.target.value})}/>	
					</Form.Group>
					<Form.Group as={Col} sm={4} >
						<Form.Label>Tenant Last Name</Form.Label>
						<Form.Control as="input" defaultValue = {this.state.lname}  onChange={(e) => this.setState({lname:e.target.value})}/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} >
						<Form.Label>Tenant Email</Form.Label>
						<Form.Control as="input"  defaultValue={this.state.email} onChange={(e) => this.setState({email:e.target.value})}/>
					</Form.Group>
					
					<Form.Group as={Col} sm={3} md={4}>
						<Form.Label> Phone Number </Form.Label>
						<Form.Control as='input' defaultValue={this.state.phoneNumber} 
						 onChange={(e) => this.setState({phoneNumber:e.target.value})}/>
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col} sm={2} md={3}>
					<Form.Label> Unit </Form.Label>
					<select className='form-control' defaultValue={this.state.lease_id} onChange={(e)=>this.setState({lease_id:e.target.value})}>
						<option value='' hidden disabled>Unit</option>
						{this.state.unitList.map((unit) => (

							<option key={unit.unit_id} value={unit.lease_id}>{unit.unit_name === 'default' ? `Default Unit` : `Unit ${unit.unit_name}`}</option>
							)
						)}
					</select>
					</Form.Group>	
				</Form.Row>				
				<br/>
				<br/>
				{this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}
				<div className="d-flex">
						<Button danger onClick ={this.discardTenant}> Discard Changes </Button>
					<div className="ml-auto p-2"> 
						<Button  type="primary" onClick ={this.submitform}> Save </Button>
					</div>
				</div>
			</Form>
			</div>)
		}
		else{
			return(
				<div>
				<Table columns={columns} dataSource={this.state.tenantlist} rowKey={'email'} pagination={false}/>
				<br/>
				<div className='text-right'>
				<Button onClick={this.addNewTenant} >
					New Tenant
				</Button>
				</div>
				<br/>
				<div className='mt-4 text-right'>
						<Button  type={"primary"} onClick={this.submitlist}  >
							Submit
						</Button>
				</div>

				</div>

			)
		}
	}			
}

export default AddTenant;