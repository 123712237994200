import React, { useState } from 'react';
import { Carousel, Button, Dropdown, Menu, Modal, Card } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ProfileAvatar } from '../Components/Avatar';
import Moment from 'react-moment';
import { DirectJRListing } from './MarketListing';
import useWindowDimensions from '../Context/WindowsDimensions';
import ImageUploadModal from '../Modals/ImageUploadModal/ImageUploadModal';
import axios from 'axios';
const cancelToken = axios.CancelToken.source();

export const RequestDescription = ({ request, updateImages }) => {
	return (
		<div className='requestDescription'>
			<Card
				id=''
				title={<div className='fs-18 g-text'>Request Details</div>}
				bordered={false}
				extra={null}
			>
				<ProfileAvatar
					name={request.req_fname + ' ' + request.req_lname}
					image='https://cdn3.f-cdn.com/contestentries/1376995/30494909/5b566bc71d308_thumb900.jpg'
				/>

				<div className='g-text mt-2 fs-12' id='requestDate'>
					<Moment format='[Request submitted on: ]DD/MM/YY [at] h:mma'>
						{request.time}
					</Moment>
				</div>
				<div id='requestDescription'>{request.description}</div>
				<ImageDisplay images={request.images} />
			</Card>
		</div>
	);
};
export const EditDescription = ({
	title,
	description,
	job_id,
	updateImages
}) => {
	const { height, width } = useWindowDimensions();
	const [ImageModal, setImageModal] = useState(false);
	const [menuVisable, setMenuVisable] = useState(false);
	const [descriptionModal, setDescriptionModal] = useState(false);
	const [titleModal, setTitleModal] = useState(false);
	return (
		<>
			<Dropdown
				overlay={
					<DescriptionMenu
						setImageUploadModal={() => setImageModal(!ImageModal)}
						setMenuModal={() => setMenuVisable(!menuVisable)}
						setTitleModal={() => setTitleModal(!titleModal)}
						setDescriptionModal={() => setDescriptionModal(!descriptionModal)}
					/>
				}
				trigger={'click'}
				open={menuVisable}
				onOpenChange={() => setMenuVisable(!menuVisable)}
				placement={'bottomCenter'}
			>
				<Button style={{ float: 'right' }}>
					{width > 760 ? 'Edit ' : ''} <DownOutlined />
				</Button>
			</Dropdown>

			{ImageModal ? (
				<ImageUploadModal
					visible={ImageModal}
					body={{ job_id: job_id }}
					route='/jobRequest/add/images'
					onClose={() => setImageModal(false)}
					updateImages={updateImages}
				/>
			) : (
				''
			)}
		</>
	);
};

function DescriptionMenu({
	setImageUploadModal,
	setMenuModal,
	setTitleModal,
	setDescriptionModal
}) {
	return (
		<Menu onClick={setMenuModal}>
			<Menu.Item key='1' onClick={setImageUploadModal}>
				Upload File
			</Menu.Item>
			<Menu.Item key='2' onClick={setTitleModal}>
				Edit Title
			</Menu.Item>
			<Menu.Item key='3' onClick={setDescriptionModal}>
				Edit Description
			</Menu.Item>
		</Menu>
	);
}
const ImageDisplay = ({ images }) => {
	const { height, width } = useWindowDimensions();
	if (!Array.isArray(images)) return <></>;
	var slidesPer;
	if (width > 1300) slidesPer = 4;
	else if (width < 1300 && width > 1130) slidesPer = 3;
	else if (width < 1130 && width > 760) slidesPer = 2;
	else slidesPer = 1;
	return (
		<Carousel
			arrows
			slidesPerRow={slidesPer}
			className='maintenanceCarousel'
			prevArrow={<img src={require('../icons/chevronLeft.png')} />}
			nextArrow={<img src={require('../icons/chevronRight.png')} />}
		>
			{images.map((image, index) => (
				<div className='imageTab' key={index}>
					<a href={image} download>
						<img
							className='d-block image carouselImages'
							src={image}
							alt='First slide'
						/>
					</a>
				</div>
			))}
		</Carousel>
	);
};

function Action(request) {
	function acceptDJR() {
		let acceptdr = request.request;
		axios.post('/acceptdirectJob', { acceptdr }).then(response => {
			if (
				response.statusText == 'OK' &&
				response.status >= 200 &&
				response.status < 300
			) {
				alert(response.data);
				this.setState({ type: 'Complete' });
			} else {
				throw new Error("Server can't be reached!");
			}
		});
	}
	function rejectDJR() {}

	return (
		<div className='requestDescription'>
			<div className='JRSubtitle'>Action Center</div>
			<Card id='' bordered={false} extra={null}>
				<div className={' taskBody'}>
					<div className='taskTitle'>Accept or Reject direct job request</div>
					<div className='taskActions'>
						<Button
							key={null}
							onClick={acceptDJR}
							type={'primary'}
							className={'mr-2 '}
						>
							Accept
						</Button>
						<Button
							key={null}
							onClick={rejectDJR}
							type={''}
							className={'mr-2 '}
						>
							Reject
						</Button>
					</div>
				</div>
			</Card>
		</div>
	);
}

class DirectJRdetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			djrid: this.props.match.params.djrid,
			request: []
		};
		//this.componentDidMount = this.componentDidMount.bind(this);
	}

	componentWillMount() {
		this.getData().then(data => {
			this.setState({ request: data });
		});
	}

	acceptdjr() {}

	getData() {
		return axios
			.get('/getdirectJRdetail_' + this.state.djrid, { crossdomain: true })
			.then(function (response) {
				return response.data;
			});
	}

	render() {
		return (
			<div id='displayShell'>
				<DirectJRListing request={this.state.request} clickable={false} />

				<RequestDescription request={this.state.request} updateImages={null} />
				<Action request={this.state.request}></Action>
			</div>
		);
	}
}

export { DirectJRdetail };
