import React from 'react';
import axios from 'axios';

import { Card, Modal } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import LoadScreen from '../../../Components/LoadScreen';
import NoAccess from '../../../Components/NoAccess';
import './propertyProfile.css';
import { UserCard } from '../../../Components/UserCard';
import EditPropertyDetails from '../../Properties/EditPropertyDetails';
import AddNewOwner from '../../People/AddNewOwner';
import AddUnit from '../../Properties/AddUnit';
import UnitSelection from '../../Intake/UnitSelection';
import { AgreementListing } from '../../Agreements/Agreements';
import { UserStateContext } from '../../../Context/UserContext';
import { PropertyMaintenanceRequestListing } from '../../JobRequests/RequestListing';

//TODO: fix mobile view

class PropertyProfile extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			property: null,
			access: null,
			units: null,
			requests: [],
			editDetails: false,
			ownerModal: false,
			unitModal: false
		};
	}

	componentDidMount = () => {
		// let id = window.location.href.split('/')[4];

		axios
			.get('/property/propertyProfile/' + this.props.location.state.id)
			.then(response => {
				console.log(response.data.property);
				this.setState({
					property: response.data.property,
					access: response.data.access,
					requests: response.data.requests,
					propertyId: this.props.location.state.id
				});
			});
	};
	openEditModal = () => {
		this.setState({ editDetails: !this.state.editDetails });
	};
	openOwnerModal = () => {
		this.setState({ ownerModal: !this.state.ownerModal });
	};
	openUnitModal = () => {
		this.setState({ unitModal: !this.state.unitModal });
	};

	setUnits = units => {
		this.setState({ units: units });
	};
	render() {
		if (this.state.access === null)
			return (
				<div className='h100'>
					<LoadScreen className='m-auto' />
				</div>
			);
		else if (!this.state.access)
			return (
				<div>
					<NoAccess />
				</div>
			);
		else if (this.state.access && this.state.property !== null)
			return (
				<div id='propertyProfileContainer'>
					<div id='propertyProfileBody'>
						<PropertyOverview
							property={this.state.property}
							editDetails={this.state.editDetails}
							openEditModal={this.openEditModal}
						/>

						<div id='propertyProfilePeople'>
							<Card
								className='profileUserCard mr-4'
								bordered={false}
								title={<div className=' fs-16 g-text'>Property Manager</div>}
							>
								<UserCard
									image={this.state.property.manager.imageurl}
									user_id={this.state.property.manager.id}
									name={
										this.state.property.manager.fname +
										' ' +
										this.state.property.manager.lname
									}
									email={this.state.property.manager.email}
									border={false}
								/>
							</Card>

							<Card
								// className='profileUserCard'
								// bordered={false}
								title={
									<div className=' fs-16 g-text'>
										Property Owner
										{this.context.user.type === 'Manager' ? (
											<PlusCircleOutlined onClick={this.openOwnerModal} />
										) : (
											''
										)}
									</div>
								}
							>
								{this.state.property.owner.map(propertyOwner => (
									<Card.Grid style={{ width: '25%', textAlign: 'center' }}>
										<UserCard
											image={propertyOwner.PO_imageurl}
											user_id={propertyOwner.PO_id}
											name={
												propertyOwner.PO_fname + ' ' + propertyOwner.PO_lname
											}
											email={propertyOwner.PO_email}
											border={false}
										/>
									</Card.Grid>
								))}
								{/* <OwnerList owners={this.state.property.owner} /> */}
							</Card>
						</div>
						<Card
							className='unitCard'
							bordered={false}
							title={
								<div className=' fs-16 g-text'>
									Units{' '}
									{this.context.user.type === 'Manager' ? (
										<PlusCircleOutlined onClick={this.openUnitModal} />
									) : (
										''
									)}
								</div>
							}
						>
							<UnitList
								units={this.state.property.units}
								propertyid={this.state.property.propertyid}
								setUnits={this.setUnits}
							/>
						</Card>

						<PropertyJobRequestDisplay
							propertyid={this.state.property.propertyid}
						/>
					</div>
					{/* for property owner */}
					<Modal
						destroyOnClose={true}
						title='Add Owner'
						visible={this.state.ownerModal}
						onCancel={this.openOwnerModal}
						width='800px'
						footer={false}
					>
						{this.state.ownerModal ? (
							<AddNewOwner
								onCancel={this.openOwnerModal}
								propertyId={this.props.location.state.id}
							/>
						) : (
							''
						)}
					</Modal>
					{/* for units */}

					<Modal
						destroyOnClose={true}
						title='Add Unit'
						visible={this.state.unitModal}
						onCancel={this.openUnitModal}
						width='500px'
						footer={false}
					>
						{this.state.unitModal ? (
							<AddUnit propertyId={this.props.location.state.id} />
						) : (
							''
						)}
					</Modal>

					{/*         
          <div id='propertyprofileSettings'>
            <Card bordered={false} className='propertySettings' title={<div className=" fs-16 g-text">Settings</div>}></Card>
          </div>
          */}
				</div>
			);
	}
}

class PropertyJobRequestDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.state = { requests: null };
	}
	componentDidMount = () => {
		axios
			.post('jobRequest/get/propertyRequests', {
				propertyid: this.props.propertyid
			})
			.then(response => {
				this.setState({ requests: response.data.requests });
			})
			.catch(err => console.log(err));
	};

	render() {
		return (
			<Card
				id='jobRequestCardPP'
				bordered={false}
				title={<div className=' fs-16 g-text'>Job Requests</div>}
			>
				{this.state.requests === null ? (
					<LoadScreen className='m-auto' />
				) : this.state.requests.length > 0 ? (
					<div>
						{this.state.requests.map(request => (
							<PropertyMaintenanceRequestListing
								clickable
								request={request}
								key={request.job_id}
							/>
						))}
					</div>
				) : (
					''
				)}
			</Card>
		);
	}
}

class UnitList extends React.Component {
	componentDidMount = () => {
		axios.get('/getAgreements/' + this.props.propertyid).then(response => {
			this.props.setUnits(response.data.agreementsList);
		});
	};

	render() {
		if (this.props.units === null) return <LoadScreen className='m-auto' />;
		else if (this.props.units.length === 0) return <div>No units found.</div>;
		return (
			<>
				{this.props.units.map((agreement, agindex) => (
					<AgreementListing
						key={agindex}
						agreement={agreement}
						customLink={{
							pathname: '/properties/profile/unitProfile',
							state: {
								propertyId: this.props.propertyid,
								unitId: agreement.unit_id
							}
						}}
						noCity
					/>
				))}
			</>
		);
	}
}
class OwnerList extends React.Component {
	render() {
		if (this.props.owners === null) return <LoadScreen className='m-auto' />;
		else if (this.props.owners.length === 0)
			return <div>No Owner is Assigned.</div>;
		else
			return (
				<>
					{this.props.owners.map(propertyOwner => (
						<UserCard
							image={propertyOwner.PO_imageurl}
							user_id={propertyOwner.PO_id}
							name={propertyOwner.PO_fname + ' ' + propertyOwner.PO_lname}
							email={propertyOwner.PO_email}
							border={false}
						/>
					))}
				</>
			);
	}
}
export class PropertyOverview extends React.Component {
	render() {
		console.log(this.props);
		return (
			<div>
				<div className='d-flex flex-column overviewTitle'>
					<h3 className='mb-0'>
						{!this.props.unit_name ? (
							<>
								{`${this.props.property.propertynumber} ${this.props.property.propertyStreet}`}
								<EditOutlined onClick={this.props.openEditModal} />
							</>
						) : (
							<>{`${this.props.property.propertynumber} ${
								this.props.property.propertyStreet
							} ${
								this.props.unit && this.props.unit_name !== 'default'
									? 'Unit ' + this.props.unit_name
									: ''
							}`}</>
						)}
					</h3>

					<div className=' fs-14 g-text'>
						{`${this.props.property.propertyCity}, ${this.props.property.propertyProvince} ${this.props.property.propertyPostalcode}`}
					</div>
				</div>

				<div className='propCardContainer'>
					<div className='propCardFooter'>
						<div className='PropCardType g-text-sm'>
							{this.props.property.propertyType}
						</div>
						<div className='g-text-sm'>
							{this.props.property.tenants.length} Tenants
						</div>
					</div>
				</div>
				<Modal
					bodyStyle={{ height: '250px' }}
					width='900px'
					title='Edit Property Details'
					visible={this.props.editDetails}
					onCancel={this.props.openEditModal}
					footer={false}
				>
					{this.props.editDetails ? (
						<EditPropertyDetails
							onCancel={this.props.openEditModal}
							propertyId={this.props.property.propertyid}
							propertyNumber={this.props.property.propertynumber}
							propertyStreet={this.props.property.propertyStreet}
							propertyCity={this.props.property.propertyCity}
							propertyProvince={this.props.property.propertyProvince}
							propertyPostalcode={this.props.property.propertyPostalcode}
							propertyType={this.props.property.propertyType}
							totalUnits={this.props.property.total_units}
						/>
					) : (
						''
					)}
				</Modal>
			</div>
		);
	}
}
/*
  <div className="propCardDescription">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
    faucibus iaculis tortor et hendrerit. Maecenas gravida sed ante eget
    efficitur. Ut viverra, leo vel ultricies venenatis... Left this here
    for property details like rooms, amenities, etc;
  </div>

*/
export default PropertyProfile;
