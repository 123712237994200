import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
const NoAccess = () => {
	return (
		<div>
			<Result
				status='403'
				title='Access Denied'
				subTitle='Sorry, you are not authorized to access this page.'
				extra={
					<Link to='/communication'>
						<Button type='primary'>Back Home</Button>
					</Link>
				}
			/>
		</div>
	);
};

export default NoAccess;
