import React from "react";
import axios from "axios";

import { Modal } from "react-bootstrap";
import {
    Form,
    Button,
    Divider,
    Checkbox
} from "antd";
import LoadScreen from '../../Components/LoadScreen';

function makeDateString(dateObj) {
    let dateString = "";
    if (typeof dateObj === "object")
      dateString = dateObj.toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    else {
      let newDate = new Date(dateObj);
      dateString = newDate.toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }
    return dateString;
  }
class ConflictModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedRes: this.props.conflicts.map(
                (unitGroup)=>Object.keys(unitGroup.conflicts).map(
                    (confIndex)=>unitGroup.conflicts[confIndex].conflicts.map((confResIndex)=>false)
                )
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.modalKey !== this.props.modalKey)
            this.setState({selectedRes: this.props.conflicts.map(
                (unitGroup)=>Object.keys(unitGroup.conflicts).map(
                    (confIndex)=>unitGroup.conflicts[confIndex].conflicts.map((confResIndex)=>false)
                )
            ),
            loading: false
            });
    }

    updateChecked = (checkVal, ugIndex, cfIndex, crIndex) => {
        let newSelectedRes = [...this.state.selectedRes];
        newSelectedRes[ugIndex][cfIndex][crIndex] = checkVal;
        this.setState({selectedRes: newSelectedRes});
    }

    checkIfConflict = () => {
        let isChecked = false;
        return [this.props.conflicts.some((unitGroup, numUnitMapped) => {
            //let unit = this.props.resData[unitGroup.index];
            return Object.keys(unitGroup.conflicts).some((confIndex, numConfMapped) => {
                let dateList = [];
                let foundConflict = unitGroup.conflicts[confIndex].conflicts.some((confResIndex, numConfResMapped) => {
                    let confRes = this.props.resData[unitGroup.index].reservations[confResIndex];
                    if (this.state.selectedRes[numUnitMapped][numConfMapped][numConfResMapped]) {
                        if (!isChecked)
                            isChecked = true;
                        let matchIndex = dateList.findIndex((dateRange)=>{
                            return (confRes.start_date >= dateRange[0] && confRes.start_date <= dateRange[1]) || (confRes.end_date >= dateRange[0] && confRes.end_date <= dateRange[1])
                        });
                        if (this.state.selectedRes[numUnitMapped][numConfMapped][numConfResMapped])
                            dateList.push([confRes.start_date, confRes.end_date]);
                        return matchIndex >= 0;
                    }
                    return false;
                });
                return foundConflict;
            });
        }), isChecked];
    }

    fixConflict = () => {
        let removeConflicts = [];
        this.props.conflicts.forEach((unitGroup, numUnitMapped) => {
            let unit = this.props.resData[unitGroup.index];
            let unitConflicts = [];
            let anyChecks = false;
            Object.keys(unitGroup.conflicts).forEach((confIndex, numConfMapped) => {
                unitGroup.conflicts[confIndex].conflicts.forEach((confResIndex, numConfResMapped) => {
                    let confRes = this.props.resData[unitGroup.index].reservations[confResIndex];
                    if (!this.state.selectedRes[numUnitMapped][numConfMapped][numConfResMapped])
                        unitConflicts.push(confRes.reservation_code || confRes.confirmation_code)
                    else
                        anyChecks = true;
                });
            });
            if (unitConflicts.length > 0 && anyChecks) {
                removeConflicts.push([unitConflicts, unit.unit_id]);
            }
        });
        if (removeConflicts.length > 0) {
            this.setState({loading: true});
            axios.post('/delAirbnbReservationConflicts', {conflicts: removeConflicts})
            .then((response, err)=>{
                if (err) {
                    this.props.error();
                    this.setState({loading: false});
                }
                else {
                    
                    this.props.success();
                }
            });
        }
    }

    render() {
        let ifConflicts = this.checkIfConflict();
        return (
            <>
                <Modal.Header>
                    <div style={{display: "inline"}}>
                    {!this.state.loading ? <><h2>Conflicts Detected</h2><p>Select which reservations to keep.</p></> : <h2>Resolving Conflicts</h2>}
                    </div>
                </Modal.Header>
                <Modal.Body style={{minHeight:'100px', maxHeight:'300px'}}>
                {!this.state.loading ?
                    this.props.conflicts.map((unitGroup, numUnitMapped)=>{
                        let unit = this.props.resData[unitGroup.index];
                        return (
                            <React.Fragment key={unitGroup.index}>
                                <Divider>
                                    {unit.listing_name ?
                                    <span style={{fontWeight: "bold"}}>
                                        {unit.unit_label} - <span style={{fontSize: "80%"}}>{unit.listing_name}</span>
                                    </span>
                                    : unit.unit_label}
                                </Divider>
                                {Object.keys(unitGroup.conflicts).map((confIndex, numConfMapped)=>{
                                    return (
                                        <React.Fragment key={confIndex}>
                                            {numConfMapped > 0 ? <Divider dashed/> : null}
                                            {unitGroup.conflicts[confIndex].conflicts.map((confResIndex, numConfResMapped)=>{
                                                let confRes = this.props.resData[unitGroup.index].reservations[confResIndex];
                                                return (
                                                    <React.Fragment key={confRes.reservation_code || confRes.confirmation_code}>
                                                        {numConfResMapped > 0 ? <br/> : null}
                                                        <Checkbox className={'no-select'} checked={this.state.selectedRes[numUnitMapped][numConfMapped][numConfResMapped]} onChange={(e)=>{
                                                            this.updateChecked(e.target.checked, numUnitMapped, numConfMapped, numConfResMapped);
                                                        }}>
                                                            {confRes.reservation_code || confRes.confirmation_code}
                                                            {confRes.guest_name ? <>&nbsp;-&nbsp;{confRes.guest_name}</> : null}
                                                            &nbsp;-&nbsp;
                                                            {makeDateString(confRes.start_date)}&nbsp;-&gt;&nbsp;{makeDateString(confRes.end_date)}
                                                        </Checkbox>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })
                : <LoadScreen className='noMarginTop' /> }
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={this.fixConflict} type='primary' disabled={this.state.loading || ifConflicts[0] || !ifConflicts[1]}>Finish</Button>
                </Modal.Footer>
            </>
        );
    }
}

export default ConflictModal;