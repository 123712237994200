import React, { useState } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import {
	Card,
	Avatar,
	Button,
	Radio,
	Modal,
	Input,
	Alert,
	message,
	Checkbox
} from 'antd';
import { Link } from 'react-router-dom';
import LoadScreen from '../../../Components/LoadScreen';
import NoAccess from '../../../Components/NoAccess';
import './propertyProfile.css';
import { UserCard } from '../../../Components/UserCard';
import {
	LeftCircleOutlined,
	LoadingOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';
import { PropertyOverview } from './PropertyProfile';
import AddNewTenant from '../../People/AddNewTenant';
import AddLease from '../../Properties/AddLease';
import { UnitMaintenanceRequestListing } from '../../JobRequests/RequestListing';
import { AgreementListing } from '../../Agreements/Agreements';

import { UserStateContext } from '../../../Context/UserContext';
const cancelToken = axios.CancelToken.source();

class UnitProfile extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			property: null,
			access: null,
			unit: null,
			requests: [],
			unitType: '',
			maintenanceActive: false,
			snowActive: false,
			icalModal: false,
			icalLink: '',
			listingName: '',
			icalError: false,
			icalLoading: false,
			unitTypeLoading: false,
			serviceLoading: false,
			leaseModal: false,
			tenantModal: false,
			tenantDisplay: false,

			ready: false
		};
	}

	componentDidMount = () => {
		// let id = window.location.href.split('/')[4];
		// let unit_id = window.location.href.split('/')[5];

		axios
			.post('/property/propertyUnit', {
				propertyid: this.props.location.state.propertyId,
				unit_id: this.props.location.state.unitId
			})
			.then(response => {
				console.log(response.data.unit);
				this.setState({
					property: response.data.property,
					unit: response.data.unit,
					access: response.data.access,
					requests: response.data.requests,
					unitType: {
						'Not Available': 'na',
						'Long Term': 'lt',
						'Short Term': 'st',
						Maintenance: 'mn',
						'Snow Clearing': 'sc'
					}[response.data.unit.type],
					readyValue: response.data.unit.ready,
					comment: response.data.unit.comments
				});
			});
	};

	changeUnitType = val => {
		let targetType = val.target.value;
		this.setState({ unitTypeLoading: true });
		axios
			.post(`/property/updateUnitType/${this.state.unit.unit_id}`, {
				unitType: {
					na: 'Not Available',
					lt: 'Long Term',
					st: 'Short Term',
					mn: 'Maintenance',
					sc: 'Snow Clearing'
				}[targetType],
				formerType: this.state.unitType
			})
			.then(response => {
				let stateUpdate = {};
				if (this.state.unitType === 'mn' || this.state.unitType === 'sc')
					stateUpdate[
						{ mn: 'snowActive', sc: 'maintenanceActive' }[this.state.unitType]
					] = false;
				if (response.data.ok)
					this.setState({
						...stateUpdate,
						unitType: targetType,
						unitTypeLoading: false
					});
				else {
					this.setState({ unitTypeLoading: false });
					message.error('Server Error: Please try again.');
				}
			});
	};

	updateSTData = val => {
		this.setState({ icalLoading: true, icalError: false });
		axios
			.post(`/property/updateUnitAirbnb/${this.state.unit.unit_id}`, {
				icalLink: this.state.icalLink,
				listingName: this.state.listingName
			})
			.then(response => {
				if (response.data.ok)
					this.setState({ icalLoading: false, icalModal: false });
				else this.setState({ icalLoading: false, icalError: true });
			});
	};

	setServiceActive = (serviceType, val) => {
		this.setState({ serviceLoading: true });
		axios
			.post(
				`/property/updateUnitServices/${this.state.unit.unit_id}/${serviceType}`,
				{ active: val.target.checked }
			)
			.then(response => {
				let stateUpdate = {};
				stateUpdate[
					{ mn: 'maintenanceActive', sc: 'snowActive' }[serviceType]
				] = val.target.checked;
				if (response.data.ok)
					this.setState({ ...stateUpdate, serviceLoading: false });
				else {
					this.setState({ serviceLoading: false });
					message.error('Server Error: Please try again.');
				}
			});
	};
	setUnitReady = ready => {
		axios
			.post('/property/update/ready', {
				Ready: ready,
				Comment: this.state.comment,
				UnitId: this.state.unit.unit_id
			})
			.then(response => {
				this.closeComment();
			});
	};
	openLeaseModal = () => {
		this.setState({ leaseModal: !this.state.leaseModal });
	};
	openTenantModal = () => {
		this.setState({ tenantModal: !this.state.tenantModal });
	};
	closeComment = () => {
		this.setState({ ready: !this.state.ready });
	};

	render() {
		console.log(this.state.readyValue);
		if (this.state.access === null)
			return (
				<div className='h100'>
					<LoadScreen className='m-auto' />
				</div>
			);
		else if (!this.state.access)
			return (
				<div>
					<NoAccess />
				</div>
			);
		else if (this.state.access && this.state.property !== null)
			return (
				<div id='propertyProfileContainer'>
					<div id='propertyProfileBody'>
						{/* <Link to={'/properties/' + this.state.property.propertyid}> */}
						<Link
							to={{
								pathname: '/properties/profile',
								state: { id: this.state.property.propertyid }
							}}
						>
							<Button className='mt-2'>
								<LeftCircleOutlined className='mr-1' /> Back to Property Profile
							</Button>
						</Link>
						<PropertyOverview
							unit_name={this.state.unit.unit_name}
							property={this.state.property}
						/>

						<Card
							bordered={false}
							title={
								<div className=' fs-16 g-text'>
									Lease{' '}
									{this.context.user.type === 'Manager' ? (
										<PlusCircleOutlined onClick={this.openLeaseModal} />
									) : (
										''
									)}
								</div>
							}
						>
							<UnitLeaseDisplay unit_id={this.state.unit.unit_id} />
						</Card>
						{this.state.unit.lease_id !== null ? (
							<div id='propertyProfilePeople'>
								<Card
									// className='profileUserCard mr-4'
									bordered={false}
									title={
										<div className=' fs-16 g-text'>
											Tenant
											{this.context.user.type === 'Manager' ? (
												<PlusCircleOutlined onClick={this.openTenantModal} />
											) : (
												''
											)}
										</div>
									}
								>
									{this.state.property.tenants.map(tenant => (
										<Card.Grid style={{ width: '50%', textAlign: 'center' }}>
											<UserCard
												image={tenant.tenant_imageurl}
												user_id={tenant.tenant_id}
												name={tenant.tenant_fname + ' ' + tenant.tenant_lname}
												email={tenant.tenant_email}
												border={false}
											/>
										</Card.Grid>
									))}
								</Card>
							</div>
						) : (
							''
						)}

						<Card
							id='jobRequestCardPP'
							bordered={false}
							title={<div className=' fs-16 g-text'>Job Requests</div>}
						>
							<UnitJobRequestDisplay unitid={this.state.unit.unit_id} />
						</Card>
					</div>
					{this.context.user.type === 'Manager' ? (
						<div id='propertyprofileSettings'>
							<Card
								bordered={false}
								className='unitSettings'
								title={<div className=' fs-16 g-text'>Settings</div>}
							>
								<div className=' fs-16 g-text'>
									Unit Type{' '}
									<LoadingOutlined
										style={{
											display: this.state.unitTypeLoading ? 'inline' : 'none'
										}}
									/>
								</div>
								<Radio.Group
									onChange={this.changeUnitType}
									value={this.state.unitType}
								>
									<Radio value={'na'}>Not Available</Radio>
									<Radio value={'lt'}>Long Term Rental</Radio>
									<Radio value={'st'}>Short Term Rental</Radio>
								</Radio.Group>
								<div className=' fs-16 g-text'>
									Services Provided{' '}
									<LoadingOutlined
										style={{
											display: this.state.serviceLoading ? 'inline' : 'none'
										}}
									/>
								</div>
								<Checkbox
									disabled={
										this.state.unitType === 'mn' || this.state.unitType === 'sc'
									}
									onChange={val => {
										this.setServiceActive('mn', val);
									}}
									checked={
										(this.state.maintenanceActive ||
											this.state.unitType === 'mn') &&
										this.state.unitType !== 'sc'
									}
								>
									Maintenance
								</Checkbox>
								<Checkbox
									disabled={
										this.state.unitType === 'mn' || this.state.unitType === 'sc'
									}
									onChange={val => {
										this.setServiceActive('sc', val);
									}}
									checked={
										(this.state.snowActive || this.state.unitType === 'sc') &&
										this.state.unitType !== 'mn'
									}
								>
									Snow Clearing
								</Checkbox>
								{this.state.unitType === 'st' ? (
									<Button
										type='primary'
										onClick={() => {
											this.setState({
												icalModal: true,
												icalLink:
													this.state.unit.ical_url !== null
														? this.state.unit.ical_url
														: this.state.icalLink,
												listingName:
													this.state.unit.listing_name !== null
														? this.state.unit.listing_name
														: this.state.unit.listingName,
												icalError: false
											});
										}}
									>
										Sync AirBnB Calendar
									</Button>
								) : null}
								<div className=' fs-16 g-text'>
									Rental Status
									<LoadingOutlined
										style={{
											display: this.state.serviceLoading ? 'inline' : 'none'
										}}
									/>
								</div>
								<Radio.Group
									onChange={e =>
										this.setState({
											readyValue: e.target.value,
											ready: !this.state.ready
										})
									}
									value={this.state.readyValue}
								>
									<Radio value={1} disabled={this.state.readyValue === 1}>
										Ready
									</Radio>
									<Radio value={0} disabled={this.state.readyValue === 0}>
										Not Ready
									</Radio>
								</Radio.Group>
								{(this.state.readyValue === 0 || this.state.readyValue === 1) &&
								this.state.ready ? (
									<>
										<Form.Group as={Col} sm={2} md={6}>
											<Form.Control
												required
												as='textarea'
												rows='5'
												onChange={e =>
													this.setState({ comment: e.target.value })
												}
												placeholder={'Comment'}
											/>
											<Button
												style={{ marginTop: 8 }}
												onClick={this.closeComment}
												type='primary'
											>
												Cancel
											</Button>
											<Button
												style={{ marginLeft: 5 }}
												onClick={() => this.setUnitReady(this.state.readyValue)}
												type='primary'
											>
												Submit
											</Button>
										</Form.Group>
									</>
								) : (
									''
								)}
							</Card>
						</div>
					) : (
						''
					)}

					<Modal
						open={this.state.icalModal}
						footer={null}
						onCancel={() => {
							this.setState({ icalModal: false, unitTypeLoading: false });
						}}
					>
						<p>Enter the AirBnB iCal link associated with your unit</p>
						<Input
							placeholder='iCal Link'
							onChange={val => {
								this.setState({ icalLink: val.target.value });
							}}
							value={this.state.icalLink}
						/>
						<Input
							placeholder='Listing Name (Copy from AirBnB Site)'
							onChange={val => {
								this.setState({ listingName: val.target.value });
							}}
							value={this.state.listingName}
						/>
						<Button type='primary' onClick={this.updateSTData}>
							Save
						</Button>
						<Alert
							style={{ display: this.state.icalError ? 'block' : 'none' }}
							type='error'
							message='Error: Please ensure the link provided is correct.'
						/>
						<LoadingOutlined
							style={{ display: this.state.icalLoading ? 'block' : 'none' }}
						/>
					</Modal>
					<Modal
						destroyOnClose={true}
						title='Add Lease'
						open={this.state.leaseModal}
						onCancel={this.openLeaseModal}
						width='500px'
						footer={false}
					>
						{this.state.leaseModal ? (
							<AddLease
								property={this.state.property}
								unit={this.state.unit}
								propertyId={this.props.location.state.propertyId}
								unitId={this.props.location.state.unitId}
							/>
						) : (
							''
						)}
					</Modal>
					<Modal
						destroyOnClose={true}
						title='Add New Tenant'
						open={this.state.tenantModal}
						onCancel={this.openTenantModal}
						width='800px'
						footer={false}
					>
						{this.state.tenantModal ? (
							<AddNewTenant
								onCancel={this.openTenantModal}
								propertyId={this.props.location.state.propertyId}
								unitId={this.props.location.state.unitId}
							/>
						) : (
							''
						)}
					</Modal>
				</div>
			);
	}
}
/*
                <UserCard
                  image={this.state.property.manager.imageurl}
                  user_id={this.state.property.manager.id}
                  name={
                    this.state.property.manager.fname +
                    " " +
                    this.state.property.manager.lname
                  }
                  email={this.state.property.manager.email}
                  border={false}
                />
*/

class UnitJobRequestDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.state = { requests: null };
	}
	componentDidMount = () => {
		axios
			.post('jobRequest/get/unitRequests', {
				unitid: this.props.unitid
			})
			.then(response => {
				this.setState({ requests: response.data.requests });
			})
			.catch(err => console.log(err));
	};

	render() {
		if (this.state.requests === null) {
			return <LoadScreen small className='m-auto' />;
		} else if (this.state.requests.length === 0)
			return <div>No requests found.</div>;
		else {
			return (
				<div>
					{this.state.requests.map(request => (
						<UnitMaintenanceRequestListing
							clickable
							request={request}
							key={request.job_id}
						/>
					))}
				</div>
			);
		}
	}
}

const UnitTenantDisplay = props => {
	const [tenants, setTenants] = useState(null);

	const getTenants = () => {
		axios
			.post('/property/unit/get/tenants', {
				unit_id: props.unitId
			})
			.then(response => {
				setTenants(response.data.tenants);
			})
			.catch(err => console.log(err));
	};

	if (tenants === null) {
		getTenants();
		return <LoadScreen small />;
	} else if (tenants.length === 0) {
		return <div>This unit does not have any Tenants.</div>;
	} else {
		return tenants.map(t => (
			<UserCard
				image={t.tenant_imageurl}
				user_id={t.tenant_id}
				name={t.tenant_fname + ' ' + t.tenant_lname}
				email={t.tenant_email}
				border={false}
			/>
		));
	}
};

const UnitLeaseDisplay = props => {
	const [lease, setLease] = useState(null);

	const fetchLease = () => {
		axios
			.post('/property/unit/get/lease', {
				unit_id: props.unit_id
			})
			.then(response => {
				setLease(response.data.lease);
			})
			.catch(err => console.log(err));
	};
	if (lease === null) {
		fetchLease();
		return <LoadScreen small className='m-auto' />;
	} else if (lease.length === 0) {
		return <div>This unit does not have any lease connected.</div>;
	} else {
		return (
			<>
				{lease.map((l, agindex) => (
					<AgreementListing agreement={l} key={agindex} />
				))}
			</>
		);
	}
};

export default UnitProfile;
