import React from 'react';
import { Table, Button, DatePicker, TimePicker, Input, Modal } from 'antd';
import {
	FormOutlined,
	LoadingOutlined,
	FieldTimeOutlined
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import NewLog from './NewLog';

class WorkLog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workLogData: [],
			activeWork: null,
			newLog: false,
			totalDistance: '',
			totalExpenses: ''
		};
		this.logColumns = [
			{
				title: 'Start Time',
				dataIndex: 'start_time',
				key: 'start_time'

				// render: (cellData, rowData, index) => {
				// 	return (
				// 		<NewLogCell
				// 			cellType='start'
				// 			cellData={cellData}
				// 			rowData={rowData}
				// 			editDate={rowData.editDate}
				// 		/>
				// 	);
				// }
			},
			{
				title: 'End Time',
				dataIndex: 'end_time',
				key: 'end_time'

				// render: (cellData, rowData, index) => {
				// 	return (
				// 		<NewLogCell
				// 			cellType='end'
				// 			cellData={cellData}
				// 			rowData={rowData}
				// 			editDate={rowData.editDate}
				// 		/>
				// 	);
				// }
			},

			{
				title: 'Total Distance Travelled(Kms)',
				dataIndex: 'total_distance',
				key: 'total_distance',

				align: 'right'
				// render: (cellData, rowData) => {
				// 	return cellData || cellData === 0 ? (
				// 		cellData
				// 	) : (
				// 		<>
				// 			<Input name='totalDistance' onChange={this.handleEventChange} />
				// 		</>
				// 	);
				// }
			},
			{
				title: 'Total Expenses($)',
				dataIndex: 'total_expenses',
				key: 'total_expenses',

				align: 'right'
				// render: (cellData, rowData) => {
				// 	return cellData || cellData === 0 ? (
				// 		cellData
				// 	) : (
				// 		<>
				// 			<Input name='totalExpenses' onChange={this.handleEventChange} />
				// 		</>
				// 	);
				// }
			},
			{
				title: 'Total Hours',
				dataIndex: 'total_time',
				key: 'total_time',

				align: 'right'
				// render: (cellData, rowData) => {
				// 	return (
				// 		cellData || (
				// 			<>
				// 				<Button
				// 					disabled={rowData.uploadingNewLog}
				// 					onClick={rowData.saveEdit}
				// 					type='primary'
				// 				>
				// 					Submit
				// 				</Button>
				// 				{rowData.uploadingNewLog ? (
				// 					<LoadingOutlined style={{ color: '#4D7CFE' }} />
				// 				) : (
				// 					<Button onClick={rowData.cancelNewLog} type='primary'>
				// 						Cancel
				// 					</Button>
				// 				)}
				// 			</>
				// 		)
				// 	);
				// }
			}
		];
	}
	openNewLog = () => {
		this.setState({ newLog: !this.state.newLog });
	};

	// addNewLog = () => {
	// 	let startDate = new Date();
	// 	let endDate = new Date();
	// 	let temp = [...this.state.workLogData];
	// 	startDate.setMinutes(0);
	// 	startDate.setSeconds(0);
	// 	endDate.setHours(endDate.getHours());
	// 	endDate.setMinutes(0);
	// 	endDate.setSeconds(0);
	// 	let newLogs = [
	// 		{
	// 			worklog_id: null,
	// 			user_id: null,
	// 			start_time: null,
	// 			start_time_data: moment(startDate).toString(),
	// 			end_time: null,
	// 			end_time_data: moment(endDate).toString(),
	// 			total_time: null,
	// 			status: null,
	// 			total_distance: null,
	// 			total_expenses: null,
	// 			editDate: this.editDate,
	// 			saveEdit: this.saveEdit,
	// 			cancelNewLog: this.cancelNewLog
	// 		}
	// 	].concat(temp);
	// 	this.setState({
	// 		total_distance: null,
	// 		total_expenses: null,
	// 		workLogData: newLogs,
	// 		newLog: true
	// 	});
	// };

	// cancelNewLog = () => {
	// 	let temp = [...this.state.workLogData];
	// 	temp.shift();
	// 	this.setState({ workLogData: temp, newLog: false });
	// };

	// editDate = (newDate, type) => {
	// 	let temp = [...this.state.workLogData];
	// 	temp[0][type + '_time_data'] = newDate.toString();
	// 	console.log(temp[0][type + '_time_data']);
	// 	this.setState({ workLogData: temp });
	// };

	// getTimeInterval = (startTime, endTime) => {
	// 	var start = moment(startTime);
	// 	var end = moment(endTime);
	// 	var minutes = end.diff(start, 'minutes') % 60;
	// 	var hours = end.diff(start, 'hours');
	// 	if (hours === 0 && minutes === 0) minutes = 1;
	// 	if (minutes < 10) {
	// 		minutes = String('0' + minutes);
	// 	}
	// 	var interval = hours + ':' + minutes;
	// 	return interval;
	// };

	// saveEdit = () => {
	// 	let temp = [...this.state.workLogData];
	// 	temp[0].uploadingNewLog = true;
	// 	this.setState({ workLogData: temp });
	// 	console.log(temp[0]);
	// 	axios
	// 		.post('/workOrder/saveNewWorklog', {
	// 			start_time: this.state.date,
	// 			end_time: null,
	// 			total_distance: this.state.totalDistance,
	// 			total_expenses: this.state.totalExpenses
	// 		})
	// 		.then(response => {
	// 			temp = [...this.state.workLogData];
	// 			delete temp[0].uploadingNewLog;
	// 			temp[0].worklog_id = response.data.worklog_id;
	// 			// let startTime = new Date(temp[0].start_time_data);
	// 			// let endTime = new Date(temp[0].end_time_data);
	// 			temp[0].start_time = moment(this.state.date).format('ddd, MMM Do YYYY');
	// 			// temp[0].end_time = moment(endTime).format(
	// 			// 	'ddd, MMM Do YYYY, [at] h:mma'
	// 			// );
	// 			// temp[0].total_time = this.getTimeInterval(startTime, endTime);
	// 			if (temp[0].total_distance !== null) {
	// 				temp[0].total_distance = this.state.totalDistance;
	// 			} else {
	// 				temp[0].total_distance = 0;
	// 			}
	// 			if (temp[0].total_expenses !== null) {
	// 				temp[0].total_expenses = this.state.totalExpenses;
	// 			} else {
	// 				temp[0].total_expenses = 0;
	// 			}
	// 			// temp[0].total_distance = this.state.totalDistance;
	// 			// temp[0].total_expenses = this.state.totalExpenses;
	// 			this.setState({ workLogData: temp, newLog: false });
	// 		})
	// 		.catch(err => {
	// 			temp = [...this.state.workLogData];
	// 			delete temp[0].uploadingNewLog;
	// 			this.setState({ workLogData: temp });
	// 		});
	// };

	componentDidMount = () => {
		axios
			.get('/workOrder/workdetails')
			.then(response => {
				let tempData = response.data.logData;

				this.setState({
					workLogData: tempData,
					activeWork: response.data.activeWork
				});
			})
			.catch(err => console.log(err));
	};
	startTime = () => {
		axios
			.post('/workOrder/startCounter')
			.then(response => {
				let data = this.state.workLogData;

				let newData = [response.data.row].concat(data);
				this.setState({
					workLogData: newData,
					activeWork: response.data.row
				});
			})
			.catch(err => {
				console.log(err);
			});
	};
	endTime = () => {
		axios
			.post('/workOrder/endCounter', {
				start_time: this.state.activeWork.start_time
			})
			.then(response => {
				let active = this.state.workLogData.findIndex(
					row => row.status === 'Active'
				);
				if (active === -1) return;
				let temp = [...this.state.workLogData];
				temp[active] = {
					...temp[active],
					end_time: response.data.end_time,
					total_time: response.data.total_time,
					status: 'Done'
				};
				return this.setState({ workLogData: temp, activeWork: null });
			})
			.catch(err => console.log(err));
	};
	// handleEventChange = e => {
	// 	if (e.target.name === 'totalDistance') {
	// 		this.setState({ totalDistance: e.target.value });
	// 	} else if (e.target.name === 'totalExpenses') {
	// 		this.setState({ totalExpenses: e.target.value });
	// 	}

	// 	console.log(this.state.totalDistance);
	// };

	render() {
		console.log(this.state.activeWork);
		const logColumns = this.logColumns.map(col => {
			if (!col.editable) {
				return col;
			}
		});
		return (
			<div style={{ margin: 'auto', width: '50%' }}>
				<div className='workLogHeader mb-4 mt-4'>
					{this.state.activeWork ? (
						<Button
							disabled={this.state.newLog}
							icon={<FieldTimeOutlined />}
							onClick={this.endTime}
							danger
							type='primary'
						>
							Clock Out
						</Button>
					) : (
						<>
							<Button
								disabled={this.state.newLog}
								icon={<FieldTimeOutlined />}
								onClick={this.startTime}
								type='primary'
							>
								Clock In
							</Button>
						</>
					)}

					<Button
						icon={<FormOutlined />}
						disabled={this.state.activeWork || this.state.newLog}
						onClick={this.openNewLog}
						type='primary'
						className='floatRight'
					>
						New Log
					</Button>
				</div>
				<Table
					size='small'
					columns={logColumns}
					dataSource={this.state.workLogData}
					rowKey='worklog_id'
					pagination={{ pageSize: 20 }}
				/>
				<Modal
					destroyOnClose={true}
					title='Add New Log'
					visible={this.state.newLog}
					onCancel={this.openNewLog}
					width='250px'
					footer={false}
				>
					{this.state.newLog ? <NewLog /> : ''}
				</Modal>
			</div>
		);
	}
}

// class NewLogCell extends React.Component {
// 	constructor(props) {
// 		super(props);
// 	}

// 	changeDate = date => {
// 		let newDate = moment(date.format('YYYY-MM-DD'))
// 			.hour(date.hour())
// 			.minute(date.minute())
// 			.second(date.second());
// 		console.log(newDate);
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		if (endDate) {
// 			if (this.props.cellType == 'start') {
// 				if (newDate >= endDate) {
// 					newDate = moment(endDate.format('YYYY-MM-DD'))
// 						.hour(endDate.hour())
// 						.minute(endDate.minute())
// 						.second(endDate.second() - 1);
// 				}
// 			} else if (newDate <= startDate) {
// 				newDate = moment(startDate.format('YYYY-MM-DD'))
// 					.hour(startDate.hour())
// 					.minute(startDate.minute())
// 					.second(startDate.second() + 1);
// 			}
// 		}

// 		this.props.editDate(newDate, this.props.cellType);
// 	};

// 	// changeTime = time => {
// 	// 	let newTime = moment(time.format('YYYY-MM-DD'))
// 	// 		.hour(time.hour())
// 	// 		.minute(time.minute())
// 	// 		.second(time.second());
// 	// 	console.log(newTime);
// 	// 	let startDate = moment(new Date(this.props.rowData.start_time_data));
// 	// 	let endDate = moment(new Date(this.props.rowData.end_time_data));
// 	// 	if (endDate) {
// 	// 		if (this.props.cellType == 'start') {
// 	// 			if (newTime >= endDate) {
// 	// 				newTime = moment(endDate.format('YYYY-MM-DD'))
// 	// 					.hour(endDate.hour())
// 	// 					.minute(endDate.minute())
// 	// 					.second(endDate.second() - 1);
// 	// 			}
// 	// 		} else if (newTime <= startDate) {
// 	// 			newTime = moment(startDate.format('YYYY-MM-DD'))
// 	// 				.hour(startDate.hour())
// 	// 				.minute(startDate.minute())
// 	// 				.second(startDate.second() + 1);
// 	// 		}
// 	// 	}
// 	// 	this.props.editDate(newTime, this.props.cellType);
// 	// };

// 	disabledDate = current => {
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		if (endDate)
// 			return (
// 				current > moment().endOf('day') ||
// 				(this.props.cellType == 'start' && current > endDate) ||
// 				(this.props.cellType == 'end' && current < startDate)
// 			);
// 		else return false;
// 	};

// 	disabledHours = () => {
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		let hours = [];
// 		if (endDate) {
// 			let startHour = 24;
// 			let endHour = 23;
// 			if (this.props.cellType == 'start') {
// 				if (startDate.format('YYYY MM DD') == endDate.format('YYYY MM DD'))
// 					startHour = endDate.hour() + 1;
// 			} else if (
// 				startDate.format('YYYY MM DD') == endDate.format('YYYY MM DD')
// 			) {
// 				startHour = 0;
// 				endHour = startDate.hour() - 1;
// 			}
// 			for (let i = startHour; i <= endHour; i++) {
// 				hours.push(i);
// 			}
// 		}
// 		return hours;
// 	};

// 	disabledMinutes = () => {
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		let minutes = [];
// 		if (endDate) {
// 			let startMinute = 60;
// 			let endMinute = 59;
// 			if (this.props.cellType == 'start') {
// 				if (
// 					startDate.format('YYYY MM DD, h') == endDate.format('YYYY MM DD, h')
// 				)
// 					startMinute = endDate.minute() + 1;
// 			} else if (
// 				startDate.format('YYYY MM DD, h') == endDate.format('YYYY MM DD, h')
// 			) {
// 				startMinute = 0;
// 				endMinute = startDate.minute() - 1;
// 			}
// 			for (let i = startMinute; i <= endMinute; i++) {
// 				minutes.push(i);
// 			}
// 		}
// 		return minutes;
// 	};

// 	disabledSeconds = () => {
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		let seconds = [];
// 		if (endDate) {
// 			let startSecond = 60;
// 			let endSecond = 59;
// 			if (this.props.cellType == 'start') {
// 				if (
// 					startDate.format('YYYY MM DD, h:mm') ==
// 					endDate.format('YYYY MM DD, h:mm')
// 				)
// 					startSecond = endDate.second();
// 			} else if (
// 				startDate.format('YYYY MM DD, h:mm') ==
// 				endDate.format('YYYY MM DD, h:mm')
// 			) {
// 				startSecond = 0;
// 				endSecond = startDate.second();
// 			}
// 			for (let i = startSecond; i <= endSecond; i++) {
// 				seconds.push(i);
// 			}
// 		}
// 		return seconds;
// 	};

// 	render() {
// 		let thisDate = moment(
// 			new Date(this.props.rowData[this.props.cellType + '_time_data'])
// 		);
// 		if (this.props.rowData[this.props.cellType + '_time']) {
// 			return <>{this.props.cellData}</>;
// 		} else {
// 			return (
// 				<>
// 					<DatePicker
// 						disabled={this.props.rowData.uploadingNewLog}
// 						inputReadOnly
// 						use12Hours
// 						hideDisabledOptions
// 						allowClear={false}
// 						renderExtraFooter={null}
// 						onSelect={this.changeDate}
// 						defaultValue={thisDate}
// 						disabledDate={this.disabledDate}
// 						format='YYYY-MM-DD h:mm A'
// 						showTime={true}
// 					/>
// 					{/* <TimePicker
// 						disabled={this.props.rowData.uploadingNewLog}
// 						use12Hours
// 						inputReadOnly
// 						hideDisabledOptions
// 						allowClear={false}
// 						format='h:mm a'
// 						renderExtraFooter={null}
// 						onSelect={this.changeTime}
// 						defaultValue={thisDate}
// 						disabledHours={this.disabledHours}
// 						disabledMinutes={this.disabledMinutes}
// 						disabledSeconds={this.disabledSeconds}
// 						showNow={false}
// 					/> */}
// 				</>
// 			);
// 		}
// 	}
// }

export default WorkLog;
