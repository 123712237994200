import React from 'react';
import { Form } from 'react-bootstrap';
import { Button, DatePicker, message } from 'antd';
import moment from 'moment';

import axios from 'axios';

class NewLog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalDistance: null,
			totalExpenses: null,
			travelDate: '',
			workLogData: []
		};
	}

	handleChange = e => {
		if (e.target.name === 'totalDistance') {
			this.setState({ totalDistance: e.target.value });
		} else if (e.target.name === 'totalExpenses') {
			this.setState({ totalExpenses: e.target.value });
		}
	};
	onSubmit = () => {
		this.setState({ submitted: true });
		let temp = [...this.state.workLogData];

		this.setState({ workLogData: temp });
		console.log(temp[0]);
		axios
			.post('/workOrder/saveNewWorklog', {
				start_time: this.state.travelDate,
				end_time: null,
				total_distance: this.state.totalDistance,
				total_expenses: this.state.totalExpenses
			})
			.then(response => {
				this.setState({ submitted: true });
				window.location.reload();
			})
			.catch(err => {
				temp = [...this.state.workLogData];

				this.setState({ workLogData: temp });
			});
	};

	render() {
		if (this.state.submitted) {
			return <h3>New Log Added! </h3>;
		}

		return (
			<div>
				<Form>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Date </Form.Label>
							<DatePicker
								style={{ marginLeft: 8 }}
								onChange={(date, dateString) => {
									this.setState({ travelDate: dateString });
								}}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className='d-flex'>
						<Form.Group>
							<Form.Label className='form-label'>
								Total Distance Travelled(Kms){' '}
							</Form.Label>

							<Form.Control
								required
								className='form-text'
								name='totalDistance'
								type='text'
								value={this.state.totalDistance || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Total Expenses($) </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='totalExpenses'
								type='text'
								value={this.state.totalExpenses || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>

					<br></br>
					<div className='text-right'>
						<Button onClick={this.onSubmit} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default NewLog;
