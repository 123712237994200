import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalTitle, Form } from 'react-bootstrap';
import useWindowDimensions from '../../../../Context/WindowsDimensions';
import BarCalendar from '../../../../Components/BarCalendar/BarCalendar';
import LoadScreen from '../../../../Components/LoadScreen';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import {
	Button,
	message,
	Avatar,
	DatePicker,
	Table,
	Checkbox,
	Card,
	Switch
} from 'antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { PDFDocument, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import ReactDOM from 'react-dom';
import './vendorListing.css';
const cancelToken = axios.CancelToken.source();
const { RangePicker } = DatePicker;
const docText = {
	Notice: 'Notice has been previously issued for Entry',
	Consent: 'Consent has been previously requested for Entry',
	Other: 'Other Entry Document has been previously prepared for Entry',
	Emergency: 'Emergency Entry Document has been previously prepared for Entry',
	None: 'No Entry Document has been previously prepared for Entry'
};

export function EditEventModal(props) {
	const {
		updateStatus,
		updateImages,
		afterEvent,
		vendors,
		employees,
		tenants,
		owners,
		user,
		event,
		updateEditEvent,
		doPrevent,
		needUpdate,
		warnUpdate
	} = props;
	const [modalPage, setPage] = useState(0);
	const [noticeData, setNoticeData] = useState(null);
	const [title, setTitle] = useState(event.title);
	const [time, setTime] = useState([
		new Date(event.start),
		new Date(event.end)
	]);
	const [lockOn, updateLock] = useState(false);
	const [noticeType, setNotice] = useState(event.document_type || 'None');
	const [selectedPeople, updatePeople] = useState({
		employee: event.users
			.map(ev_user => {
				return (
					employees.find(jr_user => {
						let id = jr_user.employee_id;
						return ev_user.user_id === id && ev_user.user_type === 'Employee';
					}) || null
				);
			})
			.filter(sel_user => {
				return sel_user !== null;
			}),
		vendor: event.users
			.map(ev_user => {
				return (
					vendors.find(jr_user => {
						let id = jr_user.vendor_id;
						return ev_user.user_id === id && ev_user.user_type === 'Vendor';
					}) || null
				);
			})
			.filter(sel_user => {
				return sel_user !== null;
			}),
		tenant: event.users
			.map(ev_user => {
				return (
					tenants.find(jr_user => {
						let id = jr_user.tenant_id;
						return ev_user.user_id === id && ev_user.user_type === 'Tenant';
					}) || null
				);
			})
			.filter(sel_user => {
				return sel_user !== null;
			}),
		owner: event.users
			.map(ev_user => {
				return (
					owners.find(jr_user => {
						let id = jr_user.owner_id;
						return ev_user.user_id === id && ev_user.user_type === 'Owner';
					}) || null
				);
			})
			.filter(sel_user => {
				return sel_user !== null;
			})
	});
	const [selectedVendor, setVendor] = useState('');
	const [isSig, setSig] = useState(false);
	const [personSel, setPerSel] = useState(
		employees.filter(employee => {
			return employee.terminate !== 1;
		}).length > 0
			? 'employee'
			: 'vendor'
	);
	const [validNotice, setValidNotice] = useState(false);
	const [timerAgain, setTimerAgain] = useState(true);
	const [areThereChanges, setAreThereChanges] = useState(false);
	const { width } = useWindowDimensions();
	const sigCanvas = useRef({});
	const [lastWarn, setLastWarn] = useState(false);

	const sigPadStyle = {
		display: 'block',
		borderRadius: '.25rem',
		border: '1px solid #ced4da',
		width: '450px',
		height: '82.5px',
		margin: 'auto',
		textAlign: 'middle'
	};

	const selectPeople = (person, type) => {
		if (!doPrevent) {
			let newPeople = { ...selectedPeople };
			let peopleIndex = newPeople[type].indexOf(person);
			let areChanges = false;
			if (peopleIndex >= 0) newPeople[type].splice(peopleIndex, 1);
			else newPeople[type].push(person);
			areChanges = newPeople.employee.some(person => {
				return !event.users.some(ev_user => {
					return (
						ev_user.user_type === 'Employee' &&
						ev_user.user_id === person.employee_id
					);
				});
			});
			if (!areChanges)
				areChanges = newPeople.vendor.some(person => {
					return !event.users.some(ev_user => {
						return (
							ev_user.user_type === 'Vendor' &&
							ev_user.user_id === person.vendor_id
						);
					});
				});
			if (!areChanges)
				areChanges = newPeople.tenant.some(person => {
					return !event.users.some(ev_user => {
						return (
							ev_user.user_type === 'Tenant' &&
							ev_user.user_id === person.tenant_id
						);
					});
				});
			if (!areChanges)
				areChanges = newPeople.owner.some(person => {
					return !event.users.some(ev_user => {
						return (
							ev_user.user_type === 'Owner' &&
							ev_user.user_id === person.owner_id
						);
					});
				});
			if (!areChanges)
				areChanges = event.users.some(ev_user => {
					let typeString = ev_user.user_type.toLowerCase();
					let isFound = newPeople[typeString].some(person => {
						return ev_user.user_id === person[typeString + '_id'];
					});
					return !isFound;
				});
			setAreThereChanges(areChanges);

			updatePeople(newPeople);
		}
	};

	const resetPeople = () => {
		updatePeople({
			employee: event.users
				.map(ev_user => {
					return (
						employees.find(jr_user => {
							let id = jr_user.employee_id;
							return ev_user.user_id === id && ev_user.user_type === 'Employee';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			vendor: event.users
				.map(ev_user => {
					return (
						vendors.find(jr_user => {
							let id = jr_user.vendor_id;
							return ev_user.user_id === id && ev_user.user_type === 'Vendor';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			tenant: event.users
				.map(ev_user => {
					return (
						tenants.find(jr_user => {
							let id = jr_user.tenant_id;
							return ev_user.user_id === id && ev_user.user_type === 'Tenant';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			owner: event.users
				.map(ev_user => {
					return (
						owners.find(jr_user => {
							let id = jr_user.owner_id;
							return ev_user.user_id === id && ev_user.user_type === 'Owner';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				})
		});
		setAreThereChanges(false);
	};

	const getSelectedPeople = () => {
		return {
			employee: event.users
				.map(ev_user => {
					return (
						employees.find(jr_user => {
							let id = jr_user.employee_id;
							return ev_user.user_id === id && ev_user.user_type === 'Employee';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			vendor: event.users
				.map(ev_user => {
					return (
						vendors.find(jr_user => {
							let id = jr_user.vendor_id;
							return ev_user.user_id === id && ev_user.user_type === 'Vendor';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			tenant: event.users
				.map(ev_user => {
					return (
						tenants.find(jr_user => {
							let id = jr_user.tenant_id;
							return ev_user.user_id === id && ev_user.user_type === 'Tenant';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				}),
			owner: event.users
				.map(ev_user => {
					return (
						owners.find(jr_user => {
							let id = jr_user.owner_id;
							return ev_user.user_id === id && ev_user.user_type === 'Owner';
						}) || null
					);
				})
				.filter(sel_user => {
					return sel_user !== null;
				})
		};
	};

	const editPurpose = () => {
		props.preventClosing(true);
		let oldTitle = event.title,
			newTitle = title,
			id = event.idcalendar_events;
		if (oldTitle !== newTitle) {
			axios
				.post('/jobRequest/update/event/purpose', {
					title: newTitle,
					id: id,
					request: props.request,
					notice_type: event.document_type || 'None'
				})
				.then(res => {
					afterEvent(res.data.newEvents);
					let eventIndex = res.data.newEvents.findIndex(newEvent => {
						return newEvent.idcalendar_events === event.idcalendar_events;
					});
					updateEditEvent(res.data.newEvents[eventIndex]);
					props.preventClosing(false);
					setPage(0);
				})
				.catch(error => {
					props.preventClosing(false);
					message.error('An Error Occurred.');
				});
		}
	};

	const editPeople = () => {
		props.preventClosing(true);
		let newSelections = {
			employee: selectedPeople.employee.filter(person => {
				return !event.users.some(ev_user => {
					return (
						ev_user.user_type === 'Employee' &&
						ev_user.user_id === person.employee_id
					);
				});
			}),
			vendor: selectedPeople.vendor.filter(person => {
				return !event.users.some(ev_user => {
					return (
						ev_user.user_type === 'Vendor' &&
						ev_user.user_id === person.vendor_id
					);
				});
			}),
			tenant: selectedPeople.tenant.filter(person => {
				return !event.users.some(ev_user => {
					return (
						ev_user.user_type === 'Tenant' &&
						ev_user.user_id === person.tenant_id
					);
				});
			}),
			owner: selectedPeople.owner.filter(person => {
				return !event.users.some(ev_user => {
					return (
						ev_user.user_type === 'Owner' && ev_user.user_id === person.owner_id
					);
				});
			})
		};
		if (
			newSelections.employee.length === 0 &&
			newSelections.vendor.length === 0 &&
			newSelections.tenant.length === 0 &&
			newSelections.owner.length === 0
		)
			newSelections = null;
		let deleteSelections = {
			employee: [],
			vendor: [],
			tenant: [],
			owner: []
		};
		event.users.forEach(ev_user => {
			let typeString = ev_user.user_type.toLowerCase();
			let isFound = selectedPeople[typeString].some(person => {
				return ev_user.user_id === person[typeString + '_id'];
			});
			if (!isFound) {
				deleteSelections[typeString].push(ev_user);
			}
		});

		if (
			deleteSelections.employee.length === 0 &&
			deleteSelections.vendor.length === 0 &&
			deleteSelections.tenant.length === 0 &&
			deleteSelections.owner.length === 0
		)
			deleteSelections = null;

		if (newSelections || deleteSelections) {
			axios
				.post('/jobRequest/update/event/people', {
					newPeople: newSelections,
					delPeople: deleteSelections,
					event_id: event.idcalendar_events,
					request: props.request,
					notice_type: event.document_type || 'None',
					event_id: event.idcalendar_events
				})
				.then(res => {
					afterEvent(res.data.newEvents);
					let eventIndex = res.data.newEvents.findIndex(newEvent => {
						return newEvent.idcalendar_events === event.idcalendar_events;
					});
					updateEditEvent(res.data.newEvents[eventIndex]);
					setAreThereChanges(false);
					props.preventClosing(false);
					setPage(0);
				})
				.catch(error => {
					props.preventClosing(false);
					message.error('An Error Occurred.');
				});
		} else {
			props.preventClosing(false);
		}
	};

	const editTime = () => {
		props.preventClosing(true);
		axios
			.post('/jobRequest/update/event/time', {
				startTime: time[0],
				endTime: time[1],
				event_id: event.idcalendar_events,
				request: props.request,
				notice_type: event.document_type || 'None'
			})
			.then(res => {
				afterEvent(res.data.newEvents);
				let eventIndex = res.data.newEvents.findIndex(newEvent => {
					return newEvent.idcalendar_events === event.idcalendar_events;
				});
				updateEditEvent(res.data.newEvents[eventIndex]);
				props.preventClosing(false);
				setPage(0);
			})
			.catch(error => {
				props.preventClosing(false);
				message.error('An Error Occurred.');
			});
	};

	const editEntry = () => {
		props.preventClosing(true);
		let body = {
			event_id: event.idcalendar_events,
			title: event.title,
			job_id: props.job_id,
			vendor_id: selectedVendor,
			startTime: moment(time[0]).valueOf(),
			endTime: moment(time[1]).valueOf(),
			sigPad: sigCanvas.current
				? Object.keys(sigCanvas.current).length > 0
					? sigCanvas.current.toDataURL()
					: null
				: null,
			noticeType: noticeType,
			propertyid: props.request.propertyid,
			selectedPeople: getSelectedPeople(),
			host: window.location.host
		};
		axios
			.post('/jobRequest/update/event/entry', body)
			.then(response => {
				clearSig();
				let jrResponse = response;
				afterEvent(jrResponse.data.scheduledEvents);
				let eventIndex = jrResponse.data.scheduledEvents.findIndex(newEvent => {
					return newEvent.idcalendar_events === event.idcalendar_events;
				});
				updateEditEvent(jrResponse.data.scheduledEvents[eventIndex]);
				if (jrResponse.data.document) updateImages([jrResponse.data.document]);
				if (jrResponse.data.updatedStatus)
					updateStatus(jrResponse.data.updatedStatus);
				props.preventClosing(false);
				props.requireUpdate(false);
				setPage(0);
			})
			.catch(error => {
				props.preventClosing(false);
				message.error('An Error Occurred.');
			});
	};

	const clearSig = () => {
		if (sigCanvas.current && 'props' in sigCanvas.current)
			sigCanvas.current.clear();
		setSig(false);
	};
	const sigTrue = () => {
		setSig(true);
	};

	const newPdf = async () => {
		console.log('Getting notice data');
		setNoticeData(null);
		const pdfTemplate = await fetch(require('../../../../pdf/notice.pdf'));
		const pdfBuffer = await pdfTemplate.arrayBuffer();
		const pdfDoc = await PDFDocument.load(pdfBuffer);
		const pdfPage = pdfDoc.getPage(0);
		const form = pdfDoc.getForm();
		const textField = form.createTextField('tenant1.textField');
		textField.setText('tenant1.textField');

		textField.addToPage(pdfPage, {
			x: 50,
			y: 75,
			width: 200,
			height: 100,
			textColor: rgb(1, 0, 0),
			borderColor: rgb(0, 0, 0),
			borderWidth: 2
		});
		const fields = form.getFields();
		fields.forEach(field => {
			const type = field.constructor.name;
			const name = field.getName();
			if (type == 'PDFTextField') form.getTextField(name).setText(name);
			console.log(`${type}: ${name}`);
		});
		const savedPdf = await pdfDoc.save();
		setNoticeData(window.URL.createObjectURL(new Blob([savedPdf])));
	};

	const checkIfTimeOk = (newTime = null) => {
		let checkTime = newTime || time[0];
		let dayInAdvance = new Date();
		dayInAdvance.setDate(dayInAdvance.getDate() + 1);
		let startingDay = new Date(checkTime.toString());
		//startingDay.setMinutes(17); Code for easy testing
		return startingDay > dayInAdvance;
	};

	useEffect(() => {
		if (event.document_up_to_date === 0 && !needUpdate) {
			props.requireUpdate(true);
		}
		if (warnUpdate && !lastWarn) {
			setPage(13);
			setLastWarn(true);
		}
		if (time) {
			const timer = setTimeout(() => {
				if (checkIfTimeOk()) setValidNotice(true);
				else setValidNotice(false);
				setTimerAgain(!timerAgain);
			}, 250);
			return () => clearTimeout(timer);
		}
	});

	switch (modalPage) {
		case 0:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>
							Schedule Event{' '}
							{needUpdate ? (
								<span className='fs-14 g-text'>
									Entry document requires an update.
								</span>
							) : null}
						</div>
					</Modal.Header>
					<Modal.Body className='text-center'>
						<Button
							onClick={() => setPage(1)}
							type='primary'
							className='rqTypeBtns'
						>
							Edit Purpose
						</Button>
						<Button
							onClick={() => setPage(2)}
							type='primary'
							className='rqTypeBtns'
						>
							Edit People
						</Button>
						<Button
							onClick={() => setPage(3)}
							type='primary'
							className='rqTypeBtns'
						>
							Edit Time
						</Button>
						<Button
							disabled={tenants.length === 0}
							icon={
								needUpdate ? (
									<ExclamationCircleFilled className='fs-18' />
								) : null
							}
							onClick={() => setPage(11)}
							type='primary'
							className='rqTypeBtns'
						>
							Update Entry
						</Button>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={props.onHide}>Close</Button>
					</Modal.Footer>
				</>
			);
		case 1:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Schedule Event</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								<Form.Label>Edit Purpose</Form.Label>
								<Form.Control
									disabled={doPrevent}
									className='width50 m-auto'
									type='text'
									value={title}
									placeholder={'Purpose'}
									onChange={e => {
										setTitle(e.target.value);
									}}
								/>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								setTitle(event.title);
								setPage(0);
							}}
						>
							Back
						</Button>
						<Button
							icon={doPrevent ? <LoadingOutlined /> : null}
							type='primary'
							disabled={title === '' || title === event.title}
							loading={doPrevent}
							onClick={editPurpose}
						>
							Save
						</Button>
					</Modal.Footer>
				</>
			);
		case 2:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Edit People</div>
					</Modal.Header>
					<Modal.Body
						className='assignVendorMBody text-center'
						style={{ height: '500px' }}
					>
						<Form>
							<Form.Group>
								<br />
								{employees.filter(employee => {
									return employee.terminate !== 1;
								}).length > 0 ? (
									<Button
										onClick={() => {
											setPerSel('employee');
										}}
									>
										Employees
									</Button>
								) : null}
								{vendors.filter(vendor => {
									return vendor.terminate !== 1;
								}).length > 0 ? (
									<Button
										onClick={() => {
											setPerSel('vendor');
										}}
									>
										Vendors
									</Button>
								) : null}
								{tenants.length > 0 ? (
									<Button
										onClick={() => {
											setPerSel('tenant');
										}}
									>
										Tenants
									</Button>
								) : null}
								{owners.length > 0 ? (
									<Button
										onClick={() => {
											setPerSel('owner');
										}}
									>
										Owners
									</Button>
								) : null}
								<div style={{ marginTop: '10px' }}>
									{
										{
											employee: employees
												.filter(employee => {
													return employee.terminate !== 1;
												})
												.map(employee => {
													return (
														<EmployeeListing
															key={
																employee.startDate + '-' + employee.employee_id
															}
															person={employee}
															selectedPeople={selectedPeople}
															selectPeople={selectPeople}
															id={employee.employee_id}
															type='employee'
														/>
													);
												}),
											vendor: vendors
												.filter(vendor => {
													return vendor.terminate !== 1;
												})
												.map(vendor => {
													return (
														<PersonListing
															key={vendor.startDate + '-' + vendor.vendor_id}
															person={vendor}
															id={vendor.vendor_id}
															selectedPeople={selectedPeople}
															selectPeople={selectPeople}
															type='vendor'
														/>
													);
												}),
											tenant: tenants.map(tenant => {
												return (
													<PersonListing
														key={tenant.startDate + '-' + tenant.tenant_id}
														person={tenant}
														id={tenant.tenant_id}
														selectedPeople={selectedPeople}
														selectPeople={selectPeople}
														type='tenant'
													/>
												);
											}),
											owner: owners.map(owner => {
												return (
													<PersonListing
														key={owner.startDate + '-' + owner.owner_id}
														person={owner}
														id={owner.owner_id}
														selectedPeople={selectedPeople}
														selectPeople={selectPeople}
														type='owner'
													/>
												);
											})
										}[personSel]
									}
								</div>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								resetPeople();
								setPage(0);
							}}
						>
							Back
						</Button>
						<Button
							icon={doPrevent ? <LoadingOutlined /> : null}
							type='primary'
							disabled={
								(selectedPeople.employee.length === 0 &&
									selectedPeople.vendor.length === 0) ||
								!areThereChanges
							}
							loading={doPrevent}
							onClick={editPeople}
						>
							Save
						</Button>
					</Modal.Footer>
				</>
			);
		case 3:
			return (
				<>
					<Modal.Header closeButton>
						<div style={{ display: 'inline' }}>
							<span className='fs-18'>Select Time</span>
							<br />
							{!validNotice && noticeType === 'Notice' ? (
								<span>
									<ExclamationCircleFilled
										style={{ color: '#fe4d97', display: 'inline' }}
									/>{' '}
									Notice would be invalid!
								</span>
							) : null}
						</div>
					</Modal.Header>
					<Modal.Body>
						<BarCalendar
							showHeader
							showTimeBar
							dataSource={[
								{
									bars: time
										? [
												{
													startTime: time[0].getHours(),
													endTime:
														time[1].getHours() > 0 ? time[1].getHours() : 24,
													startDate: new Date(
														time[0].getFullYear(),
														time[0].getMonth(),
														time[0].getDate()
													)
												}
										  ]
										: []
								}
							]}
							eventStyles={{
								pink: {
									start: {
										opacity: 0.65,
										backgroundColor: '#fe4d97'
									},
									middle: {
										opacity: 0.65,
										backgroundColor: '#fe4d97'
									},
									end: {
										opacity: 0.65,
										backgroundColor: '#fe4d97'
									},
									single: {
										opacity: 0.65,
										backgroundColor: '#fe4d97'
									}
								},
								blue: {
									start: {
										opacity: 0.65,
										backgroundColor: '#4d7cfe'
									},
									middle: {
										opacity: 0.65,
										backgroundColor: '#4d7cfe'
									},
									end: {
										opacity: 0.65,
										backgroundColor: '#4d7cfe'
									},
									single: {
										opacity: 0.65,
										backgroundColor: '#4d7cfe'
									}
								},
								default: {
									start: {
										opacity: 0.65,
										backgroundColor: '#778ca2'
									},
									middle: {
										opacity: 0.65,
										backgroundColor: '#778ca2'
									},
									end: {
										opacity: 0.65,
										backgroundColor: '#778ca2'
									},
									single: {
										opacity: 0.65,
										backgroundColor: '#778ca2'
									}
								}
							}}
							calendarView='month'
							interactMode={doPrevent ? '' : 'editable'}
							allowNewEvents={time === null}
							orientation='vertical'
							newEvent={newEvent => {
								return newEvent;
							}}
							editEvent={editEvent => {
								let newTime = editEvent.data;
								if (editEvent.data) {
									let newStartDate = new Date(
										editEvent.data.startDate.getFullYear(),
										editEvent.data.startDate.getMonth(),
										editEvent.data.startDate.getDate(),
										editEvent.data.startTime
									);
									let newEndDate = new Date(
										editEvent.data.startDate.getFullYear(),
										editEvent.data.startDate.getMonth(),
										editEvent.data.startDate.getDate(),
										editEvent.data.endTime
									);
									newTime = [newStartDate, newEndDate];
								}
								setTime(newTime);
								if (newTime && checkIfTimeOk(newTime[0])) setValidNotice(true);
								else setValidNotice(false);
								return editEvent;
							}}
							clickEvent={clickEvent => {
								return clickEvent;
							}}
							blockWidth={30}
							blockHeight={15}
							lockOn={lockOn}
							updateLock={newLock => {
								updateLock(newLock);
							}}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setTime([new Date(event.start), new Date(event.end)]);
								setPage(0);
							}}
						>
							Back
						</Button>
						<Button
							icon={doPrevent ? <LoadingOutlined /> : null}
							type='primary'
							disabled={
								!time ||
								(!validNotice && noticeType === 'Notice') ||
								(moment(time[0]).valueOf() === event.start &&
									moment(time[1]).valueOf() === event.end)
							}
							loading={doPrevent}
							onClick={editTime}
						>
							Save
						</Button>
					</Modal.Footer>
				</>
			);
		case 4:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>
							Entry Required?{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								<Button
									onClick={() => setPage(5)}
									type='primary'
									className='rqTypeBtns'
								>
									Yes
								</Button>
								<Button
									onClick={() => {
										setNotice('None');
										setPage(6);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									No
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setNotice(event.document_type || 'None');
								setPage(11);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 5:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>
							Prepare Document{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body
						className='assignVendorMBody text-center'
						style={{ height: '475px' }}
					>
						<Form>
							<Form.Group>
								<Button
									disabled={!validNotice}
									onClick={() => {
										setNotice('Notice');
										setPage(7);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Issue Notice of Entry
								</Button>
								<Button
									onClick={() => {
										setNotice('Consent');
										setPage(8);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Request Consent for Entry
								</Button>
								<Button
									onClick={() => {
										setNotice('Other');
										setPage(9);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Other
								</Button>
								<Button
									onClick={() => {
										setNotice('Emergency');
										setPage(10);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Emergency
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(4);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 6:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Schedule Event{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody'>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>No Entry Required</span>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									setNotice(event.document_type || 'None');
									setPage(4);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 7:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Schedule Event{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>Notice of Entry</span>
									{!validNotice ? (
										<>
											{' '}
											<ExclamationCircleFilled
												style={{ color: '#FE4D97' }}
											/>{' '}
											<span style={{ color: '#FE4D97' }}>
												Notice time is invalid!
											</span>
										</>
									) : null}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig || !validNotice}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
								{/*noticeData ? <object height="700" type="application/pdf" data={noticeData} style={{width: "100%"}}></object> : null*/}
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									setNotice(event.document_type || 'None');
									clearSig();
									setPage(5);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 8:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Schedule Event{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>Request Consent</span>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										requesting consent.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									setNotice(event.document_type || 'None');
									clearSig();
									setPage(5);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 9:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Schedule Event{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<h2>Other</h2>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									setNotice(event.document_type || 'None');
									clearSig();
									setPage(5);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 10:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Schedule Event{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<h2>Emergency</h2>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									setNotice(event.document_type || 'None');
									clearSig();
									setPage(5);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 11:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>
							Update Entry{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								<Button
									icon={
										needUpdate ? (
											<ExclamationCircleFilled className='fs-18' />
										) : null
									}
									onClick={() => setPage(12)}
									type='primary'
									className='rqTypeBtns'
								>
									Update Entry Document
								</Button>
								<Button
									onClick={() => setPage(4)}
									type='primary'
									className='rqTypeBtns'
								>
									Change Entry
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setNotice(event.document_type || 'None');
								setPage(0);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 12:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							Update Entry{' '}
							<span className='fs-14 g-text'>
								{docText[event.document_type || 'None']}
							</span>
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[0]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>{time[1]}</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={
										!isSig || (event.document_type === 'Notice' && !validNotice)
									}
									onClick={editEntry}
									type='primary'
									className='rqTypeBtns'
								>
									Save
								</Button>
								{/*noticeData ? <object height="700" type="application/pdf" data={noticeData} style={{width: "100%"}}></object> : null*/}
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={doPrevent}
							onClick={() => {
								if (!doPrevent) {
									clearSig();
									setPage(11);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 13:
			return (
				<>
					<Modal.Header>
						<div className='fs-18'>
							Update Required <ExclamationCircleFilled className='fs-18' />
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								The documentation associated with this event is out of date with
								changes you or someone else has made.
								<br />
								It is recommended that you update the documentation before
								closing.
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setLastWarn(false);
								props.updateWarn(false);
								setPage(0);
							}}
						>
							Back
						</Button>
						<Button
							onClick={() => {
								setLastWarn(false);
								props.onHide(true);
							}}
						>
							Update Later
						</Button>
					</Modal.Footer>
				</>
			);
		default:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>PAGE NOT FOUND</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group></Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(0);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
	}
}

export function VendorSelectListing({ addFunction, vendList, worker }) {
	return (
		<div
			className='mt-2'
			onClick={() => {
				addFunction(worker.id);
			}}
		>
			<div
				className={
					vendList.includes(worker.id)
						? 'vendor-listing selectedBlue'
						: 'vendor-listing'
				}
			>
				<div className=' d-flex flex-60'>
					<div className='flex-20 listing-icon m-auto'>
						<img
							className='circleImage image32 d-inline'
							src={worker.imageurl}
							alt='avatar'
						/>
					</div>
					<div className='listing-info flex-20 d-inline m-auto fs-14 '>
						{worker.fname} {worker.lname}
					</div>
					<div className='d-flex flex-60'>
						<div className='m-auto fs-list-sm'>{worker.email}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

/*
export function CreateEventModal(props) {
    const {afterEvent,vendors} = props; 
    const [title, setTitle] = useState("");
    const [time, setTime] = useState([null, null]);
    const [submitted,setSubmitted] = useState(false);
    const [selectedVendor,setVendor] = useState("");
    const { width } = useWindowDimensions();
    const [axiosComplete,setComplete] = useState(false);
    const [extraDisabled,setDisabled] = useState([]);
    const [upperLimit,setUpper] = useState(null);

    console.log(props)

    componentDidMount = () => {
      if(props.request.job_subcategory == 'Janitorial/Cleaning'){
        axios
          .post("/getUnitCleaningRanges", {unit_id: props.request.unit_id, job_id: props.job_id})
          .then((response) => {
            setDisabled(response.data.dateObjs);
            setUpper(response.data.upperLimit);
            setComplete(true);
          })
          .catch((response) => {
          });
      } else {
        setComplete(true);
      }
    }

    function disabledDate(current) {
      // Can not select days before today and today
      if (extraDisabled.length === 0 || !current)
        return current && current < moment().subtract(1, "days");
      else {
        return (upperLimit !== null && current >= upperLimit) || current < moment().subtract(1, "days") || extraDisabled.some(function(extraObj){
          return current >= new Date(extraObj.earliestDate) && current <= new Date(extraObj.latestDate);
        });
      }
    }
    const createEvent = () => {
      setSubmitted(true);

      let body = {
        title: title,
        job_id: props.job_id,
        vendor_id:selectedVendor,
        startTime: time[0],
        endTime: time[1],
      };
      axios
      .post("/jobRequest/add/event", body)
      .then((response) => {
        let jrResponse = response;
        afterEvent(jrResponse.data.scheduledEvent);
        props.onHide();
      })
      .catch((error) => {
        console.log(error)
      });

      if(props.request.job_subcategory == 'Janitorial/Cleaning'){
        var cleaningData ={unit_id:props.request.unit_id,startTime:time[0],job_id:props.job_id} 
        axios
          .post("/addCleaningRecord", cleaningData)
          .catch((error) => {
          });
      }
    }
    const selectVendor = (e) => setVendor(e.target.value)
    if (axiosComplete)
      return (
        <>
          <Modal.Header closeButton>
            <div className="fs-18">Schedule Event</div>
          </Modal.Header>
          <Modal.Body className="assignVendorMBody text-center">
            <Form>
              <Form.Group>
                <Form.Label>Event Purpose</Form.Label>
                <Form.Control
                  className="width50 m-auto"
                  type="text"
                  value={title}
                  placeholder={"Title"}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Event Time Range</Form.Label>
                <br />
                <RangePicker
                  showTime
                  disabledDate={disabledDate}
                  size={width > 760 ? "large" : "small"}
                  inputReadOnly
                  format="YYYY-MM-DD h:mm a"
                  onOk={(value) => setTime(value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Select Vendor</Form.Label>
                <Form.Control  
                  as="select" 
                  defaultValue={selectedVendor} 
                  onChange={selectVendor}
                  className="width50 m-auto"
                  >
                    <option disabled hidden value={""}>Select Vendor</option>
                  {vendors.map(v => <option key={v.vendor_id} value={v.vendor_id}>{v.name ? v.name : v.fname+' '+(v.lname ? v.lname : "")}</option>)}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button
              type="primary"
              disabled={time.includes(null) || selectedVendor === "" || title === "" || submitted}
              onClick={createEvent}
            >
              Schedule Work
            </Button>
          </Modal.Footer>
        </>
      );
    else
      null;
  }*/

const EmployeeListing = ({
	person,
	selectedPeople,
	selectPeople,
	id,
	type
}) => {
	let isSelected = selectedPeople[type].includes(person) ? 'selectedBlue' : '';
	return (
		<Card
			className={`mb-2 displayCard ${isSelected}`}
			style={{
				margin: 'auto',
				cursor: 'pointer',
				boxShadow: '0 2px 16px rgba(153, 155, 168, 0.12)'
			}}
			onClick={() => selectPeople(person, type)}
		>
			<div className=' cardMain'>
				<Avatar src={person.imageurl} size={38} className='mr-2' />
				<div className='cardLeft' style={{ textAlign: 'left' }}>
					<div className='cardHeader'>
						<div className='cardTitle'>
							{person.fname} {person.lname}
						</div>
					</div>
					<div
						className='cardLeftFooter g-text-sm'
						style={{ textAlign: 'left' }}
					>
						{person.role}
					</div>
				</div>
			</div>
		</Card>
	);
};

const PersonListing = ({ person, selectedPeople, selectPeople, id, type }) => {
	let isSelected = selectedPeople[type].includes(person) ? 'selectedBlue' : '';
	return (
		<>
			<Card
				className={`mb-2 displayCard ${isSelected}`}
				style={{
					margin: 'auto',
					cursor: 'pointer',
					boxShadow: '0 2px 16px rgba(153, 155, 168, 0.12)'
				}}
				onClick={() => selectPeople(person, type)}
			>
				<div className=' cardMain'>
					<Avatar src={person.imageurl} size={38} className='mr-2' />
					<div className='cardLeft' style={{ textAlign: 'left' }}>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname}
							</div>
						</div>
					</div>
				</div>
			</Card>
		</>
	);
};

export default EditEventModal;
