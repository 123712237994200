import React, { useContext, useState, useEffect } from 'react';
import { createFalse } from 'typescript';
import { FieldTimeOutlined } from '@ant-design/icons';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import { Card, Row, Col, Table, Divider, Alert, message } from 'antd';
import RequestContext from './RequestContext';
import { Button } from 'react-bootstrap';
const gridStyle = {
	width: '25%',
	textAlign: 'center'
};
const columns = [
	{
		title: 'Description',
		dataIndex: 'item'
	},
	{
		title: 'Quantity',
		dataIndex: 'quantity'
	},
	{
		title: 'Vendor',
		dataIndex: 'vendor'
	},
	{
		title: 'Price',
		dataIndex: 'est_price'
	},
	{
		title: 'Type',
		key: 'type',
		render: (_, record) => {
			return record.is_labour === 1 ? 'Labour' : 'Material';
		}
	}
];
const actualExpensesColumns = [
	{
		title: 'Invoice Date',
		dataIndex: 'invoice_date'
	},
	{
		title: 'Invoice Number',
		dataIndex: 'invoice_number'
	},
	{
		title: 'Description',
		dataIndex: 'charge_description',
		width: 120
	},
	{
		title: 'Charge From',
		dataIndex: 'charge_from'
	},
	// {
	// 	title: 'Payment Method',
	// 	dataIndex: 'payment_method'
	// },
	{
		title: ' Charge Amount',
		dataIndex: 'gross_amount'
	},
	{
		title: ' HST Amount',
		dataIndex: 'gst_amount'
	},
	{
		title: ' Total Amount',
		dataIndex: 'total_amount',
		render: (_, record) => {
			let total =
				parseFloat(record.gross_amount) + parseFloat(record.gst_amount);
			return '$' + total.toFixed(2);
		}
	}
];

const WorkDoneReport = props => {
	const context = useContext(RequestContext);

	// console.log(context);
	// console.log(props.currentWorkEvent);

	const [workDetails, setWorkDetails] = useState([]);
	const [workId, setWorkId] = useState(null);

	useEffect(() => {
		axios
			.post('/workOrder/get/workOrderDetailsforReport', {
				// eventId: context.activeEvent.idcalendar_events
				eventId: props.currentWorkEvent.idcalendar_events
			})
			.then(res => {
				setWorkDetails(res.data.details[0]);
				setWorkId(res.data.details[0].wo_id);
			});
	}, []);

	return (
		<div className='site-card-wrapper'>
			{/* {workDetails?.length > 0 ? ( */}
			<>
				<div
					style={{
						marginLeft: '15px',
						marginRight: '15px',
						marginBottom: '15px'
					}}
				>
					<p>
						<span style={{ fontWeight: 'bold', fontSize: 14 }}>Date:</span>{' '}
						<Moment format='MMM Do YYYY '>
							{new Date(props.currentWorkEvent.start)}
						</Moment>
					</p>
					<Divider orientation='center'>
						<div className='WorkOrderReport'>Troubleshooting</div>
					</Divider>
					<div>
						{/* {workDetails.causes ? ( */}
						<>
							<div className='JRSubtitle'>
								Task Issues <br></br>
							</div>
							<div orientation='center'>
								{/* <Alert
									type='info'
									// description={workDetails.workOrders[0].causes}
									description={workDetails.causes}
								/> */}
								{workDetails.causes}
								{/* /> */}
							</div>
						</>
						{/* ) : null} */}
						{workDetails.uploads?.length > 0 ? (
							<>
								<div className='JRSubtitle'>
									Pictures Before Work <br></br>
								</div>
								<div className='mt-2'>
									<props.ImageDisplay
										// images={workDetails.workOrders[0].uploads}
										// images={workDetails.uploads}
										images={
											workDetails.uploads?.length > 0
												? workDetails.uploads.filter(troubleShootImage => {
														return (
															troubleShootImage.wo_upload_purpose ===
															'troubleshoot'
														);
												  })
												: ''
										}
									/>
								</div>
							</>
						) : null}

						{workDetails.estimations?.length > 0 ? (
							<>
								<div className='JRSubtitle'>
									Estimated Expenses <br></br>
								</div>
								<Table
									columns={columns}
									// dataSource={workDetails.workOrders[0].estimations}
									dataSource={workDetails.estimations}
									pagination={false}
								></Table>
							</>
						) : null}
					</div>
					<Divider orientation='center'>
						<div className='WorkOrderReport'>Work Done</div>
					</Divider>
					<div>
						{workDetails.actions?.length > 0 ? (
							<>
								<div display='inline'>
									{' '}
									<FieldTimeOutlined /> Start Time{' --> '}
									{moment(workDetails.actions[0].start_time.valueOf()).format(
										'LT'
									)}
									<span style={{ paddingLeft: '50px' }}>
										<FieldTimeOutlined /> End Time{' --> '}
										{moment(workDetails.actions[0].end_time.valueOf()).format(
											'LT'
										)}
									</span>
								</div>
								<div className='JRSubtitle'>Actions Taken</div>
								<div>
									{/* <Alert
										// type='info'
										description={workDetails.actions[0].action}
										pagination={false}
									/> */}
									{workDetails.actions[0].action}
								</div>
							</>
						) : null}
						{workDetails.uploads?.length > 0 ? (
							<>
								<div className='JRSubtitle'>
									Pictures After Work <br></br>
								</div>

								<div id='requestImages' className='mt-2'>
									<props.ImageDisplay
										// images={workDetails.workOrders[0].uploads}

										images={
											workDetails.uploads?.length > 0
												? workDetails.uploads.filter(troubleShootImage => {
														return (
															troubleShootImage.wo_upload_purpose === 'work'
														);
												  })
												: ''
										}
									/>
								</div>
							</>
						) : null}
						{/* {workDetails.actualExpenses?.length > 0 ? (
							<>
								<div className='JRSubtitle'>
									Actual Expenses <br></br>
								</div>
								<Table
									columns={actualExpensesColumns}
									// dataSource={workDetails.workOrders[0].estimations}
									dataSource={workDetails.actualExpenses}
									pagination={false}
								></Table>
							</>
						) : null} */}
					</div>
				</div>
			</>
			{/* ) : null} */}
		</div>
	);
};

export default WorkDoneReport;
