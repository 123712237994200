import React from 'react';
import { Card, Avatar, Button, Tooltip } from 'antd';
import {
	LoadingOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
const PropertyListing = props => {
	const {
		address,
		units,
		type,
		owner,
		id,
		link,
		onclick,
		userType,
		setPropertyActive,
		active,
		loading,
		mobile,
		isPortfolio
	} = props;
	let isActive = active == 1;
	return (
		<Link
			// to={link ? '/properties/' + id : ''}
			to={{ pathname: '/properties/profile', state: { id: id } }}
			onClick={
				onclick
					? onclick
					: e => {
							if (
								e.target.className &&
								(typeof e.target.className === 'object' ||
									e.target.className.includes('excludeLinkProperty'))
							)
								e.preventDefault();
					  }
			}
		>
			<Card
				style={
					(isActive && !loading) || (loading && !isActive)
						? { margin: 'auto', padding: '8px' }
						: { backgroundColor: '#ececec', margin: 'auto', padding: '8px' }
				}
				className='propertyCard mb-2'
			>
				<div className='propCardTop'>
					<Avatar src={require('../../images/houseExample4.jpg')} size={30} />
					<h5 className=' propAddress titleHover'>{address}</h5>
					{mobile && isPortfolio && userType === 'Manager' ? (
						<ToggleButtons
							id={id}
							isActive={isActive}
							loading={loading}
							setActive={setPropertyActive}
						/>
					) : null}
				</div>
				<div className='propCardBottom'>
					<div className='g-text-sm '>
						{units} {units > 1 ? 'Units' : 'Unit'}
					</div>
					<div className='g-text-sm'>{type}</div>
					<div className='g-text-sm'></div>
				</div>
				<div>
					{!mobile && isPortfolio && userType === 'Manager' ? (
						<ToggleButtons
							id={id}
							isActive={isActive}
							loading={loading}
							setActive={setPropertyActive}
						/>
					) : null}
				</div>
			</Card>
		</Link>
	);
};

const ToggleButtons = props => {
	const { id, isActive, loading, setActive } = props;
	return loading ? (
		<LoadingOutlined style={{ marginLeft: '8px', marginTop: '8px' }} />
	) : (
		<div className='excludeLinkProperty' style={{ display: 'flex' }}>
			{!isActive ? (
				<Tooltip title='Activate'>
					<Button
						className='excludeLinkProperty'
						style={{ color: '#98a9bc' }}
						onClick={() => {
							setActive(id, 1);
						}}
						type='text'
						icon={<CheckCircleOutlined />}
					></Button>
				</Tooltip>
			) : (
				<Button
					className='excludeLinkProperty'
					disabled
					style={{ color: '#6dd230' }}
					type='text'
					icon={<CheckCircleOutlined />}
				></Button>
			)}
			{isActive ? (
				<Tooltip title='Deactivate'>
					<Button
						className='excludeLinkProperty'
						style={{ color: '#98a9bc' }}
						onClick={() => {
							setActive(id, 0);
						}}
						type='text'
						icon={<CloseCircleOutlined />}
					></Button>
				</Tooltip>
			) : (
				<Button
					className='excludeLinkProperty'
					disabled
					style={{ color: '#fe4d97' }}
					type='text'
					icon={<CloseCircleOutlined />}
				></Button>
			)}
		</div>
	);
};

export default PropertyListing;
