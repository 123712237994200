import React from 'react';
import { Card, Collapse } from 'antd';
import statusColors from '../../StringData/StatusColors';
import { Link } from 'react-router-dom';
import './maintenance.css';
import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
import TenantMaintenanceRequest from '../MaintenanceRequest/TenantMaintenanceRequest';
import { Avatar } from 'antd';
import './listing.css';
import axios from 'axios';

import BarCalendar from '../../Components/BarCalendar/BarCalendar';
const cancelToken = axios.CancelToken.source();
/*
Dropdown - Extends to dropdown display when clicked.
*/

export const MaintenanceRequestListing = ({
	selectJobRequest,
	selectjr,
	mobileView,
	request,
	clickable,
	selected = false,
	customLink = null,
	titleColors
}) => {
	return !clickable ? (
		<RequestNotClickable request={request} titleColors={titleColors} />
	) : mobileView ? (
		<MaintenanceRequestMobile
			selectJobRequest={selectJobRequest}
			selectjr={selectjr}
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	) : (
		<MaintenanceRequestCard
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	);
};

const { Panel } = Collapse;

function callback(key) {}
export const CommsMaintenanceRequestListing = ({
	mobileView,
	request,
	clickable,
	acc_type,
	selected = false,
	customLink = null,
	titleColors
}) => {
	if (acc_type.type != 'Manager') {
		return !clickable ? (
			<RequestNotClickable request={request} titleColors={titleColors} />
		) : mobileView ? (
			<CommsMaintenanceRequestMobile
				request={request}
				customLink={customLink}
				mobileView={mobileView}
				selected={selected}
			/>
		) : (
			<Collapse defaultActiveKey={['1']} onChange={callback}>
				<Panel
					header={
						<CommsMaintenanceRequestCard
							request={request}
							mobileView={mobileView}
							selected={selected}
						/>
					}
				>
					<TenantMaintenanceRequest job_id={request.job_id} mobile={true} />
				</Panel>
			</Collapse>
		);
	} else {
		return !clickable ? (
			<RequestNotClickable request={request} titleColors={titleColors} />
		) : mobileView ? (
			<CommsMaintenanceRequestMobile
				request={request}
				customLink={customLink}
				mobileView={mobileView}
				selected={selected}
			/>
		) : (
			<Collapse defaultActiveKey={['1']} onChange={callback}>
				<Panel
					header={
						<CommsMaintenanceRequestCard
							request={request}
							mobileView={mobileView}
							selected={selected}
						/>
					}
				>
					<MaintenanceRequest job_id={request.job_id} mobile={true} />
				</Panel>
			</Collapse>
		);
	}
};

const CommsMaintenanceRequestMobile = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			to={customLink ? customLink : '/Communication/request/' + request.job_id}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								<MaintenanceStatus
									className='rq-status d-inline'
									value={request.job_status}
									status={request.job_status}
								/>
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</Link>
	);
};

const MaintenanceRequestMobile = ({
	selectJobRequest,
	selectjr,
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	let selectedthread = window.location.href.split('/')[4];
	// console.log('>>>>>>>>',request)
	// console.log('>>>>>>>>>>>>>',selectjr)
	if (!selectjr || !request) return null;
	return (
		// <Link
		//   to={customLink ? customLink : "/maintenance/request/" + request.job_id}
		// >
		<div
			className={selectjr.includes(request.job_id) ? 'selectedBlue' : ''}
			style={{ cursor: 'pointer' }}
			onClick={() => {
				selectJobRequest(request.job_id);
			}}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								<MaintenanceStatus
									className='rq-status d-inline'
									value={request.job_status}
									status={request.job_status}
								/>
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</div>
		// </Link>
	);
};
const CommsMaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Card
			bordered={false}
			className={`mb-2 mrdisplayCard ${
				mobileView ? 'maintenanceMobileView' : 'displayCard'
			} ${selected ? 'maintenanceCardSelected' : ''}`}
			style={{ margin: 'auto' }}
		>
			<div className='cardMain'>
				<Avatar size={38} className='mr-2' style={{ backgroundColor: 'unset' }}>
					<img
						alt='Maintenance Category'
						width={18}
						height={18}
						src={require('../../icons/maintenanceIcons/' +
							MaintenanceIcons(request.job_subcategory))}
					/>
				</Avatar>
				<div className='cardLeft'>
					<div className='cardHeader'>
						<div className='caption titleHover'>{request.job_title}</div>
					</div>
					<div className='cardLeftFooter g-text-sm'>
						<div className='cardFooterItem'>ID JR{request.job_id}</div>
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
						<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
							{request.elapsedTime
								? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
								: ''}
						</div>
					</div>
				</div>
			</div>
			<div className={`cardRight`}>
				<div
					className={`cardRightHeader ${
						request.vendor ? '' : 'rightHeaderMxAuto'
					}`}
				>
					<MaintenanceStatus
						className='rq-status d-inline'
						value={request.job_status}
						status={request.job_status}
					/>
					&nbsp;&middot;&nbsp;
					<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
				</div>
			</div>
		</Card>
	);
};

const MaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			to={customLink ? customLink : '/maintenance/request/' + request.job_id}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard ${
					mobileView ? 'maintenanceMobileView' : 'displayCard'
				} ${selected ? 'maintenanceCardSelected' : ''}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							<div className='cardFooterItem ml-4'>
								{request.unit_name !== 'default' &&
								request.unit_name !== 'basic'
									? request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', Unit ' +
									  request.unit_name +
									  ', ' +
									  request.propertyCity
									: request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', ' +
									  request.propertyCity}
							</div>
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							className='rq-status d-inline'
							value={request.job_status}
							status={request.job_status}
						/>
						&nbsp;&middot;&nbsp;
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
				</div>
			</Card>
		</Link>
	);
};

const RequestNotClickable = props => {
	const { request, titleColors } = props;
	return (
		<div>
			<Card bordered={false} className='mb-2 displayCard nonClickableCard'>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: '#e3e3e3' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='fs-14'>
								{titleColors ? (
									<MaintenanceStatus
										status={request.job_status}
										className='rq-status d-inline'
										value={request.job_title}
									/>
								) : (
									request.job_title
								)}
							</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							{request.propertyStreet !== null ? (
								<div className='cardFooterItem ml-4'>
									{request.unit_name !== 'default' &&
									request.unit_name !== 'basic'
										? request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', Unit ' +
										  request.unit_name +
										  ', ' +
										  request.propertyCity
										: request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', ' +
										  request.propertyCity}
								</div>
							) : (
								''
							)}
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							status={request.job_status}
							className='rq-status d-inline'
							value={request.job_status}
						/>
						&nbsp;&middot;{' '}
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
					{request.vendor ? (
						<div className='cardRightFooter vendorNameMListing g-text-sm'>
							{`Vendor: ${request.vendor.fname} ${
								request.vendor.lname ? request.vendor.lname : ''
							}`}
						</div>
					) : (
						''
					)}
				</div>
			</Card>
		</div>
	);
};

export const MaintenanceStatus = ({ status, value, className = '' }) => {
	let fontColor = statusColors.find(s => s.value === status);
	let color = fontColor !== undefined ? fontColor.color : '';
	return <div className={color + ' ' + className}>{value}</div>;
};

export const MaintenanceIcons = status => {
	if (status === undefined) return 'repair.png';
	switch (status) {
		case 'Access/Security':
			return 'security.png';
		case 'Appliances':
			return 'appliances.png';
		case 'Electrical':
			return 'electrical.png';
		case 'General Repair':
			return 'repair.png';
		case 'Heating':
			return 'heating.png';
		case 'Janitorial/Cleaning':
			return 'cleaning.png';
		case 'Landscaping':
			return 'landscaping.png';
		case 'Property Management':
			return 'propertyManagement.png';
		case 'Plaster/Paint':
			return 'painting.png';
		case 'Plumbing':
			return 'plumbing.png';
		case 'Snow Clearing':
			return 'snowClearing.png';
		case 'Vendilation/AC':
			return 'ventilation.png';
		case 'Other':
			return 'repair.png';
		default:
			return 'repair.png';
	}
};
