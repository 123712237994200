import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import {
	usePropertyState,
	usePropertyDispatch
} from '../../Context/PropertyContext';
import LoadScreen from '../../Components/LoadScreen';
import { PropertyListingJobRequest } from '../../Components/PropertyListing';
import axios from 'axios';

import { Searchbar } from '../../Components/Searchbar/Searchbar';
import {
	FooterNext,
	FooterBoth,
	FooterEnd
} from '../../Components/FooterButtons';
import './jobRequest.css';
import { Button, Progress } from 'antd';
import Dropzone from 'react-dropzone'; //TODO: Remove and replace with ant
import { StepProgress } from '../../Components/StepProgress';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
const cancelToken = axios.CancelToken.source();

/*  TODO
-Change description and title to different names - prompt user to enter as much detail as possible
-Add option to add custom file names so they appear nicer in the job request 
-Add error handling to property list - if not loaded in x seconds, push an error
-Add better styling to review page
*/

class CreateMaintenanceRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			type: this.props.type,
			property: null,
			unit: null,
			title: '',
			category: null,
			description: '',
			appliance: {
				needed: false,
				type: '',
				make: '',
				modelNumber: '',
				serialNumber: ''
			},
			files: [],
			errorMsg: [],
			reviewConfirmed: false,
			disableNext: true
		};
	}

	selectProperty = property => {
		this.setState({ property: property.property, unit: property.unit });
	};
	selectType = type => {
		this.setState({ type: type });
	};
	selectCategory = category => {
		this.setState({ category: category });
	};
	selectDescription = description => {
		this.setState({ description: description });
	};
	selectTitle = title => {
		this.setState({ title: title });
	};
	confirmReview = () => {
		this.setState({ reviewConfirmed: !this.state.reviewConfirmed });
	};
	setAppliance = (field, value) => {
		this.setState({ appliance: { ...this.state.appliance, [field]: value } });
	};
	selectFiles = files => {
		let currentFiles = this.state.files;
		let errorMsg = [];
		for (let i of files) {
			let exist = currentFiles.findIndex(file => file.name === i.name);
			if (exist === -1) currentFiles.push(i);
			else {
				errorMsg.push(
					'Error: A file with name ' +
						i.name +
						' is already uploaded. This file has been skipped.'
				);
			}
		}
		this.setState({ files: currentFiles, errorMsg: errorMsg });
	};

	removeFile = name => {
		let index = this.state.files.findIndex(file => file.name === name);
		if (index !== -1) {
			let current = this.state.files;
			current.splice(index, 1);
			this.setState({ files: current, errorMsg: [] });
		} else
			this.setState({
				errorMsg: [
					'Something went wrong when trying to delete this file. Please try again.'
				]
			});
	};

	sendRequest = () => {
		this.setState({ page: 10 }); //Loading

		axios
			.post('/jobRequest/add/maintenanceRequest', {
				type: this.state.type,
				property: this.state.property,
				unit: this.state.unit,
				title: this.state.title,
				category: this.state.category,
				description: this.state.description,
				appliance: this.state.appliance,
				host: window.location.host
			})

			.then(response => {
				if (response.data.ok) {
					if (this.state.files.length > 0) {
						var form = new FormData();
						for (var x = 0; x < this.state.files.length; x++) {
							form.append('file', this.state.files[x]);
						}
						form.append('job_id', response.data.job_id);
						axios.post('/jobRequest/add/images', form).then(response2 => {
							if (response2.data.ok) {
								this.props.history.push(
									`/${
										{
											'Maintenance Request': 'maintenance',
											'Service Request': 'administrative'
										}[this.state.type]
									}/request/${response.data.job_id}`
								);
								this.setState({ page: 6 });
							}
						});
					} else {
						this.props.history.push(
							`/${
								{
									'Maintenance Request': 'maintenance',
									'Service Request': 'administrative'
								}[this.state.type]
							}/request/${response.data.job_id}`
						);
						this.setState({ page: 6 });
					}
					window.location.reload();
				} else this.setState({ page: 11 }); //default error
			});
	};

	render() {
		console.log(this.state.unit);
		let header, footer, body;
		let appPage = this.state.category == 'Appliances' ? 1 : 2; //If appliance needed, go to next page, if not, skip appliance page
		switch (this.state.page) {
			case 0:
				body = (
					// 新需求的身体部分
					<PropertySelect
						selectProperty={this.selectProperty}
						selectedUnit={
							this.state.unit !== null ? this.state.unit : { unit_id: null }
						}
						selectedProperty={
							this.state.property !== null
								? this.state.property
								: { propertyid: null }
						}
					/>
				);
				header = 'Select your Property';
				footer = (
					<FooterBoth
						nextDisabled={
							this.state.property == null ||
							(this.state.unit.unit_id == null &&
								this.state.property.units.length > 1)
						}
						clickNext={() => this.setState({ page: this.state.page + 1 })}
						clickBack={this.props.backToSelect}
						backName={'Cancel'}
					/>
				);
				break;

			case 1:
				body = (
					<TypeSelect
						type={this.state.type}
						selectType={this.selectType}
						category={this.state.category}
						selectCategory={this.selectCategory}
					/>
				);
				header = 'Select Request Category';
				footer = (
					<FooterBoth
						nextDisabled={this.state.category == null}
						clickNext={() => this.setState({ page: this.state.page + 1 })}
						clickBack={() => this.setState({ page: this.state.page - 1 })}
					/>
				);
				break;

			case 2:
				body = (
					<DescriptionSelect
						applianceNeeded={this.state.appliance.needed}
						needAppliance={() =>
							this.setState({
								appliance: {
									...this.state.appliance,
									needed: !this.state.appliance.needed
								}
							})
						}
						description={this.state.description}
						title={this.state.title}
						selectDescription={this.selectDescription}
						selectTitle={this.selectTitle}
					/>
				);
				header = 'Details About The Request';
				footer = (
					<FooterBoth
						nextDisabled={
							this.state.title === '' || this.state.description === ''
						}
						clickNext={() => this.setState({ page: this.state.page + appPage })}
						clickBack={() => this.setState({ page: this.state.page - 1 })}
					/>
				);
				break;
			case 3:
				body = (
					<ApplianceSelect
						appliance={this.state.appliance}
						setAppliance={this.setAppliance}
					/>
				);
				header = 'Details About The Appliance';
				footer = (
					<FooterBoth
						nextDisabled={
							this.state.appliance.type === '' ||
							this.state.appliance.make === '' ||
							this.state.appliance.modelNumber === '' ||
							this.state.appliance.serialNumber === ''
						}
						clickNext={() => this.setState({ page: this.state.page + 1 })}
						clickBack={() => this.setState({ page: this.state.page - 1 })}
					/>
				);
				break;
			case 4:
				body = (
					<FileSelect
						selectFiles={this.selectFiles}
						files={this.state.files}
						removeFile={this.removeFile}
					/>
				);
				header = 'Relevant Files';
				footer = (
					<FooterBoth
						clickNext={() => this.setState({ page: this.state.page + 1 })}
						clickBack={() => this.setState({ page: this.state.page - appPage })}
					/>
				);
				break;
			case 5:
				body = (
					<ReviewRequest
						request={this.state}
						confirmReview={this.confirmReview}
					/>
				);
				header = 'Review Request';
				footer = (
					<FooterEnd
						clickBack={() => this.setState({ page: this.state.page - 1 })}
						clickEnd={this.sendRequest}
					/>
				);
				break;
			case 6:
				setTimeout(() => {
					if (this.props.open) this.props.openRequest();
				}, 1000);
				body = (
					<div>
						<ConfirmSent
							request={this.state}
							confirmReview={this.confirmReview}
						/>
					</div>
				);
				header = 'Request Complete';
				break;
			case 10:
				body = <LoadScreen />;
				header = 'Sending Request';
				break;

			default:
				body = <div>Oops! Something went wrong. Please try again.</div>;
		}

		return (
			<>
				<Modal.Header>
					<Modal.Title id='contained-modal-title-vcenter'>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modal-body height500Fixed pb-0'>
					<div className='height95'>{body}</div>
					<div className='fs-13 errorMsg'>{this.state.errorMsg} </div>
				</Modal.Body>
				<Modal.Footer id='requestFooter'>
					<div style={{ flex: '50' }}>
						<Progress
							percent={20 * this.state.page}
							size='small'
							showInfo={false}
						/>
					</div>
					<div style={{ flex: '50', textAlign: 'end' }}>{footer}</div>
				</Modal.Footer>
			</>
		);
	}
}

function PropertySelect({ selectedProperty, selectedUnit, selectProperty }) {
	const addUnit = unit => {
		if (unit.unit_id === selectedUnit.unit_id)
			selectProperty({ property: selectedProperty, unit: { unit_id: null } });
		else selectProperty({ property: selectedProperty, unit: unit });
	};
	const addPropUnit = id => {
		let property = properties.find(property => property.propertyid === id);
		selectProperty({ property: property, unit: property.units[0] });
	};
	const addProperty = id => {
		let property = properties.find(property => property.propertyid === id);
		selectProperty({ property: property, unit: selectedUnit });
	};
	const { property_update, properties, purpose } = usePropertyState();
	let dispatch = usePropertyDispatch();
	let currentTime = new Date().getTime();

	const [searchValue, search] = useState('');

	if (currentTime - property_update > 60000 || purpose !== 'request') {
		axios
			.get('/getProperties', { crossdomain: true })
			.then(function (response) {
				dispatch({
					state: {
						properties: response.data.properties,
						property_update: currentTime,
						purpose: 'request'
					},
					type: 'update'
				});
			});
		return <LoadScreen />;
	} else if (properties == null) {
		return (
			<div>
				You are not connected to any properties. Please connect to a property
				before sending a request.
				<br />
				If you believe this is an error, please submit a bug report and try
				again later.
			</div>
		);
	} else if (properties === undefined) {
		return <div>Something went wrong. Please try signing in again.</div>;
	} else {
		return (
			<div>
				<Searchbar onChange={e => search(e.target.value)} value={searchValue} />
				{properties
					.filter(
						property =>
							(
								property.propertynumber +
								' ' +
								property.propertyStreet +
								', ' +
								property.propertyCity +
								', ' +
								property.propertyProvince +
								' ' +
								property.propertyPostalcode
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase()) && property.active == 1
					)
					.map(property => (
						<PropertyListingJobRequest
							addPropUnit={() => addPropUnit(property.propertyid)}
							addUnit={addUnit}
							addProperty={() => addProperty(property.propertyid)}
							icon='propertyExample1.jpg'
							units={property.units}
							total_units={property.total_units}
							type={property.propertyType}
							address={
								property.propertynumber +
								' ' +
								property.propertyStreet +
								', ' +
								property.propertyCity +
								', ' +
								property.propertyProvince
							}
							key={property.propertyid}
							selected={
								selectedProperty.propertyid === property.propertyid
									? true
									: false
							}
							selectedUnit={selectedUnit.unit_id}
						/>
					))}
			</div>
		);
	}
}

function ConfirmSent() {
	return <div>Your Request has been sent!</div>;
}

function ApplianceSelect({ appliance, setAppliance }) {
	return (
		<Form>
			<Form.Group>
				<Form.Label>Appliance Type</Form.Label>
				<Form.Control
					type='text'
					value={appliance.type}
					name='type'
					onChange={e => setAppliance(e.target.name, e.target.value)}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Model Name</Form.Label>
				<Form.Control
					type='text'
					value={appliance.make}
					name='make'
					onChange={e => setAppliance(e.target.name, e.target.value)}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Model Number</Form.Label>
				<Form.Control
					type='text'
					value={appliance.applianceNumber}
					name='modelNumber'
					onChange={e => setAppliance(e.target.name, e.target.value)}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Serial Number</Form.Label>
				<Form.Control
					type='text'
					value={appliance.serialNumber}
					name='serialNumber'
					onChange={e => setAppliance(e.target.name, e.target.value)}
				/>
			</Form.Group>
		</Form>
	);
}

function ReviewRequest({ request, confirmReview }) {
	return (
		<div className='h100'>
			<div className='height98'>
				<LinedLabel
					label='Property'
					value={
						request.unit.unit_name === 'default' ||
						request.unit.unit_name === 'basic'
							? request.property.propertynumber +
							  ' ' +
							  request.property.propertyStreet +
							  ', ' +
							  request.property.propertyCity +
							  ', ' +
							  request.property.propertyProvince +
							  ' ' +
							  request.property.propertyPostalcode
							: request.property.propertynumber +
							  ' ' +
							  request.property.propertyStreet +
							  ' Unit ' +
							  request.unit.unit_name +
							  ', ' +
							  request.property.propertyCity +
							  ', ' +
							  request.property.propertyProvince +
							  ' ' +
							  request.property.propertyPostalcode
					}
				/>
				<LinedLabel label='Type' value={request.type} />
				<LinedLabel label='Category' value={request.category} />
				<LinedLabel label='Subject' value={request.title} />
				<label className='mt-2'>
					<u>Description</u>
				</label>
				<div>{request.description}</div>
				<label className='mt-2'>
					<u>Files</u>
				</label>
				<ul>
					{request.files.map(file => (
						<li key={file.path}>
							<div className=' d-inline fs-14 mr-2'>{file.name}&nbsp;</div>
							<div className='d-inline fs-10'>
								({(parseFloat(file.size) / 1000).toFixed(2)} KB)
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

function LinedLabel({ label, value }) {
	return (
		<div className='d-flex mb-2'>
			<label className='flex-20'>{label}:</label>
			<div className='flex-80'>{value}</div>
		</div>
	);
}

class FileSelect extends React.Component {
	render() {
		return (
			<div>
				<Dropzone
					onDrop={acceptedFiles => this.props.selectFiles(acceptedFiles)}
				>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps()} className='fileSelectBox text-center'>
								<input {...getInputProps()} />
								<p>Drag 'n' drop some files here, or click to select files</p>
							</div>
						</section>
					)}
				</Dropzone>
				<h5 className='mt-2'>Files</h5>
				{this.props.files.length > 0 ? (
					<ul>
						{this.props.files.map(file => (
							<li key={file.path}>
								<img
									src={require('../../icons/delete2.png')}
									alt='delete'
									onClick={() => this.props.removeFile(file.name)}
									className='mr-2 deleteBtn'
								></img>
								<div className=' d-inline fs-14 mr-2'>{file.name}&nbsp;</div>
								<div className='d-inline fs-10'>
									({(parseFloat(file.size) / 1000).toFixed(2)} KB)
								</div>
							</li>
						))}
					</ul>
				) : (
					<div>No Files Uploaded.</div>
				)}
			</div>
		);
	}
}

class DescriptionSelect extends React.Component {
	render() {
		return (
			<Form>
				<Form.Group>
					<Form.Label>
						<div className='caption'>
							What is the <b>problem?</b>
						</div>
					</Form.Label>
					<Form.Control
						type='text'
						placeholder='Request subject'
						value={this.props.title}
						onChange={e => this.props.selectTitle(e.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<div className='caption'>
							Please provide a <b>detailed description</b> of the problem to
							help us better solve it.
						</div>
					</Form.Label>
					<Form.Control
						as='textarea'
						rows='5'
						style={{ fontSize: '14px' }}
						placeholder='Request Description'
						value={this.props.description}
						onChange={e => this.props.selectDescription(e.target.value)}
					/>
				</Form.Group>
			</Form>
		);
	}
}

class TypeSelect extends React.Component {
	subcategories = [
		'Access/Security',
		'Appliances',
		'Electrical',
		'General Repair',
		'Heating',
		'Janitorial/Cleaning',
		'Landscaping',
		'Property Management',
		'Plaster/Paint',
		'Plumbing',
		'Snow Clearing',
		'Ventilation/AC',
		'Other'
	];
	serviceSubcategories = [
		'Unit inspection',
		'Question about lease',
		'Question about rent',
		'Other'
	];
	// Retrieve this from server in the future
	render() {
		let torender = [];
		if (this.props.type === 'Maintenance Request') {
			torender = this.subcategories.map((value, index) => (
				<SmallListing
					value={value}
					key={index}
					onClick={() => this.props.selectCategory(value)}
					className={value !== this.props.category ? '' : 'selectedBlue'}
				/>
			));
		} else if (this.props.type === 'Service Request') {
			torender = this.serviceSubcategories.map((value, index) => (
				<SmallListing
					value={value}
					key={index}
					onClick={() => this.props.selectCategory(value)}
					className={value !== this.props.category ? '' : 'selectedBlue'}
				/>
			));
		} else torender = '';
		return (
			<div id='categoryList'>
				<Form>
					<Form.Group controlId='formCategory'>
						<div>{torender}</div>
					</Form.Group>
				</Form>
			</div>
		);
	}
}
function SmallListing({ value, className, onClick }) {
	return (
		<div
			className={`smallListing ${className}`}
			onClick={onClick}
			value={value}
		>
			{value}
		</div>
	);
}

export default CreateMaintenanceRequest;
