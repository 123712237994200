import axios from 'axios';

import React, { createContext } from 'react';
const cancelToken = axios.CancelToken.source();

export const RequestContext = createContext();

const types = { ONE: 'maintenance', TWO: 'administrative' };

class RequestProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			requests: null,
			lastUpdate: 0,
			lastThread: null,
			/**
             * Fetches a list of requests for this user from the server.
    
             */
			fetchRequests: (refresh, thread_limit) => {
				return new Promise((resolve, reject) => {
					const d = new Date().getTime();
					if (
						!(d - this.state.lastUpdate > 300000) &&
						thread_limit === this.state.lastThread &&
						!refresh
					)
						return resolve();
					if (!thread_limit) {
						axios
							.get('/jobRequest/get/allRequests')
							.then(response => {
								this.setState({
									requests: response.data.requests,
									lastUpdate: d,
									lastThread: thread_limit
								});
								return resolve();
							})
							.catch(err => reject());
					} else {
						axios
							.get('/jobRequest/get/allRequests/' + thread_limit)
							.then(response => {
								this.setState({
									requests: response.data.requests,
									lastUpdate: d,
									lastThread: thread_limit
								});
								return resolve();
							})
							.catch(err => reject());
					}
				});
			},
			/**
			 * Get a list of maintenance requests
			 * @param {'maintenance'|'administrative'|''} type
			 * @returns {object[]} requests
			 */
			getRequests: (refresh, type = '', thread_limit = null) => {
				return new Promise((resolve, reject) => {
					this.state
						.fetchRequests(refresh, thread_limit)
						.then(() => {
							if (type === 'maintenance')
								return resolve(
									this.state.requests.filter(
										r =>
											String(r.job_category).toLowerCase() ===
											'maintenance request'
									)
								);
							else if (type === 'administrative')
								return resolve(
									this.state.requests.filter(
										r =>
											String(r.job_category).toLowerCase() !==
											'maintenance request'
									)
								);
							else return resolve(this.state.requests);
						})
						.catch(err => reject(err));
				});
			},
			/**
			 * Add a new request to the request list
			 * @param {object} request
			 */
			addRequest: (request = {}) =>
				this.setState({ requests: [...this.state.requests, request] })
		};
	}
	render() {
		return (
			<RequestContext.Provider value={this.state}>
				{this.props.children}
			</RequestContext.Provider>
		);
	}
}

export default RequestProvider;
