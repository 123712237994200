import React from "react";
import { Button } from "antd";

class Expenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCost: this.props.expenseData.totalCost,
      taxIncluded: this.props.expenseData.taxIncluded,
      transactions: this.props.expenseData.transactions, // {receipt:"",vendor:"",amount:0,comment:""}
      images: this.props.expenseData.images,
      currentTask: "Display",
      currentTransaction: "",
    };
    this.updateTotal = this.updateTotal.bind(this);
  }

  updateTotal = () => {
    let cost = 0;

    this.state.transactions.forEach((i) => {
      if (isNaN(parseFloat(i.amount))) {
        cost += 0;
      } else cost += parseFloat(i.amount);
    });
    if (this.state.taxIncluded) cost = cost * 1.13;
    cost = cost.toFixed(2);
    this.setState({ totalCost: cost });
  };
  updateLabourCost = (e) => {
    this.setState({ labourCost: e.target.value }, this.updateTotal());
  };
  updateTaxes = () => {
    if (this.state.taxIncluded)
      this.setState({ taxIncluded: false }, this.updateTotal);
    else this.setState({ taxIncluded: true }, this.updateTotal);
  };
  newTransaction = () => {
    let row_ids = [];
    for (let i of this.state.transactions) {
      row_ids.push(i.id);
    }
    let newid;
    if (row_ids.length > 0) newid = Math.max.apply(Math, row_ids) + 1;
    else newid = 1;
    this.setState({
      currentTransaction: {
        id: newid,
        receipt: "",
        vendor: { id: null, name: "" },
        amount: 0,
        tax: "No Tax",
        comment: "",
      },
    });
    this.setState({ currentTask: "New" });
  };

  addTransaction = (transaction) => {
    let arr = this.state.transactions;
    let curr = arr.findIndex((x) => x.id === transaction.id);
    if (curr === -1) {
      arr.push(transaction);
    } else {
      arr[curr] = transaction;
    }
    this.setState({ transactions: arr });
    this.setState({ currentTask: "Display" });
    this.updateTotal();
  };

  editTransaction = (id) => {
    let curr = this.state.transactions.find((x) => x.id === id);
    this.setState({ currentTransaction: curr });
    this.setState({ currentTask: "New" });
  };
  deleteTransaction = (id) => {
    let curr = this.state.transactions.findIndex((x) => x.id === id);
    if (curr === -1) {
      this.setState({ currentTask: "Display" });
      return;
    }
    let arr = this.state.transactions;
    for (var i = 0; i < arr.length; i++) {
      if (i === curr) {
        arr.splice(i, 1);
      }
    }
    this.setState({ transactions: arr });
    this.setState({ currentTask: "Display" });
  };
  endExpenses = () => {
    this.props.updateExpenses(
      this.state.totalCost,
      this.state.taxIncluded,
      this.state.transactions
    );
  };
  render() {
    let torender;
    let addRecord;

    if (this.state.currentTask === "Display") {
      torender = (
        <TransactionDisplay
          transactions={this.state.transactions}
          editTransaction={this.editTransaction}
        />
      );
      addRecord = (
        <div className='text-right'>
          <h7 id='addMaterial' onClick={this.newTransaction}>
            Add Transaction Record
          </h7>{" "}
        </div>
      );
    } else if (this.state.currentTask === "New")
      torender = (
        <AddTransaction
          vendorList={this.props.vendorList}
          addTransaction={this.addTransaction}
          deleteTransaction={this.deleteTransaction}
          transaction={this.state.currentTransaction}
        />
      );

    return (
      <div>
        <h4 className='aligncenter'>Expenses</h4>
        <br />
        {torender}
        <br />
        {addRecord}

        <div className='borderLine mb-2'></div>
        <br />
        <div className='fixedLine mt-2'>
          <label className='txtLabel'>Final Cost: </label>
          <label className='d-inline startUnits'>$ </label>
          <input
            type='number'
            id='materialCost'
            value={this.state.totalCost}
            className='form-control mr-2 d-inline txtInput'
            disabled></input>
          <label className='d-inline endUnits'></label>
        </div>
        <div id='btnGroup' className='buttonGroup text-right'>
          <Button className=' ' onClick={this.endExpenses}>
            Send Transactions
          </Button>
        </div>
      </div>
    );
  }
}

class AddTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.transaction.id,
      receipt: this.props.transaction.receipt,
      amount: this.props.transaction.amount,
      vendor: this.props.transaction.vendor,
      comment: this.props.transaction.comment,
      tax: this.props.transaction.tax,
      other: false,
      errormsg: "",
    };
  }
  componentDidMount = () => {
    if (this.props.transaction.vendor.id == 0) {
      this.setState({ other: true });
    }
  };
  addReceipt = (e) => {
    this.setState({ receipt: e.target.value });
  };
  addAmount = (e) => {
    this.setState({ amount: e.target.value });
  };
  addVendor = (e) => {
    if (e.target.value != 0) {
      let vendor = this.props.vendorList.find((x) => x.id == e.target.value);
      this.setState({ vendor: { id: e.target.value, name: vendor.name } });
    } else this.setState({ other: true, vendor: { id: 0, name: "" } });
  };
  updateOtherName = (e) => {
    this.setState({ vendor: { id: 0, name: e.target.value } });
  };
  addComments = (e) => {
    this.setState({ comment: e.target.value });
  };
  done = () => {
    if (this.verify()) {
      let transaction = {
        id: this.state.id,
        receipt: this.state.receipt,
        amount: parseFloat(this.state.amount),
        tax: this.state.tax,
        vendor: this.state.vendor,
        comment: this.state.comment,
      };
      this.props.addTransaction(transaction);
    }
  };
  verify = () => {
    if (this.state.receipt === "") {
      this.setState({ errormsg: "Please enter a receipt number." });
      return false;
    } else if (this.state.amount === "") {
      this.setState({
        errormsg: "Please enter the receipt amount or 0 if none.",
      });
      return false;
    }
    return true;
  };
  deleteTransaction = () => {
    this.props.deleteTransaction(this.state.id);
  };
  updateTax = (e) => {
    this.setState({ tax: e.target.value });
  };

  render() {
    let vendors = [];
    let other;
    vendors.push(
      <option value={null} hidden>
        Vendor
      </option>
    );
    for (let i of this.props.vendorList) {
      vendors.push(<option value={i.id}>{i.name}</option>);
    }
    vendors.push(<option value={0}>Other</option>);
    if (this.state.other) {
      other = (
        <div className='fixedLine mt-2'>
          <label className='txtLabel'>Vendor Name</label>
          <input
            className='form-control txtInput'
            onChange={this.updateOtherName}
            value={this.state.vendor.name}
          />
        </div>
      );
    }

    return (
      <div className='aligncenter'>
        <div className='fixedLine'>
          <label className='txtLabel '>Receipt Number</label>
          <label className='d-inline startUnits'> </label>
          <input
            className='form-control txtInput mb-2'
            onChange={this.addReceipt}
            value={this.state.receipt}
            required
          />
        </div>

        <div className='fixedLine'>
          <label className='txtLabel '>Amount</label>
          <label className='d-inline startUnits m-auto'>$</label>
          <input
            type='number'
            min='0'
            className='form-control txtInput mb-2'
            onChange={this.addAmount}
            name='quantity'
            value={this.state.amount}
            required></input>
        </div>
        <div className='text-right'>
          <label className='txtLabel mr-4 '>
            Tax Included
            <input
              className='ml-2'
              name='tax'
              value='Tax Included'
              onChange={this.updateTax}
              type='radio'
            />
          </label>
          <label className='txtLabel'>
            No Tax
            <input
              className='ml-2'
              name='tax'
              value='No Tax'
              onChange={this.updateTax}
              checked
              type='radio'
            />
          </label>
        </div>
        <br />
        <div className='fixedLine'>
          <label className='txtLabel'>Vendor</label>
          <select
            className='form-control txtInput drop_assingned mb-2'
            onChange={this.addVendor}
            defaultValue={this.state.vendor.name}>
            {vendors}
          </select>
        </div>
        {other}
        <label className='txtLabel '>Comments</label>
        <textarea
          type='number'
          min='0'
          className='form-control txtInput mb-2'
          onChange={this.addComments}
          name='cost'
          value={this.state.comment}></textarea>

        <p className='errorMsg'>{this.state.errormsg}</p>
        <div className='text-right'>
          <Button className=' ' onClick={this.done}>
            Add Transaction
          </Button>
        </div>

        <div className='text-right'>
          <h7 id='delMaterial' onClick={this.deleteTransaction}>
            Delete this Transaction
          </h7>{" "}
        </div>
      </div>
    );
  }
}

class TransactionDisplay extends React.Component {
  editRow = (id) => {
    this.props.editTransaction(id);
  };

  render() {
    let rows = [],
      tableheader;
    if (this.props.transactions.length > 0) {
      tableheader = [
        <tr>
          <th style={{ width: "30%" }}> Receipts </th>{" "}
          <th style={{ width: "15%" }}> Amount</th>
          <th style={{ width: "20%" }}> Vendor </th>{" "}
          <th style={{ width: "35%" }}> Comments </th>
        </tr>,
      ];

      for (let i of this.props.transactions) {
        rows.push(
          <TransactionRow
            id={i.id}
            editRow={this.editRow}
            receipt={i.receipt}
            amount={i.amount}
            vendor={i.vendor}
            comment={i.comment}
          />
        );
      }
    } else {
      tableheader =
        "No transactions records added. Click 'Add Transaction Record' to enter a transaction.";
    }

    return (
      <table id='transactionDisplay'>
        <thead>{tableheader}</thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

class TransactionRow extends React.Component {
  editRow = () => {
    this.props.editRow(this.props.id);
  };

  render() {
    return (
      <tr className='expRow' onClick={this.editRow}>
        <td>{this.props.receipt}</td>
        <td>${this.props.amount}</td>
        <td>{this.props.vendor.name}</td>
        <td>{this.props.comment}</td>
      </tr>
    );
  }
}

//  transaction display when 1 or more transactions appear
// Add transaction opens up the form - not added until form is completed
// Can edit transactions by clicking on them and appear in form format

export default Expenses;
