import React from 'react';
import { message } from 'antd';
import Task from '../../../Components/Tasks/Task';
import WorkOrderEntry from '../../../Modals/WorkOrder/WorkOrderEntry';
import AcceptEvent from '../../../Modals/WorkOrder/Pages/AcceptEvent';
import TenantConsent from '../../../Modals/WorkOrder/Pages/TenantConsent';
import RequestContext from '../RequestContext';
import axios from 'axios';
import Moment from 'moment';

export class WorkEntryAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			entryModal: false,
			acceptModal: false,
			tenantConsentModal: false,
			entryRequired: true,
			modalLoading: false,

			// Changes entryContext.entryRequired depending on Entry Required options on EntryDetails.js
			updateEntryRequired: checkedValue => {
				if (checkedValue.indexOf(this.state.entryRequired) !== -1) {
					this.setState({ entryRequired: !this.state.entryRequired });
				}
			}
		};
	}

	openEntryModal = () => {
		this.setState({ entryModal: !this.state.entryModal });
	};

	openAcceptModal = () => {
		this.setState({ acceptModal: !this.state.acceptModal });
	};

	openTenantConsentModal = () => {
		this.setState({ tenantConsentModal: !this.state.tenantConsentModal });
	};

	setModalLoading = () => {
		this.setState({ modalLoading: !this.state.modalLoading });
	};

	submitEntry = () => {
		this.setModalLoading();
		axios
			.post('workOrder/add/session', {
				job_id: this.context.request.job_id,
				entry_required: this.state.entryRequired,
				event_id: this.context.activeEvent.idcalendar_events
			})
			.then(response => {
				this.setModalLoading();
				this.openEntryModal();
				// this.context.addValidEventsForActions(
				// 	response.data.request.workSchedule
				// );
				this.context.updateRequestWorkOrders(
					this.context.activeEvent.idcalendar_events
				);
				this.context.updateEventStatus('Work Order - Troubleshoot');
				message.success('Entry has been successfully submitted!', 2.5);
				// window.location.reload();
			})
			.catch(err => console.log(err));
	};

	render() {
		// console.log(this.context);
		// console.log(this.context.activeEvent);

		// let acceptEvent = this.context.eventToAccept;
		// if (this.context.activeEvent !== null && this.context.eventCurrentUserAccepted && this.context.eventAllWorkersAccepted && this.context.user.type === "Vendor")
		if (
			this.context.activeEvent &&
			// this.context.user.id === this.context.activeEvent.userid
			this.context.user.type === 'Vendor'
			// ||this.context.user.type === 'Manager'
		) {
			return (
				<div>
					<Task>
						<Task.Action onClick={this.submitEntry} primary>
							Start Work
						</Task.Action>
					</Task>
					{/* <Task>
						<Task.Action onClick={this.openEntryModal} primary>
							Start Work
						</Task.Action>
					</Task>
					<WorkOrderEntry
						startingPage={'EntryRequired'}
						modalOpen={this.state.entryModal}
						onCancel={this.openEntryModal}
						doneEntryAction={this.doneEntryAction}
						requestContextReq={this.context.request}
						entryRequired={this.state.entryRequired}
						updateEntryRequired={this.state.updateEntryRequired}
						activeEvent={this.context.activeEvent}
						submitEntry={this.submitEntry}
						modalLoading={this.state.modalLoading}
					/> */}
				</div>
			);
		}

		// else if (!this.context.eventCurrentUserAccepted && acceptEvent) {
		//     return (
		//         <div>
		//             <Task>
		//             <Task.Title>{this.context.eventToAccept.title}</Task.Title>
		//                 <Task.Action onClick={this.openAcceptModal} primary>
		//                     Accept Event
		//                 </Task.Action>
		//             </Task>

		//             <AcceptEvent eventToAccept={this.context.eventToAccept} currentUser={this.context.user} modalOpen={this.state.acceptModal} onCancel={this.openAcceptModal}
		//             updateEvent={() => this.context.updateEventAcceptance(acceptEvent)}

		//             />

		//         </div>
		//     )
		// }
		else {
			return null;
		}
	}
}

export default WorkEntryAction;
