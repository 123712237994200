import React, { useState } from 'react';
import { ModalTitle, Form } from 'react-bootstrap';
import Task from '../../Components/Tasks/Task';
import axios from 'axios';
import { Button, message, DatePicker, Modal } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import LoadScreen from '../../Components/LoadScreen';
import ReviewWorkOrderInfo from './Pages/ReviewWorkOrderInfo';
import ReworkWorkOrder from './Pages/ReworkWorkOrder';
import ReworkNewEventWO from './Pages/ReworkNewEventWO';
import PauseWOFromReview from './Pages/PauseWOFromReview';

const { confirm } = Modal;

export default class WorkOrderReview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'Loading',
			confirmModalVisible: false,

			setConfirmModalVisible: () => {
				this.setState({ confirmModalVisible: !this.state.confirmModalVisible });
			},

			reviewWOContext: {
				currentWorkOrder: this.props.currentWorkOrder,
				time: null,
				reworkWOTextArea: '',
				pauseWOTextArea: '',

				setTime: newTime => {
					this.setState({
						extensionContext: { ...this.state.extensionContext, time: newTime }
					});
				},

				updateReworkWOContextTextArea: value => {
					this.setState({
						reviewWOContext: {
							...this.state.reviewWOContext,
							reworkWOTextArea: value
						}
					});
				},

				updatePauseWOContextTextArea: value => {
					this.setState({
						reviewWOContext: {
							...this.state.reviewWOContext,
							pauseWOTextArea: value
						}
					});
				},

				reworkWorkOrder: this.props.reworkWorkOrder
			}
		};
	}

	changePage = page => this.setState({ page: page });

	showConfirmationModal = visible => {
		const approveWO = this.props.approveWorkOrder;
		let confirmModalVisible = visible;
		const setConfirmModalVisible = () => {
			this.state.setConfirmModalVisible();
			confirmModalVisible = this.state.confirmModalVisible;
		};
		confirm({
			title: 'Are you sure you want to approve the work order?',
			icon: <ExclamationCircleTwoTone />,
			content:
				'This will complete the work order and no further work can be done on it.',
			okText: 'Yes',
			cancelText: 'No',
			visible: confirmModalVisible,

			onOk() {
				approveWO();
				setConfirmModalVisible();
				window.location.reload();
			},

			onCancel() {
				setConfirmModalVisible();
			}
		});
	};

	componentDidMount() {
		this.setState({ page: this.props.startingPage });
	}

	render() {
		let newPageObject = null;
		switch (this.state.page) {
			case 'Loading':
				newPageObject = {
					page: LoadScreen,
					title: '',
					footer: ''
				};
				break;

			case 'ReviewWorkOrderInfo':
				newPageObject = {
					page: ReviewWorkOrderInfo,
					title: 'Review Work Order Information',
					footer: (
						<>
							<Button
								key='pause'
								type='primary'
								onClick={() => {
									this.changePage('PauseWOFromReview');
								}}
							>
								Pause
							</Button>

							<Button
								key='rework'
								type='primary'
								onClick={() => {
									this.changePage('ReworkWorkOrder');
								}}
							>
								Request Rework
							</Button>

							<Button
								key='approve'
								type='primary'
								onClick={() => {
									this.showConfirmationModal(true);
								}}
							>
								Approve
							</Button>
						</>
					)
				};
				break;

			case 'ReworkWorkOrder':
				newPageObject = {
					page: ReworkWorkOrder,
					title: 'Rework',
					footer: (
						<>
							<Button
								key='back'
								onClick={() => {
									this.changePage('ReviewWorkOrderInfo');
								}}
							>
								Back
							</Button>

							<Button
								key='requestRework'
								type='primary'
								disabled={
									!(this.state.reviewWOContext.reworkWOTextArea.length > 0)
								}
								onClick={() => {
									this.changePage('ReworkNewEventWO');
								}}
							>
								Create New Event for Rework
							</Button>
						</>
					)
				};
				break;

			case 'ReworkNewEventWO':
				newPageObject = {
					page: ReworkNewEventWO,
					title: null,
					footer: null
				};
				break;

			case 'PauseWOFromReview':
				newPageObject = {
					page: PauseWOFromReview,
					title: 'Pause Work Order',
					footer: (
						<>
							<Button
								key='back'
								onClick={() => {
									this.changePage('ReviewWorkOrderInfo');
								}}
							>
								Back
							</Button>

							<Button
								key='pause'
								type='primary'
								disabled={
									!(this.state.reviewWOContext.pauseWOTextArea.length > 0)
								}
								onClick={() => {
									this.props.pauseWorkOrder(
										this.state.reviewWOContext.pauseWOTextArea
									);
								}}
							>
								Pause
							</Button>
						</>
					)
				};
		}

		let RenderPage = newPageObject.page;

		return (
			<Modal
				title={newPageObject.title}
				visible={this.props.modalOpen}
				onCancel={this.props.onCancel}
				footer={newPageObject.footer}
			>
				<div id='reviewWO-modal-body'>
					<RenderPage
						changePage={this.changePage}
						onCancel={this.props.onCancel}
						reviewWOContext={this.state.reviewWOContext}
					/>
				</div>
			</Modal>
		);
	}
}
