import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import { Card, Avatar, Button, Modal } from 'antd';
import axios from 'axios';

import LoadScreen from '../../Components/LoadScreen';
import Moment from 'react-moment';
import './agreement.css';
import { ProfileAvatar } from '../../Components/Avatar';
import AddLease from './AddLease';
const cancelToken = axios.CancelToken.source();
class Agreements extends React.Component {
	componentDidMount() {
		document.title = 'Agreements';
	}
	render() {
		return (
			<Switch>
				<Route exact path='/agreements' component={AgreementsList} />
				<Route exact path='/agreements/:id' component={AgreementDisplay} />
			</Switch>
		);
	}
}

class AgreementsList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			agreementsList: null,
			searchValue: '',
			addLease: false
		};
	}

	componentDidMount = () => {
		axios
			.get('/getAgreements')
			.then(response => {
				this.setState({ agreementsList: response.data.agreementsList });
			})
			.catch(err => {
				this.setError(err);
			});
	};
	setError = () => {
		return <div>err</div>;
	};

	openAddLease = () => {
		this.setState({ addLease: !this.state.addLease });
	};

	setSearch = value => this.setState({ searchValue: value });
	render() {
		if (this.state.agreementsList === null)
			return (
				<div className='h100'>
					<LoadScreen />
				</div>
			);
		else if (this.state.agreementsList === [])
			return (
				<div className='h100'>
					<LoadScreen />
				</div>
			);

		return (
			<div>
				<div id='searchBarContainer' className='pt-3'>
					<Searchbar
						onChange={e => this.setSearch(e.target.value)}
						value={this.state.searchValue}
						className='maintenancebar'
					/>
				</div>
				<Button
					type='primary'
					className='addvender-btn btnLabel'
					onClick={this.openAddLease}
				>
					Add New Lease
				</Button>
				<Modal
					destroyOnClose={true}
					title='Add New Lease'
					visible={this.state.addLease}
					onCancel={this.openAddLease}
					width='500px'
					footer={false}
				>
					{this.state.addLease ? <AddLease /> : ''}
				</Modal>
				<br />
				<div id='listContainer'>
					{this.state.agreementsList
						.filter(
							agreement =>
								agreement.propertynumber +
								' ' +
								agreement.propertyStreet
									.toLowerCase()
									.includes(this.state.searchValue.toLowerCase())
						)
						.map((agreement, agindex) => (
							<AgreementListing agreement={agreement} key={agindex} />
						))}
				</div>
			</div>
		);
	}
}
export const AgreementListing = ({
	agreement,
	customLink,
	mobile,
	noCity,
	noBottomBorder
}) => {
	return (
		<Link to={customLink ? customLink : '/agreements/' + agreement.lease_id}>
			<Card
				bordered={false}
				className={`mb-2 displayCard ${mobile ? 'mobileView' : ''} ${
					noBottomBorder ? 'border-bottom-0' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<h5 className='cardTitle titleHover'>
								{!noCity
									? property(
											agreement.propertynumber,
											agreement.propertyStreet,
											agreement.unit_name,
											agreement.propertyCity
											// agreement.propertyPostalcode
									  )
									: property(
											agreement.propertynumber,
											agreement.propertyStreet,
											agreement.unit_name
									  )}
							</h5>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								{agreement.tenants
									? agreement.tenants.map((tenant, index) =>
											index + 1 !== agreement.tenants.length
												? tenant.fname + ' ' + tenant.lname + ', '
												: tenant.fname + ' ' + tenant.lname
									  )
									: ''}
							</div>
						</div>
					</div>
				</div>

				{agreement.rent ? (
					<div className='cardRight mobileRow'>
						<div className='cardRightHeader mobileRow'>
							{leaseType(agreement.lease_type)}

							<div className='cardRightHeaderItem d-inline g-text-sm'>
								Lease Period:&nbsp;
								<Moment format='DD/MM/YY'>{agreement.lease_start}</Moment>
								{agreement.lease_end ? (
									<Moment format=' - DD/MM/YY'>{agreement.lease_end}</Moment>
								) : (
									' - Present'
								)}
							</div>
						</div>
						<div className='cardRightFooter g-text-sm '>
							<div className='b-text d-inline'>${agreement.rent}&nbsp;</div>
							per month
						</div>
					</div>
				) : (
					''
				)}
			</Card>
		</Link>
	);
};
class AgreementDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.state = { agreement: null, error: false };
	}
	componentDidMount = () => {
		let lease_id = window.location.href.split('/')[4];
		axios.post('/getAgreement', { lease_id: lease_id }).then(response => {
			if (response.data.agreement === null)
				return this.setState({ error: true });
			this.setState({ agreement: response.data.agreement });
		});
	};
	render() {
		if (this.state.error) return <div>Error</div>;
		if (this.state.agreement === null)
			return (
				<div id='displayShell'>
					<LoadScreen className='m-auto' />
				</div>
			);
		return (
			<div id='displayShell'>
				<Card
					title={
						<>
							<div className='g-text d-inline'>
								Lease for&nbsp;
								{property(
									this.state.agreement.propertynumber,
									this.state.agreement.propertyStreet,
									this.state.agreement.unit_name
								)}
								&nbsp; &nbsp;
							</div>
						</>
					}
					bordered={false}
				>
					<div className='profContent'>
						<div className='profFile'>
							<img
								width={193}
								height={257}
								src='https://via.placeholder.com/193x257/?text=No+Lease+Uploaded'
								alt='Lease Not Uploaded'
							/>
							{this.state.agreement.lease_url ? (
								<div className='g-text-sm'>Click to view lease</div>
							) : (
								''
							)}
						</div>
						<div className='profDetails ml-4'>
							<div className='mt-4'>
								<div className='g-text-sm'>Lease Type</div>{' '}
								{this.state.agreement.lease_type}
							</div>
							<div className='g-text-sm'>Lease Term</div>
							<Moment format='DD/MM/YY'>
								{this.state.agreement.lease_start}
							</Moment>
							{this.state.agreement.lease_end ? (
								<Moment format=' - DD/MM/YY'>
									{this.state.agreement.lease_end}
								</Moment>
							) : (
								' - Present'
							)}
							<label className='g-text-sm d-block'>Property</label>
							<ProfileAvatar
								name={property(
									this.state.agreement.propertynumber,
									this.state.agreement.propertyStreet,
									this.state.agreement.unit_name,
									this.state.agreement.propertyCity,
									this.state.agreement.propertyPostalcode
								)}
							/>
							<br />
							<label className='g-text-sm d-block'>Manager</label>
							<ProfileAvatar
								user_id={this.state.agreement.PM_id}
								name={
									this.state.agreement.PM_fname +
									' ' +
									this.state.agreement.PM_lname
								}
							/>
							<br />
							{this.state.agreement.tenants.length > 0 ? (
								<>
									<label className='g-text-sm mt-2 d-block'>
										{this.state.agreement.tenants.length > 1
											? 'Tenants'
											: 'Tenant'}
									</label>
									<div>
										{this.state.agreement.tenants.map((tenant, tenantIndex) => (
											<ProfileAvatar
												key={tenantIndex}
												user_id={tenant.id}
												name={tenant.fname + ' ' + tenant.lname}
											/>
										))}
									</div>
								</>
							) : (
								''
							)}
						</div>
					</div>
				</Card>
			</div>
		);
	}
}
export function property(number, street, unit, city, postalCode) {
	return (
		(number ? number : '') +
		(unit && unit !== 'default' && unit !== 'basic' ? '-' + unit + ' ' : ' ') +
		(street ? street + ' ' : '') +
		(city ? city + ' ' : '') +
		(postalCode ? postalCode : '')
	);
}

export function leaseType(type = '') {
	if (type === 'Fixed term')
		return <div className=' rq-status d-inline status-red'>{type}</div>;
	else return <div className=' rq-status d-inline status-blue'>{type}</div>;
}
export default Agreements;
