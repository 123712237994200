import React from 'react';
import { Avatar } from 'antd';

export const ProfileAvatar = ({
	user_id,
	image,
	name,
	role,
	size,
	nameClass
}) => {
	return (
		<div
			style={{
				minWidth: 'fit-content',
				cursor: 'pointer',
				display: 'inline-block'
			}}
		>
			{image === undefined ||
			image === null ||
			image === 'avatar_default.png' ? (
				<Avatar size={size ? size : 32} className=' mr-2'>
					{name[0]}
				</Avatar>
			) : (
				<Avatar size={size ? size : 32} className=' mr-2' src={image} />
			)}

			<div className={`fs-14 mr-2 d-inline ${nameClass ? nameClass : ''}`}>
				{name}
			</div>
			{role ? (
				<div className='d-inline fs-12 g-text mb-2'>
					&#183;{' '}
					<div style={{ marginLeft: '6px', display: 'inline' }}>{role}</div>{' '}
				</div>
			) : (
				''
			)}
		</div>
	);
};
