import React, { useState, useRef, useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import axios from 'axios';
import { RequestContext } from '../../Context/requestContext';
import LoadScreen from '../../Components/LoadScreen';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import './maintenance.css';
import {
	PropertyMaintenanceRequestListing,
	MaintenanceStatus
} from './RequestListing';
import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
import UnitMaintenanceRequest from '../MaintenanceRequest/UnitMaintenanceRequest';
import PropertyMaintenanceRequest from '../MaintenanceRequest/PropertyMaintenanceRequest';
import IntakeRequest from '../IntakeRequest/IntakeRequest';
import requestStatus from '../../StringData/RequestStatus';

import { requestLinks } from './requestRoutes';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import NoRequestSVG from '../../SVG/NoRequests';

//Default router for request type.
class PropertyJobRequests extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super();
		this.state = {
			requests: null,
			mobile: window.innerWidth <= 1150
		};
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.debounce(this.resize, 250));
		let route = window.location.href;
		let rqType = route.split('/')[3];
		this.setState({ requestType: rqType }, this.fetchRequests(false, rqType));
	};

	fetchRequests = (refresh = false, type = this.state.requestType) => {
		this.context.getRequests(refresh, type).then(requests => {
			this.setState({ requests: requests, requestType: type });
		});
	};

	resize = () => {
		//https://stackoverflow.com/questions/44480053/how-to-detect-if-screen-size-has-changed-to-mobile-in-react
		this.setState({ mobile: window.innerWidth <= 1150 });
	};

	debounce = (fn, delay) => {
		//Throttles the function call so resize is called only once ever 250ms
		var timer = null; //https://remysharp.com/2010/07/21/throttling-function-calls
		return function () {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay);
		};
	};

	render() {
		console.log(this.state.requests);
		return this.state.mobile ? (
			<RequestMobileLayout requestList={this.state.requests} />
		) : (
			<RequestDesktopLayout
				requestList={this.state.requests}
				fetchRequests={this.fetchRequests}
			/>
		);
	}
}

const RequestMobileLayout = props => {
	const { requestList, fetchRequests } = props;
	let location = useLocation();

	const request_id = location.state.job_id;
	const property_id = location.state.property_id;

	return (
		<>
			<Route
				exact
				path='/unitMaintenance'
				render={() => <RequestList requestList={requestList} />}
			/>
			<Route
				exact
				path='/administrative'
				render={() => <RequestList requestList={requestList} />}
			/>
			<Route
				exact
				// path='/propertyMaintenance/request/:id/:propertyId'
				path='/propertyMaintenance/request/propertyJR'
				render={() => (
					<PropertyMaintenanceRequest
						job_id={request_id}
						property_id={property_id}
						fetchRequests={fetchRequests}
					/>
				)}
			/>
			<Route
				exact
				path='/administrative/request/:id'
				render={() => <UnitMaintenanceRequest job_id={request_id} />}
			/>
			<Route
				exact
				path='/administrative/intake/:id'
				render={() => <IntakeRequest job_id={request_id} />}
			/>
		</>
	);
};
const RequestDesktopLayout = props => {
	const { requestList, fetchRequests, request_type, updateMaintenanceStatus } =
		props;
	let location = useLocation();
	// const request_id = window.location.href.split('/')[6] || null;
	// const property_id = window.location.href.split('/')[5] || null;
	const request_id = location.state.job_id;
	const property_id = location.state.property_id;
	console.log(property_id);

	if (requestList === null) return <LoadScreen className='m-auto' />;
	return (
		<div className='maintenance-desktop-layout'>
			<div className='maintenance-layout-left'>
				<RequestList
					request_id={request_id}
					requestList={requestList}
					property_id={property_id}
				/>
			</div>
			<div className='maintenance-layout-right'>
				<div className='maintenance-request-container'>
					<Route
						exact
						path='/propertyMaintenance'
						render={() => (
							<>
								<SelectRequest />
							</>
						)}
					/>
					<Route
						exact
						path='/administrative'
						render={() => <SelectRequest />}
					/>
					<Route
						exact
						// path='/propertyMaintenance/request/:id/:propertyId'
						path='/propertyMaintenance/request/propertyJR'
						render={() => (
							<PropertyMaintenanceRequest
								job_id={request_id}
								property_id={property_id}
								fetchRequests={fetchRequests}
							/>
						)}
					/>
					<Route
						exact
						path='/administrative/request/:id/:propertyId'
						render={() => (
							<UnitMaintenanceRequest
								job_id={request_id}
								fetchRequests={fetchRequests}
							/>
						)}
					/>
					<Route
						exact
						path='/administrative/intake/:id'
						render={() => (
							<IntakeRequest
								job_id={request_id}
								fetchRequests={fetchRequests}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};
//Responsible for rendering the maintenance requests and filtering them.
function RequestList(props) {
	let scrollTarget = useRef(null);
	const { requestList, request_type } = props;

	const [searchValue, search] = useState('');
	const [statusFilter, setStatusFilter] = useState('');
	const [dropDownVisible, setVisible] = useState(false);
	const [willScroll, setWillScroll] = useState(false);

	useEffect(() => {
		if (scrollTarget.current) {
			scrollTarget.current.scrollIntoView({ behavior: 'smooth' });
		} else {
			setWillScroll(true);
		}
	}, [willScroll]);

	const debounce = (fn, delay) => {
		//Throttles the function call so resize is called only once ever 250ms
		var timer = null; //https://remysharp.com/2010/07/21/throttling-function-calls
		return function () {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay);
		};
	};

	const changeStatusFilter = status => {
		setStatusFilter(status);
		setVisible(false);
	};

	//request list not loaded yet
	if (requestList == null) {
		return <LoadScreen />;
	}

	//No requests found
	if (requestList.length === 0) return <NoRequests />;
	// oh no, something's wrong. TODO: error handling
	else if (requestList === undefined) {
		return <div>Something went wrong. Please try signing in again.</div>;
	}
	// const { requestDescription } = this.context;

	// console.log(requestDescription);

	return (
		<div className='h100'>
			<div className='pt-4' id='listHeader'>
				<div>
					<Dropdown
						overlay={
							<RequestMenu
								selectStatus={status => {
									debounce(changeStatusFilter(status), 500);
								}}
							/>
						}
						placement='bottomLeft'
						trigger='click'
						open={dropDownVisible}
						onOpenChange={() => setVisible(!dropDownVisible)}
					>
						<Button>
							Status <DownOutlined />
						</Button>
					</Dropdown>
				</div>
				<Searchbar
					onChange={e => search(e.target.value)}
					value={searchValue}
					className='maintenancebar'
				/>
			</div>
			<div id='maintenanceListContainer'>
				{requestList
					.filter(
						request =>
							(
								'JR' +
								request.job_id +
								request.job_category +
								request.job_subcategory +
								request.job_status +
								request.job_title +
								request.propertynumber +
								' ' +
								request.propertyStreet +
								(request.vendor
									? request.vendor.fname + ' ' + request.vendor.lname
									: '')
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase()) &&
							(request.job_status.toLowerCase() ===
								statusFilter.toLowerCase() ||
								statusFilter.length < 1) &&
							props.property_id == request.propertyid
					)
					.sort((a, b) => b.job_id - a.job_id)
					.map(request =>
						props.request_id == request.job_id ? (
							<div ref={scrollTarget} key={request.job_id}>
								<PropertyMaintenanceRequestListing
									mobileView
									selected={true}
									request={request}
									customLink={
										requestLinks[
											'property' + request.job_category.toLowerCase()
										] +
										String(request.propertyid) +
										'/' +
										String(request.job_id)
									}
									clickable={true}
								/>
							</div>
						) : (
							<div key={request.job_id}>
								<PropertyMaintenanceRequestListing
									mobileView
									selected={false}
									request={request}
									customLink={
										requestLinks[
											'property' + request.job_category.toLowerCase()
										] +
										String(request.propertyid) +
										'/' +
										String(request.job_id)
									}
									clickable={true}
								/>
							</div>
						)
					)}
			</div>
		</div>
	);
}

const SelectRequest = () => {
	return (
		<div className='text-center pt-4'>
			<NoRequestSVG />
			<br />
			<div className='g-text'>Select a request to open it here.</div>
		</div>
	);
};

const NoRequests = () => {
	return (
		<div className='text-center mt-4'>
			<br />
			<h6>No Requests found.</h6>
		</div>
	);
};
const RequestMenu = ({ selectStatus }) => {
	return (
		<Menu>
			<Menu.Item onClick={() => selectStatus('')} key={'all'}>
				All
			</Menu.Item>
			{requestStatus.map((status, index) => (
				<Menu.Item onClick={() => selectStatus(status)} key={status}>
					<MaintenanceStatus value={status} status={status} />
				</Menu.Item>
			))}
		</Menu>
	);
};

export default PropertyJobRequests;

/* old list header
      <div className='mt-4' id='listHeader'>
        <div id='maintenance-left' className='ml-2'>
          <div className='d-inline mr-4'>
            Sort: <b>A-Z</b>
          </div>
          <div className='d-inline'>
            Type: <b>All</b>
          </div>
        </div>
        <div className='flex-70 m-auto'>
          <Searchbar
            onChange={(e) => search(e.target.value)}
            value={searchValue}
          />
        </div>
        <div id='maintenance-right' className=' mr-4'>
          <a className='blueLink fs-14'>Export Spreadsheet</a>
        </div>
      </div>
*/
