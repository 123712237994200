import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'antd';
import WorkOrderContext from '../WorkOrderContext';
import WorkOrderImageUpload from '../../ImageUploadModal/WorkOrderImageUpload';

class Images extends Component {
	static contextType = WorkOrderContext;
	constructor(props) {
		super(props);
		this.state = {
			images: [],
			errormsg: '',
			loading: false
		};
	}
	componentDidMount = () => {
		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				this.setState({
					workOrderDetails: res.data.details,
					workOrderId: res.data.details[0].wo_id
				});
			});
	};

	troubleSave = () => {
		if (this.verify()) {
			this.setState({ errormsg: '', loading: true });
			axios
				.post('/wo/save/images', {
					tr_desc: this.state.images,
					wo_id: this.state.workOrderId
				})
				.then(response => {
					this.setState({ loading: false });
					this.context.goToMainMenu();
				})
				.catch(err => {
					this.setState({
						errormsg: 'Error: Please try again.',
						loading: false
					});
				});
		}
	};

	setFiles = files => {
		if (this.state.uploadFiles.length < 10) {
			let fileList = [];
			for (
				let i = 0;
				i < files.length &&
				fileList.length + this.state.uploadFiles.length < 10;
				i++
			) {
				if (
					!this.state.uploadFiles.some(file => files.item(i).name === file.name)
				)
					fileList.push(files.item(i));
			}
			this.setState({ uploadFiles: [...this.state.uploadFiles, ...fileList] });
		}
	};
	verify = () => {
		if (this.state.description === '') {
			this.setState({
				errormsg: 'Please enter a detailed description of the problem'
			});
			return false;
		}
		return true;
	};
	render() {
		return (
			<div style={{ height: '230px' }}>
				<WorkOrderImageUpload
					job_id={this.context.jobData.job_id}
					wo_id={this.state.workOrderId}
					visible={true}
					body={{
						job_id: this.context.jobData.job_id,
						wo_id: this.state.workOrderId
					}}
					route={
						'/workOrder/update/' +
						(this.context.workDone ? 'work' : 'troubleshoot') +
						'/upload'
					}
					onClose={false}
					updateImages={this.context.updateImages}
					contextData={this.context}
				/>
			</div>
		);
		/*return (
      <div className="Images">
        <div>
          <label>Detailed Description of the problem:</label>
          <textarea
            className="form-control"
            rows={6}
            defaultValue={this.state.description}
            onChange={this.changeDesc}
            id="comment"
            name="text"
          ></textarea>
        </div>
        <p className="errorMsg">{this.state.errormsg}</p>
        <br/>
        <div className="buttonGroup">
          <Button
            loading={this.state.loading}
            type="primary"
            className="mr-0 "
            onClick={this.troubleSave}
          >
            Submit
          </Button>
        </div>
      </div>
    );*/
	}
}
export default Images;
