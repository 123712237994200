import React from 'react';
import { Form } from 'react-bootstrap';
import {
	InputNumber,
	Checkbox,
	Button,
	message,
	Alert,
	Radio,
	DatePicker,
	Select
} from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import { vendorProvinces } from '../../StringData/VendorObjects';
import RequestContext from '../MaintenanceRequest/RequestContext';
const cancelToken = axios.CancelToken.source();

class AddUnit extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = { unitName: [] };
	}

	handleChange = e => {
		const { value, checked } = e.target;
		if (checked) {
			this.setState({ unitName: [...this.state.unitName, value] });
		} else {
			this.setState({ unitName: this.state.unitName.filter(e => e != value) });
		}
	};

	submitForm = () => {
		let propertyID = this.props.propertyId;

		axios
			.post('/addUnit', {
				propertyId: propertyID,

				unitName: this.state.unitName
			})
			.then(response => {
				this.setState({ submitted: true });
				window.location.reload();
			});
	};

	render() {
		if (this.state.submitted) {
			return <h3>Unit Added! </h3>;
		}
		return (
			<div>
				<Form>
					{/* <Form.Row>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Unit </Form.Label>
							<select
								name='unitName'
								className='form-control'
								onChange={e => this.setState({ unitName: e.target.value })}
							>
								<option value={''}>Select...</option>
								<option value='Default'>Default</option>
								<option value='A'>A</option>
								<option value='B'>B</option>
								<option value='C'>C</option>

								<option value='D'>D</option>
								<option value='E'>E</option>
							</select>
						</Form.Group>
					</Form.Row> */}
					<Form.Row>
						<Form.Check
							inline
							label='Default'
							value='default'
							onChange={this.handleChange}
						></Form.Check>
						<Form.Check
							inline
							label='A'
							value='A'
							onChange={this.handleChange}
						></Form.Check>
						<Form.Check
							inline
							label='B'
							value='B'
							onChange={this.handleChange}
						></Form.Check>
						<Form.Check
							inline
							label='C'
							value='C'
							onChange={this.handleChange}
						></Form.Check>
						<Form.Check
							inline
							label='D'
							value='D'
							onChange={this.handleChange}
						></Form.Check>
						<Form.Check
							inline
							label='E'
							value='E'
							onChange={this.handleChange}
						></Form.Check>
					</Form.Row>

					<br></br>
					<div className='text-right'>
						<Button onClick={this.submitForm} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default AddUnit;
