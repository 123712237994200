import React from "react";
import { ThreadList, Messenger } from "./Communication.js";
import LoadScreen from "../LoadScreen.js";
import axios from "axios";

import { Modal, ModalTitle } from "react-bootstrap";
import { Button, Avatar, Input, message } from "antd";
import { UserStateContext } from "../../Context/UserContext";
const cancelToken = axios.CancelToken.source();

class MaintenanceThread extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      threads: null,
      selectedThread: null,
      newThread: false,
    };
  }
  makeNewThread = () => {
    this.setState({ newThread: !this.state.newThread });
  };
  selectThread = (id) => {
    let thread = this.state.threads.find((thread) => thread.thread_id === id);
    this.setState({ selectedThread: thread });
  };
  goBack = () => {
    this.setState({ selectedThread: null });
  };
  componentDidMount = () => {
    axios
      .get("/communication/getRequestThreads/" + this.props.request.job_id)
      .then((response) => {
        this.setState({ threads: response.data.threads });
      });
  };
  createThread = (people, name) => {
    axios
      .post("/communication/createThread", {
        job_id: this.props.request.job_id,
        people: people,
        thread_name: name,
      })
      .then((response) => {
        this.setState({
          threads: [...this.state.threads, response.data.newThread],
          selectedThread: response.data.newThread,
          newThread: false,
        });
        message.success("Thread successfully created");
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (this.state.threads === null)
      return (
        <div
          className={`m-auto ${
            this.props.fixedHeight ? "commModalFixedHeight" : "h100"
          }`}
        >
          <LoadScreen className="m-auto" />
        </div>
      );
    else if (this.state.selectedThread !== null)
      return (
        <Messenger
          fixedHeight={this.props.fixedHeight}
          thread_id={this.state.selectedThread.thread_id}
          thread_name={this.state.selectedThread.thread_name}
          goBack={this.goBack}
          backButton
          userType={this.props.userType}
        />
      );
    else if (this.state.newThread) {
      return (
        <NewThreadModal
          createThread={this.createThread}
          request={this.props.request}
          onHide={this.makeNewThread}
        />
      );
    } else {
      return (
        <>
          <Modal.Header closeButton>
            <ModalTitle>Threads</ModalTitle>
          </Modal.Header>
          <Modal.Body className="commModalFixedHeight">
            <ThreadList
              actionNoLink={true}
              threads={this.state.threads}
              selectThread={this.selectThread}
              goBack={() => this.goBack}
              backButton
            />
            <div className="text-center mt-4">
              {this.state.threads.length === 0 ? (
                <div className="mb-2">Click below to create a new thread</div>
              ) : (
                ""
              )}
              <Button
                onClick={this.makeNewThread}
                size="large"
                type={this.state.threads.length === 0 ? "primary" : ""}
              >
                New Thread
              </Button>
            </div>
          </Modal.Body>
        </>
      );
    }
  }
}

class NewThreadModal extends React.Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    this.state = {
      people: null,
      selectedPeople: [],
      threadName: "",
      submitting: false,
    };
  }
  componentDidMount = () => {
    axios
      .post("/jobRequest/get/relatedPeople", { request: this.props.request })
      .then((response) => {
        this.setState({
          people: response.data.people,
          selectedPeople: [this.context.user],
        });
      })
      .catch((err) => console.log(err));
  };
  createThread = () =>
    this.props.createThread(this.state.selectedPeople, this.state.threadName);
  selectPerson = (person) => {
    let index = this.state.selectedPeople.findIndex(
      (p) => p.id === person.id && p.type === person.type
    );
    if (index === -1)
      this.setState({ selectedPeople: [...this.state.selectedPeople, person] });
    else {
      let temp = this.state.selectedPeople;
      temp.splice(index, 1);
      this.setState({ selectedPeople: temp });
    }
  };
  setThreadName = (v) => {
    this.setState({ threadName: v });
  };

  render() {
    if (this.state.people === null || this.state.submitting)
      return (
        <>
          <Modal.Header closeButton>
            <ModalTitle>New Thread</ModalTitle>
          </Modal.Header>
          <Modal.Body className="commModalFixedHeight">
            <LoadScreen className="m-auto" />
          </Modal.Body>
        </>
      );
    return (
      <>
        <Modal.Header closeButton>
          <ModalTitle>New Thread</ModalTitle>
        </Modal.Header>
        <Modal.Body className="commHeight400">
          <Input
            placeholder="Thread Name"
            onChange={(e) => this.setThreadName(e.target.value)}
            className="c_threadname"
          />

          {this.state.people.tenants !== null ? (
            <>
              <div className="g-text-sm">Tenants</div>
              <ul>
                {this.state.people.tenants.map((person) => (
                  <PersonLine
                    person={person}
                    selectPerson={() => this.selectPerson(person)}
                    selected={
                      this.state.selectedPeople.findIndex(
                        (p) => p.id === person.id
                      ) !== -1
                    }
                    thisUser={this.context.user.id == person.id}
                  />
                ))}
              </ul>
            </>
          ) : (
            ""
          )}
          {this.state.people.owners !== null ? (
            <>
              <div className="g-text-sm">Owners</div>
              <ul>
                {this.state.people.owners.map((person) => (
                  <PersonLine
                    person={person}
                    selectPerson={() => this.selectPerson(person)}
                    selected={
                      this.state.selectedPeople.findIndex(
                        (p) => p.id === person.id
                      ) !== -1
                    }
                    thisUser={this.context.user.id == person.id}
                  />
                ))}
              </ul>
            </>
          ) : (
            ""
          )}

          {this.state.people.vendors !== null ? (
            <>
              <div className="g-text-sm">Vendors</div>
              <ul>
                {this.state.people.vendors.map((person) => (
                  <PersonLine
                    person={person}
                    selectPerson={() => this.selectPerson(person)}
                    selected={
                      this.state.selectedPeople.findIndex(
                        (p) => p.id === person.id && p.type === person.type
                      ) !== -1
                    }
                    thisUser={
                      this.context.user.id === person.id &&
                      this.context.user.type === person.type
                    }
                  />
                ))}
              </ul>
            </>
          ) : (
            ""
          )}
          {this.state.people.managers !== null ? (
            <>
              <div className="g-text-sm">Managers</div>
              <ul>
                {this.state.people.managers.map((person) => (
                  <PersonLine
                    person={person}
                    selectPerson={() => this.selectPerson(person)}
                    selected={
                      this.state.selectedPeople.findIndex(
                        (p) => p.id === person.id
                      ) !== -1
                    }
                    thisUser={this.context.user.id == person.id}
                  />
                ))}
              </ul>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Cancel</Button>
          <Button
            type="primary"
            onClick={this.createThread}
            disabled={
              !(this.state.selectedPeople.length > 1) || this.state.submitting
            }
          >
            Create Thread
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

/* display for selecting people to add to new thread */
const PersonLine = ({ person, selectPerson, selected, thisUser }) => {
  return (
    <li className="communicationPerson">
      <div className="c_image">
        {person.imageurl === "avatar_default.png" ? (
          <Avatar size={24}>{person.fname[0]}</Avatar>
        ) : (
          <Avatar size={24} src={person.imageurl} />
        )}
      </div>
      <div className="c_name fs-12">
        {person.fname} {person.lname}
      </div>
      <div className="c_check">
        <input
          type="checkbox"
          checked={selected || thisUser}
          onClick={selectPerson}
          disabled={thisUser}
        />
      </div>
    </li>
  );
};

export default MaintenanceThread;
