import React from 'react';
import { Form } from 'react-bootstrap';
import {
	InputNumber,
	Checkbox,
	Button,
	message,
	Alert,
	DatePicker,
	Select
} from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import { vendorProvinces } from '../../StringData/VendorObjects';
import RequestContext from '../MaintenanceRequest/RequestContext';

const cancelToken = axios.CancelToken.source();

class AddNewTenant extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			leaseProperty: null,
			email: '',

			firstName: '',
			lastName: '',
			phone: '',
			homeNumber: '',
			city: '',
			homeStreet: '',
			province: '',
			postalCode: '',
			birthDate: '',
			leaseId: '',
			provinceList: []
		};
	}
	componentDidMount = () => {
		axios
			.post('/getLeaseProperty', { unitId: this.props.unitId })
			.then(response => {
				console.log(response.data.leaseProperty);

				this.setState({
					leaseProperty: response.data.leaseProperty,
					leaseId: response.data.leaseProperty[0].lease_id
				});
			})
			.catch(err => {
				this.setError(err);
			});
		this.getProvincesList();
	};
	getProvincesList = () => {
		axios.post('/getProvinces').then(res => {
			// console.log(res.data.provinces);
			this.setState({ provinceList: res.data.provinces });
		});
	};

	submitForm = () => {
		axios
			.post('/auth/validateEmail', { email: this.state.email })
			.then(response => {
				if (!response.data.ok) {
					return this.state.email === ''
						? this.setState({ error: 'Email Required!' })
						: this.setState({ error: 'EMAIL_EXISTS' });
				} else {
					axios
						.post('/addTenant', {
							newPhone: this.state.phone,
							newEmail: this.state.email,
							newFname: this.state.firstName,
							newLname: this.state.lastName,
							tenantAccount: 1,
							newHomeNumber: this.state.homeNumber,
							newHomeCity: this.state.city,
							newHomeStreet: this.state.homeStreet,
							newHomeProvince: this.state.province,
							newHomePostalcode: this.state.postalCode,
							birthDate: this.state.birthDate,
							leaseId: this.state.leaseId
						})
						.then(response => {
							this.setState({ submitted: true });
							window.location.reload();
						});
				}
			});
	};
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	/* Auto formatting phone number */
	numberFormat = value => {
		let newNumber = '';
		let phoneNumber = ('' + value).replace(/[^\d]/g, '');
		for (let i = 0; i < phoneNumber.length; i++) {
			if (i == 0) {
				newNumber = '(';
			} else if (i == 3) {
				newNumber = newNumber + ')';
			} else if (i == 6) {
				newNumber = newNumber + '-';
			}

			newNumber = newNumber + phoneNumber[i];
		}
		return newNumber;
	};
	handlePhoneNumber = e => {
		const { name, value } = e.target;
		this.setState({ [name]: this.numberFormat(value) });
	};

	render() {
		// console.log(this.state.leaseId);
		if (this.state.submitted) {
			return <h3>New Tenant Added! </h3>;
		}
		return (
			<div>
				<Form>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Email </Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='email'
								id='email'
								type='text'
								value={this.state.email}
								onChange={this.handleChange}
							/>
							{this.state.error === 'EMAIL_EXISTS' ? (
								<div className='caption errorMessage'>
									Email already Exists!
								</div>
							) : (
								''
							)}
							{this.state.error === 'Email Required!' ? (
								<div className='caption errorMessage'>Email Required!</div>
							) : (
								''
							)}
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Tenant First Name </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='firstName'
								id='firstName'
								type='text'
								value={this.state.firstName}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Tenant Last Name </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='lastName'
								id='lastName'
								type='text'
								value={this.state.lastName}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Phone Number </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='phone'
								id='phone'
								maxlength='13'
								type='text'
								value={this.state.phone}
								onChange={this.handlePhoneNumber}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Date of Birth</Form.Label>
							<DatePicker
								onChange={(date, dateString) => {
									this.setState({ birthDate: dateString });
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Street Number</Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='homeNumber'
								id='homeNumber'
								type='text'
								value={this.state.homeNumber}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label className='form-label'>Street </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='homeStreet'
								id='homeStreet'
								value={this.state.homeStreet}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label className='form-label'>City</Form.Label>
							<Form.Control
								required
								className='form-text'
								name='city'
								type='text'
								value={this.state.city}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label className='form-label'>Postal Code</Form.Label>
							<Form.Control
								required
								className='form-text'
								name='postalCode'
								type='text'
								value={this.state.postalCode}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label className='form-label'>Province</Form.Label>
							<select
								name='province'
								className='form-control'
								defaultValue={this.state.province}
								onChange={e => this.setState({ province: e.target.value })}
							>
								<option value={''}>Select Province</option>
								{this.state.provinceList.map(province => (
									<option
										value={province.province_shortform}
										key={province.province_id}
									>
										{province.province_name}
									</option>
								))}
							</select>
						</Form.Group>
						{/* <Form.Group as={Col}>
							<Form.Label className='form-label'>Available Property</Form.Label>
							<select
								name='leaseId'
								className='form-control'
								onChange={e => {
									console.log(e.target.value);
									this.setState({
										leaseId: e.target.value
									});
								}}
							>
								<option value={''}>Select Property...</option>
								{this.state.propertyName.map(property => (
									<option value={property.lease_id}>
										{property.unit_name === 'Default'
											? property.property_name
											: property.property_name + ' Unit ' + property.unit_name}
									</option>
								))}
							</select>
						</Form.Group> */}
					</Form.Row>

					<br></br>
					<div className='text-right'>
						<Button
							style={{ marginRight: 8 }}
							onClick={this.props.onCancel}
							type='primary'
						>
							Cancel
						</Button>
						<Button onClick={this.submitForm} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default AddNewTenant;
