import React, { useState } from "react";
import { Modal, ModalTitle, Form } from "react-bootstrap";
import Task from "../../../Components/Tasks/Task";
import useWindowDimensions from "../../../Context/WindowsDimensions";
import axios from "axios";

import { Button, message, Avatar, DatePicker, Table } from "antd";
import LoadScreen from '../../../Components/LoadScreen';
import AssignVendorModal from './modals/AssignVendor';
import CreateEventModal from './modals/CreateEvent'
import moment from 'moment'
import RequestContext from '../RequestContext'
import { PDFDocument, rgb } from 'pdf-lib';
const cancelToken = axios.CancelToken.source();

const { RangePicker } = DatePicker;


// ------------------- Scheduling
class SchedulingAction extends React.Component {
    static contextType = RequestContext;

    constructor(props) {
      super(props);
      this.state = {
        assignModal: false,
        scheduleModal: false,
        doPrevent: false
      };
    }
    componentDidMount = async () => {
      //Jordan - I used this to manually build a notice form
      /*const pdfTemplate = await fetch(require('../../../pdf/document.pdf'));
      let testImageBytes = await fetch(require('../../../pdf/FOES3.png'));
      const pdfBuffer = await pdfTemplate.arrayBuffer();
      let testImageArrayBuff = await testImageBytes.arrayBuffer();
      console.log(testImageArrayBuff);
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      let testImage = await pdfDoc.embedPng(testImageArrayBuff);
      const pdfPage = pdfDoc.getPage(0);
      const form = pdfDoc.getForm();
      let newField = form.createTextField('Tenant1');
      newField.addToPage(pdfPage, {
        x: 35,
        y: 696,
        width: 255,
        height: 16,
        borderWidth: 0
      });
      newField = form.createTextField('Tenant2');
      newField.addToPage(pdfPage, {
        x: 35,
        y: 668,
        width: 255,
        height: 16,
        borderWidth: 0
      });
      newField = form.createTextField('Tenant3');
      newField.addToPage(pdfPage, {
        x: 304,
        y: 696,
        width: 255,
        height: 16,
        borderWidth: 0
      });
      newField = form.createTextField('Tenant4');
      newField.addToPage(pdfPage, {
        x: 304,
        y: 668,
        width: 255,
        height: 16,
        borderWidth: 0
      });
      newField = form.createTextField('LandlordName');
      newField.addToPage(pdfPage, {
        x: 35,
        y: 614,
        width: 525,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('StreetAddress');
      newField.addToPage(pdfPage, {
        x: 35,
        y: 549,
        width: 340,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('ApartmentNumber');
      newField.addToPage(pdfPage, {
        x: 390,
        y: 549,
        width: 170,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('City');
      newField.addToPage(pdfPage, {
        x: 35,
        y: 518,
        width: 236,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Province');
      newField.addToPage(pdfPage, {
        x: 285,
        y: 518,
        width: 90,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('PostalCode');
      newField.addToPage(pdfPage, {
        x: 390,
        y: 518,
        width: 170,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Date');
      newField.addToPage(pdfPage, {
        x: 57,
        y: 457,
        width: 118,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Time');
      newField.addToPage(pdfPage, {
        x: 270,
        y: 457,
        width: 55,
        height: 18,
        borderWidth: 0
      });
      newField = form.createCheckBox('TimePeriodAM');
      newField.addToPage(pdfPage, {
        x: 329,
        y: 462,
        width: 5,
        height: 6,
        borderWidth: 0
      });
      newField = form.createCheckBox('TimePeriodPM');
      newField.addToPage(pdfPage, {
        x: 360,
        y: 462,
        width: 6,
        height: 6,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose1');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 412,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose2');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 389,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose3');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 366,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose4');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 343,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose5');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 321,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose6');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 298,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose7');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 275,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose8');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 253,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose9');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 230,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose10');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 208,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose11');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 185,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose12');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 162,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose13');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 137,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('Purpose14');
      newField.addToPage(pdfPage, {
        x: 66,
        y: 114,
        width: 471,
        height: 18,
        borderWidth: 0
      });
      newField = form.createTextField('SignatureDate');
      newField.addToPage(pdfPage, {
        x: 348,
        y: 50,
        width: 212,
        height: 18,
        borderWidth: 0
      });
      const pdfBytes = await pdfDoc.save();
      window.open(window.URL.createObjectURL(new Blob([pdfBytes])));*/
    }
    openAssign = () => {
      this.setState({ assignModal: !this.state.assignModal });
    };
    openSchedule = () => {
      if (!this.state.doPrevent)
        this.setState({ scheduleModal: !this.state.scheduleModal });
    };
    preventClosing = (doPrevent) => {
      this.setState({ doPrevent: doPrevent });
    }
    afterEvent = (event) => {
      this.context.updateWork(event);
      axios
        .post("/jobRequest/update/status/workOrder",{job_id:this.context.request.job_id})
        .then((response) => {
          message.success("Work Order has successfully been scheduled");
          this.context.updateStatus("Work Order - Scheduled");
          this.context.addActivity(response.data.note, null, true);
          this.openSchedule();
        })
        .catch((error) => {
          this.context.setError(error.name, error.description);
        });
    };
    render() {
      const activeVendors = this.context.request.vendors.filter((v) => v.terminate === 0);
      const areVendors = activeVendors.length > 0;
      return (
        <div id="SchedulingAction">
          <Task complete={areVendors}>
            <Task.Title>Assign Vendor to Complete This Request</Task.Title>
            <Task.Action onClick={this.openAssign} primary>
              Assign Vendor
            </Task.Action>
          </Task>
          <Task unqualified={!areVendors}>
            <Task.Title>Schedule Work Order Date</Task.Title>
            <Task.Action primary onClick={this.openSchedule}>
              Schedule Vendor
            </Task.Action>
          </Task>
  
          <Modal
            show={this.state.assignModal}
            onHide={this.openAssign}
            size="lg"
            contentClassName="header-wide-modal"
            aria-labelledby="assign-vendor-modal"
            centered
            scrollable
          >
            {!this.state.assignModal ? (
              ""
            ) : (
              <AssignVendorModal
                show={this.state.assignModal}
                onHide={this.openAssign}
                request={this.context.request}
                updateVendors={this.context.updateVendors}
                updateEmployees={this.context.updateEmployees}
              />
            )}
          </Modal>
          <Modal
            show={this.state.scheduleModal}
            onHide={this.openSchedule}
            size="lg"
            centered
            fullscreen="true"
          >
            {!this.state.scheduleModal ? (
              ""
            ) : (
              <CreateEventModal
                updateStatus={this.context.updateStatus}
                updateImages={this.context.updateImages}
                preventClosing={this.preventClosing}
                onHide={this.openSchedule}
                afterEvent={this.afterEvent}
                vendors={activeVendors}
                employees={this.context.request.employees}
                tenants={this.context.request.tenants}
                owners={this.context.request.owners}
                job_id={this.context.request.job_id}
                request = {this.context.request}
                user={this.context.user}
                doPrevent={this.state.doPrevent}
              />
            )}
          </Modal>
        </div>
      );
    }
  }
  
  
  /*
              {this.state.vendorList
                .filter((vendor) =>
                  (vendor.fname + vendor.lname)
                    .toLowerCase()
                    .includes(this.state.searchValue.toLowerCase())
                )
                .map((vendor, index) => (
                  <VendorSelect
                    vendor={vendor}
                    selectVendor={this.selectVendor}
                    vendorSelected={this.state.vendorSelected}
                    key={index}
                  />
                ))}
  */


  const VendorSelect = ({ selectVendor, vendor, vendorSelected }) => {
    return (
      <div
        onClick={() => selectVendor(vendor)}
        className={
          vendorSelected.id === vendor.id
            ? "vendorSelect selectedBlue"
            : "vendorSelect"
        }
      >
        <div className="flex-10 m-auto  ">
          {vendor.imageurl !== "avatar_default.png" ? (
            <Avatar src={vendor.imageurl} />
          ) : (
            <Avatar>{vendor.fname[0]}</Avatar>
          )}
        </div>
        <div className="flex-50 m-auto">
          {vendor.fname} {vendor.lname}
        </div>
        <div className="flex-40 g-text m-auto">{vendor.role}</div>
      </div>
    );
  };
  // -------------------------

  
  export default SchedulingAction;