import React, { Component } from 'react';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import WorkOrderContext from '../WorkOrderContext';

import ImageUploadModal from '../../ImageUploadModal/ImageUploadModal';

const cancelToken = axios.CancelToken.source();
class Troubleshoot extends Component {
	// static contextType = RequestContext;
	static contextType = WorkOrderContext;
	constructor(props) {
		super(props);
		this.state = {
			description: '',
			errormsg: '',
			loading: false,
			workOrderDetails: '',
			workOrderId: ''
		};
	}
	componentDidMount = () => {
		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				this.setState({
					workOrderDetails: res.data.details,
					workOrderId: res.data.details[0].wo_id
				});
			});
	};
	troubleSave = () => {
		axios.post('/workOrder/update/troubleshoot/cause', {
			wo_id: this.state.workOrderId,
			causes: this.state.description
		});
		this.context.changePage('Images');
	};
	goNext() {}

	changeDesc = e => {
		this.setState({ description: e.target.value });
	};
	verify = () => {
		if (this.state.description === '') {
			this.setState({
				errormsg: 'Please enter a detailed description of the problem'
			});
			return false;
		}
		return true;
	};
	render() {
		console.log(this.context);

		return (
			<div className='Troubleshoot'>
				<div>
					<label>Detailed Description of the problem:</label>

					<textarea
						className='form-control'
						rows={6}
						// defaultValue={
						// 	this.context.getEventData()[0].workOrders[0].causes || ''
						// }
						onChange={this.changeDesc}
						id='comment'
						name='text'
					></textarea>
				</div>

				<p className='errorMsg'>{this.state.errormsg}</p>
				<br />
				<div className='buttonGroup'>
					<Button
						loading={this.state.loading}
						type='primary'
						className='mr-0 '
						onClick={this.troubleSave}
					>
						Next
					</Button>
				</div>
			</div>
		);
	}
}
export default Troubleshoot;
