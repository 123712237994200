import React from "react";
import LoadScreen from "../../Components/LoadScreen";
import axios from "axios";

import ActivityLog from "../MaintenanceRequest/RequestActivityLog";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import NoAccess from "../../Components/NoAccess";
import RequestContext from '../MaintenanceRequest/RequestContext'
import RequestListingView from '../MaintenanceRequest/actions/RequestListingView'
import { UserStateContext } from "../../Context/UserContext";
import PropertyIntakeTasks from './PropertyIntakeTasks'
import RequestPropertyIntakeData from './RequestPropertyIntakeData'
const cancelToken = axios.CancelToken.source();
export class IntakeRequest extends React.Component {
  static contextType = UserStateContext;

  constructor(props) {
    super();
    this.state = {
      //both id & request retrieved before page loads
      request_id: null,
      request: null,
      propertyid:null,
      access: null,
      //Determines whole request render. Loading/error/request
      page: "Loading",
      errorMessage: "",

      updateStatus : (status) => {
        this.setState({ request: { ...this.state.request, job_status: status } });
        //this.props.updateMaintenanceStatus(this.props.job_id,status);
      },
      /* Adds new activity logs to the activity logs list list */
      addActivity: (activity = [], count = -1, newLog = false) => {
        if (!Array.isArray(activity)) return;
        if(this.state.request.logs == null){
          return this.setState({request: { ...this.state.request, logs: activity } })
        }
        let newLogs = newLog
          ? activity.concat(this.state.request.logs)
          : this.state.request.logs.concat(activity);

        if (count !== -1 && count !== null)
          this.setState({
            request: { ...this.state.request, logs: newLogs, logCount: count },
          });
        else this.setState({ request: { ...this.state.request, logs: newLogs } });
      },
      /* Used when adding an image to the request */
      updateImages: (images) => {
        let currentRequest = {...this.state.request};
        let matchIndex;
        if (Array.isArray(currentRequest.images)) {
          let unmatchedImages = currentRequest.images.map((unmatchImage,unmatchIndex)=>{return [unmatchImage,unmatchIndex]});
          images.forEach((newImage)=>{
            matchIndex = unmatchedImages.findIndex((oldImage)=>{
              return newImage.id===oldImage[0].id;
            });
            if (matchIndex>=0) {
              currentRequest.images[unmatchedImages[matchIndex][1]] = newImage;
              unmatchedImages.splice(matchIndex,1);
            } else
              currentRequest.images.push(newImage)
          });
        } else
          currentRequest.images = images;
        this.setState({ request: currentRequest });
      },

      //Upate title
      updateTitle: (title) => {
        this.setState({ request: { ...this.state.request, job_title: title } });
      },
      updateTaskList: (taskList) => {
        this.setState({tasks:taskList});
      },
      updateTenants: (tenants) => {
        this.setState({tenants:tenants});
      },
      updateProperty: (property) => {
        this.setState({property:property});
      },
      updateOwners: (owners) => {
        this.setState({owners:owners});
      },
      updateData:(property,owners,tenants,leases) => {
        this.setState({property:property,owners:owners,tenants:tenants,leases:leases});
      },
      setError: (error_name, error_desc) => {
        this.setState({ page: "Error", errorMessage: [error_name, error_desc] });
        return;
      }
    };
  }

  UNSAFE_componentWillMount() {
    let route = window.location.href;
    let request = this.props.job_id || route.split("/")[5];
    let request_id = parseInt(request);
    this.setState({ request_id: request_id, user: this.context.user });
    this.getRequest(request_id);
  }

  componentDidUpdate = (prevProps) => {
    let prevId = prevProps.location.pathname.split("/")[3];
    let currentId = window.location.href.split("/")[5];
    if(prevId !== currentId){
        this.setState({page:"Loading"});
        this.getRequest(currentId);
    }
  }


  getRequest = (request_id) => {
    axios
    .get("jobRequest/get/intakeRequest/" + request_id)
    .then((response) => {
      document.title = response.data.request.job_title;
      if (response.data.error) {
        this.setState({ page: "Error", errorMessage: response.data.error });
      } else if (!response.data.access) {
        this.setState({
          access: response.data.access,
          page: "NoAccess",
          access_permission: true,
        });
      } else
        this.setState({
          request: response.data.request,
          page: "Request",
          tasks:response.data.tasks,
          propertyid:response.data.propertyid,
          action_permission: true,
        });
    })
    .catch((err) => {
      console.log(err)
      if (err)
        this.setState({
          errorMessage: "Something Went Wrong.",
          page: "Error",
        });
    });
  }

  render() {
    //Still fetching the data
    if (this.state.page === "Loading"){ 
      return <LoadScreen className='m-auto'/>;
    }

    //Some error occurred
    else if (this.state.page === "Error"){
      return <ErrorPage errorMessage={this.state.errorMessage} />;
    }
    //No access to this job request
    else if (this.state.page === "NoAccess"){
      return <NoAccess />;
    }
    
    else if (this.state.page === "Request") {
      
      return (
        <div id="jobRequestShell" className={`${this.props.invertedColors ? 'invertColors' : ''}`}>
          <RequestContext.Provider value={this.state}>
            <RequestListingView mobile={this.props.mobile} request={this.state.request} />
            
            {this.state.request.job_status === 'In Progress' ? <PropertyIntakeTasks /> : ""}
            <RequestPropertyIntakeData />
            <ActivityLog context={RequestContext} />
          </RequestContext.Provider>
        </div>
      );
    }
  }
}

function ErrorPage({ errorMessage }) {
  return (
    <Result
      status="500"
      title="500"
      subTitle={errorMessage}
      extra={
        <Link to="/maintenance">
          <Button type="primary">Back to Maintenance Requests</Button>
        </Link>
      }
    />
  );
}



export default IntakeRequest;

/*
register property address ->
	-updates property address
	

connect owners -> 
	-inserts new owners
	-connects old/new owners to property
	
	
Confirm units and unit names ->
	-updates unit names

enter leases ->
	-Enter lease details
	-Connect lease to units

Enter Tenants -> 
	->Enter tenant details
	->invite new tenants
	->connect tenants with lease


*/