import React from 'react';
import {
	Table,
	Space,
	DatePicker,
	Button,
	Upload,
	message,
	Select
} from 'antd';
import '../Finance/finance.css';

import moment from 'moment';

import { CSVLink } from 'react-csv';
import { Form, Modal } from 'react-bootstrap';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import ChargeExportHeaders from './chargeExportHeaders';

class ExportModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fileName: '',
			isValid: true,
			defaultName: `charge_sheet_${moment().format('MM-DD-YYYY')}`
		};
	}

	setFileName = name => {
		if (name === '') return this.setState({ fileName: name, isValid: true });
		else if (this.isValid(name))
			return this.setState({ fileName: name, isValid: true });
		else return this.setState({ fileName: name, isValid: false });
	};

	isValid = fname => {
		var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
		var rg2 = /^\./; // cannot start with dot (.)
		var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
		return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
	};

	render() {
		return (
			<>
				<Modal.Body>
					<Form>
						<Form.Label className='fs-12'>File Name</Form.Label>
						<Form.Control
							type='text'
							value={this.state.fileName}
							placeholder={this.state.defaultName}
							style={{
								width: '60%',
								display: 'inline-block',
								marginLeft: '3px'
							}}
							onChange={e => this.setFileName(e.target.value)}
						/>
						<div className='d-inline g-text-sm'>.csv</div>
					</Form>
					<br />
					<div className='text-center'>
						<Button type='primary' size='large' disabled={!this.state.isValid}>
							<CSVLink
								onClick={e => {
									return this.state.isValid;
								}}
								data={this.props.filteredData}
								headers={ChargeExportHeaders}
								className={this.state.isValid ? '' : 'cursor-not-allowed'}
								filename={`${
									this.state.fileName !== ''
										? this.state.fileName
										: this.state.defaultName
								}.csv`}
								target='_blank'
							>
								<div className='fs-12 w-text-imp'>
									<DownloadOutlined /> Download Data
								</div>
							</CSVLink>
						</Button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onHide}>Close</Button>
				</Modal.Footer>
			</>
		);
	}
}

export default ExportModal;
