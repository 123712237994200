import React, { useState } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import {
	Card,
	Avatar,
	Button,
	message,
	Dropdown,
	Menu,
	Select,
	Space,
	Table
} from 'antd';
import axios from 'axios';

import LoadScreen from '../../../Components/LoadScreen';
import { UserStateContext } from '../../../Context/UserContext';
import Moment from 'react-moment';
import Log from '../../../Components/Log/Log';
import { Modal, Form, Feedback } from 'react-bootstrap';
import { vendorProvinces } from '../../../StringData/VendorObjects';
import './company.css';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import validEmailRegex from '../../../StringData/validEmailRegex';
const cancelToken = axios.CancelToken.source();

const validateForm = errors => {
	let valid = true;
	Object.values(errors).forEach(val => val.length > 0 && (valid = false));
	return valid;
};

const countErrors = errors => {
	let count = 0;
	Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
	return count;
};

class EmployeeIntakeForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'Form',
			employeeList: [],
			fname: '',
			lname: '',
			email: '',
			sinNumber: '',
			phone: '',
			homeStreet: '',
			city: '',
			province: '',
			postalCode: '',
			emergencyContact: '',
			emergPhone: '',
			role: '',
			error: null,
			formValid: false,
			errorCount: null,
			errors: {
				fName: '',
				lName: '',
				sinNumber: '',
				emergPhone: '',
				phone: '',
				postalCode: '',
				email: '',
				emergencyContact: '',
				password: ''
			}
		};
	}
	numberFormat = value => {
		let newNumber = '';
		let phoneNumber = ('' + value).replace(/[^\d]/g, '');
		for (let i = 0; i < phoneNumber.length; i++) {
			if (i == 0) {
				newNumber = '(';
			} else if (i == 3) {
				newNumber = newNumber + ')';
			} else if (i == 6) {
				newNumber = newNumber + '-';
			}

			newNumber = newNumber + phoneNumber[i];
		}
		return newNumber;
	};
	/* Auto formatting phone number */
	handlePhoneNumber = e => {
		const { name, value } = e.target;
		this.setState({ [name]: this.numberFormat(value) });
	};

	//For number typing in SIN text field
	handleSin = e => {
		const number = ('' + e.target.value).replace(/[^\d]/g, '');
		this.setState({ sinNumber: number });
	};

	/* Add functions here */
	handleChange = event => {
		// this.setState({homeStreet: event.target.value});
		const { name, value } = event.target;
		let errors = this.state.errors;

		switch (name) {
			case 'fname':
				errors.fName = value.length < 2 ? 'Please enter your First Name.' : '';
				break;

			case 'lname':
				errors.lName = value.length < 2 ? 'Please enter your Last Name.' : '';
				break;

			case 'email':
				errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid';
				break;

			case 'sinNumber':
				errors.sinNumber = value.length != 9 ? 'SIN must be 9 digits long' : '';
				break;

			case 'phone':
				errors.phone =
					value.length < 10 ? 'Phone must have at least 10 digits' : '';
				break;

			case 'emergencyContact':
				errors.emergencyContact =
					value.length < 4
						? 'Emergency Contact must be at least 4 characters long.'
						: '';
				break;

			case 'emergPhone':
				errors.emergPhone =
					value.length < 10 ? 'Emergency Phone must be at least 10 digits' : '';
				break;
		}
		this.setState({ errors, [name]: value });
	};

	addEmployee = () => {
		axios
			.post('/auth/validateEmail', { email: this.state.email })
			.then(response => {
				if (!response.data.ok) {
					return this.state.email === ''
						? this.setState({ error: 'Email Required!' })
						: this.setState({ error: 'EMAIL_EXISTS' });
				} else {
					axios
						.post('/addNewEmployee', {
							fname: this.state.fname,
							lname: this.state.lname,
							name: this.state.fname + ' ' + this.state.lname,
							email: this.state.email,
							homeStreet: this.state.homeStreet,
							city: this.state.city,
							province: this.state.province,
							postalCode: this.state.postalCode,
							sinNumber: this.state.sinNumber,
							phone: this.state.phone,
							emergencyContact: this.state.emergencyContact,
							emergPhone: this.state.emergPhone,
							role: this.state.role,
							crossdomain: true,
							host: window.location.host
						})
						.then(response => {
							if (response.data.error) {
								message.error('An Error Occurred. Please try again.');
								return this.setState({ error: response.data.error });
							} else {
								this.setState({
									formValid: validateForm(this.state.errors),
									errorCount: countErrors(this.state.errors),
									fname: '',
									lname: '',
									email: '',
									homeStreet: '',
									city: '',
									province: '',
									postalCode: '',
									sinNumber: '',
									phone: '',
									emergencyContact: '',
									emergPhone: '',
									role: '',
									error: null
								});
								this.props.onSuccess(response.data.newEmployee);
							}
						});
				}
			});
	};
	submitlist = event => {
		event.preventDefault();

		this.setState({ formValid: validateForm(this.state.errors) });
		this.setState({ errorCount: countErrors(this.state.errors) });

		axios
			.post('/addEmployeeList', { employeeList: this.state.employeeList })
			.then(response => {
				if (response.data.error) {
					return this.setState({ error: response.data.error });
				} else {
					this.props.onSuccess(response.data.newEmployeeList);
				}
			});
	};
	addAnotherEmployee = () => this.setState({ page: 'Form' });
	deleteRow = e => {
		this.setState({
			employeeList: this.state.employeeList.filter(
				element => element.email !== e
			)
		});
	};

	render() {
		const { employeeList } = this.state;
		const columns = [
			{ title: 'Name', dataIndex: 'name', key: 'name' },
			{ title: 'Email', key: 'email', dataIndex: 'email' },
			{ title: 'Role', key: 'role', dataIndex: 'role' },
			{
				title: 'Remove',
				key: 'action',
				width: 50,
				render: (text, record) => (
					<DeleteOutlined
						className='m-auto'
						onClick={() => {
							this.deleteRow(text.email);
						}}
					/>
				)
			}
		];
		const { errors, formValid } = this.state;
		return (
			<div className='intakeForm'>
				{/* {this.state.page === "Form" ? (
          <div className="intakeFormBody"> */}
				{/* <div className="caption">
              Please enter the following employee details.
            </div>
            <br /> */}
				<div className='addEmployeeForm'>
					<Form>
						<Form.Group>
							<Form.Label>Employee Name</Form.Label>
							<Form.Control
								required
								type='text'
								placeholder='First Name'
								name='fname'
								value={this.state.fname}
								onChange={this.handleChange}
								className='doubleInput'
								noValidate
							/>
							<Form.Control.Feedback type='invalid'>
								Looks good!
							</Form.Control.Feedback>
							<Form.Control
								type='text'
								name='lname'
								value={this.state.lname}
								onChange={this.handleChange}
								placeholder='Last Name'
								className='doubleInput ml-2 '
								noValidate
							/>
							{errors.fName.length > 0 && (
								<span className='error'>{errors.fName}</span>
							)}
							{errors.lName.length > 0 && (
								<span className='error'>{errors.lName}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='text'
								placeholder='Email'
								name='email'
								value={this.state.email}
								onChange={this.handleChange}
								noValidate
							/>

							{errors.email.length > 0 && (
								<span className='error'>{errors.email}</span>
							)}
							{this.state.error === 'EMAIL_EXISTS' ? (
								<div className='caption errorMessage'>Email already Exists</div>
							) : (
								''
							)}
							{this.state.error === 'Email Required!' ? (
								<div className='caption errorMessage'>Email Required!</div>
							) : (
								''
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Phone Number</Form.Label>
							<Form.Control
								type='tel'
								placeholder='(123)456-7890'
								maxlength='13'
								name='phone'
								value={this.state.phone}
								onChange={this.handlePhoneNumber}
								noValidate
							/>
							{errors.phone.length > 0 && (
								<span className='error'>{errors.phone}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Street Address</Form.Label>
							<Form.Control
								type='text'
								placeholder='Street Address'
								name='homeStreet'
								value={this.state.homeStreet}
								onChange={this.handleChange}
								noValidate
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>City</Form.Label>
							<Form.Control
								type='text'
								placeholder='City'
								name='city'
								value={this.state.city}
								onChange={this.handleChange}
								noValidate
							/>
							{errors.phone.length > 0 && (
								<span className='error'>{errors.phone}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label className='form-label'>Province</Form.Label>
							<select
								name='province'
								className='form-control'
								defaultValue={this.state.province}
								onChange={e => this.setState({ province: e.target.value })}
							>
								<option value={''}>Select Province</option>
								{Object.keys(vendorProvinces).map(province => (
									<option value={province} key={province}>
										{vendorProvinces[province]}
									</option>
								))}
							</select>
						</Form.Group>
						<Form.Group>
							<Form.Label>Postal Code</Form.Label>
							<Form.Control
								type='text'
								placeholder='Postal Code'
								name='postalCode'
								value={this.state.postalCode}
								onChange={this.handleChange}
								noValidate
							/>
							{errors.phone.length > 0 && (
								<span className='error'>{errors.phone}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>SIN Number</Form.Label>
							<Form.Control
								type='text'
								placeholder='Sin Number'
								name='sinNumber'
								value={this.state.sinNumber}
								onChange={this.handleSin}
								noValidate
							/>
							{errors.sinNumber.length > 0 && (
								<span className='error'>{errors.sinNumber}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Emergency Contact</Form.Label>
							<Form.Control
								type='text'
								placeholder='Name'
								className='doubleInput '
								name='emergencyContact'
								value={this.state.emergencyContact}
								onChange={this.handleChange}
								noValidate
							/>

							<Form.Control
								type='text'
								maxlength='13'
								placeholder='(123)456-7890'
								className='doubleInput ml-2'
								name='emergPhone'
								value={this.state.emergPhone}
								onChange={this.handlePhoneNumber}
								noValidate
							/>
							{errors.emergencyContact.length > 0 && (
								<span className='error'>{errors.emergencyContact}</span>
							)}
							{errors.emergPhone.length > 0 && (
								<span className='error'>{errors.emergPhone}</span>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Position in Company</Form.Label>
							<Form.Control
								as='select'
								name='role'
								onChange={this.handleChange}
								value={this.state.role}
								placeholder='Position'
								noValidate
							>
								<option hidden>Company Role</option>
								<option>Property Manager</option>
								<option>Branch Manager</option>
								<option>Maintenance</option>
								<option>Accounting</option>
							</Form.Control>
						</Form.Group>
						<div className='text-right'>
							<Button
								disabled={
									errors.fName.length > 0 ||
									errors.lName.length > 0 ||
									errors.email.length > 0
								}
								type='primary'
								onClick={this.addEmployee}
							>
								Add Employee
							</Button>
						</div>
					</Form>
				</div>
				{/* </div> */}
				{/* // ) : (
        //   <div className="intakeFormBody mt-4">
        //     <Table
        //       columns={columns}
        //       dataSource={employeeList}
        //       pagination={false}
        //       rowKey={"email"}
        //       bordered={false}
        //     />
        //     <div className="text-center mt-4 mb-4">
        //       <Button onClick={this.addAnotherEmployee}>
        //         Add Another Employee
        //       </Button>
        //     </div>
        //     <div className="intakeBtnDiv">
        //       <Button onClick={this.props.onHide} className="mr-2">
        //         Cancel
        //       </Button>
        //       <Button type="primary" onClick={this.submitlist}>
        //         Submit Employee List
        //       </Button>
        //     </div>
        //   </div>
        // )} */}
			</div>
		);
	}
}

export default EmployeeIntakeForm;
