import React, { Component } from "react";
import { Button } from "antd";
class FollowUp extends Component {
  next = (status) => {
    this.props.setEndStatus(status);
  };

  render() {
    return (
      <div>
        <div className='text-center'>
          <h5>Next Step</h5>
        </div>
        <br />
        <div>
          <p>
            If you require more time to complete this work order, click below to
            save your session and continue another time.
          </p>
          <div className='text-center'>
            <Button
              type='primary'
              className='btnWidth'
              onClick={() => this.next("Continue")}>
              Save and Continue Later
            </Button>
          </div>
          <div className='borderLine mt-2 mb-2 '></div>

          <p>
            If you need to reschedule this work order to another day, click
            below to submit an extension request to the property manager.{" "}
          </p>
          <div className='text-center'>
            <Button
              type='primary'
              className='btnWidth'
              onClick={() => this.next("Extend")}>
              Extend Work Order
            </Button>
          </div>
          <div className='borderLine mt-2 mb-2'></div>
          <p>
            If you wish to complete this work order, click below to submit it to
            the property manager.
          </p>
          <div className='text-center'>
            <Button
              type='primary'
              className='  btnWidth'
              onClick={() => this.next("Complete")}>
              Complete Work Order
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default FollowUp;
