import React, { useState, useEffect } from 'react';
import { CardAction, WorkOrderFooter } from '../WorkOrder';
import { Button, message } from 'antd';
import WorkOrderContext from '../WorkOrderContext';
import RequestContext from '../../../Pages/MaintenanceRequest/RequestContext';
import axios from 'axios';

const MenuScreen = () => {
	const context = React.useContext(WorkOrderContext);
	const requestContext = React.useContext(RequestContext);
	const [workOrderId, setWorkOrderId] = useState(null);
	console.log(context);
	useEffect(() => {
		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: context.getEventAction().idcalendar_events
			})
			.then(res => setWorkOrderId(res.data.details[0].wo_id));
	});
	return (
		<div>
			<CardAction
				text='Identify Causes of problem '
				action={() => context.changePage('Troubleshoot')}
			/>
			<CardAction
				text='Upload Picture or Document'
				action={() => context.changePage('Images')}
			/>
			<CardAction
				text='Estimate Expenses'
				action={() => context.changePage('Materials')}
			/>
			{
				//<CardAction text='Enter actions taken' action={() => context.changePage("Action")} />
				//<CardAction text='Enter labour details' action={() => context.changePage("Labour")} />
			}
			<br />
			<br />
			<WorkOrderFooter>
				<Button
					type='primary'
					onClick={() => {
						// axios
						// 	.post('/jobRequest/update/status/workOrder/start', {
						// 		job_id: requestContext.request.job_id
						// 	})
						axios
							.post('/eventCalendar/updateStatusToWorkdone', {
								eventId: requestContext.activeEvent.idcalendar_events,
								job_id: requestContext.request.job_id
							})
							.then(response => {
								requestContext.updateEventStatus('Work Order - Work Done');
								requestContext.addActivity(response.data.note, null, true);
							})
							.catch(error => {
								console.log(error);
							});
						// axios
						// 	.post('/workOrder/update/status/completeNeedsReview', {
						// 		job_id: context.jobData.job_id,
						// 		wo_id: workOrderId
						// 	})
						// 	.then(response => {
						// 		requestContext.updateRequestWorkOrders(
						// 			requestContext.activeEvent.event_id
						// 		);
						// 		requestContext.updateStatus('Work Order - Review');
						// 		requestContext.addActivity(response.data.note, null);
						// 		message.success(
						// 			'Work Order has been successfully marked as complete and has been sent for review!'
						// 		);
						// 	});
					}}
				>
					Finish
				</Button>
			</WorkOrderFooter>
		</div>
	);
};

export default MenuScreen;
