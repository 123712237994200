import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../Context/UserContext';
import Cookies from 'js-cookie';
import MainLayout from '../layout/MainLayout/MainLayout';
import axios from 'axios';
import LoadScreen from '../Components/LoadScreen';
const cancelToken = axios.CancelToken.source();
//https://medium.com/better-programming/building-basic-react-authentication-e20a574d5e71

function Authenticator(props) {
	let { location } = props;
	let user = useUserState();
	let dispatch = useUserDispatch();
	const [page, setPage] = useState('Loading');
	let session = Cookies.get('session');
	axios.defaults.headers.common['Authorization'] = session;
	// console.log(session);
	//If user is not null - proceed. (session was already initalized)
	if (user !== null) {
		return <MainLayout userType={user.user.type} />;
	} else {
		//Session was previously intact - try refreshing the session.
		if (session !== undefined) {
			axios.post('/auth/refreshUser', {}).then(response => {
				//Session was found. Set user and reinitalize session in cookies
				if (response.data.ok) {
					dispatch({ state: { user: response.data.user }, type: 'initalize' });
					Cookies.set('session', {
						session_id: response.data.session_id,
						type: response.data.user.type
					});
					setPage('private');
				} else {
					Cookies.set(
						'destination',
						{
							location: location.pathname
						},
						{ sameSite: 'strict' }
					);
					Cookies.remove('session');
					setPage('not_authenticated');
				}
			});
		} else {
			Cookies.set(
				'destination',
				{
					location: location.pathname
				},
				{ sameSite: 'strict' }
			);
			return <Redirect to='/login' />;
		}
	}
	if (page === 'Loading') return <LoadScreen />;
	else if (page === 'private') {
		return <MainLayout userType={user.user.type} />;
	} else if (page === 'not_authenticated') return <Redirect to='/login' />;
}

export default Authenticator;
