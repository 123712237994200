import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Tooltip } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { UserStateContext } from '../../Context/UserContext';
import moment from 'moment';
import axios from 'axios';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import LoadScreen from '../LoadScreen';
import { Modal, ModalTitle } from 'react-bootstrap';
import Contacts from '../../Pages/People/Contacts';
const cancelToken = axios.CancelToken.source();
class ThreadList extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			selectContacts: [],
			newThreadModal: false,
			expandedGroup: '',
			searchValue: ''
		};
	}

	//   getpeople= ()=>{
	//     console.log("g")
	//     axios.get('/get/employeeavailability')
	//     .then((response) => {this.setState({taskarray:response.data.Items, loading: false})
	//     console.log(this.state.taskarray);
	//     return;
	// })
	// };

	expandGroup = groupTitle => {
		if (this.state.expandedGroup !== groupTitle)
			this.setState({ expandedGroup: groupTitle });
		else this.setState({ expandedGroup: '' });
	};

	showNewThreadModal = () =>
		this.setState({
			selectContacts: this.state.newThreadModal
				? []
				: this.state.selectContacts,
			newThreadModal: !this.state.newThreadModal
		});

	newThread = () => {
		if (this.state.selectContacts.length > 0) {
			axios
				.post('/communication/newCreateThread', {
					people: this.state.selectContacts
				})
				.then(response => {
					console.log(this.state.selectContacts);
					this.setState({ selectContacts: [], newThreadModal: false });
					this.props.updateThreads();
					this.props.history.push(
						`/communication/${response.data.newThread.thread_id}`
					);
				});
		} else if (this.state.selectedEmployee.length > 0) {
			axios
				.post('/communication/newCreateThread', {
					people: this.state.selectedEmployee
				})
				.then(response => {
					this.setState({ selectedEmployee: [], newThreadModal: false });
					this.props.updateThreads();
					this.props.history.push(
						`/communication/${response.data.newThread.thread_id}`
					);
				});
		}
	};

	toggleContact = person => {
		let newContacts = [...this.state.selectContacts];
		let contactIndex = newContacts.findIndex(contact => {
			return contact.id === person.id && contact.type === person.type;
		});
		if (contactIndex >= 0) {
			newContacts.splice(contactIndex, 1);
		} else {
			newContacts.push(person);
		}

		this.setState({ selectContacts: newContacts });
	};

	render() {
		let activeThread = window.location.href.split('/')[4];
		// console.log(this.props.groupedThreads);
		let newThreadButton = (
			<Button
				type='primary'
				className='newthread-btn'
				onClick={this.showNewThreadModal}
				style={{ marginLeft: '20px' }}
			>
				New Message
			</Button>
		);
		// console.log(this.props.groupedThreads);
		return (
			<div className={`listContainer`}>
				<Modal
					scrollable
					size='lg'
					dialogClassName='header-wide-modal'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					show={this.state.newThreadModal}
					onHide={this.showNewThreadModal}
				>
					<Modal.Header closeButton>Contact List</Modal.Header>
					<Contacts
						newThread={this.newThread}
						toggleContact={this.toggleContact}
						selectContacts={this.state.selectContacts}
						user={this.context.user}
					/>
					<Modal.Footer>
						<Button
							onClick={() => {
								this.newThread();
							}}
							disabled={this.state.selectContacts.length == 0}
						>
							Start Chat
						</Button>
					</Modal.Footer>
				</Modal>

				<ModalTitle>
					{this.props.staticHeader ? (
						<div id='listingHeader'>
							<div>
								<Searchbar
									onChange={e => this.setState({ searchValue: e.target.value })}
									value={this.state.searchValue}
								/>
							</div>
							<div>{newThreadButton}</div>
						</div>
					) : (
						<div id='listingHeader'>{newThreadButton}</div>
					)}
				</ModalTitle>

				<Modal.Body>
					<ul className='threadList'>
						{this.props.loadedThreads ? (
							this.props.groupedThreads.order?.length > 0 ? (
								this.props.groupedThreads.order
									.filter(groupName =>
										String(
											this.props.groupedThreads.threads[groupName].threads[0]
												.thread_name
										)
											.replace(/\s+/g, '')
											.toLowerCase()
											.includes(
												this.state.searchValue
													.toLowerCase()
													.replace(/\s+/g, '')
													.replace(/,/g, '')
											)
									)
									.map(groupName => {
										let group = this.props.groupedThreads.threads[groupName];
										return (
											<ThreadGroupListing
												key={group.id}
												group={group}
												actionNoLink={this.props.actionNoLink}
												selectThread={this.props.selectThread}
												user_id={this.context.user.id}
												activeThread={activeThread}
												expandGroup={this.expandGroup}
												isExpanded={group.id === this.state.expandedGroup}
												searchValue={this.state.searchValue}
											/>
										);
									})
							) : (
								<div>You currently have no active threads.</div>
							)
						) : (
							<LoadScreen className='m-auto' />
						)}
					</ul>
				</Modal.Body>
			</div>
		);
	}
}

const ThreadGroupListing = ({
	group,
	user_id,
	expandGroup,
	isExpanded,
	actionNoLink,
	selectThread,
	activeThread,
	searchValue
}) => {
	const expandThisGroup = () => {
		expandGroup(group.id);
	};

	if (group.threads.length > 1) {
		return (
			<div style={{ borderBottom: 'medium solid #e3e3e3' }}>
				<li className='m-auto' style={{ marginBottom: '10px' }}>
					<div
						onClick={expandThisGroup}
						style={{ cursor: 'pointer', borderBottom: 'medium solid #e3e3e3' }}
						className='threadListing'
					>
						<div className='threadLeft'>
							<Avatar.Group
								size='small'
								maxCount={1}
								maxStyle={{ backgroundColor: '#4d7cfe', color: '#ffffff' }}
							>
								{group.threads[0].people
									.filter(person => person.user_id !== user_id)
									.map(person => (
										<Tooltip
											title={
												person.lname
													? person.fname + ' ' + person.lname
													: person.fname
											}
											key={person.user_id}
										>
											<Avatar
												style={{ marginLeft: '0px', marginRight: '0px' }}
												key={person.user_id}
												src={person.imageurl}
											/>
										</Tooltip>
									))}
							</Avatar.Group>
						</div>
						<div className='threadMain'>
							<div className='caption threadName'>
								{' '}
								{group.threads[0].people
									.filter(person => person.user_id !== user_id)

									.map(person =>
										person.lname
											? person.fname + ' ' + person.lname
											: person.fname
									)}{' '}
							</div>
							<div
								className={
									group.isUnread
										? 'unreadTxt txtMsg b-text-sm'
										: 'g-text-sm txtMsg'
								}
							>
								{group.threads.length} threads in total
							</div>
						</div>
						<div className='threadRight g-text-sm'>{group.latestTime}</div>
					</div>
				</li>
				{isExpanded
					? group.threads.map(thread => (
							<ThreadListing
								key={thread.thread_id}
								actionNoLink={actionNoLink}
								selectThread={selectThread}
								thread={thread}
								user_id={user_id}
								active={
									activeThread
										? parseInt(activeThread) === thread.thread_id
										: false
								}
							/>
					  ))
					: null}
			</div>
		);
	} else
		return (
			<ThreadListing
				key={group.threads[0].thread_id}
				actionNoLink={actionNoLink}
				selectThread={selectThread}
				thread={group.threads[0]}
				user_id={user_id}
				active={
					activeThread
						? parseInt(activeThread) === group.threads[0].thread_id
						: false
				}
			/>
		);
};

const ThreadListing = ({
	thread,
	actionNoLink,
	selectThread,
	user_id,

	active
}) => {
	if (!('people' in thread)) return null;
	let recentUserIndex = thread.people.findIndex(threadPerson => {
		return threadPerson.user_id === thread.recent_msg_user_id;
	});

	// const getTime = time => {
	// 	time = moment(new Date(time));
	// 	let days = moment().diff(time, 'days');
	// 	if (days > 0) return days + 'd';

	// 	let hours = moment().diff(time, 'hours');
	// 	if (hours > 0) return hours + 'h';

	// 	let minutes = moment().diff(time, 'minutes');
	// 	return minutes + 'm';
	// };
	const getTime = time => {
		let dateTime = moment(new Date(time));
		let textTime = dateTime.format('h:mma');
		let textDate = dateTime.format('MMM D');
		let textDay = dateTime.format('ddd');
		let textYearWithDate = dateTime.format('MMM D, YYYY');
		let hours = moment().diff(dateTime, 'hours');
		if (hours < 24) return textTime;
		let days = moment().diff(dateTime, 'days');
		if (days < 7) return textDay;
		else if (days >= 7 && days < 365) return textDate;
		else return textYearWithDate;
	};

	const captionThread = thread => {
		if (thread.thread_name == null) {
			return 'Thread';
		} else {
			return thread.thread_name.substr(thread.thread_name.indexOf(',') + 1);
		}
	};
	// console.log(thread);

	if (actionNoLink) {
		return (
			<li className='m-auto'>
				<div
					style={{ cursor: 'pointer' }}
					className='threadListing'
					onClick={() => selectThread(thread.thread_id)}
				>
					<div className='threadLeft'>
						<Avatar src={thread.recent_msg_img} />
					</div>
					<div className='threadMain'>
						<div className='fs-13'>{thread.thread_name}</div>
						<div
							className={
								thread.unread ? 'unreadTxt txtMsg' : 'g-text-sm txtMsg'
							}
						>
							{thread.message ? thread.message : 'No messages.'}
						</div>
					</div>
					<div className='threadRight g-text-sm'></div>
				</div>
			</li>
		);
	} else
		return (
			<li className='m-auto'>
				<Link
					className={`threadListing ${active ? 'activeThread' : ''}`}
					to={'/communication/' + thread.thread_id}
				>
					<div className='threadLeft'>
						<Avatar.Group
							size='small'
							maxCount={1}
							maxStyle={{ backgroundColor: '#4d7cfe', color: '#ffffff' }}
						>
							{thread.people
								.filter(person => person.user_id !== user_id)
								.map(person => (
									<Tooltip
										title={
											person.lname
												? person.fname + ' ' + person.lname
												: person.fname
										}
										key={person.user_id}
									>
										<Avatar
											style={{ marginLeft: '0px', marginRight: '0px' }}
											key={person.user_id}
											src={person.imageurl}
										/>
									</Tooltip>
								))}
						</Avatar.Group>
					</div>
					<div className='threadMain'>
						<div className='caption threadName'> {thread.thread_name} </div>
						<div
							className={
								thread.unread && thread.recent_msg_user_id !== user_id
									? 'unreadTxt txtMsg b-text-sm'
									: 'g-text-sm txtMsg'
							}
						>
							{thread.message
								? `${
										recentUserIndex >= 0
											? thread.recent_msg_user_id === user_id
												? 'You'
												: thread.people[recentUserIndex].fname +
												  ' ' +
												  thread.people[recentUserIndex].lname
											: 'Missing User'
								  }: ${thread.message}`
								: 'No messages.'}
						</div>
					</div>
					<div className='threadRight g-text-sm'>
						{getTime(thread.time ? thread.time : thread.create_date)}
					</div>
				</Link>
			</li>
		);
};

export default ThreadList;
