import React, { useState } from 'react';

const DAYS_PER_MONTH = [
	{
		name: 'January',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'February',
		days: function (year) {
			return year % 4 === 0
				? year % 100 === 0
					? year % 400 === 0
						? 29
						: 28
					: 29
				: 28;
		}
	},
	{
		name: 'March',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'April',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'May',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'June',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'July',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'August',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'September',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'October',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'November',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'December',
		days: function (year) {
			return 31;
		}
	}
];

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const HEADER_FONT = `normal normal normal 15px "Rubik", sans-serif`;

function getDaysPerYear(yearNum) {
	return yearNum % 4 === 0
		? yearNum % 100 === 0
			? yearNum % 400 === 0
				? 366
				: 365
			: 366
		: 365;
}

function getDateDiff(endDate, startDate, blockSize, withTimeOfDay = false) {
	let utcDiff = withTimeOfDay
		? Date.UTC(
				endDate.getFullYear(),
				endDate.getMonth(),
				endDate.getDate(),
				endDate.getHours(),
				endDate.getMinutes(),
				endDate.getSeconds(),
				endDate.getMilliseconds()
		  ) -
		  Date.UTC(
				startDate.getFullYear(),
				startDate.getMonth(),
				startDate.getDate(),
				startDate.getHours(),
				startDate.getMinutes(),
				startDate.getSeconds(),
				startDate.getMilliseconds()
		  )
		: Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
		  Date.UTC(
				startDate.getFullYear(),
				startDate.getMonth(),
				startDate.getDate()
		  );
	return (utcDiff / 86400000) * blockSize;
}

function getDateInt(
	currentDate,
	startingYear,
	blockSize,
	calendarView,
	withTimeOfDay = false
) {
	let startingDate = new Date(`January 1, ${startingYear} 00:00:00`);
	return getDateDiff(currentDate, startingDate, blockSize, withTimeOfDay);
}

function getTextWidth(text, font) {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	context.font = font || getComputedStyle(document.body).font;

	return Math.round(context.measureText(text).width);
}

class MonthBlock extends React.Component {
	constructor(props) {
		super(props);
		this.monthDiv = React.createRef();
	}
	shouldComponentUpdate(nextProps) {
		let calProps = nextProps.calProps;
		let monthPxStart = getDateInt(
			new Date(nextProps.currentYear, nextProps.currentMonth, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			nextProps.calendarView
		);
		let monthPxEnd =
			monthPxStart + calProps.blockWidth * nextProps.daysOfMonth - 1;
		return (
			nextProps.xScroll !== this.props.xScroll &&
			((calProps.xScroll >= monthPxStart && nextProps.xScroll <= monthPxEnd) ||
				this.monthDiv.current.style.paddingLeft !== '5px')
		);
	}
	render() {
		let calProps = this.props.calProps;
		let monthName = DAYS_PER_MONTH[this.props.currentMonth].name;
		let monthPxStart = getDateInt(
			new Date(this.props.currentYear, this.props.currentMonth, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			this.props.calendarView
		);
		let monthPxEnd =
			monthPxStart + calProps.blockWidth * this.props.daysOfMonth - 1;
		let leftMonthBuffer = 5;
		let monthWidth = getTextWidth(monthName, HEADER_FONT);
		if (
			calProps.xScroll >= monthPxStart &&
			this.props.xScroll <= monthPxEnd - (monthWidth + 12)
		) {
			leftMonthBuffer += calProps.xScroll - monthPxStart;
		} else if (
			calProps.xScroll >= monthPxStart &&
			this.props.xScroll <= monthPxEnd
		) {
			leftMonthBuffer =
				calProps.blockWidth * this.props.daysOfMonth - (monthWidth + 8);
		}
		return (
			<div
				ref={this.monthDiv}
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingLeft: leftMonthBuffer,
					paddingTop: 5,
					fontSize: 15,
					width: calProps.blockWidth * this.props.daysOfMonth,
					height: 35,
					color: '#252631',
					border: '1px solid #e8ecef',
					display: 'inline',
					float: 'left'
				}}
			>
				{monthName} {this.props.currentYear}
			</div>
		);
	}
}

class YearBlock extends React.Component {
	constructor(props) {
		super(props);
		this.yearDiv = React.createRef();
	}
	shouldComponentUpdate(nextProps) {
		let calProps = nextProps.calProps;
		let yearPxStart = getDateInt(
			new Date(nextProps.currentYear, 0, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			nextProps.calendarView
		);
		let yearPxEnd =
			yearPxStart + calProps.blockWidth * nextProps.daysOfYear - 1;
		return (
			nextProps.xScroll !== this.props.xScroll &&
			((calProps.xScroll >= yearPxStart &&
				calProps.xScroll <= yearPxEnd - calProps.blockWidth) ||
				this.yearDiv.current.style.paddingLeft !== '5px')
		);
	}
	render() {
		let calProps = this.props.calProps;
		let yearPxStart = getDateInt(
			new Date(this.props.currentYear, 0, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			this.props.calendarView
		);
		let yearPxEnd =
			yearPxStart + calProps.blockWidth * this.props.daysOfYear - 1;
		let leftYearBuffer = 5;
		if (
			calProps.xScroll >= yearPxStart &&
			calProps.xScroll <= yearPxEnd - calProps.blockWidth
		) {
			leftYearBuffer += calProps.xScroll - yearPxStart;
		} else if (
			calProps.xScroll >= yearPxStart &&
			calProps.xScroll <= yearPxEnd
		) {
			leftYearBuffer =
				calProps.blockWidth * this.props.daysOfYear - (calProps.blockWidth + 1);
		}
		return (
			<div
				ref={this.yearDiv}
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingLeft: leftYearBuffer,
					paddingTop: 10,
					fontSize: 15,
					width: calProps.blockWidth * this.props.daysOfYear,
					height: 50,
					color: '#252631',
					borderLeft: '1px solid #e8ecef',
					borderRight: '1px solid #e8ecef',
					borderBottom: '1px solid #e8ecef',
					display: 'inline',
					float: 'left'
				}}
			>
				{this.props.currentYear}
			</div>
		);
	}
}

function renderMonthDays(dayArray, calProps) {
	return dayArray.map(dayObj => {
		return (
			<div
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingTop: Math.round(calProps.blockHeight / (50 / 10)),
					fontSize: Math.round(calProps.blockWidth / (50 / 15)),
					width: calProps.blockWidth,
					color: '#252631',
					border: '1px solid #e8ecef',
					textAlign: 'center',
					display: 'inline',
					float: 'left'
				}}
				key={
					dayObj.currentYear.toString() +
					'/' +
					(dayObj.currentMonth + 1).toString() +
					'/' +
					dayObj.currentDay.toString()
				}
			>
				{dayObj.weekDay}
				<br />
				{dayObj.currentDay}
			</div>
		);
	});
}
function renderMonths(monthArray, calProps) {
	return monthArray.map(monthObj => {
		return (
			<MonthBlock
				key={
					monthObj.currentYear.toString() +
					'/' +
					(monthObj.currentMonth + 1).toString()
				}
				daysOfMonth={monthObj.daysOfMonth}
				currentYear={monthObj.currentYear}
				currentMonth={monthObj.currentMonth}
				calendarView={calProps.calendarView}
				xScroll={calProps.xScroll}
				earliestDate={calProps.earliestDate}
				calProps={calProps}
			/>
		);
	});
}
function renderYears(yearArray, calProps) {
	return yearArray.map(currentYear => {
		return (
			<YearBlock
				key={currentYear}
				currentYear={currentYear}
				daysOfYear={getDaysPerYear(currentYear)}
				calendarView={calProps.calendarView}
				xScroll={calProps.xScroll}
				calProps={calProps}
			/>
		);
	});
}

//Returns [headerData, calWidth]
function generateHeaders(earliestDate, blockWidth) {
	let currentYear = 0;
	let yearStart = earliestDate.getFullYear();
	let currentWeekDay = earliestDate.getDay();
	let yearEnd = earliestDate.getFullYear() + 2;
	let yearList = [];
	let monthList = [];
	let dayList = [];
	for (
		let yearIncrement = 0;
		yearIncrement + yearStart <= yearEnd;
		yearIncrement++
	) {
		currentYear = yearStart + yearIncrement;
		for (let currentMonth = 0; currentMonth < 12; currentMonth++) {
			let daysOfMonth = DAYS_PER_MONTH[currentMonth].days(currentYear);
			for (let currentDay = 1; currentDay <= daysOfMonth; currentDay++) {
				let weekDay = DAYS_OF_WEEK[currentWeekDay++];
				dayList.push({
					currentYear: currentYear,
					currentMonth: currentMonth,
					currentDay: currentDay,
					weekDay: weekDay
				});
				if (currentWeekDay > 6) currentWeekDay = 0;
			}

			monthList.push({
				currentYear: currentYear,
				currentMonth: currentMonth,
				daysOfMonth: daysOfMonth
			});
		}

		yearList.push(currentYear);
	}
	return [[yearList, monthList, dayList], dayList.length * blockWidth];
}

class CalendarHeader extends React.Component {
	constructor(props) {
		super(props);
		let headerData = [];
		let calWidth;

		if (this.props.calendarView === 'month') {
			let generatedHeader = generateHeaders(
				this.props.earliestDate,
				this.props.blockWidth
			);
			headerData = generatedHeader[0];
			calWidth = generatedHeader[1];
		} else if (this.props.calendarView === 'week') {
			headerData = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'].map(
				day => {
					return (
						<div
							draggable={false}
							style={{
								WebkitUserSelect: 'none',
								msUserSelect: 'none',
								userSelect: 'none',
								paddingTop: Math.round(this.props.blockHeight / (50 / 10)),
								fontSize: Math.round(this.props.blockWidth / (50 / 15)),
								width: this.props.blockWidth,
								color: '#252631',
								border: '1px solid #e8ecef',
								textAlign: 'center',
								display: 'inline',
								float: 'left'
							}}
							key={day}
						>
							{day}
						</div>
					);
				}
			);
			calWidth = 7 * this.props.blockWidth;
		} else if (this.props.calendarView === 'day') {
			calWidth = this.props.blockWidth;
		}

		this.state = {
			headerData: headerData,
			calWidth: calWidth
		};

		this.topScroll = React.createRef();
		this.calScroll = React.createRef();
		this.sideScroll = React.createRef();
	}

	componentDidMount() {
		if (
			this.topScroll.current !== null &&
			this.topScroll.current.scrollLeft !== this.props.xScroll
		) {
			this.topScroll.current.scrollLeft = this.props.xScroll;
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.topScroll.current !== null &&
			this.topScroll.current.scrollLeft !== this.props.xScroll
		) {
			this.topScroll.current.scrollLeft = this.props.xScroll;
			if (
				this.props.calendarView === 'month' &&
				this.props.earliestDate !== prevProps.earliestDate
			) {
				let generatedHeader = generateHeaders(
					this.props.earliestDate,
					this.props.blockWidth
				);
				this.setState({
					headerData: generatedHeader[0],
					calWidth: generatedHeader[1]
				});
			}
		}
	}

	render() {
		let headerData = [];
		if (this.props.calendarView === 'month') {
			headerData = [
				// renderYears(this.state.headerData[0], this.props),
				renderMonths(this.state.headerData[1], this.props),
				renderMonthDays(this.state.headerData[2], this.props)
			];
		} else if (this.props.calendarView === 'week') {
			headerData = [this.state.headerData];
		} else if (this.props.calendarView === 'day') {
		}
		return (
			<div
				draggable={false}
				style={{ cursor: 'default', maxWidth: this.state.calWidth }}
			>
				{this.state.headerData.length > 0 ? (
					<div
						draggable={false}
						style={{
							overflowX: 'hidden',
							border: '1px solid #e8ecef',
							backgroundColor: 'white',
							maxWidth: this.state.calWidth
						}}
						ref={this.topScroll}
					>
						{headerData.map((headerArray, headerIndex) => {
							return (
								<div
									key={headerIndex}
									draggable={false}
									style={{
										WebkitUserSelect: 'none',
										msUserSelect: 'none',
										userSelect: 'none',
										clear: 'right',
										float: 'left',
										width: this.state.calWidth
									}}
								>
									{headerArray}
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		);
	}
}

export default CalendarHeader;
