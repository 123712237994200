import React,{useState} from 'react';
import {Modal} from 'react-bootstrap';
import {Progress,Form,Input,Button,Radio,Card,Checkbox} from 'antd';
import {
    FooterNext,
    FooterBoth,
    FooterEnd,
    FooterBack,
    FooterClose
} from "../../Components/FooterButtons";
import LoadScreen from "../../Components/LoadScreen";
import validEmailRegex from "../../StringData/validEmailRegex" 
import axios from "axios";

import { Link } from 'react-router-dom';
const cancelToken = axios.CancelToken.source();


function CreateIntake(props){
    const [page,setPage] = useState(0)
    //const [owner,setOwner] = useState({type:"",fname:"",lname:"",email:""});
    //const [propDetails,setPropDetails] = useState({propertyName:"",addressLater:false, address:{number:"",street:"",city:"",province:"",postalCode:""}})
    const [propName,setPropName] = useState("");
    const [request_id,setRequestId] = useState(null);
    const [error,setError] = useState("");
    const submitIntakeRequest = () => {
      setPage(page+1);
      axios.post("/jobRequest/add/intakeRequest/property",{propertyName:propName})
      .then( (response) => {
        setRequestId(response.data.job_id);
        setPage(4);
      })
      .catch( (err) => {
        setPage(5);
        console.log(err)
        setError(JSON.stringify(err.message));
      })
    }


    let header, footer, body;
    switch (page) {
      case 0: {
        body= <SelectIntakeType goNext={() => setPage(page+1)} goSpsh={() => setPage(6)} />
        header = 'Select intake process'
        footer = (
          <FooterBack
          clickBack={props.openRequest}
          backName={'Cancel'}
          />
        )
        break;
      }
      case 1: {
        body = (
          <PropertyIntakeDetails propName={propName} setPropName ={setPropName} />
        )
        header = (
          "Property Details"
        )
        footer = (
          <FooterBoth
          clickNext={() => setPage(page + 1)}
          clickBack={() => setPage(page - 1)}
          backName={'Back'}
          nextDisabled={propName === ""}
          />
        )
        break;
      }
      case 2: {
        body = (
          <ConfirmIntakeRequest propName={propName} intakeType="Property Intake" />
        )
        header = (
          "Review Intake Request"
        )
        footer = (
          <FooterBoth
          clickNext={submitIntakeRequest}
          clickBack={() => setPage(page - 1)}
          backName={'Back'}
          nextName={'Submit Request'}
          nextDisabled={propName === ""}
          />
        )
        break;
      }
      case 3: {
        body = (
          <LoadScreen className='m-auto' />
        )
        header = "Submitting Request"
        break;
      }
      case 4: {
        body = (
          <RequestSubmitted propName={propName} request_id={request_id} onHide={props.openRequest}/>
        )
        header = (
          "Request Successfully Submitted"
        )
        footer = (
          <FooterClose
          onClick={props.openRequest}
          />
        )
        break;
      }
      case 5: {
        body = (
          <RequestError error={error} />
        )
        header = (
          "A problem has occurred"
        )
        footer = (
          <FooterClose
          onClick={props.openRequest}
          />
        )
        break;
      }
      case 6: {
        body = (
          <PropertyIntakeDetails propName={propName} setPropName ={setPropName} />
        )
        header = (
          "Upload Property Spreadsheet"
        )
        footer = (
          <FooterBoth
          clickNext={() => setPage(page + 1)}
          clickBack={() => setPage(0)}
          backName={'Back'}
          nextDisabled={propName === ""}
          />
        )
        break;
      }


        /* Legacy code
        case 0: {
          body = (
            <SelectOwner setOwner={setOwner} owner={owner} />
          );
          header = "Select the Owner";
          footer = (
            <FooterBoth
              clickNext={() => setPage(page + 1)}
              clickBack={props.backToSelect}
              backName={'Cancel'}
              nextDisabled={(owner.type === "" || owner.fname === "" || owner.lname === "" || !validEmailRegex.test(owner.email))}
            />
          );
          break;
        }
        case 1: {
          body = (
            <SelectPropDetails setPropDetails={setPropDetails} propDetails={propDetails} />
          );
          header = "Enter Property Details";
          footer = (
            <FooterBoth
              clickNext={() => setPage(page + 1)}
              clickBack={() => setPage(page - 1)}
              backName={'Back'}
              nextDisabled={false}
            />
          );
          break;
        }*/

    }

    return (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body height500Fixed pb-0">
            <div className="height95">{body}</div>
          </Modal.Body>
          <Modal.Footer id="requestFooter">
            <div style={{flex:'50'}}>
              <Progress percent={33*(page+1)} size="small" showInfo={false}/>
            </div>
            <div style={{flex:'50',textAlign:'end'}}>{footer}</div>
          </Modal.Footer>
          </>
      );
}
const RequestError = (props) => {
  return <div>{props.error}</div>
}
const RequestSubmitted = (props) => {
  return(
    <div>
    <div>Request Successfully created</div>
    <Link to={'/administrative/intake/'+props.request_id}><Button onClick={props.onHide}>Go to request</Button></Link>
  </div>
    )
}


const ConfirmIntakeRequest = (props) => {
  return(

    <div>
      <div>Intake Type: {props.intakeType}</div>
      <div>Property Name: {props.propName}</div>
    </div>
    )
}


const PropertyIntakeDetails = (props) => {
  const {propName,setPropName} = props;

  const handleNameChange = (e) => setPropName(e.target.value);

  return(
    <Form
    {...formLayout}
  >
      <Form.Item 
          label="Property Name"
          name="propertyName"
          onChange={handleNameChange}
        >
          <Input/>
      </Form.Item>
    </Form>
  )
}
const SelectIntakeType = (props) => {
  return( 
    <div>
      <Button type='primary' className='rqTypeBtns' onClick={props.goNext}> Single Property Intake</Button>
    </div>
  )
  //<Button type='primary' className='rqTypeBtns' onClick={props.goSpsh}> Multiple Property Intake (.xlsx and .csv supported)</Button>
}
const SelectPropDetails = (props) => {
  const {propDetails,setPropDetails} = props;
  const onCheckboxChange =(e) =>{setPropDetails({...propDetails, addressLater:!propDetails.addressLater})}
  const handleNameChange = (e) => setPropDetails({...propDetails, propertyName:e.target.value});
  const handleAddressChange = (e) => setPropDetails({...setPropDetails, address:{ ...propDetails.address, [e.target.id]:e.target.value}});


  return(

    <Form
      {...formLayout}
    >
        <Form.Item 
            label="Property Name"
            name="propertyName"
            onChange={handleNameChange}
          >
            <Input/>
        </Form.Item>

        <h6>Property Address</h6>
        <Form.Item {...formTailLayout}>
        <Checkbox checked={propDetails.addressLater} onChange={onCheckboxChange}>
          Enter Address Later
        </Checkbox>
      </Form.Item>
        
        {propDetails.addressLater ? "" :
        <>
          <Input.Group>
            <Form.Item 
                label="Number"
                name="number"
                onChange={handleAddressChange}
                >
                <Input/>
            </Form.Item>
            <Form.Item 
                label="Street"
                name="street"
                onChange={handleAddressChange}
                >
                <Input/>
            </Form.Item>
          </Input.Group>
          <Input.Group>
            <Form.Item 
                label="City"
                name="city"
                onChange={handleAddressChange}
                >
                <Input/>
            </Form.Item> 
            <Form.Item 
                label="Province"
                name="province"
                onChange={handleAddressChange}
                >
                <Input/>
            </Form.Item> 
            <Form.Item 
                label="Province"
                name="postalCode"
                onChange={handleAddressChange}
                >
                <Input/>
            </Form.Item> 
          </Input.Group>
        </>
  }
    </Form>

  )


}






/*const SelectOwner = (props) => {
  const {owner,setOwner} = props;

  const changeType = (e) => {
    console.log(e.target.value);
    setOwner({type:e.target.value,fname:"",lname:"",email:""});
  
  }
  const handleChange = (e) => setOwner({...owner,[e.target.id]:e.target.value});

  return(
    <div>
      <Radio.Group style={{width:"100%"}} value={owner.type} onChange={changeType}>
        <Radio.Button style={{width:"50%"}} value="Existing">Existing Owner</Radio.Button>
        <Radio.Button style={{width:"50%"}} value="New">New Owner</Radio.Button>
      </Radio.Group>
      <br/><br/>
      {owner.type == 'Existing' ?

        <SelectExistingOwner owner={owner} setOwner={setOwner} />

        :
        owner.type === 'New' ?
        <Form
          {...formLayout}
        >
          <Form.Item 
            label="First Name"
            name="fname"
            onChange={handleChange}
          >
            <Input/>
          </Form.Item>
          <Form.Item 
            label="Last Name"
            name="lname"
            onChange={handleChange}
          >
            <Input/>
          </Form.Item>
          <Form.Item 
            label="Email"
            name="email"
            onChange={handleChange}
            >
            <Input/>
          </Form.Item>
        </Form>
      : "" }
    </div>
  )
}*/
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};


export default CreateIntake;