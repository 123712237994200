import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./settings.css";
import axios from "axios";

import { Card, Avatar, Button ,message} from "antd";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import {useUserState} from '../../Context/UserContext';
import {createUseStyles} from 'react-jss'
import AddNewUser from './AddNewUser'
const cancelToken = axios.CancelToken.source();

function SettingsPage() {
  const {user} = useUserState();
  return (
    <div>
      <div id="settingsLayout">
        <UpdatePassword />
        {user.id == 57 ? <AddNewUser /> : ""}
      </div>
    </div>
  );
}



const UpdatePassword = (props) => {
  const [errorMessage, setError] = useState("");
  const [oldp, setOldP] = useState("");
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");

  const changePassword = () => {
    if (p1 == p2) {
      axios
        .post("/updatepassword", {
          p1: p1,
          p2: p2,
          oldp: oldp,
        })
        .then(function (response) {
          if (response.data.ok) {
            setP1("");setP2("");setOldP("")
            message.success(
              "Password updated successfully."
            );  
          } else {
            setError(
              "The email or password entered is incorrect. Please try again."
            );
          }
        })
        .catch((error) => {
          console.log("error fetching data");
          console.log(error);
          console.log(error.message); // Server can't be reached!
          setError(
            "Error connecting to the server. Please refresh or try again later."
          );
          
        });
    } else {
      setError("Inputs does not match above ");
    }
  };
  const clearform = () =>{
    setP1("");setP2("");setOldP("")
  }
  return(
    <div className="cardShell">
      <h5 className="text-center">Update Password</h5>
      <Form.Group>
        <Form.Label>Old Password</Form.Label>
        <Form.Control
          size="lg"
          type="password"
          value={oldp}
          placeholder="Enter your old password"
          onChange={(e) => setOldP(e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>New Password</Form.Label>
        <Form.Control
          size="lg"
          type="password"
          value={p1}
          placeholder="Enter your new password"
          onChange={(e) => setP1(e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          size="lg"
          type="password"
          value={p2}
          placeholder="Enter your new password"
          onChange={(e) => setP2(e.target.value)}
        />
      </Form.Group>
      <Form.Group>
      <Button   onClick={clearform}>
        Cancel
      </Button>
      <Button type="primary"  onClick={changePassword}>
        Update
      </Button>
      </Form.Group>
      <ErrorMessage otherClass="mt-2">{errorMessage}</ErrorMessage>
    </div>
  )
}
/*
 <Layout id='settingsLayout'>
        <Sider theme='light'>
          <div className='g-text layoutSiderHeader'>Settings</div>
          <Menu>
            <Menu.Item>My Account</Menu.Item>
            <Menu.Item>Notifications</Menu.Item>
          </Menu>
        </Sider>
        <Content></Content>
      </Layout>
*/
export { SettingsPage };
