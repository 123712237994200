import React from 'react';
import useScrollbarSize from 'react-scrollbar-size';

const scrollbarSizeHook = (Component: any) => {
  return (props: any) => {
    const {height, width} = useScrollbarSize();

    return <Component scrollHeight={height} scrollWidth={width} {...props} />;
  };
};

export default scrollbarSizeHook;