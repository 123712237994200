import { Table,Card } from 'antd';
import axios from "axios";

import React,{useContext, useState} from 'react';
import LoadScreen from '../../Components/LoadScreen';
import RequestContext from '../MaintenanceRequest/RequestContext';
import Moment from 'react-moment'
import PropertyListing from '../Properties/PropertyListing'
const cancelToken = axios.CancelToken.source();
function RequestPropertyIntakeData(){
    const {propertyid,tasks} = useContext(RequestContext);
    
    return(
        <>
            {tasks.addressIntake === 1 && <PropertyAddressData propertyid={propertyid}/>}
            {tasks.ownerIntake === 1 && <OwnerData propertyid={propertyid} />}
            {tasks.tenantIntake === 1 && <TenantData propertyid={propertyid}/>}
            {tasks.leaseIntake === 1 && <LeaseData propertyid={propertyid}/>}
        </>
    )
}


function PropertyAddressData(props){
    const [address,setAddress] = useState(undefined);
    const getAddress = () => {
        axios.post("/intake/property/get/address",{propertyid:props.propertyid}).then((response) => {
            setAddress(response.data.address);
        })
        .catch(err => setAddress(null))
    }

    if(address === undefined){
        getAddress();
    }
    return(
        <Card bordered={false} title={<div className=" fs-18 g-text">Property</div>}>
            {address === undefined && 
                <div className='text-center'><LoadScreen small /><div style={{color:"rgba(0, 0, 0, 0.25)",fontSize:'12px'}}>Loading Address...</div></div> 
            }
            {address === null && <div style={{color:"rgba(0, 0, 0, 0.25)",fontSize:'12px',textAlign:'center'}} >Cannot load property details</div>}

            {address?.propertyid &&
                <PropertyListing 
                    address={
                        address.propertynumber +
                        " " +
                        address.propertyStreet +
                        ", " +
                        address.propertyCity +
                        ", " +
                        address.propertyProvince +
                        " " +
                        address.propertyPostalcode
                    }
                    units={address.total_units}
                    type={address.propertyType}
                    id={address.propertyid}
                    link
                    /> 
            }
        </Card>
    )
}

function OwnerData(props){
    const [owners,setOwners] = useState(null);
    const getOwners = () => {
        axios.post("/intake/property/get/owners",{propertyid:props.propertyid})
        .then((response) => {
            setOwners(response.data.owners);
        })
        .catch(err => setOwners([]));
    }

    if(owners == null){
        getOwners();
    }
    return(
        <Card bordered={false} title={<div className=" fs-18 g-text">Owners</div>}>

            <Table 
                columns={ownerCols} 
                dataSource={owners} size='small' 
                locale={{emptyText: owners === null ? <div className='text-center'><LoadScreen small /><div>Loading Owners...</div></div> :"No Owners"}} 
                pagination={false} 
            />
            </Card>
    )
    
}

const ownerCols = [
    {
        title: "Owner Name",
        dataIndex: "fname",
        render:(name,data) => `${data.fname} ${data.lname ? data.lname : ""}`,  
        width: '30%',
      },
      {
        title: "Owner Email",
        dataIndex: "email",
        width: '50%',
      },
      {
        title: "Account Activated",
        dataIndex: "useraccountstat",
        render:(stat) => stat === 1 ? "Yes" : "No",
        width: '20%',
      },
]


function TenantData(props){
    const [tenants,setTenants] = useState(null);
    const getTenants = () => {
        axios.post("/intake/property/get/tenants",{propertyid:props.propertyid})
        .then((response) => {
            setTenants(response.data.tenants);
        })
        .catch(err => setTenants([]));
    }

    if(tenants == null){
        getTenants();
    }
    return(
        <Card bordered={false} title={<div className=" fs-18 g-text">Tenants</div>}>

            <Table 
                columns={tenantCols} 
                dataSource={tenants} size='small' 
                locale={{emptyText: tenants === null ? <div className='text-center'><LoadScreen small /><div>Loading tenants...</div></div> :"No tenants"}} 
                pagination={false} 
            />
            </Card>
    )
    
}

const tenantCols = [
    {
        title: "Tenant Name",
        dataIndex: "fname",
        render:(name,data) => `${data.fname} ${data.lname ? data.lname : ""}`,  
        width: '30%',
      },
      {
        title: "Tenant Email",
        dataIndex: "email",
        width: '35%',
      },
      {
        title: "Lease ID",
        dataIndex: "lease_id",
        width: '15%',
      },
      {
        title: "Account Activated",
        dataIndex: "useraccountstat",
        render:(stat) => stat === 1 ? "Yes" : "No",
        width: '20%',
      },
]

function LeaseData(props){
    const [leases,setLeases] = useState(null);
    const getLeases = () => {
        axios.post("/intake/property/get/leases",{propertyid:props.propertyid})
        .then((response) => {
            setLeases(response.data.leases);
        })
        .catch(err => setLeases([]));
    }

    if(leases == null){
        getLeases();
    }
    return(
        <Card bordered={false} title={<div className=" fs-18 g-text">Leases</div>}>

            <Table 
                columns={leaseCols} 
                dataSource={leases} size='small' 
                locale={{emptyText: leases === null ? <div className='text-center'><LoadScreen small /><div>Loading leases...</div></div> :"No leases"}} 
                pagination={false} 
            />
            </Card>
    )
}

const leaseCols = [
    {
    title: "Lease ID",
    dataIndex: "lease_id",
    width: '9%',
    },
    {
    title: "Lease Type",
    dataIndex: "lease_type",
    width: '20%',
    },
    {
    title: "Rent",
    dataIndex: "rent",
    width: '10%',
    },
    {
    title: "Deposit",
    dataIndex: "deposit",
    width: '10%',
    },
    {
    title: "Move in Date",
    dataIndex: "movein_date",
    render:(start) => <Moment format="MM/DD/YYYY">{start}</Moment>,
    width: '17%',
    },
    {
    title: "Start Date",
    dataIndex: "start_date",
    render:(start) => <Moment format="MM/DD/YYYY">{start}</Moment>,
    width: '17%',
    },
    {
    title: "End Date",
    dataIndex: "end_date",
    render:(end) => <Moment format="MM/DD/YYYY">{end}</Moment>,
    width: '17%',
    },
]

export default RequestPropertyIntakeData