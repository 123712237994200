import React from 'react';
import {Steps} from 'antd'
const { Step } = Steps;
export function StepProgress({steps,current,classOption}){
  return(
    <Steps current={current} className={classOption}>
      {Array(steps).fill(<Step />)}
    </Steps>

  )
}
