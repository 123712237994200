const statusColors = [
  { value: "Job Request", color: "status-orange" },
  { value: "Scheduling", color: "status-yellow" },
  { value: "Work Order - Scheduled", color: "status-lightBlue" },
  { value: "Work Order - Troubleshoot", color: "status-blue" },
  { value: "Work Order - Work Done", color: "status-lightGreen" },
  { value: "Work Order - Review", color: "status-darkBlue" },
  { value: "Invoicing", color: "status-green" },
  { value: "open", color: "status-yellow" },
  { value: "accept", color: "status-green" },
  { value: "Rejected", color: "status-darkGrey" },
  { value: "Cancelled", color: "status-darkGrey" },
  { value: "Complete", color: "status-lightGrey" },
  { value: "On Hold", color: "status-grey" },
  { value: "In Market", color: "status-darkRed" },
];

export default statusColors;
