import React,{useState} from 'react';
import './WorkOrderDisplay.css';
import axios from "axios";
import SessionDisplay from './Session/Session';
import Transactions from './Transactions/Transactions';
import LoadScreen from "../../../../../Components/LoadScreen";
import CompleteDisplay from './CompleteDisplay/CompleteDisplay'
import {Card,Table,Badge,Space,Dropdown,Menu,Tooltip,Divider,Carousel} from 'antd';
import {CloseOutlined,DownOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import moment from 'moment'
import RequestContext from '../../../RequestContext';
import useWindowDimensions from '../../../../../Context/WindowsDimensions';
const cancelToken = axios.CancelToken.source();
const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
);
class WorkOrderDisplay extends React.Component{
  static contextType = RequestContext;
  constructor(props){
    super();
    this.state = {
      sessions:[],
      complete:{workOrder:null,sessions:[],troubleshoots:[],materials:[],labour:[],transactions:[]},
      currentPage:'Display',
      sessionView:null,
    }
  }

  componentDidMount(){
    this.getJobData()

  }
  getSessionView=()=>{
    return(this.state.sessionView)
  }
  getJobData=()=>{
    let vendors=[];
    for(let j=0;j<this.context.request.vendors.length;j++){
      vendors.push(this.context.request.vendors[j])
    }
    this.setState({vendors:vendors})
  }

  viewSession = (id) => {
    let sess = this.props.activeEvent.workOrders.find(x => x.wo_id === id);
    if(sess !== null)
      this.setState({sessionView:sess,currentPage:'sessionView'});
  }
  goBack = () => {
    this.setState({currentPage:'Display'})
  }
  render() {
    let trans,borderline,torender;
    let back = <ArrowLeftOutlined onClick={this.goBack} className='icon' alt='back'/> ;
    if(this.props.activeEvent.workOrders.length === 0){
      torender = <LoadScreen className='m-auto' small/>
    }
    else{  
      if(this.state.complete.transactions.length >  0){
        trans = <Transactions transactions={this.state.complete.transactions} />;
        borderline= <div className='borderLine'></div>;
      }
     if(this.state.currentPage == 'Display'){
        
        /*torender = <div>         
          {trans}
          {borderline}
          <CompleteDisplay complete={this.state.complete} />
          <div className='text-center'><b>Individual Sessions</b></div>*/
         // torender = <div> <SessionTable workOrder={this.props.workOrder} getSessionView={this.getSessionView} session={this.state.sessionView} vendors={this.props.vendors} viewSession={this.viewSession} sessionView={this.state.sess} /> </div>
         torender=<div></div>
      }
      else{
        torender = 
        <div>
          <SessionDisplay backButton={back} session={this.state.sessionView} />
        </div>
        
      }
      
     
    }
    
      return (
        <>
        {trans}
        <Card
        title={<div className=" fs-18 g-text">Work Order Report</div>}
        bordered={false}

      >
        {back}
        {torender}
      </Card>
        </>
    )
  }
}

class SessionTable extends React.Component{
  viewSession = (e) => this.props.viewSession(e)
  sessionView=()=>{this.props.getSessionView()}
  render(){
    let rows = [];
    for(let i=0;i<this.props.workOrder.length;i++){
      rows.push({key:this.props.workOrder[i].wo_id,vendor_name:this.props.vendors[0].name,timeIn:this.props.workOrder[i].time_in ? moment(this.props.workOrder[i].time_in).format('ddd MMM D, h:mma') : "-",authType:this.props.workOrder[i].entry_required==0?"No Entry":"Entry",tenant:this.props.workOrder[i].tenant_name || "-",timeOut:this.props.workOrder[i].time_out ? moment(this.props.workOrder[i].time_out).format('ddd MMM D, h:mma') : "-",totalKM:this.props.workOrder[i].total_km || "-"})
    }
    return(

      <Table
      className="components-table-demo-nested" 
      columns={sessionCols}
      dataSource={rows}
      rowKey={"key"}
      size='small'
     /*
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{this.viewSession(record.key),this.sessionView(),
        <div>
          <Divider orientation="left" >Troubleshooting</Divider>
          <div>
            <div className='JRSubtitle'>
            Description <br></br>
              
            </div>
            <div className=''>
                {this.props.sessionView.causes}
              </div>
            <div className='JRSubtitle'>
              Documents <br></br>
              
            </div>
            <div id='requestImages' className='mt-2'>
            <ImageDisplay images={this.props.sessionView.uploads}/>
            </div>
            <div className='JRSubtitle'>
              Estimate Expenses <br></br>
              
            </div>
            <div>
            </div>
        </div>
        {
          <Table 
            columns={columns}
            dataSource={this.props.sessionView.estimations}
          >
          </Table>
        }
        </div>,<TroubleshootDisplay troubleshoot={this.props.session}/>}</p>,
        rowExpandable: record => record.name !== 'Not Expandable',
      }}
    */
      pagination={false}
      
      onRow={(record) => {
        return {
          onClick: () => this.viewSession(record.key)// click row
        }
      }}
      />

    )
  }

}
class TroubleshootDisplay extends React.Component {
  render(){
    return (
      <div >
        <Divider orientation="left" >Troubleshooting</Divider>
        <div>
          <div className='JRSubtitle'>
           Description <br></br>

            
          </div>
          <div className=''>
              {this.props.troubleshoot.causes}
            </div>
          <div className='JRSubtitle'>
            Documents <br></br>
            
          </div>
          <div id='requestImages' className='mt-2'>
          <ImageDisplay images={this.props.troubleshoot.uploads}/>
          </div>
          <div className='JRSubtitle'>
            Estimate Expenses <br></br>
            
          </div>
          <div>


          </div>


        </div>
        {
          <Table 
            columns={columns}
            dataSource={this.props.troubleshoot.estimations}
          >

          </Table>
        }
      </div>
    )
  }
}
const ImageDisplay = ({ images }) => {
  const { width } = useWindowDimensions();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const  download = ( url) => {
    //axios.get(url).then()
  }
  if (!Array.isArray(images)) return <></>;
  var slidesPer;
  if (width > 1300) slidesPer = 4;
  else if (width < 1300 && width > 1130) slidesPer = 3;
  else if (width < 1130 && width > 760) slidesPer = 2;
  else slidesPer = 1;
  const imgdownload = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };

  return (
    images.length > 0 ?
    (<Carousel
      arrows
      slidesPerRow={slidesPer}
      className="maintenanceCarousel"
      prevArrow={
        <img src={require("../../../../../icons/chevronLeft.png")} alt="left" />
      }
      nextArrow={
        <img src={require("../../../../../icons/chevronRight.png")} alt="right" />
      }
    >
      {images.map((i, index) => (
        <div className="imageTab" key={i.id}>
          <a href = {i.url} target='_blank' type='application/pdf' download>
            <Tooltip title={i.event_id}>
              {i.type == 'image' && <img 
                  className="d-block image carouselImages"
                  src={require('../../../../../images/pdfIcon.PNG')}//i.url
                  alt="First slide" style={{width: 50, height: 50}}
                />}
              {i.type == 'pdf' && <img 
                  className="d-block image carouselImages"
                  src={require('../../../../../images/pdfIcon.PNG')}
                  alt="First slide" style={{width: 50, height: 50}}
                />} 
            </Tooltip>
          </a>
        </div>
      ))}
    </Carousel>) : 
    ""
  );
};
const expandedRowRender=(sessionView)=>{
  const columns=[
    {
      title:"Description",
      dataIndex:"description"
    }
  ]
  return <Table columns={columns} dataSource={sessionView} pagination={false} />;
}
const columns = [
  {
    title:"Description",
    dataIndex:'item'

  },
  {
    title:"Quantity",
    dataIndex:'quantity'
  },
  {
    title:"Vendor",
    dataIndex:'vendor'
  },
  {
    title:"Price",
    dataIndex:'est_price'
  }
];

const sessionCols = [
  {
    title:'Vendor',
    dataIndex:'vendor_name',
    width:'16%',
  },
  {
    title:'Time In',
    dataIndex:'timeIn',
    width:'16%',
  },
  {
    title:'Authorization Type',
    dataIndex:'authType',
    width:'16%',
  },
  {
    title:'Total Kilometers',
    dataIndex:'totalKM',
    width:'16%',
  },
  {
    title:'Time Out',
    dataIndex:'timeOut',
    width:'16%',
  }
]



export default WorkOrderDisplay;