import React from 'react';
import axios from 'axios';

import LoadScreen from '../../Components/LoadScreen';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import { Link } from 'react-router-dom';
import { Card, Avatar, Button, Modal } from 'antd';
import { property } from '../Agreements/Agreements';
import { leaseType } from '../Agreements/Agreements';

import AddVendor from './AddVendor';

import Moment from 'react-moment';

import { UserStateContext } from '../../Context/UserContext';
import AddNewTenant from './AddNewTenant';
import AddNewOwner from './AddNewOwner';
const cancelToken = axios.CancelToken.source();
class People extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			selectedList: 'Tenant',
			tenantList: null,
			ownerList: null,
			vendorList: null,
			searchValue: '',
			addvendor: false,
			addOwner: false,
			addTenant: false
		};
	}

	setError = () => {
		return <div>err</div>;
	};
	getTenantList = () => {
		axios.get('/getTenants').then(response => {
			this.setState({ tenantList: response.data.tenants });
		});
	};

	getOwnerList = () => {
		axios
			.get('/getOwners')
			.then(response => {
				this.setState({ ownerList: response.data.owners });
			})
			.catch(err => {
				console.log(err);
			});
	};
	getVendorList = () => {
		axios.get('/getVendors').then(response => {
			this.setState({ vendorList: response.data.vendors });
		});
	};
	setSearch = value => this.setState({ searchValue: value });

	openaddVendor = () => {
		this.setState({ addvendor: !this.state.addvendor });
	};
	openAddOwner = () => {
		this.setState({ addOwner: !this.state.addOwner });
	};
	openAddTenant = () => {
		this.setState({ addTenant: !this.state.addTenant });
	};

	getalert = () => {
		window.alert();
	};

	componentDidMount = () => {
		this.getTenantList();
		this.getOwnerList();
		this.getVendorList();
	};

	render() {
		document.title = this.state.selectedList + 's';
		let torender;
		if (this.state.selectedList === 'Tenant') {
			if (this.state.tenantList === null) {
				this.getTenantList();
				torender = <LoadScreen className='m-auto' />;
			} else if (this.state.tenantList === []) {
				torender = <div>No tenants</div>;
			}
		} else if (this.state.selectedList === 'Owner') {
			if (this.state.ownerList === null) {
				this.getOwnerList();
				torender = <LoadScreen className='m-auto' />;
			} else if (this.state.ownerList === []) {
				torender = <div>No Owners</div>;
			}
		} else if (this.state.selectedList === 'Vendor') {
			if (this.state.vendorList === null) {
				this.getVendorList();
				torender = <LoadScreen className='m-auto' />;
			} else if (this.state.vendorList === []) {
				torender = <div>No Vendors</div>;
			}
		}

		return (
			<div className=''>
				<div className='pt-4' id=''>
					<div className='text-center pb-2'>
						<Button
							onClick={() => this.setState({ selectedList: 'Tenant' })}
							className={
								'mr-2 ' +
								(this.state.selectedList === 'Tenant' ? 'selected' : '')
							}
						>
							Tenants
						</Button>
						<Button
							onClick={() => this.setState({ selectedList: 'Owner' })}
							className={
								'mr-2 ' +
								(this.state.selectedList === 'Owner' ? 'selected' : '')
							}
						>
							Owners
						</Button>
						<Button
							onClick={() => this.setState({ selectedList: 'Vendor' })}
							className={this.state.selectedList === 'Vendor' ? 'selected' : ''}
						>
							Service Providers
						</Button>
					</div>

					<div id='searchBarContainer' className='pt-3'>
						<div>
							<Searchbar
								onChange={e => this.setSearch(e.target.value)}
								value={this.state.searchValue}
								className='propSearchbar mb-3'
							/>
						</div>
						<div>
							{this.state.selectedList == 'Vendor' ? (
								<Button
									type='primary'
									// className='addvender-btn btnLabel'
									onClick={this.openaddVendor}
								>
									Add New Vendor
								</Button>
							) : (
								''
							)}
						</div>
					</div>

					{torender !== undefined ? (
						torender
					) : (
						<Listing
							selectedList={this.state.selectedList}
							tenantList={this.state.tenantList}
							ownerList={this.state.ownerList}
							vendorList={this.state.vendorList}
							searchValue={this.state.searchValue}
							currentUser={this.context.user}
							getalert={this.getalert}
							openaddVendor={this.openaddVendor}
							openAddOwner={this.openAddOwner}
							openAddTenant={this.openAddTenant}
						/>
					)}
				</div>
				<br />
				<Modal
					destroyOnClose={true}
					title='Add New Vendor'
					visible={this.state.addvendor}
					onCancel={this.openaddVendor}
					width='800px'
					footer={false}
				>
					{this.state.addvendor ? (
						<AddVendor onCancel={this.openaddVendor} />
					) : (
						''
					)}
				</Modal>
			</div>
		);
	}
}

/*The component that renders the listing cards for each list */
const Listing = ({
	selectedList,
	tenantList,
	ownerList,
	vendorList,
	searchValue,
	currentUser,
	getalert,
	openaddVendor,
	openAddOwner,
	openAddTenant
}) => {
	var ifOwner = currentUser.type === 'Owner' ? true : false;
	var ownerProperties = ownerList
		? ownerList.filter(person => currentUser.id === person.PO_id)
		: [];

	return (
		<div id='listContainer'>
			{selectedList === 'Tenant' ? (
				<>
					{!ifOwner
						? tenantList
								.filter(person =>
									String(
										person.tenant_fname +
											' ' +
											person.tenant_lname +
											' ' +
											person.tenant_email +
											' ' +
											person.lease_id +
											' ' +
											property(
												person.propertynumber,
												person.propertyStreet,
												person.unit_name
											) +
											' ' +
											person.lease_type
									)
										.toLowerCase()
										.includes(searchValue.toLowerCase())
								)
								.sort((a, b) => a.tenant_fname.localeCompare(b.tenant_fname))
								.map((person, tenantIndex) => (
									<TenantListing key={tenantIndex} tenant={person} />
								))
						: tenantList
								.filter(
									person =>
										ownerProperties.find(
											property => person.propertyid === property.propertyid
										) &&
										String(
											person.tenant_fname +
												' ' +
												person.tenant_lname +
												' ' +
												person.tenant_email +
												' ' +
												person.lease_id +
												' ' +
												property(
													person.propertynumber,
													person.propertyStreet,
													person.unit_name
												) +
												' ' +
												person.lease_type
										)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
								)
								.sort((a, b) => a.tenant_fname.localeCompare(b.tenant_fname))
								.map((person, tenantIndex) => (
									<TenantListing key={tenantIndex} tenant={person} />
								))}
				</>
			) : selectedList === 'Owner' ? (
				<>
					{!ifOwner
						? ownerList
								.filter(person =>
									String(
										person.PO_fname +
											' ' +
											person.PO_lname +
											' ' +
											person.PO_email +
											' ' +
											person.propertyNumber +
											' ' +
											person.propertyStreet
									)
										.toLowerCase()
										.includes(searchValue.toLowerCase())
								)
								.sort((a, b) => a.PO_fname.localeCompare(b.PO_fname))
								.map((person, ownerIndex) => (
									<OwnerListing key={ownerIndex} person={person} />
								))
						: ownerList
								.filter(
									person =>
										currentUser.id === person.PO_id &&
										String(
											person.PO_fname +
												' ' +
												person.PO_lname +
												' ' +
												person.PO_email +
												' ' +
												person.propertyNumber +
												' ' +
												person.propertyStreet
										)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
								)
								.sort((a, b) => a.PO_fname.localeCompare(b.PO_fname))
								.map((person, ownerIndex) => (
									<OwnerListing key={ownerIndex} person={person} />
								))}
				</>
			) : (
				<>
					{vendorList
						.filter(person =>
							String(
								person.fname +
									' ' +
									person.lname +
									' ' +
									person.email +
									' ' +
									person.lease_id
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase())
						)
						.sort((a, b) => a.fname.localeCompare(b.fname))
						.map((person, vendorIndex) => (
							<VendorListing key={vendorIndex} person={person} />
						))}
				</>
			)}
		</div>
	);
};

/* Card for vendors*/
export const VendorListing = ({ person }) => {
	return (
		<Link
			to={{
				pathname: '/user/profile',
				state: { id: person.vendorid }
			}}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname}
							</div>
							<div className='cardContent g-text-sm'></div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								{/* <b>Email: </b>
								{person.email} */}
								{/* <b> Address: </b> */}
								{person.vendorAddress}
							</div>
							<div className='cardFooterItem ml-4'></div>
						</div>
					</div>
				</div>

				<div className='cardRight mobileRow'>
					<div className='cardRightHeader caption'>{person.vendorService}</div>
					<div className='cardRightFooter g-text-sm '></div>
				</div>
			</Card>
		</Link>
	);
};

/* Card for owners*/
const OwnerListing = ({ person }) => {
	return (
		<Link to={{ pathname: '/user/profile', state: { id: person.PO_id } }}>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.PO_fname} {person.PO_lname}
							</div>
							<div className='cardContent g-text-sm'></div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>{person.PO_email}</div>
							<div className='cardFooterItem ml-4'></div>
						</div>
					</div>
				</div>

				<div className='cardRight mobileRow'>
					<div className='cardRightHeader caption'>
						{property(person.propertynumber, person.propertyStreet, null)}
					</div>
					<div className='cardRightFooter g-text-sm '></div>
				</div>
			</Card>
		</Link>
	);
};

/* Card for tenants */
const TenantListing = ({ tenant }) => {
	return (
		<Link
			to={{
				pathname: '/user/profile',
				state: { id: tenant.tenant_id }
			}}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{tenant.tenant_fname} {tenant.tenant_lname}
							</div>
							<div className='cardContent g-text-sm'>
								<div className='d-inline'>Lease Period:&nbsp;</div>
								<Moment format='DD/MM/YY'>{tenant.lease_start}</Moment>
								{tenant.lease_end ? (
									<Moment format=' - DD/MM/YY'>{tenant.lease_end}</Moment>
								) : (
									' - Present'
								)}
							</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								{property(
									tenant.propertynumber,
									tenant.propertyStreet,
									tenant.unit_name
								)}
							</div>
							<div className='cardFooterItem ml-4'></div>
						</div>
					</div>
				</div>

				<div className='cardRight mobileRow'>
					<div className='cardRightHeader caption'>
						{leaseType(tenant.lease_type)}
					</div>
					<div className='cardRightFooter g-text-sm '>
						<div className='b-text d-inline'>${tenant.rent}&nbsp;</div>
						per month
					</div>
				</div>
			</Card>
		</Link>
	);
};
export default People;
