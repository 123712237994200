import React from "react";
import axios from "axios";

import TaskDescription from "../TaskDescription";
import { Empty, Form, Button, Upload, InputNumber, List, Table,Popover, Result, Checkbox } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
const cancelToken = axios.CancelToken.source();
const intakePlural = {
  "property": "properties",
  "owner": "owners",
  "tenant": "tenants"
};

function firstUpper(string) {
  return string.substr(0,1).toUpperCase().concat(string.substr(1));
}

function flipArray(array) {
  let newArray = [];

  let arrayLength = 0;
  for (let xIndex = 0; xIndex < array.length; xIndex++) {
    if (array[xIndex] !== null) {
      arrayLength = array[xIndex].length;
      break;
    }
  }
  for (let yIndex = 1; yIndex < arrayLength; yIndex++) {
    newArray.push([]);
  }

  for (let xIndex = 0; xIndex < array.length; xIndex++) {
    let col = array[xIndex];
    if (col !== null)
      for (let yIndex = 1; yIndex < array[xIndex].length; yIndex++) {
        newArray[yIndex-1].push(col[yIndex]);
      }
    else {
      for (let yIndex = 1; yIndex < arrayLength; yIndex++) {
        newArray[yIndex-1].push(null);
      }
    }
  }
  return newArray;
}

// UPLOAD SPREADSHEET
class UploadArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      sheet: typeof this.props.saveData !== "undefined" ?
        typeof this.props.saveData[this.props.intakeType + "SheetNum"] !== "undefined" ?
        this.props.saveData[this.props.intakeType + "SheetNum"] : 1 : 1,
      row: typeof this.props.saveData !== "undefined" ?
        typeof this.props.saveData[this.props.intakeType + "RowNum"] !== "undefined" ?
        this.props.saveData[this.props.intakeType + "RowNum"] : 1 : 1,
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.sheetChange = this.sheetChange.bind(this);
    this.rowChange = this.rowChange.bind(this);
  }

  componentDidMount(){
    this.setState({
      sheet: typeof this.props.saveData !== "undefined" ? typeof this.props.saveData[this.props.intakeType + "SheetNum"] !== "undefined" ? this.props.saveData[this.props.intakeType + "SheetNum"] : 1 : 1,
      row: typeof this.props.saveData !== "undefined" ? typeof this.props.saveData[this.props.intakeType + "RowNum"] !== "undefined" ? this.props.saveData[this.props.intakeType + "RowNum"] : 1 : 1
    });
    if (this.props.dataSheet <= 0) {
      axios({
        method: 'get',
        url: "/intake/LoadDatasheet/" + this.props.intakeType
      })
      .then((response) => {
        this.props.setDataSheet(response.data.data);
      });
    }
  }

  handleUpload(directFile, sheetNum, rowNum) {
    var form = new FormData();
    if (directFile === null)
      form.append("file", this.props.fileList[0]);
    else
      form.append("file", directFile);
    var url = "/intake/LoadSpreadsheet/" + this.props.intakeType + "/" + sheetNum + "/" + rowNum;
    axios.post(url, form, {
      headers: {
        "Content-type": "multipart/form-data"
      }
    })
    .then((response) => {
      if (response.data.data !== null && response.data.data.length > 0)
        this.props.updateUserSheet(response.data.data, true);
    });
  }
  sheetChange(sheetNum) {
    this.setState({sheet: sheetNum});
    this.props.updateSaveData({[this.props.intakeType + "SheetNum"]: sheetNum});
    if (this.props.fileList.length > 0)
      this.handleUpload(null, sheetNum, this.state.row);
  }
  rowChange(rowNum) {
    this.setState({row: rowNum});
    this.props.updateSaveData({[this.props.intakeType + "RowNum"]: rowNum});
    if (this.props.fileList.length > 0)
      this.handleUpload(null, this.state.sheet, rowNum);
  }
  render() {
    const uploadProps = {
      onRemove: file => {
      },
      beforeUpload: file => {
        this.props.updateFileList([file]);
        this.handleUpload(file, this.state.sheet, this.state.row);
        return false;
      },
      fileList: this.props.fileList,
      multiple: false
    };
    return(
      <Form>
        <Form.Item>
          <Upload {...uploadProps} disabled={this.props.ownerAndIs}>
            <Popover content={<p>Choose a &quot;.xlsx&quot; or &quot;.csv&quot; file to upload. Drag and Drop your columns onto ours.</p>} title={null} trigger="hover">
              <Button disabled={this.props.ownerAndIs}>
                <UploadOutlined/> Browse...
              </Button>
            </Popover>
          </Upload>
        </Form.Item>
        <Form.Item label={<Popover content={<p>Choose which sheet from an &quot;.xlsx&quot; file to use.</p>} title={null} trigger="hover">Sheet</Popover>}>
          <InputNumber min={1} defaultValue={this.state.sheet} onChange={this.sheetChange} disabled={this.props.ownerAndIs}/>
        </Form.Item>
        <Form.Item label={<Popover content={<p>Choose which row of the sheet is the header row.</p>} title={null} trigger="hover">Row</Popover>}>
          <InputNumber min={1} defaultValue={this.state.row} onChange={this.rowChange} disabled={this.props.ownerAndIs}/>
        </Form.Item>
      </Form>
    );
  }
}

function userHeaders(userSheet, handleMouseDown, showHeaderOption, hideHeaderOption, openOptions, splitNum,
  increaseSplitNum, decreaseSplitNum, commitSplitColumn, deleteExtraColumn) {
  let headerList = [];
  if (userSheet !== null && userSheet.length > 0) {
    for (let i = 0; i < userSheet[0].length; i++) {
      let splitListDisplay = null;
      let cellList = [];
      let highestNum = 0;
      let extraFlag = userSheet[0][i].indexOf("~~EXTRA_COLUMN~~") >= 0;
      for (let k = 1; k < userSheet.length; k++) {
        let displayString = "";
        if (userSheet[k][i] !== null) {
          let brokenString = userSheet[k][i].split(" ");
          brokenString.forEach(function(brokenValue, brokenIndex){
            if (brokenIndex === splitNum)
              displayString += "| " + brokenValue + " ";
            else if (splitNum >= brokenString.length && brokenIndex+1 >= brokenString.length)
              displayString += brokenValue + " |";
            else
              displayString += brokenValue + " ";
            if (brokenIndex > highestNum)
              highestNum = brokenIndex;
          });
        } else {
          displayString = "N/A";
        }
        cellList.push(
          <p key={k}>{displayString}</p>
        );
      }
      splitListDisplay = 
      <div
        style={{
          height: 100,
          overflowY: 'scroll',
          overlowX: 'inherit'
        }}
      >
        {cellList}
      </div>;
      headerList.push(
        <Popover
          key={i}
          id={i}
          content={splitListDisplay}
          title={
            <div style={{
              display:"inline",
            }}>
              <p>Edit {extraFlag ? userSheet[0][i].slice("~~EXTRA_COLUMN~~".length + userSheet[0][i].indexOf("~~EXTRA_COLUMN~~")) : userSheet[0][i]}</p>
              <Popover content={<p>Move data splitter one space left.</p>} title={null} trigger="hover">
                <Button onClick={decreaseSplitNum}>
                  <p>&lt;</p>
                </Button>
              </Popover>
              <Popover content={<p>Move data splitter one space right.</p>} title={null} trigger="hover">
                <Button onClick={splitNum <= highestNum ? increaseSplitNum : null}>
                  <p>&gt;</p>
                </Button>
              </Popover>
              <Popover content={<p>Split data into two columns.</p>} title={null} trigger="hover">
                <Button onClick={highestNum > 0 ? commitSplitColumn : null}>
                  <p>&lt;|&gt;</p>
                </Button>
              </Popover>
              {extraFlag ? <Popover content={<p>Delete extra column.</p>} title={null} trigger="hover"><Button onClick={deleteExtraColumn}>
                <p>Del</p>
              </Button></Popover> : null}
            </div>
          }
          trigger="click"
          visible={openOptions === i.toString() ? true : false}
        >
          <List.Item
            key={i}
            id={i}
            style={{userSelect:'none', height:'%100'}}
          >
            <Popover content={<p>Edit your column's data.</p>} title={null} trigger="hover">
              <Button id={i} style={{float: 'right'}} onClick={openOptions === i.toString() ? hideHeaderOption : showHeaderOption}><p id={i}>^</p></Button>
            </Popover>
            <p
              id={i}
              style={{
                fontSize: 12,
                paddingTop: 30,
                paddingBottom: 30,
                width: '100%',
                height: '100%',
                textAlign:'center'
              }}
              onMouseDown={handleMouseDown}
            >
              {extraFlag ? userSheet[0][i].slice("~~EXTRA_COLUMN~~".length + userSheet[0][i].indexOf("~~EXTRA_COLUMN~~")) : userSheet[0][i]}
            </p>
          </List.Item>
        </Popover>
      );
    }
  }
  return headerList;
}

function dataHeaders(dataSheet, dataLink, handleMouseEnter, handleDeleteColumn) {
  let headerList = [];
  if (dataSheet !== null && dataSheet.length > 0) {
    for (let i = 0; i < dataSheet[4].length; i++) {
      let userHeader = null;
      let deleteButton = null;
      let filledFlag = false;
      if (dataLink.length > 0 && dataLink[i] !== null && dataLink[i].length > 0) {
        filledFlag = true;
        userHeader = <>{dataLink[i][0].indexOf("~~EXTRA_COLUMN~~") >= 0 ? 
          dataLink[i][0].slice("~~EXTRA_COLUMN~~".length + dataLink[i][0].indexOf("~~EXTRA_COLUMN~~"))
          : dataLink[i][0]}<br/>-<br/></>;
        deleteButton = <Popover content={<p>Remove column attachment.</p>} title={null} trigger="hover"><Button id={i} onClick={handleDeleteColumn}><p id={i}>-</p></Button></Popover>;
      }
      headerList.push(
      <Popover key={i} content={<p>{dataSheet[3][i]}</p>} title={<>{dataSheet[4][i]}
      {dataSheet[2][i] === "required" ? <span style={{color: "#ce7979", fontWeight: "bold"}}> REQUIRED</span> : null}</>}
        mouseEnterDelay={0.4} mouseLeaveDelay={0.2}>
          <List.Item
            key={i}
            id={i}
            style={{userSelect:'none', height:100}}
            onMouseEnter={handleMouseEnter}
          >
            {deleteButton}
              <p
                id={i}
                style={{
                  fontSize: 12,
                  paddingTop: 10,
                  width: '100%',
                  textAlign:'center',
                  color: dataSheet[2][i] === "required" && !filledFlag ? "#ce7979" : "#9b9b9b"
                }}
              >
                {userHeader}
                {dataSheet[4][i]}{dataSheet[2][i] === "required" && !filledFlag ? "*" : null}
              </p>
          </List.Item>
        </Popover>
      );
    }
  }
  return headerList;
}

class UserScroll extends React.Component {

  componentDidMount() {
    this.props.editScrollBar.current.scrollTop = this.props.scrollBar;
  }
  render() {
    return(
      <div
        ref={this.props.editScrollBar}
        style={{
          height: 300,
          width:'50%',
          overflowY: 'scroll',
          direction: 'rtl',
          float:'left',
          borderWidth:'1px',
          borderStyle:'solid',
          borderColor:'#f0f0f0',
          backgroundColor:'#ffffff'
        }}
      >
        <List>
          {this.props.userSheet !== null && this.props.userSheet.length > 0 ?
            userHeaders(this.props.userSheet, this.props.handleMouseDown, this.props.showHeaderOption,
              this.props.hideHeaderOption, this.props.openOptions, this.props.splitNum, this.props.increaseSplitNum, this.props.decreaseSplitNum, 
              this.props.commitSplitColumn, this.props.deleteExtraColumn) :
            <Empty description={false} imageStyle={{opacity: 0}}></Empty>}
        </List>
      </div>
    );
  }
}

class DataScroll extends React.Component {

  componentDidMount() {
    this.props.editScrollBar.current.scrollTop = this.props.scrollBar;
  }
  render() {
    return(
      <div
        onMouseLeave={this.props.handleMouseLeave}
        ref={this.props.editScrollBar}
        style={{
          height: 300,
          width:'50%',
          overflowY: 'scroll',
          textAlign:'justify',
          borderWidth:'1px',
          borderStyle:'solid',
          borderColor:'#f0f0f0',
          backgroundColor:'#ffffff'
        }}
      >
        <List>
          {this.props.dataSheet !== null && this.props.dataSheet.length > 0 ?
            dataHeaders(this.props.dataSheet, this.props.dataLink, this.props.handleMouseEnter, this.props.handleDeleteColumn) :
            <Empty description={false} imageStyle={{opacity: 0}}></Empty>
          }
        </List>
      </div>
    );
  }
}

class EditSpreadsheet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedHeader: null,
        hoveringHeader: null,
        dropBuffer: false,
        selectedId: null,
        clientX: 0,
        clientY: 0
      };

      this.draggedHeader = React.createRef();
      this.setDataSheet = this.setDataSheet.bind(this);
      this.handleMouseMove = this.handleMouseMove.bind(this);
      this.handleMouseDown = this.handleMouseDown.bind(this);
      this.handleMouseUp = this.handleMouseUp.bind(this);
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleDeleteColumn = this.handleDeleteColumn.bind(this);
    }
    setDataSheet(value) {
      if (this.props.dataLink.length <= 0) {
        let dataLink = [];
        for (let x = 0; x < value[0].length; x++) {
          dataLink.push(null);
        }
        this.props.updateDataLink(dataLink);
      }
      this.props.updateDataSheet(value);
    }
    handleMouseMove(event) {
      this.setState({clientX:event.clientX, clientY:event.clientY});
      if (this.state.dropBuffer)
        this.setState({dropBuffer: false});
      else if (this.state.selectedId !== null)
        this.setState({selectedId: null});
    }
    handleMouseDown(event) {
      this.setState({selectedHeader: event.target.tagName === "P" ? event.target : event.target.firstChild});
    }
    handleMouseUp() {
      if (this.state.selectedHeader !== null && this.state.hoveringHeader !== null) {
        let dataLink = this.props.dataLink;
        let newColumn = [];
        for(let x = 0; x < this.props.userSheet.length; x++) {
          newColumn.push(
            this.props.userSheet[x][this.state.selectedHeader.id]
          );
        }
        dataLink[this.state.hoveringHeader.id] = newColumn;
        this.props.updateDataLink(dataLink);
        this.setState({selectedHeader: null});
      } else if (this.state.selectedHeader !== null) {
        this.setState({dropBuffer: true, selectedId: this.state.selectedHeader.id, selectedHeader: null});
      }
    }
    handleMouseEnter(event) {
      if (this.state.selectedHeader !== null) {
        this.setState({hoveringHeader: event.target.tagName === "P" ? event.target : event.target.firstChild});
      } else if (this.state.selectedId !== null) {
        let dataLink = this.props.dataLink;
        let newColumn = [];
        for(let x = 0; x < this.props.userSheet.length; x++) {
          newColumn.push(
            this.props.userSheet[x][this.state.selectedId]
          );
        }
        dataLink[event.target.tagName === "P" ? event.target.id : event.target.firstChild.id] = newColumn;
        this.props.updateDataLink(dataLink);
        this.setState({dropBuffer: false, selectedId: null, selectedHeader: null});
      }
    }
    handleMouseLeave() {
      this.setState({hoveringHeader: null});
    }
    handleDeleteColumn(event) {
      let dataLink = this.props.dataLink;
      //let newColumn = new Array(this.props.userSheet.length);
      //dataLink[event.target.id] = newColumn.fill(null);
      dataLink[event.target.id] = null;
      this.props.updateDataLink(dataLink);
    }
    render() {
      return(
        <div
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          style={{
            position: 'relative',
            marginLeft: 100,
            display: 'inline',
          }}>
          <div>
            <div
              ref={this.draggedHeader}
              className="hover" 
              style={{
                userSelect:'none',
                zIndex: 1, 
                position: 'fixed', 
                display: this.state.selectedHeader !== null ? 'block' : 'none',
                opacity: this.state.selectedHeader !== null && this.draggedHeader.current.offsetWidth > 2 ? 1 : 0,
                backgroundColor:"#ffffff",
                borderWidth:'1px',
                borderStyle:'solid',
                borderColor:'#f0f0f0',
                color:'#999999',
                fontSize: 12,
                left: this.state.selectedHeader !== null ? this.state.clientX - (this.draggedHeader.current.offsetWidth / 2) : 0, 
                top: this.state.selectedHeader !== null ? this.state.clientY - (this.draggedHeader.current.offsetHeight / 2) : 0
              }}>
                  {this.state.selectedHeader !== null ? this.state.selectedHeader.innerText : null}
            </div>
            <UploadArea
              intakeType={this.props.intakeType}
              updateFileList={this.props.updateFileList}
              updateSaveData={this.props.updateSaveData}
              saveData={this.props.saveData}
              fileList={this.props.fileList}
              dataSheet={this.props.dataSheet}
              setDataSheet={this.setDataSheet}
              updateUserSheet={this.props.updateUserSheet}
              userType={this.props.userType}
              ownerAndIs={this.props.ownerAndIs}
            />
            <UserScroll
              userSheet={this.props.userSheet}
              scrollBar={this.props.editScrollA}
              editScrollBar={this.props.editScrollBarA}
              handleMouseDown={this.handleMouseDown}
              showHeaderOption={this.props.showHeaderOption}
              hideHeaderOption={this.props.hideHeaderOption}
              openOptions={this.props.openOptions}
              splitNum={this.props.splitNum}
              increaseSplitNum={this.props.increaseSplitNum}
              decreaseSplitNum={this.props.decreaseSplitNum}
              commitSplitColumn={this.props.commitSplitColumn}
              deleteExtraColumn={this.props.deleteExtraColumn}
            />
            <DataScroll
              dataSheet={this.props.dataSheet}
              dataLink={this.props.dataLink}
              scrollBar={this.props.editScrollB}
              editScrollBar={this.props.editScrollBarB}
              handleDeleteColumn={this.handleDeleteColumn}
              handleMouseEnter={this.handleMouseEnter}
              handleMouseLeave={this.handleMouseLeave}
            />
          </div>
        </div>
      );
    }
}

class PreviewSpreadsheet extends React.Component {
  componentDidMount() {
    this.props.previewScrollBar.current.scrollTop = this.props.previewScrollA;
    this.props.previewScrollBar.current.scrollLeft = this.props.previewScrollB;
  }
  render() {
    let previewSource = [];
    let previewColumns = [];

    let dataSheet = this.props.dataSheet.slice(5);
    let dataLink = this.props.dataLink;

    if (!this.props.ownerAndIs) {
      this.props.dataSheet[4].forEach(function(value, sheetIndex) {
        let title = value;
        let modTitle = title.toLowerCase().replaceAll(" ", "_");
        previewColumns.push({
          title,
          dataIndex: modTitle,
          key: modTitle,
        });
        dataSheet.forEach(function(dataRow, rowIndex) {
          if (previewSource.length <= rowIndex) {
            let newObj = {key: "dbrow" + rowIndex}
            newObj[modTitle] = dataRow[sheetIndex];
            previewSource.push(newObj);
          } else {
            previewSource[rowIndex][modTitle] = dataRow[sheetIndex];
          }
        });
        if (typeof dataLink[sheetIndex] !== "undefined" && dataLink[sheetIndex] !== null) {
          dataLink[sheetIndex].slice(1).forEach(function(dataVal, rowIndex) {
            if (previewSource.length <= dataSheet.length + rowIndex) {
              let newObj = {key: "userrow" + rowIndex}
              newObj[modTitle] = dataVal;
              previewSource.push(newObj);
            } else {
              previewSource[dataSheet.length + rowIndex][modTitle] = dataVal;
            }
          });
        }
      });
    }
    
    return(<div
            ref={this.props.previewScrollBar}
            style={{
              height: 536,
              width:'100%',
              overflowX: 'scroll',
              overflowY: 'scroll',
              textAlign:'justify',
              borderWidth:'1px',
              borderStyle:'solid',
              borderColor:'#f0f0f0',
              backgroundColor:'#ffffff'
            }}>
            <Table
              dataSource={previewSource}
              columns={previewColumns}
              pagination={false}
            />
          </div>
    );
  }
}

function makeTypedArray(arg, doFrom = false) {
  let typedArray = null;
  for (let typedArrayCount = 1; typedArrayCount < 10; typedArrayCount++) {
    try {
      switch (typedArrayCount) {
        case 1:
          if (doFrom)
            typedArray = Uint8Array.from(arg);
          else
            typedArray = new Uint8Array(arg);
          break;
        case 2:
          if (doFrom)
            typedArray = Uint16Array.from(arg);
          else
            typedArray = new Uint16Array(arg);
          break;
        case 3:
          if (doFrom)
            typedArray = Uint32Array.from(arg);
          else
            typedArray = new Uint32Array(arg);
          break;
        case 4:
          if (doFrom)
            typedArray = Int8Array.from(arg);
          else
            typedArray = new Uint8Array(arg);
          break;
        case 5:
          if (doFrom)
            typedArray = Int16Array.from(arg);
          else
            typedArray = new Int16Array(arg);
          break;
        case 6:
          if (doFrom)
            typedArray = Uint8ClampedArray.from(arg);
          else
            typedArray = new Uint8ClampedArray(arg);
          break;
        case 7:
          if (doFrom)
            typedArray = Int32Array.from(arg);
          else
            typedArray = new Int32Array(arg);
          break;
        case 8:
          if (doFrom)
            typedArray = Float32Array.from(arg);
          else
            typedArray = new Float32Array(arg);
          break;
        case 9:
          if (doFrom)
            typedArray = Float64Array.from(arg);
          else
            typedArray = new Float64Array(arg);
          break;
        default:
          if (doFrom)
            typedArray = Uint8Array.from(arg);
          else
            typedArray = new Uint8Array(arg);
          break;
      }
    } catch (err) {
      //Try Next
    }
    if (typedArray !== null)
      break;
  }
  return typedArray
}

function makeAbBlobArray(fileListData) {
  let blob = new Blob([makeTypedArray(fileListData[0], true).buffer], {type: fileListData[4]});
  blob.lastModified = fileListData[1];
  blob.name = fileListData[2];
  blob.uid = fileListData[5];
  blob.webkitRelativePath = fileListData[6];
  let returnArray = [];
  returnArray.push(blob);
  return [blob];
}

class UploadSpreadsheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: typeof this.props.saveData[this.props.intakeType + "DisplayMode"]  !== "undefined" ? this.props.saveData[this.props.intakeType + "DisplayMode"] : 'edit',
      fileList: typeof this.props.saveData[this.props.intakeType + "FileList"] !== "undefined" ? makeAbBlobArray(this.props.saveData[this.props.intakeType + "FileList"]) : [],
      dataSheet: typeof this.props.saveData[this.props.intakeType + "DataSheet"] !== "undefined" ? this.props.saveData[this.props.intakeType + "DataSheet"] : [],
      dataLink: typeof this.props.saveData[this.props.intakeType + "DataLink"] !== "undefined" ? this.props.saveData[this.props.intakeType + "DataLink"] : [],
      userSheet: typeof this.props.saveData[this.props.intakeType + "UserSheet"] !== "undefined" ? this.props.saveData[this.props.intakeType + "UserSheet"] : [],
      editScrollA: 0,
      editScrollB: 0,
      previewScrollA: 0,
      previewScrollB: 0,
      openOptions: null,
      splitNum: 0,
      limitsPage: 0
    };

    this.editScrollBarA = React.createRef();
    this.editScrollBarB = React.createRef();
    this.previewScrollBar = React.createRef();
    this.updateFileList = this.updateFileList.bind(this);
    this.updateDataSheet = this.updateDataSheet.bind(this);
    this.updateDataLink = this.updateDataLink.bind(this);
    this.updateUserSheet = this.updateUserSheet.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.showHeaderOption = this.showHeaderOption.bind(this);
    this.hideHeaderOption = this.hideHeaderOption.bind(this);
    this.closeCurrentHeader = this.closeCurrentHeader.bind(this);
    this.increaseSplitNum = this.increaseSplitNum.bind(this);
    this.decreaseSplitNum = this.decreaseSplitNum.bind(this);
    this.commitSplitColumn = this.commitSplitColumn.bind(this);
    this.deleteExtraColumn = this.deleteExtraColumn.bind(this);
  }
  updateFileList(newFile) {
    if (newFile[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || newFile[0].type === "application/vnd.ms-excel") {
      let taskDataObj = {};
      let arrayBuffRead = new FileReader();
      let abReadFunction = function() {
        let resultArrayBuff = makeTypedArray(arrayBuffRead.result);
        let newArray = [];
        Object.keys(resultArrayBuff).forEach(function(propName) {
          if (parseInt(propName) !== "NaN")
            newArray.push(resultArrayBuff[propName]);
        });
        this.setState({fileList:newFile});
        taskDataObj[this.props.intakeType + "FileList"] = [
          newArray,
          newFile[0].lastModified,
          newFile[0].name,
          newFile[0].size,
          newFile[0].type,
          newFile[0].uid,
          newFile[0].webkitRelativePath
        ];
        this.props.updateSaveData(taskDataObj);
      };
      abReadFunction = abReadFunction.bind(this);
      arrayBuffRead.onload = abReadFunction;
      arrayBuffRead.readAsArrayBuffer(newFile[0]);
    }
  }
  updateDataSheet(newDataSheet) {
    this.setState({dataSheet:newDataSheet});
    this.props.updateSaveData({[this.props.intakeType + "DataSheet"]: newDataSheet});
  }
  updateDataLink(newDataLink) {
    this.setState({dataLink:newDataLink});
    let taskDataObj = {};
    let requiredComplete = true;
    if (this.state.dataSheet.length > 0) {
      taskDataObj[this.props.intakeType + "Link"] = this.state.dataSheet.concat(flipArray(newDataLink));
      this.state.dataSheet[2].some(function(requiredVal, dataSheetIndex) {
        if (requiredVal === "required" && (newDataLink[dataSheetIndex] === null || newDataLink[dataSheetIndex].length <= 0)) {
          requiredComplete = false;
          return true;
        }
        else return false;
      });
    }
    taskDataObj[this.props.intakeType + "DataLink"] = newDataLink;
    if (this.state.dataSheet.length > 0)
      taskDataObj[this.props.intakeType + "RequiredFilled"] = requiredComplete;
    this.props.updateSaveData(taskDataObj);
  }
  updateUserSheet(newUserSheet, newSheet) {
    this.setState({userSheet:newUserSheet});
    let taskDataObj = {};
    taskDataObj[this.props.intakeType + "UserSheet"] = newUserSheet;
    taskDataObj[this.props.intakeType + "Approval"] = false;
    if (newSheet)
      taskDataObj[this.props.intakeType + "ConnectionComplete"] = false;
    this.props.updateSaveData(taskDataObj);
  }
  toggleDisplay() {
    this.props.updateSaveData({[this.props.intakeType + "DisplayMode"]: this.state.display === "edit" ? "preview" : "edit"});
    this.setState({display: this.state.display === "edit" ? "preview" : "edit"});
  }
  showHeaderOption(event) {
    this.setState({openOptions: event.target.id, splitNum: 0});
  }
  hideHeaderOption(event) {
    if (this.state.openOptions === event.target.id)
      this.setState({openOptions: null});
  }
  closeCurrentHeader() {
    this.setState({openOptions: null});
  }
  increaseSplitNum() {
    this.setState({splitNum: this.state.splitNum + 1});
  }
  decreaseSplitNum() {
    if (this.state.splitNum > 0)
      this.setState({splitNum: this.state.splitNum - 1});
  }
  commitSplitColumn() {
    let headerString = "";
    let extraColNum = 1;
    for (let xIndex = 0; xIndex < this.state.userSheet[0].length; xIndex++){
      headerString = "~~EXTRA_COLUMN~~" + this.state.userSheet[0][this.state.openOptions].toString() + " - " + extraColNum.toString();
      if (headerString === this.state.userSheet[0][xIndex]) {
        xIndex = -1;
        extraColNum++;
      }
    }
    let headerStringB = "~~EXTRA_COLUMN~~" + this.state.userSheet[0][this.state.openOptions].toString() + " - " + (extraColNum + 1).toString();
    let newColumnA = [headerString];
    let newColumnB = [headerStringB];
    for (let yIndex = 1; yIndex < this.state.userSheet.length; yIndex++){
      let brokenString = this.state.userSheet[yIndex][this.state.openOptions].split(" ");
      if (this.state.splitNum <= 0 || this.state.splitNum >= brokenString.length) {
        newColumnA.push(this.state.userSheet[yIndex][this.state.openOptions]);
        newColumnB.push(this.state.userSheet[yIndex][this.state.openOptions]);
      } else {
        let splitIndex = this.state.userSheet[yIndex][this.state.openOptions].indexOf(" ");
        let currentSlice = this.state.userSheet[yIndex][this.state.openOptions].slice(splitIndex+1);
        let indexCount = 0;
        while (indexCount !== this.state.splitNum-1) {
          splitIndex += currentSlice.indexOf(" ")+1;
          currentSlice = currentSlice.slice(splitIndex);
          indexCount++;
        }
        newColumnA.push(this.state.userSheet[yIndex][this.state.openOptions].slice(0, splitIndex));
        newColumnB.push(this.state.userSheet[yIndex][this.state.openOptions].slice(splitIndex+1));
      }
    }

    let updatedUserSheet = this.state.userSheet.map(function(row){
      return row.slice();
    });

    let injectIndex = parseInt(this.state.openOptions)+1;
    newColumnB.forEach(function(newColCell, newColIndex){
      updatedUserSheet[newColIndex].splice(injectIndex, 0, newColCell);
    });
    newColumnA.forEach(function(newColCell, newColIndex){
      updatedUserSheet[newColIndex].splice(injectIndex, 0, newColCell);
    });
    this.setState({userSheet: updatedUserSheet});
    this.closeCurrentHeader();
  }
  deleteExtraColumn() {
    let deleteIndex = parseInt(this.state.openOptions);
    let updatedUserSheet = this.state.userSheet.map(function(row){
      return row.slice();
    });
    
    updatedUserSheet.forEach(function(currentRow, rowIndex) {
      currentRow.splice(deleteIndex, 1);
      updatedUserSheet[rowIndex] = currentRow;
    });

    this.setState({userSheet: updatedUserSheet});
    this.closeCurrentHeader();
  }

  render() {
    let ownerAndIs = this.props.intakeType === "owner" && this.props.userType === "Owner";
    if (!this.props.saveData.spreadsheetSubmitComplete && this.state.display === 'edit') {
      return(<>
              <h2>
                Uploading {firstUpper(this.props.intakeType)} Spreadsheet&nbsp;
                <Popover content={<><Button disabled={this.state.limitsPage<1} onClick={()=>{this.setState({limitsPage: this.state.limitsPage-1})}}>&lt;</Button><Button onClick={()=>{this.setState({limitsPage: this.state.limitsPage+1})}} disabled={this.state.limitsPage>2}>&gt;</Button></>} title={`${{
                  0: "All you need to do is upload the spreadsheet and drag each of your columns to match with our side.",
                  1: 'Only files with the extensions ".xlsx" and ".csv" are supported.',
                  2: "Ensure there are no extra rows such as rows containing totals and summaries.",
                  3: "It is recommended to use spreadsheets with only one record of data per row."
                }[this.state.limitsPage]}`} trigger="click">
                  <QuestionCircleOutlined/>
                </Popover>
              </h2>
              <EditSpreadsheet
                intakeType={this.props.intakeType}
                saveData={this.props.saveData}
                updateFileList={this.updateFileList}
                fileList={this.state.fileList}
                dataSheet={this.state.dataSheet}
                dataLink={this.state.dataLink}
                userSheet={this.state.userSheet}
                updateDataSheet={this.updateDataSheet}
                updateDataLink={this.updateDataLink}
                updateUserSheet={this.updateUserSheet}
                updateSaveData={this.props.updateSaveData}
                editScrollA={this.state.editScrollA}
                editScrollB={this.state.editScrollB}
                editScrollBarA={this.editScrollBarA}
                editScrollBarB={this.editScrollBarB}
                showHeaderOption={this.showHeaderOption}
                hideHeaderOption={this.hideHeaderOption}
                closeCurrentHeader={this.closeCurrentHeader}
                openOptions={this.state.openOptions}
                splitNum={this.state.splitNum}
                increaseSplitNum={this.increaseSplitNum}
                decreaseSplitNum={this.decreaseSplitNum}
                commitSplitColumn={this.commitSplitColumn}
                deleteExtraColumn={this.deleteExtraColumn}
                ownerAndIs={ownerAndIs}
              />
              <Popover content={<p>Preview your data in our database.</p>} title={null} trigger="hover">
                <Button
                  onClick={this.toggleDisplay}
                  disabled={ownerAndIs}
                >
                  Preview
                </Button>
              </Popover>
            </>);
    } else if (!this.props.saveData.spreadsheetSubmitComplete && this.state.display === 'preview') {
      return(<>
              <h2>
                Uploading {firstUpper(this.props.intakeType)} Spreadsheet
              </h2>
              <PreviewSpreadsheet
                ownerAndIs={ownerAndIs}
                intakeType={this.props.intakeType}
                dataSheet={this.state.dataSheet}
                dataLink={this.state.dataLink}
                previewScrollA={this.state.previewScrollA}
                previewScrollB={this.state.previewScrollB}
                previewScrollBar={this.previewScrollBar}
              />
              <Popover content={<p>Continue editing your spreadsheet.</p>} title={null} trigger="hover">
                <Button
                  onClick={this.toggleDisplay}
                >
                  Edit
                </Button>
              </Popover>
            </>);
    } else {
      return(
        <>
          <h2>
            Uploading {firstUpper(this.props.intakeType)} Spreadsheet
          </h2>
        </>
      );
    }
  }
}
// UPLOAD SPREADSHEET

class AutoScroll extends React.Component {
  componentDidMount() {
    this.props.autoScrollBarA.current.scrollTop = this.props.autoScrollA;
    this.props.autoScrollBarB.current.scrollTop = this.props.autoScrollB;
  }
  render() {
    let personArray = [];
    let propertyArray = [];

    if (this.props.loadedYet) {
      for (let personIndex = 0; personIndex < this.props.personSheet.length; personIndex++) {
        if (this.props.personSheet[personIndex][this.props.analysisResult[0]] !== null)
          personArray.push(<List.Item key={personIndex} style={{
                                                          height:100,
                                                          borderWidth:'1px',
                                                          borderStyle:'solid',
                                                          borderColor:'#f0f0f0',
                                                        }}>
            <p
            style={{
              userSelect:'none',
              fontSize: 12,
              paddingTop: 10,
              width: '100%',
              textAlign:'center',
            }}>
              {this.props.personSheet[personIndex][this.props.analysisResult[0]]}
            </p>
            </List.Item>);
      }
      for (let propertyIndex = 0; propertyIndex < this.props.propertySheet.length; propertyIndex++) {
        if (this.props.propertySheet[propertyIndex][this.props.analysisResult[1]] !== null)
          propertyArray.push(<List.Item key={propertyIndex} style={{
                                                              height:100,
                                                              borderWidth:'1px',
                                                              borderStyle:'solid',
                                                              borderColor:'#f0f0f0',}
                                                            }>
            <p
            style={{
              userSelect:'none',
              fontSize: 12,
              paddingTop: 10,
              width: '100%',
              textAlign:'center',
            }}>
              {this.props.propertySheet[propertyIndex][this.props.analysisResult[1]]}
            </p>
            </List.Item>);
      }
    } else {
      personArray = null;
      propertyArray = null;
    }

    return (<>
              <div
                onMouseLeave={this.props.handleMouseLeave}
                ref={this.props.autoScrollBarA}
                style={{
                  height: 300,
                  width:'50%',
                  overflowY: 'scroll',
                  float:'left',
                  direction: 'rtl',
                  textAlign:'justify',
                  borderWidth:'1px',
                  borderStyle:'solid',
                  borderColor:'#f0f0f0',
                  backgroundColor:'#ffffff'
                }}>
                  <List key={"AutoA"} style={{
                    height: 536,
                  }}>
                  {personArray}
                  </List>
              </div>
              <div
                onMouseLeave={this.props.handleMouseLeave}
                ref={this.props.autoScrollBarB}
                style={{
                  height: 300,
                  width:'50%',
                  overflowY: 'scroll',
                  textAlign:'justify',
                  borderWidth:'1px',
                  borderStyle:'solid',
                  borderColor:'#f0f0f0',
                  backgroundColor:'#ffffff'
                }}>
                  <List key={"AutoB"} style={{
                    height: 536,
                  }}>
                  {personArray}
                  </List>
              </div>
            </>);
  }
}

class AutoConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shift: typeof this.props.shift !== "undefined" ? this.props.shift : null
    };

    this.inputNumber = React.createRef();
    this.analysisChange = this.analysisChange.bind(this);
  }
  analysisChange(event) {
    this.props.analyzeColumns(event-1);
  }
  componentDidMount() {
    //if (this.inputNumber.current !== "undefined")
      //this.inputNumber.current.value = typeof this.props.shift !== "undefined" ? this.props.shift : 1;
  }
  render() {
    let inputNumber =
      typeof this.props.shift !== "undefined" ?
      <Popover content={<p>Check each connection we have detected to see if any of them work for your spreadsheets.</p>} title={null} trigger="hover">
        <InputNumber
          key={"numberChange"}
          ref={this.inputNumber}
          min={1}
          max={this.props.analysisResult[3]}
          defaultValue={this.props.shift}
          onChange={this.analysisChange}
        />
      </Popover>
      :
      null;
    return(
      <>
        <TaskDescription
          description={`We have detected a connection between ${intakePlural[this.props.intakeType]} and properties.`}/>
        <AutoScroll
          key={"autoScrollBox"}
          loadedYet={this.props.loadedYet}
          analysisResult={this.props.analysisResult}
          personSheet={this.props.personSheet}
          propertySheet={this.props.propertySheet}
          autoScrollA={this.props.autoScrollA}
          autoScrollB={this.props.autoScrollB}
          autoScrollBarA={this.props.autoScrollBarA}
          autoScrollBarB={this.props.autoScrollBarB}
        />
        {inputNumber}
      </>);
  }
}

function personBlocks(intakeType, personLink, handleMouseDown) {
  let personList = [];
  let leaseTypeIndex;
  let trackOwners;
  if (intakeType === "tenant")
    leaseTypeIndex = personLink[1].indexOf("lease_type");
  else if (intakeType === "owner")
    trackOwners = [];
  if (personLink !== null && personLink.length > 0) {
    for (let i = 7; i < personLink.length; i++) {
      let personString;
      let addPerson = true;
      if (intakeType === "owner") {
        personString = <>{personLink[i][1] + " " + personLink[i][2]}<br/>{personLink[i][0]}</>;
        let testString = personLink[i][1] + " " + personLink[i][2] + " - " + personLink[i][0];
        if (trackOwners.indexOf(testString) >= 0)
          addPerson = false;
        else
          trackOwners.push(testString);
      } else if (intakeType === "tenant") {
        personString = <>{personLink[i][1] + " " + personLink[i][2]}<br/>{personLink[i][0]}<br/>{personLink[i][leaseTypeIndex]
        + " lease, $" + personLink[i][leaseTypeIndex+1] + " rent"}<br/>{personLink[i][leaseTypeIndex+3]
        + (personLink[i][leaseTypeIndex+5] !== null ? " - " + personLink[i][leaseTypeIndex+5] : "")}</>;
      }
      
      if (addPerson) {
        personList.push(
          <List.Item
            key={i-7}
            id={i}
            style={{userSelect:'none', height:100}}
            onMouseDown={() => {handleMouseDown(i-7)}}
          >
            <p
              style={{
                fontSize: 12,
                paddingTop: 10,
                width: '100%',
                textAlign:'center'
              }}
            >
              {personString}
            </p>
          </List.Item>
        );
      }
    }
  }
  return personList;
}

function propertyBlocks(intakeType, propertyLink, personLink, personToProperty, handleMouseEnter, handleDeleteLink) {
  let propertyList = [];
  let leaseTypeIndex;
  if (intakeType === "tenant")
    leaseTypeIndex = personLink[1].indexOf("lease_type");
  if (propertyLink !== null && propertyLink.length > 0) {
    for (let i = 7; i < propertyLink.length; i++) {
      let propertyString = propertyLink[i][0] + " " + propertyLink[i][1] + ", " + propertyLink[i][2];
      let ptpList = [];
      let ptpKey = 0;
      if (typeof personToProperty !== "undefined" && propertyString in personToProperty) {
        for (let k = 0; k < personToProperty[propertyString].length; k++) {
          let deleteButton = <Popover content={<p>Remove property association.</p>} title={null} trigger="hover"><Button onClick={() => handleDeleteLink(i, k)}><p>-</p></Button></Popover>;
          let personString;
          if (intakeType === "owner") {
            let personLinkIndex = personLink.findIndex(function(personRecord, personIndex) {
              if (personIndex >= 7) {
                if (personRecord[1] + " " + personRecord[2]
                  + " - " + personRecord[0] ===
                  personToProperty[propertyString][k])
                  return true;
              }
              else return false;
            });
            personString = <React.Fragment key={ptpKey++}><br/>{deleteButton}{"-" + personLink[personLinkIndex][1] + " " + personLink[personLinkIndex][2]}
            <br/>{personLink[personLinkIndex][0]}
            </React.Fragment>;
          } else if (intakeType === "tenant") {
            let personLinkIndex = personLink.findIndex(function(personRecord, personIndex) {
              if (personIndex >= 7) {
                if (personRecord[1]
                  + " " + personRecord[2]
                  + " - " + personRecord[0]
                  + " - " + personRecord[leaseTypeIndex]
                  + " lease, $" + personRecord[leaseTypeIndex+1]
                  + " rent, started " + personRecord[leaseTypeIndex+3] ===
                  personToProperty[propertyString][k])
                  return true;
              }
              else return false;
            });
            personString = <React.Fragment key={ptpKey++}><br/>{deleteButton}{"-" + personLink[personLinkIndex][1] + " " + personLink[personLinkIndex][2]}<br/>
            {personLink[personLinkIndex][0]}<br/>{personLink[personLinkIndex][leaseTypeIndex]
            + " lease, $" + personLink[personLinkIndex][leaseTypeIndex+1] + " rent"}<br/>{personLink[personLinkIndex][leaseTypeIndex+3]
            + (personLink[personLinkIndex][leaseTypeIndex+5] !== null ? " - " + personLink[personLinkIndex][leaseTypeIndex+5] : "")}
            </React.Fragment>;
          }
          ptpList.push(personString);
        }
      }
      let ptpData = ptpList.length > 0 ? ptpList : null;
      propertyList.push(
        <List.Item
          key={i-7}
          id={i}
          style={{userSelect:'none', minHeight:100}}
          onMouseEnter={handleMouseEnter}
        >
          <p
            id={i-7}
            style={{
              fontSize: 12,
              paddingTop: 10,
              width: '100%',
              textAlign:'center',
              clear: 'right'
            }}
          >
            {propertyString}
          </p>
          <p
            id={i-7}
            style={{
              fontSize: 12,
              paddingTop: 10,
              width: '100%',
              textAlign:'right',
              float: 'right'
            }}
          >
            {ptpData}
          </p>
        </List.Item>
      );
    }
  }
  return propertyList;
}

class PersonScroll extends React.Component {
  componentDidMount() {
    this.props.manualScrollBar.current.scrollTop = this.props.scrollBar;
  }
  render() {
    return(
      <div
        ref={this.props.manualScrollBar}
        style={{
          height: 300,
          width:'50%',
          overflowY: 'scroll',
          direction: 'rtl',
          float:'left',
          borderWidth:'1px',
          borderStyle:'solid',
          borderColor:'#f0f0f0',
          backgroundColor:'#ffffff'
        }}
      >
        <List>
          {typeof this.props.personLink !== "undefined" && this.props.personLink !== null &&
            Object.keys(this.props.personLink).length > 0 ? personBlocks(this.props.intakeType, this.props.personLink, this.props.handleMouseDown) :
            <Empty description={false} imageStyle={{opacity: 0}}></Empty>}
        </List>
      </div>
    );
  }
}

class PropertyScroll extends React.Component {

  componentDidMount() {
    this.props.manualScrollBar.current.scrollTop = this.props.scrollBar;
  }
  render() {
    return(
      <div
        onMouseLeave={this.props.handleMouseLeave}
        ref={this.props.manualScrollBar}
        style={{
          height: 300,
          width:'50%',
          overflowY: 'scroll',
          textAlign:'justify',
          borderWidth:'1px',
          borderStyle:'solid',
          borderColor:'#f0f0f0',
          backgroundColor:'#ffffff'
        }}
      >
        <List>
          {typeof this.props.personLink !== "undefined" && this.props.propertyLink !== null &&
            Object.keys(this.props.propertyLink).length > 0 ? propertyBlocks(this.props.intakeType,
              this.props.propertyLink,
              this.props.personLink,
              this.props.personToProperty,
              this.props.handleMouseEnter,
              this.props.handleDeleteLink) :
            <Empty description={false} imageStyle={{opacity: 0}}></Empty>}
        </List>
      </div>
    );
  }
}

class ManualConnect extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedPerson: null,
        hoveringProperty: null,
        dropBuffer: false,
        selectedId: null,
        clientX: 0,
        clientY: 0
      };

      this.draggedHeader = React.createRef();
      this.handleMouseMove = this.handleMouseMove.bind(this);
      this.handleMouseDown = this.handleMouseDown.bind(this);
      this.handleMouseUp = this.handleMouseUp.bind(this);
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleDeleteLink = this.handleDeleteLink.bind(this);
    }
    handleMouseMove(event) {
      this.setState({clientX:event.clientX, clientY:event.clientY});
      if (this.state.dropBuffer)
        this.setState({dropBuffer: false});
      else if (this.state.selectedId !== null)
        this.setState({selectedId: null});
    }
    handleMouseDown(personId) {
      this.setState({selectedPerson: personId});
    }
    handleMouseUp() {
      if (this.state.selectedPerson !== null && this.state.hoveringProperty !== null) {
        let propertyId = parseInt(this.state.hoveringProperty) + 7;
        let personId = this.state.selectedPerson + 7;
        let personString;
        if (this.props.intakeType === "owner") {
          personString = this.props.personLink[personId][1]
          + " " + this.props.personLink[personId][2]
          + " - " + this.props.personLink[personId][0];
        } else if (this.props.intakeType === "tenant") {
          let leaseTypeIndex = this.props.personLink[1].indexOf("lease_type");
          personString = this.props.personLink[personId][1]
          + " " + this.props.personLink[personId][2]
          + " - " + this.props.personLink[personId][0]
          + " - " + this.props.personLink[personId][leaseTypeIndex]
          + " lease, $" + this.props.personLink[personId][leaseTypeIndex+1]
          + " rent, started " + this.props.personLink[personId][leaseTypeIndex+3] ;
        }
        let propertyString = this.props.propertyLink[propertyId][0]
        + " " + this.props.propertyLink[propertyId][1]
        + ", " + this.props.propertyLink[propertyId][2];
        this.props.updatePropertyLink(personString, propertyString);
        this.setState({selectedPerson: null});
      } else if (this.state.selectedPerson !== null) {
        this.setState({dropBuffer: true, selectedId: this.state.selectedPerson, selectedPerson: null});
      }
    }
    handleMouseEnter(event) {
      if (this.state.selectedPerson !== null) {
        this.setState({hoveringProperty: event.target.tagName === "P" ? event.target : event.target.firstChild});
      } else if (this.state.selectedId !== null) {
        let propertyId = parseInt(event.target.tagName === "P" ? event.target.id : event.target.firstChild.id) + 7;
        let personId = parseInt(this.state.selectedId) + 7;
        let personString;
        if (this.props.intakeType === "owner") {
          personString = this.props.personLink[personId][1]
          + " " + this.props.personLink[personId][2]
          + " - " + this.props.personLink[personId][0];
        } else if (this.props.intakeType === "tenant") {
          let leaseTypeIndex = this.props.personLink[1].indexOf("lease_type");
          personString = this.props.personLink[personId][1]
          + " " + this.props.personLink[personId][2]
          + " - " + this.props.personLink[personId][0]
          + " - " + this.props.personLink[personId][leaseTypeIndex]
          + " lease, $" + this.props.personLink[personId][leaseTypeIndex+1]
          + " rent, started " + this.props.personLink[personId][leaseTypeIndex+3];
        }
        let propertyString = this.props.propertyLink[propertyId][0]
        + " " + this.props.propertyLink[propertyId][1]
        + ", " + this.props.propertyLink[propertyId][2];
        this.props.updatePropertyLink(personString, propertyString);
        this.setState({dropBuffer: false, selectedId: null, selectedPerson: null});
      }
    }
    handleMouseLeave() {
      this.setState({hoveringProperty: null});
    }
    handleDeleteLink(propId, personId) {
      let propertyString = this.props.propertyLink[propId][0]
        + " " + this.props.propertyLink[propId][1]
        + ", " + this.props.propertyLink[propId][2];
      let personString = this.props.personToProperty[propertyString][personId];
      this.props.updatePropertyLink(personString, propertyString, true);
    }
    render() {
      return(
        <div
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          style={{
            position: 'relative',
            marginLeft: 100,
            display: 'inline',
          }}>
          <div>
            <div
              ref={this.draggedHeader}
              className="hover" 
              style={{
                userSelect:'none',
                zIndex: 1, 
                position: 'fixed', 
                display: this.state.selectedPerson !== null ? 'block' : 'none',
                opacity: this.state.selectedPerson !== null && this.draggedHeader.current.offsetWidth > 2 ? 1 : 0,
                backgroundColor:"#ffffff",
                borderWidth:'1px',
                borderStyle:'solid',
                borderColor:'#f0f0f0',
                color:'#999999',
                fontSize: 12,
                left: this.state.selectedPerson !== null ? this.state.clientX - (this.draggedHeader.current.offsetWidth / 2) : 0, 
                top: this.state.selectedPerson !== null ? this.state.clientY - (this.draggedHeader.current.offsetHeight / 2) : 0
              }}>
                  {this.state.selectedPerson !== null ? this.props.personLink[this.state.selectedPerson + 7][0] : null}
            </div>
            <TaskDescription
              description={`Drag ${intakePlural[this.props.intakeType]} to properties to associate them`}/>
            <PersonScroll
              key="personScroll"
              intakeType={this.props.intakeType}
              personLink={this.props.personLink}
              scrollBar={this.props.manualScrollA}
              manualScrollBar={this.props.manualScrollBarA}
              handleMouseDown={this.handleMouseDown}
            />
            <PropertyScroll
              key="propertyScroll"
              intakeType={this.props.intakeType}
              personToProperty={this.props.personToProperty}
              propertyLink={this.props.propertyLink}
              personLink={this.props.personLink}
              scrollBar={this.props.manualScrollB}
              manualScrollBar={this.props.manualScrollBarB}
              handleMouseEnter={this.handleMouseEnter}
              handleMouseLeave={this.handleMouseLeave}
              handleDeleteLink={this.handleDeleteLink}
            />
          </div>
        </div>
      );
    }
}

class ConnectSpreadsheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedYet: false,
      display: typeof this.props.saveData[this.props.intakeType + "ConnectMode"] 
        !== "undefined" ? this.props.saveData[this.props.intakeType + "ConnectMode"] : null,
      ownerLink: [],
      tenantLink: [],
      propertyLink: [],
      analysisResult: typeof this.props.saveData[this.props.intakeType + "AnalysisResult"]
        !== "undefined" ? this.props.saveData[this.props.intakeType + "AnalysisResult"] : [0, 0, 0, 0],
      autoScrollA: 0,
      autoScrollB: 0,
      manualScrollA: 0,
      manualScrollB: 0,
    };

    this.autoScrollBarA = React.createRef();
    this.autoScrollBarB = React.createRef();
    this.manualScrollBarA = React.createRef();
    this.manualScrollBarB = React.createRef();
    this.analyzeColumns = this.analyzeColumns.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.updatePropertyLink = this.updatePropertyLink.bind(this);
  }
  analyzeColumns(newShift) {
    if (this.props.saveData[this.props.intakeType + "RequiredFilled"] && this.props.saveData.propertyRequiredFilled) {
      let data = {};
      data[this.props.intakeType + "UserSheet"] = this.props.saveData[this.props.intakeType + "UserSheet"];
      data["propertyUserSheet"] = this.props.saveData["propertyUserSheet"];
      data[this.props.intakeType + "DataLink"] = this.props.saveData[this.props.intakeType + "DataSheet"].concat(flipArray(this.props.saveData[this.props.intakeType + "DataLink"]));
      data["propertyDataLink"] = this.props.saveData["propertyDataSheet"].concat(flipArray(this.props.saveData["propertyDataLink"]));
      let dataLinks = {};
      dataLinks[this.props.intakeType + "Link"] = data[this.props.intakeType + "DataLink"];
      dataLinks["propertyLink"] = data["propertyDataLink"];
      this.setState(dataLinks);
      let url = "/intake/AnalyzeSpreadsheets/" + this.props.intakeType + "/" + newShift.toString();
      axios.post(url, data)
      .then((response) => {
        this.setState({loadedYet: true});
        let taskDataObj = {};
        //taskDataObj[this.props.intakeType + "Link"] = data[this.props.intakeType + "DataLink"];
        //taskDataObj["propertyLink"] = data["propertyDataLink"];
        if (typeof this.props.saveData[this.props.intakeType + "Shift"] === "undefined")
          taskDataObj[this.props.intakeType + "Shift"] = 1;
        else if (this.props.saveData[this.props.intakeType + "Shift"] !== newShift + 1) {
          taskDataObj[this.props.intakeType + "Shift"] = newShift + 1;
        }
        taskDataObj[this.props.intakeType + "Approval"] = false;
        if (typeof response !== "undefined" && response.data.analysisResult !== null) {
          taskDataObj[this.props.intakeType + "ConnectMode"] = "automatic";
          taskDataObj[this.props.intakeType + "ToProperty"] = response.data.personToProperty;
          taskDataObj[this.props.intakeType + "AnalysisResult"] = response.data.analysisResult;
          taskDataObj[this.props.intakeType + "ConnectionComplete"] = true;
          this.setState({display: "automatic", analysisResult: response.data.analysisResult});
        } else {
          taskDataObj[this.props.intakeType + "AnalysisResult"] = [0, 0, 0, 0];
          taskDataObj[this.props.intakeType + "ConnectMode"] = "manual";
          this.setState({display: "manual"});
        }
        this.props.updateSaveData(taskDataObj);
      });
    } else {
      this.setState({display: null});
      this.props.updateSaveData({[this.props.intakeType + "ToProperty"]: [], [this.props.intakeType + "ConnectionComplete"]: false});
    }
  }
  toggleDisplay() {
    let taskDataObj = {};
    if (this.state.display === "automatic") {
      this.setState({display: "manual", autoScrollA: this.autoScrollBarA.current.scrollTop, autoScrollB: this.autoScrollBarB.current.scrollTop});
      taskDataObj[this.props.intakeType + "ConnectMode"] = "manual";
    } else {
      this.setState({manualScrollA: this.manualScrollBarA.current.scrollTop, manualScrollB: this.manualScrollBarB.current.scrollTop});
      taskDataObj[this.props.intakeType + "ConnectMode"] = "automatic";
      this.analyzeColumns(typeof this.props.saveData[this.props.intakeType + "Shift"] !== "undefined" ? this.props.saveData[this.props.intakeType + "Shift"]-1 : 0);
    }
    this.props.updateSaveData(taskDataObj);
  }
  updatePropertyLink(personString, propertyString, removeLink = false) {
    let personToProperty = JSON.parse(JSON.stringify(this.props.saveData[this.props.intakeType + "ToProperty"]));
    
    let taskDataObj = {};
    if (!(propertyString in personToProperty) || personToProperty[propertyString].indexOf(personString) < 0) {
      if (!(propertyString in personToProperty))
        personToProperty[propertyString] = [personString];
      else
        personToProperty[propertyString].push(personString);
      taskDataObj[this.props.intakeType + "ToProperty"] = personToProperty;
      taskDataObj[this.props.intakeType + "Approval"] = false;
      this.props.updateSaveData(taskDataObj);
    }
    else if (removeLink) {
      personToProperty[propertyString].splice(personToProperty[propertyString].indexOf(personString), 1);
      if (personToProperty[propertyString].length < 1)
        delete personToProperty[propertyString];
      taskDataObj[this.props.intakeType + "ToProperty"] = personToProperty;
      taskDataObj[this.props.intakeType + "Approval"] = false;
    }
    if (Object.keys(personToProperty).length > 0)
      taskDataObj[this.props.intakeType + "ConnectionComplete"] = true;
    if (Object.keys(taskDataObj).length > 0)
      this.props.updateSaveData(taskDataObj);
  }
  componentDidMount() {
    if (!this.props.saveData.spreadsheetSubmitComplete && this.state.display !== "manual")
      this.analyzeColumns(typeof this.props.saveData[this.props.intakeType + "Shift"] !== "undefined" ? this.props.saveData[this.props.intakeType + "Shift"]-1 : 0);
  }
  render(){
    let renderData = [];
    let ownerAndIs = this.props.userType === "Owner" && this.props.intakeType === "owner";
    if (!this.props.saveData.spreadsheetSubmitComplete && !ownerAndIs) {
      if (this.state.display === "automatic" && this.state.loadedYet) {
        renderData.push(<AutoConnect
          intakeType={this.props.intakeType}
          loadedYet={this.state.loadedYet}
          key={"autoConnect"}
          analysisResult={this.state.analysisResult}
          shift={this.props.saveData[this.props.intakeType + "Shift"]}
          analyzeColumns={this.analyzeColumns}
          personSheet={this.props.saveData[this.props.intakeType + "UserSheet"]}
          propertySheet={this.props.saveData["propertyUserSheet"]}
          autoScrollA={this.state.autoScrollA}
          autoScrollB={this.state.autoScrollB}
          autoScrollBarA={this.autoScrollBarA}
          autoScrollBarB={this.autoScrollBarB}
          />);
      } else if (this.state.display === "manual") {
        renderData.push(<ManualConnect
          key={"manualConnect"}
          intakeType={this.props.intakeType}
          personToProperty={this.props.saveData[this.props.intakeType + "ToProperty"]}
          updatePropertyLink={this.updatePropertyLink}
          personLink={this.props.saveData[this.props.intakeType + "Link"]}
          propertyLink={this.props.saveData["propertyLink"]}
          manualScrollA={this.state.manualScrollA}
          manualScrollB={this.state.manualScrollB}
          manualScrollBarA={this.manualScrollBarA}
          manualScrollBarB={this.manualScrollBarB}
        />);
      } else {
        renderData.push(null);
      }
      if ((this.state.display === "automatic" && this.state.loadedYet) || this.state.display === "manual") {
        renderData.push(<Popover content={<p>{this.state.display === "automatic" ? `Connect ${intakePlural[this.props.intakeType]} to properties manually.` : `Scan your ${this.props.intakeType} and property spreadsheets for connections. Warning: This will erase manual connections that you have made.`}</p>} title={null} trigger="hover">
          <Button key={"toggleButton"} onClick={this.toggleDisplay}>
          {this.state.display === "automatic" ? "Manual Connection" : "Automatic Connection"}
        </Button></Popover>);
      }
    }
    
    if (!ownerAndIs)
      return (<><h2>Connecting {firstUpper(intakePlural[this.props.intakeType])} and Properties</h2>{renderData}</>);
    else
      return null;
  }
}

function generateSpreadsheetDisplay(spreadsheetData, displayType, approveIntake, uploadAllData) {
  let renderData = [];

  if (displayType === "owner" && spreadsheetData.ownerRequiredFilled && spreadsheetData.ownerConnectionComplete){
    let ownerLink = spreadsheetData.ownerLink;
    let ownerToProperty = spreadsheetData.ownerToProperty;
    let propertyLink = spreadsheetData.propertyLink;
    let trackOwners = [];
    let ownerColumns = [];
    let propertyColumns = [];
    ownerLink[4].forEach(function(ownerHeader){
      let modTitle = ownerHeader.toLowerCase().replaceAll(" ", "_");
      ownerColumns.push({
        title: ownerHeader,
        dataIndex: modTitle,
        key: modTitle,
      });
    });
    propertyLink[4].forEach(function(propertyHeader){
      let modTitle = propertyHeader.toLowerCase().replaceAll(" ", "_");
      propertyColumns.push({
        title: propertyHeader,
        dataIndex: modTitle,
        key: modTitle,
      });
    });
    let ownerData = [];
    let ownerPropertyData = {};
    let ownerKey = 0;
    for (let ownerIndex = 7; ownerIndex < ownerLink.length; ownerIndex++) {
      if (trackOwners.indexOf(ownerLink[ownerIndex][0]) < 0) {
        let ownerString = ownerLink[ownerIndex][1] + " " + ownerLink[ownerIndex][2] + " - " + ownerLink[ownerIndex][0];
        let ownerObject = {key: ownerKey};
        ownerLink[ownerIndex].forEach(function(ownerCell, ownerCellIndex){
          ownerObject[ownerLink[4][ownerCellIndex].toLowerCase().replaceAll(" ", "_")] = ownerCell;
        });

        let ownerPropertyList = [];
        for (let propertyIndex = 7; propertyIndex < propertyLink.length; propertyIndex++) {
          let propertyString = propertyLink[propertyIndex][0] + " " + propertyLink[propertyIndex][1] + ", " + propertyLink[propertyIndex][2];
          if (propertyString in ownerToProperty && ownerToProperty[propertyString].indexOf(ownerString) >= 0) {
            let propertyObject = {key: propertyIndex-7};
            propertyLink[propertyIndex].forEach(function(propertyCell, propertyCellIndex) {
              propertyObject[propertyLink[4][propertyCellIndex].toLowerCase().replaceAll(" ", "_")] = propertyCell;
            });
            ownerPropertyList.push(propertyObject);
          }
        }

        ownerPropertyData[ownerKey++] = ownerPropertyList;

        ownerData.push(ownerObject);
        trackOwners.push(ownerLink[ownerIndex][0]);
      }
    }

    let expandedRow = row => {
      let inTable = ownerPropertyData[row.key.toString()];
      return <Table
        columns={propertyColumns}
        dataSource={inTable}
        pagination={false} 
        style={{
          overflowX: 'scroll',
          overflowY: 'scroll',
        }}/>;
    };

    renderData.push(<Table
      key={"ownerTable"}
      columns={ownerColumns}
      style={{
        height: 536,
        width:'100%',
        overflowX: 'scroll',
        overflowY: 'scroll',
        textAlign:'justify',
        borderWidth:'1px',
        borderStyle:'solid',
        borderColor:'#f0f0f0',
        backgroundColor:'#ffffff'
      }}
      expandedRowRender={expandedRow}
      dataSource={ownerData}
      pagination={false} />
    );
    renderData.push(<Popover content={<p>Approve the data to be submitted to our database.</p>} title={null} trigger="hover"><Button key={"approvalButton"}
      disabled={spreadsheetData.ownerApproval}
      onClick={approveIntake}>{spreadsheetData.ownerApproval ? "Approved" : "Approve"}
    </Button></Popover>);
  } else if (displayType === "tenant" && spreadsheetData.tenantRequiredFilled && spreadsheetData.tenantConnectionComplete){
    let tenantLink = spreadsheetData.tenantLink;
    let unitNameIndex = tenantLink[1].indexOf("unit_name");
    let leaseTypeIndex = tenantLink[1].indexOf("lease_type");
    let tenantToProperty = spreadsheetData.tenantToProperty;
    let propertyLink = spreadsheetData.propertyLink;
    let trackTenants = [];
    let tenantColumns = [];
    let propertyColumns = [];
    propertyLink[4].forEach(function(propertyHeader){
      let modTitle = propertyHeader.toLowerCase().replaceAll(" ", "_");
      propertyColumns.push({
        title: propertyHeader,
        dataIndex: modTitle,
        key: modTitle,
      });
    });
    tenantLink[4].forEach(function(tenantHeader, tenantHeaderIndex){
      let modTitle = tenantHeader.toLowerCase().replaceAll(" ", "_");
      if (tenantHeaderIndex < unitNameIndex)
        tenantColumns.push({
          title: tenantHeader,
          dataIndex: modTitle,
          key: modTitle,
        });
      else
        propertyColumns.push({
          title: tenantHeader,
          dataIndex: modTitle,
          key: modTitle,
        });
    });
    let tenantData = [];
    let tenantPropertyData = {};
    let tenantKey = 0;
    for (let tenantIndex = 7; tenantIndex < tenantLink.length; tenantIndex++) {
      let tenantString = tenantLink[tenantIndex][1] + " " + tenantLink[tenantIndex][2]
      + " - " + tenantLink[tenantIndex][0]
      + " - " + tenantLink[tenantIndex][leaseTypeIndex]
      + " lease, $" + tenantLink[tenantIndex][leaseTypeIndex+1]
      + " rent, started " + tenantLink[tenantIndex][leaseTypeIndex+3];
      let tenantObject = {key: tenantKey};
      let extraTenantData = [];
      tenantLink[tenantIndex].forEach(function(tenantCell, tenantCellIndex){
        if (tenantCellIndex < unitNameIndex)
          tenantObject[tenantLink[4][tenantCellIndex].toLowerCase().replaceAll(" ", "_")] = tenantCell;
        else
          extraTenantData.push(tenantCell);
      });

      let tenantPropertyList = [];
      for (let propertyIndex = 7; propertyIndex < propertyLink.length; propertyIndex++) {
        let propertyString = propertyLink[propertyIndex][0] + " " + propertyLink[propertyIndex][1] + ", " + propertyLink[propertyIndex][2];
        if (propertyString in tenantToProperty && tenantToProperty[propertyString].indexOf(tenantString) >= 0) {
          let propertyObject = {key: propertyIndex-7};
          propertyLink[propertyIndex].forEach(function(propertyCell, propertyCellIndex) {
            propertyObject[propertyLink[4][propertyCellIndex].toLowerCase().replaceAll(" ", "_")] = propertyCell;
          });
          extraTenantData.forEach(function(tenantCell, tenantCellIndex){
            propertyObject[tenantLink[4][tenantCellIndex+unitNameIndex].toLowerCase().replaceAll(" ", "_")] = tenantCell;
          });
          tenantPropertyList.push(propertyObject);
        }
      }

      if (tenantPropertyList.length > 0) {
        if (trackTenants.indexOf(tenantLink[tenantIndex][0]) < 0) {
          tenantPropertyData[tenantKey++] = tenantPropertyList;
          tenantData.push(tenantObject);
          trackTenants.push(tenantLink[tenantIndex][0]);
        } else {
          tenantPropertyData[trackTenants.indexOf(tenantLink[tenantIndex][0])] = tenantPropertyData[trackTenants.indexOf(tenantLink[tenantIndex][0])].concat(tenantPropertyList);
        }
      }
    }

    let expandedRow = row => {
      let inTable = tenantPropertyData[row.key.toString()];
      return <Table
        columns={propertyColumns}
        dataSource={inTable}
        pagination={false} 
        style={{
          overflowX: 'scroll',
          overflowY: 'scroll',
        }}/>;
    };

    renderData.push(<Table
      key={"tenantTable"}
      columns={tenantColumns}
      style={{
        height: 536,
        width:'100%',
        overflowX: 'scroll',
        overflowY: 'scroll',
        textAlign:'justify',
        borderWidth:'1px',
        borderStyle:'solid',
        borderColor:'#f0f0f0',
        backgroundColor:'#ffffff'
      }}
      expandedRowRender={expandedRow}
      dataSource={tenantData}
      pagination={false} />);
    renderData.push(<Popover content={<p>Approve the data to be submitted to our database.</p>} title={null} trigger="hover"><Button key={"approvalButton"}
      disabled={spreadsheetData.tenantApproval}
      onClick={approveIntake}>{spreadsheetData.tenantApproval ? "Approved" : "Approve"}
    </Button></Popover>);
  } else if (displayType === "submit" && (((spreadsheetData.ownerRequiredFilled && spreadsheetData.ownerConnectionComplete && spreadsheetData.ownerApproval) || spreadsheetData.isOwner) ||
    (spreadsheetData.tenantRequiredFilled && spreadsheetData.tenantConnectionComplete && spreadsheetData.tenantApproval) ||
    ((!spreadsheetData.ownerApproval && spreadsheetData.isOwner) && !spreadsheetData.tenantApproval && spreadsheetData.propertyRequiredFilled))){
      renderData.push(<p key={"uploadMessage"}>Ready to Upload:</p>);
      if (spreadsheetData.propertyRequiredFilled)
        renderData.push(<p key={"propertyMessage"}>Properties</p>);
      if (typeof spreadsheetData.ownerRequiredFilled !== "undefined" && typeof spreadsheetData.ownerToProperty !== "undefined" && spreadsheetData.ownerApproval && !spreadsheetData.isOwner)
        renderData.push(<p key={"ownerMessage"}>Owners</p>);
      if (typeof spreadsheetData.tenantRequiredFilled !== "undefined" && typeof spreadsheetData.tenantToProperty !== "undefined" && spreadsheetData.tenantApproval)
        renderData.push(<p key={"tenantMessage"}>Tenants</p>);
      renderData.push(<Button key={"submitButton"} onClick={uploadAllData}>Submit</Button>);
  }

  return renderData;

  /*
  if (!ownerValid && !tenantValid && typeof spreadsheetData.propertyLink !== "undefined") {
    let propertyLink = spreadsheetData.propertyLink;
    let propertyData = [];
    let propertyColumns = [];
    let trackProperties = [];
    propertyLink[4].forEach(function(propertyHeader){
      let modTitle = propertyHeader.toLowerCase().replaceAll(" ", "_");
      propertyColumns.push({
        title: propertyHeader,
        dataIndex: modTitle,
        key: modTitle,
      });
    });
    for (let propertyIndex = 7; propertyIndex < propertyLink.length; propertyIndex++){
      let propertyString = propertyLink[propertyIndex][0] + " " + propertyLink[propertyIndex][1] + ", " + propertyLink[propertyIndex][2];
      if (trackProperties.indexOf(propertyString) < 0) {
        let propertyObject = {key: propertyIndex-7};
        propertyLink[propertyIndex].forEach(function(propertyCell, propertyCellIndex){
          propertyObject[propertyLink[4][propertyCellIndex].toLowerCase().replaceAll(" ", "_")] = propertyCell;
        });
        propertyData.push(propertyObject);
        trackProperties.push(propertyString);
      }
    }
    renderData.push(<Table
      key={"tenantTable"}
      columns={propertyColumns}
      style={{
        height: 536,
        width:'60%',
        overflowX: 'scroll',
        overflowY: 'scroll',
        textAlign:'justify',
        borderWidth:'1px',
        borderStyle:'solid',
        borderColor:'#f0f0f0',
        backgroundColor:'#ffffff'
      }}
      dataSource={propertyData}
      pagination={false} />);
  }*/
}

class SubmitSpreadsheets extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: false
    };

    this.approveIntake = this.approveIntake.bind(this);
    this.uploadAllData = this.uploadAllData.bind(this);
    this.finishSubmission = this.finishSubmission.bind(this);
  }
  componentDidMount() {
    this.setState({error: false});
  }
  approveIntake() {
    this.props.updateSaveData({[this.props.intakeType + "Approval"]: true});
  }
  uploadAllData() {
    let spreadsheetData = {
      propertyLink: this.props.saveData.propertyLink,
      ownerLink: this.props.saveData.ownerLink,
      ownerToProperty: this.props.saveData.ownerToProperty,
      tenantLink: this.props.saveData.tenantLink,
      tenantToProperty: this.props.saveData.tenantToProperty,
      isOwner: this.props.userType === "Owner"
    };
    var form = new FormData();
    form.append("spreadsheetData", spreadsheetData);
    var url = "/intake/insertSpreadsheetData";
    axios.post(url, spreadsheetData)
    .then((response) => {
      if (!response.data.ok) {
        this.setState({error: true});
      } else {
        let taskDataObj = {};
        taskDataObj["spreadsheetSubmitComplete"] = true;
        this.props.updateSaveData(taskDataObj);
      }
    });
  }
  finishSubmission() {
    let deleteVars = ["propertyDataLink","propertyDataSheet","propertyFileList","propertyUserSheet", "propertyApproval","propertyConnectionComplete","propertyLink","propertyRequiredFilled","propertyDisplayMode","ownerDataLink","ownerDataSheet","ownerFileList","ownerUserSheet","ownerApproval","ownerConnectionComplete","ownerLink","ownerRequiredFilled","ownerDisplayMode","tenantDataLink","tenantDataSheet","tenantFileList","tenantUserSheet","tenantApproval","tenantConnectionComplete","tenantLink","tenantRequiredFilled","tenantDisplayMode","ownerShift","ownerConnectMode","ownerToProperty","ownerAnalysisResult","tenantShift","tenantConnectMode","tenantToProperty","tenantAnalysisResult","spreadsheetSubmitComplete"];
    this.props.updateSaveData(deleteVars, "delete");
    this.props.deleteTaskData(this.props.taskData.topLevelId);
  }
  render(){
    let spreadsheetData = null;
    let dataDisplay = null;
    let errorMessage = this.state.error ? <p>An error has occurred. Please ensure your data is complete and current.<br/>If this persists, please contact our support team.</p> :
      null;
    if (!this.props.saveData.spreadsheetSubmitComplete && ((typeof this.props.saveData.ownerLink !== "undefined" && typeof this.props.saveData.ownerToProperty !== "undefined" &&
      typeof this.props.saveData.propertyLink !== "undefined") ||
      (typeof this.props.saveData.tenantLink !== "undefined" && typeof this.props.saveData.tenantToProperty !== "undefined" &&
      typeof this.props.saveData.propertyLink !== "undefined") ||
      (typeof this.props.saveData.propertyLink !== "undefined"))) {
      spreadsheetData = {
        propertyLink: this.props.saveData.propertyLink,
        ownerLink: this.props.saveData.ownerLink,
        ownerToProperty: this.props.saveData.ownerToProperty,
        tenantLink: this.props.saveData.tenantLink,
        tenantToProperty: this.props.saveData.tenantToProperty,
        ownerApproval: this.props.saveData.ownerApproval,
        tenantApproval: this.props.saveData.tenantApproval,
        ownerRequiredFilled: this.props.saveData.ownerRequiredFilled,
        tenantRequiredFilled: this.props.saveData.tenantRequiredFilled,
        propertyRequiredFilled: this.props.saveData.propertyRequiredFilled,
        ownerConnectionComplete: this.props.saveData.ownerConnectionComplete,
        tenantConnectionComplete: this.props.saveData.tenantConnectionComplete,
        isOwner: this.props.userType === "Owner"
        };
      dataDisplay = generateSpreadsheetDisplay(spreadsheetData, this.props.intakeType, this.approveIntake, this.uploadAllData);
    } else if (this.props.saveData.spreadsheetSubmitComplete) {
      dataDisplay = <Result
        status="success"
        title="Successfully Submitted Spreadsheet Data!"
        subTitle="All of the data you have uploaded has been submitted, and the people you have added have been emailed."
        extra={[
          <Button type="primary" key="finishSubmission" onClick={this.finishSubmission}>
            Finish
          </Button>
        ]}
      />;
    } else {
      dataDisplay = null;
    }

    if (this.props.intakeType === "submit")
      return(
        <>
          <h2>Submitting Data</h2>
          {dataDisplay}
          {errorMessage}
        </>
      );
    else
      return(
        <>
          <h2>Approving {firstUpper(this.props.intakeType)} Data</h2>
          <TaskDescription
            description={"Please make sure this data is accurate before submitting."}/>
          {dataDisplay}
          {errorMessage}
        </>
      );
  }
}

export { UploadSpreadsheet, ConnectSpreadsheets, SubmitSpreadsheets };