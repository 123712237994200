import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Card, Collapse, message } from 'antd';
import statusColors from '../../StringData/StatusColors';
import { Link } from 'react-router-dom';
import './maintenance.css';
import { UserStateContext } from '../../Context/UserContext';
import vendorRequestStatus from '../../StringData/VendorRequestStatus';
import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
import UnitMaintenanceRequest from '../MaintenanceRequest/UnitMaintenanceRequest';
import TenantMaintenanceRequest from '../MaintenanceRequest/TenantMaintenanceRequest';
import requestStatus from '../../StringData/RequestStatus';
import { Avatar, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './listing.css';
import RequestContext from '../MaintenanceRequest/RequestContext';
import DevMode from '../../StringData/devMode';
const devMode = DevMode();

/*
Dropdown - Extends to dropdown display when clicked.
*/

export const MaintenanceRequestListing = ({
	mobileView,
	updateStatus,

	request,
	clickable,
	selected = false,
	customLink = null,
	titleColors
}) => {
	const contextRequest = React.useContext(RequestContext);
	// console.log(contextRequest);
	return !clickable ? (
		<RequestNotClickable
			updateStatus={updateStatus}
			allowDev={devMode}
			request={request}
			activeEvent={contextRequest.activeEvent}
			titleColors={titleColors}
		/>
	) : mobileView ? (
		<MaintenanceRequestMobile
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	) : (
		<MaintenanceRequestCard
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	);
};
export const UnitMaintenanceRequestListing = ({
	mobileView,
	updateStatus,
	request,
	clickable,
	selected = false,
	customLink = null,
	titleColors
}) => {
	return !clickable ? (
		<UnitRequestNotClickable
			updateStatus={updateStatus}
			allowDev={devMode}
			request={request}
			titleColors={titleColors}
		/>
	) : mobileView ? (
		<UnitMaintenanceRequestMobile
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	) : (
		<UnitMaintenanceRequestCard
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	);
};
export const PropertyMaintenanceRequestListing = ({
	mobileView,
	updateStatus,
	request,
	clickable,
	selected = false,
	customLink = null,
	titleColors
}) => {
	return !clickable ? (
		<PropertyRequestNotClickable
			updateStatus={updateStatus}
			allowDev={devMode}
			request={request}
			titleColors={titleColors}
		/>
	) : mobileView ? (
		<PropertyMaintenanceRequestMobile
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	) : (
		<PropertyMaintenanceRequestCard
			request={request}
			customLink={customLink}
			mobileView={mobileView}
			selected={selected}
		/>
	);
};

const { Panel } = Collapse;

function callback(key) {}
export const CommsMaintenanceRequestListing = ({
	mobileView,
	request,
	clickable,
	acc_type,
	selected = false,
	customLink = null,
	titleColors
}) => {
	if (acc_type.type != 'Manager') {
		return !clickable ? (
			<RequestNotClickable request={request} titleColors={titleColors} />
		) : mobileView ? (
			<CommsMaintenanceRequestMobile
				request={request}
				customLink={customLink}
				mobileView={mobileView}
				selected={selected}
			/>
		) : (
			<Collapse defaultActiveKey={['1']} onChange={callback}>
				<Panel
					header={
						<CommsMaintenanceRequestCard
							request={request}
							mobileView={mobileView}
							selected={selected}
						/>
					}
				>
					<TenantMaintenanceRequest job_id={request.job_id} mobile={true} />
				</Panel>
			</Collapse>
		);
	} else {
		return !clickable ? (
			<RequestNotClickable request={request} titleColors={titleColors} />
		) : mobileView ? (
			<CommsMaintenanceRequestMobile
				request={request}
				customLink={customLink}
				mobileView={mobileView}
				selected={selected}
			/>
		) : (
			<Collapse defaultActiveKey={['1']} onChange={callback}>
				<Panel
					header={
						<CommsMaintenanceRequestCard
							request={request}
							mobileView={mobileView}
							selected={selected}
						/>
					}
				>
					<MaintenanceRequest job_id={request.job_id} mobile={true} />
					{/* <UnitMaintenanceRequest job_id={request.job_id} mobile={true} /> */}
				</Panel>
			</Collapse>
		);
	}
};

const CommsMaintenanceRequestMobile = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			to={customLink ? customLink : '/Communication/request/' + request.job_id}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								<MaintenanceStatus
									className='rq-status d-inline'
									value={request.job_status}
									status={request.job_status}
								/>
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</Link>
	);
};

const MaintenanceRequestMobile = props => {
	const {
		selected,
		request,
		customLink = null,

		mobileView = false
	} = props;
	const contextUser = React.useContext(UserStateContext);

	const user = contextUser.user;

	return (
		<Link
			to={customLink ? customLink : '/maintenance/request/' + request.job_id}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								{user.type === 'Manager' ? (
									<MaintenanceStatus
										className='rq-status d-inline'
										value={request.job_status}
										status={request.job_status}
									/>
								) : (
									<MaintenanceStatus
										status={
											request.job_status != 'Invoicing'
												? request.job_status
												: 'Work Approved'
										}
										className='rq-status d-inline'
										value={
											request.job_status != 'Invoicing'
												? request.job_status
												: 'Work Approved'
										}
									/>
								)}
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</Link>
	);
};
const RequestNotClickable = props => {
	const contextUser = React.useContext(UserStateContext);

	const user = contextUser.user;
	const { request, updateStatus, titleColors, allowDev, activeEvent } = props;

	const [menuVisible, setMenuVisible] = useState(false);
	const [statusLoading, setStatusLoading] = useState(false);

	const RequestMenu = ({ user, selectStatus }) => {
		return user.type === 'Manager' ? (
			<Menu>
				{requestStatus.map(status => (
					<Menu.Item
						disabled={statusLoading}
						onClick={() => {
							selectStatus(status);
							setStatusLoading(true);
							setMenuVisible(false);
							axios
								.post('/jobRequest/dev/set/status', {
									status: status,
									job_id: request.job_id
								})
								// axios
								// 	.post('/eventCalendar/getEvents', {
								// 		status: status,
								// 		eventId: props.contextRequest.activeEvent.idcalendar_events
								// 	})
								.catch(err => {
									setStatusLoading(false);
									message.error(err.toString());
								})
								.then(response => {
									setStatusLoading(false);
									updateStatus(status);
									message.success('Status successfully updated!');
								});
						}}
						key={status}
					>
						<MaintenanceStatus value={status} status={status} />
					</Menu.Item>
				))}
			</Menu>
		) : (
			''
			// <Menu>
			// 	{vendorRequestStatus.map(status => (
			// 		<Menu.Item
			// 			disabled={statusLoading}
			// 			onClick={() => {
			// 				selectStatus(status);
			// 				setStatusLoading(true);
			// 				setMenuVisible(false);
			// 				axios
			// 					.post('/jobRequest/dev/set/status', {
			// 						status: status,
			// 						job_id: request.job_id
			// 					})
			// 					.catch(err => {
			// 						setStatusLoading(false);
			// 						message.error(err.toString());
			// 					})
			// 					.then(response => {
			// 						setStatusLoading(false);
			// 						updateStatus(status);
			// 						message.success('Status successfully updated!');
			// 					});
			// 			}}
			// 			key={status}
			// 		>
			// 			<MaintenanceStatus value={status} status={status} />
			// 		</Menu.Item>
			// 	))}
			// </Menu>
		);
	};
	// console.log(activeEvent);
	console.log(request);
	return (
		<div>
			<Card bordered={false} className='mb-2 displayCard nonClickableCard'>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: '#e3e3e3' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='fs-14'>
								{titleColors ? (
									<MaintenanceStatus
										status={request.job_status}
										className='rq-status d-inline'
										value={request.job_title}
									/>
								) : (
									request.job_title
								)}
							</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							{request.propertyStreet !== null ? (
								<div className='cardFooterItem ml-4'>
									{request.unit_name !== 'default' &&
									request.unit_name !== 'basic'
										? request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', Unit ' +
										  request.unit_name +
										  ', ' +
										  request.propertyCity
										: request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', ' +
										  request.propertyCity}
								</div>
							) : (
								''
							)}
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						{user.type === 'Manager' ? (
							<MaintenanceStatus
								status={request.job_status}
								className='rq-status d-inline'
								value={request.job_status}
							/>
						) : (
							<MaintenanceStatus
								status={
									activeEvent ? activeEvent?.event_status : 'Work Approved'
								}
								className='rq-status d-inline'
								value={
									activeEvent ? activeEvent?.event_status : 'Work Approved'
								}
							/>
						)}
						&nbsp;&middot;{' '}
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
					{request.vendor ? (
						<div className='cardRightFooter vendorNameMListing g-text-sm'>
							{`Vendor: ${request.vendor.fname} ${
								request.vendor.lname ? request.vendor.lname : ''
							}`}
						</div>
					) : (
						''
					)}
					{allowDev && user.type === 'Manager' ? (
						<div style={{ float: 'right' }}>
							<Dropdown
								overlay={
									<RequestMenu user={user} selectStatus={status => {}} />
								}
								placement='topRight'
								trigger='click'
								open={menuVisible}
								onOpenChange={() => {
									setMenuVisible(!menuVisible);
								}}
							>
								<Button>
									Status <DownOutlined />
								</Button>
							</Dropdown>
						</div>
					) : null}
				</div>
			</Card>
		</div>
	);
};
const UnitMaintenanceRequestMobile = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			// to={
			// 	customLink
			// 		? customLink
			// 		: '/unitMaintenance/request/' + request.unit_id + '/' + request.job_id
			// }
			to={{
				pathname: '/unitMaintenance/request/propertyUnitJR',
				state: { unit_id: request.unit_id, job_id: request.job_id }
			}}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								<MaintenanceStatus
									className='rq-status d-inline'
									value={request.job_status}
									status={request.job_status}
								/>
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</Link>
	);
};
const UnitMaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			// to={
			// 	customLink
			// 		? customLink
			// 		: '/unitMaintenance/request/' + request.unit_id + '/' + request.job_id
			// }
			to={{
				pathname: '/unitMaintenance/request/propertyUnitJR',
				state: { unit_id: request.unit_id, job_id: request.job_id }
			}}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard ${
					mobileView ? 'maintenanceMobileView' : 'displayCard'
				} ${selected ? 'maintenanceCardSelected' : ''}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							<div className='cardFooterItem ml-4'>
								{request.unit_name !== 'default' &&
								request.unit_name !== 'basic'
									? request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', Unit ' +
									  request.unit_name +
									  ', ' +
									  request.propertyCity
									: request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', ' +
									  request.propertyCity}
							</div>
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							className='rq-status d-inline'
							value={request.job_status}
							status={request.job_status}
						/>
						&nbsp;&middot;&nbsp;
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
				</div>
			</Card>
		</Link>
	);
};
const UnitRequestNotClickable = props => {
	const contextUser = React.useContext(UserStateContext);
	const user = contextUser.user;
	const { request, updateStatus, titleColors, allowDev } = props;

	const [menuVisible, setMenuVisible] = useState(false);
	const [statusLoading, setStatusLoading] = useState(false);

	const RequestMenu = ({ user, selectStatus }) => {
		return user.type === 'Manager' ? (
			<Menu>
				{requestStatus.map(status => (
					<Menu.Item
						disabled={statusLoading}
						onClick={() => {
							selectStatus(status);
							setStatusLoading(true);
							setMenuVisible(false);
							axios
								.post('/eventCalendar/eventStatus', {
									status: status,
									job_id: request.job_id
								})
								.catch(err => {
									setStatusLoading(false);
									message.error(err.toString());
								})
								.then(response => {
									setStatusLoading(false);
									updateStatus(status);
									message.success('Status successfully updated!');
								});
						}}
						key={status}
					>
						<MaintenanceStatus value={status} status={status} />
					</Menu.Item>
				))}
			</Menu>
		) : (
			''
			// <Menu>
			// 	{vendorRequestStatus.map(status => (
			// 		<Menu.Item
			// 			disabled={statusLoading}
			// 			onClick={() => {
			// 				selectStatus(status);
			// 				setStatusLoading(true);
			// 				setMenuVisible(false);
			// 				axios
			// 					.post('/jobRequest/dev/set/status', {
			// 						status: status,
			// 						job_id: request.job_id
			// 					})
			// 					.catch(err => {
			// 						setStatusLoading(false);
			// 						message.error(err.toString());
			// 					})
			// 					.then(response => {
			// 						setStatusLoading(false);
			// 						updateStatus(status);
			// 						message.success('Status successfully updated!');
			// 					});
			// 			}}
			// 			key={status}
			// 		>
			// 			<MaintenanceStatus value={status} status={status} />
			// 		</Menu.Item>
			// 	))}
			// </Menu>
		);
	};

	return (
		<div>
			<Card bordered={false} className='mb-2 displayCard nonClickableCard'>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: '#e3e3e3' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='fs-14'>
								{titleColors ? (
									<MaintenanceStatus
										status={request.job_status}
										className='rq-status d-inline'
										value={request.job_title}
									/>
								) : (
									request.job_title
								)}
							</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							{request.propertyStreet !== null ? (
								<div className='cardFooterItem ml-4'>
									{request.unit_name !== 'default' &&
									request.unit_name !== 'basic'
										? request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', Unit ' +
										  request.unit_name +
										  ', ' +
										  request.propertyCity
										: request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', ' +
										  request.propertyCity}
								</div>
							) : (
								''
							)}
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							status={request.job_status}
							className='rq-status d-inline'
							value={request.job_status}
						/>
						&nbsp;&middot;{' '}
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
					{request.vendor ? (
						<div className='cardRightFooter vendorNameMListing g-text-sm'>
							{`Vendor: ${request.vendor.fname} ${
								request.vendor.lname ? request.vendor.lname : ''
							}`}
						</div>
					) : (
						''
					)}
					{allowDev && user.type === 'Manager' ? (
						<div style={{ float: 'right' }}>
							<Dropdown
								overlay={
									<RequestMenu user={user} selectStatus={status => {}} />
								}
								placement='topRight'
								trigger='click'
								open={menuVisible}
								onOpenChange={() => {
									setMenuVisible(!menuVisible);
								}}
							>
								{/* <Button>
									Status <DownOutlined />
								</Button> */}
							</Dropdown>
						</div>
					) : null}
				</div>
			</Card>
		</div>
	);
};
const PropertyMaintenanceRequestMobile = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			// to={
			// 	customLink
			// 		? customLink
			// 		: '/propertyMaintenance/request/' +
			// 		  request.propertyid +
			// 		  '/' +
			// 		  request.job_id
			// }
			to={{
				pathname: '/propertyMaintenance/request/propertyJR',
				state: {
					job_id: request.job_id,
					propertyId: request.propertyid
				}
			}}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard maintenanceMobileView ${
					selected ? 'maintenanceCardSelected' : ''
				}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>
								<MaintenanceStatus
									className='rq-status d-inline'
									value={request.job_status}
									status={request.job_status}
								/>
								&nbsp;&middot;&nbsp;
								<div className='d-inline g-text-sm'>
									{request.job_subcategory}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`mobilecardRight g-text-sm`}>
					<div className='cardFooterItem'>ID JR{request.job_id}</div>

					{request.propertyStreet !== null ? (
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
					) : (
						''
					)}
					<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
						{request.elapsedTime
							? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
							: ''}
					</div>
				</div>
			</Card>
		</Link>
	);
};

const PropertyMaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			// to={
			// 	customLink
			// 		? customLink
			// 		: '/propertyMaintenance/request/' +
			// 		  request.propertyid +
			// 		  '/' +
			// 		  request.job_id
			// }
			to={{
				pathname: '/propertyMaintenance/request/propertyJR',
				state: {
					job_id: request.job_id,
					property_id: request.propertyid
				}
			}}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard ${
					mobileView ? 'maintenanceMobileView' : 'displayCard'
				} ${selected ? 'maintenanceCardSelected' : ''}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							<div className='cardFooterItem ml-4'>
								{request.unit_name !== 'default' &&
								request.unit_name !== 'basic'
									? request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', Unit ' +
									  request.unit_name +
									  ', ' +
									  request.propertyCity
									: request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', ' +
									  request.propertyCity}
							</div>
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							className='rq-status d-inline'
							value={request.job_status}
							status={request.job_status}
						/>
						&nbsp;&middot;&nbsp;
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
				</div>
			</Card>
		</Link>
	);
};
const PropertyRequestNotClickable = props => {
	const contextUser = React.useContext(UserStateContext);
	const user = contextUser.user;
	const { request, updateStatus, titleColors, allowDev } = props;

	const [menuVisible, setMenuVisible] = useState(false);
	const [statusLoading, setStatusLoading] = useState(false);

	const RequestMenu = ({ user, selectStatus }) => {
		return user.type === 'Manager' ? (
			<Menu>
				{requestStatus.map(status => (
					<Menu.Item
						disabled={statusLoading}
						onClick={() => {
							selectStatus(status);
							setStatusLoading(true);
							setMenuVisible(false);
							axios
								.post('/jobRequest/dev/set/status', {
									status: status,
									job_id: request.job_id
								})
								.catch(err => {
									setStatusLoading(false);
									message.error(err.toString());
								})
								.then(response => {
									setStatusLoading(false);
									updateStatus(status);
									message.success('Status successfully updated!');
								});
						}}
						key={status}
					>
						<MaintenanceStatus value={status} status={status} />
					</Menu.Item>
				))}
			</Menu>
		) : (
			<Menu>
				{vendorRequestStatus.map(status => (
					<Menu.Item
						disabled={statusLoading}
						onClick={() => {
							selectStatus(status);
							setStatusLoading(true);
							setMenuVisible(false);
							axios
								.post('/jobRequest/dev/set/status', {
									status: status,
									job_id: request.job_id
								})
								.catch(err => {
									setStatusLoading(false);
									message.error(err.toString());
								})
								.then(response => {
									setStatusLoading(false);
									updateStatus(status);
									message.success('Status successfully updated!');
								});
						}}
						key={status}
					>
						<MaintenanceStatus value={status} status={status} />
					</Menu.Item>
				))}
			</Menu>
		);
	};

	return (
		<div>
			<Card bordered={false} className='mb-2 displayCard nonClickableCard'>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: '#e3e3e3' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='fs-14'>
								{titleColors ? (
									<MaintenanceStatus
										status={request.job_status}
										className='rq-status d-inline'
										value={request.job_title}
									/>
								) : (
									request.job_title
								)}
							</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							{request.propertyStreet !== null ? (
								<div className='cardFooterItem ml-4'>
									{request.unit_name !== 'default' &&
									request.unit_name !== 'basic'
										? request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', Unit ' +
										  request.unit_name +
										  ', ' +
										  request.propertyCity
										: request.propertynumber +
										  ' ' +
										  request.propertyStreet +
										  ', ' +
										  request.propertyCity}
								</div>
							) : (
								''
							)}
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							status={request.job_status}
							className='rq-status d-inline'
							value={request.job_status}
						/>
						&nbsp;&middot;{' '}
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
					{request.vendor ? (
						<div className='cardRightFooter vendorNameMListing g-text-sm'>
							{`Vendor: ${request.vendor.fname} ${
								request.vendor.lname ? request.vendor.lname : ''
							}`}
						</div>
					) : (
						''
					)}
					{allowDev ? (
						<div style={{ float: 'right' }}>
							<Dropdown
								overlay={
									<RequestMenu user={user} selectStatus={status => {}} />
								}
								placement='topRight'
								trigger='click'
								open={menuVisible}
								onOpenChange={() => {
									setMenuVisible(!menuVisible);
								}}
							>
								<Button>
									Status <DownOutlined />
								</Button>
							</Dropdown>
						</div>
					) : null}
				</div>
			</Card>
		</div>
	);
};
const CommsMaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Card
			bordered={false}
			className={`mb-2 mrdisplayCard ${
				mobileView ? 'maintenanceMobileView' : 'displayCard'
			} ${selected ? 'maintenanceCardSelected' : ''}`}
			style={{ margin: 'auto' }}
		>
			<div className='cardMain'>
				<Avatar size={38} className='mr-2' style={{ backgroundColor: 'unset' }}>
					<img
						alt='Maintenance Category'
						width={18}
						height={18}
						src={require('../../icons/maintenanceIcons/' +
							MaintenanceIcons(request.job_subcategory))}
					/>
				</Avatar>
				<div className='cardLeft'>
					<div className='cardHeader'>
						<div className='caption titleHover'>{request.job_title}</div>
					</div>
					<div className='cardLeftFooter g-text-sm'>
						<div className='cardFooterItem'>ID JR{request.job_id}</div>
						<div className='cardFooterItem ml-4'>
							{request.unit_name !== 'default' && request.unit_name !== 'basic'
								? request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', Unit ' +
								  request.unit_name +
								  ', ' +
								  request.propertyCity
								: request.propertynumber +
								  ' ' +
								  request.propertyStreet +
								  ', ' +
								  request.propertyCity}
						</div>
						<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
							{request.elapsedTime
								? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
								: ''}
						</div>
					</div>
				</div>
			</div>
			<div className={`cardRight`}>
				<div
					className={`cardRightHeader ${
						request.vendor ? '' : 'rightHeaderMxAuto'
					}`}
				>
					<MaintenanceStatus
						className='rq-status d-inline'
						value={request.job_status}
						status={request.job_status}
					/>
					&nbsp;&middot;&nbsp;
					<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
				</div>
			</div>
		</Card>
	);
};

const MaintenanceRequestCard = ({
	selected,
	request,
	customLink = null,
	mobileView = false
}) => {
	return (
		<Link
			to={customLink ? customLink : '/maintenance/request/' + request.job_id}
		>
			<Card
				bordered={false}
				className={`mb-2 mrdisplayCard ${
					mobileView ? 'maintenanceMobileView' : 'displayCard'
				} ${selected ? 'maintenanceCardSelected' : ''}`}
				style={{ margin: 'auto' }}
			>
				<div className='cardMain'>
					<Avatar
						size={38}
						className='mr-2'
						style={{ backgroundColor: 'unset' }}
					>
						<img
							alt='Maintenance Category'
							width={18}
							height={18}
							src={require('../../icons/maintenanceIcons/' +
								MaintenanceIcons(request.job_subcategory))}
						/>
					</Avatar>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='caption titleHover'>{request.job_title}</div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>ID JR{request.job_id}</div>
							<div className='cardFooterItem ml-4'>
								{request.unit_name !== 'default' &&
								request.unit_name !== 'basic'
									? request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', Unit ' +
									  request.unit_name +
									  ', ' +
									  request.propertyCity
									: request.propertynumber +
									  ' ' +
									  request.propertyStreet +
									  ', ' +
									  request.propertyCity}
							</div>
							<div className='cardFooterItem mr-2' style={{ float: 'right' }}>
								{request.elapsedTime
									? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
									: ''}
							</div>
						</div>
					</div>
				</div>
				<div className={`cardRight`}>
					<div
						className={`cardRightHeader ${
							request.vendor ? '' : 'rightHeaderMxAuto'
						}`}
					>
						<MaintenanceStatus
							className='rq-status d-inline'
							value={request.job_status}
							status={request.job_status}
						/>
						&nbsp;&middot;&nbsp;
						<div className='d-inline g-text-sm'>{request.job_subcategory}</div>
					</div>
				</div>
			</Card>
		</Link>
	);
};

export const MaintenanceStatus = ({ status, value, className = '' }) => {
	let fontColor = statusColors.find(s => s.value === status);
	let color = fontColor !== undefined ? fontColor.color : '';
	return <div className={color + ' ' + className}>{value}</div>;
};

export const MaintenanceIcons = status => {
	if (status === undefined) return 'repair.png';
	switch (status) {
		case 'Access/Security':
			return 'security.png';
		case 'Appliances':
			return 'appliances.png';
		case 'Electrical':
			return 'electrical.png';
		case 'General Repair':
			return 'repair.png';
		case 'Heating':
			return 'heating.png';
		case 'Janitorial/Cleaning':
			return 'cleaning.png';
		case 'Landscaping':
			return 'landscaping.png';
		case 'Property Management':
			return 'propertyManagement.png';
		case 'Plaster/Paint':
			return 'painting.png';
		case 'Plumbing':
			return 'plumbing.png';
		case 'Snow Clearing':
			return 'snowClearing.png';
		case 'Vendilation/AC':
			return 'ventilation.png';
		case 'Other':
			return 'repair.png';
		default:
			return 'repair.png';
	}
};
