import React, { useState } from 'react';
import { Button, Dropdown, Menu, Card, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useWindowDimensions from '../../Context/WindowsDimensions';
import axios from 'axios';

import { Modal, Form } from 'react-bootstrap';
import RequestContext from './RequestContext';
import getActions from './StatusComponents';
const cancelToken = axios.CancelToken.source();

class RequestActions extends React.Component {
	static contextType = RequestContext;

	resumeRequest = () => {
		axios
			.post('/jobRequest/update/status/resume', {
				job_id: this.context.request.job_id
			})
			.then(response => {
				this.context.updateStatus(response.data.status);
				message.success('Request has been successfully resumed', 2.5);
				return;
			})
			.catch(error => {
				console.log(error);
				this.context.setError(error.name, error.description);
				return;
			});
	};

	render() {
		// console.log(this.context.request);

		// const action = getActions(
		// 	this.context.user.type === 'Manager'
		// 		? this.context.request
		// 		: this.context.activeEvent
		// );
		const action = getActions(this.context);
		return action ? (
			<>
				<div className='JRSubtitle'>Action Center</div>
				<Card bordered={false}>
					<div id='actionContainer'>{action}</div>
				</Card>
			</>
		) : (
			''
		);
	}
}

const OnHoldModal = ({ updateOnHold, closeModal }) => {
	let [reason, setReason] = useState();
	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>Put Request On Hold</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Reason For putting request on hold</Form.Label>
						<Form.Control
							as='textarea'
							placeholder='Enter reason here'
							onChange={e => setReason(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<Button className='mr-4' onClick={closeModal}>
						Close
					</Button>
					<Button type='primary' onClick={() => updateOnHold(reason)}>
						Submit
					</Button>
				</div>
			</Modal.Footer>
		</>
	);
};
/*
TODO:Fix
const CancelModal = ({ updateCancelRequest, closeModal }) => {
  let [reason, setReason] = useState();
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Reason For cancelling Request</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter reason here"
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button className="mr-4" onClick={closeModal}>
            Close
          </Button>

          <Button type="primary" onClick={() => updateOnHold(reason)}>
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};
*/
const ActionDropdownBtn = () => {
	const { width } = useWindowDimensions();
	return (
		<>
			{width > 760 ? 'Actions ' : ''} <DownOutlined />
		</>
	);
};

export default RequestActions;
