import React from 'react';
import moment from 'moment';
import {
	PlusCircleOutlined,
	EditOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import {
	Card,
	Avatar,
	Button,
	message,
	Dropdown,
	Menu,
	Select,
	Table,
	Skeleton,
	DatePicker,
	Input,
	TimePicker
} from 'antd';

class EditableLogCell extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			loading: false,
			editDate:
				this.props.rowData[this.props.cellType + '_time'] !== '-'
					? moment(
							new Date(this.props.rowData[this.props.cellType + '_time_data'])
					  )
					: null,
			editDistance: this.props.rowData.total_distance,
			editExpenses: this.props.rowData.total_expenses
			// ,editDistanceExpenses: this.props.rowData['total_' + this.props.cellType],
		};
	}

	componentDidUpdate(prevProps) {
		console.log(this.state.editDate);
		if (
			prevProps.rowData[this.props.cellType + '_time'] === '-' &&
			this.props.rowData[this.props.cellType + '_time'] !== '-'
		) {
			this.setState({
				editDate: moment(
					new Date(this.props.rowData[this.props.cellType + '_time_data'])
				)
			});
		}
	}

	startEdit = () => {
		this.setState({ editing: true });
	};

	cancelEdit = () => {
		this.setState({
			editing: false,
			editDate: moment(
				new Date(this.props.rowData[this.props.cellType + '_time_data'])
			)
		});
	};

	saveTimeEdit = async () => {
		let startDate;
		let endDate;
		if (this.props.cellType === 'start') {
			startDate = this.state.editDate;
			endDate = moment(new Date(this.props.rowData.end_time_data));
		} else if (this.props.cellType === 'end') {
			startDate = moment(new Date(this.props.rowData.start_time_data));
			endDate = this.state.editDate;
		}
		this.setState({ loading: true });
		await this.props.editTime(
			this.props.cellType,
			this.state.editDate,
			startDate,
			endDate,
			this.props.worklog_id,
			this.props.rowData.status
		);
		this.setState({ editing: false, loading: false });
	};

	saveDistanceExpensesEdit = async () => {
		let newTotalDistance;
		let newTotalExpenses;
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate = moment(new Date(this.props.rowData.end_time_data));
		if (this.props.cellType === 'distance') {
			newTotalDistance = this.state.editDistance;
			newTotalExpenses = this.props.rowData.total_expenses;
		} else if (this.props.cellType === 'expenses') {
			newTotalDistance = this.props.rowData.total_distance;
			newTotalExpenses = this.state.editExpenses;
		}
		this.setState({ loading: true });
		await this.props.editDistanceExpenses(
			this.props.cellType,
			this.state.editDistance,
			this.state.editExpenses,
			newTotalDistance,
			newTotalExpenses,
			startDate,
			endDate,
			this.props.worklog_id,
			this.props.rowData.status
		);
		this.setState({ editing: false, loading: false });
	};

	changeDate = date => {
		let newDate = moment(date.format('YYYY-MM-DD'))
			.hour(date.hour())
			.minute(date.minute())
			.second(date.second());
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		if (endDate) {
			if (this.props.cellType == 'start') {
				if (newDate >= endDate)
					newDate = moment(endDate.format('YYYY-MM-DD'))
						.hour(endDate.hour())
						.minute(endDate.minute())
						.second(endDate.second() - 1);
			} else if (newDate <= startDate) {
				newDate = moment(startDate.format('YYYY-MM-DD'))
					.hour(startDate.hour())
					.minute(startDate.minute())
					.second(startDate.second() + 1);
			}
		}
		this.setState({ editDate: newDate });
	};

	changeTime = time => {
		let newDate = moment(time.format('YYYY-MM-DD'))
			.hour(time.hour())
			.minute(time.minute())
			.second(time.second());
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		if (endDate) {
			if (this.props.cellType == 'start') {
				if (newDate >= endDate) {
					newDate = moment(endDate.format('YYYY-MM-DD'))
						.hour(endDate.hour())
						.minute(endDate.minute())
						.second(endDate.second() - 1);
				}
			} else if (newDate <= startDate) {
				newDate = moment(startDate.format('YYYY-MM-DD'))
					.hour(startDate.hour())
					.minute(startDate.minute())
					.second(startDate.second() + 1);
			}
		}
		this.setState({ editDate: newDate });
	};

	disabledDate = current => {
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		if (endDate)
			return (
				current > moment().endOf('day') ||
				(this.props.cellType == 'start' && current > endDate) ||
				(this.props.cellType == 'end' && current < startDate)
			);
		else return false;
	};

	disabledHours = () => {
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		let hours = [];
		if (endDate) {
			let startHour = 24;
			let endHour = 23;
			if (this.props.cellType == 'start') {
				if (
					this.state.editDate.format('YYYY MM DD') ==
					endDate.format('YYYY MM DD')
				)
					startHour = endDate.hour() + 1;
			} else if (
				startDate.format('YYYY MM DD') ==
				this.state.editDate.format('YYYY MM DD')
			) {
				startHour = 0;
				endHour = startDate.hour() - 1;
			}
			for (let i = startHour; i <= endHour; i++) {
				hours.push(i);
			}
		}
		return hours;
	};

	disabledMinutes = () => {
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		let minutes = [];
		if (endDate) {
			let startMinute = 60;
			let endMinute = 59;
			if (this.props.cellType == 'start') {
				if (
					this.state.editDate.format('YYYY MM DD, h') ==
					endDate.format('YYYY MM DD, h')
				)
					startMinute = endDate.minute() + 1;
			} else if (
				startDate.format('YYYY MM DD, h') ==
				this.state.editDate.format('YYYY MM DD, h')
			) {
				startMinute = 0;
				endMinute = startDate.minute() - 1;
			}
			for (let i = startMinute; i <= endMinute; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	disabledSeconds = () => {
		let startDate = moment(new Date(this.props.rowData.start_time_data));
		let endDate =
			this.props.rowData.end_time_data !== '-'
				? moment(new Date(this.props.rowData.end_time_data))
				: null;
		let seconds = [];
		if (endDate) {
			let startSecond = 60;
			let endSecond = 59;
			if (this.props.cellType == 'start') {
				if (
					this.state.editDate.format('YYYY MM DD, h:mm') ==
					endDate.format('YYYY MM DD, h:mm')
				)
					startSecond = endDate.second();
			} else if (
				startDate.format('YYYY MM DD, h:mm') ==
				this.state.editDate.format('YYYY MM DD, h:mm')
			) {
				startSecond = 0;
				endSecond = startDate.second();
			}
			for (let i = startSecond; i <= endSecond; i++) {
				seconds.push(i);
			}
		}
		return seconds;
	};

	render() {
		if (!this.state.editing) {
			return (
				<>
					{this.props.cellData}&nbsp;
					{!this.state.editing ? (
						<EditOutlined onClick={this.startEdit} />
					) : null}
				</>
			);
		} else if (this.state.editing && this.props.editTime) {
			return (
				<>
					<DatePicker
						disabled={this.state.loading}
						inputReadOnly
						hideDisabledOptions
						allowClear={false}
						renderExtraFooter={null}
						onSelect={this.changeDate}
						value={this.state.editDate}
						disabledDate={this.disabledDate}
						format='YYYY-MM-DD h:mm A'
						showTime={true}
					/>
					{/* <TimePicker disabled={this.state.loading} use12Hours inputReadOnly hideDisabledOptions allowClear={false} renderExtraFooter={null}
            onSelect={this.changeTime} value={this.state.editDate} disabledHours={this.disabledHours}
            disabledMinutes={this.disabledMinutes} disabledSeconds={this.disabledSeconds} showNow={false}/> */}
					&nbsp;
					{this.state.loading ? (
						<LoadingOutlined style={{ color: '#4D7CFE' }} />
					) : (
						<>
							<Button onClick={this.saveTimeEdit} type='primary'>
								Save
							</Button>
							<Button onClick={this.cancelEdit} type='primary'>
								Cancel
							</Button>
						</>
					)}
				</>
			);
		} else if (this.state.editing && this.props.editDistanceExpenses) {
			return this.props.cellType === 'distance' ? (
				<>
					<Input
						disabled={this.state.loading}
						inputReadOnly
						hideDisabledOptions
						onChange={e => this.setState({ editDistance: e.target.value })}
						value={this.state.editDistance}
					/>
					&nbsp;
					{this.state.loading ? (
						<LoadingOutlined style={{ color: '#4D7CFE' }} />
					) : (
						<>
							<Button onClick={this.saveDistanceExpensesEdit} type='primary'>
								Save
							</Button>
							<Button onClick={this.cancelEdit} type='primary'>
								Cancel
							</Button>
						</>
					)}
				</>
			) : (
				<>
					<Input
						disabled={this.state.loading}
						inputReadOnly
						hideDisabledOptions
						onChange={e => this.setState({ editExpenses: e.target.value })}
						value={this.state.editExpenses}
					/>
					&nbsp;
					{this.state.loading ? (
						<LoadingOutlined style={{ color: '#4D7CFE' }} />
					) : (
						<>
							<Button onClick={this.saveDistanceExpensesEdit} type='primary'>
								Save
							</Button>
							<Button onClick={this.cancelEdit} type='primary'>
								Cancel
							</Button>
						</>
					)}
				</>
			);
		}
	}
}
export default EditableLogCell;
