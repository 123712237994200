import React from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { message } from 'antd';
import axios from 'axios';
import Task from '../../../Components/Tasks/Task';
import WorkOrder from '../../../Modals/WorkOrder/WorkOrder';
import RequestContext from '../RequestContext';
import WorkOrderTroubleshoot from '../../../Modals/WorkOrder/WorkOrderTroubleshoot';
import WorkOrderWorkDone from '../../../Modals/WorkOrder/WorkOrderWorkDone';
import ManageWorkOrder from '../../../Modals/WorkOrder/ManageWorkOrder';
import { WindowsFilled } from '@ant-design/icons';

export class WorkOrderAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			workModal: false
		};
	}

	openWorkModal = () => {
		this.setState({ workModal: !this.state.workModal });
	};

	doneWorkOrder = (newStatus, note) => {
		this.setState({ workModal: false });
		this.context.addActivity(note, null, true);
		this.context.updateStatus(newStatus);
		if (newStatus === 'Work Order - Work Done')
			message.success('Work Order progress has been saved');
		else if (newStatus === 'Work Order - Review')
			message.success('Work Order complete and sent for review');
		else if (newStatus === 'Extension')
			message.success(
				'Work Order has been saved and an extension has been requested'
			);

		return;
	};
	render() {
		return (
			<div>
				<Task>
					<Task.Title>Complete Entry Form</Task.Title>
					<Task.Action onClick={this.openWorkModal} primary>
						Enter Unit
					</Task.Action>
				</Task>

				<WorkOrder
					startingPage={'Entry'}
					doneWorkOrder={this.doneWorkOrder}
					modalOpen={this.state.workModal}
					onCancel={this.openWorkModal}
				/>
			</div>
		);
	}
}
export class WorkOrderTroubleshootAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			workModal: false
		};
	}
	openWorkModal = () => {
		this.setState({ workModal: !this.state.workModal });
	};
	// startWork = () => {
	// 	axios
	// 		.post('workOrder/add/session', {
	// 			job_id: this.context.request.job_id,
	// 			entry_required: 0,
	// 			event_id: this.context.activeEvent.idcalendar_events
	// 		})
	// 		.then(response => {
	// 			// this.setModalLoading();
	// 			// this.openEntryModal();
	// 			this.context.addValidEventsForActions(
	// 				response.data.request.workSchedule
	// 			);
	// 			this.context.updateRequestWorkOrders(
	// 				this.context.activeEvent.idcalendar_events
	// 			);
	// 			this.context.updateEventStatus('Work Order - Troubleshoot');
	// 			message.success('Entry has been successfully submitted!', 2.5);
	// 			// window.location.reload();
	// 		})
	// 		.catch(err => console.log(err));
	// };

	doneWorkOrder = (newStatus, note) => {
		this.setState({ workModal: false });
		this.context.addActivity(note, null, true);
		this.context.updateStatus(newStatus);
		if (newStatus === 'Work Order - Troubleshoot')
			message.success('Work Order progress has been saved');
		else if (newStatus === 'Work Order - Review')
			message.success('Work Order complete and sent for review');
		else if (newStatus === 'Extension')
			message.success(
				'Work Order has been saved and an extension has been requested'
			);

		return;
	};
	render() {
		console.log(this.context.activeEvent);

		if (this.context.activeEvent) {
			// if (this.context.activeEvent.workOrders) {
			return (
				<div>
					{this.context.user.type === 'Vendor' ||
					this.context.user.type === 'Manager' ? (
						<>
							{/* {this.context.activeEvent.start_work === 0 ? (
									<Task>
										<Task.Action onClick={this.startWork} primary>
											Start Work
										</Task.Action>
									</Task>
								) : ( */}
							<Task>
								<Task.Title>Do Troubleshooting process</Task.Title>
								<Task.Action primary onClick={this.openWorkModal}>
									Troubleshoot
								</Task.Action>
							</Task>
							{/* )} */}
						</>
					) : (
						''
					)}

					<WorkOrderTroubleshoot
						doneWorkOrder={this.doneWorkOrder}
						modalOpen={this.state.workModal}
						onCancel={this.openWorkModal}
					/>
				</div>
			);
			// } else {
			// 	return null;
			// }
		} else {
			return null;
		}
	}
}
export class WorkOrderWorkDoneAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			workModal: false
		};
	}
	openWorkModal = () => {
		this.setState({ workModal: !this.state.workModal });
	};
	doneWorkOrder = (newStatus, note) => {
		this.setState({ workModal: false });
		this.context.addActivity(note, null, true);
		this.context.updateEventStatus(newStatus);
		if (newStatus === 'Work Order - Work Done')
			message.success('Work Order progress has been saved');
		else if (newStatus === 'Work Order - Review')
			message.success('Work Order complete and sent for review');
		else if (newStatus === 'Extension')
			message.success(
				'Work Order has been saved and an extension has been requested'
			);

		return;
	};
	render() {
		if (
			this.context.user.type === 'Vendor' ||
			this.context.user.type === 'Manager'
		) {
			return (
				<div>
					<Task>
						<Task.Title>Fill in work order details</Task.Title>
						<Task.Action primary onClick={this.openWorkModal}>
							Work Order Details
						</Task.Action>
					</Task>
					<WorkOrderWorkDone
						doneWorkOrder={this.doneWorkOrder}
						modalOpen={this.state.workModal}
						onCancel={this.openWorkModal}
					/>
				</div>
			);
		} else {
			return null;
		}
	}
}
export class WorkOrderManageAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			workModal: false
		};
	}
	openWorkModal = () => {
		this.setState({ workModal: !this.state.workModal });
	};
	doneWorkOrder = (newStatus, note) => {
		this.setState({ workModal: false });
		this.context.addActivity(note, null, true);
		this.context.updateStatus(newStatus);
		if (newStatus === 'Manage Work Order')
			message.success('Work Order progress has been saved');
		else if (newStatus === 'Work Order - Review')
			message.success('Work Order complete and sent for review');
		else if (newStatus === 'Extension')
			message.success(
				'Work Order has been saved and an extension has been requested'
			);

		return;
	};
	render() {
		return (
			<div>
				<Task>
					<Task.Title>Work Order Management</Task.Title>
					<Task.Action primary onClick={this.openWorkModal}>
						Manage Work Order
					</Task.Action>
				</Task>
				<ManageWorkOrder
					doneWorkOrder={this.doneWorkOrder}
					modalOpen={this.state.workModal}
					onCancel={this.openWorkModal}
				/>
			</div>
		);
	}
}
