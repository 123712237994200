import React from "react";
import { Form } from "react-bootstrap";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import axios from "axios";

import "./taskStyle.css";
import { Button} from 'antd';
const cancelToken = axios.CancelToken.source();

function completeTask(updateFunction, taskData, unitTask){
	updateFunction();
}

//NEED GENERAL PAGE OF STUFF FOR UTILITIES


class ExteriorStructure extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			propertySize: 0,
			siding: [],
			shingles: [],
			foundation: {0:{type:"", age:0},},
			windows: [],
			doors: [],
			/*selections: {
				siding: null,
				shingles: null,
				foundation: null,
			}*/
			htmlFoundation: [],
		}
		//this.createSelect = this.createSelect.bind(this)
		
	}
	
	componentDidMount(){
		let examplesent = {"exterior" : {"foundation" : ["Isolated footing", "Combined footing", "Strip Foundation", "Raft Foundation", "Pile Foundation", "Filled shaft"],
	"siding": ["Brick", "Engineered wood", "Fiber-Cement", "Glass", "Metal", "Stone", "Stucco", "Vinyl", "Wood"],
		"shingles" : ["3 Tab Asphalt, Archtierctural", "Metal Roof", "Wood", "Wood shake", "Solar", "Slate TIle", "Rubber", "Composite Plastic", "Tile"]}}
		//this.setState({selections["foundation"]: examplesent[]})
		//his.setState({selections.foundation: [createSelect(examplesent["exterior"]["foundation"]])})
		let temp = this.createSelect(examplesent["exterior"]["foundation"] )
		this.setState({htmlFoundation: temp})
		
		//createSelect()
		
		
	}
	
	verify = () => {
		return true;
	}
	
	submitForm = () => {
		if(this.verify){
			
		}
	}
	
	createSelect = (options) => {
		//if(!options) return
		
		let htmlOption = []; 
		for(let i=0; i<options.length; i++){
			//console.log(i)
			htmlOption.push(<option key = {options[i]} name={options[i]}>{options[i]}</option>)
		}
		return htmlOption;
	}
	
	/*renderChoices = (selection) => {
		console.log("hi")
		let type, length, repeatCode;
		let returnCode = [];
		let foundationCode = 
			<Form.Row>
				<Form.Group as={Col} md={4}>
					<Form.Label className="form-label"> Type </Form.Label>
					<select className="form-control" onChange={this.updateFoundation}>
						{this.state.htmlFoundation}
					</select>
				</Form.Group>
				<Form.Group as={Col} md={3} >
					<Form.Label className="form-label"> Age </Form.Label>
					<Form.Control className="form-text" type="number" onChange={(e) => console.log(e)} /> 
				</Form.Group>
			</Form.Row>;
			
		if(selection == "f"){
			type = this.state.foundation;
			length = this.state.foundation.length;
			repeatCode = foundationCode;
		}
		
		
		/*if(length == 0){
			length++
		}
		
		for(let i=0; i<length; i++){
			returnCode.push(repeatCode)
		}
		
		return returnCode;
		
	}
	
	addChoice = (selection) => {
		let name; 
		console.log(selection)
		
		if(selection == "f"){
			name = this.state.foundation.length
			//get prev state
			//this.setState(prev => ({
				//foundation: [...prev.foundation, []]
			//}))
			/*this.setState(prevState => ({
				foundation: {
					...prevState.foundation,
					[name]: "heY"
				}
			}))
			this.setState()
			console.log(this.state)
		}
		
	}*/
	
	addChoice = event => {
		let name = event.currentTarget.name[0];
		let setUp = {"type": "", "age": 0}
		
		if(name == "f"){
			let num = event.currentTarget.name[2]
			num++;
			event.currentTarget.name="f-" + num
			this.setState(prevState => ({
				foundation: {
					...prevState.foundation,
					[num]: setUp
				}
			}))
			
		}
		
	}
	
	
	
	
	render(){
		
		//let foundationArea = "";
		//let foundaitonTableDiv = <div type="hidden">
		
		let foundation = [];
		//foundation.push(<Form.Row> <Form.Group as={Col} md={4}> <Form.Label className="form-label"> Type </Form.Label> <select name="f-0"className="form-control" onChange={this.updateFoundation}> {this.state.htmlFoundation} </select> </Form.Group> <Form.Group as={Col} md={3} > <Form.Label className="form-label"> Age </Form.Label> <Form.Control name="f-0" className="form-text" type="number" onChange={(e) => console.log(e)} />  </Form.Group> </Form.Row>);
		
		/*for(let i=0; i<this.state.foundation.length; i++){
			foundation= <Form.Row> <Form.Group as={Col} md={4}> <Form.Label className="form-label"> Type </Form.Label> <select className="form-control" onChange={this.updateFoundation}> {this.state.htmlFoundation} </select> </Form.Group> <Form.Group as={Col} md={3} > <Form.Label className="form-label"> Age </Form.Label> <Form.Control className="form-text" type="number" onChange={(e) => console.log(e)} />  </Form.Group> </Form.Row>
			console.log(foundation)
		}*/
		
		for(let i=0; i< Object.keys(this.state.foundation).length; i++){
			foundation.push(<div><Form.Row><Form.Group as={Col} md={4}> 
				<Form.Label className="form-label"> Type </Form.Label> 
					<select name="f-1"className="form-control" onChange={this.updateFoundation}> {this.state.htmlFoundation} </select> 
				</Form.Group> 
				<Form.Group as={Col} md={3} > <Form.Label className="form-label"> Age </Form.Label> 
				<Form.Control name="f-1" className="form-text" type="number" onChange={(e) => console.log(e)}/>   
				</Form.Group></Form.Row></div>)
		}
		
		
		return (
			<div>
				<h3 className="title"> Register Enterior Property Infomation </h3>
				<Form>
					<Form.Row>
						<Form.Group as={Col} md={4}>
							<Form.Label className="form-label"> Property Size </Form.Label>
							<Form.Control className="form-text" type="number" value={this.state.propertySize} onChange={(e) => this.setState({propertySize: e.target.value})} />
						</Form.Group>
						<Form.Group as={Col} >
							<Form.Label className="form-label">. </Form.Label>
							<select className="form-control">
								<option> Meters^2 </option>
								<option> Feet^2 </option>
							</select>
						</Form.Group>
						
						
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} >
							
						</Form.Group>
					</Form.Row>
					
					<Form.Label className="form-label"> Foundation </Form.Label>
					<div name="foundation">
						{foundation}
					</div>
					<Button name="f-0" onClick={this.addChoice}> Add New Foundation </Button>
				
				</Form>
			</div>
		)
	}
	
	
}


export {ExteriorStructure};