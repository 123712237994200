import React, { Component } from 'react';
import { Layout, Button, Collapse, Alert } from 'antd';
import { Modal, ModalTitle, Form } from 'react-bootstrap';
import './communication.css';
import { Route } from 'react-router-dom';
import {
	Messenger,
	ThreadList
} from '../../Components/Communication/Communication';
import LoadScreen from '../../Components/LoadScreen';
import axios from 'axios';
import moment from 'moment';

import { UserStateContext } from '../../Context/UserContext'; //Get the current user's data
import CommsMaintenanceRequest from '../../Modals/JobRequest/CommsMaintenanceRequest';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
import { CommsMaintenanceRequestCard } from '../JobRequests/RequestListing';
import RequestDescription from '../MaintenanceRequest/RequestDescription';
import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
import { CommsMaintenanceRequestListing } from '../JobRequests/RequestListing';
import SelectJobRequests from '../JobRequests/SelectJobRequests';
const cancelToken = axios.CancelToken.source();

const { Sider, Content } = Layout;
const { Panel } = Collapse;
/*
todo 
-connect websocket
-Add maintenance request information
*/

class CommunicationLayout extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);

		this.state = {
			width: 0,
			threads: [],
			groupedThreads: {},
			loadedThreads: false,
			datalist: []
		};
	}

	getTime = time => {
		time = moment(time);
		let days = moment().diff(time, 'days');
		if (days > 0) return days + 'd';

		let hours = moment().diff(time, 'hours');
		if (hours > 0) return hours + 'h';

		let minutes = moment().diff(time, 'minutes');
		return minutes + 'm';
	};

	compareTime = (oldTime, newTime) => {
		let oldTimePeriod = { m: 1, h: 2, d: 3, y: 4 }[oldTime[oldTime.length - 1]];
		let newTimePeriod = { m: 1, h: 2, d: 3, y: 4 }[newTime[newTime.length - 1]];
		if (oldTimePeriod > newTimePeriod) return newTime;
		else if (oldTimePeriod < newTimePeriod) return oldTime;
		else {
			let oldTimeNum = parseInt(oldTime.slice(0, oldTime.length - 1));
			let newTimeNum = parseInt(newTime.slice(0, newTime.length - 1));
			if (oldTimeNum > newTimeNum) return newTime;
			else return oldTime;
		}
	};

	getIntTime = time => {
		let totalTime = parseInt(time.slice(0, time.length - 1));
		totalTime *= { m: 1, h: 60, d: 1440, y: 525600 }[time[time.length - 1]];
		return totalTime;
	};

	/* Used to determine if the view is mobile or desktop */
	updateWidth = () => {
		this.setState({ width: window.innerWidth });
	};

	/* Fetch full thread list */
	updateThreads = () => {
		axios.post('/communication/getThreads').then(response => {
			// console.log(response.data.threads);
			if (response.data.threads.length > 0) {
				let groupedThreads = {};
				let userList = '';
				response.data.threads.forEach(thread => {
					userList = (thread.people ?? [])
						.map(user => user.user_id)
						.filter(user_id => user_id !== this.context.user.id)
						.sort()
						.toString();
					if (userList in groupedThreads) {
						groupedThreads[userList].threads.push(thread);
						groupedThreads[userList].isUnread =
							groupedThreads[userList].isUnread || thread.unread;
						groupedThreads[userList].latestTime = this.compareTime(
							groupedThreads[userList].latestTime,
							this.getTime(thread.time ? thread.time : thread.create_date)
						);
					} else
						groupedThreads[userList] = {
							threads: [thread],
							id: userList,
							isUnread: thread.unread,
							latestTime: this.getTime(
								thread.time ? thread.time : thread.create_date
							)
						};
				});
				let groupedThreadsOrder = Object.keys(groupedThreads).sort((a, b) => {
					let aGroup = groupedThreads[a];
					let bGroup = groupedThreads[b];
					return (
						this.getIntTime(aGroup.latestTime) -
						this.getIntTime(bGroup.latestTime)
					);
				});
				this.setState({
					threads: response.data.threads,
					loadedThreads: true,
					groupedThreads: {
						threads: groupedThreads,
						order: groupedThreadsOrder
					}
				});
			} else {
				this.setState({ threads: response.data.threads, loadedThreads: true });
			}
		});
	};

	componentDidMount = () => {
		document.title = 'Communication';
		this.updateWidth();
		window.addEventListener('resize', this.updateWidth);
		this.updateThreads();
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateWidth);
	};

	render() {
		return this.state.width > 760 ? (
			<DesktopCommLayout
				loadedThreads={this.state.loadedThreads}
				updateThreads={this.updateThreads}
				history={this.props.history}
				groupedThreads={this.state.groupedThreads}
				threads={this.state.threads}
				userType={this.props.userType}
			/>
		) : (
			<MobileCommLayout
				loadedThreads={this.state.loadedThreads}
				updateThreads={this.updateThreads}
				history={this.props.history}
				className='mr-2 m-auto'
				groupedThreads={this.state.groupedThreads}
				threads={this.state.threads}
				userType={this.props.userType}
			/>
		);
	}
}

/*Desktop comm layout. props: threads */
class DesktopCommLayout extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			calData: [],
			datalist: [],
			threadList: [],
			jrList: [],
			jrList1: [],
			tenant: [],
			property: [],
			displayJRKey: 0,
			selectedThread: window.location.href.split('/')[4]
		};
	}

	getThread = () => {
		axios
			.post('/communication/getallThread', { user_id: this.context.user.id })
			.then(response => {
				this.setState({ threadList: response.data.message });
				return;
			});
	};

	getDetails = selected_thread => {
		axios
			.post('jobRequest/get/threadjrproperty', { thread_id: selected_thread })
			.then(response => {
				this.setState({ details: response.data.requests });

				return;
			});
	};
	getTenantDetails = selected_thread => {
		axios
			.post('communication/get/threadtenant', { thread_id: selected_thread })
			.then(response => {
				if (response.data.requests > 0)
					this.setState({ tenant: response.data.requests[0] });
			});
	};
	getTenantProperty = tenant => {
		axios
			.post('communication/get/tenantproperty', { tenant_id: tenant })
			.then(response => {
				this.setState({ property: response.data });
			});
	};
	changeThread = selected_thread => {
		this.setState({ selectedThread: selected_thread });
	};

	reloadJRDisplay = () =>
		this.setState({ displayJRKey: this.state.displayJRKey !== 0 ? 0 : 1 });

	render() {
		let locationSplit = window.location.href.split('/');
		let selected_thread = locationSplit.length > 4 ? locationSplit[4] : null;
		const thread = this.state.jrList.filter(e => {
			return e.thread_id == selected_thread;
		});
		const reload = () => window.location.reload();
		if (this.context.user.type == 'Manager') {
			return (
				<Layout className='commmunicationLayout communicationDesktop'>
					<Sider width={300} id='commSiderLeft' style={{ overflow: 'auto' }}>
						<ThreadList
							loadedThreads={this.props.loadedThreads}
							updateThreads={this.props.updateThreads}
							history={this.props.history}
							staticHeader
							groupedThreads={this.props.groupedThreads}
							threads={this.props.threads}
						/>
					</Sider>
					<Content id='communicationContent'>
						{selected_thread !== null ? (
							<Messenger
								backButton
								reloadJRDisplay={this.reloadJRDisplay}
								history={this.props.history}
								updateThreads={this.props.updateThreads}
								thread_id={selected_thread}
								userType={this.props.userType}
							/>
						) : (
							<NoMessage />
						)}
					</Content>
					<Content id='allJobRequest'>
						<DisplayThreadJR
							key={this.state.displayJRKey}
							thread_id={selected_thread}
						/>
					</Content>
				</Layout>
			);
		} else {
			return (
				<Layout className='commmunicationLayout communicationDesktop'>
					<Sider width={300} id='commSiderLeft'>
						<ThreadList
							loadedThreads={this.props.loadedThreads}
							updateThreads={this.props.updateThreads}
							history={this.props.history}
							staticHeader
							groupedThreads={this.props.groupedThreads}
							threads={this.props.threads}
						/>
					</Sider>
					<Content id='communicationContent'>
						<Route exact path='/communication' render={() => <NoMessage />} />
						<Route
							exact
							path='/communication/:id'
							render={() => (
								<Messenger
									backButton
									reloadJRDisplay={this.reloadJRDisplay}
									updateThreads={this.props.updateThreads}
									history={this.props.history}
									thread_id={selected_thread}
									userType={this.props.userType}
								/>
							)}
						/>
					</Content>
					<Content id='allJobRequest'>
						<Route exact path='/communication' render={() => <NoMessage />} />
						<Route
							exact
							path='/communication/:id'
							render={() => (
								<DisplayThreadJR
									key={this.state.displayJRKey}
									thread_id={selected_thread}
								/>
							)}
						/>
					</Content>
				</Layout>
			);
		}
	}
}

/* Mobile view for communication tab. props: threads */
class MobileCommLayout extends React.Component {
	static contextType = UserStateContext;
	render() {
		let selected_thread = window.location.href.split('/')[4];

		return (
			<>
				<Route
					exact
					path='/communication'
					render={() => (
						<ThreadList
							loadedThreads={this.props.loadedThreads}
							updateThreads={this.props.updateThreads}
							history={this.props.history}
							groupedThreads={this.props.groupedThreads}
							threads={this.props.threads}
						/>
					)}
				/>
				<Route
					exact
					path='/communication/:id'
					render={() => (
						<Messenger
							reloadJRDisplay={this.reloadJRDisplay}
							history={this.props.history}
							updateThreads={this.props.updateThreads}
							backButton
							thread_id={selected_thread}
							userType={this.props.userType}
						/>
					)}
				/>
			</>
		);
	}
}

/* Displays when no thread is specified in the main body. Only used in desktop view */
const NoMessage = () => {
	return (
		<div className='noSelectedImg text-center'>
			<img src={require('../../images/emptyThread.png')} />
			<div className='g-text-lg'>
				Select a thread to the left to open the messenger.
			</div>
		</div>
	);
};

/* Displays when no JR  is linked in the main body. Only used in desktop view */

const NoJR = () => {
	return (
		<div className='noSelectedImg text-center'>
			<img src={require('../../images/emptyThread.png')} />
			<div className='g-text-lg'>
				There is no Job request linked to the conversation.
			</div>
		</div>
	);
};

class DisplayThreadJR extends Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			jrList: [],
			loading: false
		};
	}
	componentDidMount() {
		if (this.props.thread_id !== null) {
			this.getJRs();
		} else {
			this.setState({ loading: false, jrList: [] });
		}
	}

	componentDidUpdate(pP, pS, sS) {
		if (pP.thread_id != this.props.thread_id) {
			if (this.props.thread_id !== null) {
				this.getJRs();
			} else {
				this.setState({ loading: false, jrList: [] });
			}
		}
	}

	getJRs = () => {
		this.setState({ loading: true });
		axios
			.post('/jobRequest/get/threadjobid', { thread_id: this.props.thread_id })
			.then(response => {
				this.setState({ jrList: response.data.requests, loading: false });
			});
	};

	render() {
		return (
			<div className={`listContainer`}>
				{!this.state.loading ? (
					this.state.jrList.length > 0 ? (
						<div className={`listScrollContainer`}>
							{this.state.jrList.map((jr, jrIndex) => {
								return (
									<CommsMaintenanceRequestListing
										key={jrIndex}
										request={jr}
										clickable={true}
										acc_type={this.context.user}
									/>
								);
							})}{' '}
						</div>
					) : (
						<NoJR />
					)
				) : (
					<LoadScreen />
				)}
			</div>
		);
	}
}

export default CommunicationLayout;
