import React, { useState } from "react";
import "./propertyListing.css";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
export function PropertyListing({
  icon,
  address,
  units,
  total_units,
  type,
  owner,
  link,
  font,
}) {
  return (
    <div className={font + " mt-2"}>
      <Link
        className={"listing-lg"}
        to={link !== null ? "properties/" + link : "#"}
      >
        <div className="flex-15 listing-icon m-auto text-center">
          <img
            className="circleImage m-auto image40"
            src={require("../images/" + icon)}
            alt="house"
          />
        </div>
        <div className="listing-address flex-40 m-auto fs-18 ">
          <div>{address}</div>
        </div>
        <div className="listing-units m-auto flex-7 fs-list">
          {total_units} {units === 1 ? "Unit" : "Units"}
        </div>
        <div className="listing-type m-auto flex-18 fs-list">{type}</div>
        <div className="listing-owner m-auto flex-20 fs-list">
          Owner - {owner}
        </div>
      </Link>
    </div>
  );
}

export const UnitCard = ({
  image = "",
  className = "",
  number = -1,
  unit = "",
  street = "",
  city = "",
  province = "",
  postalCode = "",
  total_units = -1,
  unit_id = -1,
  type = "",
  total_tenants = -1,
  inline = false,
  actions = false,
}) => {
  return (
    <Link
      className={
        "_profileCard " + (inline ? "d-inline-block " : "d-block ") + +className
      }
      to={unit_id !== -1 ? "/property/" + unit_id : "#"}
    >
      <div className="_content">
        <div className="_left">
          {image === "" ? (
            <Avatar size={64}>{street.charAt(0)}</Avatar>
          ) : (
            <Avatar src={image} size={64}></Avatar>
          )}
        </div>
        <div className="_right">
          <h5>
            {unit !== ""
              ? number + "-" + unit + " " + street
              : number + " " + street}
          </h5>
          <div className="g-text-sm _location">
            {city}, {province} {postalCode}
          </div>
          <div className="g-text-sm d-flex">
            {type !== "" ? <div className="_bottom">{type}</div> : ""}

            {total_tenants === -1 ? (
              ""
            ) : (
              <div className="_bottom">
                {total_tenants} {total_tenants > 1 ? " Tenants" : " Tenant"}
              </div>
            )}

            {total_units === -1 ? (
              ""
            ) : (
              <div className="_bottom">
                {total_units} {total_units > 1 ? " Units" : " Unit"}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

/*
 {actions ? (
        <div className="_btnGroup">
          <Button className="_button">Contact</Button>
          <Button className="_button">Profile</Button>
        </div>
      ) : (
        ""
      )}

                <UnitCard
            image={
              "https://cdn.torontolife.com/wp-content/uploads/2018/04/toronto-house-for-sale-316-indian-road-1-803x603.jpg"
            }
            number={this.state.request.propertynumber}
            street={this.state.request.propertyStreet}
            city={this.state.request.propertyCity}
            province={this.state.request.propertyProvince}
            postalCode={this.state.request.propertyPostalcode}
            unit={this.state.request.unit_name}
            total_units={this.state.request.total_units}
            unit_id={this.state.request.unit_id}
            type={this.state.request.propertyType}
            total_tenants={1}
          />
          <br />
          <UserCard
            name={
              this.state.request.requester_fname +
              " " +
              this.state.request.requester_lname
            }
            accType={this.state.request.requester_acctype}
            email={this.state.request.requester_email}
            user_id={this.state.request.requester_id}
          />
*/
export function PropertyListingJobRequest({
  icon,
  address,
  total_units,
  units,
  type,
  owner,
  selectedUnit,
  addProperty,
  addPropUnit,
  addUnit,
  selected,
}) {
    return (
      <div className="mt-2">
        <div
          className={
            !selected
              ? "request-listing"
              : "request-listing selectedBlue"
          }
          onClick={total_units === 1 ? addPropUnit : addProperty}
        >
          <div className=" d-flex flex-60">
            <div className='flex-10 listing-icon m-auto'>
              <img
                className="circleImage image32 d-inline"
                src={require("../images/" + icon)}
                alt="house"
              />
            </div>
            <div className="listing-address flex-70 d-inline m-auto fs-14 ">
              {address}
            </div>
          </div>
          <div className='d-flex flex-40'>
            <div className="listing-units m-auto fs-list-sm">
              {total_units} Unit
            </div>
            <div className="listing-type m-auto fs-list-sm">{type}</div>
          </div>
        </div>
        {total_units > 1 ? 
          <div className={!selected ? "dropdown d-none" : "dropdown "}>
            {units.map((unit, unitKey) => (
              <UnitListing
                key={unitKey}
                selectedUnit={selectedUnit === unit.unit_id ? true : false}
                onclick={() => addUnit(unit)}
                unit_name={"Unit " + unit.unit_name}
              />
            ))}
          </div>
        : ""}
      </div>
    );
}

function UnitListing({ selectedUnit, onclick, icon, unit_name }) {
  return (
      <div
        className={
          !selectedUnit
            ? " unit-listing"
            : " unit-listing selectedBlue"
        }
        onClick={onclick}
      >
        <div className="listing-address m-auto flex-50 fs-14 ">
          <div>{unit_name}</div>
        </div>
      </div>
  );
}

/* For future use - initalize job request with all units or no units
 

<UnitListing 
  selectedUnit = {selectedUnit === "All" ? true : false}
  onclick = {() => addUnit("All")}
  unit_name={"All Units"}    
/>
<UnitListing 
  selectedUnit = {selectedUnit === "None" ? true : false}
  onclick = {() => addUnit("None")}
  unit_name={"No units, only the exterior building"}    
/>

*/
