import React, { Component } from 'react';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { Button, TimePicker } from 'antd';
import WorkOrderContext from '../WorkOrder/WorkOrderContext';
import ImageUploadModal from '../ImageUploadModal/ImageUploadModal';
import moment from 'moment';

const cancelToken = axios.CancelToken.source();
class ActionsTaken extends Component {
	static contextType = WorkOrderContext;
	constructor(props) {
		super(props);
		this.state = {
			description: '',
			errormsg: '',
			action_id: null,
			loading: false,
			user_id: null,
			user_type: null,
			start_time: null,
			end_time: null,
			actionDetails: []
		};
	}
	componentDidMount = () => {
		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				console.log(res.data.details);
				this.setState({
					workOrderId: res.data.details[0].wo_id
				});
			});
	};
	/* troubleSave is the function that saves the description for the troubleshooting
but for some reason is not working */
	troubleSave = () => {
		if (this.verify()) {
			let x = [
				{
					action_id: this.state.action_id,
					action: this.state.description,
					user_id: this.state.user_id,
					user_type: this.state.user_type,
					start_time: this.state.start_time,
					end_time: this.state.end_time
				}
			];
			this.setState({ errormsg: '', loading: true });
			axios
				.post('/workOrder/update/work/actions', {
					actions: [
						{
							action_id: this.state.action_id,
							action: this.state.description,
							user_id: this.state.user_id,
							user_type: this.state.user_type,
							start_time: this.state.start_time.valueOf(),
							end_time: this.state.end_time.valueOf()
						}
					],
					wo_id: this.state.workOrderId
				})

				.then(response => {
					this.setState({
						loading: false,
						actionDetails: response.data.actions
					});
					this.context.changePage('Images');
				})
				.catch(err => {
					this.setState({
						errormsg: 'Error: Please try again.',
						loading: false
					});
				});
		}
	};
	goNext() {}

	changeDesc = e => {
		this.setState({ description: e.target.value });
	};
	verify = () => {
		if (this.state.description === '') {
			this.setState({
				errormsg: 'Please enter a detailed description of the problem'
			});
			return false;
		}
		return true;
	};
	startOnChange = (time, timeString) => {
		console.log(time.valueOf());
		this.setState({ start_time: time });
	};
	endOnChange = (time, timeString) => {
		this.setState({ end_time: time });
	};
	render() {
		const { request } = this.context;
		console.log(this.context);
		// const eventData = this.context.getEventData()[0].workOrders[0];
		//let start=moment(eventData.actions[0].start_time.valueOf()).format('LTS')||"";
		//let end=moment(eventData.actions[0].end_time.valueOf()).format('LTS')||"";
		return (
			<div className='Actions Taken'>
				<div>
					Start Time
					{
						<TimePicker
							style={{ marginRight: 60, marginLeft: 10 }}
							onChange={this.startOnChange}
							// defaultValue={
							// 	this.state.actionTaken.length != 0
							// 		? moment(
							// 				moment(this.state.actionTaken[0].start_time.valueOf()),
							// 				'HH:mm'
							// 		  )
							// 		: moment('00:00', 'HH:mm')
							// }
							format='HH:mm'
						/>
					}
					End Time
					{
						<TimePicker
							style={{ marginLeft: 10 }}
							onChange={this.endOnChange}
							// defaultValue={
							// 	this.state.actionTaken.length != 0
							// 		? moment(
							// 				moment(this.state.actionTaken[0].end_time.valueOf()),
							// 				'HH:mm'
							// 		  )
							// 		: moment('00:00', 'HH:mm')
							// }
							format='HH:mm'
						/>
					}
				</div>
				<div>
					<label style={{ marginTop: 25 }}>Details of the actions taken:</label>

					<textarea
						className='form-control'
						rows={6}
						// defaultValue={
						// 	this.state.actionTaken.length != 0
						// 		? this.state.actionTaken[0].action
						// 		: this.state.description
						// }
						//defaultValue={""}
						onChange={this.changeDesc}
						id='comment'
						name='text'
					></textarea>
				</div>

				<p className='errorMsg'>{this.state.errormsg}</p>
				<br />
				<div className='buttonGroup'>
					<Button
						loading={this.state.loading}
						type='primary'
						className='mr-0 '
						onClick={() => this.troubleSave()}
					>
						Save
					</Button>
				</div>
			</div>
		);
	}
}

export default ActionsTaken;
