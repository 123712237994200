import React from "react";
import Cookies from "js-cookie";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();
function userReducer(state, action) {
  switch (action.type) {
    case "initalize": {
      Cookies.set("user", action.state.user, { expires: 1 });
      return { user: action.state.user };
    }
    case "update": {
      Cookies.set("user", action.state.user, { expires: 1 });
      return { user: action.state.user };
    }
    case "sync": {
      let user = Cookies.get("user");
      if (user !== undefined) return { user: JSON.parse(user) };
      else return { user: undefined };
    }

    case "signout": {
      Cookies.remove("session");
      return { user: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, null);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a userProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a userProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, UserStateContext };
