import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import BreadCrumbTitles from './BreadCrumbTitles';
class BreadCrumbHeader extends React.Component {
	render() {
		let pathName = this.props.history.location.pathname;

		let items = [];
		let str = String(pathName).split('/');

		let i = 1;
		while (i < str.length) {
			let crumb = { name: str[i], link: '/' + str[i] };
			let j = i - 1;
			while (j > 0) {
				crumb.link = '/' + str[j] + crumb.link;
				j = j - 1;
			}
			items.push(_crumb(crumb.link, crumb.name, i + 1, i, str.length));
			i = i + 1;
		}

		if (items.length == 0) items = [];
		return <Breadcrumb className='d-inline'>{items}</Breadcrumb>;
	}
}

/* Used to change the name depending on the breadcrumb. */
function _crumb(link, cname, index, key, max) {
	let _cname = capitalizeFirstLetter(cname).replace(/_/g, ' ');

	let crumbTitle = BreadCrumbTitles(cname);
	if (!crumbTitle) {
		if (_cname.split('_')[0] == 'Request') return null;
		else if (String(link).includes('/request/' + cname)) {
			_cname = cname;
		} else if (cname.split('_')[0] == 'U') return '';
		// else if (String(link).includes('/request/1/' + cname)) {
		// 	_cname = 'JR' + cname;
		// }
		return (
			<Breadcrumb.Item key={index}>
				<Link className='breadLink' to={link}>
					{_cname}
				</Link>
			</Breadcrumb.Item>
		);
	} else {
		return (
			<Breadcrumb.Item key={index}>
				<Link className='breadLink' to={link}>
					{crumbTitle}
				</Link>
			</Breadcrumb.Item>
		);
	}
}

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export default withRouter(BreadCrumbHeader);
