import React from 'react';
import { Layout } from 'antd';
import { Link, Redirect } from 'react-router-dom';

const { Sider } = Layout;

/* TODO

-Make sure the correc tab is highlighted after reload

*/

class DesktopSidebar extends React.Component {
	render() {
		let defaultkey = String(this.props.pathName).split('/');
		return (
			<Sider
				theme='light'
				collapsed={this.props.collapseNavBar}
				className='desktopSidebar hideScrollBar'
			>
				<div
					className={
						this.props.collapseNavBar ? 'notVisable logo' : 'logo text-center'
					}
				>
					<Link to='/communication'>
						<img
							className='steadyIcon'
							src={require('../../icons/steady.png')}
						/>
					</Link>
				</div>
				{this.props.SidebarMenu}
			</Sider>
		);
	}
}

export default DesktopSidebar;
