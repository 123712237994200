import React from 'react';
import axios from 'axios';

import LoadScreen from '../../Components/LoadScreen';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import { Link } from 'react-router-dom';
import { Card, Avatar, Button, Collapse } from 'antd';
import { property } from '../Agreements/Agreements';
import { leaseType } from '../Agreements/Agreements';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
const cancelToken = axios.CancelToken.source();
function callback(key) {}
const { Panel } = Collapse;
class Contacts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedList: this.props.user.type == 'Manager' ? 'Tenant' : 'Vendor',
			tenantList: null,
			ownerList: null,
			vendorList: null,
			searchValue: ''
		};
	}

	componentDidMount() {
		this.getContacts();
	}

	setError = () => {
		return <div>err</div>;
	};
	getContacts = () => {
		axios.get('/communication/get/contacts').then(response => {
			this.setState({
				tenantList: response.data.tenants,
				ownerList: response.data.owners,
				vendorList: response.data.vendors
			});
		});
	};

	setSearch = value => this.setState({ searchValue: value });
	render() {
		let torender;
		if (this.state.selectedList === 'Tenant') {
			if (this.state.tenantList === null) {
				torender = (
					<div style={{ height: '72vh' }}>
						<LoadScreen className='m-auto' />
					</div>
				);
			} else if (this.state.tenantList.length == 0) {
				torender = (
					<div style={{ textAlign: 'center' }}>No Tenants to View</div>
				);
			}
		} else if (this.state.selectedList === 'Owner') {
			if (this.state.ownerList === null) {
				torender = (
					<div style={{ height: '72vh' }}>
						<LoadScreen className='m-auto' />
					</div>
				);
			} else if (this.state.ownerList.length == 0) {
				torender = <div style={{ textAlign: 'center' }}>No Owners to View</div>;
			}
		} else if (this.state.selectedList === 'Vendor') {
			if (this.state.vendorList === null) {
				torender = (
					<div style={{ height: '72vh' }}>
						<LoadScreen className='m-auto' />
					</div>
				);
			} else if (this.state.vendorList.length == 0) {
				torender = (
					<div style={{ textAlign: 'center' }}>
						No Service Providers to View
					</div>
				);
			}
		}

		return (
			<Modal.Body>
				<div className=''>
					<div className='pt-4' id=''>
						<div
							className='header-sticky'
							style={{ zIndex: 2, backgroundColor: 'white' }}
						>
							<div className='text-center pb-2'>
								{this.props.user.type == 'Manager' ? (
									<>
										<Button
											onClick={() => this.setState({ selectedList: 'Tenant' })}
											className={
												'mr-2 ' +
												(this.state.selectedList === 'Tenant' ? 'selected' : '')
											}
										>
											Tenants
										</Button>
										<Button
											onClick={() => this.setState({ selectedList: 'Owner' })}
											className={
												'mr-2 ' +
												(this.state.selectedList === 'Owner' ? 'selected' : '')
											}
										>
											Owners
										</Button>
										<Button
											onClick={() => this.setState({ selectedList: 'Vendor' })}
											className={
												this.state.selectedList === 'Vendor' ? 'selected' : ''
											}
										>
											Service Providers
										</Button>
									</>
								) : null}
							</div>
							<div id='searchBarContainer' className='pt-3'>
								<Searchbar
									onChange={e => this.setSearch(e.target.value)}
									value={this.state.searchValue}
									className='propSearchbar mb-4'
								/>
							</div>
						</div>
						{torender !== undefined ? (
							torender
						) : (
							<Listing
								newThread={this.props.newThread}
								toggleContact={this.props.toggleContact}
								selectContacts={this.props.selectContacts}
								selectedList={this.state.selectedList}
								tenantList={this.state.tenantList}
								ownerList={this.state.ownerList}
								vendorList={this.state.vendorList}
								searchValue={this.state.searchValue}
								userType={this.props.user.type}
							/>
						)}
					</div>
					<br />
				</div>
			</Modal.Body>
		);
	}
}

/*The component that renders the listing cards for each list */
const Listing = ({
	toggleContact,
	selectContacts,
	newThread,
	selectedList,
	tenantList,
	ownerList,
	vendorList,
	searchValue,
	userType
}) => {
	return (
		<div>
			{selectedList === 'Tenant' ? (
				<>
					{selectContacts
						.filter(
							person =>
								person.type != 'Tenant' &&
								String(
									person.fname + ' ' + person.lname + ' ' + person.email + ' '
								)
									.toLowerCase()
									.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => {
							if (person.type === 'Manager' || person.type === 'Employee') {
								return (
									<React.Fragment key={person.email + person.type}>
										<EmployeeListing
											toggleContact={toggleContact}
											newThread={newThread}
											person={person}
											searchValue={searchValue}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={person.email + person.type}>
										<OwnerListing
											toggleContact={toggleContact}
											newThread={newThread}
											person={person}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							}
						})}

					{tenantList
						.filter(person =>
							String(
								person.fname +
									' ' +
									person.lname +
									' ' +
									person.email +
									' ' +
									property(person.propnum, person.propst, person.uname)
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => (
							<React.Fragment key={person.email + person.type}>
								<TenantListing
									key={personKey}
									toggleContact={toggleContact}
									newThread={newThread}
									tenant={person}
									selectContacts={selectContacts}
									userType={userType}
								/>
								<br></br>
							</React.Fragment>
						))}
				</>
			) : selectedList === 'Owner' ? (
				<>
					{selectContacts
						.filter(
							person =>
								person.type != 'Owner' &&
								String(
									person.fname + ' ' + person.lname + ' ' + person.email + ' '
								)
									.toLowerCase()
									.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => {
							if (person.type === 'Manager' || person.type === 'Employee') {
								return (
									<React.Fragment key={person.email + person.type}>
										<EmployeeListing
											key={personKey}
											toggleContact={toggleContact}
											newThread={newThread}
											person={person}
											searchValue={searchValue}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={person.email + person.type}>
										<TenantListing
											key={personKey}
											toggleContact={toggleContact}
											newThread={newThread}
											tenant={person}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							}
						})}

					{ownerList
						.filter(person =>
							String(
								person.fname + ' ' + person.lname + ' ' + person.email + ' '
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => (
							<React.Fragment key={person.email + person.type}>
								<OwnerListing
									key={personKey}
									toggleContact={toggleContact}
									newThread={newThread}
									person={person}
									selectContacts={selectContacts}
									userType={userType}
								/>
								<br></br>
							</React.Fragment>
						))}
				</>
			) : (
				<>
					{selectContacts
						.filter(
							person =>
								person.type != 'Manager' &&
								person.type != 'Employee' &&
								String(
									person.fname + ' ' + person.lname + ' ' + person.email + ' '
								)
									.toLowerCase()
									.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => {
							if (person.type === 'Owner') {
								return (
									<React.Fragment key={person.email + person.type}>
										<OwnerListing
											key={personKey}
											toggleContact={toggleContact}
											newThread={newThread}
											person={person}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={person.email + person.type}>
										<TenantListing
											key={personKey}
											toggleContact={toggleContact}
											newThread={newThread}
											tenant={person}
											selectContacts={selectContacts}
											userType={userType}
										/>
										<br></br>
									</React.Fragment>
								);
							}
						})}

					{vendorList
						.filter(person =>
							String(
								person.fname + ' ' + person.lname + ' ' + person.email + ' '
							)
								.toLowerCase()
								.includes(searchValue.toLowerCase())
						)
						.map((person, personKey) => (
							<React.Fragment key={person.email + person.type}>
								<VendorListing
									key={personKey}
									searchValue={searchValue}
									toggleContact={toggleContact}
									newThread={newThread}
									person={person}
									selectContacts={selectContacts}
									userType={userType}
								/>
								<br></br>
							</React.Fragment>
						))}
				</>
			)}
		</div>
	);
};

/* Card for vendors*/
export const VendorListing = ({
	toggleContact,
	searchValue,
	newThread,
	person,
	selectContacts,
	userType,
	toggleEmployeeContact,
	selectedEmployee
}) => {
	if (person.employees.length > 0)
		return (
			<Card className='mb-2 displayCard'>
				<div style={{ cursor: 'pointer' }}>
					<Collapse ghost defaultActiveKey={['1']} onChange={callback}>
						<Panel
							header={
								<Card
									bordered={false}
									className='mb-2 displayCard'
									style={{ margin: 'auto' }}
								>
									<div className=' cardMain'>
										<Avatar src={person.imageurl} size={38} className='mr-2' />
										<div className='cardLeft'>
											<div className='cardHeader'>
												<div className='cardTitle'>
													{person.fname} {person.lname} - {person.role}
												</div>
											</div>
											{userType === 'Manager' ? (
												<div className='cardLeftFooter g-text-sm'>
													<div className='cardFooterItem'>{person.email}</div>
													<div className='cardFooterItem ml-4'></div>
												</div>
											) : null}
										</div>
									</div>

									{/* <div className='cardRight mobileRow'>
										<div className='cardRightHeader'></div>
										<div className='cardRightFooter g-text-sm '></div>
									</div> */}
								</Card>
							}
						>
							<EmployeeList
								newThread={newThread}
								toggleContact={toggleContact}
								selectedVendors={selectContacts}
								// selectedList={selectedList}
								employeeList={person.employees}
								searchValue={searchValue}
								selectContacts={selectContacts}
								userType={userType}
								companyFName={person.fname}
								companyLName={person.lname}
							/>
						</Panel>
					</Collapse>
				</div>
			</Card>
		);
	else return null;
};

/* Card for owners*/
const OwnerListing = ({
	toggleContact,
	newThread,
	person,
	selectContacts,
	userType
}) => {
	return (
		<div
			className={selectContacts.includes(person) ? 'selectedBlue' : ''}
			style={{ cursor: 'pointer' }}
			onClick={() => toggleContact(person)}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar src={person.imageurl} size={38} className='mr-2' />
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname}
							</div>
						</div>
						{userType === 'Manager' ? (
							<div className='cardLeftFooter g-text-sm'>
								<div className='cardFooterItem'>
									{person.email} - {person.type}
								</div>
								<div className='cardFooterItem ml-4'></div>
							</div>
						) : null}
					</div>
				</div>

				{/* <div className='cardRight mobileRow'>
					<div className='cardRightHeader caption'>
						{property(person.propnum, person.propst, null)}
					</div>
					<div className='cardRightFooter g-text-sm '></div>
				</div> */}
			</Card>
		</div>
	);
};

/* Card for tenants */
const TenantListing = ({
	toggleContact,
	newThread,
	tenant,
	selectContacts,
	userType
}) => {
	return (
		<div
			className={selectContacts.includes(tenant) ? 'selectedGreen' : ''}
			style={{ cursor: 'pointer' }}
			onClick={() => toggleContact(tenant)}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar src={tenant.imageurl} size={38} className='mr-2' />
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{tenant.fname} {tenant.lname}
							</div>
						</div>
						{userType === 'Manager' ? (
							<div className='cardLeftFooter g-text-sm'>
								<div className='cardFooterItem'>
									{tenant.email} - {tenant.type}
								</div>
								<div className='cardFooterItem ml-4'></div>
							</div>
						) : null}
					</div>
				</div>
				{/* <div className='cardRight mobileRow'>
					<div className='cardRightHeader caption'>
						{property(tenant.propnum, tenant.propst, tenant.uname)}
					</div>
					<div className="cardRightFooter g-text-sm "></div>
				</div> */}
			</Card>
		</div>
	);
};
export const EmployeeListing = ({
	toggleContact,
	newThread,
	person,
	userType,
	vendorList,
	selectContacts
}) => {
	// console.log(person.imageurl);
	return (
		<div
			className={selectContacts.includes(person) ? 'selectedOrange' : ''}
			style={{ cursor: 'pointer' }}
			onClick={() => toggleContact(person)}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar src={person.imageurl} size={38} className='mr-2' />
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname} - {person.role}
							</div>
							<div className='cardContent g-text-sm'></div>
						</div>
						{userType === 'Manager' ? (
							<div className='cardLeftFooter g-text-sm'>
								<div className='cardFooterItem'>
									{person.email} - {person.companyFName} {person.companyLName}
								</div>
								<div className='cardFooterItem ml-4'></div>
							</div>
						) : null}
					</div>
				</div>

				{/* <div className='cardRight mobileRow'>
					<div className='cardRightHeader'></div>
					<div className='cardRightFooter g-text-sm '></div>
				</div> */}
			</Card>
		</div>
	);
};
const EmployeeList = ({
	toggleContact,
	vendorList,
	newThread,
	selectedList,
	selectContacts,
	searchValue,
	employeeList,
	userType,
	companyFName,
	companyLName
}) => {
	return (
		<div>
			{employeeList
				.filter(employee =>
					String(
						employee.fname +
							' ' +
							employee.lname +
							' ' +
							employee.email +
							' ' +
							employee.companyFName +
							' ' +
							employee.companyLName +
							' '
					)
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				)
				.map((employee, personKey) => {
					employee.companyFName = companyFName;
					employee.companyLName = companyLName;
					return (
						<EmployeeListing
							key={personKey}
							toggleContact={toggleContact}
							newThread={newThread}
							person={employee}
							vendorList={vendorList}
							searchValue={searchValue}
							selectContacts={selectContacts}
							userType={userType}
						/>
					);
				})}
		</div>
	);
};
export default Contacts;
