import React from 'react';
import { Form } from 'react-bootstrap';
import {
	InputNumber,
	Checkbox,
	Button,
	message,
	Alert,
	Radio,
	DatePicker,
	Select
} from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import { vendorProvinces } from '../../StringData/VendorObjects';
import RequestContext from '../MaintenanceRequest/RequestContext';
import CostOptions from '../../Modals/WorkOrder/Pages/CostOptions';

class AddLease extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = { value: '', propertyName: [] };
	}
	componentDidMount = () => {
		axios
			.post('/getPropertyName')
			.then(response => {
				console.log(response.data.propertyName);

				this.setState({ propertyName: response.data.propertyName });
			})
			.catch(err => {
				this.setError(err);
			});
	};
	setError = () => {
		return <div>err</div>;
	};
	closeError = () => this.setState({ errorMessage: null });

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	submitForm = () => {
		this.setState({ submitted: true });

		axios
			.post('/addLease', {
				propertyId: this.state.propertyId,
				propertyName: this.state.propertyAddress,
				unitName: this.state.unitName,
				leaseType: this.state.leaseType,
				rent: this.state.rent,
				deposit: this.state.deposit,
				moveInDate: this.state.moveInDate,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				// status: this.state.status,
				pets: this.state.pets,
				electricity: this.state.electricity,
				heat: this.state.heat,
				internet: this.state.internet
			})
			.then(response => {
				this.setState({ submitted: true });
				window.location.reload();
			});
	};

	render() {
		// let disabled = (this.state.homeStreet === '' || this.state.homeNumber === '' || this.state.city === '' || this.state.postalCode === '' || this.state.fname === '')
		console.log(this.state.propertyName);
		if (this.state.submitted) {
			return <h3>New Lease Added! </h3>;
		}
		return (
			<div>
				<Form>
					<Form.Row>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Lease Type </Form.Label>
							<select
								name='leaseType'
								className='form-control'
								onChange={e => this.setState({ leaseType: e.target.value })}
							>
								<option value={''}>Select...</option>
								<option value='Fixed Term'>Fixed Term</option>
								<option value='Month-to-month'>Month-to-month</option>
								<option value='Daily'>Daily</option>
								<option value='Weekly'>Weekly</option>
							</select>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Rent </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='rent'
								type='text'
								value={this.state.rent || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Deposit </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='deposit'
								type='text'
								value={this.state.deposit || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Move in Date </Form.Label>
							<DatePicker
								onChange={(date, dateString) => {
									this.setState({ moveInDate: dateString });
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Start Date</Form.Label>
							<DatePicker
								onChange={(date, dateString) => {
									this.setState({ startDate: dateString });
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>End Date</Form.Label>
							<DatePicker
								onChange={(date, dateString) => {
									this.setState({ endDate: dateString });
								}}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Property</Form.Label>
							<Select
								className='form-control'
								// style={{ width: 150 }}
								placeholder='Select..'
								options={this.state.propertyName.map((property, index) => ({
									key: index,
									label: property.propertyName,
									value: property.propertyid
								}))}
								onChange={(index, selectedProperty) => {
									console.log(selectedProperty.label, selectedProperty.value);
									this.setState({
										propertyId: selectedProperty.value,
										propertyAddress: selectedProperty.label
									});
								}}
							></Select>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={4}>
							<Form.Label className='form-label'>Unit </Form.Label>
							<select
								name='unitName'
								className='form-control'
								onChange={e => this.setState({ unitName: e.target.value })}
							>
								<option value={''}>Select...</option>
								<option value='Default'>Default</option>
								<option value='A'>A</option>
								<option value='B'>B</option>
								<option value='C'>C</option>

								<option value='D'>D</option>
								<option value='E'>E</option>
							</select>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Check
							style={{ marginRight: 15 }}
							className='form-text'
							name='pets'
							label='Pets'
							type='checkbox'
							onChange={e => this.setState({ pets: e.target.checked })}
						/>

						<Form.Check
							style={{ marginRight: 15 }}
							className='form-text'
							name='electricity'
							label='Electricity'
							type='checkbox'
							onChange={e => this.setState({ electricity: e.target.checked })}
						/>

						<Form.Check
							style={{ marginRight: 15 }}
							className='form-text'
							name='heat'
							label='Heat'
							type='checkbox'
							onChange={e => this.setState({ heat: e.target.checked })}
						/>

						<Form.Check
							className='form-text'
							name='internet'
							label='Internet'
							type='checkbox'
							onChange={e => this.setState({ internet: e.target.checked })}
						/>
					</Form.Row>

					<br></br>
					<div className='text-right'>
						<Button onClick={this.submitForm} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default AddLease;
