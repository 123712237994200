import React from "react";
import { Form } from "react-bootstrap";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from "axios";

import "./taskStyle.css";

import { Select, Space, Checkbox, Alert, InputNumber } from "antd";
import {
  Input,
  Button,
  Table,
} from "antd";

import {
	DeleteOutlined,
	EditOutlined,
  } from '@ant-design/icons';

const cancelToken = axios.CancelToken.source();

const { Option } = Select;
const { TextArea } = Input;

let questionaire = {
	"checklist": "",
      "description": "Questionaire for owners with no PM",
      "dueDate": "",
      "isCompleted": "False",
      "numSteps": 1,
      "numStepsCompleted": 0,
      "prerequisiteTasks": "GeneralPropertyInfo",
      "priority": "",
      "subtasks": {          },
      "taskId": "registerQuestionaire",
      "taskName": "Register Questionaire",
      "taskObjectName": "3_registerQuestionaire",
      "type": "intake/PropertyReg/registerQuestionaire"
}

let rules = {
	"checklist": "",
	"description": "Get info like address and DOB",
	"dueDate": "",
	"isCompleted": "False",
	"prerequisiteTasks": "GeneralPropertyInfo",
	"priority": "",
	"numSteps": 1,
	"numStepsCompleted": 0,
	"subtasks": {          },
	"taskId": "createRules",
	"taskName": "Register Rules for property",
	"taskObjectName": "4_createRules",
	"type": "intake/PropertyReg/createRules"
}

let owners = {
	"checklist": "",
	"description": "Setting up owners",
	"dueDate": "",
	"isCompleted": "False",
	"priority": "",
	"numSteps": 1,
	"numStepsCompleted": 0,
	"subtasks": {          },
	"taskId": "registerOwners",
	"taskName": "Register Owners",
	"taskObjectName": "6_registerOwners",
	"type": "intake/PropertyReg/registerOwners"
}

let tenants = {
	"checklist": "",
	"description": "Setting up tenants",
	"dueDate": "",
	"isCompleted": "False",
	"prerequisiteTasks": "GeneralPropertyInfo",
	"priority": "",
	"numSteps": 1,
	"numStepsCompleted": 0,
	"subtasks": {          },
	"taskId": "registerTenants",
	"taskName": "Register Tenants",
	"taskObjectName": "5_registerTenants",
	"type": "intake/PropertyReg/registerTenants"
}

function completeTask(updateFunction, taskData, unitTask = null, state) {
  let newTaskData = JSON.parse(JSON.stringify(taskData));
  let updates = [];
  let deletes = [];
  newTaskData.isCompleted = "true";
  
  if(state){
		if (typeof unitTask !== "undefined" && unitTask !== null){
		//updateFunction(newTaskData, taskData.topLayerId, unitTask);
			updates.push(unitTask)
			//updates.push(rules)
		}
		else
			deletes.push("registerUnits")
		//updateFunction(newTaskData);
		
		if(state.areTenants){
			rules["propertyID"] = taskData.propertyID;
			tenants["propertyID"] = taskData.propertyID;
			owners["propertyID"] = taskData.propertyID;
			updates.push(rules)
			updates.push(tenants)
			//updates.push(owners)
		}

		/*if(state.isOwner && !state.isPM){
			//updateFunction(newTaskData, taskData.topLayerId, questionaire)
			questionaire["propertyID"] = taskData.propertyID;
			updates.push(questionaire)
			deletes.push("registerQuestionaire")
			
		}

		if(!state.areTenants){
			deletes.push("createRules")
			deletes.push("registerTenants")
		}

		if(state.areTenants && state.isPM && state.isOwner ) {
			//updateFunction(newTaskData, taskData.topLayerId, rules)
			//updateFunction(newTaskData, taskData.topLayerId, tenants)
			rules["propertyID"] = taskData.propertyID;
			tenants["propertyID"] = taskData.propertyID;
			updates.push(rules)
			updates.push(tenants)
			deletes.push("registerOwners")
		}
		else if (state.areTenants && state.isPM && !state.isOwner){
			//updateFunction(newTaskData, taskData.topLayerId, rules)
			//updateFunction(newTaskData, taskData.topLayerId, tenants)
			//updateFunction(newTaskData, taskData.topLayerId, owners)
			rules["propertyID"] = taskData.propertyID;
			tenants["propertyID"] = taskData.propertyID;
			owners["propertyID"] = taskData.propertyID;
			updates.push(rules)
			updates.push(tenants)
			updates.push(owners)
			deletes.push("registerQuestionaire")
		}*/

		updateFunction(newTaskData, taskData.topLayerId, updates, deletes)
	}
	else {
		updateFunction(newTaskData)
	}
  
  //updateFunction(newTaskData)
  
  
  
	

  return true;
}

//--------------------------------------------------UNITS
class Units extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			unitName: {},
			floors: 0,
			perLevel: 0,
			total: 0,
			floorLevels: [],
			stateUnits: {},
			errorMessage: null
			
		}
	}
	
	verify = () => {
		/*console.log(this.state.unitName)
		for(let f =0; f<this.state.unitName.length; f++){
			for(let u=0; u<this.state.unitName[f].length; u++){
				console.log(this.state.unitName[f][u])
				if(this.state.unitName[f][u] === ""){
					this.setState({errorMessage: 'Please enter a unit name'});
				}
			}
		}
		
		console.log('in verify')
		/*if(this.state.streetAddress === '') {
		  this.setState({errorMessage: 'Please enter a street address.'});
		  return false;
		}*/
		return true;
	}
	
	componentDidMount(){
		axios.post("/tenantInfo",{data: this.props.taskData}).then((response) => {
			let responseUnits = response.data.units;
			//this.setState({stateUnits: response.unit})
			let units = {};
			let levels = -1;
			let floors = [];	
			let tableUnit = [];
			
			for(let i=0; i<responseUnits.length; i++){
				let placement = responseUnits[i]["placementString"].split("f");
				//console.log(placement)
				
				if(placement[0] > levels){
					levels = placement[0]
				}
				
				if(units[placement[0]]){
					units[placement[0]].push(responseUnits[i]["unit_name"])
					
				}
				else{
					units[placement[0]] = [];
					units[placement[0]].push(responseUnits[i]["unit_name"])
				}
				
				
			}
			levels++
			
			
			for (let i =0; i<levels; i++){
				floors.push(<input type="number" name={i} defaultValue={units[i].length} onChange= {this.createUnit} />);
				
				tableUnit=[]
				for(let j=0; j< units[i].length; j++){
					tableUnit.push(<input name= {j + "f" + i} deaultValue={units[i][j]} onChange={this.updateUnit} />)
				}
				
				this.setState(prevState => ({
					stateUnits : {
						...prevState.stateUnits,
						[i]: tableUnit
					}
				}))

			}
		
			this.setState({floorLevels: [...this.state.floorLevels, floors]});
			//this.setState({stateUnits: [...this.state.stateUnits: tableUnit]})
			
			this.setState({unitName: units, floors: levels})
			//onFloorChange()
			//this.setState({floors: levels})
			
			
		})
	}
	
	submitForm = () => {
		if(!this.verify()) return
		let total = 0;
		for(let f =0; f<this.state.unitName.length; f++){
			for(let u=0; u<this.state.unitName[f].length; u++){
				total++;
			}
		}
		this.setState({total: total})
		
		
		axios
		.post("/registerUnit", {
			unitName : this.state.unitName,
			total: this.state.total,
			data: this.props.taskData
		})
		.then((response) => {
      //Say its complete!
      completeTask(this.props.updateTaskData, this.props.taskData, response.data.unitTask);
	  //console.log(this.state)
		})
		.catch((err) => console.log(err));
	};
	
	onFloorChange = event => {
		let floors = [];
		
		for (let i =0; i<event.target.value; i++){
			//floors.push(<input type="number" name={i} onChange= {this.createUnit} />); placeholder={"Floor " + (i+1)}
			floors.push(<Form.Control key={i} as="input" type="number" defaultValue={i < this.state.floorLevels.length ? this.state.floorLevels[i] : ""}  name={i} placeholder={"Enter the amount of units on this floor"} onChange= {this.createUnit} /> )
			///this.setState({floorLevels: floors});
		}

		if (event.target.value < this.state.floors) {
			let newSU = {...this.state.stateUnits};
			Object.keys(this.state.stateUnits).forEach(function(val){
				if (parseInt(val) >= event.target.value)
					delete newSU[val];
			});
			this.setState({stateUnits: newSU});
		}
		
		this.setState({floors: event.target.value, floorLevels: floors});
	}
	
	
	createUnit = event => {
		let units = [];
		let name = event.currentTarget.name;
		
		for(let i=0; i<event.target.value; i++){
			//units.push(<input key={event.currentTarget.name + "f" + i} name= {event.currentTarget.name + "f" + i} onChange={this.updateUnit} />  );
			units.push(<Form.Control as="input" placeholder={"Unit " + (i+1)} key={event.currentTarget.name + "f" + i} name= {event.currentTarget.name + "f" + i} onChange={this.updateUnit} /> )
		}
		
		this.setState(prevState => ({
			stateUnits : {
				...prevState.stateUnits,
				[name]: units
			}
		}))
		//this.setState({stateUnits : })
		
	}
	
	createTable = () => {
		let table = [];
		
		table.push(<th> Floors </th>)
		table.push(<th> Units On This Floor </th>)
		table.push(<th> Unit Names </th>)
		
		for(let i =0; i< this.state.floors; i++){
			let children = [];
			children.push(<td key={"f"+i}> Floor {i+1} </td>)
			children.push(<td key={i}> {this.state.floorLevels[i]} </td>)
			
			if(this.state.stateUnits[i]){
				let temp = <td key={i+"f"}>{this.state.stateUnits[i]}</td>
				children.push(temp);
			}
			
			table.push(<tr key ={i}>{children}</tr>);
			
			//{this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}
			
			
		}
		return table
	}
	
	updateUnit = event => {
		let string = event.currentTarget.name;
		let name = string.substring(0, string.indexOf('f'));
		let unitNum = string.substring(string.indexOf('f')+1, string.length);
		//console.log(string, name, unitNum)
		
		let temp = this.state.unitName[name];
		
		if(!temp){
			temp = [];
		}
		temp[unitNum] = event.target.value;
		//console.log(temp);
		
		this.setState(prevState => ({
			unitName : {
				...prevState.unitName,
				[name]: temp
			}
		}))
		//console.log(this.state)
	} 
	
	render(){
		
		return(
			<div>
				<h3 className='title'>Register Unit info</h3>
				<Form>
				
					<Form.Label className='form-label'>How many levels</Form.Label>
					<Form.Control
						required
						className='form-text'
						name='floors'
						type='number'
						min={0}
						value={this.state.floors}
						onChange={this.onFloorChange}
						/>
					
					<br/>
					<Form.Group as={Col}>
					<table>
						<tbody>
							{this.createTable()}
						</tbody>
					</table>
						
					</Form.Group>
					
					<div>
						
					</div>
					
					
					
					<br/>
					<br/>
					<Button onClick={this.submitForm}>Submit</Button>
				
				</Form>
				
				
			</div>
		);
	}
}

class Questionaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      goal: "",
      use: "",
      notes: "",
    }
  }
  
  componentDidMount(){
	  axios.post("/getQuestionaire",{data: this.props.taskData}).then((response) => {
		 // console.log(response.data)
		  let questionaire = JSON.parse(response.data[0]["questionaire"])
		  
		  this.setState({type:questionaire[0], goal:questionaire[1], use:questionaire[2], notes:questionaire[3]})
		  
	  })
  }

  //Submitting form
  submitForm = () => {
    axios
      .post("/registerQuestionaire", {
        answers: [
          this.state.type,
          this.state.goal,
          this.state.use,
          this.state.notes,
        ],
        data: this.props.taskData,
      })
      .then((response) => {
        //Say its complete!
        completeTask(this.props.updateTaskData, this.props.taskData);
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div>
        <h3 className="title">Register Info</h3>
        <Form>
          <Form.Group>
            <p> Please enter the following infomation about yourself</p>

            <Form.Label className="form-label">Type</Form.Label>
            <Select
              name="type"
              value={this.state.type}
              onChange={(e) => this.setState({ type: e })}
            >
              <Option value="Rental"> Rental </Option>
              <Option value="Commerical"> Commerical </Option>
              <Option value="Other"> Other </Option>
            </Select>

            <Form.Label className="form-label">
              Goal for the property
            </Form.Label>
            <Form.Control
              required
              className="form-text"
              name="goal"
              type="text"
              //defaultValue={this.state.goal}
			  value={this.state.goal}
              onChange={(e) => this.setState({ goal: e.target.value })}
            />

            <Form.Label className="form-label">
              Why are you using this service
            </Form.Label>
            <Form.Control
              required
              className="form-text"
              name="use"
              type="text"
			  value={this.state.use}
              //defaultValue={this.state.postalCode}
              onChange={(e) => this.setState({ use: e.target.value })}
            />

            <Form.Label className="form-label">Any other info</Form.Label>
            <TextArea rows={4} value={this.state.notes} onChange={(e) => this.setState({ notes: e.target.value })} />
          </Form.Group>
          <br></br>
          <Button onClick={this.submitForm}>Submit</Button>
        </Form>
      </div>
    );
  }
}

class Lease extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rules: [],
			ruleName: "",
			description: "",
			unitsAffected: [],
			unitList: [],
			allUnits: true,
		};
		this.submitRule = this.submitRule.bind(this);
		this.submitList = this.submitList.bind(this);
	}
	
	componentDidMount(){
		axios.post("/tenantInfo", {data: this.props.taskData}).then((response) => {
			let data = response.data
			this.setState({unitList : [...this.state.unitList, data["units"] ]  });  
			this.createUnits();
		})
	}
	
	createUnits = () => {
		let options = [];

		if(!this.state.unitList){
			return options;
		}
		
		if(this.state.unitList[0]){
			let data = this.state.unitList[0];
			for (let i=0; i<this.state.unitList[0].length; i++){
				options.push(<option key = {this.state.unitList[0][i].unit_id} name = {this.state.unitList[0][i].unit_name} > {this.state.unitList[0][i].unit_name} </option>)
			}
		}
		return options;
	}

	submitRule() {
		this.setState({ rules: [ ...this.state.rules, { ruleName: this.state.ruleName, description: this.state.description, unitsAffected: this.state.unitsAffected, },], });

		this.setState({
			ruleName: "",
			description: "",
			unitsAffected: [],
			allUnits: true,
			
		});
	}

	submitList() {
		axios
		.post("/registerLease", {//need to get the rules by unit ID, then create the list
			rules: this.state.rules,
			data: this.props.taskData,
		})
		.then((response) => {});
	}

	deleterow = (e) => {
		let id = e.currentTarget.dataset.id;
		this.setState({
			rules: this.state.rules.filter((element) => element.email !== id),
		});
		this.setState({
			ruleName: "",
			description: "",
			unitsAffected: [],
			allUnits: true,
		});
	};

	editRow = (e) => {
		let id = e.currentTarget.dataset.id;
		let recordChange = this.state.tenantlist.filter( (element) => element.email === id );
		this.setState({
			ruleName: recordChange[0].ruleName,
			description: recordChange[0].description,
		});
	};

	render() {
		const columns = [
			{ title: "Rule Name", dataIndex: "ruleName", key: "ruleName" },
			{ title: "Description", dataIndex: "description", key: "description" },
			{ title: "Units Affected", dataIndex: "unitsAffected", key: "unitsAffected" },
			{
				title: "Action",
				key: "action",
				render: (text, record) => (
				<Space size="middle">
					<a data-id={record.ruleName} onClick={this.deleterow}>
						<DeleteOutlined />
					</a>
				</Space>
				),
			},
			{
				render: (text, record) => (
				<Space size="middle">
					<a data-id={record.ruleName} onClick={this.editRow}>
						<EditOutlined />
					</a>
				</Space>
				),
			},
		];
		
		let unitSelectory = "";
		if(this.state.allUnits){
			unitSelectory = ""//this is a coment
		}
		else {
			unitSelectory = <div><Form.Label>Units affected (hold SHIFT to select mulitple)</Form.Label> <select className="form-control" multiple onChange={(e) => this.setState({ unitsAffected: e.target })} deafultvalue = {this.state.unitsAffected}> {this.createUnits()} </select></div>
		}

		return (
			<div>
				<h2> Create Rules </h2>
				<p> Description </p>
				<br />
				<br />

				<form>
					<Form.Row>
					<Form.Group as ={Col}>
						<Form.Label>Rule Name</Form.Label>
						<Form.Control
							as="input"
							placeholder="Rule Name"
							value={this.state.ruleName}
							onChange={(e) => this.setState({ ruleName: e.target.value })}
						/>

						<Form.Label>Description</Form.Label>
						<Form.Control
							as="input"
							placeholder="Description"
							value={this.state.description}
							onChange={(e) => this.setState({ description: e.target.value })}
						/>
					</Form.Group>
					</Form.Row>
					
					<Form.Row>
						<Form.Group as ={Col} md={4}>
							<Checkbox checked={this.state.allUnits} onChange = {(e) => {this.setState({allUnits: e.target.checked})}} > This Rule applies to all units </Checkbox>
						</Form.Group>
						
						<Form.Group as ={Col}>
							{unitSelectory}
						</Form.Group>
					</Form.Row>
					
					<div className="d-flex">
						<div className="ml-auto p-2"> 
							<Button className="btn-info btn pull-right" type="primary" onClick={this.submitRule}>
								Add
							</Button>
						</div>
					</div>
				</form>

				<div>
					<Table columns={columns} dataSource={this.state.rules} />{" "}
				</div>
				
				
				<Button onClick={this.submitList}>
					{" "}
					Submit{" "}
				</Button>
			</div>
		);
	}
}

export {Units, Questionaire, Lease };
