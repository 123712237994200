import React from 'react';
import { Modal } from 'react-bootstrap';
import Task from '../../../Components/Tasks/Task';
import Transactions from '../../../Modals/Transactions/Transactions';
import RequestContext from '../RequestContext';
import { message } from 'antd';

class SettlingFinanceAction extends React.Component {
	static contextType = RequestContext;

	constructor(props) {
		super();
		this.state = {
			transactionModal: false
		};
	}
	openTransactionModal = () => {
		this.setState({ transactionModal: !this.state.transactionModal });
	};
	completeTransactions = () => {
		this.openTransactionModal();
		this.context.updateStatus('Complete');
		message.success(
			'Successfully completed and closed the maintenance request'
		);
	};
	sendTransactions = (newStatus, note) => {
		this.setState({ transactionModal: false });
		this.context.addActivity(note, null, true);
		if (newStatus === null)
			message.success('Transaction progress has been saved');
		else if (newStatus === 'Complete') {
			this.context.updateStatus(newStatus);
			message.success('Work Order has successfully been closed');
		}
	};
	render() {
		if (this.context.user.type === 'Manager')
			return (
				<div>
					{/* <Task>
						<Task.Title>Enter transactions from the work order</Task.Title>

						<Task.Action primary onClick={this.openTransactionModal}>
							Enter Transactions
						</Task.Action>
					</Task>

					<Modal
						show={this.state.transactionModal}
						onHide={this.openTransactionModal}
						aria-labelledby='workorder-modal'
						centered
					>
						{this.state.transactionModal ? (
							<Transactions
								job_id={this.context.request.job_id}
								vendor={this.context.request.vendors}
								onHide={this.openTransactionModal}
								sendTransactions={this.sendTransactions}
							/>
						) : (
							''
						)}
					</Modal> */}
				</div>
			);
		else return '';
	}
}

export default SettlingFinanceAction;
