import React, { useState } from "react";
import "./searchbar.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { SearchOutlined } from "@ant-design/icons";

export function Searchbar({ value, onChange, className }) {
  return (
    <InputGroup size="sm" className={className}>
      <FormControl
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
        value={value}
        onChange={onChange}
        className="noHighlight sbh32"
      />
      <InputGroup.Append>
        <InputGroup.Text id="inputGroup-sizing-sm">
          <SearchOutlined />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

export function SearchbarManual({
  searchValue,
  onClick,
  className,
  height = null,
  placeholder = "Search",
}) {
  const [value, setValue] = useState(searchValue);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onClick(value);
    }
  };

  return (
    <InputGroup size="sm" className={className}>
      <FormControl
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
        value={value}
        onKeyDown={handleKeyDown}
        className="noHighlight sbh32"
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
      <InputGroup.Append>
        <Button onClick={() => onClick(value)}>
          <SearchOutlined />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
