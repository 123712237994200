import React from 'react';
import JobRequestAction from './actions/JobRequestAction';
import SchedulingAction from './actions/SchedulingAction';

import {
	WorkOrderAction,
	workOrderWorkDone,
	WorkOrderTroubleshootAction,
	WorkOrderWorkDoneAction,
	WorkOrderManageAction
} from './actions/WorkOrderAction';

import WorkEntryAction from './actions/WorkEntryAction';

import WorkOrderReviewAction from './actions/WorkOrderReviewAction';
import SettlingFinanceAction from './actions/SettlingFinanceAction';
import InMarketAction from './actions/InMarketAction';
import PendingExtensionAction from './actions/PendingExtensionAction';
import OnHoldAction from './actions/OnHoldAction';
import Task from '../../Components/Tasks/Task';

/*
Controls which component displays in the action center.
*/
const getActions = context => {
	console.log(context);
	return [
		/*"Work Order - Scheduled", "Work Order - Work Done", "Work Order - Review", "Invoicing", "On Hold", "In Market"*/
	].includes(
		context.user.type === 'Manager'
			? context.request.job_status
			: context.activeEvent?.event_status
	)
		? StatusComponents['ACMaintenance']
		: StatusComponents[
				context.user.type === 'Manager'
					? context.request.job_status
					: context.activeEvent?.event_status
		  ];
	//return StatusComponents[request.job_status];
};

const StatusComponents = {
	ACMaintenance: (
		<div>
			<Task>
				<Task.Title>
					Action Center is under maintenance. We are working hard to get it
					working for you!
				</Task.Title>
			</Task>
		</div>
	),
	'Job Request': <JobRequestAction />,
	Scheduling: <SchedulingAction />,
	'Work Order - Scheduled': <WorkEntryAction />,
	'Work Order - Work Done': <WorkOrderWorkDoneAction />,
	'Work Order - Troubleshoot': <WorkOrderTroubleshootAction />,
	'Manage Work Order': <WorkOrderManageAction />,
	'Work Order - Review': <WorkOrderReviewAction />,
	Invoicing: <SettlingFinanceAction />,
	'Pending Extension': <PendingExtensionAction />,
	'On Hold': <OnHoldAction />,
	'In Market': <InMarketAction />
};

export default getActions;
