/* Used in finance page to export airbnb finance data */

const FinanceExportHeaders = [
  {
    label: "Date",
    key: "trans_date",
  },
  {
    label: "Type",
    key: "trans_type",
  },
  {
    label: "Confirmation Code",
    key: "confirmation_code",
  },
  {
    label: "Start Date",
    key: "start_date",
  },
  {
    label: "Nights",
    key: "num_nights",
  },
  {
    label: "Guest",
    key: "guest_name",
  },
  {
    label: "Listing",
    key: "listing_name",
  },
  {
    label: "Details",
    key: "trans_details",
  },
  {
    label: "Reference",
    key: "reference",
  },
  {
    label: "Currency",
    key: "currency",
  },
  {
    label: "Amount",
    key: "trans_amount",
  },
  {
    label: "Paid Out",
    key: "paid_out",
  },
  {
    label: "Host Fee",
    key: "host_fee",
  },
  {
    label: "Cleaning Fee",
    key: "cleaning_fee",
  },
  {
    label: "Resort Fee",
    key: "resort_fee",
  },
];

export default FinanceExportHeaders;
