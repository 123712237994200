import React from 'react';

function LoginLayout({ children, header = '' }) {
	return (
		<div className='h100'>
			<div id='loginForm' className='d-flex flex-column split left h100'>
				<div className='h100 d-flex flex-column'>
					<h1 className='flex-20 signTitle'>{header}</h1>
					<div
						// style={{ backgroundColor: '#f8fafb' }}
						id='content'
						className=' d-flex flex-column center flex-80 h100'
					>
						<div className='d-flex flex-column h100'>{children}</div>
					</div>
				</div>
				<div id='jumbotron' className='split right'>
					<img
						src={require('../../images/houseExample4.jpg')}
						className='backgroundimg'
					/>
				</div>
			</div>
		</div>
	);
}

export default LoginLayout;
