import React, { Component } from "react";
import axios from "axios";

import "./WorkOrder.css";
import {IssuesCloseOutlined,ArrowLeftOutlined,FileDoneOutlined, PauseCircleOutlined, PieChartOutlined, RightCircleOutlined, ToolOutlined, UploadOutlined } from "@ant-design/icons";
import {createUseStyles} from 'react-jss'
import Troubleshoot from "./Pages/Troubleshoot.js";
import Images from "./Pages/Images.js";
import Entry from "./Pages/Entry.js";
import Materials from "./Pages/Materials.js";
import EndSession from "./Pages/EndSession";
import Action from "./Pages/Action.js";
import Labour from "./Pages/Labour.js";
import CostOptions from "./Pages/CostOptions.js";
import Expenses from "./Pages/Expenses.js";
import FollowUp from "./Pages/FollowUp.js";
import ErrorPage from "./Pages/ErrorPage.js";
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext';
import MenuScreen from './Pages/MenuScreen.js'
import WorkOrderContext from './WorkOrderContext'
import LoadScreen from '../../Components/LoadScreen'
import PauseWorkOrder from "./Pages/PauseWorkOrder";
import {Modal} from 'antd'
const cancelToken = axios.CancelToken.source();

/*
/workOrder/save/troubleshoot, {description, images}
/workOrder/save/action, {action_time, action_desc}


*/


const PageComponents = {
  "Entry": <Entry />,
  "Menu": <MenuScreen/>,
  "Troubleshoot": <Troubleshoot />,
  "Images": <Images />,
  "Materials": <Materials />,
  "Action": <Action />,
  "Labour": <Labour />,
  "CostOptions": <CostOptions />,
  "Expenses": <Expenses />,
  "FollowUp": <FollowUp />,
  "ErrorPage": <ErrorPage />,
  "Loading": <LoadScreen />,
  "Pause":<PauseWorkOrder/>
}

const WorkOrderClasses = createUseStyles({

  backBtn:{
    display:"inline-block",
    marginRight:10
  },
  cardContainer:{
    width:'90%',
    height:70,
    padding:10,
    lineHeight:'50px',
    cursor:'pointer',
    marginBottom:'5px',
    '&:hover':{
      color:'#4d7cfe'
    }
  },
  color:{
      backgroundColor:'#e6f7ff',
      borderRadius: 5

  },
  rightCircle:{
    float:'right',
    display: 'inline-block',
    lineHeight:'56px'
  },
  footer:{
    borderTop:'thin solid #e3e3e3',
    textAlign:'right',
    paddingTop:'8px'
  },
})

class WorkOrder extends Component {
  static contextType = RequestContext;
  constructor(props) {
    super();
    this.state = {
      page: "Loading",
      getActiveEvent:()=>{},
      goToMainMenu: () => {
        this.setState({page:'Menu'})
      },
      changePage: (page) => this.setState({page:page}),
      updateEntryDetails: (auth="", time, tenant="",date) => {
        this.setState({
          entryData: { authType: auth, timeIn: time, tenant: tenant, date: date },
        });
      },
      updateTroubleshootData: (description, images) => {
        this.setState({
          troubleshootData: { description: description, images: images },
        });
      },
      updateMaterialData: (materialsNeeded, materials, cost) => {
        this.setState({
          materialData: {
            materialsNeeded: materialsNeeded,
            materialRows: materials,
            totalCost: cost,
          },
        });
      },
      updateLabourData: (costs, totalCost) => {
        this.setState({ labourData: { costList: costs, totalCost: totalCost } });
      },
      updateActionData: (time, action) => {
        this.setState({ actionData: { time: time, description: action } });
      },
      saveAndContinue: () => {
        this.setState({ followupData: { notes: "", nextStatus: "Continue" } });
        this.goNext();
      },
      startWorkOrder: (updatedRequest) => {
        axios
        .post("/jobRequest/update/status/workOrder/start", { job_id: this.context.request.job_id })
        .then((response) => {
          this.context.updateStatus("Work Order - Work Done");
          this.context.addActivity(response.data.note, null, true);
          this.props.onCancel();
        })
        .catch((error) => {
          console.log(error);
        });
        
      }
    };
  }

  componentDidMount() {
    axios
      .post("/workOrder/get/workOrder/" + this.context.request.job_id)
      .then((response) => {
        this.setState({
          jobData: {
            job_id: this.context.request.job_id,
            vendors: this.context.request.vendors,
            workOrders: this.context.activeEvent ? this.context.activeEvent.workOrders : [],
            session_id: this.context.session_id
          },
          status: this.context.request.status,
          tenantList: response.data.tenantList,
          page: this.props.startingPage || "Menu"
        });
      })
      .catch((err) => {
        this.setState({ currentPage: "errorPage",error:err });
      });
  }
  setEndStatus = (status) => {
    this.setState({ followupData: { notes: "", nextStatus: status } });
    this.goNext();
  };
  updateEndSession = (timeOut, totalKM, extensionNotes) => {
    this.setState(
      {
        endSessionData: {
          timeOut: timeOut,
          totalKM: totalKM,
        },
        followupData: {
          notes: extensionNotes,
          nextStatus: this.state.followupData.nextStatus,
        },
      },
      () => {
        this.totaldata();
      }
    );
  };

  saveAndContinue = () => {
    this.setState({ followupData: { notes: "", nextStatus: "Continue" } });
    this.goNext();
  };

  requestApproval = () => {
    this.setState({ followupData: { notes: "", nextStatus: "Approval" } });
    this.goEnd();
  };
  updateExpenses = (totalcost, taxInc, transactions) => {
    this.setState({
        expenseData: {
          totalCost: totalcost,
          taxIncluded: taxInc,
          transactions: transactions,
        },
      },
      this.totaldata
    );
  };

  updateFollowup = (sameJob, newJobs) => {
    this.setState({ followupData: { sameJob: sameJob, newJobs: newJobs } });
  };

  goExpenses = () => {
    //Check if allowed
    this.setState({ currentPage: "Expenses" });
  };

  totaldata = () => {
    let totaldata = this.state;
    axios.post("/saveworkOrder", totaldata).then((response) => {
      this.props.doneWorkOrder(response.data.newStatus, response.data.note);
    });
  };

  goEnd() {
    this.setState({ currentPage: "EndSession", end: true });
  }
  goFollowUp() {
    this.setState({ currentPage: "FollowUp" });
  }

  render() {
    
    return (
      <WorkOrderContext.Provider value={this.state}>

        <Modal 
          className='workOrderModal'
          title={
            <>
              {this.state.page !== "Entry" && this.state.page !== "Menu" ?
                <BackBtn goBack={this.state.goToMainMenu} /> 
                : ""
              }
              <div className='d-inline-block'>{workOrderTitle[this.state.page]}</div>
            </>
          }
          visible={this.props.modalOpen} 
          onCancel={this.props.onCancel}
          footer={null}
        >
          <div id='wo-modal-body'>
            {PageComponents[this.state.page]}
          </div>
        </Modal>
      </WorkOrderContext.Provider>
    );
  }
}
export const CardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const ToolCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <ToolOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const ExtendCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <IssuesCloseOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const PauseCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <PauseCircleOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const DoneCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <FileDoneOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const UploadCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <UploadOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}
export const MaterialCardAction = (props) => {
  const classes = WorkOrderClasses();
  const {action,text,color} = props;

  return(
      <div className={classes.cardContainer +' '+ classes.color} onClick={action}>
        <PieChartOutlined/>&nbsp;
          <div className='d-inline-block'>{text}</div>
          <RightCircleOutlined className={classes.rightCircle} />
      </div>
  )
}

const BackBtn = (props) => {
  const classes = WorkOrderClasses();

  return(
    <ArrowLeftOutlined
      onClick={props.goBack}
      className={classes.backBtn}
    />
  )
}

export const WorkOrderFooter = (props) => {
  const classes = WorkOrderClasses();
  return(
    <div className={classes.footer}>
      {props.children}
    </div>
  )
}


const workOrderTitle = {
  "Entry": "Entry Details",
  "Menu": "Main Menu",
  "Troubleshoot": "Troubleshoot Problem",
  "Images": "Upload Images",
  "Materials": "Estimate Expenses",
  "Action": "Actions Required",
  "Labour": "Labour Needed",
  "CostOptions": "Cost Details",
  "Expenses": "Total Expenses",
  "FollowUp": "Follow Up Required",
  "ErrorPage": "Error"
}
export default WorkOrder;

      /*jobData: {
        job_id: null,
        tenants: [],
        vendor_id: null,
        wo_id: null,
      },
      status: "a",
      tenantList: [],
      vendorList: [],

      entryData: {
        authType: "",
        date: moment(),
        timeIn: null,
        tenant: null,
      },
      troubleshootData: {
        description: "",
        images: "",
      },
      materialData: {
        materialsNeeded: false,
        materialRows: [],
        totalCost: 0,
      },
      labourData: {
        costList: [
          {
            id: 1,
            label: "Estimated hours to fix",
            startUnit: "",
            price: 0,
            endUnit: "Hours",
            custom: false,
          },
          {
            id: 2,
            label: "Hourly Rate",
            startUnit: "$",
            price: 0,
            endUnit: "/Hour",
            custom: false,
          },
          {
            id: 3,
            label: "Material Cost",
            startUnit: "$",
            price: 0,
            endUnit: "",
            custom: false,
          },
        ],
        totalCost: 0,
      },
      actionData: {
        time: null,
        description: null,
      },
      endSessionData: {
        timeOut: null,
        totalKM: null,
      },
      expenseData: {
        totalCost: 0,
        taxIncluded: false,
        transactions: [], // {id:0,receipt:"",vendor:"",amount:"",comment:""}
        images: [],
      },
      followupData: {
        notes: "",
        nextStatus: "",
      },*/
    
