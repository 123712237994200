import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.name = props.name;
  }

  render() {
    return <>{this.name}</>;
  }
}
export default Feature;
