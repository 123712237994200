//line 153 to add the backend axios to get and push data to backend
//line 187 to calculate the total cost
//add some code to calculate the labour approx cost from their hrly wage
import React, {
	Component,
	useState,
	useContext,
	useEffect,
	useRef
} from 'react';
import axios from 'axios';
import {
	DatePicker,
	TimePicker,
	Button,
	InputNumber,
	Table,
	Modal,
	Checkbox,
	Input,
	Popconfirm,
	Form,
	Select,
	Menu,
	Tooltip,
	Dropdown
} from 'antd';
//import { Form } from "react-bootstrap";
import WorkOrderContext from '../WorkOrderContext';
//import Form from "antd/lib/form/Form";
import '../WorkOrder.css';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CardAction, WorkOrderFooter } from '../WorkOrder';

const { Option } = Select;
let currentDate = new Date();
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

class MaterialsNeeded extends Component {
	static contextType = WorkOrderContext;

	constructor(props) {
		super(props);
		this.state = {
			materialsNeeded: false,
			materialRows: [],
			currentRow: '',
			currentTask: '',
			period: null,
			invoice: '',
			dueDate: '',
			vendors: null,
			propertyId: '',
			payeeId: '',
			invoiceDate: '',
			invoiceNumber: '',
			description: '',
			chargeAmount: '',
			paymentMethod: '',
			gstAmount: '',
			payee: ''
		};
		this.materialCols = [
			{
				title: 'Invoice Date',
				dataIndex: 'invoice_date',
				width: '50%',
				render: record => {
					return (
						<DatePicker
							onChange={(date, dateString) => {
								this.setState({ invoiceDate: dateString });
							}}
						/>
					);
				}
			},
			{
				title: 'Invoice Number',
				dataIndex: 'invoice_number',
				editable: true,
				render: record => (
					<Input
						onChange={this.handleChange}
						name='invoiceNumber'
						value={this.state.invoiceNumber}
						style={{ width: 80 }}
					/>
				)
			},
			{
				title: 'Description',
				dataIndex: 'charge_description',
				width: '20%',
				render: record => (
					<Input
						onChange={this.handleChange}
						name='description'
						value={this.state.description}
						style={{ width: 80 }}
					/>
				)
			},

			{
				title: 'Payee',
				dataIndex: 'payee',
				width: '20%',
				render: record => (
					<Input
						onChange={this.handleChange}
						name='payee'
						value={this.state.payee}
						style={{ width: 80 }}
					/>
				)
			},
			{
				title: 'Payment Method',
				dataIndex: 'paymentMethod',
				width: '20%',
				editable: true,
				render: record => (
					<Input
						onChange={this.handleChange}
						name='paymentMethod'
						value={this.state.paymentMethod}
						style={{ width: 80 }}
					/>
				)
			},
			{
				title: ' Charge Amount',
				dataIndex: 'gross_amount',
				editable: true,

				render: record => (
					<Input
						onChange={e => this.handleChange(e)}
						name='chargeAmount'
						value={this.state.chargeAmount}
						style={{ width: 80 }}
					/>
				)
			},
			{
				title: ' GST Amount',
				dataIndex: 'gst_amount',
				editable: true,

				render: record => (
					<Input
						onChange={e => this.handleChange(e)}
						name='gstAmount'
						value={this.state.gstAmount}
						style={{ width: 80 }}
					/>
				)
			},
			{
				title: ' Total Amount',

				render: (_, record) => {
					let total = record.gross_amount + record.gst_amount;
					return '$' + total;
				}
			}
		];
	}
	EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);
		useEffect(() => {
			if (editing) {
				inputRef.current.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex]
			});
		};

		const save = async () => {
			record.period = this.state.period;
			record.invDate = this.state.invoice;
			record.dueDate = this.state.dueDate;
			record.payee = this.state.payeeId;
			record.payeeCode = record.payee;
			record.property = this.state.propertyId;
			record.legalEntity = this.state.propertyId;

			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({ ...record, ...values });
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}
				>
					<Input ref={inputRef} onPressEnter={save} onBlur={save} />
				</Form.Item>
			) : (
				<div
					className='editable-cell-value-wrap'
					style={{
						paddingRight: 24
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
			);
		}

		return <td {...restProps}>{childNode}</td>;
	};

	// componentDidMount = () => {
	// 	this.setState({ propertyId: this.context.getJrData().propertyId });
	// 	if (this.context.materialData) {
	// 		const { materialsNeeded, materialRows, totalCost } =
	// 			this.context.materialData;
	// 		this.setState({
	// 			materialsNeeded: materialsNeeded,
	// 			materialRows: materialRows,
	// 			totalCost: totalCost
	// 		});
	// 	}
	// 	this.getVendors();
	// };
	// expensePopUp = () => {
	// 	this.setState({ expenseType: !this.state.expenseType });
	// };

	// saveMaterials = () => {
	// 	if (this.state.next) {
	// 		this.context.updateMaterialData(
	// 			this.state.materialsNeeded,
	// 			this.state.materialRows,
	// 			this.state.totalCost,
	// 			false
	// 		);
	// 	}
	// };

	materialsnextclick = () => {
		this.setState({ next: false });
		this.props.updateMaterialData(
			this.state.materialsNeeded,
			this.state.materialRows,
			this.state.totalCost,
			true
		);
	};
	// getVendors = () => {
	// 	let x = [];
	// 	this.setState({ vendors: this.context.jobData.vendors });
	// 	for (let i = 0; i < this.context.jobData.vendors.length; i++) {
	// 		x.push(
	// 			<Option value={this.context.jobData.vendors[i].company_id}>
	// 				{this.context.jobData.vendors[i].name}
	// 			</Option>
	// 		);
	// 	}
	// 	this.setState({ vendors: x });
	// };

	newMaterial = () => {
		let row_ids = [],
			rowid;
		for (let i of this.state.materialRows) {
			row_ids.push(i.row_id);
		}
		if (row_ids.length > 0) {
			rowid = Math.max.apply(Math, row_ids) + 1;
		} else rowid = 1;

		let newMaterial = {
			row_id: rowid,
			material: null,
			quantity: 1,
			measure: false,
			measurement: null,
			unit: null,
			est_cost: null,
			store: 'Store'
		};
		this.setState({ currentRow: newMaterial });
		this.setState({ currentTask: 'Add' });
	};

	deleteMaterial = id => {
		let curr = this.state.materialRows.findIndex(x => x.row_id === id);
		let arr = this.state.materialRows;
		for (var i = 0; i < arr.length; i++) {
			if (i === curr) {
				arr.splice(i, 1);
			}
		}
		this.setState({ materialRows: arr });
		this.setState({ currentTask: 'Display' });
		this.updateTotal();

		if (this.state.materialRows.length === 0) {
			this.setState({ materialsNeeded: false });
		}
	};

	needMaterials = () => {
		this.setState({ currentTask: 'Display' });
		this.setState({ materialsNeeded: true });
	};

	//Changes the currentRow to the row with row_id
	editRow = row_id => {
		let curr = this.state.materialRows.find(x => x.row_id === row_id);
		this.setState({ currentRow: curr });
		this.setState({ currentTask: 'Add' });
	};
	resetStateDetails = () => {
		this.setState({ type: '' });
		this.setState({ quantity: '' });
		this.setState({ vendor: '' });
		this.setState({ approximateCost: 0 });
		this.setState({ approximateLabourCost: 0 });
		this.setState({ hours: 0 });
		this.setState({ minutes: 0 });
		this.setState({ modalStatus: 'Estimate Expense' });
		this.setState({ isLabour: false });
		this.setState({ action_value: 'New' });
		this.setState({ exp_id: null });
	};
	updateTotal = () => {
		let cost = 0;
		this.state.materialRows.forEach(i => {
			if (isNaN(parseFloat(i.est_cost))) {
				cost += 0;
			} else cost += parseFloat(i.est_cost);
		});
		this.setState({ totalCost: cost });
	};
	addMaterial = material => {
		let curr = this.state.materialRows.findIndex(
			x => x.row_id === material.row_id
		);
		let arr = this.state.materialRows;
		if (curr === -1) {
			arr.push(material);
			this.setState({ materialRows: arr });
			this.setState({ currentTask: 'Display' });
		} else {
			arr[curr] = material;
			this.setState({ materialRows: arr });
			this.setState({ currentTask: 'Display' });
		}
		this.updateTotal();
	};

	handleChangePayee = e => {
		this.setState({ payeeId: e });
	};
	// onChangePeriod = (date, dateString) => {
	// 	this.setState({ period: date });
	// };
	// onChangeInvoice = (date, dateString) => {
	// 	this.setState({ invoice: date });
	// };
	// onChangeDueDate = (date, dateString) => {
	// 	this.setState({ dueDate: date });
	// };
	onSubmit = () => {
		if (this.state.modalStatus == 'Materials') {
			//Add routes to add the the details of material expense to the table
		} else if (this.state.modalStatus == 'Labour') {
			//Add routes to add the the details of labour expense to the table
		}
		this.expensePopUp();
	};
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	handleDelete = key => {
		const dataSource = [...this.state.materialRows];
		this.setState({
			dataSource: dataSource.filter(item => item.key !== key)
		});
	};
	handleAdd = type => {
		const count = this.state.count;
		const dataSource = this.state.materialRows;
		const newData = {
			period: ` `,
			invDate: ` `,
			dueDate: ``,
			payeeCode: ``,
			payee: ``,
			paymentMethod: ``,
			batch: ``,
			control: ``,
			legalEntity: ``,
			property: ``,
			grossAmountL: ``,
			invoiceNumber: ``,
			notes: ``,
			description: ''
		};
		if (type == 'Labour') {
			newData.cost = (newData.quantity / 60) * 12.75;
			newData.isLabour = true;
		}
		this.setState({
			materialRows: [...dataSource, newData],
			count: count + 1
		});
	};
	handleSave = row => {
		const newData = [...this.state.materialRows];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
			materialRows: newData
		});
	};
	saveExpenses = () => {
		axios
			.post('workORder/add/charge', {
				Create_Entry_Time: currentDate,

				Invoice_Date: this.state.invoiceDate,
				Charge_From: this.state.payeeId,

				Invoice_Number: this.state.invoiceNumber,
				// Property_ID: props.tenantLeases[0].propertyid,

				Charge_Description: this.state.description,
				Gross_Amount: this.state.chargeAmount,
				GST_Amount: this.state.gstAmount
			})

			.then(res => {
				this.context.changePage('Menu');
			});
	};
	menu = (
		<Menu>
			<Menu.Item onClick={() => this.handleAdd('Material')}>Material</Menu.Item>
			<Menu.Item onClick={() => this.handleAdd('Labour')}>Labour</Menu.Item>
		</Menu>
	);

	render() {
		console.log(this.context);
		const { dataSource } = this.state.materialRows;
		const components = {
			body: {
				row: EditableRow,
				cell: this.EditableCell
			}
		};
		const columns = this.materialCols.map(col => {
			if (!col.editable) {
				return col;
			}

			return {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: this.handleSave
				})
			};
		});
		return (
			<div style={{ height: '270px', width: '495px' }} className=''>
				Expenses {/*<PlusCircleOutlined  onClick={this.handleAdd}/>*/}
				{
					<Dropdown overlay={this.menu} trigger={['click']}>
						<a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
							<PlusCircleOutlined />
						</a>
					</Dropdown>
				}
				<Table
					components={components}
					columns={columns}
					dataSource={this.state.materialRows}
					rowKey={'row_id'}
					size='small'
					pagination={false}
					rowClassName={() => 'editable-row'}
					// scroll={{ x: 500 }}
				/>
				{/*<Button onClick={this.newMaterial} type='primary'>Add Material</Button>*/}
				{/* Add code to calculate the total cost from the details from backend */}
				{/* <p>Total Cost: ${this.state.approximateCost}</p> */}
				<div className='borderLine'></div>
				<div>
					<Button
						className='mr-0'
						type='primary'
						style={{
							whiteSpace: 'normal',
							height: 'auto',
							marginTop: '10px'
						}}
						onClick={() => this.context.changePage('Menu')}
					>
						Submit
					</Button>
				</div>
				<Modal
					title={this.state.modalStatus}
					destroyOnClose={true}
					visible={this.state.expenseType}
					onCancel={this.expensePopUp}
					footer={
						this.state.modalStatus == 'Estimate Expense' ? (
							<div></div>
						) : (
							[
								<Button
									type='primary'
									onClick={() =>
										this.setState({ modalStatus: 'Estimate Expense' })
									}
								>
									Back
								</Button>,
								<Button type='primary' onClick={() => this.onSubmit()}>
									Save
								</Button>
							]
						)
					}
				>
					{this.state.modalStatus == 'Estimate Expense' ? (
						<div>
							<CardAction
								text='Estimate Materials '
								action={() => this.setState({ modalStatus: 'Materials' })}
							/>
							<CardAction
								text='Estimate Labour'
								action={() => this.setState({ modalStatus: 'Labour' })}
							/>
							<br />
						</div>
					) : this.state.modalStatus == 'Materials' ? (
						<div title='Estimate Material'>
							<Form>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>Item </Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='modalStatus'
											id='modalStatus'
											type='text'
											value={this.state.modalStatus}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>Quantity </Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='quantity'
											id='quantity'
											type='text'
											value={this.state.quantity}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>Vendor </Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='vendor'
											id='vendor'
											type='text'
											value={this.state.vendor}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>
											Estimated cost{' '}
										</Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='approximateCost'
											id='approximateCost'
											type='text'
											value={this.state.approximateCost}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
							</Form>
							Estimated pay: {this.state.approximateCost}
						</div>
					) : (
						<div>
							<Form>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>Item </Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='modalStatus'
											id='modalStatus'
											type='text'
											value={this.state.modalStatus}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>Vendor </Form.Label>
										<Form.Control
											editable='true'
											required
											className='form-text'
											name='vendor'
											id='vendor'
											type='text'
											value={this.state.vendor}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group>
										<Form.Label className='form-label'>
											Time Taken(hours){' '}
										</Form.Label>
										<InputNumber
											min={1}
											defaultValue={0}
											onChange={this.onChangehrs}
										/>
										<Form.Label className='form-label'>Minutes </Form.Label>
										<InputNumber
											min={1}
											defaultValue={0}
											onChange={this.onChangemins}
										/>
									</Form.Group>
								</Form.Row>
							</Form>
							Estimated pay:
							{Math.round(
								(this.state.hours + this.state.minutes / 60) * 12.75,
								2
							)}
						</div>
					)}
				</Modal>
			</div>
		);
	}
}

export class Measurement extends Component {
	updateMeasurement = e => {
		this.props.updateMeasurement('measurement', e.target.value);
	};
	updateUnits = e => {
		this.props.updateMeasurement('unit', e.target.value);
	};

	render() {
		return (
			<div>
				<div className='fixedLine'>
					<label className='txtLabel '>Material Measurement</label>
					<label className='d-inline startUnits'> </label>
					<input
						type='text'
						placeholder='Ex. 2, 2x2, 2x2x5'
						className='form-control txtInput mb-2'
						onChange={this.updateMeasurement}
						name='measurement'
						value={this.props.measurement}
					></input>
				</div>
				<div className='fixedLine'>
					<label className='txtLabel '>Unit</label>
					<label className='d-inline startUnits'> </label>
					<select
						className='form-control txtInput drop_assingned mb-2'
						onChange={this.updateUnits}
						name='unit'
						value={this.props.unit}
					>
						<option selected hidden disabled>
							Unit (Optional)
						</option>
						<optgroup label='Length'>
							<option>Centimeters (cm)</option>
							<option>Meters (m)</option>
							<option>Kilometers (km) </option>
							<option>Inches (in)</option>
							<option>Feet (ft)</option>
							<option>Yards (yd)</option>
							<option>Miles (mi)</option>
						</optgroup>
						<optgroup label='Volume'>
							<option>Millilitres (ml) </option>
							<option>Liters (l)</option>
							<option>Gallon (gal)</option>
						</optgroup>
						<optgroup label='Weight'>
							<option>Grams (g)</option>
							<option>Kilograms (kg)</option>
							<option>Pounds (lb)</option>
						</optgroup>
					</select>
				</div>
			</div>
		);
	}
}

//{mat_id:1, material_name:"", quantity:0,measurement:"",unit:"",cost:0.0,store:""}
export const AddMaterials = props => {
	const { currentRow } = props;
	const [newRow, setRow] = useState(currentRow);

	const saveRow = e => {
		return;
	};

	return (
		<Form {...layout} initialValues={currentRow} onFinish={saveRow}>
			<Form.Item name='material_name' label='Material Name'>
				<input />
			</Form.Item>
			<Form.Item name='quantity' label='Quantity'>
				<InputNumber />
			</Form.Item>
			<Form.Item name='cost' label='Estimated Cost'>
				<InputNumber
					formatter={value =>
						`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					}
					parser={value => value.replace(/\$\s?|(,*)/g, '')}
				/>
			</Form.Item>
		</Form>
	);
};

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};

export class Addmaterials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			row_id: this.props.row_id,
			material: this.props.material,
			quantity: this.props.quantity,
			measure: this.props.measure,
			measurement: this.props.measurement,
			unit: this.props.unit,
			cost: this.props.est_cost,
			store: this.props.store,
			errormsg: ''
		};
		this.addMaterial = this.addMaterial.bind(this);
		this.addQuantity = this.addQuantity.bind(this);
		this.addUnit = this.addUnit.bind(this);
		this.addCost = this.addCost.bind(this);
		this.addStore = this.addStore.bind(this);
		this.sendMaterial = this.sendMaterial.bind(this);
	}

	addMaterial = e => {
		this.setState({ material: e.target.value });
	};
	addQuantity = e => {
		this.setState({ quantity: e.target.value });
	};
	addUnit = e => {
		this.setState({ unit: e.target.value });
	};
	addCost = e => {
		this.setState({ cost: e.target.value });
	};
	addStore = e => {
		this.setState({ store: e.target.value });
	};
	deleteMaterial = () => {
		this.props.deleteMaterial(this.state.row_id);
	};
	sendMaterial = () => {
		let material;
		if (this.verify()) {
			material = {
				row_id: this.state.row_id,
				material: this.state.material,
				quantity: this.state.quantity,
				measure: this.state.measure,
				measurement: this.state.measurement,
				unit: this.state.unit,
				est_cost: this.state.cost,
				store: this.state.store
			};
			this.props.addMaterial(material);
		}
	};
	verify = () => {
		if (this.state.material === 'Material') {
			this.setState({ errormsg: 'Please select a material' });
			return false;
		} else if (this.state.cost === null) {
			this.setState({ errormsg: 'Please enter the material cost' });
			return false;
		}
		return true;
	};
	updateMeasurement = (type, value) => {
		if (type === 'measurement') {
			this.setState({ measurement: value });
		} else if (type === 'unit') {
			this.setState({ unit: value });
		}
	};
	measureChange = e => {
		if (e.target.value === 'Yes') this.setState({ measure: true });
		else this.setState({ measure: false });
	};
	render() {
		let measurement;
		if (this.state.measure === true)
			measurement = (
				<Measurement
					measurement={this.state.measurement}
					unit={this.state.unit}
					updateMeasurement={this.updateMeasurement}
				/>
			);
		return (
			<div className='aligncenter'>
				<div className='fixedLine'>
					<label className='txtLabel '>Material</label>
					<label className='d-inline startUnits'> </label>
					<input
						name='materialname'
						type='text'
						className='form-control txtInput drop_assingned mb-2'
						placeholder='Material Name'
						onChange={this.addMaterial}
						value={this.state.material}
					/>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Material Quantity</label>
					<label className='d-inline startUnits'> </label>
					<input
						type='number'
						min='0'
						className='form-control txtInput mb-2'
						onChange={this.addQuantity}
						name='quantity'
						value={this.state.quantity}
					></input>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Estimated Cost</label>
					<label className='d-inline startUnits text-center m-auto'>$ </label>
					<input
						type='number'
						min='0'
						className='form-control txtInput mb-2'
						onChange={this.addCost}
						name='cost'
						placeholder='0'
						value={this.state.cost}
					></input>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Store</label>
					<label className='d-inline startUnits'> </label>
					<select
						className='form-control drop_assingned txtInput mb-2'
						name='store'
						onChange={this.addStore}
						value={this.state.store}
					>
						<option selected hidden disabled>
							Store
						</option>
						{this.props.vendorList.map(vendor => (
							<option key={vendor.id}>{vendor.name}</option>
						))}
						<option>Other</option>
					</select>
				</div>
				<br />
				<div className='fixedLine'>
					<label className='txtLabel '>Add Material Measurements </label>
					<div className='txtInput'>
						<input
							type='radio'
							name='measurement'
							value='Yes'
							id='yesM'
							checked={this.state.measure === true}
							onChange={this.measureChange}
						/>
						<label className='mr-2'>Yes</label>
						<input
							type='radio'
							name='measurement'
							value='No'
							id='noM'
							checked={this.state.measure === false}
							onChange={this.measureChange}
						/>
						<label>No</label>
					</div>
				</div>

				{measurement}

				<div className='errorMsg'>{this.state.errormsg}</div>
				<br />
				<div className='text-right'>
					<Button type='primary' onClick={this.sendMaterial}>
						Add Material
					</Button>
					<br />
					<h7 id='delMaterial' onClick={this.deleteMaterial}>
						Delete This Material
					</h7>
				</div>
			</div>
		);
	}
}

export class ButtonGroup extends Component {
	constructor(props) {
		super(props);
		this.labourPage = this.labourPage.bind(this);
	}
	labourPage() {
		this.props.materialsnextclick();
	}
	exitpage = () => {
		this.props.goEnd();
	};
	render() {
		return (
			<div id='btnGroup' className='buttonGroup text-right'>
				<Button type='primary' onClick={this.labourPage}>
					Next
				</Button>
			</div>
		);
	}
}

export default MaterialsNeeded;
