import React from 'react';
import './boxdisplay.css'

export const BoxDisplay = ({children,mobile}) => {
  return(
    <div className={!mobile ? 'd-flex h100 flex-wrap ':'d-flex h100  flex-column '}>
      {children}  
    </div>
  )
}

BoxDisplay.HelpBox = ({children}) => {
  return(
  <div className={'d-flex helpbox'}>
    <div className='feedbackBox'>
      <div className='feedbackContent'>
        {children}
      </div>
    </div>
  </div>
  )
}
BoxDisplay.HelpBoxSmall = ({children}) => {
  return(
  <div className={'d-flex helpbox'}>
    <div className='boxSmall'>
      <div className='contentSmall'>
        {children}
      </div>
    </div>
  </div>
  )
}