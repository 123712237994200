import React, {
	Component,
	useState,
	useContext,
	useEffect,
	useRef
} from 'react';
import axios from 'axios';
import {
	DatePicker,
	TimePicker,
	Button,
	InputNumber,
	Table,
	Checkbox,
	Input,
	Popconfirm,
	Select,
	Menu,
	Tooltip,
	Dropdown
} from 'antd';
import { Modal, Form, Col } from 'react-bootstrap';
import moment from 'moment';
//import { Form } from "react-bootstrap";

//import Form from "antd/lib/form/Form";
import './WorkOrder.css';
import { PlusCircleOutlined } from '@ant-design/icons';
import RequestContext from '../MaintenanceRequest/RequestContext';

import UploadInvoice from './UploadInvoice';

const { Option } = Select;
let currentDate = new Date();
const EditableContext = React.createContext(null);

class EditCharge extends Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			materialRows: this.props.invoiceDetails[0].chargeData.map(
				charge => charge
			),
			invoiceDate: this.props.invoiceDetails[0].date,
			payees: [],
			payers: [],
			chargeFrom: this.props.invoiceDetails[0].charge_from,

			chargeTo: this.props.invoiceDetails[0].charge_to_id,
			invoiceNumber: this.props.invoiceDetails[0].invoice_number,
			invoiceUpload: false,
			fileUrl: this.props.invoiceDetails[0].file
		};
		this.handleChange = this.handleChange.bind(this);
		this.materialCols = [
			{
				title: 'Description',
				dataIndex: 'charge_description',
				width: 120,

				render: (inputValue, record, rowIndex) => {
					console.log(record);

					return (
						<Input
							value={record.charge_description || inputValue}
							onChange={e =>
								this.handleChange(
									e.target.value,
									rowIndex,
									'charge_description'
								)
							}
							style={{ width: 200 }}
						/>
					);
				}
			},
			{
				title: 'Charge Amount',
				dataIndex: 'gross_amount',
				editable: true,
				width: 10,

				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e, rowIndex, 'gross_amount');
					};
					return (
						<InputNumber
							formatter={value => `$ ${value}`}
							precision={2}
							min={0}
							controls={false}
							value={record.gross_amount || inputValue}
							onChange={changeRecord}
							style={{ width: 100 }}
						/>
					);
				}
			},
			{
				title: ' HST Amount',
				dataIndex: 'gst_amount',
				editable: true,
				width: 50,
				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e, rowIndex, 'gst_amount');
					};
					return (
						<InputNumber
							formatter={value => `$ ${value}`}
							precision={2}
							min={0}
							controls={false}
							value={record.gst_amount || inputValue}
							onChange={changeRecord}
							style={{ width: 100 }}
						/>
					);
				}
			},
			{
				title: 'Total Amount',
				dataIndex: 'total_amount',
				width: 80,
				render: (_, record) => {
					// console.log(record.gst_amount);
					let total =
						parseFloat(record.gross_amount) + parseFloat(record.gst_amount);
					console.log(total);
					return '$' + total.toFixed(2);
				}
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 80,
				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e.target.value, rowIndex, 'notes');
					};
					return (
						<Input
							value={record.notes || inputValue}
							onChange={changeRecord}
							style={{ width: 150 }}
						/>
					);
				}
			}
		];
	}

	componentDidMount = () => {
		// console.log(this.context);
		axios.get('/getVendors').then(response => {
			// console.log(response.data.vendors);
			this.setState({ payees: response.data.vendors });
		});
		axios
			.post('/getOwnerTenant', { UnitId: this.context.request.unit_id })
			.then(response => {
				// console.log(response.data.OwnerTenant);
				this.setState({ payers: response.data.OwnerTenant });
			});
	};

	invoiceUploadModal = () => {
		this.setState({ invoiceUpload: !this.state.invoiceUpload });
	};
	EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);
		useEffect(() => {
			if (editing) {
				inputRef.current.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex]
			});
		};

		const save = async () => {
			record.period = this.state.period;
			record.invDate = this.state.invoice;
			record.dueDate = this.state.dueDate;
			record.payee = this.state.payeeId;
			record.payeeCode = record.payee;
			record.property = this.state.propertyId;
			record.legalEntity = this.state.propertyId;

			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({ ...record, ...values });
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}
				>
					<Input ref={inputRef} onPressEnter={save} onBlur={save} />
				</Form.Item>
			) : (
				<div
					className='editable-cell-value-wrap'
					style={{
						paddingRight: 24
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
			);
		}

		return <td {...restProps}>{childNode}</td>;
	};

	handleAdd = () => {
		const dataSource = this.state.materialRows;
		const newData = {};

		this.setState({
			materialRows: [...dataSource, newData]
		});
	};
	handleChange = (e, rowIndex, column) => {
		let updatedRow = {
			...this.state.materialRows[rowIndex]
		};
		updatedRow[column] = e;
		this.setState({
			materialRows: [
				...(rowIndex !== 0 ? this.state.materialRows.slice(0, rowIndex) : []),
				updatedRow,
				...(rowIndex < this.state.materialRows.length - 1
					? this.state.materialRows.slice(rowIndex + 1)
					: [])
			]
		});
	};

	handleSave = row => {
		const newData = [...this.state.materialRows];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
			materialRows: newData
		});
	};

	updateInvoiceDetails = () => {
		// console.log(this.props.invoiceDetails.length);

		let totalInvoiceAmount = 0;
		let totalInvoiceTax = 0;
		this.state.materialRows.forEach(expense => {
			console.log(expense.gross_amount, expense.gst_amount);
			totalInvoiceAmount += parseFloat(expense.gross_amount);
			totalInvoiceTax += parseFloat(expense.gst_amount);
		});
		console.log(parseFloat(totalInvoiceAmount).toFixed(2));
		console.log(parseFloat(totalInvoiceTax).toFixed(2));
		axios
			.post('/workOrder/updateInvoice', {
				Invoice_Date: this.state.invoiceDate,
				Charge_From: this.state.chargeFrom,
				Charge_To_ID: this.state.chargeTo,
				Invoice_Number:
					this.state.invoiceNumber ||
					(this.props.invoiceDetails.length > 0
						? 'JR-' +
						  this.context.request.job_id +
						  '-' +
						  this.props.invoiceDetails.length
						: 'JR-' + this.context.request.job_id),

				Invoice_Amount: parseFloat(totalInvoiceAmount).toFixed(2),
				GST_Amount: parseFloat(totalInvoiceTax).toFixed(2),
				File: this.state.fileUrl,
				InvoiceId: this.props.invoiceDetails[0].id
			})
			.then(res => {
				this.props.getInvoiceDetails();
			});
	};

	updateCharges = () => {
		this.state.materialRows.forEach(expense => {
			axios
				.post('/workOrder/updateCharge', {
					Create_Entry_Time: currentDate,

					Invoice_Date: this.state.invoiceDate,
					Charge_From: this.state.chargeFrom,
					Charge_To_ID: this.state.chargeTo,

					Charge_Description: expense.charge_description,
					Gross_Amount: expense.gross_amount,
					GST_Amount: expense.gst_amount,

					Notes: expense.notes,
					Charge_Control_Id: expense.charge_control_id
				})

				.then(res => {
					this.props.onClose();
				});
		});
		this.updateInvoiceDetails();
	};

	render() {
		console.log(this.props.invoiceDetails);
		// console.log(this.state.invoiceNumber);
		// console.log(this.state.chargeTo);
		// console.log(this.state.chargeFrom);

		const columns = this.materialCols.map(col => {
			if (!col.editable) {
				return col;
			}

			return {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title
					// handleSave: this.handleSave
				})
			};
		});
		return (
			<>
				<div>
					<Modal.Body>
						<Form>
							<Form.Row>
								<Form.Group as={Col} md={3}>
									<Form.Label className='form-label'>Invoice Date</Form.Label>
									<DatePicker
										value={moment(this.state.invoiceDate)}
										// style={{ width: 150, marginLeft: 5 }}
										onChange={(date, dateString) => {
											this.setState({
												invoiceDate: dateString
											});
										}}
									/>
								</Form.Group>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Invoice Number</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='invoiceNumber'
										id='invoiceNumber'
										type='text'
										value={this.state.invoiceNumber}
										onChange={e =>
											this.setState({ invoiceNumber: e.target.value })
										}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Charge From</Form.Label>
									<select
										value={this.state.chargeFrom}
										name='chargeFrom'
										className='form-control'
										onChange={e => {
											console.log(e.target.value);
											this.setState({
												chargeFrom: e.target.value
											});
										}}
									>
										<option key={'select'}>Select...</option>
										{this.state.payees.map(item => (
											<>
												<option
													value={item.fname + ' ' + item.lname}
													key={item.fname}
												>
													{item.fname + ' ' + item.lname}
												</option>
											</>
										))}
									</select>
								</Form.Group>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Charge To</Form.Label>
									<select
										value={this.state.chargeTo}
										name='chargeTo'
										className='form-control'
										onChange={e => {
											// console.log(e.target.value);
											this.setState({
												chargeTo: e.target.value
											});
										}}
									>
										<option key={'select'}>Select...</option>
										{this.state.payers.map(item => (
											<>
												<option
													value={item.property_owner_id}
													key={item.property_owner_id}
												>
													{'O-' + item.property_owner_name}
												</option>
												<option value={item.tenant_id} key={item.tenant_id}>
													{'T-' + item.tenant_name}
												</option>
											</>
										))}
									</select>
								</Form.Group>
							</Form.Row>
							{/* <div>
								Expenses
								{
									<PlusCircleOutlined
										style={{ marginLeft: '5px' }}
										onClick={this.handleAdd}
									/>
								}
							</div> */}
							<Table
								columns={columns}
								dataSource={this.state.materialRows}
								// rowKey={'row_id'}
								size='small'
								pagination={false}
								// rowClassName={() => 'editable-row'}
								// scroll={{ x: 500 }}
							/>
						</Form>
					</Modal.Body>
					{this.state.fileUrl}
					<Modal.Footer>
						<div>
							<Button
								className='mr-0'
								type='primary'
								style={{
									whiteSpace: 'normal',
									height: 'auto',
									marginTop: '10px',
									float: 'left'
								}}
								onClick={this.invoiceUploadModal}
							>
								Upload Invoice
							</Button>
							<Button
								className='mr-0'
								type='primary'
								style={{
									whiteSpace: 'normal',
									height: 'auto',
									marginTop: '10px',
									float: 'right',
									marginLeft: '10px'
								}}
								onClick={this.updateCharges}
							>
								Update
							</Button>
						</div>
					</Modal.Footer>
				</div>
				<div>
					<Modal
						show={this.state.invoiceUpload}
						onHide={this.invoiceUploadModal}
						size='sm'
						aria-labelledby='add-invoices'
						centered
					>
						<UploadInvoice
							visible={this.state.invoiceUpload}
							body={{ job_id: this.context.request.job_id }}
							route='/workOrder/uploadInvoice'
							onClose={this.invoiceUploadModal}
							updateImages={this.context.updateImages}
							onUploadingInvoice={file => {
								this.setState({ fileUrl: file });
							}}
						/>
					</Modal>
				</div>
			</>
		);
	}
}

export default EditCharge;
