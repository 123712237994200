import React from 'react'
import { CardAction, WorkOrderFooter } from '../WorkOrder'
import {Button} from 'antd'
import WorkOrderContext from '../WorkOrderContext'


const EstimateMenu = () => {
    const context = React.useContext(WorkOrderContext);
    return(
        <div>
            <CardAction text='Estimate Materials ' action={() => context.changePage("Materials")} />
            <CardAction text='Estimate Labour' action={() => context.changePage("Labour")} />
            

        </div>
    
    )
}

export default EstimateMenu