import React, { Component } from "react";
import { Input } from "antd";

export default class ExtensionDeclined extends Component {
    constructor(props) {
        super(props);
    }

    updateExtensionTextArea = (e) => {
        this.props.extensionContext.updateExtensionTextArea(e.target.value);
    }

    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Input.TextArea autoSize={ {minRows: 2, maxRows: 4} } placeholder="List reasons why, etc..." onChange={this.updateExtensionTextArea} />
                </div>
            </>
        )
    }
}