import React from 'react';
import { Button, message, Card } from 'antd';
import axios from 'axios';
//Library to send requests to the backend
import { useUserState, useUserDispatch } from '../../../Context/UserContext'; //Get user object and dispatch user changes
import ProfileInformation from './Components/ProfileInformation';
import AvailiabilityCalendar from './Components/AvailabilityCalendar';
import SetAvailability from './Components/SetAvailability';
import './myprofile.css';
import AvailabilityCalendar from './Components/AvailabilityCalendar';
const cancelToken = axios.CancelToken.source();

function MyProfile() {
	const dispatch = useUserDispatch();
	const { user } = useUserState();
	const updateProfileImage = newImage => {
		dispatch({
			state: {
				user: { ...user, imageurl: newImage }
			},
			type: 'initalize'
		});
	};
	return <MyProfileMain updateProfileImage={updateProfileImage} user={user} />;
}

class MyProfileMain extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	changeProfilePicture = file => {
		console.log(file);
		var form = new FormData();
		form.append('file', file);
		axios.post('/updateProfileImage', form).then(response => {
			this.props.updateProfileImage(response.data.newImage);
			this.setState({ changeImageModal: false });
			message.success('Profile picture successfully updated');
		});
	};

	render() {
		return (
			<div className='profileShell myProfileContainer'>
				<Card>
					<ProfileInformation
						changeProfilePicture={this.changeProfilePicture}
					/>
				</Card>
				<Card>
					<SetAvailability />
					<AvailabilityCalendar />
				</Card>
			</div>
		);
	}
}

export default MyProfile;
