import React, { Component } from 'react';
import axios from 'axios';
import PauseWorkOrder from './Pages/PauseWorkOrder';
import CompleteWorkOrder from './CompleteWorkOrder';
import RequestExtension from './Pages/RequestExtension';
import ManageMenu from './ManageMenu';
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext';
import { Modal } from 'antd';
import WorkOrderContext from './WorkOrderContext';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
const PageComponents = {
	Pause: <PauseWorkOrder />,
	Complete: <CompleteWorkOrder />,
	'Manage Menu': <ManageMenu />,
	'Request Extension': <RequestExtension />
};
const workOrderTitle = {
	'Manage Menu': 'Manage Work Order',
	Pause: 'Pausing the Work Order',
	Complete: 'Finishing the Work order',
	'Request Extension': 'Requesting an Extension'
};
const WorkOrderClasses = createUseStyles({
	backBtn: {
		display: 'inline-block',
		marginRight: 10
	},
	cardContainer: {
		width: '90%',
		height: 70,
		padding: 10,
		lineHeight: '50px',
		cursor: 'pointer',
		marginBottom: '5px',
		'&:hover': {
			color: '#4d7cfe'
		}
	},
	color: {
		backgroundColor: '#e6f7ff',
		borderRadius: 5
	},
	rightCircle: {
		float: 'right',
		display: 'inline-block',
		lineHeight: '56px'
	},
	footer: {
		borderTop: 'thin solid #e3e3e3',
		textAlign: 'right',
		paddingTop: '8px'
	}
});
class ManageWorkOrder extends Component {
	static contextType = RequestContext;

	constructor(props) {
		super();
		this.state = {
			page: 'Manage Menu',

			goToMainMenu: () => {
				this.setState({ page: 'Manage Menu' });
			},
			changePage: page => this.setState({ page: page }),
			updateEntryDetails: (auth = '', time, tenant = '', date) => {
				this.setState({
					entryData: {
						authType: auth,
						timeIn: time,
						tenant: tenant,
						date: date
					}
				});
			},
			updateTroubleshootData: (description, images) => {
				this.setState({
					troubleshootData: { description: description, images: images }
				});
			}
		};
	}
	componentDidMount() {
		axios
			.post('/workOrder/get/workOrder/' + this.context.request.job_id)
			.then(response => {
				this.setState({
					jobData: {
						job_id: this.context.request.job_id,
						vendors: this.context.request.vendors,
						workOrders: this.context.activeEvent
							? this.context.activeEvent.workOrders
							: [],
						currentWorkOrder: this.context.currentWorkOrder,
						session_id: this.context.session_id
					},
					status: this.context.request.status,
					tenantList: response.data.tenantList,
					page: 'Manage Menu'
				});
			})
			.catch(err => {
				this.setState({ currentPage: 'errorPage', error: err });
			});
	}

	render() {
		return (
			<WorkOrderContext.Provider value={this.state}>
				<Modal
					width='1000px'
					className='workOrderModal'
					title={
						<>
							{this.state.page !== 'Manage Menu' ? (
								<BackBtn goBack={this.state.goToMainMenu} />
							) : (
								''
							)}
							<div className='d-inline-block'>
								{workOrderTitle[this.state.page]}
							</div>
						</>
					}
					visible={this.props.modalOpen}
					onCancel={this.props.onCancel}
					footer={null}
				>
					<div id='wo-modal-body'>{PageComponents[this.state.page]}</div>
				</Modal>
			</WorkOrderContext.Provider>
		);
	}
}
const BackBtn = props => {
	const classes = WorkOrderClasses();

	return (
		<ArrowLeftOutlined onClick={props.goBack} className={classes.backBtn} />
	);
};
export default ManageWorkOrder;
