import React from "react";
import NewMessageList from "../../Components/Communication/NewMessageList";
import LoadScreen from "../../Components/LoadScreen";
import axios from "axios";
let source = axios.CancelToken.source();

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessages: [],
      loaded: false
    };
  }
  componentDidMount() {
    document.title = "Overview";
    /*source = axios.CancelToken.source();
    axios.get("/communication/getNewUserMessages", {cancelToken: source.token})
    .then((res)=>{
      this.setState({newMessages: "newMessages" in res.data ? res.data.newMessages : [], loaded: true});
    }).catch((err)=>{
      if (!axios.isCancel(err)) {
        throw err;
      }
    });*/
  }

  componentWillUnmount() {
    if (source)
      source.cancel("Moved to new page");
  }

  render() {
    /*if (this.state.loaded)
      return (
        <div style={{display: "flex", flexWrap: "nowrap", flexDirection: "row", height: "100%"}}>
          <div style={{width: "33%", borderRight: "thin solid #e3e3e3"}}>
            <NewMessageList threads={this.state.newMessages}/>
          </div>
        </div>
        );
    else {
      return <LoadScreen/>;
    }*/
    return null
  }
}
export default Overview;