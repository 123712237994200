import React from "react";
import GeneralTask from "./Tasks/GeneralTask";
import InsuranceTask from "./Tasks/InsuranceTask";
import BankingTask from "./Tasks/BankingTask";
import CompanyTask from "./Tasks/CompanyTask";
import VendorTask from "./Tasks/VendorTask";
import AddEmployeeTask from "./Tasks/AddEmployeeTask";
import {ExteriorStructure} from "./Tasks/roomIntakeTask";
import {UploadSpreadsheet,ConnectSpreadsheets,SubmitSpreadsheets} from '../Requests/Tasks/SpreadsheetTasks'
import {
  GeneralInfoTask,
  GeneralPicID,
  GeneralGovID,
} from "./Tasks/InitialRegistrationTask";
import { Questionaire, Units, Lease } from "./Tasks/propertyInfoTask";


class TaskDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // add your tasks here based on the task type
    if (this.props.displayTask !== null) {
      let types = this.props.displayTask.type.split("/");
      switch (types[0]){ 
        case "general":
          return <GeneralTask {...this.props.displayTask} />;
        case "intake":
          switch (types[1]) {
            case "spreadsheet":
              switch (types[2]) {
                case "upload":
                  return (
                    <UploadSpreadsheet
                      key={types[3]}
                      intakeType={types[3]}
                      saveData={this.props.saveData}
                      taskData={this.props.displayTask}
                      updateSaveData={this.props.updateSaveData}
                      updateTaskData={this.props.updateTaskData}
                      userType={this.props.userType}
                    />
                  );
                case "connect":
                  return (
                    <ConnectSpreadsheets
                      key={types[3]}
                      intakeType={types[3]}
                      saveData={this.props.saveData}
                      taskData={this.props.displayTask}
                      updateSaveData={this.props.updateSaveData}
                      updateTaskData={this.props.updateTaskData}
                      userType={this.props.userType}
                    />
                  );
                case "submit":
                  return (
                    <SubmitSpreadsheets
                      key={types[1] + "/" + types[2]}
                      intakeType={types[3]}
                      saveData={this.props.saveData}
                      taskData={this.props.displayTask}
                      updateSaveData={this.props.updateSaveData}
                      updateTaskData={this.props.updateTaskData}
                      deleteTaskData={this.props.deleteTaskData}
                      userType={this.props.userType}
                    />
                  );
                default:
                  break;
              } //end of spreadsheet
              break;
            case "insurance":
              return <InsuranceTask {...this.props.displayTask} />;
            case "banking":
              return <BankingTask {...this.props.displayTask} />;
            case "company":
              return <CompanyTask {...this.props.displayTask} />;
            case "vendor":
              return <VendorTask {...this.props.displayTask} />;
            case "employee":
              return <AddEmployeeTask {...this.props.displayTask} />;
            case "initialReg":
              switch (types[2]) {
                case "registerUserInfo":
                  return (
                    <GeneralInfoTask
                      {...this.props.displayTask}
                      saveData={this.props.saveData}
                      updateSaveData={this.props.updateSaveData}
                    />
                  );
                  break;

                case "registerID":
                  return (
                    <GeneralGovID
                      {...this.props.displayTask}
                      saveData={this.props.saveData}
                      updateSaveData={this.props.updateSaveData}
                    />
                  );
                  break;

                case "registerSelfie":
                  return (
                    <GeneralPicID
                      {...this.props.displayTask}
                      saveData={this.props.saveData}
                      updateSaveData={this.props.updateSaveData}
                    />
                  );
                  break;

                default:
                  break;
              }
              break;
            case "PropertyReg" :
              switch(types[2]){
                case "registerQuestionaire": 
                  return(<Questionaire
                    {...this.props.displayTask}
                    saveData={this.props.saveData}
                    updateTaskData={this.props.updateTaskData}
					taskData={this.props.displayTask}
                    completedTasks={this.props.completedTasks}/>);
                  break;
                case "registerUnits": 
                  return(<Units
                    {...this.props.displayTask}
                    saveData={this.props.saveData}
                    taskData={this.props.displayTask}
                    updateSaveData={this.props.updateSaveData}
                    updateTaskData={this.props.updateTaskData}
                    completedTasks={this.props.completedTasks}/>);
                  break;
                case "createRules":
                  return (<Lease
                    completedTasks={this.props.completedTasks}
					saveData={this.props.saveData}
                    taskData={this.props.displayTask}
                    updateSaveData={this.props.updateSaveData}
                    updateTaskData={this.props.updateTaskData}/>)
                    // completedTasks={this.props.completedTasks}/>);
                  break;

              }
              break;
			
            case "room":
              return(<ExteriorStructure />)
              default:
              break;
          }
        default:
          return <GeneralTask {...this.props.displayTask} />;
          break;
      }
    } else {
      return null;
    }
  }
}

export default TaskDisplay;
