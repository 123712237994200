import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useUserState, useUserDispatch } from '../../Context/UserContext';
import LoginLayout from './LoginLayout';
import { Redirect } from 'react-router-dom';

function SelectAccount() {
	let { user } = useUserState();
	let userDispatch = useUserDispatch();
	let torender = [];
	let [page, setPage] = useState('Select');
	const selectType = type => {
		let temp = user;
		temp.type = type;
		userDispatch({ state: { user: temp }, type: 'update' });
		setPage('next');
	};

	if (user.PM_account === 1) {
		torender.push({ name: 'Property Manager', value: 'Manager' });
	}
	if (user.PO_account === 1) {
		torender.push({ name: 'Property Owner', value: 'Owner' });
	}
	if (user.Tenant_account === 1) {
		torender.push({ name: 'Tenant', value: 'Tenant' });
	}
	if (user.Vendor_account === 1) {
		torender.push({ name: 'Vendor', value: 'Vendor' });
	}
	if (user.General_account === 1) {
		torender.push({ name: 'General User', value: 'General' });
	}
	if (user.Admin_account === 1) {
		torender.push({ name: 'Admin', value: 'Admin' });
	}
	if (page === 'Select') {
		return (
			<LoginLayout header='Select Account'>
				<div>
					{torender.map(type => (
						<Button
							className='d-block mb-4'
							onClick={() => selectType(type.value)}
						>
							{type.name}
						</Button>
					))}
				</div>
			</LoginLayout>
		);
	} else {
		return <Redirect to='/communication' />;
	}
}
export default SelectAccount;
