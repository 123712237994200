import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';

import { DownOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Menu, Button, Table } from 'antd';
import {
	FormOutlined,
	LoadingOutlined,
	FieldTimeOutlined
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import EmployeesList from '../MaintenanceRequest/actions/modals/EmployeesList';

const SummaryWorkLog = props => {
	const [allEmployees, setAllEmployees] = useState([]);
	const [activeEmployees, setActiveEmployees] = useState([]);

	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [workLogData, setWorkLogData] = useState([]);

	useEffect(() => {
		axios
			.get('/workOrder/workLogDetails')
			.then(response => {
				console.log(response.data.activeEmployees);
				setAllEmployees(response.data.activeEmployees);
				setWorkLogData(response.data.logData);
				let tempActiveEmployees = response.data.activeEmployees;

				let workLogDetails = response.data.logData.filter(log =>
					moment(log.date, 'ddd, MMM, Do YYYY').isBetween(
						props.rangePickerStart,
						props.rangePickerEnd,
						'day',
						'[]'
					)
				);
				tempActiveEmployees.forEach(employee => {
					employee.logData = workLogDetails.filter(
						log => log.user_id === employee.id
					);

					let employeeValues = updateTotals(employee.logData);
					employee.employee_name = employee.fname + ' ' + employee.lname;
					employee.from_date = moment(props.rangePickerStart).format(
						'ddd, MMM, Do YYYY'
					);
					employee.to_date = moment(props.rangePickerEnd).format(
						'ddd, MMM, Do YYYY'
					);

					employee.logData.some(log => {
						if (log.user_id === employee.id) {
							employee.total_time =
								employeeValues.totalHours + ':' + employeeValues.totalMinutes;
							employee.total_distance = employeeValues.totalDistanceTravelled;
							employee.total_expenses = employeeValues.totalExpenses;
						} else {
							return null;
						}
					});
				});
				let activeUser = tempActiveEmployees.filter(employee => {});
				setActiveEmployees(tempActiveEmployees);
			})

			.catch(err => console.log(err));
	}, [props.rangePickerStart, props.rangePickerEnd]);

	useEffect(() => {
		let tempActiveEmployees = allEmployees.filter(preFilterEmployee => {
			return props.selectedFilters.includes(preFilterEmployee.id);
		});

		setActiveEmployees(tempActiveEmployees);
	}, [props.selectedFilters]);

	const activeUser = activeEmployees.filter(
		employee => employee.id === props.user.id
	);
	const updateTotals = dataSource => {
		let totalMinutes = 0,
			totalHours = 0,
			totalDistanceTravelled = 0,
			totalExpenses = 0;

		dataSource.forEach(row => {
			if (row.total_time) {
				totalMinutes += parseFloat(
					row.total_time.substring(row.total_time.indexOf(':') + 1)
				);
				totalHours += parseFloat(
					row.total_time.substring(0, row.total_time.indexOf(':'))
				);
			}

			if (row.total_distance !== null && row.total_distance !== '')
				totalDistanceTravelled += parseFloat(row.total_distance);
			if (row.total_expenses !== null && row.total_expenses !== '')
				totalExpenses += parseFloat(row.total_expenses);
		});

		totalHours += Math.floor(totalMinutes / 60);
		totalMinutes = totalMinutes % 60;

		totalHours = totalHours.toFixed(0);
		totalDistanceTravelled = totalDistanceTravelled.toFixed(2) + ' Kms';
		totalExpenses = '$' + totalExpenses.toFixed(2);

		return { totalHours, totalMinutes, totalDistanceTravelled, totalExpenses };
	};

	const expandedRowRender = record => {
		const columns = [
			{
				title: 'Date',
				dataIndex: 'date'
			},
			{
				title: 'Start Time',
				dataIndex: 'start_time',
				align: 'right'
			},
			{
				title: 'End Time',
				dataIndex: 'end_time',
				align: 'right'
			},
			{
				title: 'Total Time',
				dataIndex: 'total_time',
				align: 'right'
			},

			{
				title: 'Total Distance Travelled(Kms) ',
				dataIndex: 'total_distance',
				align: 'right'
			},
			{
				title: 'Total Expenses($)',
				dataIndex: 'total_expenses',
				align: 'right'
			}
		];

		return (
			<Table
				rowKey={rowRecord => rowRecord.worklog_id}
				columns={columns}
				dataSource={record.logData}
				pagination={false}
			/>
		);
	};

	const summaryColumns = [
		{
			title: 'Employee ID',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Employee Name',
			dataIndex: 'employee_name'
		},
		{
			title: 'From Date',
			dataIndex: 'from_date'
		},
		{
			title: 'To Date',
			dataIndex: 'to_date'
		},

		{
			title: 'Total Hours',
			dataIndex: 'total_time',
			align: 'right'
		},

		{
			title: 'Total Distance Travelled(Kms) ',
			dataIndex: 'total_distance',
			align: 'right'
		},
		{
			title: 'Total Expenses($)',
			dataIndex: 'total_expenses',
			align: 'right'
		}
	];

	return (
		<>
			<Table
				rowKey={record => record.id}
				columns={summaryColumns}
				expandable={{
					expandedRowRender,
					defaultExpandedRowKeys: ['0']
					// onExpand: (expanded, record) => {
					// 	console.log('onExpand: ', record, expanded);
					// }
				}}
				// rowSelected={{ ...rowSelection }}
				dataSource={
					props.user.type === 'Manager' ? activeEmployees : activeUser
				}
				pagination={false}
			/>
		</>
	);
};

export default SummaryWorkLog;
