/* Used in Charge page to export airbnb charge data */

const ChargeExportHeaders = [
	{
		label: 'Invoice Date',
		key: 'invoice_date'
	},
	{
		label: 'Invoice No.',
		key: 'invoice_number'
	},
	{
		label: 'Charge Control ID',
		key: 'charge_control_id'
	},

	{
		label: 'Charge From',
		key: 'charge_from'
	},
	{
		label: 'Charge To',
		key: 'charge_to'
	},
	{
		label: 'Description',
		key: 'charge_description'
	},
	{
		label: 'Property',
		key: 'property_name'
	},
	{
		label: 'Charge Amount',
		key: 'gross_amount'
	},

	{
		label: 'HST Amount',
		key: 'gst_amount'
	},
	{
		label: 'Total Amount',
		key: 'total_amount'
	}
];

export default ChargeExportHeaders;
