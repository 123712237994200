import React from 'react';
import JobRequest from '../../Modals/JobRequest/JobRequest';
import { PropertyProvider } from '../../Context/PropertyContext';
import { withRouter } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import HeaderLayout from '../Header/Header';
import Main from '../Main/Main';
import { Layout, message } from 'antd';
import axios from 'axios';

import CommNotifications from '../../Context/CommNotifications';
import Cookies from 'js-cookie';
const refreshEvents = [
	'pointermove',
	'pointerdown',
	'pointerup',
	'keydown',
	'keyup'
];
var sessionExpiryMessage = null;
var sessionWarnCounter = null;
var sessionExpiryCounter = null;
var sessionRefreshCounter = null;
var lastRefresh = new Date();
var doRefresh = false;
let source = axios.CancelToken.source();

class MainLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			requestOpen: false,
			sidebarOpen: false,
			feedbackOpen: false,
			mobile: null,
			autoResize: true,
			collapseNavBar: false
		};
		this.openRequest = this.openRequest.bind(this);
		this.openSidebar = this.openSidebar.bind(this);
		this.openFeedback = this.openFeedback.bind(this);
		this.resize = this.resize.bind(this);
		this.collapseSideBar = this.collapseSideBar.bind(this);
		this.layoutRef = React.createRef();
	}

	openRequest() {
		this.setState({ requestOpen: !this.state.requestOpen });
	}
	openFeedback() {
		this.setState({ feedbackOpen: !this.state.feedbackOpen });
	}
	openSidebar() {
		this.setState({ sidebarOpen: !this.state.sidebarOpen });
	}
	refreshSessionCounter = () => {
		doRefresh = true;
		if (sessionWarnCounter !== null) clearTimeout(sessionWarnCounter);
		if (sessionExpiryCounter !== null) clearTimeout(sessionExpiryCounter);
		sessionWarnCounter = setTimeout(() => {
			sessionExpiryMessage = message.warning({
				content: 'Are you still there? Your session will expire in one minute!',
				key: 'expirynotice',
				duration: 0
			});
			if (sessionExpiryCounter !== null) clearTimeout(sessionExpiryCounter);
			sessionExpiryCounter = setTimeout(() => {
				sessionExpiryMessage();
				Cookies.remove('session');
				this.props.history.push('/login');
			}, 60000);
		}, 840000);
		if (Date.now() - lastRefresh.getTime() > 300000) {
			lastRefresh = new Date();
			source = axios.CancelToken.source();
			axios
				.post('/auth/refreshUser', {
					cancelToken: source.token,
					expireTime: Math.floor(Date.now() / 1000) + 900
				})
				.then(response => {
					//Session was found. Set user and reinitalize session in cookies
					if (response.data.ok) {
						Cookies.set('user', response.data.user, { expires: 1 });
						Cookies.set('session', {
							session_id: response.data.session_id,
							type: response.data.user.type
						});
						doRefresh = false;
						if (sessionExpiryMessage !== null) {
							message.success({
								content: 'Great!',
								key: 'expirynotice',
								duration: 0.5
							});
							sessionExpiryMessage = null;
						}
					} else {
						Cookies.remove('session');
						this.props.history.push('/login');
					}
				});
		}
	};

	// Layout & resizing

	collapseSideBar() {
		if (this.state.collapseNavBar)
			this.setState({ collapseNavBar: false, autoResize: false });
		else this.setState({ collapseNavBar: true, autoResize: false });
	}

	resize() {
		//https://stackoverflow.com/questions/44480053/how-to-detect-if-screen-size-has-changed-to-mobile-in-react
		if (this.state.autoResize === true && window.innerWidth < 1130) {
			this.setState({ collapseNavBar: true, mobile: window.innerWidth <= 760 });
		} else
			this.setState({
				collapseNavBar: false,
				mobile: window.innerWidth <= 760
			});
	}
	debounce(fn, delay) {
		//Throttles the function call so resize is called only once ever 250ms
		var timer = null; //https://remysharp.com/2010/07/21/throttling-function-calls
		return function () {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay);
		};
	}
	componentDidMount() {
		let destination = Cookies.get('destination');
		if (destination) {
			let newDestination = JSON.parse(destination).location;
			Cookies.remove('destination');
			this.props.history.push(newDestination);
		}
		window.addEventListener('resize', this.debounce(this.resize, 250));
		refreshEvents.forEach(eventType => {
			document.addEventListener(eventType, this.refreshSessionCounter);
		});
		lastRefresh = new Date();
		sessionWarnCounter = setTimeout(() => {
			sessionExpiryMessage = message.warning({
				content: 'Are you still there? Your session will expire in one minute!',
				key: 'expirynotice',
				duration: 0
			});
			sessionExpiryCounter = setTimeout(() => {
				sessionExpiryMessage();
				Cookies.remove('session');
				Cookies.set(
					'destination',
					{
						location: window.location.pathname
					},
					{ sameSite: 'strict' }
				);
				this.props.history.push('/login');
			}, 60000);
		}, 840000);
		sessionRefreshCounter = setInterval(() => {
			if (doRefresh && Date.now() - lastRefresh.getTime() > 300000) {
				lastRefresh = new Date();
				source = axios.CancelToken.source();
				axios
					.post('/auth/refreshUser', {
						cancelToken: source.token,
						expireTime: Math.floor(Date.now() / 1000) + 900
					})
					.then(response => {
						//Session was found. Set user and reinitalize session in cookies
						if (response.data.ok) {
							Cookies.set('user', response.data.user, { expires: 1 });
							Cookies.set('session', {
								session_id: response.data.session_id,
								type: response.data.user.type
							});
							doRefresh = false;
							if (sessionExpiryMessage !== null) {
								message.success({
									content: 'Great!',
									key: 'expirynotice',
									duration: 0.5
								});
								sessionExpiryMessage = null;
							}
						} else {
							Cookies.remove('session');
							Cookies.set(
								'destination',
								{
									location: window.location.pathname
								},
								{ sameSite: 'strict' }
							);
							this.props.history.push('/login');
						}
					});
			}
		}, 1000);
		this.resize();
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		refreshEvents.forEach(eventType => {
			document.removeEventListener(eventType, this.refreshSessionCounter);
		});
		if (source) source.cancel('User Moved to New Page');
		if (sessionWarnCounter !== null) clearTimeout(sessionWarnCounter);
		if (sessionExpiryCounter !== null) clearTimeout(sessionExpiryCounter);
		if (sessionRefreshCounter !== null) clearInterval(sessionRefreshCounter);
		if (sessionExpiryMessage !== null) sessionExpiryMessage();
	}

	//

	render() {
		return (
			<Layout className='h100'>
				<Sidebar
					openFeedback={this.openFeedback}
					collapseNavBar={this.state.collapseNavBar}
					mobile={this.state.mobile}
					sidebarOpen={this.state.sidebarOpen}
					openSidebar={this.openSidebar}
					openRequest={this.openRequest}
				/>
				<PropertyProvider>
					<Layout>
						<HeaderLayout
							pathName={this.props.pathName}
							openSidebar={this.openSidebar}
							collapseSideBar={this.collapseSideBar}
							mobile={this.state.mobile}
							openRequest={this.openRequest}
						/>
						<Main
							path={this.state.path}
							updatePath={this.updatePath}
							userType={this.props.userType}
						/>
					</Layout>
					{this.state.requestOpen ? (
						<JobRequest
							history={this.props.history}
							open={this.state.requestOpen}
							openRequest={this.openRequest}
						/>
					) : (
						''
					)}
				</PropertyProvider>
			</Layout>
		);
	}
}

/*
return(
            <div className='h100'>
                <Sidebar openFeedback={this.openFeedback} collapseNavBar={this.props.collapseNavBar} mobile={this.props.mobile} sidebarOpen={this.state.sidebarOpen} openSidebar={this.openSidebar} />
                    <div className='d-flex flex-column h100 of-hidden'>
                        <Header pathName={this.props.pathName} openSidebar={this.openSidebar} collapseSideBar={this.props.collapseSideBar} mobile={this.props.mobile} openRequest={this.openRequest}/>       
                        <Main path={this.state.path} updatePath={this.updatePath}/>
                    </div> 
                <PropertyProvider>
                    {this.state.requestOpen ? <JobRequest open={this.state.requestOpen} openRequest={this.openRequest}/> : ''}            
                </PropertyProvider>

                {this.state.feedbackOpen ? <Feedback mobile={this.props.mobile} open={this.state.feedbackOpen} openFeedback={this.openFeedback} /> : '' }
            </div>
        )    
*/

export default withRouter(MainLayout);
