export const vendorServices = {
	accessSecurity: 'Access & Security',
	'appliance repair': 'Appliance Repair',
	brickLayer: 'Brick Layer',
	'bulding supplies': 'Building Supplies',
	cableAndData: 'Cabling and Data',
	cabinetMaker: 'Cabinet Maker',
	carpenter: 'Carpenter',
	cleaning: 'Cleaning',
	electrical: 'Electrical',
	environmental: 'Environmental',
	'fire protection': 'Fire Protection',
	flooring: 'Flooring',
	gasFitter: 'Gas Fitter',
	'general repair': 'General Repair',
	'glass wholesale': 'Glass Wholesale',
	heating: 'Heating',
	homeInspector: 'Home Inspector',
	'hot tub service': 'Hot Tub Service',
	hvac: 'HVAC',
	landscaping: 'Landscaping',
	'lock smith': 'Lock Smith',
	'Miscellaneaous supply': 'Miscellaneaous Supply',
	oilBurnerMechanic: 'Oil Burner Mechanic',
	'paint supply': 'Paint Supply',
	'Pest control': 'Pest Control',
	'plasterPaint service': 'Plaster & Paint Service',
	plumbing: 'Plumbing',
	'plumbing supply': 'Plumbing Supply',
	'propane service': 'Propane Service',
	propertyManagement: 'Property Management',
	'Property restoration': 'Property Restoration',
	refrigerationAC: 'Refrigeration & AC',
	roofing: 'Roofing',
	sheetMetalWorker: 'Sheet Metal Worker',
	snowClearing: 'Snow Clearing',
	tileSetter: 'Tile Setter',
	ventilation: 'Ventilation',
	'waste management': 'Waste Management',
	windowsDoors: 'Windows & Doors',
	other: 'Other'
};
export const vendorProvinces = {
	AB: 'Alberta',
	BC: 'British Columbia',
	MB: 'Manitoba',
	NB: 'New Brunswick',
	NL: 'Newfoundland and Labrador',
	NT: 'Northwest Territories',
	NS: 'Nova Scotia',
	NU: 'Nunavut',
	ON: 'Ontario',
	PE: 'Prince Edward Island',
	QC: 'Quebec',
	SK: 'Saskatchewan',
	YT: 'Yukon'
};
