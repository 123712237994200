import React, { Component } from 'react';
import axios from 'axios';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import './WorkOrder.css';
import { createUseStyles } from 'react-jss';
import Troubleshoot from './Pages/Troubleshoot.js';
import MenuScreen from './Pages/MenuScreen.js';
import Images from './Pages/Images';
import Materials from './Pages/Materials';

import WorkOrderContext from './WorkOrderContext';
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext';
import { Modal, Button } from 'antd';
import EstimateMenu from './Pages/EstimateMenu';
import LoadScreen from '../../Components/LoadScreen';

const PageComponents = {
	Troubleshoot: <Troubleshoot />,
	Menu: <MenuScreen />,
	Images: <Images />,
	Materials: <Materials />,
	'Estimate Menu': <EstimateMenu />
};
const WorkOrderClasses = createUseStyles({
	backBtn: {
		display: 'inline-block',
		marginRight: 10
	},
	cardContainer: {
		width: '90%',
		height: 70,
		padding: 10,
		lineHeight: '50px',
		cursor: 'pointer',
		marginBottom: '5px',
		'&:hover': {
			color: '#4d7cfe'
		}
	},
	color: {
		backgroundColor: '#e6f7ff',
		borderRadius: 5
	},
	rightCircle: {
		float: 'right',
		display: 'inline-block',
		lineHeight: '56px'
	},
	footer: {
		borderTop: 'thin solid #e3e3e3',
		textAlign: 'right',
		paddingTop: '8px'
	}
});

const workOrderTitle = {
	Entry: 'Entry Details',
	Menu: 'Main Menu',
	Troubleshoot: 'Troubleshoot Problem',
	Images: 'Upload Images',
	Materials: 'Estimate Expenses',
	Action: 'Actions Required',
	Labour: 'Labour Needed',
	CostOptions: 'Cost Details',
	Expenses: 'Total Expenses',
	FollowUp: 'Follow Up Required',
	ErrorPage: 'Error',
	UploadTroubleshoot: 'UploadTroubleshoot'
};
class WorkOrderTroubleshoot extends Component {
	static contextType = RequestContext;
	constructor(props) {
		super();
		this.state = {
			page: 'Menu',
			getEventAction: () => {
				console.log(this.context.activeEvent.users);
				return this.context.activeEvent;
			},
			goToMainMenu: () => {
				this.setState({ page: 'Menu' });
			},
			changePage: page => this.setState({ page: page }),
			updateEntryDetails: (auth = '', time, tenant = '', date) => {
				this.setState({
					entryData: {
						authType: auth,
						timeIn: time,
						tenant: tenant,
						date: date
					}
				});
			},
			updateTroubleshootData: (description, images) => {
				this.setState({
					troubleshootData: { description: description, images: images }
				});
			},
			updateImages: images => {
				this.context.updateImages(images);
			}
		};
	}
	componentDidMount() {
		this.setState({
			getEventData: () => {
				return this.context.validEvents;
			},
			jobData: {
				job_id: this.context.request.job_id,
				vendors: this.context.request.vendors,
				workOrders: this.context.activeEvent
					? this.context.activeEvent.workOrders
					: [],
				session_id: this.context.session_id
			},
			status: this.context.request.status
		});
		let request_job_id = this.context.request.job_id;
		axios
			.post('/workOrder/get/workOrder/' + request_job_id)
			.then(response => {
				this.setState({
					tenantList: response.data.tenantList
				});
			})
			.catch(err => {
				this.setState({ currentPage: 'errorPage', error: err });
			});
	}

	render() {
		console.log(this.context);
		return (
			<WorkOrderContext.Provider value={this.state}>
				<Modal
					width={'750px'}
					className='workOrderModal'
					title={
						<>
							{this.state.page !== 'Entry' && this.state.page !== 'Menu' ? (
								<BackBtn goBack={this.state.goToMainMenu} />
							) : (
								''
							)}
							<div className='d-inline-block'>
								{workOrderTitle[this.state.page]}
							</div>
						</>
					}
					visible={this.props.modalOpen}
					onCancel={this.props.onCancel}
					footer={null}
					bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
				>
					<div id='wo-modal-body'>{PageComponents[this.state.page]}</div>
				</Modal>
			</WorkOrderContext.Provider>
		);
	}
}
const BackBtn = props => {
	const classes = WorkOrderClasses();

	return (
		<ArrowLeftOutlined onClick={props.goBack} className={classes.backBtn} />
	);
};
export default WorkOrderTroubleshoot;
