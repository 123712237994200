import React from 'react';
import { Form, DropdownButton, Modal } from 'react-bootstrap';
import axios from 'axios';

import { Button, Dropdown, Menu, Card } from 'antd';
import { ProfileAvatar } from '../Components/Avatar';
import Moment from 'react-moment';
import './marketListing.css';
import '../App.css';
import DirectJR from './directJR';
import { MarketListing } from './MarketListing';
const cancelToken = axios.CancelToken.source();
//import { useUserState, useUserDispatch } from "../Context/UserContext";

export const RequestDescription = ({ request, updateImages }) => {
	return (
		<div className='requestDescription'>
			<Card
				id=''
				title={<div className='fs-18 g-text'>Request Details</div>}
				bordered={false}
				extra={null}
			>
				<ProfileAvatar
					name={request.req_fname + ' ' + request.req_lname}
					image='https://cdn3.f-cdn.com/contestentries/1376995/30494909/5b566bc71d308_thumb900.jpg'
				/>

				<div className='g-text mt-2 fs-12' id='requestDate'>
					<Moment format='[Request submitted on: ]DD/MM/YY [at] h:mma'>
						{request.time}
					</Moment>
				</div>
				<div id='requestDescription'>{request.service_description}</div>
				{/*<ImageDisplay images={request.images} />*/}
			</Card>
		</div>
	);
};

class MakeNewBid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			jobid: this.props.job_id,
			biddata: {
				bidprice: 0,
				bidtype: null,
				bidnotes: null,
				bidQuestion: null
			}
		};
		this.selectBidType = this.selectBidType.bind(this);
		this.selectBidDescription = this.selectBidDescription.bind(this);
		this.selectBidPrice = this.selectBidPrice.bind(this);
		this.selectBidQuestion = this.selectBidQuestion.bind(this);
	}

	selectBidType(type) {
		this.setState({ biddata: { bidtype: type } });
	}
	selectBidDescription(description) {
		this.setState({ biddata: { bidnotes: description } });
	}
	selectBidPrice(price) {
		this.setState({ biddata: { bidprice: price } });
	}
	selectBidQuestion(question) {
		this.setState({ biddata: { bidQuestion: question } });
	}
	submitbid = () => {
		axios
			.post('/make_bid', {
				jobid: this.state.jobid,
				//type: this.state.biddata.bidtype,
				type: 'job',
				info: this.state.biddata.bidnotes,
				//price: this.state.biddata.bidprice,
				price: 13,
				bidQuestion: this.state.biddata.bidQuestion
			})
			.then(response => {
				if (response.statusText == 'OK') {
					this.setState({ showbid: false });
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		return (
			<>
				<Modal show={this.props.showbid} onHide={this.props.hidebid}>
					<Modal.Header closeButton>
						<Modal.Title>Make Bid</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group controlId='bidprice'>
								<Form.Label>Bid Price</Form.Label>
								<Form.Control type='number' placeholder='Enter price in $' />
							</Form.Group>
							<Form.Group controlId='bidtye'>
								<Form.Label>Price per</Form.Label>
								<Form.Control as='select'>
									<option>Per job</option>
									<option>Per hr</option>
									<option>Other</option>
								</Form.Control>
							</Form.Group>
							<Form.Group controlId='bidnotes'>
								<Form.Label>Notes</Form.Label>
								<Form.Control
									type='text'
									placeholder='Enter notes or description'
									value={this.props.bidnotes}
									onChange={e => this.selectBidDescription(e.target.value)}
								/>
							</Form.Group>
							<Form.Group controlId='bidnotes'>
								<Form.Label>Questions</Form.Label>
								<Form.Control
									type='text'
									placeholder='Ask your questions and they sre visible to public'
								/>
							</Form.Group>

							<Button variant='primary' onClick={this.submitbid}>
								Submit
							</Button>
						</Form>
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</>
		);
	}
}

class BidListing extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='site-card-border-less-wrapper'>
				<Card
					title={<div className='fs-18 g-text'>Bid Details</div>}
					bordered={false}
				>
					<p>
						Bid Price : {this.props.bidd.bid_price} per{' '}
						{this.props.bidd.bid_type}
					</p>
					<p>Vendor Name : {this.props.bidd.vendorname}</p>
					<p>Time : {this.props.bidd.bid_time}</p>
					<Button onClick={this.acceptbid}>Accept</Button>
					<Button>Reject</Button>
				</Card>
			</div>
		);
	}
}

class ListingDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listingdetail: [],
			bidclicked: false,
			sendJRclicked: false,
			jobid: 12,
			biddata: []
		};
	}
	componentWillMount() {
		this.getData().then(data => {
			this.setState({
				listingdetail: data.ad_detail,
				jobid: data.ad_detail.job_id,
				biddata: data.bids,
				showbidlist: false
			});
		});
	}

	getData() {
		let adid = this.props.match.params.adid;
		return axios
			.get('/api/GetListingDetail/' + adid, { crossdomain: true })
			.then(function (response) {
				return response.data;
			});
	}

	bidclick() {
		this.setState({ bidclicked: true });
	}
	hidebidd() {
		this.setState({ bidclicked: false });
	}
	hideSendJR() {
		this.setState({ sendJRclicked: false });
	}

	senddirectJR() {
		this.setState({ sendJRclicked: true });
	}

	render() {
		const adtype = this.state.listingdetail.type;
		let click;
		if (adtype == 'job') {
			click = (
				<button
					type='button'
					className='width10 btn btn-primary height30Fixed'
					onClick={this.bidclick.bind(this)}
				>
					Make bid
				</button>
			);
		} else {
			click = (
				<button
					type='button'
					className='width20 btn btn-primary height90Fixed'
					onClick={this.senddirectJR.bind(this)}
				>
					Send Direct Job Request
				</button>
			);
		}
		return (
			<div>
				<div id='displayShell'>
					<MarketListing
						icon='propertyExample1.jpg'
						Category={this.state.listingdetail.service_subcategories}
						Description={this.state.listingdetail.service_description}
						City={this.state.listingdetail.location}
						link={this.state.listingdetail.idmarket_ads}
						bidsno={this.state.listingdetail.bidsno}
					/>
					<RequestDescription
						request={this.state.listingdetail}
						updateImages={null}
					/>
				</div>
				<div id='BiddComp'>
					<Card
						id=''
						title={<div className='fs-18 g-text'>Request Details</div>}
						bordered={false}
						extra={null}
					>
						{click}
						<MakeNewBid
							showbid={this.state.bidclicked}
							hidebid={this.hidebidd.bind(this)}
							job_id={this.state.jobid}
						></MakeNewBid>
						<DirectJR
							sendJR={this.state.sendJRclicked}
							hideJR={this.hideSendJR.bind(this)}
						></DirectJR>
						<br></br>
						<div>
							<Modal
								show={this.state.showbidlist}
								onHide={() => {
									this.setState({ showbidlist: false });
								}}
							>
								<Modal.Header>The list of bids</Modal.Header>
								<Modal.Body>
									{this.state.biddata.map(bid => (
										<BidListing icon='propertyExample1.JPG' bidd={bid} />
									))}
								</Modal.Body>
								<Modal.Footer>
									<Button
										onClick={() => {
											this.setState({ showbidlist: false });
										}}
									>
										Cancel
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</Card>
				</div>
			</div>
		);
	}
}

export { ListingDetail };
