import React from "react";
import { Button } from "antd";

/*
    Buttons used in the footer of forms to change the pages. Onclick functions are passed in parameters
*/

export function FooterNext({ className, clickNext }) {
  return (
    <Button type="primary" className={className} onClick={clickNext}>
      Next
    </Button>
  );
}
export function FooterBack({ className, clickBack }) {
  return (
    <Button className={className} onClick={clickBack}>
      Back
    </Button>
  );
}
export function FooterBoth({ className, clickNext, clickBack,backName="Back",nextName="Next",nextDisabled=false }) {
  return (
    <div>
      <Button
        variant="outline-primary"
        className={className + " mr-2 btnLabel"}
        onClick={clickBack}
      >
        {backName}
      </Button>
      <Button type="primary" onClick={clickNext} disabled={nextDisabled} className="btnLabel">
        {nextName}
      </Button>
    </div>
  );
}
export function FooterEnd({ className, clickBack, clickEnd, endDisabled=false }) {
  return (
    <div>
      <Button
        variant="outline-primary"
        className={className + " mr-2 btnLabel"}
        onClick={clickBack}
      >
        Back
      </Button>
      <Button type="primary" className="btnLabel" disabled={endDisabled} onClick={clickEnd}>
        Submit
      </Button>
    </div>
  );
}
export function FooterClose({ className, clickClose }) {
  return (
    <Button
      variant="secondary"
      className={className + " mr-2 btnLabel"}
      onClick={clickClose}
    >
      Close
    </Button>
  );
}

export function FooterSubmit({ className, clickEnd }) {
  return (
    <Button type="primary" className={className} onClick={clickEnd}>
      Submit
    </Button>
  );
}
