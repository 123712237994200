import React from "react";
import "antd/dist/antd.css";
import { Menu, Modal, Button } from "antd";
import {MenuOutlined} from "@ant-design/icons";
import Feature from "./Feature";

const { SubMenu } = Menu;
class FeatureList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: {},
      deletable: true,
      name: "",
      modalVisible: false,
      selfName: props.selfName,
      currentKey: null,
    };
    this.addNewFeature = this.addNewFeature.bind(this);
    this.afterOk = this.afterOk.bind(this);
    this.afterCancel = this.afterCancel.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.deleteFeature = this.deleteFeature.bind(this);

    if (this.state.selfName === "Structure") {
      this.state.deletable = false;
    }

    let d = new Date();
    let n = d.getTime();
    if (Object.keys(props.features).length > 0) {
      Object.keys(props.features).map((keys) => {
        n = n + 1;
        this.state.features[n] = <Feature id={n} name={keys} />;
        let image = props.features[keys].imageUrl;
        if (image === undefined) {
          image = "";
        }
        this.props.menu(n, {
          room: this.props.roomName,
          roomId: this.props.roomId,
          imageUrl: image,
          featureType: this.state.selfName,
          name: keys,
          date: props.features[keys].date,
          brand: props.features[keys].brand,
          make: props.features[keys].make,
          model: props.features[keys].model,
          serialNum: props.features[keys].serialNum,
          age: props.features[keys].age,
          width: props.features[keys].width,
          height: props.features[keys].height,
          widthMeasurement: props.features[keys].widthMeasurement
        });
      });
    }

    if(Object.keys(props.features).length === 0 && this.state.selfName === "Room Structure"){
      n = n + 1;
      this.state.features[n] = <Feature id={n} name={"Structure"} />;
      this.props.menu(n, {
        room: this.props.roomName,
        imageUrl: "",
        roomId: this.props.roomId,
        featureType: this.state.selfName,
        name: "Structure",
        date: "",
        brand: "",
        make: "",
        model: "",
        serialNum: "",
        age: "",
        width: "",
        height: "",
        widthMeasurement: "",
        heightMeasurement: "",
      });
    }
  }

  addNewFeature() {
    this.setState({ modalVisible: true });
  }

  nameChange(event) {
    this.setState({ name: event.target.value });
  }

  afterOk() {
    if (this.state.name.length != 0) {
      let d = new Date();
      let n = d.getTime();
      this.state.features[n] = <Feature id={n} name={this.state.name} />;
      //THE FORM THAT WILL APPEAR ON THE RIGHT (second input)
      this.props.menu(n, {
        room: this.props.roomName,
        imageUrl: "",
        roomId: this.props.roomId,
        featureType: this.state.selfName,
        name: this.state.name,
        date: "",
        brand: "",
        make: "",
        model: "",
        serialNum: "",
        age: "",
        width: "",
        height: "",
        widthMeasurement: "",
        heightMeasurement: "",
      });
      this.props.selected(n);

      this.setState({
        features: this.state.features,
        name: "",
        modalVisible: false,
      });
    } else {
      this.afterCancel();
    }
  }

  afterCancel() {
    this.setState({ modalVisible: false, name: "" });
  }

  deleteFeature(id) {
    delete this.state.features[id];
    this.props.deleteMenu(id);
    this.setState({ features: this.state.features });
  }
  render() {
    return (
      <Menu
        mode="inline"
        inlineIndent="30"
        style={{ height: "100%", width: "100%", borderRight: "0" }}
      >
        <SubMenu
          icon={<MenuOutlined />}
          key={this.state.selfName}
          title={this.state.selfName}
          style={{borderRight: "0"}}
        >
          {this.state.selfName !== "Room Structure" && <div>
            <Button
              type="text"
              block={true}
              style={{ textAlign: "left", textIndent: 50, fontSize: 14 }}
              onClick={this.addNewFeature.bind(this)}
            >
              <b>+New {this.state.selfName}</b>
            </Button>
          </div>}
          {Object.keys(this.state.features).map((feature) => (
            <div key={feature}>
              {this.state.selfName !== "Room Structure" && 
              <Button 
                style={{float:'left'}}
                type="dashed" 
                onClick={()=> this.deleteFeature(feature)}>
                  X
              </Button>}
              <Button
                type="text"
                block={true}
                style={{
                  width:'80%',
                  fontSize: 14,
                  textIndent: 80,
                  textAlign: "left",
                  color: "DodgerBlue",
                }}
                key={feature}
                onClick={() => this.props.selected(feature)}
              >
                {this.state.features[feature]}
              </Button>
            </div>
          ))}
          {
            //this.state.deletable ? <div><Button block={true} style={{fontSize:14, textAlign:"left", textIndent:50}}type="text" onClick={()=>this.props.delete(this.props.listId, this.state.features)}><b>-Remove {this.state.selfName} List</b></Button></div> : <></>
          }
          <Modal
            title="Feature Details"
            visible={this.state.modalVisible}
            onOk={this.afterOk}
            onCancel={this.afterCancel}
          >
            <label>
              Name:
              <input
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.nameChange}
              />
            </label>
          </Modal>
        </SubMenu>
      </Menu>
    );
  }
}
export default FeatureList;
