import React from 'react'
import { ExtendCardAction,PauseCardAction,DoneCardAction, WorkOrderFooter,MaterialCardAction,UploadCardAction } from '../WorkOrder/WorkOrder'
import {Button} from 'antd'
import WorkOrderContext from '../WorkOrder/WorkOrderContext'
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext'
import { ToolOutlined } from '@ant-design/icons';



const ManageMenu = () => {
    const context = React.useContext(WorkOrderContext);
    const requestContext=React.useContext(RequestContext)
    return(
        <div>
            <PauseCardAction   text='Pause Work Order ' action={() => context.changePage("Pause")} />
            <DoneCardAction text='Complete Work Order' action={() => context.changePage("Complete")} />
            <ExtendCardAction text='Request Extension' action={() => context.changePage("Request Extension")} />
            {
            //<CardAction text='Enter actions taken' action={() => context.changePage("Action")} />
            //<CardAction text='Enter labour details' action={() => context.changePage("Labour")} />
            }
        </div>
    
    )
}

export default ManageMenu