import axios from 'axios';
import React, { useEffect, useState } from 'react';

const InvoiceFile = props => {
	const [invoiceDoc, setInvoiceDoc] = useState('');

	useEffect(() => {
		axios
			.post('/workOrder/getUploadedInvoice', { key: props.invoiceData[0].file })
			.then(res => {
				console.log(res.data.url);
				setInvoiceDoc(res.data.url);
			});
	});

	return (
		<div>
			<a href={invoiceDoc} target='_blank'>
				Download
			</a>
		</div>
	);
};

export default InvoiceFile;
