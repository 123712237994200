import React from 'react';
import '../JobRequests/maintenance.css';
import LoadScreen from '../../Components/LoadScreen';
import axios from 'axios';
import Moment from 'moment';

import RequestActions from './RequestActions';
import './maintenanceRequest.css';
import ActivityLog from './RequestActivityLog';
import RequestDescription from './RequestDescription';
import { Result, Button, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { RequestVendor } from './RequestVendor';
import NoAccess from '../../Components/NoAccess';
import ReservationDetails from './actions/ReservationDetails';
import { MaintenanceRequestListing } from '../JobRequests/RequestListing';
import RequestContext from './RequestContext';
import { UserStateContext } from '../../Context/UserContext';
import Modal from 'antd/lib/modal/Modal';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
const cancelToken = axios.CancelToken.source();

var eventCounter = null;

export class UnitMaintenanceRequest extends React.Component {
	static contextType = UserStateContext;

	constructor(props) {
		super();
		this.state = {
			//both id & request retrieved before page loads
			request_id: null,
			request: null,
			access: null,
			//Determines whole request render. Loading/error/request
			page: 'Loading',
			errorMessage: '',
			newAvailability: false,
			calData: [],
			workers: [],
			lockOn: false,
			eventTenantConsented: false,
			eventCurrentUserAccepted: false,
			eventAllWorkersAccepted: false,
			activeEvent: null,
			upcomingEvent: null,
			eventToAccept: null,
			validEvents: [],
			currentWorkOrder: null,
			user: null,

			refreshList: () => {
				if ('fetchRequests' in this.props) {
					this.props.fetchRequests(true);
				}
			},

			updateStatus: (status, updatedRequest = null) => {
				if (updatedRequest === null)
					this.setState({
						request: { ...this.state.request, job_status: status }
					});
				else this.setState({ request: updatedRequest });
				this.state.refreshList();
			},

			terminateVendor: (vendor_id, endDate) => {
				let temp = [...this.state.request.vendors];
				let currVendor = temp.findIndex(v => v.vendor_id === vendor_id);
				if (currVendor === -1) {
					return;
				}
				temp[currVendor] = {
					...temp[currVendor],
					endDate: endDate,
					terminate: 1
				};

				this.setState({ request: { ...this.state.request, vendors: temp } });
				return;
			},
			terminateEmployee: (employee_id, endDate) => {
				let temp = [...this.state.request.employees];
				let currEmployee = temp.findIndex(v => v.employee_id === employee_id);
				if (currEmployee === -1) {
					return;
				}
				temp[currEmployee] = {
					...temp[currEmployee],
					endDate: endDate,
					terminate: 1
				};

				this.setState({ request: { ...this.state.request, employees: temp } });
				return;
			},

			//Schedule New Event
			scheduleWork: event => {
				let currentSchedule =
					this.state.request.workSchedule === undefined
						? []
						: [...this.state.request.workSchedule];
				currentSchedule.push(event);
				this.setState({
					request: { ...this.state.request, workSchedule: currentSchedule }
				});
				return;
			},
			updateWork: newEvents => {
				let tempEventList = [];
				this.setState({
					request: { ...this.state.request, workSchedule: newEvents }
				});
				tempEventList = this.state.addValidEventsForActions(newEvents);
				this.eventInterval(tempEventList, true);
			},
			//Edits the details of one event
			editEvent: updatedEvent => {
				let events = [...this.state.request.workSchedule];
				let index = events.findIndex(
					event => event.idcalendar_events === updatedEvent.idcalendar_events
				);
				events.splice(index, 1, updatedEvent);
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			//Deletes a scheduled event
			cancelEvent: id => {
				let events = this.state.request.workSchedule;
				let index = events.findIndex(event => event.idcalendar_events === id);
				events[index].terminate = 1;
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			completeEvent: id => {
				let events = this.state.request.workSchedule;
				let index = events.findIndex(event => event.idcalendar_events === id);
				events[index].complete = 1;
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			// valid events is events where the start date is not before the current date. Returns that list of valid events if needed.
			addValidEventsForActions: eventList => {
				if (eventList !== undefined && eventList.length > 0) {
					let currentDate = Moment().valueOf();
					let tempEventList = [];
					eventList.forEach(evt => {
						let jrStatusCheck = evt.complete == 1 || evt.terminate == 1;
						// if event end date is after the current date and if event is not completed or terminated.
						if (Moment(evt.end) > currentDate && !jrStatusCheck) {
							tempEventList.push(evt);
						}
					});
					this.setState({ validEvents: tempEventList });
					return tempEventList;
				}
			},
			/* Adds new activity logs to the activity logs list list */
			addActivity: (activity = [], count = -1, newLog = false) => {
				if (!Array.isArray(activity)) return;
				if (this.state.request.logs == null) {
					return this.setState({
						request: { ...this.state.request, logs: activity }
					});
				}
				let newLogs = newLog
					? activity.concat(this.state.request.logs)
					: this.state.request.logs.concat(activity);

				if (count !== -1 && count !== null)
					this.setState({
						request: { ...this.state.request, logs: newLogs, logCount: count }
					});
				else
					this.setState({ request: { ...this.state.request, logs: newLogs } });
			},
			/* Used when adding an image to the request */
			updateImages: images => {
				let currentRequest = { ...this.state.request };
				let matchIndex;
				if (Array.isArray(currentRequest.images)) {
					let unmatchedImages = currentRequest.images.map(
						(unmatchImage, unmatchIndex) => {
							return [unmatchImage, unmatchIndex];
						}
					);
					images.forEach(newImage => {
						matchIndex = unmatchedImages.findIndex(oldImage => {
							return newImage.id === oldImage[0].id;
						});
						if (matchIndex >= 0) {
							currentRequest.images[unmatchedImages[matchIndex][1]] = newImage;
							unmatchedImages.splice(matchIndex, 1);
						} else currentRequest.images.push(newImage);
					});
				} else currentRequest.images = images;
				this.setState({ request: currentRequest });
			},

			//Upate title
			updateTitle: title => {
				this.setState({ request: { ...this.state.request, job_title: title } });
			},
			//Update description
			updateDescription: description => {
				this.setState({
					request: { ...this.state.request, job_description: description }
				});
			},
			updateVendors: (vendors = []) => {
				if (vendors.length == 0) return;
				let updatedVendors = vendors.concat(this.state.request.vendors);
				let updatedActiveVendors = vendors.concat(
					this.state.request.activeVendors
				);
				this.setState({
					request: {
						...this.state.request,
						vendors: updatedVendors,
						activeVendors: updatedActiveVendors
					}
				});
			},

			updateEmployees: (employees = []) => {
				if (employees.length == 0) return;
				let updatedEmployees = employees.concat(this.state.request.employees);
				let updatedActiveEmployees = employees.concat(
					this.state.request.activeEmployees
				);
				this.setState({
					request: {
						...this.state.request,
						employees: updatedEmployees,
						activeEmployees: updatedActiveEmployees
					}
				});
			},

			updateStateCurrentWorkOrder: workOrders => {
				if (workOrders) {
					let latestWOTime = new Date(
						Math.max.apply(
							null,
							workOrders
								.filter(workOrder => workOrder.status !== 'Complete')
								.map(function (wo) {
									return new Date(wo.time_in);
								})
						)
					);

					let latestWO = workOrders.filter(workOrder => {
						let tempDate = new Date(workOrder.time_in);
						return tempDate.getTime() == latestWOTime.getTime();
					});

					this.setState({ currentWorkOrder: latestWO[0] });
				}
			},

			updateRequestWorkOrders: event_id => {
				axios
					.post('/workOrder/get/workOrder/' + event_id)
					.then(response => {
						this.setState({
							request: {
								...this.state.request,
								workOrders: response.data.workOrders
							}
						});
						this.state.updateStateCurrentWorkOrder(response.data.workOrders);
					})
					.catch(err => console.log(err));
			},
			updateWorkOrders: workOrders => {
				if (workOrders !== null) {
					let currentWorkOrderIndex = workOrders.findIndex(
						workOrder => workOrder.status !== 'Complete'
					);
					this.setState({
						currentWorkOrder: workOrders[currentWorkOrderIndex],
						request: { ...this.state.request, workOrders: workOrders }
					});
				}
			},

			updateEventAcceptance: eventToUpdate => {
				axios
					.post('/jobRequest/update/event/accept', {
						event_id: eventToUpdate.idcalendar_events
					})
					.then(response => {
						let newWorkSchedule = [...this.state.request.workSchedule];
						let updatedEventIndex = newWorkSchedule.findIndex(
							evt => evt.idcalendar_events === eventToUpdate.idcalendar_events
						);
						let updatedUserIndex = newWorkSchedule[
							updatedEventIndex
						].users.findIndex(
							user =>
								user.user_id === this.context.user.id &&
								(user.user_type === this.context.user.type ||
									(user.user_type === 'Employee' &&
										(this.context.user.type === 'Manager' ||
											this.context.user.type === 'Vendor')))
						);

						newWorkSchedule[updatedEventIndex].users[
							updatedUserIndex
						].has_accepted = 1;

						eventToUpdate.idcalendar_events ===
						(this.state.activeEvent !== null &&
							this.state.activeEvent.idcalendar_events)
							? this.updateActiveEvent(newWorkSchedule[updatedEventIndex])
							: this.updateUpcomingEvent(newWorkSchedule[updatedEventIndex]);
						this.state.updateWork(newWorkSchedule);
						this.setState({
							eventCurrentUserAccepted: !this.state.checkUserNotAccepted(
								newWorkSchedule[updatedEventIndex].users
							)
						});
						return;
					})
					.catch(err => {
						if (err) {
							message.error('An Error Occurred! Please try again.');
						}
					});
			},

			checkUserNotAccepted: (eventUsers = []) => {
				return eventUsers.some(
					eventUser =>
						eventUser.user_id == this.context.user.id &&
						(eventUser.user_type === this.context.user.type ||
							(eventUser.user_type === 'Employee' &&
								(this.context.user.type === 'Manager' ||
									this.context.user.type === 'Vendor'))) &&
						eventUser.has_accepted === null
				);
			},

			checkAllUsersAccepted: (eventUsers = []) => {
				return !eventUsers.some(eventUser => eventUser.has_accepted === null);
			},

			setError: (error_name, error_desc) => {
				this.setState({
					page: 'Error',
					errorMessage: [error_name, error_desc]
				});
				return;
			}
		};
	}
	openNewAvailability = () =>
		this.setState({ newAvailability: !this.state.newAvailability });

	async componentDidMount() {
		// let route = window.location.href;
		// let request = this.props.job_id || route.split('/')[5];
		let request = this.props.job_id || this.props.unit_id;
		console.log(request);
		let request_id = parseInt(request);
		this.setState({ request_id: request_id, user: this.context.user });
		let selectedRequest = await this.getRequest(request_id);
		let selectedAvailability = await this.getAvailability();

		// Event interval Initialization
		if (selectedRequest !== null) {
			let tempEventList = [];

			tempEventList = this.state.addValidEventsForActions(
				selectedRequest.workSchedule
			);
			this.eventInterval(tempEventList, true);
			eventCounter = setInterval(this.eventInterval, 2000);
		}
	}

	componentDidUpdate = async (prevProps, prevState) => {
		if (
			'match' in prevProps &&
			prevProps.match.params.id !== this.props.match.params.id
		) {
			this.setState({ page: 'Loading', validEvents: [] });
			this.updateActiveEvent(null);
			this.updateUpcomingEvent(null);
			this.updateEventToAccept(null);
			let selectedRequest = await this.getRequest(this.props.match.params.id);

			// Event interval Initialization
			if (selectedRequest !== null) {
				let tempEventList = [];

				tempEventList = this.state.addValidEventsForActions(
					selectedRequest.workSchedule
				);
				this.eventInterval(tempEventList, true);
				eventCounter = setInterval(this.eventInterval, 2000);
			}
		} else if (prevProps.job_id !== this.props.job_id) {
			this.setState({ page: 'Loading', validEvents: [] });
			this.updateActiveEvent(null);
			this.updateUpcomingEvent(null);
			this.updateEventToAccept(null);
			let selectedRequest = await this.getRequest(this.props.job_id);

			// Event interval Initialization
			if (selectedRequest !== null) {
				let tempEventList = [];

				tempEventList = this.state.addValidEventsForActions(
					selectedRequest.workSchedule
				);
				this.eventInterval(tempEventList, true);
				eventCounter = setInterval(this.eventInterval, 2000);
			}
		}
	};

	componentWillUnmount() {
		if (eventCounter !== null) clearInterval(eventCounter);
	}

	getRequest = request_id => {
		return new Promise((resolve, reject) => {
			axios
				.get('jobRequest/get/maintenanceRequest/' + request_id)
				.then(response => {
					document.title = response.data.request.job_title;
					if (response.data.error) {
						this.setState({
							worder: null,
							page: 'Error',
							errorMessage: response.data.error
						});
					} else if (!response.data.access) {
						this.setState({
							worder: response.data.worder,
							access: response.data.access,
							page: 'NoAccess',
							action_permission: false,
							errorMessage: ''
						});
						resolve(null);
					} else {
						this.setState({
							worder: response.data.worder,
							request: response.data.request,
							page: 'Request',
							action_permission: true,
							workers: response.data.workers || [],
							errorMessage: ''
						});
						resolve(response.data.request);
					}
				})
				.catch(err => {
					if (err)
						this.setState({
							worder: null,
							errorMessage: 'Something Went Wrong.',
							page: 'Error'
						});
					resolve(null);
				});
		});
	};

	getAvailability = () => {
		axios
			.post('/post/loadjravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				)
			})
			.then(response => {
				this.setState({ calData: response.data.availability });
				return;
			});
	};
	createEvent(newEvent) {
		//let eventID= new Date().getTime()
		axios.post('/post/addjravailability', {
			jobuserID: Number(
				String(this.context.user.id) + String(this.props.job_id)
			),
			eventID: newEvent.event_id,
			// jobID:this.props.job_id,
			// userID:this.context.user_id,
			startTime: newEvent.startTime,
			endTime: newEvent.endTime,
			startDate: newEvent.startDate
		});
	}
	changeEvent = newData => {
		if (newData.operation == 'delete') {
			axios.post('/deletejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id
				// userID:this.context.user.id,
				// jobID:this.context.user_id,
			});
		}
		if (newData.operation == 'edit') {
			axios.post('/updatejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id,
				// userID:this.props.job_id,
				// jobID:this.context.user_id,

				NewEndTime: newData.data.endTime,
				NewStartTime: newData.data.startTime,
				NewStartDate: newData.data.startDate
			});
		}
		if (newData.operation == 'merge') {
			for (var i = 0; i < newData.delete_ids.length; i++) {
				axios.post('/deletejravailability', {
					jobuserID: Number(
						String(this.context.user.id) + String(this.props.job_id)
					),
					eventID: newData.id
				});
			}
			axios.post('/updatejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id,
				NewEndTime: newData.data.endTime,
				NewStartTime: newData.data.startTime,
				NewStartDate: newData.data.startDate
			});
		}
	};

	updateActiveEvent = jrEvent => {
		this.setState({ activeEvent: jrEvent });
	};

	updateUpcomingEvent = jrEvent => {
		this.setState({ upcomingEvent: jrEvent });
	};

	updateEventToAccept = jrEvent => {
		this.setState({ eventToAccept: jrEvent });
	};

	checkTenantConsented = (tenantList = []) => {
		if (tenantList.length > 0) {
			return tenantList
				.filter(tenant => tenant.user_type == 'Tenant')
				.some(tenant => tenant.has_accepted == 1);
		}
	};

	eventInterval = (validEventsList = [], overrideState = false) => {
		let intervalActiveEvent = overrideState ? null : this.state.activeEvent;
		let intervalUpcomingEvent = overrideState ? null : this.state.upcomingEvent;
		let currentDate = Moment().valueOf();
		if (validEventsList.length > 0) {
			validEventsList.some((validEvent, eventIndex) => {
				if (
					currentDate > Moment(validEvent.start) &&
					currentDate < Moment(validEvent.end) &&
					(!intervalActiveEvent ||
						intervalActiveEvent.idcalendar_events !==
							validEvent.idcalendar_events)
				) {
					this.updateActiveEvent(validEvent);
					this.updateEventToAccept(validEvent);
					if (validEventsList.length > 1) {
						this.updateUpcomingEvent(validEventsList[eventIndex - 1]);
					}
					this.state.updateStateCurrentWorkOrder(validEvent.workOrders);
					this.setState({
						eventCurrentUserAccepted: !this.state.checkUserNotAccepted(
							validEvent.users
						)
					});
					this.setState({
						eventAllWorkersAccepted: this.state.checkAllUsersAccepted(
							validEvent.users
						)
					});
					this.setState({
						eventTenantConsented: this.checkTenantConsented(validEvent.users)
					});
					return true;
				} else {
					this.updateUpcomingEvent(validEventsList[validEventsList.length - 1]);
					this.updateEventToAccept(validEventsList[validEventsList.length - 1]);
				}
			});
		} else if (!overrideState) {
			if (validEventsList.length > 0) {
				this.state.validEvents.some((stateValidEvent, eventIndex) => {
					if (
						currentDate > Moment(stateValidEvent.start) &&
						currentDate < Moment(stateValidEvent.end) &&
						(!intervalActiveEvent ||
							intervalActiveEvent.idcalendar_events !==
								stateValidEvent.idcalendar_events)
					) {
						this.updateActiveEvent(stateValidEvent);
						this.updateEventToAccept(stateValidEvent);
						if (validEventsList.length > 1) {
							this.updateUpcomingEvent(validEventsList[eventIndex - 1]);
						}
						this.state.updateStateCurrentWorkOrder(stateValidEvent.workOrders);
						this.setState({
							eventCurrentUserAccepted: !this.state.checkUserNotAccepted(
								stateValidEvent.users
							)
						});
						this.setState({
							eventAllWorkersAccepted: this.state.checkAllUsersAccepted(
								stateValidEvent.users
							)
						});
						this.setState({
							eventTenantConsented: this.checkTenantConsented(
								stateValidEvent.users
							)
						});
						return true;
					} else {
						this.updateUpcomingEvent(
							validEventsList[validEventsList.length - 1]
						);
						this.updateEventToAccept(
							validEventsList[validEventsList.length - 1]
						);
					}
				});
			}
		}
	};

	render() {
		//Still fetching the data
		if (this.state.page === 'Loading') {
			return <LoadScreen className='m-auto' />;
		}

		//Some error occurred
		else if (this.state.page === 'Error') {
			return <ErrorPage errorMessage={this.state.errorMessage} />;
		}
		//No access to this job request
		else if (this.state.page === 'NoAccess') {
			return <NoAccess />;
		} else if (this.state.page === 'Request') {
			//TODO: Add light color to the jr tiles
			return (
				<div
					id='jobRequestShell'
					className={`${this.props.invertedColors ? 'invertColors' : ''}`}
				>
					<RequestContext.Provider value={this.state}>
						<RequestDescription mobile={this.props.mobile} />

						{/*<Button
            onClick={this.openNewAvailability}
            
            >               
              Availability
            </Button>*/}
						<Modal
							visible={this.state.newAvailability}
							onCancel={this.openNewAvailability}
							footer={false}
						>
							<BarCalendar
								dataSource={[
									{
										bars: this.state.calData
									}
								]}
								eventStyles={{
									pink: {
										start: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									},
									blue: {
										start: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									},
									default: {
										start: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											backgroundColor: '#778ca2',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									}
								}}
								calendarView='month'
								showHeader={true}
								interactMode=''
								showTimeBar={false}
								orientation='vertical'
								newEvent={newEvent => {
									this.createEvent(newEvent);
									return newEvent;
								}}
								editEvent={newData => {
									this.changeEvent(newData);
									return;
								}}
								clickEvent={eventData => {}}
								blockWidth={35}
								blockHeight={20}
								updateLock={e => {
									this.setState({ lockOn: e });
								}}
								lockOn={this.state.lockOn}
							/>
						</Modal>

						{this.state.action_permission ? <RequestActions /> : ''}

						{!(
							this.state.request.job_status === 'Job Request' ||
							this.state.request.job_status === 'Complete' ||
							this.state.request.job_status === 'Rejected' ||
							this.state.request.job_status === 'Cancelled'
						) ? (
							<RequestVendor user={this.context.user} />
						) : (
							''
						)}
						<ActivityLog />
					</RequestContext.Provider>
				</div>
			);
		}
	}
}

function ErrorPage({ errorMessage }) {
	return (
		<Result
			status='500'
			title='500'
			subTitle={errorMessage}
			extra={
				<Link to='/unitMaintenance'>
					<Button type='primary'>Back to Maintenance Requests</Button>
				</Link>
			}
		/>
	);
}

export default UnitMaintenanceRequest;
