import React from 'react';
import BreadCrumb from './BreadCrumb'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'
import './header.css'

class HeaderLayout extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  
  render(){
      
      if(this.props.mobile) return( <MobileHeader openSidebar={this.props.openSidebar}  />)
      else return( <DesktopHeader collapseSideBar={this.props.collapseSideBar} openRequest={this.props.openRequest}/>)
    }
}

export default HeaderLayout;