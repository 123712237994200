import Modal from 'antd/lib/modal/Modal'
import axios from "axios";

import React from 'react'
import LoadScreen from '../../Components/LoadScreen'
import Task from '../../Components/Tasks/Task'
import RequestContext from '../MaintenanceRequest/RequestContext'
import PropertyAddressIntake from './actions/PropertyAddessIntake'
import AddUnits from './actions/AddUnits'
import AddOwner from './actions/AddOwner'
import AddTenant from './actions/AddTenant'
import AddLeases from './actions/AddLeases'
import {Card} from 'antd'
const cancelToken = axios.CancelToken.source();
/*
Displays the task list for intaking a property. The tasks are defined by an object from the propertyIntake table in dynamodb.
*/
class PropertyIntakeTasks extends React.Component{
    static contextType = RequestContext;
    constructor(props){
        super()
        this.state = {
            tasks:null,
            error:false,
            errorMessage:"",
            editAddress:false,
            editOwners:false,
            editLeases:false,
            editTenants:false,
        }
    }

    editAddressDetails = () => this.setState({editAddress:!this.state.editAddress});
    editUnitIntake = () => this.setState({editUnits:!this.state.editUnits});
    editOwnerDetails = () => this.setState({editOwners:!this.state.editOwners});
    editLeaseDetails = () => this.setState({editLeases:!this.state.editLeases});
    editTenantDetails = () => this.setState({editTenants:!this.state.editTenants});
    completeIntake = () => {
        axios.post("/intake/property/complete",{job_id:this.context.request_id}).then(() => {
            this.context.updateStatus("Complete")
        })
    }
    render(){
        const {tasks} = this.context;   
        const allComplete = !Object.values(tasks).includes(0);
        if(this.state.error) return <div className='g-text-sm'>{this.state.errorMessage}</div>
        else{ 
            return(
                <>
                <div className="JRSubtitle">Action Center</div>
                <Card
                bordered={false}
              >

                {tasks ?
                    allComplete ? 

                    <Task>
                        <Task.Title>
                            Complete Property Intake
                        </Task.Title>
                        <Task.Action primary onClick={this.completeIntake}>
                            Mark as Complete
                        </Task.Action>
                    </Task>

                    :
                (
                <>
                    <Task complete={tasks.addressIntake === 1}>
                        <Task.Title>
                            Enter the Property Details
                        </Task.Title>
                        <Task.Action primary onClick={this.editAddressDetails}>
                            Enter Details
                        </Task.Action>
                    </Task>
                    <Task complete={tasks.ownerIntake === 1}>
                        <Task.Title>
                            Connect Owners to this property
                        </Task.Title>
                        <Task.Action primary onClick={this.editOwnerDetails}>
                            Connect Owners
                        </Task.Action>
                    </Task>
                    {tasks.leaseIntake !== undefined ? 
                        <Task complete={tasks.leaseIntake === 1} unqualified={tasks.addressIntake === 0}>
                            <Task.Title>
                                Enter the Lease Details
                            </Task.Title>
                            <Task.Action primary onClick={this.editLeaseDetails}>
                                Enter Details
                            </Task.Action>
                        </Task>
                    : ""}
                        <Task complete={tasks.tenantIntake === 1} unqualified={tasks.leaseIntake === 0} >
                            <Task.Title>
                                Enter the Tenant Details
                            </Task.Title>
                            <Task.Action primary onClick={this.editTenantDetails}>
                                Connect Tenants
                            </Task.Action>
                        </Task>
                    {tasks.tenantIntake !== undefined ? <div></div>
                    : ""}



                <Modal
                    title='Register Address'
                    visible={this.state.editAddress}
                    onCancel={this.editAddressDetails}
                    footer={false}
                    >
                    {this.state.editAddress ? <PropertyAddressIntake onCancel={this.editAddressDetails} />  : <div></div>}
                </Modal>
                <Modal
                    title='Configure Units'
                    visible={this.state.editUnits}
                    onCancel={this.editAddressDetails}
                    footer={false}
                    >
                    {this.state.editUnits ? <AddUnits onCancel={this.editAddressDetails}/>  : <div></div>}
                </Modal>
                
                <Modal
                    title='Connect Owners'
                    visible={this.state.editOwners}
                    onCancel={this.editOwnerDetails}
                    footer={false}
                    >
                    {this.state.editOwners ? <AddOwner onCancel={this.editOwnerDetails}/>  : <div></div>}
                </Modal>

                <Modal
                    title='Connect Leases'
                    visible={this.state.editLeases}
                    onCancel={this.editLeaseDetails}
                    footer={false}
                    >
                    {this.state.editLeases ? <AddLeases onCancel={this.editLeaseDetails} />  : <div></div>}
                </Modal>

                <Modal
                    title='Connect Tenants'
                    visible={this.state.editTenants}
                    onCancel={this.editTenantDetails}
                    footer={false}
                    >
                    {this.state.editTenants ? <AddTenant onCancel={this.editTenantDetails}/>  : <div></div>}
                </Modal>
            </>
            )
            : <LoadScreen small className='m-auto' /> }
            </Card> 
                </>
            )
        }
    }
    
}




// { addressIntake:0,ownerIntake:0,leaseIntake:0,tenenatIntake:0}


export default PropertyIntakeTasks