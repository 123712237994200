import React from 'react';
import { Modal, Button, Popconfirm } from 'antd';
import BarCalendar from '../../../../Components/BarCalendar/BarCalendar';
import { UserStateContext } from '../../../../Context/UserContext'; //Get the current user's data
import axios from 'axios';
import { WindowsFilled } from '@ant-design/icons';
const source = axios.CancelToken.source();

class SetAvailability extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super();

		this.state = {
			scheduleModalOpen: false, //if the modal is open or not
			taskarray: null,
			deletearray: [],
			temp: [],
			lockOn: false
			//Enter state data for the scheduling here
		};
	}
	//Open or closes the modal
	openScheduleModal = () =>
		this.setState({ scheduleModalOpen: !this.state.scheduleModalOpen });
	componentDidMount() {
		document.title = 'Employee Availability';
		this.getAvailability();
	}

	getAvailability = () => {
		axios.get('/get/retrieveavailability').then(response => {
			this.setState({
				taskarray: response.data.Item ? response.data.Item.availability : null,
				showCalendar: true
			});
			return;
		});
	};

	//Submit schedule and send request to backend
	SubmitSchedule = () => {
		return;
	};

	createEvent = event => {
		if (source) source.cancel('Updated before finished');
		let newTaskArray;
		let recordExists = this.state.taskarray !== null;
		if (recordExists) {
			newTaskArray = [...this.state.taskarray];
			newTaskArray.push({
				event_id: event.event_id,
				startTime: event.startTime,
				endTime: event.endTime,
				startDate: event.startDate.valueOf(),
				repeatAvailability: false,
				repeatDates: []
			});
		} else
			newTaskArray = [
				{
					event_id: event.event_id,
					startTime: event.startTime,
					endTime: event.endTime,
					startDate: event.startDate.valueOf(),
					repeatAvailability: false,
					repeatDates: []
				}
			];
		this.setState({ taskarray: newTaskArray });
		// axios
		// 	.post('/post/updateAvailability', {
		// 		cancelToken: source.token,
		// 		newAvailability: newTaskArray,
		// 		recordExists: recordExists
		// 	})
		// 	.then(response => {});
	};

	changeEvent = newData => {
		if (newData.operation !== 'new') {
			if (source) source.cancel('Updated before finished');
			let newTaskArray = [...this.state.taskarray];
			if (newData.operation == 'delete') {
				let deleteIndex = newTaskArray.findIndex(task => {
					return newData.id === task.event_id;
				});
				newTaskArray.splice(deleteIndex, 1);
			}
			if (newData.operation == 'edit') {
				let editIndex = newTaskArray.findIndex(task => {
					return newData.id === task.event_id;
				});
				newTaskArray[editIndex].startTime = newData.data.startTime;
				newTaskArray[editIndex].endTime = newData.data.endTime;
				newTaskArray[editIndex].startDate = newData.data.startDate.valueOf();
			}
			if (newData.operation == 'merge') {
				let deleteIndex;
				for (var i = 0; i < newData.delete_ids.length; i++) {
					deleteIndex = newTaskArray.findIndex(task => {
						return newData.delete_ids[i] === task.event_id;
					});
					newTaskArray.splice(deleteIndex, 1);
				}
				let editIndex = newTaskArray.findIndex(task => {
					return newData.id === task.event_id;
				});
				newTaskArray[editIndex].startTime = newData.data.startTime;
				newTaskArray[editIndex].endTime = newData.data.endTime;
				newTaskArray[editIndex].startDate = newData.data.startDate.valueOf();
			}

			this.setState({ taskarray: newTaskArray });

			// axios
			// 	.post('/post/updateAvailability', {
			// 		cancelToken: source.token,
			// 		newAvailability: newTaskArray,
			// 		recordExists: true
			// 	})
			// .then(response => {});
		}
	};
	//Submit schedule and send request to backend
	submitSchedule = () => {
		let newTaskArray = [...this.state.taskarray];
		axios
			.post('/post/updateAvailability', {
				newAvailability: newTaskArray
			})
			.then(response => {
				window.location.reload();
			});
	};

	render() {
		return (
			<>
				<Button type='primary' onClick={this.openScheduleModal}>
					Set Availability
				</Button>

				<Modal
					title='Schedule Availability'
					visible={this.state.scheduleModalOpen}
					onCancel={this.openScheduleModal}
					destroyOnClose={true}
					width={'800px'}
					footer={[
						<Button onClick={this.openScheduleModal}>Cancel</Button>,
						<Button type='primary' onClick={this.submitSchedule}>
							Submit
						</Button>
					]}
				>
					<BarCalendar
						dataSource={[
							{
								bars: this.state.taskarray || []
							}
						]}
						eventStyles={{
							pink: {
								start: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								}
							},
							blue: {
								start: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								}
							},
							default: {
								start: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								}
							}
						}}
						calendarView='month'
						showHeader={true}
						allowNewEvents
						interactMode='editable'
						orientation='vertical'
						newEvent={newEvent => {
							this.createEvent(newEvent);
							return newEvent;
						}}
						editEvent={newData => {
							this.changeEvent(newData);
							return;
						}}
						clickEvent={eventData => {}}
						blockWidth={35}
						blockHeight={20}
						loading={!this.state.showCalendar}
						updateLock={e => {
							this.setState({ lockOn: e });
						}}
						lockOn={this.state.lockOn}
					/>
				</Modal>
			</>
		);
	}
}

export default SetAvailability;
