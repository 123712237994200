import React, {
	Component,
	useState,
	useContext,
	useEffect,
	useRef
} from 'react';
import axios from 'axios';
import {
	DatePicker,
	TimePicker,
	Button,
	InputNumber,
	Table,
	Modal,
	Checkbox,
	Input,
	Popconfirm,
	Form,
	Select,
	Menu,
	Tooltip,
	Dropdown
} from 'antd';
//import { Form } from "react-bootstrap";
import WorkOrderContext from '../WorkOrderContext';
import RequestContext from '../../../Pages/MaintenanceRequest/RequestContext';
//import Form from "antd/lib/form/Form";
import '../WorkOrder.css';
import { PlusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
let currentDate = new Date();
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

class ExactExpenses extends Component {
	static contextType = WorkOrderContext;

	constructor(props) {
		super(props);
		this.state = {
			materialRows: [],

			invoiceDate: '',
			payees: [],
			payers: [],
			workId: '',
			ownerId: ''
		};
		this.handleChange = this.handleChange.bind(this);
		this.materialCols = [
			{
				title: 'Invoice Date',
				dataIndex: 'invoice_date',
				width: 80,
				render: (value, record) => {
					return (
						<DatePicker
							style={{ width: 100 }}
							onChange={(date, dateString) => {
								this.setState({ invoiceDate: dateString });
							}}
						/>
					);
				}
			},
			{
				title: 'Invoice Number',
				dataIndex: 'invoice_number',
				editable: true,
				width: 50,
				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e.target.value, rowIndex, 'invoice_number');
					};
					return (
						<Input
							value={inputValue}
							onChange={changeRecord}
							style={{ width: 50 }}
						/>
					);
				}
			},
			{
				title: 'Description',
				dataIndex: 'charge_description',
				width: 120,

				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e.target.value, rowIndex, 'charge_description');
					};
					return (
						<Input
							value={inputValue}
							onChange={changeRecord}
							style={{ width: 150 }}
						/>
					);
				}
			},

			{
				title: 'Charge From',
				dataIndex: 'payee',
				width: 80,
				render: (inputValue, dataRecord, rowIndex) => {
					console.log(dataRecord);
					let changeRecord = e => {
						if (e.length >= 1 || (inputValue.length <= 1 && e.length === 0))
							this.handleChange(e, rowIndex, 'payee');
					};

					return (
						<Select
							showSearch
							onSearch={changeRecord}
							// value={inputValue}
							style={{ width: 100 }}
							onChange={changeRecord}
						>
							{this.state.payees.map(item =>
								item.id == null ? (
									<Option key={item}>{item}</Option>
								) : (
									<Option key={item.fname}>
										{item.fname + ' ' + item.lname}
									</Option>
								)
							)}
						</Select>
					);
				}
			},
			{
				title: 'Charge To',
				dataIndex: 'payer',
				width: 80,
				render: (inputValue, dataRecord, rowIndex) => {
					console.log(dataRecord);
					let changeRecord = e => {
						if (e.length >= 1 || (inputValue.length <= 1 && e.length === 0))
							this.handleChange(e, rowIndex, 'payer');
					};

					return (
						<Select
							showSearch
							onSearch={changeRecord}
							// value={inputValue}
							style={{ width: 100 }}
							onChange={changeRecord}
						>
							{this.state.payers.map(item =>
								item.unit_id == null ? (
									<Option key={item}>{item}</Option>
								) : (
									<>
										{item.property_owner_id ? (
											<Option key={item.property_owner_id}>
												{'O-' + item.property_owner_name}
											</Option>
										) : (
											''
										)}
										{item.lease_id != null ? (
											<Option key={item.tenant_id}>
												{'T-' + item.tenant_name}
											</Option>
										) : (
											''
										)}
									</>
								)
							)}
						</Select>
					);
				}
			},
			// {
			// 	title: 'Payment Method',
			// 	dataIndex: 'payment_method',
			// 	width: '20%',
			// 	editable: true,
			// 	render: (inputValue, record, rowIndex) => {
			// 		let changeRecord = e => {
			// 			if (e.length >= 1 || (inputValue.length <= 1 && e.length === 0))
			// 				this.handleChange(e, rowIndex, 'payment_method');
			// 		};
			// 		return (
			// 			<Select
			// 				showSearch
			// 				onSearch={changeRecord}
			// 				// value={inputValue}
			// 				style={{ width: 80 }}
			// 				onChange={changeRecord}
			// 			>
			// 				<Option key='Email'>Email</Option>
			// 				<Option key='Card'>Card</Option>
			// 				<Option key='Cash'>Cash</Option>
			// 			</Select>
			// 		);
			// 	}
			// },
			{
				title: 'Charge Amount',
				dataIndex: 'gross_amount',
				editable: true,
				width: 50,

				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e, rowIndex, 'gross_amount');
					};
					return (
						<InputNumber
							formatter={value => `$ ${value}`}
							precision={2}
							min={0}
							controls={false}
							value={inputValue}
							onChange={changeRecord}
							style={{ width: 70 }}
						/>
					);
				}
			},
			{
				title: ' HST Amount',
				dataIndex: 'gst_amount',
				editable: true,
				width: 50,
				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e, rowIndex, 'gst_amount');
					};
					return (
						<InputNumber
							formatter={value => `$ ${value}`}
							precision={2}
							min={0}
							controls={false}
							value={inputValue}
							onChange={changeRecord}
							style={{ width: 70 }}
						/>
					);
				}
			},
			{
				title: 'Total Amount',
				dataIndex: 'total_amount',
				width: 50,
				render: (_, record) => {
					let total = record.gross_amount + record.gst_amount;
					return '$' + total;
				}
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 80,
				render: (inputValue, record, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e.target.value, rowIndex, 'notes');
					};
					return (
						<Input
							value={inputValue}
							onChange={changeRecord}
							style={{ width: 120 }}
						/>
					);
				}
			}
		];
	}
	componentDidMount = () => {
		console.log(this.context.getEventAction());
		axios.get('/getVendors').then(response => {
			// console.log(response.data.vendors);
			this.setState({ payees: response.data.vendors });
		});
		axios
			.post('/getOwnerTenant', { UnitId: this.context.getJrData().unit_id })
			.then(response => {
				// console.log(response.data.OwnerTenant);
				this.setState({ payers: response.data.OwnerTenant });
			});
		axios
			.post('workOrder/get/workOrderDetails', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				console.log(res.data.details);
				this.setState({ workId: res.data.details[0].wo_id });
			});

		axios
			.post('workOrder/get/chargeTo', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				// console.log(res.data.ownerId[0].property_owner_id);
				this.setState({ ownerId: res.data.ownerId[0].property_owner_id });
			});
	};

	EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);
		useEffect(() => {
			if (editing) {
				inputRef.current.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex]
			});
		};

		const save = async () => {
			record.period = this.state.period;
			record.invDate = this.state.invoice;
			record.dueDate = this.state.dueDate;
			record.payee = this.state.payeeId;
			record.payeeCode = record.payee;
			record.property = this.state.propertyId;
			record.legalEntity = this.state.propertyId;

			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({ ...record, ...values });
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}
				>
					<Input ref={inputRef} onPressEnter={save} onBlur={save} />
				</Form.Item>
			) : (
				<div
					className='editable-cell-value-wrap'
					style={{
						paddingRight: 24
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
			);
		}

		return <td {...restProps}>{childNode}</td>;
	};

	handleAdd = type => {
		const dataSource = this.state.materialRows;
		const newData = {
			payee: '',
			invoice_Date: this.state.invoiceDate,
			invoice_number: '',
			charge_description: '',
			gross_amount: 0,
			payment_method: '',
			gst_amount: 0,
			notes: ''
		};

		this.setState({
			materialRows: [...dataSource, newData]
		});
	};
	handleChange = (e, rowIndex, column) => {
		let updatedRow = {
			...this.state.materialRows[rowIndex]
		};
		updatedRow[column] = e;
		this.setState({
			materialRows: [
				...(rowIndex !== 0 ? this.state.materialRows.slice(0, rowIndex) : []),
				updatedRow,
				...(rowIndex < this.state.materialRows.length - 1
					? this.state.materialRows.slice(rowIndex + 1)
					: [])
			]
		});
	};

	// handleChange = e => {
	// 	console.log(e.target.value);
	// 	this.setState({
	// 		[e.target.name]: e.target.value
	// 	});
	// };
	handleSave = row => {
		const newData = [...this.state.materialRows];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
			materialRows: newData
		});
	};

	saveExpenses = () => {
		this.state.materialRows.forEach(expense => {
			axios
				.post('workOrder/add/expenses', {
					workId: this.state.workId,
					Create_Entry_Time: currentDate,

					Invoice_Date: this.state.invoiceDate,
					Charge_From: expense.payee,
					Charge_To_ID: expense.payer,
					Invoice_Number: expense.invoice_number,
					Property_ID: this.context.getJrData().propertyid,

					Charge_Description: expense.charge_description,
					Gross_Amount: expense.gross_amount,
					GST_Amount: expense.gst_amount,
					Payment_Method: expense.payment_method,
					Notes: expense.notes
				})

				.then(res => {
					this.context.changePage('Work Done Menu');
				});
		});
	};
	menu = (
		<Menu>
			<Menu.Item onClick={() => this.handleAdd('Material')}>Material</Menu.Item>
			<Menu.Item onClick={() => this.handleAdd('Labour')}>Labour</Menu.Item>
		</Menu>
	);

	render() {
		console.log(this.state.materialRows);
		// console.log(this.context.jobData);
		// console.log(this.context.getJrData().unit_id);

		const columns = this.materialCols.map(col => {
			if (!col.editable) {
				return col;
			}

			return {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title
					// handleSave: this.handleSave
				})
			};
		});
		return (
			<div style={{ width: '950px' }} className=''>
				Expenses
				{
					<Dropdown overlay={this.menu} trigger={['click']}>
						<a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
							<PlusCircleOutlined style={{ marginLeft: '5px' }} />
						</a>
					</Dropdown>
				}
				<Table
					columns={columns}
					dataSource={this.state.materialRows}
					// rowKey={'row_id'}
					size='small'
					pagination={false}
					// rowClassName={() => 'editable-row'}
					// scroll={{ x: 500 }}
				/>
				<div className='borderLine'></div>
				<div>
					<Button
						className='mr-0'
						type='primary'
						style={{
							whiteSpace: 'normal',
							height: 'auto',
							marginTop: '10px',
							float: 'right'
						}}
						onClick={this.saveExpenses}
					>
						Submit
					</Button>
				</div>
			</div>
		);
	}
}

export default ExactExpenses;
