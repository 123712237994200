import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import { Button, Input } from 'antd';
import './login.css';
import axios from 'axios';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import LoadScreen from '../../Components/LoadScreen';
import { useUserDispatch } from '../../Context/UserContext';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import LoginLayout from './LoginLayout';
import Cookies from 'js-cookie';
const cancelToken = axios.CancelToken.source();

/* 
-Write backend login route
-Connect login to user context
-change header profile to use user context
*/

function LoginPage() {
	document.title = 'Login';
	let userDispatch = useUserDispatch();
	const [screen, setScreen] = useState('Form');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setError] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const sendLogin = () => {
		setScreen('Loading');
		axios
			.post('/auth/login', {
				crossdomain: true,
				email: email,
				password: password
			})
			.then(function (response) {
				if (response.data.ok && !response.data.accountTerminated) {
					//email & password correct
					userDispatch({
						state: { user: response.data.user },
						type: 'initalize'
					});

					Cookies.set('session', {
						session_id: response.data.session_id,
						type: response.data.user.type
					});
					if (response.data.setPassword)
						//Account not activated
						setScreen('setPassword');
					else if (response.data.verifyAccount) {
						//Account not activated
						setError('Please verify email to login.');
						setScreen('Form');
					} else setScreen('overview');
				} else if (!response.data.accountTerminated) {
					setError(
						'The email or password entered is incorrect. Please try again.'
					);
					setScreen('Form');
				} else {
					setError(
						'Sorry, but this account has been terminated. If you think this is a mistake, please email us at info@steadypropertymanagement.com, or call (709) 639-6431.'
					);
					setScreen('Form');
				}
			})
			.catch(error => {
				setError(
					'Error connecting to the server. Please refresh or try again later.'
				);
				setScreen('Form');
			});
	};
	const togglePassword = () => {
		setShowPassword(!showPassword);
	};
	const handleKeyDown = e => {
		if (e.key === 'Enter') {
			sendLogin();
		}
	};
	const getSignupPage = () => {
		setScreen('loadSignup');
	};

	if (screen === 'Form') {
		return (
			<LoginLayout header='Sign In' className='text-center w-100'>
				<Form className='flex-60'>
					<Form.Group>
						<Form.Label>Your Email</Form.Label>
						<Form.Control
							size='lg'
							type='text'
							value={email}
							placeholder='Enter your email'
							onChange={e => setEmail(e.target.value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Password</Form.Label>
						<Form.Control
							size='lg'
							type={showPassword ? 'text' : 'password'}
							value={password}
							placeholder='Enter your password'
							onChange={e => setPassword(e.target.value)}
							onKeyDown={e => handleKeyDown(e)}
						/>
					</Form.Group>
					<Form.Group className='text-left'>
						<Form.Check
							label='Show Password'
							onChange={togglePassword}
						></Form.Check>
					</Form.Group>

					<Form.Group className='text-right'>
						<Link id='recoverpassword' to='/recoverPassword' className='link'>
							Forgot your password?
						</Link>
					</Form.Group>
					<Button type='primary' className={' signInBtn'} onClick={sendLogin}>
						Sign In
					</Button>
					<ErrorMessage otherClass='mt-2'>{errorMessage}</ErrorMessage>
				</Form>
				{/*<div className="flex-30 caption mt-4 signuphere">
          Don't have an account?{" "}
          <div className="blueLink" onClick={getSignupPage}>
            Sign up here
          </div>
          </div>*/}
			</LoginLayout>
		);
	}
	switch (screen) {
		case 'Loading': {
			return (
				<LoginLayout header='Sign In'>
					<div className='d-flex flex-column h100'>
						<LoadScreen />
					</div>
				</LoginLayout>
			);
		}
		case 'selectaccount': {
			return <Redirect to='/selectaccount' />;
		}
		case 'overview': {
			return <Redirect to='/communication' />;
		}
		case 'setPassword': {
			return <Redirect to='/setpassword' />;
		}
		case 'q': {
			return <div>verify account</div>;
		}
		case 'loadSignup': {
			return <Redirect to='/signup' />;
		}
		default:
			return <LoadScreen />;
	}
}

export default LoginPage;
