import React from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import { Alert, Button } from 'antd';
import './taskStyle.css';

import LoadScreen from '../../../Components/LoadScreen';
const cancelToken = axios.CancelToken.source();

class CompanyTask extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyName: '',
			companyAddress: '',
			phoneNumber: '',
			positionOfUser: '',
			submitted: false,
			loaded: false
		};
	}

	verify = () => {
		if (this.state.companyName === '') {
			this.setState({ errorMessage: 'Please enter a company Name.' });
			return false;
		} else if (this.state.companyAddress === '') {
			this.setState({ errorMessage: 'Please enter a company address.' });
			return false;
		} else if (
			this.state.phoneNumber === '' ||
			this.state.phoneNumber.length < 9
		) {
			this.setState({ errorMessage: 'Please enter a phone number.' });
			return false;
		} else if (this.state.positionOfUser === '') {
			this.setState({
				errorMessage: 'Please enter your position in the company.'
			});
			return false;
		}
		return true;
	};

	submitForm = () => {
		if (!this.verify()) return;
		this.setState({ submitted: true });
		axios
			.post('/companyComplete', {
				companyName: this.state.companyName,
				companyAddress: this.state.companyAddress,
				phoneNumber: this.state.phoneNumber,
				positionOfUser: this.state.positionOfUser,
				errorMessage: null
			})
			.then(response => {
				//next page
			})
			.catch(err => console.log(err));
	};

	closeError = () => this.setState({ errorMessage: null });

	componentDidMount() {
		axios.post('/getCompany', { data: this.props.taskData }).then(response => {
			let companyName = response.data.companyName || '';
			let address = response.data.companyAddress || '';
			let phone = response.data.phoneNumber || '';
			let position = response.data.positionOfUser || '';

			this.setState({
				companyName: companyName,
				companyAddress: address,
				phoneNumber: phone,
				positionOfUser: position,
				loaded: true
			});
		});
	}
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const isEnabled = () => this.verify();

		if (!this.state.loaded) return <LoadScreen className='m-auto' />;

		return (
			<div className='taskContainer'>
				<div>
					<h3 className='title'>Company Info</h3>
					<Form action='companyComplete' method='post'>
						<Form.Group>
							<p>
								Description: Please enter the following information to update
								your account.
							</p>
							<Form.Label className='form-label'>Company Name:</Form.Label>
							<Form.Control
								className='form-text'
								name='companyName'
								type='text'
								placeholder='Company Name'
								defaultValue={this.state.companyName}
								onChange={this.handleChange}
							/>
							<br />
							<Form.Label className='form-label'>Company Address:</Form.Label>
							<Form.Control
								className='form-text'
								name='companyAddress'
								placeholder='Company Address'
								type='text'
								defaultValue={this.state.companyAddress}
								onChange={this.handleChange}
							/>
							<br />
							<Form.Label className='form-label'>Phone Number:</Form.Label>
							<Form.Control
								className='form-text'
								name='phoneNumber'
								type='text'
								placeholder='Phone Number'
								defaultValue={this.state.phoneNumber}
								onChange={this.handleChange}
							/>

							<br />
							<Form.Label className='form-label'>
								Position in company:
							</Form.Label>
							<Form.Control
								as='select'
								name='role'
								onChange={this.handleChange}
								value={this.state.role}
								placeholder='Position'
								noValidate
								className='form-text'
							>
								<option hidden>Company Role</option>
								<option>Property Manager</option>
								<option>Maintenance</option>
								<option>Accounting</option>
							</Form.Control>
						</Form.Group>
						{this.state.errorMessage ? (
							<Alert
								message={this.state.errorMessage}
								type='error'
								className='pointer'
								showIcon
								onClick={this.closeError}
								closable
							/>
						) : (
							''
						)}
						<br />

						<Button
							type='primary'
							disabled={this.state.submitted}
							onClick={this.submitForm}
						>
							Submit
						</Button>
					</Form>
				</div>
			</div>
		);
	}
}

export default CompanyTask;
