import React, { Component } from "react";
import { Button } from "antd";
import WorkOrderContext from "../WorkOrderContext";

class Labour extends Component {
  static contextType = WorkOrderContext;
  constructor(props) {
    super(props);
    this.state = {
      costList: [],
      totalCost: 0,
    };

    this.goNext = this.goNext.bind(this);
    this.goEnd = this.goEnd.bind(this);
    this.addCost = this.addCost.bind(this);
    this.labelChange = this.labelChange.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }
  componentDidMount = () => {
    let arr = (this.context.labourData && this.context.labourData.costList) || [];
    this.setState({ costList: arr, totalCost: (this.context.labourData && this.context.labourData.totalCost) || 0 });
  };
  componentWillUnmount = () => {
    this.context.updateLabourData(
      this.state.costList,
      this.state.totalCost,
      false
    );
  };

  updateTotalCost() {
    let total = this.state.costList[0].price * this.state.costList[1].price;
    for (let i = 2; i < this.state.costList.length; i++) {
      if (!isNaN(this.state.costList[i].price)) {
        if (this.state.costList[i].price !== "")
          total += parseFloat(this.state.costList[i].price);
      } else if (isNaN(this.state.costList[i].price)) total += 0;
    }
    this.setState({ totalCost: total });
  }
  goNext() {
    this.context.updateLabourData(
      this.state.costList,
      this.state.totalCost,
      true
    );
  }
  goEnd() {
    this.context.saveAndContinue();
  }

  addCost() {
    let rowid,
      row_ids = [];
    for (let i of this.state.costList) {
      row_ids.push(i.id);
    }
    rowid = Math.max.apply(Math, row_ids) + 1;

    this.state.costList.push({
      id: rowid,
      label: "",
      startUnit: "$",
      price: 0,
      endUnit: "",
      custom: true,
    });
    this.setState({ costList: this.state.costList });
  }
  labelChange = (label, id) => {
    let curr = this.state.costList.findIndex((x) => x.id === id);
    let arr = this.state.costList;
    arr[curr] = {
      id: arr[curr].id,
      label: label,
      startUnit: arr[curr].startUnit,
      price: arr[curr].price,
      endUnit: arr[curr].endUnit,
      custom: arr[curr].custom,
    };
    this.setState({ costList: arr });
  };
  valueChange = (value, id) => {
    let curr = this.state.costList.findIndex((x) => x.id === id);
    let arr = this.state.costList;
    arr[curr] = {
      id: arr[curr].id,
      label: arr[curr].label,
      startUnit: arr[curr].startUnit,
      price: value,
      endUnit: arr[curr].endUnit,
      custom: arr[curr].custom,
    };
    this.setState({ costList: arr });
    this.updateTotalCost();
  };
  deleteCost = (id) => {
    let curr = this.state.costList.findIndex((x) => x.id === id);
    let arr = this.state.costList;
    for (var i = 0; i < arr.length; i++) {
      if (i === curr) {
        arr.splice(i, 1);
      }
    }
    this.setState({ costList: arr });
    this.updateTotalCost();
  };

  render() {
    let costList = [];

    for (let i of this.state.costList) {
      if (!i.custom)
        costList.push(
          <InitalCostForm
            valueChange={this.valueChange}
            id={i.id}
            label={i.label}
            startUnit={i.startUnit}
            price={i.price}
            endUnit={i.endUnit}
          />
        );
      else
        costList.push(
          <OtherCost
            deleteCost={this.deleteCost}
            labelChange={this.labelChange}
            valueChange={this.valueChange}
            id={i.id}
            label={i.label}
            startUnit={i.startUnit}
            price={i.price}
            endUnit={i.endUnit}
          />
        );
    }
    return (
      <div className='aligncenter'>
        <h4>Cost Estimation</h4>
        <br />
        {costList}
        <br></br>
        <div className='text-right'>
          <h7 id='addMaterial' onClick={this.addCost}>
            Add Other Cost
          </h7>{" "}
        </div>
        <div className='borderLine'></div>
        <br />
        <div className='fixedLine'>
          <label className='txtLabel'>Estimated Cost: </label>
          <label className='d-inline startUnits'>$ </label>
          <input
            type='number'
            id='materialCost'
            value={this.state.totalCost}
            className='form-control mr-2 d-inline txtInput'
            disabled></input>
          <label className='d-inline endUnits'></label>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className='buttonGroup'>
          <Button type='primary' className=' mt-4 mr-0' onClick={this.goNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

class InitalCostForm extends Component {
  constructor(props) {
    super(props);
    this.valueChange = this.valueChange.bind(this);
  }

  valueChange(e) {
    this.props.valueChange(e.target.value, this.props.id);
  }

  goNext() {
    this.props.goNext();
  }

  render() {
    let inp;
    if (this.props.label === "Material Cost")
      inp = (
        <input
          type='number'
          min='0'
          value={this.props.price}
          onChange={this.valueChange}
          className='txtInput mr-2 form-control d-inline '
          disabled></input>
      );
    else
      inp = (
        <input
          type='number'
          min='0'
          value={this.props.price}
          onChange={this.valueChange}
          className='txtInput mr-2 form-control d-inline '></input>
      );
    return (
      <div>
        <div className='fixedLine '>
          <label className='txtLabel'>{this.props.label}</label>
          <label className='d-inline startUnits'>{this.props.startUnit}</label>
          {inp}
          <label className='d-inline endUnits'>{this.props.endUnit}</label>
        </div>
        <br />
      </div>
    );
  }
}
class OtherCost extends Component {
  constructor(props) {
    super(props);
    this.valueChange = this.valueChange.bind(this);
    this.labelChange = this.labelChange.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }

  labelChange(e) {
    this.props.labelChange(e.target.value, this.props.id);
  }

  valueChange(e) {
    this.props.valueChange(e.target.value, this.props.id);
  }
  deleteCost() {
    this.props.deleteCost(this.props.id);
  }
  goNext() {
    this.props.goNext();
  }

  addOther = () => {
    let t = { price: 0, name: "" };
    this.state.otherCosts.push(t);
    this.setState({ otherCosts: this.state.otherCosts });
  };

  render() {
    return (
      <div>
        <div className='fixedLine '>
          <input
            className='txtLabel form-control width80'
            placeholder='Cost Name'
            onChange={this.labelChange}
            value={this.props.label}></input>
          <label className='d-inline startUnits'>{this.props.startUnit}</label>
          <input
            type='number'
            min='0'
            value={this.props.price}
            onChange={this.valueChange}
            className='txtInput mr-2 form-control d-inline '></input>
          <div className='endUnits text-right'>
            <img
              className='d-inline icon'
              onClick={this.deleteCost}
              alt='Delete'
              value='delete'
              //src={require("../../images/delete.png")}
              >
              </img>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default Labour;
