import React, { useState } from "react";
import { Modal, ModalTitle, Form } from "react-bootstrap";
import Task from "../../../Components/Tasks/Task";
import axios from "axios";

import { Button,message } from "antd";
import RequestContext from '../RequestContext'
const cancelToken = axios.CancelToken.source();
class JobRequestAction extends React.Component {
    static contextType = RequestContext;

    constructor(props) {
      super(props);
      this.state = {
        rejectModal: false,
      };
    }
    openRejectModal = () =>
      this.setState({ rejectModal: !this.state.rejectModal });
  
     // Job Request Status
    acceptRequest = () => {
      axios
        .post("/jobRequest/update/status/scheduling", {
          job_id: this.context.request.job_id,
        })

        .then((response) => {
            this.context.updateStatus(response.data.newStatus);
            this.context.addActivity(response.data.note, null, true);
            message.success("Job Request has been accepted", 2.5);
            return;
        })
        .catch((error) => {
          console.log(error);
          this.context.setError(error.name, error.description);
          return;
        });
    };
    
    rejectRequest = (reason) => {
      axios
        .post("/jobRequest/update/status/reject", {
          job_id: this.context.request.job_id,
          reason: reason,
        })
        .then((response) => {
          this.openRejectModal();
          this.context.updateStatus(response.data.newStatus);
          this.context.addActivity(response.data.note, null, true);
          message.success("Job Request has been rejected", 2.5);
          return;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    render() {

      return (
        <div>
          <Task>
            <Task.Title>Accept or Reject This Request</Task.Title>
            <Task.Action danger onClick={this.openRejectModal}>
              Reject Request
            </Task.Action>
            <Task.Action primary onClick={this.acceptRequest}>
              Accept Request
            </Task.Action>
          </Task>
  
          <Modal
            show={this.state.rejectModal}
            onHide={this.openRejectModal}
            size="lg"
            aria-labelledby="assign-vendor-modal"
            centered
          >
            {!this.state.rejectModal ? (
              ""
            ) : (
              <RejectRequestModal
                onHide={this.openRejectModal}
                rejectRequest={this.rejectRequest}
              />
            )}
          </Modal>
        </div>
      );
    }
  }


/* job request task --- approve or reject job request */

const RejectRequestModal = ({ onHide, rejectRequest }) => {
  const [reason, updateReason] = useState("");
  return (
    <>
      <Modal.Header closeButton>
        <ModalTitle>Reject Request</ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reason for Rejecting this request</Form.Label>
          <Form.Control
            type="textarea"
            defaultValue={reason}
            onChange={(e) => updateReason(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button type="primary" danger onClick={() => rejectRequest(reason)}>
          Confirm Rejection
        </Button>
      </Modal.Footer>
    </>
  );
};


export default JobRequestAction;