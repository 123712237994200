import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalTitle, Form } from 'react-bootstrap';
import useWindowDimensions from '../../../../Context/WindowsDimensions';
import BarCalendar from '../../../../Components/BarCalendar/BarCalendar';
import LoadScreen from '../../../../Components/LoadScreen';
import axios from 'axios';
import Moment from 'react-moment';
// import { Grid, Stack, Typography } from '@mui/material';
// import { Box } from '@mui/material';
import {
	Button,
	message,
	Avatar,
	DatePicker,
	Table,
	Checkbox,
	Card,
	Switch
} from 'antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { PDFDocument, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';

import './vendorListing.css';

// You can add colors here!
const availableUserColors = {
	color1: {
		text: {
			color: '#4C6593'
		},
		start: {
			border: '2px solid #4C6593',
			borderBottom: '0px none',
			opacity: 0.6,
			backgroundColor: '#778ca2'
		},
		middle: {
			border: '2px solid #4C6593',
			borderBottom: '0px none',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#778ca2'
		},
		end: {
			border: '2px solid #4C6593',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#778ca2'
		},
		single: {
			border: '2px solid #4C6593',
			opacity: 0.6,
			backgroundColor: '#778ca2'
		}
	},
	color2: {
		text: {
			color: '#D66F0E'
		},
		start: {
			border: '2px solid #D66F0E',
			borderBottom: '0px none',
			opacity: 0.6,
			backgroundColor: '#ffab2b'
		},
		middle: {
			border: '2px solid #D66F0E',
			borderBottom: '0px none',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#ffab2b'
		},
		end: {
			border: '2px solid #D66F0E',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#ffab2b'
		},
		single: {
			border: '2px solid #D66F0E',
			opacity: 0.6,
			backgroundColor: '#ffab2b'
		}
	},
	color3: {
		text: {
			color: '#D66F0E'
		},
		start: {
			border: '2px solid #18BA43',
			borderBottom: '0px none',
			opacity: 0.6,
			backgroundColor: '#6dd230'
		},
		middle: {
			border: '2px solid #18BA43',
			borderBottom: '0px none',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#6dd230'
		},
		end: {
			border: '2px solid #18BA43',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#6dd230'
		},
		single: {
			border: '2px solid #18BA43',
			opacity: 0.6,
			backgroundColor: '#6dd230'
		}
	},
	color4: {
		text: {
			color: '#EA2556'
		},
		start: {
			border: '2px solid #EA2556',
			borderBottom: '0px none',
			opacity: 0.6,
			backgroundColor: '#fe4d97'
		},
		middle: {
			border: '2px solid #EA2556',
			borderBottom: '0px none',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#fe4d97'
		},
		end: {
			border: '2px solid #EA2556',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#fe4d97'
		},
		single: {
			border: '2px solid #EA2556',
			opacity: 0.6,
			backgroundColor: '#fe4d97'
		}
	},
	color5: {
		text: {
			color: '#1A7FCC'
		},
		start: {
			border: '2px solid #1A7FCC',
			borderBottom: '0px none',
			opacity: 0.6,
			backgroundColor: '#2ce5f6'
		},
		middle: {
			border: '2px solid #1A7FCC',
			borderBottom: '0px none',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#2ce5f6'
		},
		end: {
			border: '2px solid #1A7FCC',
			borderTop: '0px none',
			opacity: 0.6,
			backgroundColor: '#2ce5f6'
		},
		single: {
			border: '2px solid #1A7FCC',
			opacity: 0.6,
			backgroundColor: '#2ce5f6'
		}
	}
};

export function CreateEventModal(props) {
	const {
		updateStatus,
		updateImages,
		afterEvent,
		vendors,
		employees,
		tenants,
		owners,
		user,
		doPrevent
	} = props;
	const [modalPage, setModalPage] = useState(0);
	const [noticeData, setNoticeData] = useState(null);
	const [title, setTitle] = useState('');
	// const [entryRequired, setEntryRequired] = useState(false);
	const [time, setTime] = useState(null);
	const [eventTimes, setEventTimes] = useState([]);
	const [stMount, setStMount] = useState(false);
	const [empAvail, setEmpAvail] = useState([]);
	const [combAvail, setCombAvail] = useState([]);
	const [lockOn, setLockOn] = useState(false);
	const [noticeType, setNotice] = useState(null);
	const [selectedPeople, setSelectedPeople] = useState({
		employee: [],
		vendor: [],
		tenant: [],
		owner: []
	});
	const [selectedVendor, setVendor] = useState('');
	const [isSig, setSig] = useState(false);
	const [personSel, setPersonSel] = useState(
		employees.filter(employee => {
			return employee.terminate !== 1;
		}).length > 0
			? 'employee'
			: 'vendor'
	);
	const [validNotice, setValidNotice] = useState(true);
	const [timerAgain, setTimerAgain] = useState(true);
	const [availabilityUserId, setAvailabilityUserId] = useState(null);
	const [eventBeingChanged, setEventBeingChanged] = useState(null);
	const [takenAvail, setTakenAvail] = useState([]);
	const { width } = useWindowDimensions();
	const sigCanvas = useRef({});

	const eventTaken = () => {
		axios
			.post('/jobRequest/getEvents', {
				UserId: selectedPeople.employee.map(emp => {
					return emp.employee_id;
				})
			})
			.then(res => {
				// console.log(res.data.eventData);
				let takenAvailabilities = res.data.eventData;
				let eventAssigned = [];
				takenAvailabilities.forEach((eventTaken, eventIndex) => {
					eventAssigned.push({
						...eventTaken,

						interactMode: 'none',
						eventStyle:
							Object.keys(availableUserColors).length > 0 ? 'color4' : 'color1',
						caption: 'taken'
					});
				});

				setTakenAvail(eventAssigned);
			});
	};

	const sigPadStyle = {
		display: 'block',
		borderRadius: '.25rem',
		border: '1px solid #ced4da',
		width: '450px',
		height: '82.5px',
		margin: 'auto',
		textAlign: 'middle'
	};

	const selectPeople = (person, type) => {
		let newPeople = { ...selectedPeople };
		let peopleIndex = newPeople[type].indexOf(person);
		if (peopleIndex >= 0) newPeople[type].splice(peopleIndex, 1);
		else newPeople[type].push(person);
		setSelectedPeople(newPeople);
	};

	const getEmployeeAvailabilities = () => {
		setStMount(false);
		let employeeNames = {};
		axios
			.post('/get/employeeavailability', {
				employees: selectedPeople.employee.map(emp => {
					employeeNames[emp.employee_id] = emp.fname + ' ' + emp.lname[0] + '.';

					return emp.employee_id;
				})
			})
			.then(response => {
				console.log(response.data.availabilities);
				setAvailabilityUserId(response.data.availabilities.user_id);
				let newAvailabilities = [];
				let fetchedAvailabilities = response.data.availabilities;
				let userAvailabilities = [];
				let combinedAvailabilities = {};
				let possibleAvailabilities = [];
				let newAvail;
				let eventIndex = 0;
				let possibleAvail;

				fetchedAvailabilities.forEach((availObj, userIndex) => {
					userAvailabilities = availObj.availability;
					userAvailabilities.forEach(userAvail => {
						newAvail = { ...userAvail };
						if (newAvail.startDate in combinedAvailabilities) {
							possibleAvailabilities = [
								...combinedAvailabilities[newAvail.startDate]
							];
							for (
								let availIndex = 0;
								availIndex < possibleAvailabilities.length;
								availIndex++
							) {
								possibleAvail = possibleAvailabilities[availIndex];
								if (
									(possibleAvail.startTime <= newAvail.startTime &&
										possibleAvail.endTime >= newAvail.startTime) ||
									(possibleAvail.startTime <= newAvail.endTime &&
										possibleAvail.endTime >= newAvail.endTime) ||
									(newAvail.startTime <= possibleAvail.startTime &&
										newAvail.endTime >= possibleAvail.startTime) ||
									(newAvail.startTime <= possibleAvail.endTime &&
										newAvail.endTime >= possibleAvail.endTime) ||
									possibleAvail.startTime - 1 == newAvail.endTime ||
									possibleAvail.endTime == newAvail.startTime
								) {
									newAvail.startTime = Math.min(
										possibleAvail.startTime,
										newAvail.startTime
									);
									newAvail.endTime = Math.max(
										possibleAvail.endTime,
										newAvail.endTime
									);
									possibleAvailabilities.splice(availIndex, 1);
								}
							}
							combinedAvailabilities[newAvail.startDate] = [
								...possibleAvailabilities,
								{ ...newAvail }
							];
						} else {
							combinedAvailabilities[userAvail.startDate] = [{ ...newAvail }];
						}
						newAvailabilities.push({
							...userAvail,
							caption: employeeNames[availObj.user_id],
							interactMode: 'none',
							eventStyle:
								userIndex + 1 > Object.keys(availableUserColors).length
									? 'color1'
									: 'color' + (userIndex + 1),
							event_id: eventIndex++
						});
					});
				});

				setEmpAvail(newAvailabilities);
				setCombAvail(combinedAvailabilities);
				setStMount(true);
			});
	};

	const setPage = page => {
		switch (page) {
			case 2:
				getEmployeeAvailabilities();
				eventTaken();
				setModalPage(page);
				break;
			default:
				setModalPage(page);
				break;
		}
	};

	const createEvent = () => {
		props.preventClosing(true);
		if (props.reworkModal) {
			axios
				.post('/jobRequest/workOrder/rework', {
					wo_id: props.workId,
					job_id: props.job_id
				})
				.then(res => {
					eventTimes.forEach(eventTime => {
						let body = {
							title: title,
							job_id: props.job_id,
							vendor_id: selectedVendor,
							startDate: new Date(eventTime.startDate),
							start: new Date(eventTime.startDate).setHours(
								eventTime.startTime
							),
							end: new Date(eventTime.startDate).setHours(eventTime.endTime),
							startTime: eventTime.startTime,
							endTime: eventTime.endTime,
							sigPad: sigCanvas.current
								? Object.keys(sigCanvas.current).length > 0
									? sigCanvas.current.toDataURL()
									: null
								: null,
							noticeType: noticeType,
							propertyid: props.request.propertyid,
							selectedPeople: selectedPeople,
							host: window.location.host
						};
						axios
							.post('/jobRequest/add/event', body)
							.then(response => {
								let jrResponse = response;
								if (jrResponse.data.document)
									updateImages([jrResponse.data.document]);
								if (jrResponse.data.updatedStatus)
									updateStatus(jrResponse.data.updatedStatus);
								message.success('Rework schedule is succesfully created.');
								props.onClose();
							})
							.catch(error => {
								props.preventClosing(false);
								console.log(error);
							});
					});
				});
		} else
			eventTimes.forEach(eventTime => {
				let body = {
					title: title,
					job_id: props.job_id,
					vendor_id: selectedVendor,
					startDate: eventTime.startDate,
					start: new Date(eventTime.startDate).setHours(eventTime.startTime),
					end: new Date(eventTime.startDate).setHours(eventTime.endTime),
					startTime: eventTime.startTime,
					endTime: eventTime.endTime,
					sigPad: sigCanvas.current
						? Object.keys(sigCanvas.current).length > 0
							? sigCanvas.current.toDataURL()
							: null
						: null,
					noticeType: noticeType,
					propertyid: props.request.propertyid,
					selectedPeople: selectedPeople,
					host: window.location.host
				};
				axios
					.post('/jobRequest/add/event', body)
					.then(response => {
						let jrResponse = response;
						if (jrResponse.data.document)
							updateImages([jrResponse.data.document]);
						if (jrResponse.data.updatedStatus)
							updateStatus(jrResponse.data.updatedStatus);
						afterEvent(jrResponse.data.scheduledEvents);
						props.preventClosing(false);
					})
					.catch(error => {
						props.preventClosing(false);
						console.log(error);
					});
			});
	};
	console.log(selectedPeople);
	const clearSig = () => {
		if (sigCanvas.current && 'props' in sigCanvas.current)
			sigCanvas.current.clear();
		setSig(false);
	};
	const sigTrue = () => {
		setSig(true);
	};

	const newPdf = async () => {
		console.log('Getting notice data');
		setNoticeData(null);
		const pdfTemplate = await fetch(require('../../../../pdf/notice.pdf'));
		const pdfBuffer = await pdfTemplate.arrayBuffer();
		const pdfDoc = await PDFDocument.load(pdfBuffer);
		const pdfPage = pdfDoc.getPage(0);
		const form = pdfDoc.getForm();
		const textField = form.createTextField('tenant1.textField');
		textField.setText('tenant1.textField');

		textField.addToPage(pdfPage, {
			x: 50,
			y: 75,
			width: 200,
			height: 100,
			textColor: rgb(1, 0, 0),
			borderColor: rgb(0, 0, 0),
			borderWidth: 2
		});
		const fields = form.getFields();
		fields.forEach(field => {
			const type = field.constructor.name;
			const name = field.getName();
			if (type == 'PDFTextField') form.getTextField(name).setText(name);
			console.log(`${type}: ${name}`);
		});
		const savedPdf = await pdfDoc.save();
		setNoticeData(window.URL.createObjectURL(new Blob([savedPdf])));
	};

	const checkIfTimeOk = (newTime = null) => {
		let checkTime =
			newTime ||
			new Date(eventTimes[0].startDate).setHours(eventTimes[0].startTime);
		let dayInAdvance = new Date();
		dayInAdvance.setDate(dayInAdvance.getDate() + 1);
		let startingDay = new Date(checkTime.toString());
		//startingDay.setMinutes(17); Code for easy testing
		return startingDay > dayInAdvance;
	};

	// useEffect(() => {
	/*if (time) {
			const timer = setTimeout(() => {
				if (checkIfTimeOk()) setValidNotice(true);
				else setValidNotice(false);
				setTimerAgain(!timerAgain);
			}, 250);
			return () => clearTimeout(timer);
		}*/
	// });

	let combAvailDisplay = [];

	Object.keys(combAvail).forEach(daysAvails =>
		combAvailDisplay.push(...combAvail[daysAvails])
	);

	switch (modalPage) {
		case 0:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>
							{props.reworkModal ? 'Reschedule Event' : 'Schedule Event'}
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								<Form.Label>Event Purpose</Form.Label>
								<Form.Control
									className='width50 m-auto'
									type='text'
									value={title}
									placeholder={'Purpose'}
									onChange={e => setTitle(e.target.value)}
								/>
							</Form.Group>
							{/* <Form.Group>
								<Form.Label>Entry Required? </Form.Label>
								<Checkbox onChange={e => setEntryRequired(e.target.checked)}>
									Yes
								</Checkbox>
								<Checkbox onChange={e => setEntryRequired(e.target.checked)}>
									No
								</Checkbox>
							</Form.Group> */}
						</Form>
					</Modal.Body>
					<Modal.Footer>
						{props.reworkModal ? (
							<Button onClick={props.onClose}>Close</Button>
						) : (
							<Button onClick={props.onHide}>Close</Button>
						)}

						<Button
							type='primary'
							disabled={title === ''}
							onClick={() => {
								setPage(1);
							}}
						>
							Next
						</Button>
					</Modal.Footer>
				</>
			);
		case 1:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Choose People</div>
					</Modal.Header>
					<Modal.Body
						className='assignVendorMBody text-center'
						style={{ height: '500px' }}
					>
						<Form>
							<Form.Group>
								<br />
								{employees.filter(employee => {
									return employee.terminate !== 1;
								}).length > 0 ? (
									<Button
										onClick={() => {
											setPersonSel('employee');
										}}
									>
										Employees
									</Button>
								) : null}
								{vendors.filter(vendor => {
									return vendor.terminate !== 1;
								}).length > 0 ? (
									<Button
										onClick={() => {
											setPersonSel('vendor');
										}}
									>
										Vendors
									</Button>
								) : null}
								{tenants.length > 0 ? (
									<Button
										onClick={() => {
											setPersonSel('tenant');
										}}
									>
										Tenants
									</Button>
								) : null}
								{owners.length > 0 ? (
									<Button
										onClick={() => {
											setPersonSel('owner');
										}}
									>
										Owners
									</Button>
								) : null}
								<div style={{ marginTop: '10px' }}>
									{
										{
											employee: employees
												.filter(employee => {
													return employee.terminate !== 1;
												})
												.map(employee => {
													return (
														<EmployeeListing
															key={
																employee.startDate + '-' + employee.employee_id
															}
															person={employee}
															selectedPeople={selectedPeople}
															selectPeople={selectPeople}
															id={employee.employee_id}
															type='employee'
														/>
													);
												}),
											vendor: vendors
												.filter(vendor => {
													return vendor.terminate !== 1;
												})
												.map(vendor => {
													return (
														<PersonListing
															key={vendor.startDate + '-' + vendor.vendor_id}
															person={vendor}
															id={vendor.vendor_id}
															selectedPeople={selectedPeople}
															selectPeople={selectPeople}
															type='vendor'
														/>
													);
												}),
											tenant: tenants.map(tenant => {
												return (
													<PersonListing
														key={tenant.startDate + '-' + tenant.tenant_id}
														person={tenant}
														id={tenant.tenant_id}
														selectedPeople={selectedPeople}
														selectPeople={selectPeople}
														type='tenant'
													/>
												);
											}),
											owner: owners.map(owner => {
												return (
													<PersonListing
														key={owner.startDate + '-' + owner.owner_id}
														person={owner}
														id={owner.owner_id}
														selectedPeople={selectedPeople}
														selectPeople={selectPeople}
														type='owner'
													/>
												);
											})
										}[personSel]
									}
								</div>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(0);
							}}
						>
							Back
						</Button>
						<Button
							type='primary'
							disabled={
								selectedPeople.employee.length === 0 &&
								selectedPeople.vendor.length === 0
							}
							onClick={() => {
								setPage(2);
							}}
						>
							Next
						</Button>
					</Modal.Footer>
				</>
			);
		case 2:
			if (!stMount) {
				return (
					<>
						<Modal.Header closeButton>
							<div className='fs-18'>Select Time</div>
						</Modal.Header>
						<Modal.Body>
							<LoadScreen />
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={() => {
									setPage(1);
								}}
							>
								Back
							</Button>
							<Button type='primary' disabled={true}>
								Next
							</Button>
						</Modal.Footer>
					</>
				);
			} else {
				return (
					<>
						<Modal.Header closeButton>
							<div className='fs-18'>Select Time</div>
						</Modal.Header>
						{/* <Grid>
							<Stack direction='row' spacing={1.5} sx={{ mb: 2, ml: 5, pt: 3 }}>
								<Box
									sx={{
										width: 15,
										height: 15,
										backgroundColor: '#778ca2'
									}}
								/>{' '}
								<Typography variant='h7' color='secondary'>
									Employee Availability
								</Typography>
								<Box
									sx={{
										width: 15,
										height: 15,
										backgroundColor: '#fe4d97'
									}}
								/>
								<Typography variant='h7' color='secondary'>
									Event Assigned
								</Typography>
								<Box
									sx={{
										width: 15,
										height: 15,
										backgroundColor: '#4d7cfe'
									}}
								/>
								<Typography variant='h7' color='secondary'>
									Selected Time
								</Typography>
							</Stack>
						</Grid> */}
						<Modal.Body>
							<BarCalendar
								showHeader
								showTimeBar
								dataSource={[
									[
										{
											bars: eventTimes
										}
									],
									[
										{
											bars: empAvail
										}
									],
									[
										{
											bars: takenAvail
										}
									]
								]}
								eventStyles={{
									...availableUserColors,
									default: {
										start: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe'
										},
										middle: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe'
										}
									}
								}}
								calendarView='month'
								interactMode='editable'
								allowNewEvents
								orientation='vertical'
								newEvent={newEvent => {
									return newEvent;
								}}
								editEvent={editEvent => {
									let newTime = editEvent.data;
									//if (newTime && checkIfTimeOk(new(new Date(eventTimes[0].startDate)).setHours(eventTimes[0].startTime))) setValidNotice(true);
									//else setValidNotice(false);
									if (editEvent.operation === 'new') {
										if (
											editEvent.delete_ids &&
											editEvent.delete_ids.length > 0
										) {
											let deleteDictionary = {};
											editEvent.delete_ids.forEach(
												delId => (deleteDictionary[delId] = true)
											);
											setEventTimes(existingEvents => {
												return [
													...existingEvents.reduce((newEvents, evt) => {
														if (!(evt.event_id in deleteDictionary))
															newEvents.push(evt);
														return newEvents;
													}, []),
													{
														startTime: newTime.startTime,
														endTime: newTime.endTime,
														startDate: newTime.startDate,
														event_id: editEvent.id
													}
												];
											});
										} else
											setEventTimes(existingEvents => {
												return [
													...existingEvents,
													{
														startTime: newTime.startTime,
														endTime: newTime.endTime,
														startDate: newTime.startDate,
														event_id: editEvent.id
													}
												];
											});
									} else if (editEvent.operation === 'delete') {
										setEventTimes(existingEvents => {
											return existingEvents.filter(evt => {
												return evt.event_id !== editEvent.id;
											});
										});
									} else if (editEvent.operation === 'merge') {
										let deleteDictionary = {};
										editEvent.delete_ids.forEach(
											delId => (deleteDictionary[delId] = true)
										);
										setEventTimes(existingEvents => {
											return existingEvents.reduce((newEvents, evt) => {
												if (evt.event_id === editEvent.id)
													newEvents.push({
														...evt,
														startTime: newTime.startTime,
														endTime: newTime.endTime,
														startDate: newTime.startDate
													});
												else if (!(evt.event_id in deleteDictionary))
													newEvents.push(evt);
												return newEvents;
											}, []);
										});
									} else {
										setEventTimes(existingEvents => {
											return existingEvents.map(evt => {
												if (evt.event_id === editEvent.id)
													return {
														...evt,
														startTime: newTime.startTime,
														endTime: newTime.endTime,
														startDate: newTime.startDate
													};
												else return evt;
											});
										});
									}

									return editEvent;
								}}
								clickEvent={clickEvent => {
									return clickEvent;
								}}
								blockWidth={45}
								blockHeight={15}
								lockOn={lockOn}
								updateLock={newLock => {
									setLockOn(newLock);
								}}
								restrictToReference={combAvail}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={() => {
									setPage(1);
								}}
							>
								Back
							</Button>
							<Button
								type='primary'
								disabled={eventTimes.length === 0}
								onClick={() => {
									if (tenants.length > 0) setPage(3);
									else {
										setNotice('None');
										setPage(5);
									}
								}}
							>
								Next
							</Button>
						</Modal.Footer>
					</>
				);
			}
		case 3:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Entry Required?</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group>
								<Button
									onClick={() => setPage(4)}
									type='primary'
									className='rqTypeBtns'
								>
									Yes
								</Button>
								<Button
									onClick={() => {
										setNotice('None');
										setPage(5);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									No
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setPage(2)}>Back</Button>
					</Modal.Footer>
				</>
			);
		case 4:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>Prepare Document</div>
					</Modal.Header>
					<Modal.Body
						className='assignVendorMBody text-center'
						style={{ height: '475px' }}
					>
						<Form>
							<Form.Group>
								<Button
									disabled={!validNotice}
									onClick={() => {
										setNotice('Notice');
										setPage(6);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Issue Notice of Entry
								</Button>
								<Button
									onClick={() => {
										setNotice('Consent');
										setPage(7);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Request Consent for Entry
								</Button>
								<Button
									onClick={() => {
										setNotice('Other');
										setPage(8);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Other
								</Button>
								<Button
									onClick={() => {
										setNotice('Emergency');
										setPage(9);
									}}
									type='primary'
									className='rqTypeBtns'
								>
									Emergency
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(3);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 5:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>
							{props.reworkModal ? 'Reschedule Event' : 'Schedule Event'}
						</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody'>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: 14 }}>
										No {tenants.length > 0 ? 'Entry' : 'Notice'} Required
									</span>
								</p>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: 14 }}>
										Purpose:
									</span>{' '}
									{title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: 14 }}>
										Start Time:
									</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].startTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: 14 }}>
										End Time:
									</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].endTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: 14 }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								if (!doPrevent) {
									setNotice(null);
									tenants.length > 0 ? setPage(3) : setPage(2);
								}
							}}
						>
							Back
						</Button>
						<Button
							// icon={doPrevent ? <LoadingOutlined className='fs-18' /> : null}
							// loading={doPrevent}
							onClick={createEvent}
							type='primary'
							// className='rqTypeBtns'
						>
							Submit
						</Button>
					</Modal.Footer>
				</>
			);
		case 6:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>Schedule Event</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>Notice of Entry</span>
									{!validNotice ? (
										<>
											{' '}
											<ExclamationCircleFilled
												style={{ color: '#FE4D97' }}
											/>{' '}
											<span style={{ color: '#FE4D97' }}>
												Notice time is invalid!
											</span>
										</>
									) : null}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].startTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].endTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig || !validNotice}
									onClick={createEvent}
									type='primary'
									className='rqTypeBtns'
								>
									Submit
								</Button>
								{/*noticeData ? <object height="700" type="application/pdf" data={noticeData} style={{width: "100%"}}></object> : null*/}
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								if (!doPrevent) {
									setNotice(null);
									clearSig();
									setPage(4);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 7:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>Schedule Event</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<p>
									<span style={{ fontWeight: 'bold' }}>Request Consent</span>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].startTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].endTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										requesting consent.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={createEvent}
									type='primary'
									className='rqTypeBtns'
								>
									Send
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								if (!doPrevent) {
									setNotice(null);
									clearSig();
									setPage(4);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 8:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>Schedule Event</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<h2>Other</h2>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].startTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].endTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={createEvent}
									type='primary'
									className='rqTypeBtns'
								>
									Submit
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								if (!doPrevent) {
									setNotice(null);
									clearSig();
									setPage(4);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		case 9:
			return (
				<>
					<Modal.Header closeButton={!doPrevent}>
						<div className='fs-18'>Schedule Event</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody' style={{ height: '500px' }}>
						<Form>
							<Form.Group>
								<h2>Emergency</h2>
								<p>
									<span style={{ fontWeight: 'bold' }}>Purpose:</span> {title}
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>Start Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].startTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>End Time:</span>{' '}
									<Moment format='MMM Do YYYY h:mm a'>
										{new Date(eventTimes[0].startDate).setHours(
											eventTimes[0].endTime
										)}
									</Moment>
								</p>
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Please verify that this event information is accurate before
										submitting.
									</span>
								</p>
								<Form.Group style={{ textAlign: 'center' }}>
									<Form.Label>Draw Signature</Form.Label>
									<div style={sigPadStyle}>
										<SignatureCanvas
											penColor='black'
											canvasProps={{ width: 450, height: 82.5 }}
											ref={sigCanvas}
											onEnd={sigTrue}
										/>
									</div>
									<Button className='sigButton' onClick={clearSig}>
										Clear Signature
									</Button>
								</Form.Group>
								<Button
									icon={
										doPrevent ? <LoadingOutlined className='fs-18' /> : null
									}
									loading={doPrevent}
									disabled={!isSig}
									onClick={createEvent}
									type='primary'
									className='rqTypeBtns'
								>
									Submit
								</Button>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								if (!doPrevent) {
									setNotice(null);
									clearSig();
									setPage(4);
								}
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
		default:
			return (
				<>
					<Modal.Header closeButton>
						<div className='fs-18'>PAGE NOT FOUND</div>
					</Modal.Header>
					<Modal.Body className='assignVendorMBody text-center'>
						<Form>
							<Form.Group></Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								setPage(0);
							}}
						>
							Back
						</Button>
					</Modal.Footer>
				</>
			);
	}
}

export function VendorSelectListing({ addFunction, vendList, worker }) {
	return (
		<div
			className='mt-2'
			onClick={() => {
				addFunction(worker.id);
			}}
		>
			<div
				className={
					vendList.includes(worker.id)
						? 'vendor-listing selectedBlue'
						: 'vendor-listing'
				}
			>
				<div className=' d-flex flex-60'>
					<div className='flex-20 listing-icon m-auto'>
						<img
							className='circleImage image32 d-inline'
							src={worker.imageurl}
							alt='avatar'
						/>
					</div>
					<div className='listing-info flex-20 d-inline m-auto fs-14 '>
						{worker.fname} {worker.lname}
					</div>
					<div className='d-flex flex-60'>
						<div className='m-auto fs-list-sm'>{worker.email}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const EmployeeListing = ({
	person,
	selectedPeople,
	selectPeople,
	id,
	type
}) => {
	let isSelected = selectedPeople[type].includes(person) ? 'selectedBlue' : '';
	return (
		<Card
			className={`mb-2 displayCard ${isSelected}`}
			style={{
				margin: 'auto',
				cursor: 'pointer',
				boxShadow: '0 2px 16px rgba(153, 155, 168, 0.12)'
			}}
			onClick={() => selectPeople(person, type)}
		>
			<div className=' cardMain'>
				<Avatar src={person.imageurl} size={38} className='mr-2' />
				<div className='cardLeft' style={{ textAlign: 'left' }}>
					<div className='cardHeader'>
						<div className='cardTitle'>
							{person.fname} {person.lname}
						</div>
					</div>
					<div
						className='cardLeftFooter g-text-sm'
						style={{ textAlign: 'left' }}
					>
						{person.role}
					</div>
				</div>
			</div>
		</Card>
	);
};

const PersonListing = ({ person, selectedPeople, selectPeople, id, type }) => {
	let isSelected = selectedPeople[type].includes(person) ? 'selectedBlue' : '';
	return (
		<>
			<Card
				className={`mb-2 displayCard ${isSelected}`}
				style={{
					margin: 'auto',
					cursor: 'pointer',
					boxShadow: '0 2px 16px rgba(153, 155, 168, 0.12)'
				}}
				onClick={() => selectPeople(person, type)}
			>
				<div className=' cardMain'>
					<Avatar src={person.imageurl} size={38} className='mr-2' />
					<div className='cardLeft' style={{ textAlign: 'left' }}>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname}
							</div>
						</div>
					</div>
				</div>
			</Card>
		</>
	);
};

export default CreateEventModal;
