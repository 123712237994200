import React, { useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";

import Dropzone from "react-dropzone"; //TODO: Remove and replace with ant
import LoadScreen from "../../Components/LoadScreen";
const cancelToken = axios.CancelToken.source();

const ImageUploadModal = ({ visible, onClose, body, route, updateImages }) => {
  //State: Footer (submit or complete), Files: where files are stored, errorMsg: Error msg
  const [footer, setFooter] = useState(
    <SubmitFooter type='primary' close={onClose} submit={() => uploadImage()} />
  );
  const [mainPage, setMainPage] = useState("Upload");
  const [files, setFiles] = useState([]);
  const [errorMessage, setError] = useState("");

  //route for uploading images
  const uploadImage = () => {
    setMainPage("Loading");
    setFooter("");
    var form = new FormData();
    for (let f of files) {
      form.append("file", f);
    }
    if (typeof body === "object") {
      for (let [key, value] of Object.entries(body)) {
        form.append(key, value);
      }
    }
    const uploadClose = () => {
      onClose();
      setMainPage("Upload");
      setFooter(<SubmitFooter type='primary' close={onClose} submit={() => uploadImage()} />)
    }
    axios
      .post(route, form)
      .then((response) => {
        if (response.data.ok) {
          setMainPage("Complete");
          setFooter(<CompleteFooter close={uploadClose} />);
          updateImages(response.data.images);
        }
      })
      .catch((err) => {
        setError([err.response]);
        setMainPage("Error");

        setFooter(<CompleteFooter close={onClose} />);
      });

    return;
  };

  //Used to save files to the file state. If file name already there, will skip the file.
  const selectFiles = (newFiles) => {
    let currentFiles = files;
    let errorMsg = [];
    for (let i of newFiles) {
      let exist = currentFiles.findIndex((file) => file.name === i.name);
      if (exist === -1) currentFiles.push(i);
      else {
        errorMsg.push(
          "Error: A file with name " +
            i.name +
            " is already uploaded. This file has been skipped."
        );
      }
    }
    setFiles(currentFiles);
    if (errorMsg !== []) setError(errorMsg);
    return;
  };

  //Used to remove file from state.
  //Bug: Sometimes does not rerender after deleting from state
  const removeFile = (name) => {
    let index = files.findIndex((file) => file.name === name);
    if (index > -1) {
      let current = files;
      current.splice(index, 1);
      setError("");
      setFiles(current);
      return;
    } else
      setError([
        "Something went wrong when trying to delete this file. Please try again.",
      ]);
    return;
  };
  let torender;
  if (mainPage === "Upload") {
    torender = (
      <UploadBody
        files={files}
        selectFiles={selectFiles}
        removeFile={removeFile}
        errorMessage={errorMessage}
      />
    );
  } else if (mainPage === "Loading") {
    torender = <LoadScreen />;
  } else if (mainPage === "Complete") {
    torender = <CompleteBody />;
  } else if (mainPage === "Error") {
    torender = <ErrorPage errorMessage={errorMessage} />;
  }
  return (
    
    
    <Modal
      title='Upload File'
      visible={visible}
      onCancel={onClose}
      className='imageUploadModal'
      footer={footer}>
      {visible ? torender : <div></div>}
    </Modal>
  
  );
};
const CompleteBody = () => {
  return <div>File upload was a success!</div>;
};
const ErrorPage = ({ errorMessage }) => {
  return (
    <>
      <div>An error occured while uploading the files.</div>
      {errorMessage.map((error) => (
        <div>{error}</div>
      ))}
    </>
  );
};
const UploadBody = ({ files, selectFiles, removeFile, errorMessage }) => {
  return (
    <>
      <Dropzone onDrop={(acceptedFiles) => selectFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className='fileSelectBox text-center'>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
      <h5 className='mt-2'>Files</h5>
      <ul>
        {files.map((file) => (
          <li key={file.path}>
            <img
              src={require("../../icons/delete2.png")}
              alt='delete'
              onClick={() => removeFile(file.name)}
              className='mr-2 deleteBtn'></img>
            <div className=' d-inline fs-14 mr-2'>{file.name}&nbsp;</div>
            <div className='d-inline fs-10'>
              ({(parseFloat(file.size) / 1000).toFixed(2)} KB)
            </div>
          </li>
        ))}
      </ul>
      {errorMessage}
    </>
  );
};

const SubmitFooter = ({ close, submit, className = "" }) => {
  return (
    <div className={className}>
      <Button onClick={close} className='mr-4'>
        Close
      </Button>
      <Button type='primary' onClick={submit}>
        Submit
      </Button>
    </div>
  );
};

const CompleteFooter = ({ close, className }) => {
  return (
    <div className={className}>
      <Button onClick={close}>Close</Button>
    </div>
  );
};

export default ImageUploadModal;
