import React from "react";
import { Menu, Drawer, Layout } from "antd";
import { BtnDiv } from "./DesktopSidebar";
import HeaderProfile from "../Header/HeaderProfile";
const { Sider } = Layout;
class MobileSidebar extends React.Component {
  //enableDragHandle off

  render() {
    return (
      <Drawer
        title={<HeaderProfile mobile mobileClose={this.props.openSidebar} />}
        placement={"left"}
        visible={this.props.sidebarOpen}
        onClose={this.props.openSidebar}
        closable={false}
      >
        <Sider theme="light" className="mobileSidebar">
          {this.props.SidebarMenu}
          {this.props.BtnDiv}
        </Sider>
      </Drawer>
    );
  }
}

function SidebarDropdown({ collapse, pageName, icon }) {
  return (
    <li className="active sidebar-tab">
      <a className="sidebar-link color-g">
        <img
          className="nav-icon d-inline mr-2"
          src={require("../../icons/" + icon + ".png")}
        />
        <div
          className={
            !collapse ? "navbar-label fs-14" : "navbar-label fs-14 d-none"
          }
        >
          {pageName}
        </div>
      </a>
    </li>
  );
}

export default MobileSidebar;
