import React from "react";
import axios from "axios";

import { Modal } from "react-bootstrap";
import {
  Form,
  Button,
  Upload,

} from "antd";
import LoadScreen from './LoadScreen';
import { UploadOutlined } from "@ant-design/icons";
const cancelToken = axios.CancelToken.source();


// UPLOAD SPREADSHEET
class AirbnbUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      loading:false,
      fileList: [],
      error:null
    };

    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload() {
    let fileData = this.state.fileList[0];
    this.setState({ uploading: true,loading:true });
    var form = new FormData();
    form.append("file", fileData);

    var url = "/uploadAirbnbData/" + this.props.spreadsheetType;
    axios
      .post(url, form, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if(response.data.result == "ERROR"){
          console.log(response.data.error)
          this.setState({error:response.data.error, uploading: false, loading: false});
          this.props.importError();
        } else {
          this.setState({ fileList: [], uploading: false, loading: false});
          this.props.success();
        }
      }).catch((err) => this.setState({error:err}));
        
      
  }
  render() {
    const uploadProps = {
      onRemove: (file) => {
        this.setState({ fileList: [] });
      },
      beforeUpload: (file) => {
        if (!this.state.uploading) {
          this.setState({ fileList: [file] });
        }
        return false;
      },
      fileList: this.state.fileList,
      multiple: false,
    };
    return (
      <>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Body style={{minHeight:'100px'}}>
          {!this.state.loading ? 
          
          <Form>
            <Form.Item>
              <Upload {...uploadProps}>
                <Button>
                  <UploadOutlined /> Browse...
                </Button>
              </Upload>
            </Form.Item>
          </Form> : <LoadScreen className='noMarginTop' /> }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Cancel</Button>
          <Button onClick={this.handleUpload} type='primary' disabled={this.state.loading}>Upload</Button>
        </Modal.Footer>
      </>
    );
  }
}

export default AirbnbUpload;
