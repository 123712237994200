import JobRequests from '../Pages/JobRequests/JobRequests';
import UnitJobRequests from '../Pages/JobRequests/UnitJobRequests';
import PropertyJobRequests from '../Pages/JobRequests/PropertyJobRequests';
import Market from '../Market/Market';
//import {Company} from '../Pages/MyCompany/Company';
import Properties from '../Pages/Properties/Properties';
import Overview from '../Pages/Overview/Overview';
import Agreements from '../Pages/Agreements/Agreements';
import People from '../Pages/People/People';
import CommunicationLayout from '../Pages/Communication/CommunicationLayout';
import FinanceLayout from '../Pages/Finance/FinanceLayout';
import EventCalendarLayout from '../Pages/EventCalendar/EventCalendarLayout';
import { RequestListLayout } from '../Pages/Requests/RequestListLayout';
import MyProfile from '../Pages/Profile/MyProfile/MyProfile';
import UserProfile from '../Pages/Profile/UserProfile/UserProfile';
import CompanyPage from '../Pages/Profile/CompanyProfile/companyProfile';
import { SettingsPage } from '../Pages/SettingsPage/SettingsPage';
import WorkLog from '../Pages/WorkLog/WorkLog';
import UnitProfile from '../Pages/Profile/PropertyProfile/UnitProfile';
import PropertyProfile from '../Pages/Profile/PropertyProfile/PropertyProfile';
import PropertyDetails from '../Pages/Intake/PropertyDetails';
import InsuranceTask from '../Pages/Requests/Tasks/InsuranceTask';
import BankingTask from '../Pages/Requests/Tasks/BankingTask';
import CompanyTask from '../Pages/Requests/Tasks/CompanyTask';
import VendorTask from '../Pages/Requests/Tasks/VendorTask';
import AddEmployeeTask from '../Pages/Requests/Tasks/AddEmployeeTask';
import TaskManagementLayout from '../Pages/TaskManagement/TaskManagementLayout';
import ViewWorkLog from '../Pages/WorkLog/ViewWorkLog';
// import ChargeLayout from '../Pages/Charge/charge';
import InvoiceTable from '../Pages/Charge/InvoiceTable';

export default [
	// { path: '/overview', name: 'Overview', Component: Overview, exact: true },
	{
		path: '/maintenance',
		name: 'Maintenance',
		Component: JobRequests,
		exact: false
	},
	{
		path: '/unitMaintenance',
		name: 'Unit Maintenance',
		Component: UnitJobRequests,
		exact: false
	},
	{
		path: '/propertyMaintenance',
		name: 'Property Maintenance',
		Component: PropertyJobRequests,
		exact: false
	},
	{
		path: '/administrative',
		name: 'administrative',
		Component: JobRequests,
		exact: false
	},
	{ path: '/market', name: 'Market', Component: Market, exact: false },

	{
		path: '/Properties',
		name: 'Properties',
		Component: Properties,
		exact: true
	},
	{
		path: '/properties/profile',

		name: 'Property Profile',
		Component: PropertyProfile,
		exact: true
	},
	{ path: '/people', name: 'People', Component: People, exact: true },
	{
		path: '/communication',
		name: 'Communication',
		Component: CommunicationLayout,
		exact: false
	},
	{ path: '/finance', name: 'Finance', Component: FinanceLayout, exact: true },
	{ path: '/charge', name: 'Charge', Component: InvoiceTable, exact: true },
	{
		path: '/work_log_report',
		name: 'Work Log Report',
		Component: ViewWorkLog,
		exact: true
	},
	{
		path: '/event_calendar',
		name: 'Event Calendar',
		Component: EventCalendarLayout,
		exact: true
	},
	{
		path: '/myprofile',
		name: 'My Profile',
		Component: MyProfile,
		exact: true
	},
	{
		path: '/user/profile',
		name: 'User Profile',
		Component: UserProfile,
		exact: true
	},

	{
		path: '/intake/propertyDetails/:pid/:uid',
		name: 'Property Details',
		Component: PropertyDetails,
		exact: true
	},
	{
		path: '/intake/InsuranceInfo',
		name: 'Insurance Info',
		Component: InsuranceTask,
		exact: true
	},
	{
		path: '/intake/BankingInfo',
		name: 'Banking Info',
		Component: BankingTask,
		exact: true
	},
	{
		path: '/intake/CompanyInfo',
		name: 'Company Info',
		Component: CompanyTask,
		exact: true
	},
	{
		path: '/intake/VendorInfo',
		name: 'Vendor Info',
		Component: VendorTask,
		exact: true
	},
	{
		path: '/intake/EmployeeInfo',
		name: 'Employee Info',
		Component: AddEmployeeTask,
		exact: true
	},
	{
		path: '/mycompany',
		name: 'My Company',
		Component: CompanyPage,
		exact: true
	},
	{
		path: '/settings',
		name: 'Settings',
		Component: SettingsPage,
		exact: false
	},
	{
		path: '/workLog',
		name: 'Work Log',
		Component: WorkLog,
		exact: true
	},
	{
		path: '/unit/unitProfile',
		name: 'Unit Profile',
		Component: UnitProfile,
		exact: true
	},
	{
		path: '/properties/:pid/:uid',
		name: 'Property Details',
		Component: UnitProfile,
		exact: true
	},
	{
		path: '/tasks/:req_ID?',
		name: 'Tasks',
		Component: RequestListLayout,
		exact: true
	},
	{
		path: '/taskManagement',
		name: 'Task Management',
		Component: TaskManagementLayout,
		exact: true
	}
];
