import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import { UserProvider } from './Context/UserContext';
import Authenticator from './Authenticator/Authenticator';
import routes from './Routes/LoginRoutes';
import axios from 'axios';
const cancelToken = axios.CancelToken.source();

class App extends React.Component {
	render() {
		axios.defaults.baseURL =
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_DEVELOPMENT_ENDPOINT
				: process.env.REACT_APP_PRODUCTION_ENDPOINT;
		return (
			<div className='App'>
				<UserProvider>
					<Switch>
						{routes.map(({ path, Component }, key) => (
							<Route exact path={path} key={key} component={Component} />
						))}
						<Route component={Authenticator} />
					</Switch>
				</UserProvider>
			</div>
		);
	}
}

export default App;
