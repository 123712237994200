import React from 'react';
import axios from 'axios';

import LoadScreen from '../../../../Components/LoadScreen';
import { Searchbar } from '../../../../Components/Searchbar/Searchbar';
import { Link } from 'react-router-dom';
import { Card, Avatar, Button, Collapse } from 'antd';
import { property } from '../../../../Pages/Agreements/Agreements';
import { leaseType } from '../../../../Pages/Agreements/Agreements';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
const cancelToken = axios.CancelToken.source();
function callback(key) {}
const { Panel } = Collapse;
class VendorList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyId: null,
			selectedList: 'Vendor',
			vendorList: null,
			searchValue: '',
			selectedEmployee: 'Employee'
		};
	}

	componentDidMount() {
		this.getVendorList();
		this.getCompanyId();
	}

	setError = () => {
		return <div>err</div>;
	};
	getCompanyId = () => {
		axios.post('communication/get/managercompany').then(response => {
			this.setState({ companyId: response.data[0].company_id });
		});
	};

	getVendorList = () => {
		axios
			.get('/getRequestAssignVendors/' + this.props.job_id)
			.then(response => {
				this.setState({
					vendorList: [...response.data.vendors, ...response.data.managers]
				});
			});
	};
	setSearch = value => this.setState({ searchValue: value });
	render() {
		let torender;
		if (this.state.selectedList === 'Vendor') {
			if (!this.state.vendorList) {
				torender = (
					<div style={{ height: '72vh' }}>
						<LoadScreen className='m-auto' />
					</div>
				);
			} else if (this.state.vendorList === []) {
				torender = <div>no Vendors</div>;
			}
		}

		return (
			<Modal.Body>
				<div className=''>
					<div className='pt-4' id=''>
						<div
							style={{
								zIndex: 2,
								backgroundColor: 'white',
								position: 'sticky',
								top: 0
							}}
						>
							<div id='searchBarContainer' className='pt-3'>
								<Searchbar
									onChange={e => this.setSearch(e.target.value)}
									value={this.state.searchValue}
									className='propSearchbar mb-4'
								/>
							</div>
						</div>
						{torender || (
							<Listing
								newThread={this.props.newThread}
								toggleContact={this.props.toggleContact}
								selectedVendors={this.props.selectedVendors}
								selectedList={this.state.selectedList}
								vendorList={this.state.vendorList}
								searchValue={this.state.searchValue}
								employeeList={this.props.employeeList}
								selectedEmployee={this.props.selectedEmployee}
								toggleEmployeeContact={this.props.toggleEmployeeContact}
								companyId={this.state.companyId}
							/>
						)}
					</div>
					<br />
				</div>
			</Modal.Body>
		);
	}
}

/*The component that renders the listing cards for each list */
const Listing = ({
	toggleContact,
	selectedVendors,
	newThread,
	selectedList,
	vendorList,
	searchValue,
	employeeList,
	selectedEmployee,
	toggleEmployeeContact,
	companyId
}) => {
	return (
		<div>
			{vendorList
				.filter(person =>
					String(person.fname + ' ' + person.lname + ' - ' + person.type)
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				)
				.map((person, personKey) => (
					<VendorListing
						key={personKey}
						companyId={companyId}
						toggleContact={toggleContact}
						toggleEmployeeContact={toggleEmployeeContact}
						newThread={newThread}
						person={person}
						selectedVendors={selectedVendors}
						searchValue={searchValue}
						employeeList={employeeList}
						selectedEmployee={selectedEmployee}
					/>
				))}
		</div>
	);
};

/* Card for vendors*/
export const VendorListing = ({
	personKey,
	toggleContact,
	companyId,
	newThread,
	person,
	selectedVendors,
	searchValue,
	employeeList,
	selectedEmployee,
	toggleEmployeeContact
}) => {
	return person.id == companyId ? (
		//<div className = {selectedVendors.includes(`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`)?"selectedBlue":""} style={{cursor: "pointer"}} onClick={() => toggleContact(person)}>
		<Collapse
			className={
				selectedVendors.includes(
					`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
				)
					? 'selectedBlue'
					: ''
			}
			ghost
			defaultActiveKey={['1']}
			onChange={() => toggleContact(person)}
		>
			<Panel
				header={
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => toggleContact(person)}
					>
						<Card
							bordered={false}
							className='mb-2 displayCard'
							style={{ margin: 'auto' }}
						>
							<div className=' cardMain'>
								<Avatar
									src={
										'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
									}
									size={38}
									className='mr-2'
								/>
								<div className='cardLeft'>
									<div className='cardHeader'>
										<div className='cardTitle'>
											{person.fname} {person.lname} - {person.type}
										</div>
										<div className='cardContent g-text-sm'></div>
									</div>
									<div className='cardLeftFooter g-text-sm'>
										<div className='cardFooterItem'>{person.email}</div>
										<div className='cardFooterItem ml-4'></div>
									</div>
								</div>
							</div>

							{/* <div className='cardRight mobileRow'>
								<div className='cardRightHeader'></div>
								<div className='cardRightFooter g-text-sm '></div>
							</div> */}
						</Card>
					</div>
				}
			>
				<EmployeeList
					newThread={newThread}
					toggleContact={toggleContact}
					selectedVendors={selectedVendors}
					//selectedList={selectedList}
					employeeList={employeeList}
					searchValue={searchValue}
					selectedEmployee={selectedEmployee}
					toggleEmployeeContact={toggleEmployeeContact}
				/>
			</Panel>
		</Collapse>
	) : (
		// <div className = {selectedVendors.includes(`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`)?"selectedBlue":""} style={{cursor: "pointer"}} onClick={() => toggleContact(person)}>

		<div
			className={
				selectedVendors.includes(
					`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
				)
					? 'selectedBlue'
					: ''
			}
			style={{ cursor: 'pointer' }}
			onClick={() => toggleContact(person)}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname} - {person.type}
							</div>
							<div className='cardContent g-text-sm'></div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							<div className='cardFooterItem'>{person.email}</div>
							<div className='cardFooterItem ml-4'></div>
						</div>
					</div>
				</div>

				{/* <div className='cardRight mobileRow'>
					<div className='cardRightHeader'></div>
					<div className='cardRightFooter g-text-sm '></div>
				</div> */}
			</Card>
		</div>
	);
	//</div>
};
export const EmployeeListing = ({
	toggleContact,
	newThread,
	person,
	selectedVendors,
	vendorList,
	selectedEmployee,
	toggleEmployeeContact
}) => {
	return (
		<div
			className={
				selectedEmployee.includes(
					`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
				)
					? 'selectedBlue'
					: ''
			}
			style={{ cursor: 'pointer' }}
			onClick={() => toggleEmployeeContact(person)}
		>
			<Card
				bordered={false}
				className='mb-2 displayCard'
				style={{ margin: 'auto' }}
			>
				<div className=' cardMain'>
					<Avatar
						src={
							'https://assets.architecturaldesigns.com/plan_assets/325002316/large/500063VV_01_1556635118.jpg?1556635119'
						}
						size={38}
						className='mr-2'
					/>
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.fname} {person.lname} - {person.role}
							</div>
							<div className='cardContent g-text-sm'></div>
						</div>
						<div className='cardLeftFooter g-text-sm'>
							{/*<div className="cardFooterItem">{person.email}</div>*/}
							<div className='cardFooterItem ml-4'></div>
						</div>
					</div>
				</div>

				{/* <div className='cardRight mobileRow'>
					<div className='cardRightHeader'></div>
					<div className='cardRightFooter g-text-sm '></div>
				</div> */}
			</Card>
		</div>
	);
};
const EmployeeList = ({
	toggleContact,
	selectedVendors,
	newThread,
	selectedList,
	selectedEmployee,
	searchValue,
	employeeList,
	toggleEmployeeContact
}) => {
	return (
		<div>
			{employeeList.map((employee, personKey) => (
				<EmployeeListing
					key={personKey}
					toggleContact={toggleContact}
					newThread={newThread}
					person={employee}
					selectedVendors={selectedVendors}
					searchValue={searchValue}
					selectedEmployee={selectedEmployee}
					toggleEmployeeContact={toggleEmployeeContact}
				/>
			))}
		</div>
	);
};

export default VendorList;
