import Moment from "react-moment";
import React, { useState } from "react";
import "./marketListing.css";
import { Card, Avatar } from "antd";
import { Link } from "react-router-dom";
import {
  MaintenanceStatus,
  MaintenanceIcons,
} from "../Pages/JobRequests/RequestListing";
export function DirectJRListing({ request }) {
  return (
    <Link to={"/market/djr_" + request.direct_id}>
      <Card
        bordered={false}
        className="mb-2 displayCard"
        style={{ margin: "auto" }}
      >
        <div className="cardMain">
          <Avatar
            size={38}
            className="mr-2"
            style={{ backgroundColor: "#e3e3e3" }}
          >
            <img
              width={18}
              height={18}
              src={require("../icons/maintenanceIcons/" +
                MaintenanceIcons(request.subcategory))}
            />
          </Avatar>
          <div className="cardLeft">
            <div className="cardHeader">
              <div className="fs-14">{request.brief_description}</div>
            </div>
            <div className="cardLeftFooter g-text-sm">
              <div className="cardFooterItem">ID JR{request.direct_id}</div>
              <div className="cardFooterItem ml-4">
                {request.property_address}
              </div>
              <div className="cardFooterItem mr-2" style={{ float: "right" }}>
                {/*request.elapsedTime
                  ? `${request.elapsedTime.value}${request.elapsedTime.type[0]}`
                : ""*/}
              </div>
            </div>
          </div>
        </div>
        <div className={`cardRight`}>
          <div
            className={`cardRightHeader rightHeaderMxAuto
            `}
          >
            &nbsp;&middot;{" "}
            <div className="d-inline g-text-sm">{request.subcategory}</div>
          </div>
        </div>
      </Card>
    </Link>
  );
}

export function MarketListing({
  icon,
  Category,
  Description,
  City,
  link,
  bidsno,
}) {
  return (
    <Link to={"/market/ad_" + link}>
      <Card
        bordered={false}
        className="mb-2 marketCard"
        style={{ margin: "auto" }}
      >
        <div id="marketCardMain">
          <div className="marketCardHeader">
            <Avatar
              src={require("../icons/maintenanceIcons/" +
                MaintenanceIcons(Category))}
              size={19}
              className="mr-2"
            />
            <div className="maintenanceTitle">{Category}</div>
          </div>
          <div className="maintenanceCardFooter g-text">
            <div className="maintenanceCardID">{Description}</div>
          </div>
        </div>
        <div id="maintenanceCardBody">
          <div className="maintenanceCardHeader">
            <div className=" rq-status d-inline"> {City} </div>
            <div className=" rq-status d-inline" style={{ alignSelf: "right" }}>
              {" "}
              {bidsno}{" "}
            </div>
          </div>
          <div className="maintenanceCardFooter g-text">
            <Moment format="[Last Update: ]DD/MM/YY [at] h:mma">
              {"4:00pm"}
            </Moment>
          </div>
        </div>
      </Card>
    </Link>
  );
}
