import React from "react";
import Task from "../../../Components/Tasks/Task";
import Moment from "react-moment";
import RequestContext from "../RequestContext";
import {message} from 'antd'
import axios from "axios";
const cancelToken = axios.CancelToken.source();

const OnHoldAction = () => {

  const resumeRequest = () => {
    axios
      .post("/jobRequest/update/status/resume", {
        job_id: context.request.job_id,
      })
      .then((response) => {
        context.updateStatus(response.data.status);
        message.success("Request has been successfully resumed", 2.5);
        return;
      })

      .catch((error) => {
        console.log(error);
        context.setError(error.name, error.description);
        return;
      });
  };

    const context = React.useContext(RequestContext);
    return (
      <Task>
        <Task.Title>
          This task was put on hold{" "}
          {<Moment format="on MMM Do YYYY [at] h:mma">{context.request.last_update}</Moment>}
        </Task.Title>
        <Task.Action primary onClick={resumeRequest}>
          Resume Request
        </Task.Action>
      </Task>
    );
  };

  export default OnHoldAction;