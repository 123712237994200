import React from 'react';
import {
	ToolCardAction,
	WorkOrderFooter,
	MaterialCardAction,
	UploadCardAction
} from '../WorkOrder/WorkOrder';
import { Button, Tooltip } from 'antd';
import WorkOrderContext from '../WorkOrder/WorkOrderContext';
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext';
import {
	PauseCircleOutlined,
	CheckCircleOutlined,
	ClockCircleOutlined
} from '@ant-design/icons';

const WorkDoneMenu = () => {
	const context = React.useContext(WorkOrderContext);
	const requestContext = React.useContext(RequestContext);
	return (
		<div>
			<ToolCardAction
				text='Actions Taken '
				action={() => context.changePage('Actions Taken')}
			/>
			<UploadCardAction
				text='Upload Picture or Document'
				action={() => context.changePage('Work Done Images')}
			/>
			{/* <MaterialCardAction
				text='Enter Actual Expenses'
				action={() => context.changePage('Actual Expenses')}
			/> */}
			{
				//<CardAction text='Enter actions taken' action={() => context.changePage("Action")} />
				//<CardAction text='Enter labour details' action={() => context.changePage("Labour")} />
			}
			<br />
			<br />
			<WorkOrderFooter>
				{/* <Tooltip title='Pause Work Order'>
					<Button
						type='text'
						size='large'
						icon={<PauseCircleOutlined />}
						onClick={() => context.changePage('Pause Work Order')}
					></Button>
				</Tooltip>

				<Tooltip title='Request Extension'>
					<Button
						style={{ marginLeft: '5px', color: '#98a9bc', fontSize: '125%' }}
						type='text'
						size='large'
						icon={<ClockCircleOutlined />}
						onClick={() => context.changePage('Request Extension')}
					/>
				</Tooltip> */}

				{/* <Tooltip title='Complete Work Order'> */}
				<Button
					// style={{ marginLeft: '5px', fontSize: '125%', color: '#6dd230' }}
					type='primary'
					size='large'
					// icon={<CheckCircleOutlined />}
					onClick={() => context.changePage('Complete Work Order')}
				>
					Finish
				</Button>
				{/* </Tooltip> */}
			</WorkOrderFooter>
		</div>
	);
};

export default WorkDoneMenu;
