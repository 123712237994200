
import React, { useContext } from 'react'
import {Card} from 'antd'
import RequestContext from '../RequestContext'
import { MaintenanceRequestListing } from "../../JobRequests/RequestListing";


const RequestListingView = (props) => {
    const {request} = props;
    return(
        <div className="requestDescription">

            <Card
            id="rqDescription"
            bordered={false}
        >
            <MaintenanceRequestListing
            request={request}
            clickable={false}
            titleColors={true}
            />
            </Card>
        </div>
    )


}

export default RequestListingView;