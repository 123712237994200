import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
export const UserCard = ({
	image = '',
	user_id,
	className = '',
	name = '',
	accType = '',
	email = '',
	phoneNumber = '',
	inline = false,
	actions = false,
	border = true
}) => {
	return (
		<Link
			to={{ pathname: '/user/profile', state: { id: user_id } }}
			className={`_profileCard
        ${inline ? 'd-inline-block ' : 'd-block '}
        ${className ? className : ''}
        ${border ? 'cardBorder' : ''}`}
		>
			<div className='_content'>
				<div className='_left'>
					{image === '' || image === 'avatar_default.png' ? (
						<Avatar size={64}>{name.charAt(0)}</Avatar>
					) : (
						<Avatar size={64} src={image}></Avatar>
					)}
				</div>
				<div className='_right'>
					{name !== '' ? <h5>{name}</h5> : ''}

					{accType !== '' ? (
						<div className='g-text-sm _location'>{accType}</div>
					) : (
						''
					)}
					{email !== '' ? (
						<div className='g-text-sm _location'>{email}</div>
					) : (
						''
					)}
					{phoneNumber !== '' ? (
						<div className='g-text-sm _location'>{phoneNumber}</div>
					) : (
						''
					)}
				</div>
			</div>
		</Link>
	);
};

export const UserCardSm = ({ user_id, name, type, image }) => {
	return (
		<Link>
			{image === '' ? (
				<Avatar size={64}>{name.charAt(0)}</Avatar>
			) : (
				<Avatar size={64} src={image}></Avatar>
			)}
			<div>{name}</div>
		</Link>
	);
};
