import React from 'react';
import { Form } from 'react-bootstrap';
import {
	InputNumber,
	Checkbox,
	Button,
	message,
	Alert,
	DatePicker
} from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import moment from 'moment';

import RequestContext from '../../MaintenanceRequest/RequestContext';
const cancelToken = axios.CancelToken.source();

class EditUserDetails extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			userID: this.props.userID,
			firstName: this.props.fname,
			lastName: this.props.lname,
			homeNumber: this.props.homeNumber,
			homeStreet: this.props.street,
			email: this.props.email,
			phone: this.props.phone,
			city: this.props.city,
			province: this.props.province,
			postalCode: this.props.postalCode,
			birthDate: this.props.birthDate,
			taxRate: this.props.taxRate,
			hst: this.props.hst_number,
			// vendorService: this.props.user.services || '',
			type: '',
			totalUnits: 1,
			areTenants: false,
			submitted: false,
			loaded: false,
			serviceList: [],
			provinceList: []
		};
	}
	componentDidMount = () => {
		axios.post('/getVendorServices').then(res => {
			console.log(res.data.vendorServices);
			this.setState({ serviceList: res.data.vendorServices });
		});
		this.getProvincesList();
	};
	getProvincesList = () => {
		axios.post('/getProvinces').then(res => {
			console.log(res.data.provinces);
			this.setState({ provinceList: res.data.provinces });
		});
	};

	verify = () => {
		if (this.state.homeStreet === '') {
			this.setState({ errorMessage: 'Please enter a street address.' });
			return false;
		} else if (
			this.state.streetNum === '' ||
			isNaN(parseInt(this.state.streetNum))
		) {
			this.setState({ errorMessage: 'Please enter an street number.' });
			return false;
		} else if (this.state.city === '') {
			this.setState({ errorMessage: 'Please enter a city.' });
			return false;
		} else if (this.state.province === '') {
			this.setState({ errorMessage: 'Please enter a province.' });
			return false;
		} else if (this.state.postalCode === '') {
			this.setState({ errorMessage: 'Please enter a postal code.' });
			return false;
		} else if (this.state.type === '') {
			this.setState({ errorMessage: 'Please enter a province.' });
			return false;
		} else if (this.state.totalUnits < 1 || this.state.totalUnits > 100) {
			this.setState({ errorMessage: 'Please enter valid units.' });
			return false;
		}

		return true;
	};

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	numberFormat = value => {
		let newNumber = '';
		let phoneNumber = ('' + value).replace(/[^\d]/g, '');
		for (let i = 0; i < phoneNumber.length; i++) {
			if (i == 0) {
				newNumber = '(';
			} else if (i == 3) {
				newNumber = newNumber + ')';
			} else if (i == 6) {
				newNumber = newNumber + '-';
			}

			newNumber = newNumber + phoneNumber[i];
		}
		return newNumber;
	};
	/* Auto formatting phone number */
	handlePhoneNumber = e => {
		const { name, value } = e.target;
		this.setState({ [name]: this.numberFormat(value) });
	};
	submitForm = () => {
		if (
			this.props.user.Vendor_account === 1 ||
			this.props.user.Admin_account === 1
		) {
			axios
				.post('/updateVendor', {
					userID: this.state.userID,
					newPhone: this.state.phone,
					newEmail: this.state.email,
					newFname: this.state.firstName,
					newLname: this.state.lastName,
					newHomeNumber: this.state.homeNumber,
					newHomeCity: this.state.city,
					newHomeStreet: this.state.homeStreet,
					newHomeProvince: this.state.province,
					newHomePostalcode: this.state.postalCode,
					birthDate: this.state.birthDate,
					TaxRate: this.state.taxRate,
					HST: this.state.hst,
					// newVendorService: this.state.vendorService,
					vendorAddress:
						this.state.homeNumber +
						' ' +
						this.state.homeStreet +
						' ' +
						this.state.city +
						' ' +
						this.state.province +
						' ' +
						this.state.postalCode
				})
				.then(() => {
					this.setState({ submitted: true });
					window.location.reload();
				});
		} else {
			axios
				.post('/updateuser', {
					userID: this.state.userID,
					newPhone: this.state.phone,
					newEmail: this.state.email,
					newFname: this.state.firstName,
					newLname: this.state.lastName,
					newHomeNumber: this.state.homeNumber,
					newHomeCity: this.state.city,
					newHomeStreet: this.state.homeStreet,
					newHomeProvince: this.state.province,
					newHomePostalcode: this.state.postalCode,
					birthDate: this.state.birthDate
				})
				.then(() => {
					this.setState({ submitted: true });
					window.location.reload();
				});
		}
	};

	render() {
		console.log(this.state.hst);
		if (this.state.submitted) {
			return <h3>Profile has been updated! </h3>;
		}
		return (
			<div>
				<Form>
					{this.props.user.Tenant_account === 1 ||
					this.props.user.PO_account === 1 ? (
						<>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Email </Form.Label>
									<Form.Control
										editable='true'
										required
										className='form-text'
										name='email'
										id='email'
										type='text'
										value={this.state.email || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>First Name </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='firstName'
										id='firstName'
										type='text'
										value={this.state.firstName || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Last Name </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='lastName'
										id='lastName'
										type='text'
										value={this.state.lastName || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Phone Number </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='phone'
										id='phone'
										maxlength='13'
										type='text'
										value={this.state.phone || ''}
										onChange={this.handlePhoneNumber}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>
										HST Registration #{' '}
									</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='hst'
										id='hst'
										type='text'
										value={this.state.hst || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Tax Rate(%)</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='taxRate'
										id='taxRate'
										type='text'
										value={this.state.taxRate || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								{this.props.user.id === 42 ? (
									''
								) : (
									<Form.Group as={Col}>
										<Form.Label className='form-label'>
											Date of Birth
										</Form.Label>
										<DatePicker
											defaultValue={moment(this.state.birthDate)}
											onChange={(date, dateString) => {
												this.setState({ birthDate: dateString });
											}}
										/>
									</Form.Group>
								)}
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Home Number</Form.Label>
									<Form.Control
										editable='true'
										required
										className='form-text'
										name='homeNumber'
										id='homeNumber'
										type='text'
										value={this.state.homeNumber || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>Street </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='homeStreet'
										id='homeStreet'
										value={this.state.homeStreet || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>City</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='city'
										type='text'
										value={this.state.city || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>Postal Code</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='postalCode'
										type='text'
										value={this.state.postalCode || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Province</Form.Label>
									<select
										name='province'
										className='form-control'
										value={this.state.province}
										onChange={e => this.setState({ province: e.target.value })}
									>
										<option value={''}>Select Province</option>
										{this.state.provinceList.map(provinces => (
											<option
												value={provinces.province_shortform}
												key={provinces.province_shortform}
											>
												{provinces.province_name}
											</option>
										))}
									</select>
								</Form.Group>
							</Form.Row>
						</>
					) : this.props.user.Vendor_account === 1 ? (
						<>
							<Form.Row>
								<Form.Group>
									<Form.Label className='form-label'>Email </Form.Label>
									<Form.Control
										editable='true'
										required
										className='form-text'
										name='email'
										id='email'
										type='text'
										value={this.state.email || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>First Name </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='firstName'
										id='firstName'
										type='text'
										value={this.state.firstName || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Last Name </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='lastName'
										id='lastName'
										type='text'
										value={this.state.lastName || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Phone Number </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='phone'
										id='phone'
										maxlength='13'
										type='text'
										value={this.state.phone || ''}
										onChange={this.handlePhoneNumber}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} sm={2} md={3}>
									<Form.Label className='form-label'>Home Number</Form.Label>
									<Form.Control
										editable='true'
										required
										className='form-text'
										name='homeNumber'
										id='homeNumber'
										type='text'
										value={this.state.homeNumber || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>Street </Form.Label>
									<Form.Control
										required
										className='form-text'
										name='homeStreet'
										id='homeStreet'
										value={this.state.homeStreet || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>City</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='city'
										type='text'
										value={this.state.city || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>Postal Code</Form.Label>
									<Form.Control
										required
										className='form-text'
										name='postalCode'
										type='text'
										value={this.state.postalCode || ''}
										onChange={this.handleChange}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<Form.Label className='form-label'>Province</Form.Label>
									<select
										name='province'
										className='form-control'
										value={this.state.province}
										onChange={e => this.setState({ province: e.target.value })}
									>
										<option value={''}>Select Province</option>
										{this.state.provinceList.map(province => (
											<option
												value={province.province_shortform}
												key={province.province_id}
											>
												{province.province_name}
											</option>
										))}
									</select>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label className='form-label'>
										Vendor Service{' '}
									</Form.Label>
									<select
										name='vendorService'
										className='form-control'
										value={this.state.vendorService}
										onChange={e =>
											this.setState({ vendorService: e.target.value })
										}
									>
										<option value={''}>Select Service</option>
										{this.state.serviceList.map(service => (
											<option
												value={service.service_name}
												key={service.service_name}
											>
												{service.service_name}
											</option>
										))}
									</select>
								</Form.Group>
							</Form.Row>
						</>
					) : (
						''
					)}

					{this.state.errorMessage ? (
						<Alert
							message={this.state.errorMessage}
							type='error'
							className='pointer'
							showIcon
							onClick={this.closeError}
							closable
						/>
					) : (
						''
					)}
					<br></br>
					<div className='text-right'>
						<Button
							/*disabled={disabled}*/ onClick={this.submitForm}
							type='primary'
						>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default EditUserDetails;
