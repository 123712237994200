import React, { useState } from 'react';
import LoginLayout from './LoginLayout';
import { Form, Button } from 'react-bootstrap';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import axios from 'axios';

import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
const cancelToken = axios.CancelToken.source();

/*  TODO
-Add more password requirements: length,capital,number
-Add 2fa
*/
function SetPassword() {
	document.title = 'Set Password';
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setError] = useState('');
	const [screen, setScreen] = useState('Form');
	const sendNewPassword = () => {
		setScreen('Loading');
		if (!verify()) {
			return;
		}

		axios
			.post('/api/setPassword', {
				crossdomain: true,
				password: confirmPassword,
				session_id: JSON.parse(Cookies.get('session')).session_id
			})
			.then(function (response) {
				if (response.data.ok) setScreen('Overview');
				else {
					setError('Error fetching account data. Please try logging in again.');
					setScreen('Form');
				}
			})
			.catch(error => {
				setError(
					'Error connecting to the server. Please refresh or try again later.'
				);
				setScreen('Form');
			});
		return;
	};

	const verify = () => {
		if (newPassword !== confirmPassword) {
			setError('Passwords entered do not match. Please try again. ');
			return false;
		}
		return true;
	};

	if (screen === 'Form') {
		return (
			<LoginLayout header='Set Password'>
				<Form className='flex-60'>
					<Form.Group>
						<Form.Label>New Password</Form.Label>
						<Form.Control
							size='lg'
							type='password'
							value={newPassword}
							placeholder='Enter new password'
							onChange={e => setNewPassword(e.target.value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							size='lg'
							type='password'
							value={confirmPassword}
							placeholder='Confirm password'
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</Form.Group>
					<Button
						className={'highlightedButton1 signInBtn boxShadowNone'}
						onClick={sendNewPassword}
					>
						Set Password
					</Button>
					<ErrorMessage otherClass='mt-2'>{errorMessage}</ErrorMessage>
				</Form>
			</LoginLayout>
		);
	} else if (screen === 'Overview') {
		return <Redirect to='/communication' />;
	} else {
		return (
			<LoginLayout header='Set Password'>
				<ErrorMessage>{errorMessage}</ErrorMessage>
			</LoginLayout>
		);
	}
}

export default SetPassword;
