import React, { useState } from 'react';
import { Modal, ModalTitle, Form } from 'react-bootstrap';
import Task from '../../../Components/Tasks/Task';
import axios from 'axios';
import WorkOrderReview from '../../../Modals/WorkOrder/WorkOrderReview';

import { Button, message, DatePicker } from 'antd';
import RequestContext from '../RequestContext';
const cancelToken = axios.CancelToken.source();

export default class WorkOrderReviewAction extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = {
			page: 'Loading',
			reviewWorkOrderModal: false,
			workDetails: ''
		};
	}
	componentDidMount = () => {
		if (this.context.activeEvent !== null) this.getEventId();
	};
	// componentDidUpdate = () => {
	// 	if (this.context.activeEvent !== null) this.getEventId();
	// };
	getEventId = async () => {
		await axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: this.context.activeEvent?.idcalendar_events
			})
			.then(res => {
				// console.log(res.data.details[0].wo_id);
				this.setState({
					workDetails: res.data.details,
					workOrderId: res.data.details[0].wo_id
				});
			})
			.catch(err => {
				console.log(err);
			});
	};
	openReviewWorkOrderModal = () => {
		this.setState({ reviewWorkOrderModal: !this.state.reviewWorkOrderModal });
	};

	approveWorkOrder = () => {
		// axios
		// 	.post('/jobRequest/update/status/workOrder/approve', {
		// 		job_id: this.context.request.job_id,
		// 		// wo_id: this.context.currentWorkOrder.wo_id
		// 		wo_id: this.state.workOrderId
		// 	})
		axios
			.post('/eventCalendar/updateStatusToApproved', {
				job_id: this.context.request.job_id,
				// wo_id: this.context.currentWorkOrder.wo_id
				wo_id: this.state.workOrderId
			})
			.then(response => {
				message.success('Work Order Successfully Approved!', 2.5);
				this.context.addActivity(response.data.note, null, true);
				this.context.updateEventStatus('Work Approved');
			})
			.catch(error => {
				console.log(error);
			});
	};

	pauseWorkOrder = reason => {
		axios
			.post('workOrder/update/status/hold', {
				status_reason: reason,
				job_id: this.context.request.job_id,
				// wo_id: this.context.currentWorkOrder.wo_id
				wo_id: this.state.workOrderId
			})
			.then(response => {
				message.success('Work Order successfully paused.', 2.5);
				this.context.addActivity(response.data.note, null, true);
				this.context.updateEventStatus('On Hold');
			})
			.catch(error => {
				console.log(error);
			});
	};

	reworkWorkOrder = reason => {
		axios
			.post('workOrder/update/status/rework', {
				status_reason: reason,
				job_id: this.context.request.job_id,
				// wo_id: this.context.currentWorkOrder.wo_id
				wo_id: this.state.workOrderId
			})
			.then(response => {
				message.success(
					'Event for Reworking Work Order has been successfully created.',
					2.5
				);
				this.context.addActivity(response.data.note, null, true);
				this.context.updateEventStatus('Work Order - Scheduled');
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		if (
			// this.context.currentWorkOrder &&
			// this.context.currentWorkOrder.status === 'Paused' &&
			// this.state.workDetails &&
			// this.state.workDetails.status === 'Paused' &&
			(this.context.user.id === this.context.request.PM_id ||
				this.context.user.company_id === this.context.request.PM_id) &&
			this.context.user.type === 'Manager'
		) {
			return (
				<>
					<Task>
						<Task.Title>Review The Completed Work Order</Task.Title>
						{/* <Task.Action primary onClick={this.openReviewWorkOrderModal}> */}
						<Task.Action primary>Review Work Order</Task.Action>
					</Task>

					<WorkOrderReview
						startingPage={'ReviewWorkOrderInfo'}
						modalOpen={this.state.reviewWorkOrderModal}
						onCancel={this.openReviewWorkOrderModal}
						currentWorkOrder={this.state.workDetails}
						approveWorkOrder={this.approveWorkOrder}
						pauseWorkOrder={this.pauseWorkOrder}
						reworkWorkOrder={this.reworkWorkOrder}
					/>
				</>
			);
		} else {
			return null;
		}
	}
}
