import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Avatar, Button, message, Select } from 'antd';

const ChangeRoleModal = ({ onHide, employee, changeEmployeeRole }) => {
	const [role, selectRole] = useState(employee.role);

	return (
		<>
			<Modal.Header closeButton>
				Change {employee.fname + ' ' + employee.lname + "'s"} Role
			</Modal.Header>
			<Modal.Body>
				<Select
					value={role}
					onChange={e => selectRole(e)}
					style={{ width: '30%' }}
				>
					<Select.Option value='Maintenance'>Maintenance</Select.Option>
					<Select.Option value='Accounting'>Accounting</Select.Option>
					<Select.Option value='Property Manager'>
						Property Manager
					</Select.Option>
					<Select.Option value='Branch Manager'>Branch Manager</Select.Option>
				</Select>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onHide}>Cancel</Button>
				<Button type='primary' onClick={() => changeEmployeeRole(role)}>
					Change Role
				</Button>
			</Modal.Footer>
		</>
	);
};
export default ChangeRoleModal;
