import React from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

import {
  vendorServices,
  vendorProvinces,
} from "../../../StringData/VendorObjects";
import { Select, Space } from "antd";
import { Button } from "antd";
import { Upload, message } from "antd";
import provinceCertificateRequirements from "../../../StringData/provinceCertificateRequirements";
import LoadScreen from "../../../Components/LoadScreen";
const cancelToken = axios.CancelToken.source();

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/pdf" ||
    file.type === "application/pdf";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/PDF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class VendorTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorName: "",
      vendorService: "",
      vendorProvince: "",
      credentials: null,
      hstNumber: "",
      licenseNumber: "",
      requirement: "",
      loaded:false
    };
  }

  //Function checking the state values making sure the required inputs are filled (you can change this depending on whats required/optional)
  getRequirements = () => {
    if (this.state.vendorProvince === "" || this.state.vendorService === "")
      return console.log("one or more empty");



    let province = provinceCertificateRequirements.find(
      (ele) =>
        String(ele.province).toLowerCase() ===
        String(this.state.vendorProvince).toLowerCase()
    );
    if (province === undefined) {
      return;
    }
    let service = province.services.find(
      (ele) =>
        String(ele.service).toLowerCase() ===
        this.state.vendorService.toLowerCase()
    );
    if (service === undefined) return;
    this.showHideRequirements(service);
  };
  showHideRequirements = (service) => {
    switch (service.requirements) {
      case 0: {
        return this.setState({ requirement: "No License Required" });
      }
      case 1: {
        return this.setState({ requirement: "License Required" });
      }
      case 2: {
        return this.setState({ requirement: "Permit Required" });
      }
      case 3: {
        return this.setState({ requirement: "Certificate Required" });
      }
      default: {
        return this.setState({ requirements: "No License Required" });
      }
    }
  };
  verify = () => {
    return (
      this.state.vendorName !== "" &&
      this.state.vendorProvince !== "" &&
      this.state.credentials !== null &&
      this.state.licenseNumber !== null
    );
  };

  //Submit form and send data to the server.  [Add: handling after form is submitted]
  submitForm = () => {
    //Check if form is filled out correctly
    if (!this.verify()) {
      message.error("Please Fill out the form completely before submitting.");
      return false;
    }

    //FormData obj used to send files to the server
    let form = new FormData();

    //Values being appended to the form body
    let data = {
      vendorName: this.state.vendorName,
      vendorService: this.state.vendorService,
      vendorProvince: this.state.vendorProvince,
      hstNumber: this.state.hstNumber,
      licenseNumber: this.state.licenseNumber,
    };

    //Appending data
    Object.keys(data).map((key) => {
      form.append(key, data[key]);
    });
    //appending the file
    form.append("credentials", this.state.credentials);

    //Sending data to server
    axios
      .post("/vendorComplete", form)
      .then((response) => {
        //next page
      })
      .catch((err) => console.log(err));
  };

  handleUpload = (fileName, displayName, file) => {
    getBase64(file.file, (preview) => {
      file.file.preview = preview;
      file.file.fileName = fileName;
      file.file.displayName = displayName;
      file.file.status = "done";
      this.setState({ [fileName]: file.file });
    });
  };

  handleChange = (fileName) => {
    this.setState({ [fileName]: null });
  };

  selectService = (service) => {
    this.setState({ vendorService: service }, this.getRequirements);
  };

  selectProvince = (province) => {
    this.setState({ vendorProvince: province }, this.getRequirements);
  };

  componentDidMount(){
		axios.post("/getVendor",{data: this.props.taskData}).then((response) => {
        let vendorName = response.data.vendorName || ""
        let vendorService = response.data.vendorService || "Select Service"
        let vendorProvince = response.data.vendorProvince || "Select Province"
        // response.data.credentials = null
        let hstNumber = response.data.hstNumber || ""
        let licenseNumber = response.data.licenseNumber || ""
        let requirement = response.data.requirement ||""
        
        this.setState({ vendorName: vendorName, vendorService: vendorService, vendorProvince:vendorProvince, HST_no: hstNumber, License_number: licenseNumber, requirement: requirement,loaded:true})

		})
  }
  render() {

    //Wait until the didmount is complete
    if(!this.state.loaded) return( <LoadScreen className='m-auto' />)
    
    return (
      <div className="taskContainer">
        <div>
          <h3 className="title">Vendor Info</h3>
          <Form action="vendorComplete" method="post">
            <Form.Group>
              <p>
                Description: Please enter the following information to update
                your account.
              </p>
              <Form.Label className="form-label">Vendor Name:</Form.Label>
              <Form.Control
                className="form-text"
                name="vendorName"
                type="text"
                placeholder='Name'
                defaultValue={this.state.vendorName}
                onChange={(e) => this.setState({ vendorName: e.target.value })}
              />

              <Space direction="vertical" size={12}>
                <br />
                <Form.Label className="form-label">Vendor Province</Form.Label>
                <Select
                  name="vendorProvince"
                  id="provinceSelect"
                  defaultValue={this.state.vendorProvince}

                  onChange={(e) => this.selectProvince(e)}
                >
                  {Object.keys(vendorProvinces).map((key) => (
                    <Select.Option value={vendorProvinces[key]} key={key}>
                      {vendorProvinces[key]}
                    </Select.Option>
                  ))}
                </Select>
                <Form.Label className="form-label">Vendor Service</Form.Label>
                <Select
                  name="vendorService"
                  id="vendorService"
                  defaultValue={this.state.vendorService}

                  onChange={(e) => this.selectService(e)}
                >
                  {Object.keys(vendorServices).map((key) => (
                    <Select.Option value={vendorServices[key]} key={key}>
                      {vendorServices[key]}
                    </Select.Option>
                  ))}
                </Select>

                <Form.Label className="form-label">Credentials:</Form.Label>
                <div className="errorMsg fs-14">{this.state.requirement}</div>

                <Upload
                  name="credentialsUpload"
                  customRequest={(file) =>
                    this.handleUpload("credentials", "Credentials Upload", file)
                  }
                  listType="picture-card"
                  showUploadList={false}
                  className="cred-uploader"
                  beforeUpload={beforeUpload}
                  onChange={() => this.handleChange("credentials")}
                >
                  {this.state.credentials !== null ? (
                    <img
                      alt="preview"
                      className="imagePreview"
                      src={this.state.credentials.preview}
                    />
                  ) : (
                    "Upload Credentials"
                  )}
                </Upload>
                <Form.Label className="form-label">HST Number:</Form.Label>
                <Form.Control
                  className="form-text"
                  name="hstNumber"
                  type="text"
                  defaultValue={this.state.hstNumber}
                  placeholder='HST Number'
                  onChange={(e) => this.setState({ hstNumber: e.target.value })}
                />
                <Form.Label className="form-label">License Number:</Form.Label>
                <Form.Control
                  className="form-text"
                  name="licenseNumber"
                  type="text"
                  defaultValue={this.state.licenseNumber}
                  placeholder='License Number'
                  onChange={(e) =>
                    this.setState({ licenseNumber: e.target.value })
                  }
                />
              </Space>
            </Form.Group>

            <br></br>
            <Button onClick={this.submitForm}>Submit</Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default VendorTask;
