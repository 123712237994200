import React, { Component } from "react";


import{ Card, Typography, Avatar } from "antd"; 

export default class ReviewWorkOrderInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <>
            {/*
            <Card size="small" bodyStyle={{width: "100%"}} style={{ display: 'flex', marginTop: '3px' }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Avatar src={}
                </div>
            </Card>
            <Typography.Text style ={{ marginBottom: '5px' }}>
                has requested an extension for the corresponding work order:
            </Typography.Text>
            <br></br>
            */}
            </>
        )
    }
}