import React, { useState } from 'react';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
import { withRouter } from 'react-router-dom';
import './sidebar.css';
import { Link, Redirect } from 'react-router-dom';
import { Menu, Button, Badge } from 'antd';
import Feedback from '../../Modals/Feedback/Feedback';
import { useUserState } from '../../Context/UserContext';

import {
	FormOutlined,
	ProfileOutlined,
	InfoCircleOutlined,
	FileTextOutlined,
	ReadOutlined,
	ToolOutlined,
	MessageOutlined,
	CalendarOutlined,
	DollarCircleOutlined,
	HeartOutlined,
	MoneyCollectOutlined,
	QuestionOutlined,
	IdcardOutlined,
	HomeOutlined,
	ShopOutlined,
	FileProtectOutlined,
	GroupOutlined,
	ClockCircleOutlined,
	BarsOutlined
} from '@ant-design/icons';
import axios from 'axios';

import { UserStateContext } from '../../Context/UserContext';
const cancelToken = axios.CancelToken.source();

const { SubMenu } = Menu;

class Sidebar extends React.Component {
	static contextType = UserStateContext;
	constructor() {
		super();
		this.state = {};
	}

	render() {
		let pathName = this.props.history.location.pathname;
		if (this.props.mobile) {
			return (
				<MobileSidebar
					pathName={pathName}
					sidebarOpen={this.props.sidebarOpen}
					openSidebar={this.props.openSidebar}
					openFeedback={this.props.openFeedback}
					SidebarMenu={
						<SidebarMenu defaultkey={pathName} close={this.props.openSidebar} />
					}
					BtnDiv={
						<BtnDiv
							mobile
							className='mt-2'
							openRequest={this.props.openRequest}
							closeSidebar={this.props.openSidebar}
						/>
					}
				/>
			);
		} else {
			return (
				<DesktopSidebar
					pathName={pathName}
					collapseNavBar={this.props.collapseNavBar}
					openFeedback={this.props.openFeedback}
					SidebarMenu={
						<SidebarMenu
							defaultkey={pathName}
							BtnDiv={
								<BtnDiv
									collapseNavBar={this.props.collapseNavBar}
									mobile={this.props.mobile}
									closeSidebar={this.props.openSidebar}
								/>
							}
						/>
					}
				/>
			);
		}
	}
}

const SidebarMenu = ({ defaultkey, BtnDiv, close }) => {
	const { user } = useUserState();
	let commNotificationUpdate = window.sessionStorage.getItem(
		'commNotificationUpdate'
	)
		? window.sessionStorage.getItem('commNotificationUpdate')
		: 0;
	if (user === undefined) return <Redirect to='/' />;

	const getCommunicationNotifications = () => {
		let d = new Date().getTime();
		window.sessionStorage.setItem('commNotificationUpdate', d);
		axios.get('/communication/sidebarNotifications').then(response => {
			window.sessionStorage.setItem(
				'commNotification',
				response.data.unreadCount
			);
		});
	};
	let d = new Date().getTime();
	if (d - commNotificationUpdate > 180000) getCommunicationNotifications();

	let commNotification = window.sessionStorage.getItem('commNotification');

	return (
		<>
			<Menu
				className='mainNavMenu'
				theme='light'
				mode='inline'
				defaultSelectedKeys={defaultkey}
				onClick={
					close !== undefined
						? close
						: () => {
								return;
						  }
				}
			>
				{/* <Menu.Item
					key='/overview'
					className='mt-0'
					icon={<InfoCircleOutlined />}
				>
					<Link to='/overview' className='nohighlight caption'>
						Overview
					</Link>
				</Menu.Item> */}
				<Menu.Item key='/communication' icon={<MessageOutlined />}>
					<Link to='/communication' className='nohighlight caption'>
						{commNotification > 0 ? (
							<>
								<Badge
									count={commNotification}
									offset={[20, 6]}
									style={{ cursor: 'pointer' }}
								>
									<div className='caption d-inline'>Inbox</div>
								</Badge>
							</>
						) : (
							<div className='caption d-inline'>Inbox</div>
						)}
					</Link>
				</Menu.Item>
				{user.type === 'Manager' || user.type === 'Vendor' ? (
					// <SubMenu
					// 	key='sub3'
					// 	icon={<ProfileOutlined />}
					// 	title={<div className='caption d-inline'>Requests</div>}
					// >
					<Menu.Item key='/maintenance' icon={<ToolOutlined />}>
						<Link to='/maintenance' className='nohighlight caption'>
							<div className='caption d-inline'>Job Request</div>
						</Link>
					</Menu.Item>
				) : // </SubMenu>
				null}

				{user.type === 'Manager' || user.type === 'Owner' ? (
					<Menu.Item
						key='/taskManagement'
						className='mt-0'
						icon={<GroupOutlined />}
					>
						<Link to='/taskManagement' className='nohighlight caption'>
							Task Management
						</Link>
					</Menu.Item>
				) : (
					''
				)}
				<Menu.Item key='/event_calendar' icon={<CalendarOutlined />}>
					<Link to='/event_calendar' className='nohighlight caption'>
						<div className='caption d-inline'>Event Calendar</div>
					</Link>
				</Menu.Item>

				{/*user.type === "Vendor" || user.type === "Manager" ? () : (
          ""
          )*/}

				{user.type === 'Manager' || user.type === 'Owner' ? (
					<Menu.Item
						key='/finance'
						className='ant-tooltip-open'
						icon={<DollarCircleOutlined />}
					>
						<Link to='/finance' className='nohighlight caption'>
							<div className='caption d-inline'>Finance</div>
						</Link>
					</Menu.Item>
				) : (
					''
				)}
				{user.type === 'Manager' ? (
					<Menu.Item
						key='/charge'
						className='ant-tooltip-open'
						icon={<MoneyCollectOutlined />}
					>
						<Link to='/charge' className='nohighlight caption'>
							<div className='caption d-inline'>Charge</div>
						</Link>
					</Menu.Item>
				) : (
					''
				)}
				{user.type === 'Manager' || user.type === 'Owner' ? (
					<SubMenu
						key='sub1'
						icon={<ReadOutlined />}
						title={<div className='caption d-inline'>Portfolio</div>}
					>
						{user.type !== 'Owner' ? (
							<Menu.Item key='/mycompany' icon={<ShopOutlined />}>
								<Link to='/mycompany' className='nohighlight caption'>
									My Company
								</Link>
							</Menu.Item>
						) : (
							''
						)}

						<Menu.Item key='/properties' icon={<HomeOutlined />}>
							<Link to='/properties' className='nohighlight caption'>
								Properties
							</Link>
						</Menu.Item>
						<Menu.Item key='/people' icon={<IdcardOutlined />}>
							<Link to='/people' className='nohighlight caption '>
								People
							</Link>
						</Menu.Item>
					</SubMenu>
				) : (
					''
				)}
				{/* {user.type === 'Vendor' || user.type === 'Manager' ? (
					<Menu.Item key='/workLog' icon={<ClockCircleOutlined />}>
						<Link to='/workLog' className='nohighlight caption'>
							Clock In/Out
						</Link>
					</Menu.Item>
				) : (
					''
				)} */}
				{user.type === 'Manager' || user.type === 'Vendor' ? (
					<Menu.Item
						key='/work_log_report'
						className='ant-tooltip-open'
						icon={<ClockCircleOutlined />}
					>
						<Link to='/work_log_report' className='nohighlight caption'>
							<div className='caption d-inline'>Time Management</div>
						</Link>
					</Menu.Item>
				) : (
					''
				)}
				{/*user.type !== "Owner" && user.type !== "Tenant" ? (
          <Menu.Item key="market" icon={<ShoppingCartOutlined />}>
            <Link to="/market" className="nohighlight caption">
              <div className="caption d-inline">Market</div>
            </Link>
          </Menu.Item>
        ) : (
          ""
        )*/}
			</Menu>
			{BtnDiv}
		</>
	);
};

const BtnDiv = ({
	collapseNavBar,
	openRequest,
	closeSidebar,
	mobile,
	className
}) => {
	const [feedbackOpen, setFeedback] = useState(false);
	const startRequest = () => {
		openRequest();
		closeSidebar();
	};
	const openFeedback = () => {
		if (!feedbackOpen) {
			closeSidebar();
		}
		setFeedback(!feedbackOpen);
	};
	return (
		<div
			className={
				'btnMenu text-center ' + (className !== undefined ? className : '')
			}
		>
			{/* {mobile ? (
				<Button className='sidebarBtn' type='primary' onClick={startRequest}>
					<ToolOutlined />
					<div className={!collapseNavBar ? ' d-inline ml-2' : 'd-none'}>
						New Request
					</div>
				</Button>
			) : (
				''
			)} */}

			<Button
				id={!collapseNavBar ? 'sendFeedback' : 'feedbackCollapse'}
				onClick={openFeedback}
				className='mt-2 d-inline sidebarBtn btnOutline'
			>
				<HeartOutlined />
				<div className={!collapseNavBar ? ' d-inline ml-2' : 'd-none'}>
					Give Feedback
				</div>
			</Button>
			{feedbackOpen ? (
				<Feedback
					mobile={mobile}
					open={feedbackOpen}
					openFeedback={() => setFeedback(!feedbackOpen)}
				/>
			) : (
				''
			)}
			<div style={{ fontSize: '9px', marginTop: '10px' }}>
				© 2021 Steady Property Management Inc.
			</div>
		</div>
	);
};
/*
tenants ---

overview
agreements
maintenance
service
communication


owners ---

overview
portfolio
agreements
maintenance
service
communication
finance

vendors ---

overview
Work Management
maintenance
communication

*/
export default withRouter(Sidebar);
