import React, { Component } from "react";

import { Checkbox } from "antd";

class EntryRequired extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    Entry Required?
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Checkbox.Group options={checkBoxOptions} value={[this.props.entryRequired]} onChange={this.props.updateEntryRequired} />
                </div>

            </>
        )
    }
}


const checkBoxOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  

export default EntryRequired;