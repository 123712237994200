import React from "react";
import EmployeeIntakeForm from "../../Profile/CompanyProfile/EmployeeIntake";

import "./taskStyle.css";

function handleChange(value) {
}
class AddEmployeeTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addEmployeeModal: false,
    };
  }

  openAddEmployee = () => {
    this.setState({ addEmployeeModal: !this.state.addEmployeeModal });
  };
  render() {
    return (
      <>
        <EmployeeIntakeForm onSuccess={this.onSuccess} />
      </>
    );
  }
}

class AddEmployeeModal extends React.Component {
  
  constructor(props) {
    super(props);
  }
  render() {
    return <EmployeeIntakeForm addEmployees={this.props.addEmployees} />;
  }
}

export default AddEmployeeTask;
