import React, { useState } from 'react';
import Moment from 'react-moment';
import { Card, Button, message } from 'antd';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { PlusCircleOutlined } from '@ant-design/icons';
import LoadScreen from '../../Components/LoadScreen';
import Log from '../../Components/Log/Log';
import RequestContext from './RequestContext';
const cancelToken = axios.CancelToken.source();

class ActivityLog extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super();
		this.state = { activityModal: false };
	}

	componentDidMount = () => {
		axios
			.post('/jobRequest/get/activityLogs', {
				job_id: this.context.request.job_id,
				logIndex: 0
			})
			.then(response => {
				this.context.addActivity(response.data.logs, response.data.logCount);
			});
	};

	//Open add activity log modal
	openActivity = () => {
		this.setState({ activityModal: !this.state.activityModal });
	};

	//Upload activity log action
	uploadActivity = note => {
		axios
			.post('/jobRequest/add/note', {
				job_id: this.context.request.job_id,
				note: note
			})
			.then(response => {
				this.context.addActivity(response.data.newNote, null, true);
				this.openActivity();
				message.success('Successfully posted new activity', 2.5);
			})
			.catch(error => {
				this.context.setError(error.name, error.description);
			});
	};

	//Load more activity logs from the db
	loadActivityLogs = () => {
		axios
			.post('/jobRequest/get/activityLogs', {
				job_id: this.context.request.job_id,
				logIndex: this.context.logs.length
			})
			.then(response => {
				this.context.addActivity(response.data.logs, response.data.logCount);
			});
	};
	render() {
		const { request } = this.context;
		// console.log(request);
		return (
			<Card
				title={
					<>
						<div className='fs-18 g-text d-inline mr-2'>Activity Log</div>
						{this.context.action_permission ? (
							<PlusCircleOutlined
								className='greyActionIcon'
								onClick={this.openActivity}
							/>
						) : (
							''
						)}
					</>
				}
				bordered={false}
				style={{ minHeight: '120px' }}
			>
				{request.logs ? (
					request.logs.length > 0 ? (
						<ul>
							{request.logs.map((log, index) => (
								<Log
									time={log.notes_time}
									key={index}
									text={log.notes}
									user={
										log.user_fname +
										' ' +
										(log.user_lname === null ? '' : log.user_lname)
									}
								/>
							))}
						</ul>
					) : (
						<div className='g-text-sm'>
							No Activity recorded in this request.
						</div>
					)
				) : (
					<LoadScreen className='m-auto' small />
				)}
				<div className='text-center'>
					{request.logCount > 0 ? (
						<div
							className='fs-12 status-blue pointer'
							onClick={this.loadActivityLogs}
						>
							Load more activity notes ({request.logCount})
						</div>
					) : (
						''
					)}
				</div>
				<Modal
					show={this.state.activityModal}
					onHide={this.openActivity}
					size='lg'
					aria-labelledby='assign-vendor-modal'
					centered
				>
					{!this.state.activityModal ? (
						''
					) : (
						<AddActivityModal
							show={this.state.activityModal}
							hide={this.openActivity}
							uploadActivity={this.uploadActivity}
						/>
					)}
				</Modal>
			</Card>
		);
	}
}

const AddActivityModal = ({ uploadActivity, hide }) => {
	let [activity, setActivity] = useState();
	return (
		<>
			<Modal.Header closeButton>
				<div className='fs-18'>New Activity</div>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div className='logDate fs-12'>
						<Moment format='MMM D, YYYY' className='g-text-sm'></Moment>
					</div>
					<div className='logDescription'>
						<textarea
							className='form-control'
							defaultValue={activity}
							onChange={e => setActivity(e.target.value)}
							style={{ width: '100%' }}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={hide}>Cancel</Button>
				<Button onClick={() => uploadActivity(activity)} type='primary'>
					Add Activity
				</Button>
			</Modal.Footer>
		</>
	);
};

export default ActivityLog;
