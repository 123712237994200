import React, { useState } from "react";
import { Table, Space, DatePicker, Button, Upload, message, Select } from "antd";
import "./finance.css";
import { Resizable } from "react-resizable";
import { SearchbarManual } from "../../Components/Searchbar/Searchbar";
import moment from "moment";
import Moment from "react-moment";
import axios from "axios";

import LoadScreen from "../../Components/LoadScreen";
import { CSVLink } from "react-csv";
import { Form, Modal } from "react-bootstrap";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import financeExportHeaders from "../../StringData/financeExportHeaders";
import AirbnbUpload from "../../Components/AirbnbUpload";
const cancelToken = axios.CancelToken.source();
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";


class FinanceLayout extends React.Component {
  componentWillMount() {
    document.title = "Finance";
  }
  render() {
    return <AirbnbFinance intakeType="Transaction" />;
  }
}

class AirbnbFinance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rangePickerStart: moment().add(-30, "days"),
      rangePickerEnd: moment(),

    };
  }

  setRangePicker = (start, end) =>
    this.setState({ rangePickerStart: start, rangePickerEnd: end });


  render() {
    return (
      <div style={{ padding: "24px" }}>
        <AirbnbPaymentTable
          rangePickerStart={this.state.rangePickerStart}
          rangePickerEnd={this.state.rangePickerEnd}
          setRangePicker={this.setRangePicker}
        />
      </div>
    );
  }
}

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
class AirbnbPaymentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      columns: [
        {
          title: "Date",
          dataIndex: "trans_date",
          width: 90,
          render: (date) => <Moment format="MM/DD/YYYY">{date}</Moment>,
          sorter: (a, b) =>
            moment(a.trans_date).unix() - moment(b.trans_date).unix(),
          onFilter: (value, record) =>
            moment(record.date).isBetween(value.start, value.end, "days", "[]"),

          filteredValue: [
            {
              start: this.props.rangePickerStart,
              end: this.props.rangePickerEnd,
            },
          ],
          filtered: true,
        },
        {
          title: "Type",
          dataIndex: "trans_type",
          width: 100,
          filters: [],
          filterMultiple: true,
          defaultFilteredValue: true,
          filteredValue:[],
          onFilter: (value, record) => value === record.trans_type,
        },
        {
          title: "Confirmation Code",
          dataIndex: "confirmation_code",
          width: 130,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          render: (date) =>
            date !== null ? <Moment format="MM/DD/YYYY">{date}</Moment> : null,
          width: 90,
        },
        {
          title: "Nights",
          dataIndex: "num_nights",
          width: 60,
        },
        {
          title: "Guest",
          dataIndex: "guest_name",
          width: 150,
        },
        {
          title: "Listing",
          dataIndex: "listing_name",
          width: 200,
          sorter: {
            compare: (a, b) =>
              String(a.listing_name ? a.listing_name : "zzzz").localeCompare(
                String(b.listing_name ? b.listing_name : "zzzz")
              ),
          },
          filters: [],
          filterMultiple: true,
          defaultFilteredValue: true,
          filteredValue: this.props.filteredListings || [],
          onFilter: (value, record) => value === record.listing_name,
          render: (value,record) => {
            return(
            record.trans_type === 'Resolution Adjustment' ?
                <div style={{cursor:'pointer',color:'#4d7cfe'}} onClick={() => this.selectResolutionListing(record)}>{value || "Select Listing"}</div>
                :
                <div>{value}</div>
              
              )
          }
        },

        {
          title: "Currency",
          dataIndex: "currency",
          width: 80,
        },
        {
          title: "Amount",
          dataIndex: "trans_amount",
          width: 80,
        },
        {
          title: "Paid Out",
          dataIndex: "paid_out",
          width: 80,
        },
        {
          title: "Host Fee",
          dataIndex: "host_fee",
          width: 80,
        },
        {
          title: "Cleaning Fee",
          dataIndex: "cleaning_fee",
          width: 80,
        },
        {
          title: "Resort Fee",
          dataIndex: "resort_fee",
          width: 80,
        },
        {
          title: "Details",
          dataIndex: "trans_details",
          width: 200,
        },
        {
          title: "Reference",
          dataIndex: "reference",
          width: 100,
        },
      ],
      rawData: null,
      filteredData: [],
      exportModal: false,
      importModal: false,
      totalAmount: 0,
      totalPaidOut: 0,
      totalHost: 0,
      totalCleaning: 0,
      totalResort: 0,
      passThroughAmount: 0,
      resolutionAdjustment:0,
      loadingData: true,
      currentTableData: [],
      resolutionListing:null,
      openResolutionListing:false
    };
  }
  componentDidMount = () => {
    this.getData(null, null, () => {
      return;
    });
  };

  getData = (start = null, end = null, callback) => {
    this.setState({ loadingData: true });
    let body = {
      startDate:
        start === null
          ? moment(this.props.rangePickerStart).format("YYYY-MM-DD")
          : moment(start).format("YYYY-MM-DD"),
      endDate:
        end === null
          ? moment(this.props.rangePickerEnd).format("YYYY-MM-DD")
          : moment(end).format("YYYY-MM-DD"),
    };
    axios
      .post("/getAirbnbData", body)
      .then((response) => {
        console.log(response.data.airbnbData)
        this.setState({ rawData: response.data.airbnbData });
        this.getListingFilters();
        this.getTypeFilters();
        this.filterData();
        callback();
      })
      .catch((err) => console.log(err));
  };
  getListingFilters = () => {
    let listings = [];
    this.state.rawData.forEach((data) => {
      if (!listings.includes(data.listing_name) && data.listing_name !== null)
        listings.push(data.listing_name);
    });
    listings.sort();
    let listingFilters = [];
    listings.forEach((listing) =>
      listingFilters.push({ text: listing, value: listing })
    );
    let cols = this.state.columns;
    cols[6].filters = listingFilters;
    this.setState({ columns: cols });
  };
  getTypeFilters = () => {
    let types = [];
    this.state.rawData.forEach((data) => {
      if (!types.includes(data.trans_type) && data.trans_type !== null)
      types.push(data.trans_type);
    });
    types.sort();
    let typeFilters = [];
    types.forEach((type) =>
      typeFilters.push({ text: type, value: type })
    );
    let cols = this.state.columns;
    cols[1].filters = typeFilters;
    this.setState({ columns: cols });
  };
  /* Called when the data range picker is changed */
  setDateRange = (date) => {
    let start = date !== null ? date[0] : null;
    let end = date !== null ? date[1] : null;
    this.props.setRangePicker(start, end);
    this.getData(start, end, () => {
      this.filterData();
    });
  };

  setSearchValue = (searchValue) => {
    this.setState({ searchValue: searchValue }, this.filterData);
  };
  /* Used to initalize resizable column headers */
  financeComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  /*Used to handle columns being resized */
  handleResize = (index) => (e, { size }) => {
    e.stopImmediatePropagation();
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };
  openExportModal = () => {
    this.setState({ exportModal: !this.state.exportModal });
  };
  openImportModal = () => {
    this.setState({ importModal: !this.state.importModal });
  };

  onChange = (pagination, filters, sorter, extra) => {
    let cols = this.state.columns;
    cols[1].filteredValue = filters.trans_type;
    cols[6].filteredValue = filters.listing_name;
    this.setState({
      columns: cols,
    });
  };
  updateTotals = () => {
    let totalAmount = 0,
      totalPaidOut = 0,
      totalHost = 0,
      totalCleaning = 0,
      totalResort = 0,
      resolutionAdjustment = 0,
      passThroughAmount = 0;

    this.state.currentTableData.forEach((row) => {
      if (row.trans_amount !== null)
        totalAmount += parseFloat(row.trans_amount);
      if (row.paid_out !== null) totalPaidOut += parseFloat(row.paid_out);
      if (row.host_fee !== null) totalHost += parseFloat(row.host_fee);
      if (row.cleaning_fee !== null)
        totalCleaning += parseFloat(row.cleaning_fee);
      if (row.resort_fee !== null) totalResort += parseFloat(row.resort_fee);
      if (row.trans_type === "Pass Through Tot") passThroughAmount += parseFloat(row.trans_amount);
      if (row.trans_type === "Resolution Adjustment") resolutionAdjustment += parseFloat(row.trans_amount); 
    });
    this.setState({
      totalAmount: totalAmount.toFixed(2),
      totalPaidOut: totalPaidOut.toFixed(2),
      totalHost: totalHost.toFixed(2),
      totalCleaning: totalCleaning.toFixed(2),
      totalResort: totalResort.toFixed(2),
      passThroughAmount: passThroughAmount.toFixed(2),
      resolutionAdjustment: resolutionAdjustment.toFixed(2)
    });
  };
  /* Used to filter data when adding a search value or filtering listing/type. */
  filterData = () => {
    if (this.state.rawData.length === 0) {
      this.setState({ loadingData: false });
      return;
    }
    var filteredData = this.state.rawData;
    if (this.state.searchValue !== "" && this.state.searchValue !== null)
      filteredData = filteredData.filter((row) =>
        `${row.trans_data} ${row.trans_type} ${row.confirmation_code} ${row.guest_name} ${row.start_date} ${row.listing_name} ${row.trans_details} ${row.trans_amount} ${row.cleaning_fee} ${row.paid_out}`
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase())
      );
    this.setState(
      {
        filteredData: filteredData,
        loadingData: false,
      },
      () => this.updateTotals()
    );

    return;
  };
  importSuccess = () => {
    message.success("Data has been successfully imported.");
    this.openImportModal();
    this.getData(null, null, () => {
      this.filterData();
    });
  };
  importError = () => {
    message.error("Upload error, please try again.");
  }
  selectResolutionListing = (record) => {
    this.setState({resolutionListing:record,openResolutionListing:true});
  }
  closeResolutionListing = () => this.setState({openResolutionListing:false});
  changeResolutionListing = (listing) => {
     axios.post("finance/update/resolutionListing",{listing:listing,id:this.state.resolutionListing.id})
     .then(() => {
       let data = this.state.filteredData;
       let index = data.findIndex(d => d.id === this.state.resolutionListing.id);
       data[index].listing_name = listing; 
       this.setState({filterData:[...data], resolutionListing:null,openResolutionListing:false});
    })
  }
  render() {
    /* Used to handle resize width of columns */
    const renderedColumns = this.state.columns
      .filter((col) => col.visible || col.visible === undefined)
      .map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      }));
    if (this.state.rawData === null)
      return (
        <div className='airbnbFinanceContainer'>
          <LoadScreen className='m-auto' />
        </div>
      );
    return (
      <div id="airbnbFinanceContainer">
        <div className="mb-4 financeHeader">
          <RangePicker
            defaultValue={[
              moment(this.props.rangePickerStart, dateFormat),
              moment(this.props.rangePickerEnd, dateFormat),
            ]}
            onChange={this.setDateRange}
            className='airbnbFinanceRangePicker'
          />

          <SearchbarManual
            onClick={this.setSearchValue}
            searchValue={this.state.searchValue}
            className='airbnbFinanceSearch'
          />
          <div className="airbnbFinanceExpImp">
            <Button onClick={this.openExportModal}>
              <DownloadOutlined />
              Export
            </Button>

            {/*
            
              HIDE IMPORT BUTTON FOR NON-MANAGERS
          
            */}

            <Button onClick={this.openImportModal} className="ml-2">
              <UploadOutlined />
              Import
            </Button>
          </div>
        </div>
        {this.state.loadingData ? (
          <LoadScreen className="m-auto" />
        ) : (
          <>
            <Table
              title={(currentPageData) => {
                if (
                  compareArrays(currentPageData, this.state.currentTableData)
                ) {
                  return null;
                }
                this.setState({ currentTableData: currentPageData }, () =>
                  this.updateTotals()
                );
                return null;
              }}
              id="airbnbFinanceTable"
              size="small"
              scroll={{ y: "65vh" }}
              onChange={this.onChange}
              components={this.financeComponents}
              columns={renderedColumns}
              pagination={false}
              dataSource={this.state.filteredData}
              rowKey={"id"}
            ></Table>
            <Form className="mt-4 financeTotalCosts">
              <Form.Group>
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.totalAmount}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Total Paid Out</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.totalPaidOut}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Total Host Fees</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.totalHost}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Total Cleaning Fees</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.totalCleaning}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Total Resort Fees</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.totalResort}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Pass through Amount</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.passThroughAmount}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Resolution Adjustments</Form.Label>
                <Form.Control
                  type="text"
                  value={"$ " + this.state.resolutionAdjustment}
                  disabled
                />
              </Form.Group>
            </Form>
          </>
        )}
        <Modal
          show={this.state.exportModal}
          onHide={this.openExportModal}
          size="sm"
          aria-labelledby="assign-vendor-modal"
          centered
        >
          {this.state.exportModal ? (
            <ExportModal
              filteredData={this.state.currentTableData}
              onHide={this.openExportModal}
            />
          ) : (
            ""
          )}
        </Modal>
        <Modal
          show={this.state.importModal}
          onHide={this.openImportModal}
          size="sm"
          aria-labelledby="assign-vendor-modal"
          centered
        >
          {this.state.importModal ? (
            <AirbnbUpload
              success={this.importSuccess}
              importError={this.importError}
              spreadsheetType="transactions"
              title="Import Airbnb Transactions"
              onHide={this.openImportModal}
            />
          ) : (
            ""
          )}
        </Modal>
        <Modal
          show={this.state.openResolutionListing}
          onHide={this.closeResolutionListing}
          size="sm"
          aria-labelledby="assign-vendor-modal"
          centered
        >
          {this.state.openResolutionListing ? (
            <ListingAdjustment
              currentListing={this.state.resolutionListing?.listing_name || null}
              changeResolutionListing={this.changeResolutionListing}
              onHide={this.closeResolutionListing}
              filteredListings={this.state.columns[6].filters}
            />
          ) : (
            ""
          )}
        </Modal>
      </div>
    );
  }
}

function ListingAdjustment(props){
  const [newListing,setNewListing] = useState("");
  const [submitting,setSubmitting] = useState(false);
  const selectListing = (listing) => {
    setNewListing(listing);
  }

  const submitListing = () => {
    setSubmitting(true);
    props.changeResolutionListing(newListing);
  }

  return(
    <>
     <Modal.Header closeButton>Change Listing</Modal.Header>
        <Modal.Body>
          <div>Current Listing: {props.currentListing || "None"}</div>
          <br/>
          <div>Select new Listing:</div>
          <Select
              style={{width:200}}
              defaultValue={newListing}
              placeholder="Select a Listing"
              onChange={(listing) => selectListing(listing)}
          >
            {props.filteredListings.map((listing) => (
              <Select.Option key={listing.value} value={listing.value}>{listing.text}</Select.Option>
            ))}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <Button type='primary' disabled={newListing === "" || submitting} onClick={submitListing}>Confirm New Listing</Button>
        </Modal.Footer>
    </>
  )

}
/* Modal for airbnb file export */
class ExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      isValid: true,
      defaultName: `airbnb_finance_${moment().format("MM-DD-YYYY")}`,
    };
  }

  setFileName = (name) => {
    if (name === "") return this.setState({ fileName: name, isValid: true });
    else if (this.isValid(name))
      return this.setState({ fileName: name, isValid: true });
    else return this.setState({ fileName: name, isValid: false });
  };

  isValid = (fname) => {
    var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    var rg2 = /^\./; // cannot start with dot (.)
    var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>Export Data</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className="fs-12">File Name</Form.Label>
            <Form.Control
              type="text"
              value={this.state.fileName}
              placeholder={this.state.defaultName}
              style={{ width: "80%", display: "inline-block" }}
              onChange={(e) => this.setFileName(e.target.value)}
            />
            <div className="d-inline g-text-sm" style={{ marginLeft: "3px" }}>
              .csv
            </div>
          </Form>
          <br />
          <div className="text-center">
            <Button type="primary" size="large" disabled={!this.state.isValid}>
              <CSVLink
                onClick={(e) => {
                  return this.state.isValid;
                }}
                data={this.props.filteredData}
                headers={financeExportHeaders}
                className={this.state.isValid ? "" : "cursor-not-allowed"}
                filename={`${
                  this.state.fileName !== ""
                    ? this.state.fileName
                    : this.state.defaultName
                }.csv`}
                target="_blank"
              >
                <div className="fs-12 w-text-imp">
                  <DownloadOutlined /> Download Data
                </div>
              </CSVLink>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </>
    );
  }
}
const compareArrays = (arr1, arr2) => {
  if (arr1.length != arr2.length) return false;

  for (var i = 0, l = arr1.length; i < l; i++) {
    // Check if we have nested arrays
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!arr1[i].equals(arr2[i])) return false;
    } else if (arr1[i] != arr2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
export default FinanceLayout;
