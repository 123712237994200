import React from 'react';
import Moment from 'react-moment';
import './log.css';
const DisplayLog = ({ time, text, user }) => {
	return (
		<li className='logContainer'>
			<div className='logDate fs-12'>
				<Moment format='MMM D, YYYY HH:mm' className='g-text'>
					{time}
				</Moment>
			</div>
			<div className='logDescription'>{text}</div>
			<div className='logName fs-12 g-text'>{user}</div>
		</li>
	);
};

export default DisplayLog;
