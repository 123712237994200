import React, { Component } from "react";
import { Button, Modal, message } from "antd";

import LoadScreen from '../../Components/LoadScreen';
import ReviewExtensionRequest from './Pages/ReviewExtensionRequest.js';
import RequestContext from "../../Pages/MaintenanceRequest/RequestContext";
import CreateEventModal from '../../Pages/MaintenanceRequest/actions/modals/CreateEvent';
import ExtensionAccepted from './Pages/ExtensionAccepted.js';
import ExtensionDeclined from './Pages/ExtensionDeclined.js';



export default class ExtensionRequest extends Component {
  static contextType = RequestContext;
    constructor(props) {
        super(props);
        this.state = {
          doPrevent: true,
          page: "Loading",


          extensionContext: {
            currentWorkOrder: this.props.currentWorkOrder,
            time: null,
            extensionTextArea: "",            

            submitExtensionAccepted: this.props.submitExtensionAccepted, //this.props.submitExtensionAccepted is a prop function.

            setTime: (newTime) => {
              this.setState({extensionContext: {...this.state.extensionContext, time: newTime } });
            },

            updateExtensionTextArea: (value) => {
              this.setState({extensionContext: {...this.state.extensionContext, extensionTextArea: value } })
            }       
          }
        }
    }
   
    changePage = (page) => this.setState({ page: page })

    componentDidMount() {
      this.setState({ page: this.props.startingPage });
    }

    render() {
        let newPageObject = null;
        switch (this.state.page) {
          case "Loading":
            newPageObject = {
              page: LoadScreen,
              title: "",
              footer: ""
            };
            break;
          
          case "ReviewExtensionRequest":
            newPageObject = {
              page: ReviewExtensionRequest,
              title: "Review Extension Request",
              footer:
                <>
                  <Button key="yes" type="primary" onClick={() => { this.changePage("ExtensionAccepted") }} >
                      Approve and Create New Event
                  </Button>
    
                  <Button key="no" onClick={() => { this.changePage("ExtensionDeclined") }} >
                      Reject
                  </Button>
    
                </>
            };
            break;
            
          case "ExtensionAccepted":
            newPageObject = {
              page: ExtensionAccepted,
              title: null,
              footer: null
            };
            break;

            case "ExtensionDeclined":
              newPageObject = {
                page: ExtensionDeclined,
                title: "State Reasons for Declining",
                footer:
                <>
                  <Button key="submit" type="primary" disabled={!(this.state.extensionContext.extensionTextArea.length > 0)} onClick={() => {this.props.onCancel(); this.props.submitExtensionDeclined(this.state.extensionContext.extensionTextArea); } }>
                    Submit
                  </Button>

                  <Button key="back" onClick={() => { this.changePage("ReviewExtensionRequest") }} >
                    Back
                  </Button>
                </>
              }
    
        }
        let RenderPage = newPageObject.page;

        return (
          <Modal
            title={newPageObject.title}
            visible={this.props.modalOpen}
            onCancel={this.props.onCancel}
            footer={
              newPageObject.footer
            }
          >
            <div id='extension-modal-body'>
              <RenderPage changePage={this.changePage} onCancel={this.props.onCancel} extensionContext={this.state.extensionContext} />
            </div>
          </Modal>
        )
    }
}