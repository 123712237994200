import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import routes from "../../Routes/MainRoutes";
import NotFound from "../../Components/NotFound.js";
import "./mainStyle.css";
import RequestProvider from '../../Context/requestContext';

class Main extends React.PureComponent {
  //<Route exact path='/' component={Overview}/>

  render() {
    return (
      <main id='main-body' className=''>
        <div className='h100 mbody' onChange={this.props.updatePath}>
          <RequestProvider>
              <Switch>
                {routes.map(({ path, Component, exact }, key) => (
                  <Route
                    exact={exact}
                    path={path}
                    key={key}
                    render={()=>
                    <Component
                    userType={this.props.userType}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                    staticContext={this.props.staticContext}/>}
                  />
                ))}
                <Route component={NotFound} />
              </Switch>
          </RequestProvider>
        </div>
      </main>
    );
  }
}

export default withRouter(Main);
