import React from 'react';

class PropertyManagement extends React.Component {
	render() {
		return (
			<div>
				<div className='jumbotron' id='jumbotronPM'>
					<div id='jumboDisplay-pm'>
						<div className='w-text' id='pmtitle'>
							Taking the hassle out of property ownership
						</div>
					</div>
					<div id='jumboPara-pm'>
						<div id='about-pm'>
							<div className='w-text fs-18'>
								With decades of experience in property management, financing,
								maintenance and home inspections, our team of professionals will
								ensure you get the most from your real estate investment.{' '}
							</div>
						</div>
						<div id='empty'></div>
						<div id='about-btns'>
							<br />
							<br />
							<a className='btn pri pmbtn' href='#whatisapropertymanager'>
								What is a Property Manager?
							</a>
							<a className='btn pri pmbtn' href='#ourservices'>
								Our Services
							</a>
							<a className='btn pri pmbtn' href='#pricing'>
								Services
							</a>
							<br />
							<br />
						</div>
					</div>
				</div>
				<div id='info-pm'>
					<h4 className=' font-30 spmcolor' id='whatisapropertymanager'>
						What is a property manager?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg'>
							The quick answer is that we legally represent you. A property
							manager oversees the daily operations of a commercial or
							residential property that generates income for an owner. Primary
							activities include showing properties to renters, preparing
							leases, collecting rent payments, managing facilities, preparing
							financial statements and ensuring reasonable conditions for
							tenants
						</p>
					</div>
					<br />
					<h4 className='font-30 my-2 spmcolor'>
						Why hire a property manager?
					</h4>
					<br />
					<h4 className='b-text font-20'>
						Are you visiting your property on a regular basis?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							You are busy. Between your kids, your day job, your business, the
							evening activities (and the list goes on...), you just don’t have
							time to be visiting your income property. Besides, things there
							are fine, right? Regular property inspections are key in
							protecting your investment. You did your best in choosing a tenant
							but it’s important to ensure that they are following the rules of
							the property. Further, regular visits will allow for the early
							detection and remediation of potential costly damages caused by
							faulty systems. Our property management team has designed a
							regular inspection and reporting system which protects your
							investment and keeps you updated on how things are going.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>Dealing with conflict.</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							Late night parties, late payment of rent, tenant disputes,
							unauthorized animals, illegal activity on the premises... these
							are all situations that a landlord will eventually run into; these
							are all situations that we have dealt with. As a matter of fact,
							it has become a bit of our specialty. Don’t let these nasty
							incidents ruin your experience as a landlord. We do all the dirty
							work so you can reap all the benefits.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>Regular maintenance schedule.</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							Are things getting a little out of hand? “That faucet is still
							leaking and the furnace is still making that funny sound. I should
							really check that out”. “Those gutters really need to be cleared.
							I’ll do it next fall.” Overwhelmed by all the little tasks? Not
							us. Our regular maintenance schedule creates huge efficiencies and
							increases the value and return of your property. We find and
							repair problems before they become problems.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						Your portfolio of rental properties is growing.
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							You have realized the value of passive income. You have
							accumulated several rental properties and things are going well.
							But now, that once passive income is starting to become more and
							more of an active challenge. More units mean more viewings, more
							tenants, more rent collection, more maintenance. Don’t become
							overwhelmed. Focus on what has allowed you to get all these units
							- your main business. Allow us to handle your portfolio, maximize
							your passive income and increase your return on investment.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						Can you stay on top of all the maintenance and repairs?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							Broken toilets, old plumbing, bad electrical connections, damaged
							flooring – you can handle it right? As a landlord you will quickly
							come to realize that these things happen, more often than not,
							when you are out camping with your family, away on a business trip
							or at home on a quiet Friday evening. We are on call 24/7/365 and
							have a team of professionals ready to tackle all of these chores!
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						How quickly are you able to get your unit rented?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							Vacancies kill your bottom line. All that hard work you did last
							year to make your property profitable can be wiped away with a few
							too many vacancies. And all that time and effort that you put in
							to posting the unit for rent, speaking with potential tenants,
							travelling to show the unit…your time could be better spent. We
							have developed systems that allow us to show your unit to more
							people in a shorter amount of time. We use digital advertisement
							channels to reach a wider audience to rent your unit in the most
							efficient way.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						Are you handling all the finance, accounting and record keeping for
						your property?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							Profit and loss statements, tax deductions, rent roll, forecasted
							expenditures are all important areas to pay attention to when you
							own a rental property. Maybe you can handle it or maybe you
							outsource to an accountant. When you trust us with your property
							we take care of all this for you. We provide quarterly and
							annualreports detailing all of your information. Additionally, you
							will be able to log on to our owner portal where you can see all
							your information 24/7/365 in real time.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						Are you willing to be on call 24/7/365?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							It’s important to be honest with yourself about this one. When an
							emergency happens at your property you are obligated to respond.
							Your child’s birthday, Christmas Eve, your anniversary, important
							meeting, vacation, or personal situations don’t give you an out.
							Overflowing toilets and broken furnaces in January don’t happen
							all the time but when they do, are you able to always be there
							immediately? We are always ready to answer that call. Our team of
							professionals is there 24/7/365 so that your tenants never miss a
							beat.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>Evictions.</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							This is a tough one. It however is an unfortunate reality in the
							life of a landlord. Despite your best efforts in selecting a
							tenant, sometimes they let you down and you are forced to take
							action. Further, are you familiar with all the laws governing
							tenancies and evictions in Newfoundland and Labrador? Are you
							aware of the notices required in order to evict someone? We are
							and have become experts at dealing with these tricky situations.
							Let us handle the problems when they arise.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						How well do you understand the laws governing rental properties?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							As a landlord it is critical that you operate within the laws and
							regulations of the Residential Tenancies Act. Not doing so could
							expose you to lawsuits and problems that you really can’t afford.
							We are trained in law and know how to operate within the
							guidelines. Let our expertise shield you from liability.
						</p>
					</div>
					<br />
					<h4 className='b-text font-20'>
						Will managing your own property give you the highest return for your
						time investment?
					</h4>
					<div className='txtbox'>
						<p className='g-text-lg '>
							In some cases the owner of the property might be the best person
							to run the property. Often times though, it makes sense to hire a
							property manager. Successful entrepreneurs and professionals like
							yourself appreciate the value of delegation. A property manager
							offers you that delegation to free you up for higher value tasks.
							Our low fees, which are tax deductible will ensure you get the
							best return on your investment.
						</p>
					</div>
					<a id='toplink' href='#top'>
						<img
							id='topimg'
							src={require('../images/LandingPage/backtotop.png')}
							width='50'
							height='50'
						/>
					</a>
					<br />
					<br />
					<br />
					<br />
					<h4 className=' font-30 spmcolor' id='ourservices'>
						Our Services
					</h4>
					<img
						id='services'
						src={require('../images/LandingPage/services.png')}
					/>
					<br />
					<br />
					<p className=' font-20 b-text'>
						Maximize your profit, minimize your risk and protect your
						investment.
					</p>
					<p className=' font-20 b-text'>
						Contact us for a free Rental Evaluation today!
					</p>
					<br />
					<a id='toplink' href='#top'>
						<img
							id='topimg'
							src={require('../images/LandingPage/backtotop.png')}
							width='50'
							height='50'
						/>
					</a>
					<h4 className=' font-30 spmcolor mt-5 mb-3' id='pricing'>
						Services
					</h4>
					<div id='prices'>
						<div className='singlePrice d-flex flex-row justify-content-around'>
							<div className='priceDisplay d-flex flex-column border border-dark'>
								<h4 className=' font-20'>
									<b>Single Family Residence</b>
								</h4>
								{/* <p className=" font-20 b-text">11% gross rent</p>
                <p className=" font-20 b-text">$599 leasing fee</p> */}
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Marketing</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Property Viewing</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Comprehensive tenant screening
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Lease Administration</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Move-in/Move-out inspections
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Rent collection</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Routine inspections</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Comprehensive accounting</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Annual Tax Statement</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Maintenance coordination</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Eviction Oversight/Legal Advice
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Lawn care/Snow clearing coordination
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Capital improvements consultation
									</p>
								</div>
							</div>
							<div className='multiPrice d-flex flex-column border border-dark'>
								<h4 className=' font-20'>
									<b>Multi-Unit Residence</b>
								</h4>
								{/* <p className=" font-20 b-text">9% gross rent</p>
                <p className=" font-20 b-text">$499 leasing fee</p> */}
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Marketing</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Property Viewing</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Comprehensive tenant screening
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Lease Administration</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Move-in/Move-out inspections
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Rent collection</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Routine inspections</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Comprehensive accounting</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Annual Tax Statement</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Maintenance coordination</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Eviction Oversight/Legal Advice
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Lawn care/Snow clearing coordination
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Capital improvements consultation
									</p>
								</div>
							</div>
							<div className='shortPrice d-flex flex-column border border-dark'>
								<h4 className=' font-20'>
									{' '}
									<b>Short Term Rentals</b>
								</h4>
								{/* <p className=" font-20 b-text">19%</p> */}
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Marketing </p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Prepare and stage your home
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Communicate with all guests
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Annual Tax Statement</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Maintenance coordination</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>Cleaning coordination</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Lawn care/Snow clearing coordination
									</p>
								</div>
								<div className='cell border-top border-dark'>
									<p className='b-text-lg spmcolor'>
										Capital improvements consultation
									</p>
								</div>
							</div>
						</div>
					</div>
					<a id='toplink' href='#top'>
						<img
							id='topimg'
							src={require('../images/LandingPage/backtotop.png')}
							width='50'
							height='50'
						/>
					</a>
				</div>
			</div>
		);
	}
}

export default PropertyManagement;
