import React from 'react';
import axios from 'axios';

import { Card, Avatar } from 'antd';
const cancelToken = axios.CancelToken.source();

class UnitSelection extends React.Component {
	constructor(props) {
		super(props);
		let url = props.link;
		let names = url.split('/');
		this.state = {
			units: [],
			pId: parseInt(names[2])
		};
	}
	componentWillMount() {
		document.title = 'My Units';
		this.getData().then(data => {
			Object.keys(data.data.properties).map(property => {
				if (data.data.properties[property].propertyid === this.state.pId) {
					this.setState({ units: data.data.properties[property].units });
				}
			});
		});
	}

	getData() {
		return axios
			.get('/getProperties', { crossdomain: true })
			.then(function (response) {
				return response;
			});
	}

	render() {
		return this.state.units.map((unit, index) => (
			<a
				key={index}
				href={'/properties/' + this.state.pId + '/' + unit.unit_id}
			>
				<Card
					style={{ margin: 'auto', padding: '8px' }}
					key={index}
					className='unitCard mb-2'
				>
					<div className='propCardTop mb-2'>
						<Avatar src={require('../../images/houseExample4.jpg')} size={40} />
						<div className='propAddress'>{'Unit Name: ' + unit.unit_name}</div>
					</div>
				</Card>
			</a>
		));
	}
}

export default UnitSelection;
