import React, { useEffect, useState } from 'react';
import { FilePdfTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Table, Button, Modal, Popconfirm } from 'antd';

import axios from 'axios';

import InvoicePDFViewer from './InvoicePDFViewer';
import EditCharge from './EditCharge';

const InvoiceDetails = props => {
	const [showInvoice, setShowInvoice] = useState(false);
	const [invoiceData, setInvoiceData] = useState([]);
	const [invoices, setInvoices] = useState(props.invoiceDetails);
	const [editModal, setEditModal] = useState(false);

	useEffect(() => setInvoices(props.invoiceDetails), [props.invoiceDetails]);

	const getInvoiceData = id => {
		axios.post('/workOrder/getInvoiceData', { InvoiceId: id }).then(res => {
			// console.log(res.data.invoiceDetails);
			let invoiceDetailsArray = res.data.invoiceDetails;
			let chargeDetailsArray = res.data.chargeDetails;
			invoiceDetailsArray.forEach(invoice => {
				invoice.chargeData = chargeDetailsArray.filter(
					charge => charge.invoice_id === invoice.id
				);
			});
			setInvoiceData(invoiceDetailsArray);
			setEditModal(!editModal);
		});
	};

	const invoiceModal = id => {
		axios.post('/workOrder/getInvoiceData', { InvoiceId: id }).then(res => {
			// console.log(res.data.invoiceDetails);
			let invoiceDetailsArray = res.data.invoiceDetails;
			let chargeDetailsArray = res.data.chargeDetails;
			invoiceDetailsArray.forEach(invoice => {
				invoice.chargeData = chargeDetailsArray.filter(
					charge => charge.invoice_id === invoice.id
				);
			});
			setInvoiceData(invoiceDetailsArray);
			setShowInvoice(true);
		});
	};
	const handleDelete = id => {
		// console.log(id);
		axios.post('/workOrder/deleteInvoice', { InvoiceId: id }).then(res => {
			const newInvoice = invoices.filter(inv => inv.id !== id);

			setInvoices(newInvoice);
		});
	};

	const openEditModal = () => {
		setEditModal(!editModal);
	};

	const expandedRowRender = record => {
		// console.log(record);
		const chargeColumns = [
			{
				title: 'Charge Description',
				dataIndex: 'charge_description',
				width: 130
			},

			{
				title: 'Charge From',
				dataIndex: 'charge_from',
				width: 130
			},

			{
				title: 'Charge Amount',
				dataIndex: 'gross_amount',
				width: 80
			},
			{
				title: 'HST Amount',
				dataIndex: 'gst_amount',
				width: 80
			},

			{
				title: 'Total Amount',
				dataIndex: 'total_amount',
				width: 80
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 80
			}
		];
		return (
			<Table
				className='mt-3 mb-3'
				rowKey={rowRecord => rowRecord.invoice_id}
				columns={chargeColumns}
				dataSource={record.chargeData}
				pagination={false}
			/>
		);
	};

	const columns = [
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			width: 90
		},
		{
			title: 'Invoice No.',
			dataIndex: 'invoice_number',
			width: 80
		},
		{
			title: 'Charge To',
			dataIndex: 'charge_to',
			width: 100
		},

		{
			title: 'Charge From',
			dataIndex: 'charge_from',
			width: 100
		},
		{
			title: 'Property',
			dataIndex: 'property_name',

			width: 90
		},

		{
			title: 'Invoice Amount',
			dataIndex: 'invoice_amount',
			width: 80,
			align: 'right'
		},
		{
			title: 'HST Amount',
			dataIndex: 'gst_amount',
			width: 80,
			align: 'right'
		},

		{
			title: 'Total Amount',
			dataIndex: 'total_amount',
			width: 80,
			align: 'right'
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: (_, record) => (
				<>
					<FilePdfTwoTone
						onClick={() => {
							// console.log(record.id);
							invoiceModal(record.id);
						}}
					/>
					<EditTwoTone
						onClick={() => {
							// console.log(record.id);
							getInvoiceData(record.id);
						}}
					/>
					<Popconfirm
						title='Sure to delete?'
						onConfirm={() => handleDelete(record.id)}
					>
						<DeleteTwoTone />
					</Popconfirm>
				</>
			)
		}
	];

	return (
		<>
			<Table
				rowKey={record => record.invoice_number}
				columns={columns}
				expandable={{
					expandedRowRender,
					defaultExpandedRowKeys: ['0']
					// onExpand: (expanded, record) => {
					// 	console.log('onExpand: ', record, expanded);
					// }
				}}
				dataSource={invoices}
				pagination={false}
			/>

			{showInvoice && (
				<Modal
					title='Invoice'
					open={showInvoice}
					onCancel={() => setShowInvoice(false)}
					footer={false}
				>
					<InvoicePDFViewer invoiceData={invoiceData} />
				</Modal>
			)}
			<Modal
				destroyOnClose={true}
				title='Edit Charges'
				open={editModal}
				onCancel={openEditModal}
				footer={false}
				width={'950px'}
			>
				<EditCharge
					invoiceDetails={invoiceData}
					onClose={openEditModal}
					getInvoiceDetails={props.getInvoiceDetails}
				/>
			</Modal>
		</>
	);
};

export default InvoiceDetails;
