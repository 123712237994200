import React, { useState } from 'react';
import BreadCrumbHeader from './BreadCrumb';
import { Layout, Dropdown, Menu, Button } from 'antd';
import HeaderProfile from './HeaderProfile';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { UserStateContext } from '../../Context/UserContext';

const { Header } = Layout;

class DesktopHeader extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Header id='header' className='d-flex flex-row'>
				<img
					className='menu-collapse mr-4 ml-3'
					onClick={this.props.collapseSideBar}
					src={require('../../icons/menu-collapse-icon.png')}
				/>
				<div className='currentPage fs-20 flex-50'>
					<BreadCrumbHeader />{' '}
				</div>
				<div className='flex-35 text-right'>
					{this.props.history.location.pathname.split('/')[1] !==
						'communication' && this.context.user.type === 'Manager' ? (
						<Button
							type='primary'
							className='request-btn width50 btnLabel'
							onClick={this.props.openRequest}
						>
							New Request
						</Button>
					) : null}
				</div>
				<HeaderProfile />
			</Header>
		);
	}
}

export default withRouter(DesktopHeader);
