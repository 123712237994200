import React from 'react';
import { Card, Avatar, Dropdown, Menu, Modal } from 'antd';
import axios from 'axios';

import { UserStateContext } from '../../../../Context/UserContext'; //Get the current user's data
import ChangeImageModal from './ChangeImageModal';
import { EditOutlined } from '@ant-design/icons';
import EditUserDetails from '../../../IntakeRequest/actions/EditUserDetails';
const cancelToken = axios.CancelToken.source();

class ProfileInformation extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			agreement: null,
			error: false,
			changeImageModal: false,
			profile: [],
			editDetails: false
		};
	}
	componentWillMount = () => {
		axios
			.get('/user/' + this.context.user.id)
			.then(response => {
				this.setState({
					profile: response.data.user,
					access: response.data.access
				});
			})
			.catch(err => {});
	};
	openEditModal = () => this.setState({ editDetails: !this.state.editDetails });

	openChangeImage = () =>
		this.setState({ changeImageModal: !this.state.changeImageModal });

	render() {
		const { user } = this.context;

		return (
			<div>
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item onClick={this.openChangeImage}>
								Change Profile Image
							</Menu.Item>
						</Menu>
					}
					trigger={['click']}
					placement='bottomRight'
				>
					<img
						src={require('../../../../icons/verticalMenuGrey.png')}
						className='verticalMenuIcon myProfileMenu'
					/>
				</Dropdown>
				<div className=' text-center'>
					{user.imageurl === 'avatar_default.png' ? (
						<Avatar size={145} className='myProfileAva'>
							{user.fname[0]}
						</Avatar>
					) : (
						<Avatar size={145} src={user.imageurl} className='myProfileAva' />
					)}
					<h2 className='mt-2'>
						{user.fname} {user.lname}
					</h2>
				</div>
				<Card
					title={
						<div className=' fs-16 g-text'>
							Contact Information
							<EditOutlined onClick={this.openEditModal}></EditOutlined>
						</div>
					}
					bordered={false}
				>
					<div className='g-text-sm' htmlFor='userEmail'>
						Email
					</div>
					<div id='userEmail' className='mb-2'>
						{this.state.profile.email}
					</div>
					{this.state.profile.phone ? (
						<>
							<div className='g-text-sm' htmlFor='userPhone'>
								Phone Number
							</div>
							<div id='userPhone'>{this.state.profile.phone}</div>
						</>
					) : (
						''
					)}
					{this.state.profile.birthDate ? (
						<>
							<div className='g-text-sm' htmlFor='userbirthDate'>
								Birth Date
							</div>
							<div id='userBD'>{this.state.profile.birthDate}</div>
						</>
					) : (
						''
					)}
					{this.state.profile.homeStreet ? (
						<>
							<div className='g-text-sm' htmlFor='userhomeStreet'>
								Address
							</div>
							<div id='userhomeStreet'>
								{this.state.profile.homeNumber}&nbsp;
								{this.state.profile.homeStreet},&nbsp;
								{this.state.profile.homeCity},&nbsp;
								{this.state.profile.homeProvince},&nbsp;
								{this.state.profile.homePostalcode}
							</div>
						</>
					) : (
						''
					)}
				</Card>
				<Modal
					visible={this.state.changeImageModal}
					onCancel={this.openChangeImage}
					size='sm'
					aria-labelledby='add-employee-modal'
					centered
					footer={false}
				>
					{this.state.changeImageModal ? (
						<ChangeImageModal
							currImg={user.imageurl}
							onHide={this.openChangeImage}
							changeProfilePicture={this.props.changeProfilePicture}
						/>
					) : (
						''
					)}
				</Modal>
				<Modal
					title='Edit contact details'
					centered
					width='700px'
					open={this.state.editDetails}
					onCancel={this.openEditModal}
					footer={false}
				>
					{this.state.editDetails ? (
						<EditUserDetails
							user={this.context.user}
							onCancel={this.openEditModal}
							fname={this.state.profile.fname}
							lname={this.state.profile.lname}
							email={this.state.profile.email}
							phone={this.state.profile.phone}
							homeNumber={this.state.profile.homeNumber}
							street={this.state.profile.homeStreet}
							city={this.state.profile.homeCity}
							postalCode={this.state.profile.homePostalcode}
							province={this.state.profile.homeProvince}
							birthDate={this.state.profile.birthDate}
							userID={this.context.user.id}
						/>
					) : (
						<div></div>
					)}
				</Modal>
			</div>
		);
	}
}

export default ProfileInformation;
