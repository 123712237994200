import React, { useState } from 'react';
import { Modal, ModalTitle, Form } from 'react-bootstrap';
import axios from 'axios';

import { Button, message, Table, Progress, Collapse } from 'antd';
import LoadScreen from '../../../../Components/LoadScreen';
import { Searchbar } from '../../../../Components/Searchbar/Searchbar';
import VendorList from './VendorList';

import {
	FooterNext,
	FooterBoth,
	FooterEnd
} from '../../../../Components/FooterButtons';
// import "./jobRequest.css";

const cancelToken = axios.CancelToken.source();

//Modal that opens when you need to assign or change vendor. Initalizes vendor list.
class AssignVendorModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'Loading',
			vendorSelected: '',
			maintenanceList: null,
			vendorList: null,
			employeeList: null,
			searchValue: '',
			selectedVendors: [],
			selectedEmployee: []
		};
	}
	selectVendor = vendor => {
		this.setState({ vendorSelected: vendor });
	};

	componentDidMount() {
		axios
			.post('/jobRequest/get/vendorList', {
				company_id: this.props.request.job_assignedto_id
			})
			.then(response => {
				this.setState({
					vendorList: response.data.vendorList,
					employeeList: response.data.employeeList,
					page: 'SelectVendor'
				});
				return;
			})
			.catch(err => {
				this.setState({ page: 'Error', errorMessage: err.description });
			});
	}
	assignVendors = () => {
		if (this.state.selectedVendors.length != 0) {
			axios
				.post('/jobRequest/add/vendors', {
					vendors: this.state.selectedVendors,
					job_id: this.props.request.job_id
				})
				.then(responseA => {
					if (this.state.selectedEmployee.length != 0) {
						axios
							.post('jobRequest/add/jremployees', {
								employees: this.state.selectedEmployee,
								job_id: this.props.request.job_id
							})
							.then(responseB => {
								this.props.onHide();
								this.props.updateVendors(responseA.data.vendors);
								this.props.updateEmployees(responseB.data.employees);
								message.success(
									'Vendors and Employees have been successfully added'
								);
							})
							.catch(err => {
								console.log(err);
							});
					} else {
						this.props.onHide();
						this.props.updateVendors(responseA.data.vendors);
						message.success('Vendors have been successfully added');
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	};
	getDisabled = record => {
		return this.props.request.vendors
			? !this.props.request.vendors.findIndex(
					v => record.id === v.vendor_id && v.terminate === 0
			  )
			: false;
	};
	rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			this.setState({ selectedVendors: selectedRows });
		},
		getCheckboxProps: record => ({
			disabled: this.props.request.vendors
				? this.props.request.vendors.findIndex(
						v => record.id === v.vendor_id && v.terminate === 0
				  ) + 1
				: false,
			name: record.name
		})
	};

	setSearchValue = e => this.setState({ searchValue: e.target.value });

	toggleContact = person => {
		let newContacts = [...this.state.selectedVendors];
		let contactIndex = newContacts.findIndex(contact => {
			return (
				contact ===
				`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
			);
		});
		if (contactIndex >= 0) {
			newContacts.splice(contactIndex, 1);
		} else {
			//调整是否多选还是单选
			// newContacts = [(`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`)]
			newContacts.push(
				`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
			);
		}

		this.setState({ selectedVendors: newContacts });
		if (newContacts.length == 0) {
			this.setState({ selectedEmployee: [] });
		}
	};
	toggleEmployeeContact = person => {
		let newContacts = [...this.state.selectedEmployee];
		let contactIndex = newContacts.findIndex(contact => {
			return (
				contact ===
				`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
			);
		});
		if (contactIndex >= 0) {
			newContacts.splice(contactIndex, 1);
		} else {
			//调整是否多选还是单选
			//newContacts = [(`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`)]
			newContacts.push(
				`${person.id}/--/${person.fname}/--/${person.lname}/--/${person.type}`
			);
		}

		this.setState({ selectedEmployee: newContacts });
	};

	render() {
		return (
			<>
				<>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>
							Assign Vendors
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<VendorList
							newThread={this.newThread}
							toggleContact={this.toggleContact}
							toggleEmployeeContact={this.toggleEmployeeContact}
							selectedVendors={this.state.selectedVendors}
							selectedEmployee={this.state.selectedEmployee}
							job_id={this.props.request.job_id}
							employeeList={this.state.employeeList}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.props.onHide}>Close</Button>
						<Button
							type='primary'
							disabled={
								this.state.selectedVendors.length === 0 &&
								this.state.selectedEmployee.length === 0
							}
							onClick={this.assignVendors}
						>
							Assign vendor
						</Button>
					</Modal.Footer>
				</>
			</>
		);
	}
}

const vendorListCols = [
	{
		title: 'Name',
		dataIndex: 'name',
		render: (name, record) =>
			record.fname + ' ' + (record.lname ? record.lname : '')
	},
	{
		title: 'Company',
		dataIndex: 'company_id',
		render: id => (id === 42 ? 'Steady Property Management' : '')
	}
];
export default AssignVendorModal;
