import React, { Component } from "react";
import { message } from "antd";
import RequestContext from "../../../Pages/MaintenanceRequest/RequestContext";
import CreateEventModal from '../../../Pages/MaintenanceRequest/actions/modals/CreateEvent';

export default class ReworkNewEventWO extends Component {
  static contextType = RequestContext;
    constructor(props) {
        super(props);
        this.state = {
          doPrevent: false,

        }
    }

    preventClosing = (val) => {
      this.setState({doPrevent: val});
    }

    afterEvent = (evt) => {
      this.context.updateWork(evt);
      this.props.reviewWOContext.reworkWorkOrder(this.props.reviewWOContext.reworkWOTextArea);
      this.props.onCancel();
    }


    render() {
        return (
            <>
            <CreateEventModal
              updateStatus={this.context.updateStatus}
              updateImages={this.context.updateImages}
              preventClosing={this.preventClosing}
              afterEvent={this.afterEvent}
              onHide={ () => {this.props.changePage("ReworkWorkOrder")} }
              vendors={this.context.request.vendors}
              employees={this.context.request.employees}
              tenants={this.context.request.tenants}
              owners={this.context.request.owners}
              job_id={this.context.request.job_id}
              request = {this.context.request}
              user={this.context.user}
              doPrevent={this.state.doPrevent}
            />
            </>
        )
    }
}