import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Card, Avatar, Button, Modal } from 'antd';
import { EditOutlined, CalendarOutlined } from '@ant-design/icons';
import axios from 'axios';

import LoadScreen from '../../../Components/LoadScreen';
import { AgreementListing } from '../../Agreements/Agreements';
import EmployeeAvailabilityCalendar from '../MyProfile/Components/EmployeeAvailabilityCalendar';
import EditUserDetails from '../../IntakeRequest/actions/EditUserDetails';
const cancelToken = axios.CancelToken.source();
class UserProfile extends React.Component {
	constructor(props) {
		super();
		this.state = {
			availabilityModalOpen: false,
			editDetails: false,
			taskarray: []
		};
	}
	componentDidMount = () => {
		// let user_id = window.location.href.split('/')[4];

		axios
			.get('/user/' + this.props.location.state.id)
			.then(response => {
				this.setState({
					user: response.data.user,
					access: response.data.access
				});
			})
			.catch(err => {});
	};
	openScheduleModal = () =>
		this.setState({ availabilityModalOpen: !this.state.availabilityModalOpen });
	openEditModal = () => this.setState({ editDetails: !this.state.editDetails });
	getAvailability = userId => {
		axios.post('/get/useravailability', { userID: userId }).then(response => {
			this.setState({ taskarray: response.data.Items });
			return response.data.Items;
		});
	};
	render() {
		console.log(this.state.user);
		if (this.state.user === undefined) return <LoadScreen className='m-auto' />;
		else if (this.state.user === null) return <div>No Profile Found</div>;
		return (
			<div className='profileShell myProfileContainer '>
				<div className='profileAva text-center'>
					{this.state.user.company_account === 1 ? (
						<>
							<CalendarOutlined
								style={{ display: 'flex', justifyContent: 'left' }}
								onClick={this.openScheduleModal}
							></CalendarOutlined>
							{this.state.user.imageurl === 'avatar_default.png' ? (
								<Avatar size={145}>{this.state.user.fname[0]}</Avatar>
							) : (
								<Avatar size={145} src={this.state.user.imageurl} />
							)}
							<h2 className='mt-2'>
								{this.state.user.fname} {this.state.user.lname}
							</h2>
						</>
					) : (
						<>
							{this.state.user.imageurl === 'avatar_default.png' ? (
								<Avatar size={145}>{this.state.user.fname[0]}</Avatar>
							) : (
								<Avatar size={145} src={this.state.user.imageurl} />
							)}
							<h2 className='mt-2'>
								{this.state.user.fname} {this.state.user.lname}
							</h2>
						</>
					)}
				</div>
				<div className='borderLine mb-2'></div>

				{this.state.access === 'Full_Access' ? (
					this.state.user.Tenant_account === 1 ||
					this.state.user.PO_account === 1 ? (
						<>
							<Card
								title={
									<div className=' fs-16 g-text'>
										Contact Information
										<EditOutlined onClick={this.openEditModal}></EditOutlined>
									</div>
								}
								bordered={false}
							>
								<div className='g-text-sm'>Email</div>
								<div id='userEmail' className='mb-2'>
									{this.state.user.email}
								</div>

								{this.state.user.phone ? (
									<>
										<div className='g-text-sm' for='userPhone'>
											Phone Number
										</div>
										<div id='userPhone'>{this.state.user.phone}</div>
									</>
								) : (
									''
								)}
								{this.state.user.birthDate ? (
									<>
										<div className='g-text-sm' for='userbirthDate'>
											Birth Date
										</div>
										<div id='userBD'>{this.state.user.birthDate}</div>
									</>
								) : (
									''
								)}
								{this.state.user.homeStreet ? (
									<>
										<div className='g-text-sm' for='userhomeStreet'>
											Address
										</div>
										<div id='userhomeStreet'>
											{this.state.user.homeNumber}&nbsp;
											{this.state.user.homeStreet}
											<br />
											{this.state.user.homeCity},<br />
											{this.state.user.homeProvince} &nbsp;
											{this.state.user.homePostalcode}
										</div>
									</>
								) : (
									''
								)}
							</Card>
						</>
					) : this.state.user.Vendor_account === 1 ? (
						<>
							<Card
								title={
									<div className=' fs-16 g-text'>
										Contact Information
										<EditOutlined onClick={this.openEditModal}></EditOutlined>
									</div>
								}
								bordered={false}
							>
								{this.state.user.services ? (
									<>
										<div className='g-text-sm' for='userservices'>
											Service
										</div>
										<div id='userservices'>{this.state.user.services}</div>
									</>
								) : (
									''
								)}
								<div className='g-text-sm'>Email</div>
								<div id='userEmail' className='mb-2'>
									{this.state.user.email}
								</div>

								{this.state.user.phone ? (
									<>
										<div className='g-text-sm' for='userPhone'>
											Phone Number
										</div>
										<div id='userPhone'>{this.state.user.phone}</div>
									</>
								) : (
									''
								)}

								{this.state.user.homeStreet ? (
									<>
										<div className='g-text-sm' for='userhomeStreet'>
											Address
										</div>
										<div id='userhomeStreet'>
											{this.state.user.homeNumber}&nbsp;
											{this.state.user.homeStreet}
											<br />
											{this.state.user.homeCity},<br />
											{this.state.user.homeProvince} &nbsp;
											{this.state.user.homePostalcode}
										</div>
									</>
								) : (
									''
								)}
							</Card>
						</>
					) : (
						''
					)
				) : (
					''
				)}

				<Modal
					bodyStyle={{ height: '350px' }}
					width='800px'
					title='Edit Details'
					visible={this.state.editDetails}
					onCancel={this.openEditModal}
					footer={false}
				>
					{this.state.editDetails ? (
						<EditUserDetails
							onCancel={this.openEditModal}
							user={this.state.user}
							fname={this.state.user.fname}
							lname={this.state.user.lname}
							email={this.state.user.email}
							phone={this.state.user.phone}
							homeNumber={this.state.user.homeNumber}
							street={this.state.user.homeStreet}
							city={this.state.user.homeCity}
							postalCode={this.state.user.homePostalcode}
							province={this.state.user.homeProvince}
							birthDate={this.state.user.birthDate}
							userID={this.props.location.state.id}
						/>
					) : (
						<div></div>
					)}
				</Modal>
				{
					<Modal
						bodyStyle={{ height: '700px' }}
						width='1046px'
						title='Employee Availability'
						visible={this.state.availabilityModalOpen}
						onCancel={this.openScheduleModal}
						footer={false}
					>
						<EmployeeAvailabilityCalendar
							userId={this.props.location.state.id}
						/>
					</Modal>
				}
			</div>
		);
	}
}

// const LeaseInfo = ({leases}) => {

//   return(
//     <Card
//     title={<div className=" fs-16 g-text">Lease Information</div>}
//     bordered={false}
//     >
//     {leases.map( (lease, agindex) => <AgreementListing key={agindex} agreement={lease} mobile noBottomBorder={leases.length == 1}/> )}
//     </Card>
//   )

// }

export default UserProfile;
