import React, { Component, useState } from "react";
import axios from "axios";
import { Button, message } from "antd";
import WorkOrderContext from "../WorkOrderContext";
import RequestContext from '../../../Pages/MaintenanceRequest/RequestContext'
import ImageUploadModal from "../../ImageUploadModal/ImageUploadModal";
import moment from 'moment';

const cancelToken = axios.CancelToken.source();
const PauseWorkOrder = (props) => {
  const contextWorkOrder = React.useContext(WorkOrderContext);
  const contextRequest = React.useContext(RequestContext);
  const [description, setDescription] = useState("");
  const [errormsg, seterrormsg] = useState("");
  const [loading, setLoading] = useState(false);
      
  const submitRequestForPause = () => {
    axios
      .post("/workOrder/update/status/hold", {
          job_id: contextWorkOrder.jobData.job_id,
          wo_id: contextRequest.currentWorkOrder.wo_id,
          pm_id: contextRequest.user.pm_id,
          status_reason: description
      })
      .then((response) => {
        contextRequest.updateRequestWorkOrders(contextRequest.activeEvent.event_id);
        contextRequest.updateStatus("On Hold");
        contextRequest.addActivity(response.data.note, null);
        message.success("Request to pause Work Order has been sent!");
      })
  }

  const handleTextAreaChange = (evt) => {
    setDescription(evt.target.value);
  }

  return (
    <div className="Reason ">
      <div>
        
        <label>Reasons for requiring a pause to the Work Order</label>
        
        <textarea
          className="form-control"
          rows={6}
          defaultValue={description}
          onChange={(evt) => handleTextAreaChange(evt)}
          id="comment"
          name="text"
        ></textarea>
      </div>
      
      <p className="errorMsg">{errormsg}</p>
      <br/>
      <div className="buttonGroup">
        <Button
          loading={loading}
          type="primary"
          disabled={!(description.length > 0)}
          className="mr-0 "
          onClick={submitRequestForPause}
        >
          Submit
        </Button>
      </div>
    </div>
    
  );
}
export default PauseWorkOrder;