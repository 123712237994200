import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Button, message } from "antd";
import axios from "axios";

import {
  FooterNext,
  FooterBack,
  FooterSubmit,
} from "../../Components/FooterButtons";
import { BoxDisplay } from "./../../Components/BoxDisplay/BoxDisplay";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import { StepProgress } from "../../Components/StepProgress";
import "./feedback.css";
import LoadScreen from "../../Components/LoadScreen";
import { withRouter } from "react-router-dom";
const cancelToken = axios.CancelToken.source();

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      body: "",
      page: 0,
      bug: {
        category: "",
        description: "",
        steps: "",
        pageName: this.props.history.location.pathname,
      },
      suggestion: {
        description: "",
        pageName: this.props.history.location.pathname,
      },
      errorMessage: "",
      progress: 0,
    };
    this.selectType = this.selectType.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.setBugDescription = this.setBugDescription.bind(this);
    this.validateDescription = this.validateDescription.bind(this);
    this.goback = this.goback.bind(this);
    this.setBugSteps = this.setBugSteps.bind(this);
    this.submitBug = this.submitBug.bind(this);
    this.completeRequest = this.completeRequest.bind(this);
  }

  selectType(type) {
    this.setState({ type: type });
  }
  selectCategory(category) {
    let bug = this.state.bug;
    bug.category = category;
    this.setState({ bug: bug, page: this.state.page + 1 });
  }
  setBugDescription(description) {
    let bug = this.state.bug;
    bug.description = description;
    this.setState({ bug: bug });
  }
  setSuggestionDescription = (description) =>
    this.setState({
      suggestion: { ...this.state.suggestion, description: description },
    });
  setBugSteps(steps) {
    let bug = this.state.bug;
    bug.steps = steps;
    this.setState({ bug: bug });
  }
  validateDescription() {
    if (this.state.bug.description !== "") {
      this.setState({ page: this.state.page + 1, errorMessage: "" });
    } else
      this.setState({
        errorMessage:
          "Error: Please enter a detailed description of the bug encountered.",
      });
  }
  goback() {
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    } else {
      this.setState({ type: "" });
    }
  }
  completeRequest() {
    this.setState({ type: "Complete" });
  }
  submitFeedback = () => {
    this.setState({ type: "Submitting" });
    axios
      .post("/sendSuggestion", { suggestion: this.state.suggestion })
      .then(() => {
        this.props.openFeedback();
        message.success(
          "Thank you for your feedback."
        );
      })
      .catch((err) => console.log(err));
  };
  submitBug() {
    this.setState({ type: "Submitting" });
    axios
      .post("/bugReport", {
        bug: this.state.bug,
      })
      .then((response) => {

          this.props.openFeedback();
          message.success(
            "Thank you for your feedback, your bug report has been received."
          );
        
      })
      .catch((error) => {

        this.setState({ type: "Error", errorMessage: error.message });
      });
  }

  render() {
    let footer, body, header, progress;

    if (this.state.type === "Bug")
      progress = (
        <StepProgress
          steps={3}
          current={this.state.page}
          classOption="width45"
        />
      );
    else progress = "";

    switch (this.state.type) {
      case "":
        body = (
          <SelectHelpType
            nextPage={this.nextPage}
            selectType={this.selectType}
            type={this.state.type}
            mobile={this.props.mobile}
          />
        );
        header = "Select Feedback Type";
        //footer = <FooterClose className='width20'clickClose={this.props.openFeedback}/>;
        break;
      case "Bug":
        header = "Bug Report";
        switch (this.state.page) {
          case 0:
            body = <SelectBugType selectCategory={this.selectCategory} />;
            break;

          case 1:
            body = (
              <BugDescription
                description={this.state.bug.description}
                setBugDescription={this.setBugDescription}
                errorMessage={this.state.errorMessage}
              />
            );
            footer = (
              <div className="d-inline">
                {" "}
                <FooterNext clickNext={() => this.validateDescription()} />
              </div>
            );
            break;
          case 2:
            body = (
              <BugSteps
                setBugSteps={this.setBugSteps}
                steps={this.state.bug.steps}
              />
            );
            footer = <FooterSubmit clickEnd={this.submitBug} />;
            break;

          default:
            body = <ErrorPage />;
            header = "Error";
            footer = (
              <FooterBack
                clickBack={() => this.setState({ page: this.state.page - 1 })}
              />
            );
        }
        break;

      case "Suggestion":
        switch (this.state.page) {
          case 0:
            body = (
              <FeedbackInformation
                setDescription={this.setSuggestionDescription}
                description={this.state.suggestion.description}
              />
            );
            header = "Provide Feedback";
            footer = <FooterSubmit width100 clickEnd={this.submitFeedback} />;
            break;
        }
        break;
      case "Submitting":
        body = <LoadScreen />;
        header = "Sending...";
        break;
      case "Complete":
        body = <div>Thank You for your bug report.</div>;
        header = "Bug successfully sent";
        break;
      case "Error":
        body = <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
        header = "Error";
        break;
      default:
        body = (
          <SelectHelpType
            selectType={this.selectType}
            type={this.state.type}
            mobile={this.props.mobile}
          />
        );
        header = "Error";
        footer = (
          <FooterBack
            clickBack={() => this.setState({ page: this.state.page - 1 })}
          />
        );
    }

    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.open}
        onHide={this.props.openFeedback}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <img
              src={require("../../icons/backArrow.png")}
              alt="back"
              onClick={this.goback}
              className={
                this.state.type === "" || this.state.type === "Complete"
                  ? "mr-4 d-none"
                  : "mr-4 pointer"
              }
            />
            <div className="fs-18 d-inline">{header}</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={
            !this.props.mobile
              ? "modal-body padding0 height310Fixed"
              : "modal-body padding0 height400Fixed"
          }
        >
          {body}
        </Modal.Body>

        <Modal.Footer id="feedbackFooter">
          {progress}
          <div
            className={`text-right floatRight ${
              this.state.type === "Suggestion" ? "width100" : "width50"
            } d-inline`}
          >
            {footer}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function FeedbackInformation({ description, setDescription }) {
  return (
    <Form>
      <Form.Group className="feedbackForm">
        <Form.Label>Suggestion description</Form.Label>
        <Form.Control
          as="textarea"
          rows="8"
          defaultValue={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
    </Form>
  );
}
function SetSuggestionDescription() {
  return <div></div>;
}

function BugSteps({ steps, setBugSteps }) {
  return (
    <Form className="mt-2 mb-4">
      <Form.Group className="width90 m-auto">
        <Form.Label>
          If possible, please provide steps to recreate this bug
        </Form.Label>
        <Form.Control
          size="sm"
          as="textarea"
          rows="8"
          placeholder="Steps to recreate"
          value={steps}
          onChange={(e) => setBugSteps(e.target.value)}
        />
      </Form.Group>
      <Form.Text className="mt-2 width90 m-auto"></Form.Text>
    </Form>
  );
}

function BugDescription({ description, setBugDescription, errorMessage }) {
  return (
    <div>
      <Form className="mt-2 mb-4">
        <Form.Group className="width90 m-auto">
          <Form.Label>Description of the Bug </Form.Label>
          <Form.Control
            size="sm"
            as="textarea"
            rows="5"
            placeholder="Bug Description"
            value={description}
            onChange={(e) => setBugDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Text className="mt-2 width90 m-auto">
          Questions to consider:
          <ul>
            <li className="pl-2 fs-13">
              -What you were doing when this happened
            </li>
            <li className="pl-2 fs-13">-What was impacted by this bug</li>
            <li className="pl-2 fs-13">-What were the results of this bug</li>
          </ul>
        </Form.Text>
      </Form>
      <ErrorMessage otherClass="width90 m-auto mt-2">
        {errorMessage}
      </ErrorMessage>
    </div>
  );
}

function SelectBugType({ selectCategory }) {
  return (
    <BoxDisplay className="d-flex h100">
      <BoxDisplay.HelpBoxSmall>
        <h5 className="flex-15 fs-16">Navigation or Loading problems</h5>
        <div className="fs-13 mb-4 flex-65">
          If you are having trouble navigating around /Mountain/ or experience
          content loading incorrectly, please click the button below to send a
          note to our developers.{" "}
        </div>
        <Button
          type="primary"
          size="sm"
          className="flex-20"
          onClick={(e) => selectCategory(e.target.value)}
          value="Loading/Navigation"
        >
          Loading or Navigation Problem
        </Button>
      </BoxDisplay.HelpBoxSmall>
      <BoxDisplay.HelpBoxSmall>
        <h5 className="flex-15 fs-16">Data Problems</h5>
        <div className="fs-13 mb-4 flex-65">
          If you have issues with your data not displaying properly or data not
          being visible in /Mountain/, please click the button below to send a
          bug report to our developers.
        </div>
        <Button
          type="primary"
          size="sm"
          className="flex-20"
          onClick={(e) => selectCategory(e.target.value)}
          value="Data"
        >
          Bug Involving Data
        </Button>
      </BoxDisplay.HelpBoxSmall>
      <BoxDisplay.HelpBoxSmall>
        <h5 className="flex-15 fs-16">Account or Payment Problem</h5>
        <div className="fs-13 mb-4 flex-65">
          If you are experiencing problems with your account, or have problems
          with our payment system, please click the button below to send a note
          to our developers.{" "}
        </div>
        <Button
          type="primary"
          size="sm"
          className="flex-20"
          onClick={(e) => selectCategory(e.target.value)}
          value="Account/Payment"
        >
          Account or Payment Problems
        </Button>
      </BoxDisplay.HelpBoxSmall>
      <BoxDisplay.HelpBoxSmall>
        <h5 className="flex-15 fs-16">Other</h5>
        <div className="fs-13 mb-4 flex-65">
          If you are having other issues using /Mountain/, please click the
          button below to send a bug report to our developers.
        </div>
        <Button
          type="primary"
          size="sm"
          className="flex-20"
          onClick={(e) => selectCategory(e.target.value)}
          value="Other"
        >
          Other
        </Button>
      </BoxDisplay.HelpBoxSmall>
    </BoxDisplay>
  );
}

const ErrorPage = () => {
  return <div>Something went wrong. Please try again later.</div>;
};

function SelectHelpType({ selectType, mobile }) {
  return (
    <BoxDisplay className="d-flex h100" mobile={mobile}>
      <BoxDisplay.HelpBox>
        <h5 className="flex-15">Leave Feedback</h5>
        <div className="fs-13 mb-4 flex-65">
          If you have a suggestion or feature you would like to see in
          /Mountain/, please click the button below to send a note to our
          developers.{" "}
        </div>
        <Button
          type="primary"
          className="width100 flex-20"
          onClick={() => selectType("Suggestion")}
        >
          Leave Feedback
        </Button>
      </BoxDisplay.HelpBox>
      <BoxDisplay.HelpBox>
        <h5 className="flex-15">Report a Bug</h5>
        <div className="fs-13 mb-4 flex-65">
          If you find any issues while using /Mountain/, please click the button
          below to send a bug report to our developers.
        </div>
        <Button
          className="width100 flex-20"
          onClick={() => selectType("Bug")}
          type="primary"
        >
          Report a Bug
        </Button>
      </BoxDisplay.HelpBox>
    </BoxDisplay>
  );
}

export default withRouter(Feedback);
