import React from 'react';
const PropertyStateContext = React.createContext();
const PropertyDispatchContext = React.createContext()

function propertyReducer(state, action) {
    switch (action.type) {
      case 'update': {
          return {properties:action.state.properties,property_update:action.state.property_update,purpose:action.state.purpose}; 
      }
      case 'add': {
        return {properties:state.properties,property_update:state.property_update,purpose:state.purpose}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
  }


const PropertyProvider = ({children}) => {
    const [state,dispatch] = React.useReducer(propertyReducer, {properties: [],property_update:0,purpose:""})

    return(
        <PropertyStateContext.Provider value={state}>
            <PropertyDispatchContext.Provider value={dispatch}>
                {children}
            </PropertyDispatchContext.Provider>
        </PropertyStateContext.Provider>
    )
}

function usePropertyState() {
    const context = React.useContext(PropertyStateContext)
    if (context === undefined) {
      throw new Error('usePropertyState must be used within a propertyProvider')
    }
    return context
  }
  
function usePropertyDispatch() {
    const context = React.useContext(PropertyDispatchContext)
    if (context === undefined) {
      throw new Error('usePropertyDispatch must be used within a propertyProvider')
    }
    return context
  }

  export {PropertyProvider, usePropertyState, usePropertyDispatch}
  
