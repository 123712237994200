import React, { useEffect, useState } from 'react';
import { FilePdfTwoTone } from '@ant-design/icons';
import { Table, Button, Modal, DatePicker, Popconfirm } from 'antd';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { SearchbarManual } from '../../Components/Searchbar/Searchbar';
import ExportModal from './ExportModal';
import axios from 'axios';
import {
	DownloadOutlined,
	DeleteTwoTone,
	EditTwoTone
} from '@ant-design/icons';
import InvoicePDFViewer from './InvoicePDFViewer';

import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
// import EditCharge from './EditCharge';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const InvoiceTable = props => {
	const [showInvoice, setShowInvoice] = useState(false);
	// const [invoiceData, setInvoiceData] = useState([]);
	const [filterInvoiceData, setFilteredInvoiceData] = useState([]);
	const [filterChargeData, setFilteredChargeData] = useState([]);
	const [totalInvoiceAmount, setTotalInvoiceAmount] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [totalHstAmount, setTotalHstAmount] = useState();
	const [totalAmount, setTotalAmount] = useState();
	const [startRangePicker, setStartRangePicker] = useState(
		moment().add(-30, 'days')
	);
	const [endRangePicker, setEndRangePicker] = useState(moment());
	const [specificInvoiceData, setSpecificInvoiceData] = useState([]);
	const [exportModal, setExportModal] = useState(false);
	// const [editModal, setEditModal] = useState(false);
	const [jrModal, setJrModal] = useState(false);
	const [jobId, setJobId] = useState();

	useEffect(() => {
		getInvoiceDetails(startRangePicker, endRangePicker);
	}, []);

	const setRangePicker = (start, end) => {
		setStartRangePicker(start);
		setEndRangePicker(end);
	};

	const getInvoiceDetails = (start, end) => {
		let body = {
			startDate:
				start === null
					? moment(startRangePicker).format('YYYY-MM-DD')
					: moment(start).format('YYYY-MM-DD'),
			endDate:
				end === null
					? moment(endRangePicker).format('YYYY-MM-DD')
					: moment(end).format('YYYY-MM-DD')
		};
		axios
			.post('/workOrder/getAllInvoiceData', body)
			.then(res => {
				// console.log(res.data.invoiceDetails);
				// console.log(res.data.chargeDetails);
				let invoiceDetailsArray = res.data.invoiceDetails;
				let chargeDetailsArray = res.data.chargeDetails;
				invoiceDetailsArray.forEach(invoice => {
					invoice.chargeData = chargeDetailsArray.filter(
						charge => charge.invoice_id === invoice.id
					);
				});
				setFilteredInvoiceData(invoiceDetailsArray);
				setFilteredChargeData(chargeDetailsArray);
				filterData();
			})
			.catch(err => console.log(err));
	};

	const invoiceModal = id => {
		const individualInvoiceDetails = filterInvoiceData.filter(
			data => data.id === id
		);

		setSpecificInvoiceData(individualInvoiceDetails);
		setShowInvoice(true);
	};
	// const getInvoiceData = id => {
	// 	axios.post('/workOrder/getInvoiceData', { InvoiceId: id }).then(res => {
	// 		// console.log(res.data.invoiceDetails);
	// 		let invoiceDetailsArray = res.data.invoiceDetails;
	// 		let chargeDetailsArray = res.data.chargeDetails;
	// 		invoiceDetailsArray.forEach(invoice => {
	// 			invoice.chargeData = chargeDetailsArray.filter(
	// 				charge => charge.invoice_id === invoice.id
	// 			);
	// 		});
	// 		// setInvoiceData(invoiceDetailsArray);
	// 		setEditModal(!editModal);
	// 	});
	// };
	// const handleDelete = id => {
	// 	// console.log(id);
	// 	axios.post('/workOrder/deleteInvoice', { InvoiceId: id }).then(res => {
	// 		const newInvoiceData = filterInvoiceData.filter(inv => inv.id !== id);

	// 		setFilteredInvoiceData(newInvoiceData);
	// 	});
	// };
	// const openEditModal = () => {
	// 	setEditModal(!editModal);
	// };

	const updateTotals = () => {
		let totalGrossAmount = 0,
			totalHstAmount = 0,
			totalAmount = 0;

		filterInvoiceData.forEach(row => {
			if (row.invoice_amount !== null)
				totalGrossAmount += parseFloat(row.invoice_amount);
			if (row.gst_amount !== null) totalHstAmount += parseFloat(row.gst_amount);
			if (row.invoice_amount !== null || row.gst_amount !== null)
				totalAmount +=
					parseFloat(row.invoice_amount) + parseFloat(row.gst_amount);
		});

		setTotalInvoiceAmount(totalGrossAmount.toFixed(2));
		setTotalHstAmount(totalHstAmount.toFixed(2));
		setTotalAmount(totalAmount.toFixed(2));
	};

	const filterData = () => {
		let filteredDetails;
		if (searchValue !== '' && searchValue !== null) {
			filteredDetails = filterInvoiceData.filter(row =>
				` ${row.invoice_number}  ${row.charge_to} ${row.property_name}`.toLowerCase()
			);
			setFilteredInvoiceData(filteredDetails);
			updateTotals();
		}
	};

	const setDateRange = date => {
		let start = date !== null ? date[0] : null;
		let end = date !== null ? date[1] : null;
		setRangePicker(start, end);
		getInvoiceDetails(start, end);
	};

	const findSearchValue = value => {
		setSearchValue(value);
		filterData();
	};

	const openExportModal = () => {
		setExportModal(!exportModal);
	};

	const openJRModal = id => {
		setJobId(id);
		setJrModal(!jrModal);
	};

	const expandedRowRender = record => {
		console.log(record);
		const chargeColumns = [
			{
				title: 'Charge Description',
				dataIndex: 'charge_description',
				width: 130
			},

			{
				title: 'Charge From',
				dataIndex: 'charge_from',
				width: 130
			},

			{
				title: 'Charge Amount',
				dataIndex: 'gross_amount',
				width: 80
			},
			{
				title: 'HST Amount',
				dataIndex: 'gst_amount',
				width: 80
			},

			{
				title: 'Total Amount',
				dataIndex: 'total_amount',
				width: 80
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				width: 80
			}
		];
		return (
			<Table
				className='mt-3 mb-3'
				rowKey={rowRecord => rowRecord.invoice_id}
				columns={chargeColumns}
				dataSource={record.chargeData}
				pagination={false}
			/>
		);
	};

	const columns = [
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			width: 90
		},
		{
			title: 'Invoice No.',
			dataIndex: 'invoice_number',
			width: 80,
			key: 'Invoice number',
			render: (_, record) => (
				<a style={{ color: 'blue' }} onClick={() => openJRModal(record.job_id)}>
					{record.invoice_number}
				</a>
			)
		},

		{
			title: 'Charge To',
			dataIndex: 'charge_to',
			width: 100
		},
		{
			title: 'Charge From',
			dataIndex: 'charge_from',
			width: 130
		},
		{
			title: 'Description',
			dataIndex: 'job_title',
			width: 130
		},
		{
			title: 'Property',
			dataIndex: 'property_name',

			width: 90
		},

		{
			title: 'Invoice Amount',
			dataIndex: 'invoice_amount',
			width: 80,
			align: 'right'
		},
		{
			title: 'HST Amount',
			dataIndex: 'gst_amount',
			width: 80,
			align: 'right'
		},

		{
			title: 'Total Amount',
			dataIndex: 'total_amount',
			width: 80,
			align: 'right'
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'action',
			width: 80,
			render: (_, record) => (
				<>
					<FilePdfTwoTone onClick={() => invoiceModal(record.id)} />
					{/* <EditTwoTone
						onClick={() => {
							// console.log(record.id);
							getInvoiceData(record.id);
						}}
					/>
					<Popconfirm
						title='Sure to delete?'
						onConfirm={() => handleDelete(record.id)}
					>
						<DeleteTwoTone />
					</Popconfirm> */}
				</>
			)
		}
	];
	console.log(filterInvoiceData);
	return (
		<div id='airbnbFinanceContainer' key='chargeContainer'>
			<div className='m-4 financeHeader'>
				<RangePicker
					defaultValue={[
						moment(startRangePicker, dateFormat),
						moment(endRangePicker, dateFormat)
					]}
					onChange={setDateRange}
					className='airbnbFinanceRangePicker'
				/>

				<SearchbarManual
					onClick={findSearchValue}
					searchValue={searchValue}
					className='airbnbFinanceRangePicker ml-5'
				/>
				<div className='airbnbFinanceExpImp mr-5'>
					<Button onClick={openExportModal}>
						<DownloadOutlined />
						Export
					</Button>
				</div>
			</div>
			<Table
				className='ml-4 mr-4'
				title={() => {
					updateTotals();
				}}
				rowKey={record => record.invoice_number}
				columns={columns}
				expandable={{
					expandedRowRender,
					defaultExpandedRowKeys: ['0']
				}}
				dataSource={filterInvoiceData}
				pagination={false}
			/>
			<Form className='mt-4 ml-4 financeTotalCosts'>
				<Form.Group>
					<Form.Label>Total Invoice Amount</Form.Label>
					<Form.Control
						type='text'
						value={'$ ' + totalInvoiceAmount}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Total HST Amount</Form.Label>
					<Form.Control type='text' value={'$ ' + totalHstAmount} disabled />
				</Form.Group>
				<Form.Group>
					<Form.Label>Total Amount</Form.Label>
					<Form.Control type='text' value={'$ ' + totalAmount} disabled />
				</Form.Group>
			</Form>

			{showInvoice && (
				<Modal
					title='Invoice'
					open={showInvoice}
					onCancel={() => setShowInvoice(false)}
					footer={false}
				>
					<InvoicePDFViewer invoiceData={specificInvoiceData} />
				</Modal>
			)}
			<Modal
				title='Export Data'
				open={exportModal}
				onCancel={openExportModal}
				size='sm'
				aria-labelledby='assign-vendor-modal'
				centered
				footer={false}
			>
				{exportModal ? (
					<ExportModal
						filteredData={filterChargeData}
						onHide={openExportModal}
					/>
				) : (
					''
				)}
			</Modal>
			{jrModal && (
				<Modal
					title='Job Request Details'
					open={jrModal}
					onCancel={openJRModal}
					footer={false}
					width={'950px'}
				>
					<MaintenanceRequest job_id={jobId} />
				</Modal>
			)}
		</div>
	);
};

export default InvoiceTable;
