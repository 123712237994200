import React from "react";
import moment from "moment";
import { Button,DatePicker,Input } from "antd";
import WorkOrderContext from "../WorkOrderContext";
import axios from "axios";
const cancelToken = axios.CancelToken.source();

const format = "hh:mm a";
const {TimePicker} = DatePicker;

class Action extends React.Component {
  static contextType = WorkOrderContext;
  constructor(props) {
    super(props);
    this.state = {
      action_time:moment(),
      action_desc: "",
      errormsg: "",
    };
  }


  componentDidMount = () => {
    if(this.context.actionData){
      const {action_time,action_desc} = this.context.actionData;
      this.setState({action_time:action_time, action_desc:action_desc});
    }
  }

  setTime = (e) => {
    this.setState({ action_time: e });
  }
  setDescription = (e) => {
    this.setState({ action_desc: e });
  }

  verify() {
    if (this.state.action_desc === "") {
      this.setState({ errormsg: "Please enter the action taken on this job." });
      return false;
    }
    return true;
  }

  actionNextClick = () => {
    if (this.verify()) {
      axios.post("/workOrder/save/action",{action_time:this.state.action_time,action_desc:this.state.action_desc})
      .then( () => {
        this.context.updateActionData(this.state.action_time,this.state.action_desc);
        this.context.goToMainMenu();
      })
      .catch( (err) => {
        console.log(err);
      })
    }
  }

  render() {
    return (
      <div className='action'>
        <label htmlFor='detailsInput'>
          Details on the action taken
          <Input.TextArea
            rows='5'
            cols='50'
            className='mb-4'
            defaultValue={this.state.action_desc}
            onChange={(e) =>
              this.setDescription(e.target.value)
            }
          />
        </label>

        <label htmlFor='timeinput' className='mr-4 mb-2'>Time of Action:</label>
        <TimePicker id="timeinput" use12Hours format={format} value={this.state.action_time} onChange={this.setTime} />

        <br />
        <div className='errorMsg text-center'>{this.state.errormsg}</div>
        <div className='text-right'>
          <Button type='primary' onClick={this.actionNextClick}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default Action;
