import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, message, Select, Button, Menu, Dropdown, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';

import { Modal, Form, Col } from 'react-bootstrap';
import moment from 'moment';

import LoadScreen from '../../../Components/LoadScreen';
import { UserStateContext } from '../../../Context/UserContext';

import './company.css';
import {
	PlusCircleOutlined,
	EditOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import EditableLogCell from './EditableLogCells';
import CompanyListing from './CompanyListing';
import CompanyActions from './CompanyActions';
import AddEmployee from './AddEmployee';
import CompanyInfo from './CompanyInfo';
import ChangeRoleModal from './ChangeRoleModal';
const cancelToken = axios.CancelToken.source();
const { Option } = Select;

class CompanyPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedEmployee: null,
			changeRoleModal: false,
			addAdclicked: false,
			workLogsModal: false,
			employees: [],
			deactivatedEmployees: [],
			filters: {
				active: {
					0: false,
					1: true
				}
			}
		};
	}
	static contextType = UserStateContext;

	componentDidMount = () => {
		axios
			.get('/mycompany')
			.then(response => {
				this.setState({
					company: response.data.company,
					employees: response.data.people
				});
			})
			.catch(err => {
				console.log(err);
			});
		this.getDeactivatedEmployee();
	};

	// openAddservicead = () => {
	// 	this.setState({ addAdclicked: true });
	// };
	// hideaddAd() {
	// 	this.setState({ addAdclicked: false });
	// }

	removeEmployeeFromList = id => {
		let index = this.state.employees.findIndex(e => e.id === id);
		if (index === -1) return;

		let temp = this.state.employees;
		temp.splice(index, 1);

		this.setState({ employees: temp });
	};
	removeEmployee = employee => {
		axios
			.post('/removeEmployee', { employee_id: employee.id })
			.then(response => {
				this.removeEmployeeFromList(employee.id);
				message.success(
					employee.fname + ' ' + employee.lname + ' has been removed.'
				);
			});
	};

	getDeactivatedEmployee = () => {
		axios.get('/get/deactivatedEmployee').then(response => {
			// console.log(response.data.people);
			this.setState({
				// company: response.data.company,
				deactivatedEmployees: response.data.people
			});
		});
	};

	openChangeRoleModal = () =>
		this.setState({ changeRoleModal: !this.state.changeRoleModal });

	changeRole = employee => {
		this.setState({ selectedEmployee: employee });
		this.openChangeRoleModal();
	};

	hideWorkLogs = () => {
		this.setState({ workLogsModal: !this.state.workLogsModal });
	};

	addEmployees = empList => {
		let employees = this.state.employees;
		employees = employees.concat(empList);
		this.setState({ employees: employees });
	};
	submitChangeRole = newRole => {
		axios
			.post('/changeEmployeeRole', {
				user_id: this.state.selectedEmployee.id,
				company_id: this.state.selectedEmployee.company_id,
				newRole: newRole
			})
			.then(() => {
				let updatedPerson = this.state.employees.findIndex(
					e => e.id === this.state.selectedEmployee.id
				);
				let temp = this.state.employees;
				temp[updatedPerson].role = newRole;
				this.setState({
					changeRoleModal: false,
					employees: temp,
					selectedEmployee: null
				});

				message.success('Role has successfully been changed');
			});
	};

	render() {
		// console.log(this.state.company);
		if (this.state.company === undefined)
			return <LoadScreen className='m-auto' />;
		else if (this.state.company === null) return <div>No Profile Found</div>;
		return (
			<div id='companyShell' className='pb-4'>
				<div className='top-company'>
					<CompanyInfo company={this.state.company} />

					<CompanyActions
						employees={this.state.employees}
						removeEmployee={this.removeEmployee}
					/>
				</div>
				<Card
					title={
						<div className=' fs-14 g-text'>
							<>
								Employees
								{this.context.user.role === 'Branch Manager' &&
								this.context.user.type === 'Manager' ? (
									<AddEmployee addEmployees={this.addEmployees} />
								) : (
									''
								)}
							</>
							<div style={{ marginLeft: '350px', marginTop: '-25px' }}>
								<Dropdown
									trigger='click'
									placement='bottomCenter'
									overlay={
										<Menu style={{ padding: '5px' }}>
											<Menu.Item style={{ cursor: 'default' }} disabled key={1}>
												<Checkbox
													checked={this.state.filters.active[1]}
													onChange={e => {
														let newFilters = { ...this.state.filters };
														newFilters.active[1] = e.target.checked;
														this.setState({ filters: newFilters });
													}}
												>
													Active
												</Checkbox>
											</Menu.Item>
											<Menu.Item style={{ cursor: 'default' }} disabled key={2}>
												<Checkbox
													checked={this.state.filters.active[0]}
													onChange={e => {
														let newFilters = { ...this.state.filters };
														newFilters.active[0] = e.target.checked;
														this.setState({ filters: newFilters });
													}}
												>
													Inactive
												</Checkbox>
											</Menu.Item>
										</Menu>
									}
								>
									<Button>
										Status
										<DownOutlined />
									</Button>
								</Dropdown>
							</div>
						</div>
					}
					bordered={false}
					className={'cardShell peopleShell'}
				>
					{this.state.filters.active[1]
						? this.state.employees.map((person, index) => (
								<CompanyListing
									key={person.id}
									person={person}
									changeRole={this.changeRole}
									removeEmployeeFromList={this.removeEmployeeFromList.bind(
										this
									)}
									showActions={
										this.context.user.role === 'Branch Manager' &&
										person.id !== this.context.user.id
									}
								/>
						  ))
						: ''}
					{this.state.filters.active[0]
						? this.state.deactivatedEmployees.map((person, index) => (
								<CompanyListing key={person.id} person={person} />
						  ))
						: ''}
				</Card>

				<Modal
					show={this.state.changeRoleModal}
					onHide={this.openChangeRoleModal}
					size='lg'
					aria-labelledby='add-employee-modal'
					centered
				>
					{!this.state.changeRoleModal ? (
						''
					) : (
						<ChangeRoleModal
							changeEmployeeRole={this.submitChangeRole}
							onHide={this.openChangeRoleModal}
							employee={this.state.selectedEmployee}
						/>
					)}
				</Modal>
			</div>
		);
	}
}
export default CompanyPage;
