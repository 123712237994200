const requestStatus = [
  "Job Request",
  "Scheduling",
  "Pending Cost Approval",
  "Work Order - Scheduled",
  "Work Order - Troubleshoot",
  "Work Order - Work Done",
  "Manage Work Order",
  "Work Order - Review",
  "Invoicing",
  "In Market",
  "Pending Extension",
  "On Hold",
  "Rejected",
  "Cancelled",
  "Complete",
];

export default requestStatus;
