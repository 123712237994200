const provinceCertificateRequirements = [
  {
    province: "Alberta",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 3 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 1 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 0 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 3 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "British Columbia",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 1 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 2 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 0 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 0 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "Refrigeration & AC", requirements: 2 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Manitoba",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 1 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 0 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "New Brunswick",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 3 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 1 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 3 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 1 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Newfoundland and Labrador",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 3 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 0 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 0 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Northwest Territories",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 2 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 2 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 2 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 0 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 0 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Nova Scotia",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 3 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 1 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 1 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 3 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Nunavut",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 0 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 0 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 0 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Ontario",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 1 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 3 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 3 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Prince Edward Island",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 1 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 0 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 1 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 1 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 0 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Quebec",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 3 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 3 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 3 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 3 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 3 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 3 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Saskatchewan",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 1 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 1 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 3 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 3 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 3 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 3 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
  {
    province: "Yukon",
    services: [
      { service: "Access & Security", requirements: 0 },
      { service: "Appliance Repair", requirements: 0 },
      { service: "Brick Layer", requirements: 0 },
      { service: "Building Supplies", requirements: 0 },
      { service: "Cabling And Data", requirements: 0 },
      { service: "Cabinet Maker", requirements: 0 },
      { service: "Carpenter", requirements: 0 },
      { service: "Cleaning", requirements: 0 },
      { service: "Electrical", requirements: 1 },
      { service: "Environmental", requirements: 0 },
      { service: "Fire Protection", requirements: 0 },
      { service: "Flooring", requirements: 0 },
      { service: "Gas Fitter", requirements: 1 },
      { service: "General Repair", requirements: 0 },
      { service: "Glass Wholesale", requirements: 0 },
      { service: "Heating", requirements: 0 },
      { service: "Home Inspector", requirements: 0 },
      { service: "Hot Tub Service", requirements: 0 },
      { service: "HVAC", requirements: 0 },
      { service: "landscaping", requirements: 0 },
      { service: "lock smith", requirements: 0 },
      { service: "Miscellaneaous supply", requirements: 0 },
      { service: "oil Burner Mechanic", requirements: 1 },
      { service: "paint supply", requirements: 0 },
      { service: "Pest control", requirements: 0 },
      { service: "plaster & Paint service", requirements: 0 },
      { service: "plumbing", requirements: 0 },
      { service: "plumbing supply", requirements: 0 },
      { service: "propane service", requirements: 0 },
      { service: "property Management", requirements: 0 },
      { service: "Property restoration", requirements: 0 },
      { service: "refrigeration & ac", requirements: 0 },
      { service: "roofing", requirements: 0 },
      { service: "sheet Metal Worker", requirements: 0 },
      { service: "snow Clearing", requirements: 0 },
      { service: "tile Setter", requirements: 0 },
      { service: "ventilation", requirements: 0 },
      { service: "waste management", requirements: 0 },
      { service: "windows & doors", requirements: 0 },
    ],
  },
];

export default provinceCertificateRequirements;
