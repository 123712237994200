import React from 'react';
import { Form } from 'react-bootstrap';
import { InputNumber, Checkbox, Button, message, Alert } from 'antd';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import { vendorProvinces } from '../../StringData/VendorObjects';
import RequestContext from '../MaintenanceRequest/RequestContext';

const cancelToken = axios.CancelToken.source();

class AddNewProperty extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state = { provinceList: [] };
	}
	componentDidMount = () => {
		axios.post('/getProvinces').then(res => {
			console.log(res.data.provinces);
			this.setState({ provinceList: res.data.provinces });
		});
	};

	closeError = () => this.setState({ errorMessage: null });

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleChangeSelect = e => {
		this.setState({ [e.target.name]: e.persist() });
	};
	handleChangeType = e => {
		this.resetPropData('type', e.persist());
		this.setState({ type: e.persist() });
	};
	submitForm = () => {
		this.setState({ submitted: true });

		axios
			.post('/addProperty', {
				propertyNumber: this.state.propertyNumber,
				propertyStreet: this.state.propertyStreet,
				propertyCity: this.state.propertyCity,
				propertyProvince: this.state.propertyProvince,
				propertyPostalcode: this.state.propertyPostalcode,
				propertyType: this.state.propertyType,
				totalUnits: this.state.totalUnits,
				propertyName:
					this.state.propertyNumber + ' ' + this.state.propertyStreet
			})
			.then(response => {
				this.setState({ submitted: true });
				window.location.reload();
			});
	};

	render() {
		// let disabled = (this.state.homeStreet === '' || this.state.homeNumber === '' || this.state.city === '' || this.state.postalCode === '' || this.state.fname === '')
		if (this.state.submitted) {
			return <h3>New property successfully added! </h3>;
		}
		return (
			<div>
				<Form>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Property Number </Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='propertyNumber'
								type='text'
								value={this.state.propertyNumber}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Property Street </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='propertyStreet'
								type='text'
								value={this.state.propertyStreet}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>Property City </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='propertyCity'
								id='lastName'
								type='text'
								value={this.state.propertyCity}
								onChange={this.handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} sm={2} md={3}>
							<Form.Label className='form-label'>
								Property Postal Code
							</Form.Label>
							<Form.Control
								editable='true'
								required
								className='form-text'
								name='propertyPostalcode'
								type='text'
								value={this.state.propertyPostalcode}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label className='form-label'>Property Province</Form.Label>
							<select
								name='propertyProvince'
								className='form-control'
								value={this.state.propertyProvince}
								onChange={e =>
									this.setState({ propertyProvince: e.target.value })
								}
							>
								<option value={''}>Select Province</option>
								{this.state.provinceList.map(province => (
									<option
										value={province.province_shortform}
										key={province.province_id}
									>
										{province.province_name}
									</option>
								))}
							</select>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label className='form-label'>Property Type</Form.Label>
							<select
								name='propertyType'
								className='form-control'
								defaultValue={this.state.propertyType}
								onChange={e => this.setState({ propertyType: e.target.value })}
							>
								<option value='' key=''>
									Select..
								</option>
								<option value='House with apartment' key='duplex'>
									House with apartment
								</option>
								<option value='duplex' key='duplex'>
									Duplex
								</option>
								<option value='Multi-unit Residence' key='multiUnit'>
									Multi-unit residence
								</option>
								<option value='Single family' key='single'>
									Single family
								</option>
							</select>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label className='form-label'>Total Units </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='totalUnits'
								value={this.state.totalUnits}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<div className='text-right'>
						<Button
							/*disabled={disabled}*/ onClick={this.submitForm}
							type='primary'
						>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default AddNewProperty;
