import React from "react";

class GeneralTask extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let dataString = JSON.stringify(this.props);
    return <p>{dataString}</p>;
  }
}

export default GeneralTask;
