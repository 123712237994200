import React from 'react';
import BarCalendar from '../../../../Components/BarCalendar/BarCalendar';
import axios from 'axios';
const cancelToken = axios.CancelToken.source();

class EmployeeAvailabilityCalendar extends React.Component {
	constructor(props) {
		super();

		this.state = {
			taskarray: props.taskarray,
			loading: true,
			lockOn: false

			//Enter state data for the scheduling here
		};
	}
	componentDidMount() {
		document.title = 'Employee Availability';
		console.log(parseInt(this.props.userId));
		this.getAvailability(parseInt(this.props.userId));
	}
	getAvailability = userId => {
		axios.post('/get/useravailability', { userID: userId }).then(response => {
			this.setState({
				taskarray: response.data.Item ? response.data.Item.availability : [],
				loading: false
			});
			return response.data.Item ? response.data.Item.availability : [];
		});
	};

	render() {
		if (this.state.taskarray)
			return (
				<div>
					<BarCalendar
						dataSource={[
							{
								bars: this.state.taskarray
							}
						]}
						eventStyles={{
							pink: {
								start: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#fe4d97'
								}
							},
							blue: {
								start: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#4d7cfe'
								}
							},
							default: {
								start: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								middle: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								end: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								},
								single: {
									opacity: 0.65,
									backgroundColor: '#778ca2'
								}
							}
						}}
						calendarView='month'
						showHeader={true}
						showTimeBar={true}
						orientation='vertical'
						newEvent={newEvent => {
							/*this.createEvent(newEvent);*/ return newEvent;
						}}
						editEvent={newData => {
							//this.changeEvent(newData);
							return;
						}}
						clickEvent={eventData => {}}
						blockWidth={35}
						blockHeight={20}
						loading={this.state.loading}
						updateLock={e => {
							this.setState({ lockOn: e });
						}}
						lockOn={this.state.lockOn}
					/>
				</div>
			);
		else return null;
	}
}

export default EmployeeAvailabilityCalendar;
