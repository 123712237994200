import React from "react";
import { Layout } from "antd";
import BreadCrumbHeader from "./BreadCrumb";

const { Header } = Layout;
class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseNavBar: null,
    };
  }

  render() {
    return (
      <Header id="header" className="d-flex flex-row">
        <div className="nav-container">
          <img
            className="menu-collapse mr-4 ml-3"
            onClick={this.props.openSidebar}
            src={require("../../icons/menu-collapse-icon.png")}
          />
          <HeaderBreadcrumb className="currentPage fs-20">
            Maintenance / JR714
          </HeaderBreadcrumb>
        </div>
      </Header>
    );
  }
}

class HeaderBreadcrumb extends React.Component {
  render() {
    let params = this.props.location;

    return (
      <div>
        <BreadCrumbHeader />
      </div>
    );
  }
}

export default MobileHeader;
