import React, { useState } from "react";
import {Button, Card, message} from "antd";
import { Modal, Form } from "react-bootstrap";
import {LeftCircleOutlined,RightCircleOutlined } from "@ant-design/icons";

import axios from "axios";



import RequestContext from '../RequestContext'
const cancelToken = axios.CancelToken.source();

class RequestSettings extends React.Component {
    static contextType = RequestContext;
    constructor(props) {
      super();
      this.state = {
        page:"Settings"
      };
    }
    updateTitle = (title) => {
      axios
        .post("/jobRequest/update/title", {
          job_id: this.context.request.job_id,
          newTitle: title,
        })
        .then((response) => {
          this.context.updateTitle(title);
          this.context.addActivity(response.data.note, null, true);
          this.props.onHide();
          message.success("Title has been successfully changed");
        })
        .catch((err) => {
          console.log(err);
          this.context.setError(err, err.description);
        });
    };
    updateDescription = (description) => {
      axios
        .post("/jobRequest/update/description", {
          job_id: this.context.request.job_id,
          newDescription: description,
        })
        .then((response) => {
          this.context.updateDescription(description);
          this.context.addActivity(response.data.note, null, true);
          this.props.onHide();
          message.success("Description has been successfully changed");
        })
        .catch((err) => {
          console.log(err);
          this.context.setError(err, err.description);
        });
    };
    updateToOnHold = (reason) => {
      axios.post('/jobRequest/update/status/onHold',
      {
        job_id:this.context.request.job_id,
        reason:reason
      }).then( (response) => {
        this.context.updateStatus("On Hold");
        this.context.addActivity(response.data.note,null,true);
        this.props.onHide();
        message.success("Status has been changed to On Hold");
      })
    }
  
    updateToCancelled = (reason) => {
      axios.post('/jobRequest/update/status/cancelled',
      {
        job_id:this.context.request.job_id,
        reason:reason
      }).then( (response) => {
        this.context.updateStatus("Cancelled");
        this.context.addActivity(response.data.note,null,true);
        this.props.onHide();
        message.success("Job Request has been Cancelled");
      })
    }
  
    setPage = (p) => this.setState({page:p});
  
    render() {
      const {request} = this.context;
      
      if(this.state.page === 'Title'){
        return (
          <UpdateTitleModal 
            back={() => this.setPage('Settings')} 
            onHide={this.props.onHide} 
            currentTitle={request.job_title}  
            updateTitle = {this.updateTitle}
          />
        )
      }
      else if(this.state.page === 'Description'){
        return (
          <UpdateDescriptionModal 
            back={() => this.setPage('Settings')} 
            onHide={this.props.onHide} 
            currentDescription = {request.job_description}
            updateDescription = {this.updateDescription}
          />
        )
      }
      else if(this.state.page === 'OnHold'){
        return(
          <OnHoldModal 
            back={() => this.setPage('Settings')} 
            onHide={this.props.onHide} 
            updateToOnHold = {this.updateToOnHold}
          />
        )
      }
      else if(this.state.page === 'Cancel'){
        return(
          <CancelModal 
            back={() => this.setPage('Settings')} 
            onHide={this.props.onHide} 
            updateToCancelled = {this.updateToCancelled}
          />
        )
      }
      else{
        return( 
          <>
            <Modal.Header closeButton>
              Settings
            </Modal.Header>
            <Modal.Body>
              <Card onClick={() => this.setPage('Title')} className='settingsCard'>
              <div className='caption'>Change Title <RightCircleOutlined className='caption' style={{float:'right'}} /> </div>
              </Card>
              <Card onClick={() => this.setPage('Description')} className='settingsCard'>
              <div className='caption'>Change Description <RightCircleOutlined className='caption' style={{float:'right'}} /> </div>
  
              </Card>
              {this.props.requestStatus !== "On Hold" && 
              <Card onClick={() => this.setPage('OnHold')} className='settingsCard'>
                <div className='caption'>Put Request On Hold <RightCircleOutlined className='caption' style={{float:'right'}} /> </div>
              </Card>}
              <Card onClick={() => this.setPage('Cancel')} className='settingsCard'>
              <div className='caption'>Cancel Request<RightCircleOutlined className='caption' style={{float:'right'}} /> </div>
              </Card>
            </Modal.Body>
          </>
        )
      }
    }
  }
  
  const OnHoldModal = (props) => {
    const {updateToOnHold,onHide,back } = props;
  
    const [reason, setReason] = useState("");
    const [disableClick,setdisableClick] = useState(false)
    const submitOnHold = () => {
      setdisableClick(true)
      updateToOnHold(reason);
    }
      return(
      <>
      <Modal.Header closeButton>
        <div className='fs-14'><LeftCircleOutlined onClick={back} className='mr-2'/>Put Reason On Hold</div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Reason For Putting Reason On Hold</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter reason here"
              onChange={(e) => setReason(e.target.value)}
              className='fs-12'
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button className="mr-4" onClick={onHide}>
            Close
          </Button>
          <Button type="primary" onClick={submitOnHold} disabled={disableClick}>
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </>
    )
  }
  
  const CancelModal = (props) => {
    const {updateToCancelled,onHide,back } = props;
    const [disableClick,setdisableClick] = useState(false)
  
    const [reason, setReason] = useState("");
    const [confirm, setConfirm] = useState(false);
    const submitCancel = () => { 
      setdisableClick(true)
      updateToCancelled(reason); 
      
    }
    return(
      <>
      <Modal.Header closeButton>
        <div className='fs-14'><LeftCircleOutlined onClick={back} className='mr-2'/>Cancel Request</div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Reason For Cancelling Request</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter reason here"
              onChange={(e) => setReason(e.target.value)}
              className='fs-12'
            />
          </Form.Group>
            <br/>
            <Form.Check className='confirmCheck' value={confirm} onClick={() =>setConfirm(!confirm)} label={"I understand this action cannot be undone."} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button className="mr-4" onClick={onHide}>
            Close
          </Button>
          <Button danger type="primary" disabled={reason === "" || !confirm || disableClick}  onClick={submitCancel}>
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </>
    )
  }
  
  
  const UpdateTitleModal = (props) => {
    const { currentTitle, updateTitle,onHide,back } = props;
    const [newTitle, setTitle] = useState("");
    const [disableClick,setdisableClick] = useState(false)
  
    const submitTitle = () => {
      setdisableClick(true)
      updateTitle(newTitle)
    }
      return (
      <>
        <Modal.Header closeButton>
          <div className='fs-14'><LeftCircleOutlined onClick={back} className='mr-2'/>Change Request Title</div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Previous Title</Form.Label>
              <Form.Control as="input" disabled defaultValue={currentTitle} />
            </Form.Group>
            <Form.Group>
              <Form.Label>New Title</Form.Label>
              <Form.Control
                as="input"
                placeholder="Enter new title here"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button className="mr-4" onClick={onHide}>
              Close
            </Button>
            <Button type="primary" onClick={submitTitle} disabled={disableClick}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  };
  
  
  const UpdateDescriptionModal = ({
    currentDescription,
    updateDescription,
    onHide,
    back
  }) => {
    const [disableClick,setdisableClick] = useState(false)
  
    const [newDesc, setDescription] = useState("");
    const submitDescription = () => {
      setdisableClick(true)
      updateDescription(newDesc);
    }
    return (
      <>
        <Modal.Header closeButton>
          <div className='fs-14'><LeftCircleOutlined onClick={back} className='mr-2' />Change Request Description</div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Previous Description</Form.Label>
              <Form.Control
                as="textarea"
                disabled
                defaultValue={currentDescription}
                className='fs-12'
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>New Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter new description here"
                className='fs-12'
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button className="mr-4" onClick={onHide}>
              Close
            </Button>
            <Button type="primary" onClick={submitDescription} disabled={disableClick}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  };


export default RequestSettings;