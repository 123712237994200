import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import "./login.css";
import axios from "axios";

import LoginLayout from "./LoginLayout";
const cancelToken = axios.CancelToken.source();
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      page: "",
      errorMsg: "",
    };
  }
  validateEmail = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  };
  resetPassword = () => {
    if (!this.validateEmail())
      return this.setState({ errorMsg: "EMAIL_INVALID" });
    axios
      .post("/auth/sendResetPasswordCode", { email: this.state.email })
      .then((response) => {
        if (response.data.ok) this.setState({ page: "Complete" });
        else this.setState({ errorMsg: response.data.errorMsg });
      })
      .catch((err) => console.log(err));
  };

  setEmail = (e) => this.setState({ email: e });
  render() {
    if (this.state.page === "Complete")
      return (
        <LoginLayout header="Reset Your Password?">
          <Result
            className="flex-60 padding0"
            status="success"
            title={<h4>A password reset link has been sent to your email.</h4>}
            extra={[
              <Button type="primary">
                <Link to="/">Back to Landing Page</Link>
              </Button>,
            ]}
          />
        </LoginLayout>
      );
    else {
      let errorMsg = "";
      switch (this.state.errorMsg) {
        case "":
          break;
        case "EMAIL_NOT_FOUND":
          errorMsg = (
            <>
              There seems to be no account connected to this email. Please try
              again or <Link to="/register">create an account</Link>
            </>
          );
          break;
        case "EMAIL_INVALID":
          errorMsg = "Please enter a valid email address.";
          break;
        case "INTERNAL_SERVICE_ERROR":
          errorMsg =
            "Our server seems to be having difficulties. Please try again later.";
          break;
        case "EMAIL_NOT_SENT":
          errorMsg =
            "There was a problem senting the email. Please try again later.";
          break;
        default:
          errorMsg = "";
          break;
      }
      console.log(errorMsg);
      return (
        <LoginLayout header="Reset Your Password">
          <Form className="flex-60">
            <Form.Group>
              <Form.Label>
                Enter the email associated with your account
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                value={this.state.email}
                placeholder="Enter your email"
                onChange={(e) => this.setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              type="primary"
              className="signInBtn"
              onClick={this.resetPassword}
            >
              Request Password Reset
            </Button>
            <div className="errorMessage mt-4">{errorMsg}</div>
          </Form>

          <Link to="/login" className="flex-40">
            Back to Login
          </Link>
        </LoginLayout>
      );
    }
  }
}

export default ForgotPassword;
