import React from 'react';
// import "antd/dist/antd.css";
import { Layout, Menu, Modal, Button } from 'antd';
import Room from './Components/Room';
import { Upload, message } from 'antd';
import {
	LoadingOutlined,
	PlusOutlined,
	QuestionCircleOutlined
} from '@ant-design/icons';
import axios from 'axios';
const cancelToken = axios.CancelToken.source();

const { Content, Sider } = Layout;

let preMade = {};

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}
function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

class PropertyDetails extends React.Component {
	constructor(props) {
		super(props);
		this.addNewRoom = this.addNewRoom.bind(this);
		this.afterOk = this.afterOk.bind(this);
		this.afterCancel = this.afterCancel.bind(this);
		this.nameChange = this.nameChange.bind(this);
		this.addNewMenu = this.addNewMenu.bind(this);
		this.selectedMenu = this.selectedMenu.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.form = this.form.bind(this);
		this.deleteRoom = this.deleteRoom.bind(this);
		this.deleteMenu = this.deleteMenu.bind(this);
		this.getMenus = this.getMenus.bind(this);
		this.saveAll = this.saveAll.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.retrieve = this.retrieve.bind(this);
		this.afterSave = this.afterSave.bind(this);
		this.afterCancelSave = this.afterCancelSave.bind(this);
		let url = this.props.location.pathname;
		let names = url.split('/');

		this.state = {
			rooms: { 0: { name: 'Exterior', room: '' } },
			modalVisible: false,
			name: '',
			applianceMenus: {},
			selectedMenu: '0',
			loading: false,
			pId: parseInt(names[2]),
			uId: parseInt(names[3]),
			image: '',
			newUpload: false,
			saveModal: false
		};
	}

	async componentDidMount() {
		await this.retrieve();
	}

	async retrieve() {
		axios({
			method: 'post',
			url: '/propertyDetails/getDetails',
			data: { pID: this.state.pId, uID: this.state.uId }
		}).then(
			response => {
				preMade = response.data;
				if (Object.keys(preMade).length > 0) {
					Object.keys(preMade).map(key => {
						let deleteAllowed = false;
						key === 'Exterior0'
							? (deleteAllowed = false)
							: (deleteAllowed = true);
						let name = key.slice(0, -1);
						if (preMade[key]['Room Structure'] === undefined) {
							preMade[key]['Room Structure'] = {};
						}
						this.state.rooms[key.charAt(key.length - 1)] = {
							name: name,
							room: (
								<Room
									featureLists={preMade[key]}
									getMenus={this.getMenus}
									deleteMenu={this.deleteMenu}
									name={name}
									deleteAllowed={deleteAllowed}
									delete={this.deleteRoom}
									listId={key.charAt(key.length - 1)}
									menu={this.addNewMenu}
									selected={this.selectedMenu}
								/>
							)
						};
					});
				} else {
					this.state.rooms[0] = {
						name: 'Exterior',
						room: (
							<Room
								featureLists={{ 'Room Structure': {}, Doors: {} }}
								getMenus={this.getMenus}
								deleteMenu={this.deleteMenu}
								name={'Exterior'}
								deleteAllowed={false}
								delete={this.deleteRoom}
								listId={0}
								menu={this.addNewMenu}
								selected={this.selectedMenu}
							/>
						)
					};
				}
				this.setState({});
			},
			error => {
				console.log(error);
			}
		);
	}

	addNewRoom() {
		this.setState({ modalVisible: true });
	}

	addNewMenu(key, menu) {
		/// USED IN FeatureList.js to update applianceMenus and keep track of all the forms
		if (menu.featureType === 'Doors') {
			menu['key'] = '';
		}
		this.state.applianceMenus[key] = menu;
		this.setState({ applianceMenus: this.state.applianceMenus });
	}

	selectedMenu(key) {
		if (key !== this.state.selectedMenu || this.state.newUpload) {
			this.state.selectedMenu = key;
			this.setState({ selectedMenu: key, image: '', newUpload: false });
			let imageKey = {
				imageKey: this.state.applianceMenus[this.state.selectedMenu]['imageUrl']
			};
			axios({
				method: 'post',
				url: '/propertyDetails/getImage',
				data: imageKey
			}).then(
				response => {
					if (response.data !== this.state.image) {
						this.setState({
							image: response.data,
							loading: false
						});
					}
				},
				error => {
					console.log(error);
				}
			);
		}
	}

	getMenus() {
		return this.state.applianceMenus;
	}

	deleteMenu(key) {
		delete this.state.applianceMenus[key];
		this.setState({ selectedMenu: '0' });
	}

	afterOk() {
		if (this.state.name.length !== 0) {
			let latestKey = Object.keys(this.state.rooms)[
				Object.keys(this.state.rooms).length - 1
			];
			let newKey = parseInt(latestKey) + 1;
			let d = new Date();
			this.state.rooms[newKey] = {
				name: this.state.name,
				room: (
					<Room
						featureLists={{ 'Room Structure': {} }}
						getMenus={this.getMenus}
						deleteMenu={this.deleteMenu}
						deleteAllowed={true}
						name={this.state.name}
						delete={this.deleteRoom}
						listId={newKey}
						menu={this.addNewMenu}
						selected={this.selectedMenu}
					/>
				)
			};
			this.setState({ rooms: this.state.rooms, modalVisible: false, name: '' });
		} else {
			this.afterCancel();
		}
	}

	afterCancel() {
		this.setState({ modalVisible: false, name: '' });
	}

	afterSave() {
		this.setState({ saveModal: false });
		this.saveAll();
	}

	afterCancelSave() {
		this.setState({ saveModal: false });
	}

	nameChange(event) {
		this.setState({ name: event.target.value });
	}

	handleChange(event) {
		let form = this.state.applianceMenus[this.state.selectedMenu];
		form[event.target.name] = event.target.value;
		this.state.applianceMenus[this.state.selectedMenu] = form;
		this.setState({ applianceMenus: this.state.applianceMenus });
	}

	deleteRoom(listId) {
		delete this.state.rooms[listId];
		let arr = this.state.rooms;
		this.setState({ rooms: arr });
	}

	saveAll() {
		let rooms = {};
		let finalList = {};
		Object.keys(this.state.applianceMenus).map(key => {
			let roomAndId =
				this.state.applianceMenus[key].room +
				this.state.applianceMenus[key].roomId;
			if (rooms[roomAndId] === undefined) {
				rooms[roomAndId] = {};
			}
			rooms[roomAndId][key] = {
				featureList: this.state.applianceMenus[key].featureType,
				feature: this.state.applianceMenus[key].name
			};
			return 0;
		});

		Object.keys(rooms).map(room => {
			Object.keys(rooms[room]).map(feature => {
				let name = this.state.applianceMenus[feature].name;
				let featureListName = rooms[room][feature].featureList;
				if (finalList[room] === undefined) {
					finalList[room] = {};
				}
				if (finalList[room][featureListName] === undefined) {
					finalList[room][featureListName] = {};
				}
				finalList[room][featureListName][name] =
					this.state.applianceMenus[feature];
				return 0;
			});
			return 0;
		});
		let data = { pID: this.state.pId, uID: this.state.uId, body: finalList };
		axios({
			method: 'post',
			url: '/propertyDetails/uploadDetails',
			data: data
		}).then(
			() => {},
			error => {
				console.log(error);
			}
		);
	}

	handleUpload = info => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl => {
				this.setState({
					loading: false
				});
			});
		}
	};
	customRequest = options => {
		const data = new FormData();
		data.append('file', options.file);
		const config = {
			headers: {
				'content-type': 'multipart/form-data;'
			}
		};
		axios
			.post(options.action, data, config)
			.then(res => {
				options.onSuccess(res.data, options.file);
				this.state.applianceMenus[this.state.selectedMenu]['imageUrl'] =
					res.data;
				this.state.newUpload = true;
				this.setState({});
				this.selectedMenu(this.state.selectedMenu);
				this.saveAll();
			})
			.catch(err => {
				console.log(err);
			});
	};

	form() {
		if (this.state.selectedMenu === '0') {
			return (
				<div>
					<p>Welcome to the property details editor!</p>

					<p>Here you will be able to upload details of your unit.</p>

					<p>
						You can click the circular + button to create a new room, a new
						window will pop-up and allow you to name the room. You will start
						with a room for the Exterior of the unit.
					</p>

					<p>
						Each room will be constructed using Feature Lists. Feature Lists are
						lists of features about the room, for example, you might have a
						Feature List for Appliances, which will be a list of appliances that
						come with the room.
					</p>

					<p>
						Once you have constucted a Feature List, you can use the &quot;+New
						...&quot; button to add a new feature. This will construct a new
						feature, in which you can upload pictures, and give information
						about.
					</p>

					<p>
						Once you are finished, you can save your progress using the
						&quot;SAVE ALL&quot; button.
					</p>
				</div>
			);
		} else {
			const uploadButton = (
				<div>
					{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
					<div className='ant-upload-text'>Upload</div>
				</div>
			);
			return (
				<div>
					<form>
						<label>
							Name:{' '}
							<b>{this.state.applianceMenus[this.state.selectedMenu].name}</b>
						</label>
						<br />
						<br />
						Feel free to upload a picture
						{this.state.applianceMenus[this.state.selectedMenu].featureType ===
						'Doors'
							? ' of your door key!'
							: '!'}
						<Upload
							name='file'
							listType='picture-card'
							className='avatar-uploader'
							showUploadList={false}
							action='/propertyDetails/uploadImage'
							customRequest={this.customRequest}
							beforeUpload={beforeUpload}
							onChange={this.handleUpload}
						>
							{this.state.applianceMenus[this.state.selectedMenu][
								'imageUrl'
							] !== '' ? (
								<img
									src={this.state.image}
									alt='menu'
									style={{ width: '100%' }}
								/>
							) : (
								uploadButton
							)}
						</Upload>
						<br />
						<br />
						<label>Fill out all applicable fields!</label>
						<br />
						{this.state.applianceMenus[this.state.selectedMenu].featureType ===
						'Doors' ? (
							<div>
								<label>
									Door Key Info:
									<input
										name='key'
										value={
											this.state.applianceMenus[this.state.selectedMenu].key
										}
										onChange={this.handleChange}
									/>
								</label>
								<br />
							</div>
						) : (
							<></>
						)}
						<label>
							Date(DD/MM/YYYY):
							<input
								name='date'
								value={this.state.applianceMenus[this.state.selectedMenu].date}
								onChange={this.handleChange}
							/>
						</label>
						<br />
						<label>
							Brand:
							<input
								name='brand'
								value={this.state.applianceMenus[this.state.selectedMenu].brand}
								onChange={this.handleChange}
							/>
						</label>
						<br />
						<label>
							Make:
							<input
								name='make'
								value={this.state.applianceMenus[this.state.selectedMenu].make}
								onChange={this.handleChange}
							/>
						</label>
						<br />
						<label>
							Model:
							<input
								name='model'
								value={this.state.applianceMenus[this.state.selectedMenu].model}
								onChange={this.handleChange}
							/>
						</label>
						<br />
						<label>
							Serial Number:
							<input
								name='serialNum'
								value={
									this.state.applianceMenus[this.state.selectedMenu].serialNum
								}
								onChange={this.handleChange}
							/>
						</label>
						<br />
						<label>
							Age(Years):
							<input
								name='age'
								value={this.state.applianceMenus[this.state.selectedMenu].age}
								onChange={this.handleChange}
								maxLength='2'
							/>
						</label>
						<br />
						<label>
							Width:
							<input
								name='width'
								value={this.state.applianceMenus[this.state.selectedMenu].width}
								onChange={this.handleChange}
								maxLength='4'
							/>
							<select
								name='widthMeasurement'
								value={
									this.state.applianceMenus[this.state.selectedMenu]
										.widthMeasurement
								}
								id='widthMeasurement'
								onChange={this.handleChange}
							>
								<option value='' hidden={true}></option>
								<option value='ft'>ft</option>
								<option value='m'>m</option>
								<option value='in'>in</option>
								<option value='cm'>cm</option>
							</select>
						</label>
						<br />
						<label>
							Height:
							<input
								name='height'
								value={
									this.state.applianceMenus[this.state.selectedMenu].height
								}
								onChange={this.handleChange}
								maxLength='4'
							/>
							<select
								name='heightMeasurement'
								value={
									this.state.applianceMenus[this.state.selectedMenu]
										.heightMeasurement
								}
								id='heightMeasurement'
								onChange={this.handleChange}
							>
								<option value='' hidden={true}></option>
								<option value='ft'>ft</option>
								<option value='m'>m</option>
								<option value='in'>in</option>
								<option value='cm'>cm</option>
							</select>
						</label>
						<br />
					</form>
				</div>
			);
		}
	}

	render() {
		return (
			<Layout>
				<Sider width='25%' className='site-layout-background'>
					<Menu
						mode='inline'
						style={{ height: '100%', borderRight: '0' }}
						forceSubMenuRender={true}
					>
						<div>
							<Button
								type='text'
								block={true}
								style={{ textAlign: 'left', fontSize: 14, color: 'DodgerBlue' }}
								onClick={() => this.setState({ selectedMenu: '0' })}
							>
								<QuestionCircleOutlined /> Info
							</Button>
						</div>
						{Object.keys(this.state.rooms).map(room => (
							<div key={this.state.rooms[room].name + room}>
								{this.state.rooms[room].room}
							</div>
						))}
					</Menu>
					<Content
						style={{
							background: 'white',
							margin: '0'
						}}
					>
						<div style={{ textAlign: 'center' }}>
							<Button
								shape='circle'
								style={{
									display: 'inline-block',
									margin: '',
									color: 'black'
								}}
								onClick={this.addNewRoom}
							>
								+
							</Button>
						</div>
						<div>
							<Button
								type='text'
								block={true}
								style={{ textAlign: 'left', fontSize: 14, color: 'black' }}
								onClick={() => this.setState({ saveModal: true })}
							>
								SAVE ALL
							</Button>
						</div>
					</Content>
				</Sider>
				<Modal
					title='Room Details'
					visible={this.state.modalVisible}
					onOk={this.afterOk}
					onCancel={this.afterCancel}
				>
					<label>
						Name of Room:
						<input
							name='nameOfRoom'
							type='text'
							value={this.state.name}
							onChange={this.nameChange}
						/>
					</label>
				</Modal>
				<Modal
					title='Save'
					visible={this.state.saveModal}
					onOk={this.afterSave}
					onCancel={this.afterCancelSave}
				>
					Save Progress?
				</Modal>
				<Sider width='30'></Sider>

				<Sider
					className='site-layout-background'
					width='50%'
					style={{
						padding: 10,
						backgroundColor: 'white'
					}}
				>
					{this.form()}
				</Sider>
			</Layout>
		);
	}
}

export default PropertyDetails;
