import SelectAccount from "../Pages/Login/SelectAccount";
import SetPassword from "../Pages/Login/SetPassword";
import PropertyManagement from "../PropertyManagement/PropertyManagement";
import LandingLayout from "../PropertyManagement/LandingLayout";
import LoginPage from "../Pages/Login/Login";
import SignupPage from "../Pages/Login/Signup/Signup";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import ResetPassword from "../Pages/Login/ResetPassword";
import Logout from "../Pages/Login/Logout";
import VerifyAccount from '../Pages/Login/VerifyAccount';



export default [
  {
    path: "/",
    name: "Steady Property Management",
    Component: LandingLayout,
    exact: false,
  },
  {
    path: "/propertyManagement",
    name: "Steady Property Management",
    Component: LandingLayout,
    exact: false,
  },
  {
    path: "/rentals",
    name: "Rentals",
    Component: LandingLayout,
    exact: false,
  },

  { path: "/login", name: "Login", Component: LoginPage, exact: true },
  {
    path: "/selectaccount",
    name: "Select Account",
    Component: SelectAccount,
    exact: true,
  },
  {
    path: "/setpassword",
    name: "Set Password",
    Component: SetPassword,
    exact: true,
  },
  {
    path: "/recoverpassword",
    name: "Recover Password",
    Component: ForgotPassword,
    exact: true,
  },
  { path: "/signup", name: "Sign Up", Component: SignupPage, exact: true },
  {
    path: "/resetpassword/:user_id/:token",
    name: "Reset Password",
    Component: ResetPassword,
    exact: true,
  },
  {
    path: "/logout",
    name: "Logout",
    Component: Logout,
    exact: true,
  },
  {
    path: "/verifyAccount/:id/:code",
    name: "Verify Account",
    Component: VerifyAccount,
    exact: true,
  }

];
