import React from "react";
import findByType from "./findByType";
import "./tasks.css";
import { Button } from "antd";
const Title = () => null;
Title.displayName = "Title";

const Description = () => null;
Description.displayName = "Description";

const Action = (primary = false, onClick = function () {}) => null;
Action.displayName = "Action";

const StartTime = () => null;
const EndTime = () => null;
const Person = () => null;

class Task extends React.Component {
  renderTitle() {
    const { children } = this.props;
    // First we try to find the Title sub-component among the children of Article
    const title = findByType(children, Title);
    // If we don’t find any we return null
    if (!title) {
      return null;
    }
    // Else we return the children of the Title sub-component as wanted
    return <>{title.props.children}</>;
  }
  renderAction() {
    const { children } = this.props;
    var actions = [];
    actions = findByType(children, Action);

    if (!actions.length === 0 || actions === null) {
      return null;
    }
    return actions.map((action, index) => (
      <Button
        key={index}
        onClick={action.props.onClick}
        disabled={this.props.complete || this.props.unqualified}
        danger={action.props.danger}
        type={action.props.primary === true ? "primary" : ""}
        className={
          "mr-2 " +
          (action.props.className === null ? "" : action.props.className)
        }
      >
        {action.props.children}
      </Button>
    ));
  }

  render() {
    const { complete, unqualified } = this.props;
    return (
      <div
        className={complete ? "taskDone taskBody" : "taskBody"}
      >
        <div className={"taskTitle"}>{this.renderTitle()}</div>
        <div className="taskActions">{this.renderAction()}</div>
      </div>
    );
  }
}
Task.Title = Title;
Task.Description = Description;
Task.Action = Action;
Task.StartTime = StartTime;
Task.EndTime = EndTime;
Task.Person = Person;

export default Task;
