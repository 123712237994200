import React from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

import moment from 'moment';

import { DatePicker, Space, Button } from "antd";
import "./taskStyle.css";
const cancelToken = axios.CancelToken.source();
/*
function PickerWithType({ type, onChange }) {
  if (type === "date") return <DatePicker onChange={onChange} />;
  return <DatePicker picker="date" onChange={onChange} />;
}

function SwitchablePicker() {
  const [type, setType] = React.useState("time");
  const onChange = (value) => {
    console.log(value);
  };
  return (
    <Space>
      { <TypeSelect type={type} onChange={setType} /> }
      { <PickerWithType type={type} onChange={onChange} />}
    </Space>
  );
}

*/
class InsuranceTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyNumber: "",
      dateIssued: "j",
      dateExpired: "",
      insuranceBroker: "",
      amountLiability: "",
    };
  }
  submitForm = () => {
    axios
      .post("/insuranceComplete", {
        policyNumber: this.state.policyNumber,
        dateIssued: this.state.dateIssued,
        dateExpired: this.state.dateExpired,
        insuranceBroker: this.state.insuranceBroker,
        amountLiability: this.state.amountLiability,
      })
      .then((response) => {
        //next page
      })
      .catch((err) => console.log(err));
  };

  componentDidMount(){
		axios.post("/getInsurance",{data: this.props.taskData}).then((response) => {
			if(response.data.policyNumber == null ){
        response.data.policyNumber = ""
        response.data.dateIssued = ""
        response.data.dateExp = ""
        response.data.insuranceBroker = ""
        response.data.amountLiability = ""
			}
			
			this.setState({ policyNumber: response.data.policyNumber, dateIssued: response.data.dateIssued , dateExpired: response.data.dateExp, insuranceBroker: response.data.insuranceBroker, amountLiability: response.data.amountLiability})
			
		})
	}
  render() {
    return (
      <div className="taskContainer">
        <h3 className="title">Insurance Info</h3>
        <Form action="insuranceComplete" method="post">
          <Form.Group>
            <p>
              Description: Please enter the following information to update your
              account.
            </p>
            <Form.Label className="form-label">Policy Number:</Form.Label>
            <Form.Control
              className="form-text"
              name="policyNumber"
              type="text"
              defaultValue={this.state.policyNumber}
              onChange={(e) => this.setState({ policyNumber: e.target.value })}
            />

            <Space direction="vertical" size={12}>
              <br />
              <Form.Label className="form-label">Date Issued:</Form.Label>
              <DatePicker
                className="form-datepicker task-input-box"
                name="dateIssued"
                defaultValue={moment(this.state.dateIssued)}
                onChange={(e) => this.setState({ dateIssued: e })}
              />
              <Form.Label className="form-label">Date Expired:</Form.Label>
              <DatePicker
                className="form-datepicker"
                name="dateExpired"
                value={moment(this.state.dateExpired)}
                onChange={(e) => this.setState({ dateExpired: e })}
              />
              <p>{this.state.dateExpired}</p>
              <Form.Label className="form-label">Insurance Broker:</Form.Label>
              <Form.Control
                className="form-text"
                name="insuranceBroker"
                type="text"
                defaultValue={this.state.insuranceBroker}
                onChange={(e) =>
                  this.setState({ insuranceBroker: e.target.value })
                }
              />
              <Form.Label className="form-label">
                Amount of Liability:
              </Form.Label>
              <Form.Control
                className="form-text"
                name="amountLiability"
                type="text"
                defaultValue={this.state.amountLiability}
                onChange={(e) =>
                  this.setState({ amountLiability: e.target.value })
                }
              />
            </Space>
          </Form.Group>
          <br></br>
          <Button type='primary' className="ant-btn" onClick={this.submitForm}>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default InsuranceTask;
