import React from 'react';
import {
	Card,
	Avatar,
	Button,
	message,
	Dropdown,
	Menu,
	Select,
	Table,
	Skeleton,
	DatePicker,
	TimePicker
} from 'antd';
import {
	PlusCircleOutlined,
	EditOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditableLogCell from './EditableLogCells';
import AddNewLog from './AddNewLog';

class CompanyListing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deactivateEmployeeModal: false,
			workLogsModal: false,
			logData: null,
			reasonToTerminate: '',
			terminatedDate: '',
			newLog: false
		};
	}

	getData = () => {
		axios
			.get('/workOrder/mycompany/maintenancelogs/' + this.props.person.id)
			.then(response => {
				console.log(response.data.logs);
				this.setLogData(
					response.data.logs.map(log => {
						return {
							...log,
							editTime: this.editTime,
							editDistanceExpenses: this.editDistanceExpenses
						};
					})
				);
			})
			.catch(err => {
				console.log(err);
			});
	};

	openDeactivateEmployeeModal = () => {
		this.setState({
			deactivateEmployeeModal: !this.state.deactivateEmployeeModal
		});
	};

	openWorkLogsModal = newWorkModal => {
		this.setState({ workLogsModal: newWorkModal });
	};

	setLogData = newLogData => {
		this.setState({ logData: newLogData });
	};
	openNewLog = () => {
		this.setState({ newLog: !this.state.newLog });
	};

	editTime = (
		type,
		updateTime,
		startTime,
		endTime,

		worklog_id,
		status
	) => {
		let temp = [...this.state.logData];
		return new Promise((resolve, reject) => {
			axios
				.post('/workOrder/setWorkTime/' + type + '/' + this.props.person.id, {
					// update_time: updateTime.toString(),
					start_time: startTime.toString(),
					end_time: endTime.toString(),

					worklog_id: worklog_id,
					status: status
				})
				.then(response => {
					let found = temp.findIndex(row => row.worklog_id === worklog_id);
					if (found === -1) resolve();
					if (type === 'start')
						temp[found] = {
							...temp[found],
							start_time: updateTime.format('ddd, MMM Do YYYY, [at] h:mma'),
							start_time_data: updateTime,
							total_time: response.data.total_time
						};
					else
						temp[found] = {
							...temp[found],
							end_time: updateTime.format('ddd, MMM Do YYYY, [at] h:mma'),
							end_time_data: updateTime,
							total_time: response.data.total_time
						};
					resolve(this.setLogData(temp));
				});
		});
	};

	editDistanceExpenses = (
		type,
		updateDistanceValue,
		updateExpensesValue,
		updatedDistance,
		updatedExpenses,
		startTime,
		endTime,
		worklog_id,
		status
	) => {
		let temp = [...this.state.logData];
		return new Promise((resolve, reject) => {
			axios
				.post('/workOrder/setWorkTime/' + type + '/' + this.props.person.id, {
					// update_time: updateTime.toString(),
					start_time: startTime.toString(),
					end_time: endTime.toString(),
					total_distance: updatedDistance,
					total_expenses: updatedExpenses,
					worklog_id: worklog_id,
					status: status
				})
				.then(response => {
					let found = temp.findIndex(row => row.worklog_id === worklog_id);
					if (found === -1) resolve();
					if (type === 'distance')
						temp[found] = {
							...temp[found],
							total_distance: updateDistanceValue,
							total_time: response.data.total_time
						};
					if (type === 'expenses')
						temp[found] = {
							...temp[found],
							total_expenses: updateExpensesValue,
							total_time: response.data.total_time
						};
					resolve(this.setLogData(temp));
				});
		});
	};

	handleChange = event => {
		// this.setState({homeStreet: event.target.value});
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};
	deactivateEmployee = employee => {
		axios
			.post('/deactivateEmployee', {
				termination_date: this.state.terminatedDate,
				reasons: this.state.reasonToTerminate,
				employee_id: employee.id
			})
			.then(response => {
				this.props.removeEmployeeFromList(employee.id);
				message.success(
					employee.fname + ' ' + employee.lname + ' has been deactivated.'
				);
			});
	};

	render() {
		let {
			person,
			deactivatedPerson,
			deactivateEmployee,
			changeRole,
			showActions
		} = this.props;
		return (
			<div className='companyCardContainer '>
				<div className='companyPerson'>
					<Avatar src={person.imageurl} size={38} className='mr-2' />
					<div className='cardLeft'>
						<div className='cardHeader'>
							<div className='cardTitle'>
								{person.status === 1 ? (
									<Link
										to={{
											pathname: '/user/profile',
											state: { id: person.id }
										}}
									>
										{person.fname} {person.lname}
									</Link>
								) : (
									<>
										{person.fname} {person.lname}
									</>
								)}
							</div>
						</div>
						{person.status === 1 ? (
							<div className='cardLeftFooter g-text-sm'>
								<div className='cardFooterItem'>{person.role}</div>
							</div>
						) : (
							<>
								<div className='cardLeftFooter g-text-sm'>
									<div className='cardFooterItem'>{person.role}</div>
								</div>
								<div className='cardLeftFooter g-text-sm'>
									<div className='cardFooterItem'>
										Termination Date: {person.termination_date}
									</div>
								</div>
								<div className='cardLeftFooter g-text-sm'>
									<div className='cardFooterItem'>
										Reason for termination: {person.reasons}
									</div>
								</div>
							</>
						)}
					</div>
				</div>

				<div className='companyCardEdit'>
					{showActions ? (
						<Dropdown
							overlay={
								<Menu>
									<Menu.Item onClick={this.openDeactivateEmployeeModal}>
										Deactive Employee
									</Menu.Item>

									<Menu.Item onClick={() => this.props.changeRole(person)}>
										Change Type
									</Menu.Item>
									<Menu.Item
										onClick={() => {
											this.openWorkLogsModal(true);
											this.getData();
										}}
									>
										View Logs
									</Menu.Item>
								</Menu>
							}
							trigger={['click']}
							placement='bottomLeft'
						>
							<img
								src={require('../../../icons/verticalMenuGrey.png')}
								className='verticalMenuIcon'
							/>
						</Dropdown>
					) : (
						''
					)}
				</div>
				<Modal
					show={this.state.deactivateEmployeeModal}
					onHide={this.openDeactivateEmployeeModal}
					// size='lg'
				>
					<Modal.Header>
						{`Deactivate ${person.fname} ${person.lname}?`}
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group>
								<Form.Label>Reasons:</Form.Label>
								<Form.Control
									as='textarea'
									rows={3}
									required
									type='text'
									name='reasonToTerminate'
									value={this.state.reasonToTerminate}
									onChange={this.handleChange}
								/>
							</Form.Group>
							<Form.Label>Termination Date:</Form.Label>
							<DatePicker
								style={{ marginLeft: 8 }}
								onChange={(date, dateString) => {
									this.setState({ terminatedDate: dateString });
								}}
							/>
						</Form>
					</Modal.Body>

					<Modal.Footer>
						<Button onClick={() => this.openDeactivateEmployeeModal(false)}>
							Cancel
						</Button>
						<Button
							type='primary'
							danger
							onClick={() => {
								this.deactivateEmployee(person);
								this.openDeactivateEmployeeModal(false);
							}}
						>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal
					centered
					size='lg'
					show={this.state.workLogsModal}
					onHide={() => this.openWorkLogsModal(false)}
				>
					<Modal.Body>
						<Button
							type='primary'
							style={{ marginBottom: 8 }}
							onClick={this.openNewLog}
						>
							Add New Log
						</Button>
						{this.state.logData ? (
							<Table
								columns={tableColumns}
								pagination={false}
								dataSource={this.state.logData}
							/>
						) : (
							<Table
								columns={loadingColumns}
								pagination={false}
								dataSource={[
									{
										date: <Skeleton active paragraph={false} />,
										start_time: <Skeleton active paragraph={false} />,
										end_time: <Skeleton active paragraph={false} />,
										total_time: <Skeleton active paragraph={false} />,
										notes: <Skeleton active paragraph={false} />
									}
								]}
							/>
						)}
					</Modal.Body>
				</Modal>
				<Modal
					centered
					size='sm'
					show={this.state.newLog}
					onHide={this.openNewLog}
				>
					<Modal.Header closeButton>Add Log</Modal.Header>
					<Modal.Body>
						{this.state.newLog ? (
							<AddNewLog employeeId={this.props.person.id} />
						) : (
							''
						)}
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
const loadingColumns = [
	{
		title: 'Start Time',
		dataIndex: 'start_time',
		key: 'start_time',
		width: '41%'
	},
	{
		title: 'End Time',
		dataIndex: 'end_time',
		key: 'end_time',
		width: '41%'
	},
	{
		title: 'Total Time',
		dataIndex: 'total_time',
		key: 'total_time'
	}
];

const tableColumns = [
	{
		title: 'Start Time',
		dataIndex: 'start_time',
		key: 'start_time',
		width: '50%',
		render: (cellData, rowData, index) => {
			return rowData.notes !== 'New log' ? (
				<EditableLogCell
					cellType='start'
					cellData={cellData}
					rowData={rowData}
					worklog_id={rowData.worklog_id}
					rowIndex={index}
					editTime={rowData.editTime}
				/>
			) : (
				cellData
			);
		}
	},
	{
		title: 'End Time',
		dataIndex: 'end_time',
		key: 'end_time',
		width: '60%',
		render: (cellData, rowData, index) => {
			return rowData.notes !== 'New log' ? (
				<EditableLogCell
					cellType='end'
					cellData={cellData}
					rowData={rowData}
					worklog_id={rowData.worklog_id}
					rowIndex={index}
					editTime={rowData.editTime}
				/>
			) : (
				''
			);
		}
	},
	{
		title: 'Total Time',
		dataIndex: 'total_time'
		// width: '41%'
	},

	{
		title: 'Total Distance Travelled(Kms)',
		dataIndex: 'total_distance',
		align: 'right',
		render: (cellData, rowData, index) => {
			return (
				<EditableLogCell
					cellType='distance'
					cellData={cellData}
					rowData={rowData}
					worklog_id={rowData.worklog_id}
					rowIndex={index}
					editDistanceExpenses={rowData.editDistanceExpenses}
				/>
			);
		}
	},
	{
		title: 'Total Expenses($)',
		dataIndex: 'total_expenses',
		align: 'right',
		render: (cellData, rowData, index) => {
			return (
				<EditableLogCell
					cellType='expenses'
					cellData={cellData}
					rowData={rowData}
					worklog_id={rowData.worklog_id}
					rowIndex={index}
					editDistanceExpenses={rowData.editDistanceExpenses}
				/>
			);
		}
	}
];
export default CompanyListing;
