// import { defaultOptionListAppearanceProvider } from 'pdf-lib';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';
import { Link, Redirect } from 'react-router-dom';

class CompanyActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workLogsModal: false,
			maintenanceWorkers: {}
		};
	}
	componentDidMount() {
		// axios.get('/mycompany/maintenancelogs').then(response => {
		//   this.setState({maintenanceWorkers:response.data.maintenanceWorkers})
		//   console.log(response)
		// })
	}

	hideWorkLogs = () => {
		this.setState({ workLogsModal: !this.state.workLogsModal });
	};

	render() {
		return (
			<div className='cardShell actionShell'>
				{/* <Link to='/workLog' className='nohighlight caption'>
					Work Log
				</Link> */}
				{/* <Button className='companyBtn' onClick={this.hideWorkLogs}>
					View Work Logs
				</Button> */}
				<Modal show={this.state.workLogsModal} onHide={this.hideWorkLogs}>
					<Modal.Body></Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default CompanyActions;
