import React from 'react';
import axios from 'axios';

import './transactions.css';
import { Popover, OverlayTrigger, Modal, ModalTitle } from 'react-bootstrap';
import { Button } from 'antd';
import LoadScreen from '../../Components/LoadScreen';
const cancelToken = axios.CancelToken.source();
//Add to job request & test with transaction history

class Transactions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			transactions: [],
			currentTask: 'Loading',
			currentTransaction: '',
			totalCost: 0,
			vendorList: []
		};
		this.updateTotal = this.updateTotal.bind(this);
	}
	componentDidMount() {
		this.getData().then(data => {
			let transactionArr = [],
				vendorarr = [];
			let j = 1;
			for (let i of data.data.transactionData) {
				let transaction = {
					trans_id: i.trans_id,
					wo_id: i.wo_id,
					session_id: i.session_id,
					row_id: i.row_id,
					receipt: i.receipt_id,
					amount: i.amount,
					tax: i.tax,
					comment: i.comment,
					vendor: { id: i.vendor_id, name: i.vendor_name },
					active: i.active,
					id: j,
					type: 'Old'
				};
				transactionArr.push(transaction);
				j += 1;
			}
			for (let i of data.data.vendors) {
				let vendor = { id: i.id, name: i.fname };
				vendorarr.push(vendor);
			}
			this.setState({
				transactions: transactionArr,
				vendorList: vendorarr,
				currentTask: 'Display'
			});
			this.updateTotal();

			/*$(function () {
        $('[data-toggle="popover"]').popover();
      });*/
		});
	}
	getData() {
		return new Promise((resolve, reject) => {
			axios
				.post('/transactionDetails', { job_id: this.props.job_id })
				.then(function (response) {
					resolve(response);
				});
		});
	}

	sendTransactions = type => {
		axios
			.post('/completeTransactions', {
				job_id: this.props.job_id,
				transactions: this.state.transactions,
				next: type
			})
			.then(response => {
				this.props.sendTransactions(
					response.data.newStatus,
					response.data.note
				);
			})
			.catch(err => {
				console.log(err);
			});
	};
	updateTransactions = () => {
		return; //TODO add update for jr
	};
	updateTotal = () => {
		let cost = 0;

		this.state.transactions.forEach(i => {
			if (isNaN(parseFloat(i.amount)) || i.active === 0) {
				cost += 0;
			} else cost += parseFloat(i.amount);
		});
		if (this.state.taxIncluded) cost = cost * 1.13;
		cost = cost.toFixed(2);
		this.setState({ totalCost: cost });
	};
	updateLabourCost = e => {
		this.setState({ labourCost: e.target.value }, this.updateTotal());
	};
	updateTaxes = () => {
		if (this.state.taxIncluded)
			this.setState({ taxIncluded: false }, this.updateTotal);
		else this.setState({ taxIncluded: true }, this.updateTotal);
	};
	newTransaction = () => {
		let row_ids = [];
		for (let i of this.state.transactions) {
			row_ids.push(i.id);
		}
		let newid;
		if (row_ids.length > 0) newid = Math.max.apply(Math, row_ids) + 1;
		else newid = 1;
		this.setState({
			currentTransaction: {
				trans_id: null,
				id: newid,
				receipt: '',
				vendor: { id: null, name: '' },
				amount: 0,
				tax: 'No Tax',
				comment: '',
				active: 1,
				type: 'New'
			}
		});
		this.setState({ currentTask: 'New' });
	};

	addTransaction = transaction => {
		let arr = this.state.transactions;
		let curr = arr.findIndex(x => x.id === transaction.id);
		if (curr === -1) {
			arr.push(transaction);
		} else {
			arr[curr] = transaction;
		}
		this.setState({ transactions: arr });
		this.setState({ currentTask: 'Display' });
		this.updateTotal();
	};

	editTransaction = id => {
		let curr = this.state.transactions.find(x => x.id === id);
		this.setState({ currentTransaction: curr });
		this.setState({ currentTask: 'New' });
	};
	cancelRow = id => {
		let curr = this.state.transactions.findIndex(x => x.id === id);
		if (curr === -1) {
			this.setState({ currentTask: 'Display' });
			return;
		}
		let arr = this.state.transactions;
		let trans = arr[curr];
		let act;
		if (trans.active === 1) {
			act = 0;
		} else act = 1;
		let newTrans = {
			trans_id: trans.trans_id,
			id: trans.id,
			receipt: trans.receipt,
			vendor: trans.vendor,
			amount: trans.amount,
			tax: trans.tax,
			comment: trans.comment,
			active: act,
			type: trans.type
		};
		arr[curr] = newTrans;
		this.setState({ transactions: arr });
		this.setState({ currentTask: 'Display' });
		this.updateTotal();
	};

	goBack = () => {
		this.setState({ currentTask: 'Display' });
	};
	render() {
		let torender;
		let addRecord;
		let help;

		if (this.state.currentTask === 'Loading') {
			return (
				<>
					<Modal.Header>Transactions</Modal.Header>
					<Modal.Body className='transModalBody'>
						<LoadScreen className='m-auto' />
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.props.onHide}>Cancel</Button>
					</Modal.Footer>
				</>
			);
		} else if (this.state.currentTask === 'Display') {
			torender = (
				<TransactionDisplay
					cancelRow={this.cancelRow}
					transactions={this.state.transactions}
					editTransaction={this.editTransaction}
				/>
			);
			addRecord = (
				<div className='text-right'>
					<h7 id='addMaterial' onClick={this.newTransaction}>
						Add Transaction Record
					</h7>{' '}
				</div>
			);
			if (this.state.transactions.length > 0) help = <DisplayPopover />;
			else help = <NewDisplayPopover />;
		} else if (this.state.currentTask === 'New') {
			torender = (
				<AddTransaction
					vendorList={this.state.vendorList}
					addTransaction={this.addTransaction}
					deleteTransaction={this.deleteTransaction}
					transaction={this.state.currentTransaction}
					goBack={this.goBack}
				/>
			);
			help = <EditPopover />;
		}

		return (
			<>
				<Modal.Header closeButton>
					<div className='fs-18'>Transactions</div>
				</Modal.Header>
				<Modal.Body style={{ minHeight: '400px' }}>
					{help}
					<br />
					{torender}
					<br />
					{addRecord}

					<div className='borderLine mb-2'></div>
					<br />
					<div className='fixedLine mt-2'>
						<label className='txtLabel'>Final Cost: </label>
						<label className='d-inline startUnits'>$ </label>
						<div className='txtInput totalCost'>{this.state.totalCost}</div>
						<label className='d-inline endUnits'></label>
					</div>
				</Modal.Body>
				<Modal.Footer id='btnGroup'>
					<Button onClick={() => this.sendTransactions('Continue')}>
						Save and Continue Later
					</Button>
					<Button
						type='primary'
						onClick={() => this.sendTransactions('Complete')}
					>
						Complete
					</Button>
				</Modal.Footer>
			</>
		);
	}
}

class AddTransaction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.transaction.id,
			receipt: this.props.transaction.receipt,
			amount: this.props.transaction.amount,
			vendor: this.props.transaction.vendor
				? this.props.transaction.vendor
				: { id: null, name: '' },
			comment: this.props.transaction.comment,
			tax: this.props.transaction.tax,

			other: false,
			errormsg: ''
		};
	}
	componentDidMount = () => {
		if (this.props.transaction.vendor.id === 0) {
			this.setState({ other: true });
		}
	};
	addReceipt = e => {
		this.setState({ receipt: e.target.value });
	};
	addAmount = e => {
		this.setState({ amount: e.target.value });
	};
	addVendor = e => {
		if (e.target.value !== 0) {
			let vendor = this.props.vendorList.find(
				x => x.id === parseInt(e.target.value)
			);
			this.setState({ vendor: vendor });
		} else this.setState({ other: true, vendor: { id: 0, name: '' } });
	};
	updateOtherName = e => {
		this.setState({ vendor: { id: 0, name: e.target.value } });
	};
	addComments = e => {
		this.setState({ comment: e.target.value });
	};
	done = () => {
		if (this.verify()) {
			let transaction = {
				trans_id: this.props.transaction.trans_id,
				id: this.state.id,
				receipt: this.state.receipt,
				amount: parseFloat(this.state.amount),
				tax: this.state.tax,
				vendor: this.state.vendor,
				comment: this.state.comment,
				type: this.props.transaction.type,
				active: this.props.transaction.active
			};
			this.props.addTransaction(transaction);
		}
	};
	verify = () => {
		if (this.state.receipt === '') {
			this.setState({ errormsg: 'Please enter a receipt number.' });
			return false;
		} else if (this.state.amount === '') {
			this.setState({
				errormsg: 'Please enter the receipt amount or 0 if none.'
			});
			return false;
		}
		return true;
	};
	deleteTransaction = () => {
		this.props.deleteTransaction(this.state.id);
	};
	updateTax = e => {
		this.setState({ tax: e.target.value });
	};

	render() {
		let vendors = [];
		let other;
		vendors.push(
			<option value={null} hidden>
				Vendor
			</option>
		);
		for (let i of this.props.vendorList) {
			vendors.push(<option value={i.id}>{i.name}</option>);
		}
		vendors.push(<option value={0}>Other</option>);
		if (this.state.other) {
			other = (
				<div className='fixedLine mt-2'>
					<label className='txtLabel'>Vendor Name</label>
					<input
						className='form-control txtInput'
						onChange={this.updateOtherName}
						value={this.state.vendor.name}
					/>
				</div>
			);
		}

		return (
			<div className='aligncenter'>
				<br></br>
				<div className='fixedLine'>
					<label className='txtLabel '>Receipt Number</label>
					<label className='d-inline startUnits'> </label>
					<input
						className='form-control txtInput mb-2'
						onChange={this.addReceipt}
						value={this.state.receipt}
						required
					/>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Amount</label>
					<label className='d-inline startUnits m-auto'>$</label>
					<input
						type='number'
						min='0'
						className='form-control txtInput mb-2'
						onChange={this.addAmount}
						name='quantity'
						value={this.state.amount}
						required
					></input>
				</div>
				<div className='text-right'>
					<label className='txtLabel mr-4 '>
						Tax Included
						<input
							className='ml-2'
							name='tax'
							value='Tax Included'
							onChange={this.updateTax}
							type='radio'
						/>
					</label>
					<label className='txtLabel'>
						No Tax
						<input
							className='ml-2'
							name='tax'
							value='No Tax'
							onChange={this.updateTax}
							checked
							type='radio'
						/>
					</label>
				</div>
				<br />
				<div className='fixedLine'>
					<label className='txtLabel'>Vendor</label>
					<select
						className='form-control txtInput drop_assingned mb-2'
						onChange={e => this.addVendor(e)}
						defaultValue={this.state.vendor.name}
					>
						{vendors}
					</select>
				</div>
				{other}
				<label className='txtLabel '>Comments</label>
				<textarea
					type='number'
					min='0'
					className='form-control txtInput mb-2'
					onChange={this.addComments}
					name='cost'
					value={this.state.comment}
				></textarea>

				<p className='errorMsg'>{this.state.errormsg}</p>
				<div className='text-right'>
					<Button onClick={this.props.goBack} className='mr-2'>
						Cancel
					</Button>
					<Button type='primary' onClick={this.done}>
						Add Transaction
					</Button>
				</div>
			</div>
		);
	}
}

class TransactionDisplay extends React.Component {
	editRow = id => {
		this.props.editTransaction(id);
	};
	cancelRow = id => {
		this.props.cancelRow(id);
	};
	render() {
		let rows = [],
			tableheader;
		if (this.props.transactions.length > 0) {
			tableheader = [
				<tr>
					<th style={{ width: '30%' }}> Receipts </th>{' '}
					<th style={{ width: '15%' }}> Amount</th>
					<th style={{ width: '20%' }}> Vendor </th>{' '}
					<th style={{ width: '35%' }}> Comments </th>
				</tr>
			];

			for (let i of this.props.transactions) {
				rows.push(
					<TransactionRow
						id={i.id}
						cancelRow={this.cancelRow}
						editRow={this.editRow}
						receipt={i.receipt}
						amount={i.amount}
						vendor={i.vendor}
						comment={i.comment}
						active={i.active}
					/>
				);
			}
		} else {
			tableheader = <div>No transactions recorded.</div>;
		}

		return (
			<table id='transactionDisplay'>
				<thead>{tableheader}</thead>
				<tbody>{rows}</tbody>
			</table>
		);
	}
}

class TransactionRow extends React.Component {
	editRow = () => {
		this.props.editRow(this.props.id);
	};
	row_cancel = () => {
		this.props.cancelRow(this.props.id);
	};
	render() {
		let trclass, actionBtn;
		if (this.props.active === 0) {
			trclass = 'cancelled';
			actionBtn = (
				<img
					src={require('./images/plus.png')}
					alt='Add Again'
					className='d-inline transIcon'
					onClick={this.row_cancel}
				/>
			);
		} else {
			trclass = '';
			actionBtn = (
				<img
					src={require('./images/delete.png')}
					alt='Remove'
					className='d-inline transIcon'
					onClick={this.row_cancel}
				/>
			);
		}
		return (
			<tr className={trclass}>
				<td className='borderL'>{this.props.receipt}</td>
				<td>${this.props.amount}</td>
				<td>{this.props.vendor.name}</td>
				<td className='borderR'>{this.props.comment}</td>
				<div className='d-flex trBtnGroup'>
					<img
						src={require('./images/wrench.png')}
						alt='Edit'
						className='d-inline transIcon ml-1 mr-1'
						onClick={this.editRow}
					/>
					{actionBtn}
				</div>
			</tr>
		);
	}
}

const displayPopover = (
	<Popover id='popover-basic'>
		<Popover.Content>
			Transactions for this job request are displayed here.
			<br />
			To edit a transaction, click{' '}
			<img
				src={require('./images/wrench.png')}
				alt='edit'
				className='smallIcon'
			/>
			<br />
			To remove a transaction, click{' '}
			<img
				src={require('./images/delete.png')}
				alt='delete'
				className='smallIcon'
			/>
			<br />
			<div className='oneLine'>
				Transactions that are shaded grey are removed and will not appear in the
				final work order.{' '}
			</div>
			To include the transaction again, press{' '}
			<img src={require('./images/plus.png')} alt='add' className='smallIcon' />
		</Popover.Content>
	</Popover>
);
const editPopover = (
	<Popover id='popover-basic'>
		<Popover.Content>
			If tax was included in the transaction, please indicate it by selecting
			the <i>Tax Included</i> button.
			<br />
			If no tax was required for this transaction, indicate it by selecting the{' '}
			<i>No Tax</i> button.
		</Popover.Content>
	</Popover>
);
const newDisplayPopover = (
	<Popover id='popover-basic'>
		<Popover.Content>
			Click <div className='bluetext d-inline'>Add Transaction Record</div>{' '}
			below to enter a new Transaction.
			<br />
		</Popover.Content>
	</Popover>
);
const DisplayPopover = () => (
	<OverlayTrigger trigger='click' placement='left' overlay={displayPopover}>
		<img
			src={require('./images/help.png')}
			alt='help'
			className='tooltipHelp'
		></img>
	</OverlayTrigger>
);
const EditPopover = () => (
	<OverlayTrigger trigger='click' placement='left' overlay={editPopover}>
		<img
			src={require('./images/help.png')}
			alt='help'
			className='tooltipHelp'
		></img>
	</OverlayTrigger>
);
const NewDisplayPopover = () => (
	<OverlayTrigger trigger='click' placement='left' overlay={newDisplayPopover}>
		<img
			src={require('./images/help.png')}
			alt='help'
			className='tooltipHelp'
		></img>
	</OverlayTrigger>
);

//  transaction display when 1 or more transactions appear
// Add transaction opens up the form - not added until form is completed
// Can edit transactions by clicking on them and appear in form format

export default Transactions;
