import axios from "axios";
import React, { Component } from "react";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import SignatureCanvas from 'react-signature-canvas'

class ReviewEntryNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 0
        };

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({numPages: numPages});
    }

    render() {
        return (
            <>
                <div style={{ display: 'flex', maxHeight: '500px', overflowY: 'auto', overflowX: 'auto', marginBottom: '20px' }}>
                    <Document file={this.props.activeEvent.document_url} onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page pageNumber={1} />
                    </Document>
                </div>

                <div style={{ border: '1px solid black' }}>
                    <SignatureCanvas canvasProps={{width: 471, height: 82.5 }}/>
                </div>

            </>
        )
    }
}

export default ReviewEntryNotice;