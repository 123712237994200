//line
//line 153 to add the backend axios to get and push data to backend
//line 187 to calculate the total cost
//add some code to calculate the labour approx cost from their hrly wage
import axios from 'axios';
import React, {
	Component,
	useState,
	useContext,
	useEffect,
	useRef
} from 'react';
import {
	Statistic,
	Button,
	Divider,
	InputNumber,
	Table,
	Modal,
	Checkbox,
	Input,
	Popconfirm,
	Form,
	Select,
	Menu,
	Tooltip,
	Dropdown,
	message
} from 'antd';
//import { Form } from "react-bootstrap";
import WorkOrderContext from '../WorkOrderContext';
import RequestContext from '../../../Pages/MaintenanceRequest/RequestContext';
//import Form from "antd/lib/form/Form";
import '../WorkOrder.css';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CardAction, WorkOrderFooter } from '../WorkOrder';

const { Option } = Select;
let types = 'Labour';
const EditableContext = React.createContext(null);
let index = 0;
let totalCost = 0;
const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

class Materials extends Component {
	static contextType = WorkOrderContext;
	constructor(props) {
		super(props);

		this.materialCols = [
			{
				title: 'Description',
				dataIndex: 'item',
				width: '20%',
				render: (inputValue, dataRecord, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e.target.value, rowIndex, 'item');
					};
					return (
						<Input
							placeholder='Input a description'
							value={inputValue}
							onChange={changeRecord}
						/>
					);
				}
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
				width: '5%',

				render: (inputValue, dataRecord, rowIndex) => {
					if (dataRecord.is_labour === 0) {
						let changeRecord = e => {
							this.handleChange(e, rowIndex, 'quantity');
						};
						return (
							<>
								<InputNumber
									precision={0}
									min={0}
									controls={false}
									value={inputValue}
									onChange={changeRecord}
								/>
							</>
						);
					} else {
						let changeRecord = e => {
							this.handleChange(e, rowIndex, 'quantity');
						};
						return (
							<InputNumber
								precision={0}
								min={0}
								controls={false}
								value={inputValue}
								onChange={changeRecord}
							/>
						);
					}
				}
			},
			{
				title: 'Price',
				dataIndex: 'est_price',
				width: '10%',

				render: (inputValue, dataRecord, rowIndex) => {
					let changeRecord = e => {
						this.handleChange(e, rowIndex, 'est_price');
					};
					return (
						<InputNumber
							formatter={value => `$ ${value}`}
							precision={2}
							min={0}
							controls={false}
							value={inputValue}
							onChange={changeRecord}
						/>
					);
				}
			},
			{
				title: 'Vendor',
				dataIndex: 'vendor',
				width: '20%',
				render: (inputValue, dataRecord, rowIndex) => {
					let changeRecord = e => {
						if (e.length >= 1 || (inputValue.length <= 1 && e.length === 0))
							this.handleChange(e, rowIndex, 'vendor');
					};
					const { contacts, name } = this.state;
					return dataRecord.is_labour == 0 ? (
						<Select
							showSearch
							onSearch={changeRecord}
							value={inputValue}
							style={{ width: 200 }}
							onChange={changeRecord}
						>
							{this.state.contacts.map(item =>
								item.id == null ? (
									<Option key={item}>{item}</Option>
								) : (
									<Option key={item.fname}>{item.fname}</Option>
								)
							)}
						</Select>
					) : (
						<Select
							value={inputValue}
							style={{ width: 200 }}
							onSearch={changeRecord}
							onChange={changeRecord}
						>
							{this.state.arraya}
						</Select>
					);
				}
			},
			{
				title: 'Estimated Cost',
				key: 'est_cost',
				width: '15%',
				align: 'right',

				render: (_, record) => {
					return '$' + record.quantity * record.est_price;
				}
			}
			// {
			// 	title: 'Operation',
			// 	dataIndex: 'operation',
			// 	render: (_, record) =>
			// 		this.state.materialRows.length >= 1 ? (
			// 			<Popconfirm
			// 				title='Sure to delete?'
			// 				onConfirm={() => this.handleDelete(record.key)}
			// 			>
			// 				<a>Delete</a>
			// 			</Popconfirm>
			// 		) : null
			// }
		];

		this.state = {
			materialsNeeded: false,
			materialRows: [], // {row_id:1, material:" ", quantity:0, unit:"",est_cost:0,store:""}
			currentRow: '',
			currentTask: '',
			contacts: null,
			optionContact: null,
			vendorId: '',
			type: 'Materials',
			count: 0,
			arraya: [],
			totalCost: 0,
			name: ''
		};
	}

	componentDidMount = () => {
		this.getContacts();

		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: this.context.getEventAction().idcalendar_events
			})
			.then(res => {
				this.setState({
					workOrderId: res.data.details[0].wo_id
				});
			});

		if (this.context.materialData) {
			const { materialsNeeded, materialRows, totalCost } =
				this.context.materialData;
			this.setState({
				materialsNeeded: materialsNeeded,
				materialRows: materialRows,
				totalCost: totalCost
			});
		}
		axios
			.post('/workOrder/get/workOrderEstimation', {
				woId: this.state.workOrderId
			})
			.then(res => {
				this.setState({
					materialRows: res.data.estimations
				});
			});
	};

	addItem = () => {
		const { contacts, name } = this.state;
		{
			this.setState({
				contacts: [...contacts, name || `New item ${index++}`],
				name: ''
			});
		}
	};
	onNameChange = event => {
		this.setState({
			name: event.target.value
		});
	};
	EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);
		useEffect(() => {
			if (editing) {
				inputRef.current.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex]
			});
		};

		const save = async () => {
			this.totalCost();
			if (this.state.vendorId != ` `) {
				record.vendor = this.state.vendorId;
			}
			if (record.type == 'Labour') {
				record.estimatedcost = ((record.quantity / 60) * 12.75).toFixed(2);
			}
			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({ ...record, ...values });
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}
				>
					<Input ref={inputRef} onPressEnter={save} onBlur={save} />
				</Form.Item>
			) : (
				<div
					className='editable-cell-value-wrap'
					style={{
						paddingRight: 24
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
			);
		}

		return <td {...restProps}>{childNode}</td>;
	};
	getContacts = () => {
		let y = [];
		let employeeArray = this.context.getEventAction();
		for (let x = 0; x < employeeArray.length; x++) {
			y.push(
				<Option value={employeeArray[x].fname + ' ' + employeeArray[x].lname}>
					{employeeArray[x].fname} {employeeArray[x].lname}{' '}
				</Option>
			);
		}
		this.setState({ arraya: y });
		axios.get('/getVendors').then(response => {
			this.setState({ contacts: response.data.vendors });
			/*for (let i=0;i<response.data.vendors.length;i++)
      {
        x.push(<Option value={response.data.vendors[i].id }>{response.data.vendors[i].fname}</Option>)
      }*/
		});
	};
	expensePopUp = () => {
		this.setState({ expenseType: !this.state.expenseType });
	};

	saveMaterials = () => {
		if (this.state.next) {
			this.context.updateMaterialData(
				this.state.materialsNeeded,
				this.state.materialRows,
				this.state.totalCost,
				false
			);
		}
	};

	materialsnextclick = () => {
		this.setState({ next: false });
		this.props.updateMaterialData(
			this.state.materialsNeeded,
			this.state.materialRows,
			this.state.totalCost,
			true
		);
	};

	newMaterial = () => {
		let row_ids = [],
			rowid;
		for (let i of this.state.materialRows) {
			row_ids.push(i.exp_id);
		}
		if (row_ids.length > 0) {
			rowid = Math.max.apply(Math, row_ids) + 1;
		} else rowid = 1;

		let newMaterial = {
			exp_id: rowid,
			item: null,
			quantity: 1,
			est_price: 0,
			vendor: ''
		};
		this.setState({ currentRow: newMaterial });
		this.setState({ currentTask: 'Add' });
	};

	deleteMaterial = id => {
		let curr = this.state.materialRows.findIndex(x => x.exp_id === id);
		let arr = this.state.materialRows;
		for (var i = 0; i < arr.length; i++) {
			if (i === curr) {
				arr.splice(i, 1);
			}
		}
		this.setState({ materialRows: arr });
		this.setState({ currentTask: 'Display' });
		this.updateTotal();

		if (this.state.materialRows.length === 0) {
			this.setState({ materialsNeeded: false });
		}
	};

	needMaterials = () => {
		this.setState({ currentTask: 'Display' });
		this.setState({ materialsNeeded: true });
	};

	//Changes the currentRow to the row with row_id
	editRow = row_id => {
		let curr = this.state.materialRows.find(x => x.exp_id === row_id);
		this.setState({ currentRow: curr });
		this.setState({ currentTask: 'Add' });
	};
	resetStateDetails = () => {
		this.setState({ type: '' });
		this.setState({ quantity: '' });
		this.setState({ vendor: '' });
		this.setState({ approximateCost: 0 });
		this.setState({ approximateLabourCost: 0 });
		this.setState({ hours: 0 });
		this.setState({ minutes: 0 });
		this.setState({ modalStatus: 'Estimate Expense' });
		this.setState({ isLabour: false });
		this.setState({ action_value: 'New' });
		this.setState({ exp_id: null });
	};
	updateTotal = () => {
		let cost = 0;
		this.state.materialRows.forEach(i => {
			if (isNaN(parseFloat(i.est_cost))) {
				cost += 0;
			} else cost += parseFloat(i.est_cost);
		});
		this.setState({ totalCost: cost });
	};
	addMaterial = material => {
		let curr = this.state.materialRows.findIndex(
			x => x.exp_id === material.exp_id
		);
		let arr = this.state.materialRows;
		if (curr === -1) {
			arr.push(material);
			this.setState({ materialRows: arr });
			this.setState({ currentTask: 'Display' });
		} else {
			arr[curr] = material;
			this.setState({ materialRows: arr });
			this.setState({ currentTask: 'Display' });
		}
		this.updateTotal();
	};
	handleChange = (e, rowIndex, column) => {
		let updatedRow = {
			...this.state.materialRows[rowIndex]
		};
		updatedRow[column] = e;
		this.setState({
			materialRows: [
				...(rowIndex !== 0 ? this.state.materialRows.slice(0, rowIndex) : []),
				updatedRow,
				...(rowIndex < this.state.materialRows.length - 1
					? this.state.materialRows.slice(rowIndex + 1)
					: [])
			]
		});
	};
	onChangehrs = value => {
		this.setState({ hours: value });
		this.setState({ approximateLabourCost: value * 12.75 });
	};
	onChangemins = value => {
		this.setState({ minutes: value });
		this.setState({
			approximateLabourCost: ((this.state.hours + value / 60) * 12.75).toFixed(
				2
			)
		});
	};
	onSubmit = () => {
		axios
			.post('/workOrder/update/troubleshoot/expense', {
				job_id: this.context.jobData.job_id,
				wo_id: this.state.workOrderId,
				est_expenses: this.state.materialRows
			})
			.then(response => {
				this.context.goToMainMenu();
			})
			.catch(error => {
				message.error('An Error Occurred.');
			});
	};
	totalCost = () => {
		for (let i = 0; i < this.state.materialRows.length; i++) {
			totalCost = totalCost + parseInt(this.state.materialRows[i].est_cost);
		}
	};

	handleDelete = key => {
		const dataSource = [...this.state.materialRows];

		this.setState({
			materialRows: dataSource.filter(item => item.key !== key)
		});
	};
	handleAdd = type => {
		this.setState({ type: type });
		const count = this.state.count;
		const dataSource = this.state.materialRows;
		const newData = {
			key: count,
			item: '',
			quantity: 1,
			est_price: 0.01,
			vendor: '',
			is_labour: 0,
			exp_id: null,
			action_value: 'New'
		};
		if (type == 'Labour') {
			newData.estimatedcost = (newData.quantity / 60) * 12.75;
			newData.is_labour = 1;
		}
		this.setState({
			materialRows: [...dataSource, newData],
			count: count + 1
		});
	};
	handleSave = row => {
		const newData = [...this.state.materialRows];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
			materialRows: newData
		});
	};
	menu = (
		<Menu>
			<Menu.Item onClick={() => this.handleAdd('Materials')}>
				Material
			</Menu.Item>
			<Menu.Item onClick={() => this.handleAdd('Labour')}>Labour</Menu.Item>
		</Menu>
	);

	render() {
		const { dataSource } = this.state.materialRows;
		const components = {
			body: {
				row: EditableRow,
				cell: this.EditableCell
			}
		};
		const columns = this.materialCols.map(col => {
			if (!col.editable) {
				return col;
			}

			return {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: this.handleSave
				})
			};
		});
		return (
			<div style={{ height: '270px' }} className=''>
				Expenses {/*<PlusCircleOutlined  onClick={this.handleAdd}/>*/}
				{
					<Dropdown overlay={this.menu} trigger={['click']}>
						<a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
							<PlusCircleOutlined />
						</a>
					</Dropdown>
				}
				<Table
					components={components}
					columns={columns}
					dataSource={
						this.state.contacts == null ? [] : this.state.materialRows
					}
					rowKey={'exp_id'}
					size='small'
					pagination={false}
					rowClassName={() => 'editable-row'}
				/>
				{/*<Button onClick={this.newMaterial} type='primary'>Add Material</Button>*/}
				{/* Add code to calculate the total cost from the details from backend */}
				{/* <div>{totalCost}</div> */}
				<div className='borderLine'></div>
				<div>
					<Button
						className='mr-0'
						type='primary'
						style={{
							whiteSpace: 'normal',
							height: 'auto',
							marginBottom: '10px',
							marginTop: '10px'
						}}
						onClick={() => this.onSubmit()}
					>
						Submit
					</Button>
				</div>
			</div>
		);
	}
}

class Measurement extends Component {
	updateMeasurement = e => {
		this.props.updateMeasurement('measurement', e.target.value);
	};
	updateUnits = e => {
		this.props.updateMeasurement('unit', e.target.value);
	};

	render() {
		return (
			<div>
				<div className='fixedLine'>
					<label className='txtLabel '>Material Measurement</label>
					<label className='d-inline startUnits'> </label>
					<input
						type='text'
						placeholder='Ex. 2, 2x2, 2x2x5'
						className='form-control txtInput mb-2'
						onChange={this.updateMeasurement}
						name='measurement'
						value={this.props.measurement}
					></input>
				</div>
				<div className='fixedLine'>
					<label className='txtLabel '>Unit</label>
					<label className='d-inline startUnits'> </label>
					<select
						className='form-control txtInput drop_assingned mb-2'
						onChange={this.updateUnits}
						name='unit'
						value={this.props.unit}
					>
						<option selected hidden disabled>
							Unit (Optional)
						</option>
						<optgroup label='Length'>
							<option>Centimeters (cm)</option>
							<option>Meters (m)</option>
							<option>Kilometers (km) </option>
							<option>Inches (in)</option>
							<option>Feet (ft)</option>
							<option>Yards (yd)</option>
							<option>Miles (mi)</option>
						</optgroup>
						<optgroup label='Volume'>
							<option>Millilitres (ml) </option>
							<option>Liters (l)</option>
							<option>Gallon (gal)</option>
						</optgroup>
						<optgroup label='Weight'>
							<option>Grams (g)</option>
							<option>Kilograms (kg)</option>
							<option>Pounds (lb)</option>
						</optgroup>
					</select>
				</div>
			</div>
		);
	}
}

//{mat_id:1, material_name:"", quantity:0,measurement:"",unit:"",cost:0.0,store:""}
const AddMaterials = props => {
	const { currentRow } = props;
	const [newRow, setRow] = useState(currentRow);

	const saveRow = e => {
		return;
	};

	return (
		<Form {...layout} initialValues={currentRow} onFinish={saveRow}>
			<Form.Item name='material_name' label='Material Name'>
				<input />
			</Form.Item>
			<Form.Item name='quantity' label='Quantity'>
				<InputNumber />
			</Form.Item>
			<Form.Item name='cost' label='Estimated Cost'>
				<InputNumber
					formatter={value =>
						`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					}
					parser={value => value.replace(/\$\s?|(,*)/g, '')}
				/>
			</Form.Item>
		</Form>
	);
};

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};

class Addmaterials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			row_id: this.props.row_id,
			material: this.props.material,
			quantity: this.props.quantity,
			measure: this.props.measure,
			measurement: this.props.measurement,
			unit: this.props.unit,
			cost: this.props.est_cost,
			store: this.props.store,
			errormsg: ''
		};
		this.addMaterial = this.addMaterial.bind(this);
		this.addQuantity = this.addQuantity.bind(this);
		this.addUnit = this.addUnit.bind(this);
		this.addCost = this.addCost.bind(this);
		this.addStore = this.addStore.bind(this);
		this.sendMaterial = this.sendMaterial.bind(this);
	}

	addMaterial = e => {
		this.setState({ material: e.target.value });
	};
	addQuantity = e => {
		this.setState({ quantity: e.target.value });
	};
	addUnit = e => {
		this.setState({ unit: e.target.value });
	};
	addCost = e => {
		this.setState({ cost: e.target.value });
	};
	addStore = e => {
		this.setState({ store: e.target.value });
	};
	deleteMaterial = () => {
		this.props.deleteMaterial(this.state.row_id);
	};
	sendMaterial = () => {
		let material;
		if (this.verify()) {
			material = {
				row_id: this.state.row_id,
				material: this.state.material,
				quantity: this.state.quantity,
				measure: this.state.measure,
				measurement: this.state.measurement,
				unit: this.state.unit,
				est_cost: this.state.cost,
				store: this.state.store
			};
			this.props.addMaterial(material);
		}
	};
	verify = () => {
		if (this.state.material === 'Material') {
			this.setState({ errormsg: 'Please select a material' });
			return false;
		} else if (this.state.cost === null) {
			this.setState({ errormsg: 'Please enter the material cost' });
			return false;
		}
		return true;
	};
	updateMeasurement = (type, value) => {
		if (type === 'measurement') {
			this.setState({ measurement: value });
		} else if (type === 'unit') {
			this.setState({ unit: value });
		}
	};
	measureChange = e => {
		if (e.target.value === 'Yes') this.setState({ measure: true });
		else this.setState({ measure: false });
	};
	render() {
		let measurement;
		if (this.state.measure === true)
			measurement = (
				<Measurement
					measurement={this.state.measurement}
					unit={this.state.unit}
					updateMeasurement={this.updateMeasurement}
				/>
			);
		return (
			<div className='aligncenter'>
				<div className='fixedLine'>
					<label className='txtLabel '>Material</label>
					<label className='d-inline startUnits'> </label>
					<input
						name='materialname'
						type='text'
						className='form-control txtInput drop_assingned mb-2'
						placeholder='Material Name'
						onChange={this.addMaterial}
						value={this.state.material}
					/>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Material Quantity</label>
					<label className='d-inline startUnits'> </label>
					<input
						type='number'
						min='0'
						className='form-control txtInput mb-2'
						onChange={this.addQuantity}
						name='quantity'
						value={this.state.quantity}
					></input>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Estimated Cost</label>
					<label className='d-inline startUnits text-center m-auto'>$ </label>
					<input
						type='number'
						min='0'
						className='form-control txtInput mb-2'
						onChange={this.addCost}
						name='cost'
						placeholder='0'
						value={this.state.cost}
					></input>
				</div>

				<div className='fixedLine'>
					<label className='txtLabel '>Store</label>
					<label className='d-inline startUnits'> </label>
					<select
						className='form-control drop_assingned txtInput mb-2'
						name='store'
						onChange={this.addStore}
						value={this.state.store}
					>
						<option selected hidden disabled>
							Store
						</option>
						{this.props.vendorList.map(vendor => (
							<option key={vendor.id}>{vendor.name}</option>
						))}
						<option>Other</option>
					</select>
				</div>
				<br />
				<div className='fixedLine'>
					<label className='txtLabel '>Add Material Measurements </label>
					<div className='txtInput'>
						<input
							type='radio'
							name='measurement'
							value='Yes'
							id='yesM'
							checked={this.state.measure === true}
							onChange={this.measureChange}
						/>
						<label className='mr-2'>Yes</label>
						<input
							type='radio'
							name='measurement'
							value='No'
							id='noM'
							checked={this.state.measure === false}
							onChange={this.measureChange}
						/>
						<label>No</label>
					</div>
				</div>

				{measurement}

				<div className='errorMsg'>{this.state.errormsg}</div>
				<br />
				<div className='text-right'>
					<Button type='primary' onClick={this.sendMaterial}>
						Add Material
					</Button>
					<br />
					<h7 id='delMaterial' onClick={this.deleteMaterial}>
						Delete This Material
					</h7>
				</div>
			</div>
		);
	}
}

class ButtonGroup extends Component {
	constructor(props) {
		super(props);
		this.labourPage = this.labourPage.bind(this);
	}
	labourPage() {
		this.props.materialsnextclick();
	}
	exitpage = () => {
		this.props.goEnd();
	};
	render() {
		return (
			<div id='btnGroup' className='buttonGroup text-right'>
				<Button type='primary' onClick={this.labourPage}>
					Next
				</Button>
			</div>
		);
	}
}

export default Materials;
