import React from 'react';
import MaintenanceRequest from '../MaintenanceRequest/MaintenanceRequest';
import UnitMaintenanceRequest from '../MaintenanceRequest/UnitMaintenanceRequest';
import PropertyMaintenanceRequest from '../MaintenanceRequest/PropertyMaintenanceRequest';
import IntakeRequest from '../IntakeRequest/IntakeRequest';

/**
 * The route array for the specific type of job requests.
 * @returns {object[]} path routes
 */
const requestRoutes = [
	{
		path: '/maintenance/request/:id',
		name: 'Maintenance Request',
		// component: MaintenanceRequest,
		exact: true
	},
	{
		path: '/unitMaintenance/request/propertyUnitJR',
		name: 'Unit Maintenance Request',
		component: UnitMaintenanceRequest,
		exact: true
	},
	// {
	// 	path: '/propertyMaintenance/request/propertyJR',
	// 	name: 'Property Maintenance Request',
	// 	component: PropertyMaintenanceRequest,
	// 	exact: true
	// },
	{
		path: '/propertyMaintenance/request/propertyJR',
		name: 'Property Maintenance Request',
		component: PropertyMaintenanceRequest,
		exact: true
	}
	// {
	// 	path: '/administrative/request/:id',
	// 	name: 'Admin Request',
	// 	component: MaintenanceRequest,
	// 	exact: true
	// },
	// {
	// 	path: '/administrative/intake/:id',
	// 	name: 'Intake Request',
	// 	component: IntakeRequest,
	// 	exact: true
	// }
];

/**
 * The url links to the specific type of job requests.
 */
export const requestLinks = {
	'maintenance request': '/maintenance/request/',
	'unitmaintenance request': '/unitMaintenance/request/',
	'propertymaintenance request': '/propertyMaintenance/request/',
	'service request': '/administrative/request/',
	'intake request': '/administrative/intake/'
};

export default requestRoutes;
