import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Typography } from 'antd';
import WorkOrderContext from './WorkOrderContext';
import RequestContext from '../../Pages/MaintenanceRequest/RequestContext';
import moment from 'moment';

const { Paragraph } = Typography;

const CompleteWorkOrder = props => {
	const contextWorkOrder = React.useContext(WorkOrderContext);
	const contextRequest = React.useContext(RequestContext);
	const [loading, setLoading] = useState(false);
	const [workOrderId, setWorkOrderId] = useState(null);

	useEffect(() => {
		axios
			.post('/workOrder/get/workOrderDetails', {
				eventId: contextRequest.activeEvent.idcalendar_events
			})
			.then(res => {
				console.log(res.data.details);

				setWorkOrderId(res.data.details[0].wo_id);
			});
	});

	const submitCompletionWO = () => {
		axios
			.post('/eventCalendar/updateStatusToReview', {
				eventId: contextRequest.activeEvent.idcalendar_events,
				job_id: contextWorkOrder.jobData.job_id,
				// wo_id: contextRequest.currentWorkOrder.wo_id
				wo_id: workOrderId
			})
			.then(response => {
				console.log(contextRequest.activeEvent.idcalendar_events);
				contextRequest.updateRequestWorkOrders(
					contextRequest.activeEvent.idcalendar_events
				);
				contextRequest.updateEventStatus('Work Order - Review');
				contextRequest.addActivity(response.data.note, null);
				message.success('Work Order has been successfully  sent for review!');
			});
	};
	// console.log(contextRequest);
	return (
		<div className='Reason '>
			<div
				style={{
					marginTop: '25px',
					marginBottom: '40px',
					outline: '1px groove black'
				}}
			>
				<Paragraph style={{ marginLeft: '10px' }}>
					Are you sure you would like to complete the Work Order?
				</Paragraph>
				<Paragraph style={{ marginLeft: '10px' }}>
					This will finalize your work and be sent to the manager for review.
				</Paragraph>
			</div>

			<br />
			<div className='buttonGroup'>
				<Button
					loading={loading}
					type='primary'
					className='mr-0 '
					onClick={submitCompletionWO}
				>
					Complete Work Order
				</Button>
			</div>
		</div>
	);
};
export default CompleteWorkOrder;
