import React, { useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	Menu,
	message,
	DatePicker,
	Table,
	Popconfirm,
	Calendar
} from 'antd';
import { Modal, Form } from 'react-bootstrap';
import {
	ArrowRightOutlined,
	MoreOutlined,
	PlusCircleOutlined,
	CloseCircleOutlined,
	CalendarOutlined,
	EditOutlined,
	CheckCircleOutlined
} from '@ant-design/icons';
import useWindowDimensions from '../../Context/WindowsDimensions';
import axios from 'axios';

import AssignVendorModal from './actions/modals/AssignVendor';
import CreateEventModal from './actions/modals/CreateEvent';
import moment from 'moment';
import RequestContext from './RequestContext';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
const cancelToken = axios.CancelToken.source();
const { RangePicker } = DatePicker;
//Main container for the vendor and event details.
class TenantRequestVendor extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super(props);

		// currentEdit / currentDelete - event objects of the event being changed. // editModal/deleteModal : boolean of the modal open or closed.
		this.state = {
			currentEdit: null,
			editInfo: null,
			editModal: false,
			currentDelete: null,
			deleteModal: false,
			calendarModal: false,
			taskarray: []
		};
	}
	getAvailability = () => {
		axios
			.post('/get/loadvenderavailability', { jobvenderID: 123 })
			.then(response => {
				this.setState({ taskarray: response.data.Items });
				return;
			});
	};

	openDeleteEventModal = () => {
		this.setState({ deleteModal: !this.state.deleteModal });
	};
	openEditEventModal = () => {
		this.setState({ editModal: !this.state.editModal });
	};
	openCalendarModal = () => {
		this.setState({ calendarModal: !this.state.calendarModal });
	};
	setDeleteEvent = event => {
		this.setState({ currentDelete: event }, this.openDeleteEventModal);
	};
	setEditEvent = event => {
		this.setState({ currentEdit: event }, this.openEditEventModal);
	};

	submitEditEvent = editInfo => {
		let body = {
			event: this.state.currentEdit,
			editInfo: editInfo
		};
		axios
			.post('/jobRequest/update/event', body)
			.then(response => {
				this.context.editEvent(response.data.updatedEvent);
				this.context.addActivity(response.data.note, null, false);
				this.openEditEventModal();
				message.success(
					`The event "${response.data.updatedEvent.title}" has been updated.`
				);
			})
			.catch(err => console.log(err));
	};

	//Terminates the vendor.
	terminateVendor = id => {
		axios
			.post('jobRequest/update/vendor/terminate', {
				vendor_id: id,
				job_id: this.context.request.job_id
			})
			.then(response => {
				this.context.terminateVendor(id, response.data.endDate);
				message.success('Vendor has been terminated.');
			})
			.catch(err => {
				console.log(err);
			});
	};

	editEvent = id => {
		let event = this.context.request.workSchedule.find(
			e => e.idcalendar_events === id
		);
		this.setState({ editModal: !this.state.editModal, currentEdit: event });
		return;
	};

	completeEvent = id => {
		let body = {
			job_id: this.context.request.job_id,
			event_id: id
		};
		axios
			.post('jobRequest/update/event/complete', body)
			.then(() => {
				this.context.completeEvent(id);
				message.success(`The event has been marked as complete.`);
			})
			.catch(err => console.log(err));
	};

	cancelEvent = id => {
		let body = {
			job_id: this.context.request.job_id,
			event_id: id
		};
		axios
			.post('jobRequest/update/event/cancel', body)
			.then(() => {
				this.context.cancelEvent(id);
				//this.context.addActivity(response.data.note, null, false);
				message.success(`The event has been cancelled.`);
			})
			.catch(err => console.log(err));
	};
	render() {
		return (
			<>
				<Card bordered={false}>
					<div id='vendorInfoDiv'>
						<div className='JRSubtitle'>Vendors</div>
						{this.props.user.company_id === this.context.request.PM_id &&
						this.props.user.type === 'Manager' &&
						(this.context.request.job_status === 'Scheduling' ||
							this.context.request.job_status === 'Work Order - Scheduled' ||
							this.context.request.job_status === 'Work Order - Work Done') ? (
							<ChangeVendor
								request={this.props.request}
								addActivity={this.props.addActivity}
								updateVendors={this.props.updateVendors}
							/>
						) : (
							''
						)}
						<br />
						<Table
							style={{ width: '100%' }}
							columns={vendorTableCols(
								this.terminateVendor,
								this.state.calendarModal,
								this.openCalendarModal,
								this.getAvailability,
								this.state.taskarray
							)}
							dataSource={this.context.request.vendors || []}
							rowKey={v => v.vendor_id + v.start}
							pagination={false}
							size={'small'}
							rowClassName={row =>
								row.terminate === 1 ? 'table-row-disabled' : ''
							}
						/>

						{this.context.request.workSchedule !== undefined ? (
							<>
								<br />
								<div>
									<div className='JRSubtitle'>Events</div>
									{this.props.user.company_id === this.context.request.PM_id &&
									this.props.user.type === 'Manager' &&
									(this.context.request.job_status === 'Scheduling' ||
										this.context.request.job_status ===
											'Work Order - Scheduled' ||
										this.context.request.job_status ===
											'Work Order - Work Done') ? (
										<AddSchedule
											request={this.props.request}
											addActivity={this.props.addActivity}
											scheduleWork={this.props.scheduleWork}
										/>
									) : (
										''
									)}
								</div>
								{this.context.request.workSchedule.length > 0 ? (
									<div className='scheduledEvents'>
										<Table
											style={{ width: '100%' }}
											columns={eventTableCols(
												this.editEvent,
												this.cancelEvent,
												this.completeEvent,
												this.context.request.vendors
											)}
											dataSource={this.context.request.workSchedule}
											rowKey={e => e.idcalendar_events || e.job_id}
											pagination={false}
											size={'small'}
											rowClassName={row =>
												row.terminate === 1 || row.complete === 1
													? 'table-row-disabled'
													: ''
											}
										/>
									</div>
								) : (
									<div className='mt-2'>
										<div className='g-text-sm'>
											No Events currently scheduled
										</div>
									</div>
								)}
							</>
						) : (
							''
						)}
					</div>
				</Card>
				<Modal
					show={this.state.deleteModal}
					onHide={this.openDeleteEventModal}
					size='sm'
					aria-labelledby='change-vendor-modal'
					centered
				>
					{this.state.deleteModal ? (
						<DeleteEventModal
							onHide={this.openDeleteEventModal}
							deleteEvent={this.submitDeleteEvent}
							event={this.state.currentDelete}
						/>
					) : (
						''
					)}
				</Modal>
				<Modal
					show={this.state.editModal}
					onHide={this.openEditEventModal}
					size='lg'
					aria-labelledby='change-vendor-modal'
					centered
				>
					{this.state.editModal ? (
						<EditEventModal
							onHide={this.openEditEventModal}
							editEvent={this.submitEditEvent}
							event={this.state.currentEdit}
							vendors={this.context.request.vendors}
						/>
					) : (
						''
					)}
				</Modal>
			</>
		);
	}
}
const eventTableCols = (editEvent, cancelEvent, completeEvent, vendors) => {
	return [
		{
			title: 'Purpose',
			dataIndex: 'title',
			key: 'title',
			width: '35%'
		},
		{
			title: 'Start Date',
			dataIndex: 'start',
			key: 'start',
			width: '20%',
			render: e => moment(e).format('ddd MMM D, h:mma')
		},
		{
			title: 'End Date',
			dataIndex: 'end',
			key: 'end',
			width: '20%',
			render: e => moment(e).format('ddd MMM D, h:mma')
		},
		{
			title: 'Assigned Vendor',
			dataIndex: 'userid',
			key: 'userid',
			width: '15%',
			render: (v, row) => row.fname + ' ' + (row.lname ? row.lname : '')
		}
	];
};
const vendorTableCols = (
	terminateVendor,
	calendarModal,
	openCalendarModal,
	getAvailability,
	taskarray
) => {
	return [
		{
			title: 'Name',
			dataIndex: 'name',
			width: '30%',
			key: 'name'
		},
		{
			title: 'Company',
			key: 'company',
			dataIndex: 'company_id',
			width: '30%',
			render: id => (id === 42 ? 'Steady Property Management' : '')
		},
		{
			title: 'Start',
			key: 'vendor_start',
			dataIndex: 'startDate',
			width: '20%'
		},
		{
			title: 'End',
			key: 'vendor_end',
			dataIndex: 'endDate',
			width: '20%'
		}
	];
};
const EventCard = ({
	event,
	editEvent,
	deleteEvent,
	editAccess = false,
	width100 = false
}) => {
	let startDate =
		event.start < 1263254400000 ? event.start * 1000 : event.start;
	let endDate = event.end < 1263254400000 ? event.end * 1000 : event.end;
	let sameDay = moment(endDate).isSame(moment(startDate), 'day');
	const [menuVisible, setMenuVisible] = useState(false);

	const edit = () => {
		editEvent(event);
		setMenuVisible(false);
	};
	const delete_ = () => {
		deleteEvent(event);
		setMenuVisible(false);
	};
	return (
		<div
			className={
				'eventCard timeBox ' +
				`${
					moment(event.start).isSame(new Date(), 'day') ? `eventToday ` : ''
				}` +
				`${
					moment(event.start).isAfter(new Date(), 'day') ? 'eventFuture ' : ''
				}` +
				`${
					moment(event.start).isBefore(new Date(), 'day') ? 'eventPast ' : ''
				}` +
				`${width100 ? 'width100' : ''}`
			}
		>
			<h5 className='eventTitle'>{event.title}</h5>
			{editAccess && !moment(event.start).isBefore(new Date(), 'day') ? (
				<div className='floatRight'>
					<Dropdown
						overlay={
							<Menu onClick={''}>
								<Menu.Item key='1' onClick={edit}>
									Edit Event
								</Menu.Item>
								<Menu.Item key='2' onClick={delete_}>
									Delete Event
								</Menu.Item>
							</Menu>
						}
						trigger={'click'}
						open={menuVisible}
						onOpenChange={setMenuVisible}
						placement={'bottomRight'}
					>
						<MoreOutlined />
					</Dropdown>
				</div>
			) : (
				''
			)}

			<div className='d-flex'>
				<div className={'fs-12'}>
					{moment(startDate).format('ddd, MMM D')}
					<br />
					{`${moment(startDate).format('h:mma')} ${
						sameDay ? ' - ' + moment(endDate).format('h:mma') : ''
					}`}
				</div>
				{!sameDay ? (
					<div style={{ fontSize: '14px' }} className='ml-2 mr-2'>
						<ArrowRightOutlined />
					</div>
				) : (
					''
				)}
				{!sameDay ? (
					<div className='fs-12'>
						{moment(endDate).format('ddd, MMM D')}
						<br />
						{moment(endDate).format('h:mma')}
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
const EditEventModal = props => {
	const [time, setTime] = useState([props.event.start, props.event.end]);
	const { width } = useWindowDimensions();
	const [title, setTitle] = useState(props.event.title);
	const [selectedVendor, setVendor] = useState(props.event.userid);
	const [errorMsg, setErrorMsg] = useState('');
	function disabledDate(current) {
		// Can not select days before today and today
		return current && current < moment().subtract(1, 'days');
	}
	const changeTitle = e => {
		if (e.target.value.length === 0) {
			setErrorMsg('Please enter a title');
		} else {
			setErrorMsg('');
		}
		setTitle(e.target.value);
	};
	const editEvent = () => {
		let editObj = {
			start: time[0],
			end: time[1],
			title: title,
			vendor_id: selectedVendor
		};
		props.editEvent(editObj);
	};
	return (
		<>
			<Modal.Header closeButton>
				<div className='fs-18'>Schedule Event</div>
			</Modal.Header>
			<Modal.Body className='assignVendorMBody text-center'>
				<Form>
					<Form.Group>
						<Form.Label>Event Purpose</Form.Label>
						<Form.Control
							className='width50 m-auto'
							type='text'
							value={title}
							placeholder={'Title'}
							onChange={changeTitle}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Event Time Range</Form.Label>
						<br />
						<RangePicker
							showTime
							disabledDate={disabledDate}
							size={width > 760 ? 'large' : 'small'}
							inputReadOnly
							value={[moment(time[0]), moment(time[1])]}
							format='YYYY-MM-DD h:mm a'
							onOk={value => setTime(value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Select Vendor</Form.Label>
						<Form.Control
							as='select'
							defaultValue={selectedVendor}
							onChange={e => setVendor(e.target.value)}
							className='width50 m-auto'
						>
							<option disabled hidden value={''}>
								Select Vendor
							</option>
							{props.vendors
								.filter(
									v => v.terminate === 0 || selectedVendor === v.vendor_id
								)
								.map(v => (
									<option
										key={v.vendor_id}
										disabled={v.terminate === 1}
										value={v.vendor_id}
									>
										{v.name ? v.name : v.fname + ' ' + (v.lname ? v.lname : '')}
									</option>
								))}
						</Form.Control>
					</Form.Group>
				</Form>
				<div className='errorMsg'>{errorMsg}</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
				<Button type='primary' onClick={editEvent}>
					Schedule Work
				</Button>
			</Modal.Footer>
		</>
	);
};
const DeleteEventModal = props => {
	return (
		<>
			<Modal.Header>Really delete this event?</Modal.Header>
			<Modal.Body>
				<EventCard event={props.event} width100 />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Cancel</Button>
				<Button type='primary' danger onClick={props.deleteEvent}>
					Confirm
				</Button>
			</Modal.Footer>
		</>
	);
};

const CalendarModal = props => {
	return (
		<>
			<Modal.Header>Availability </Modal.Header>
			<Modal.Body>
				<BarCalendar
					dataSource={[
						{
							bars: props.taskArray
						}
					]}
					eventStyles={{
						pink: {
							start: {
								opacity: 0.65,
								backgroundColor: '#fe4d97',
								borderTop: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#fe4d97',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#fe4d97',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#fe4d97',
								borderTop: '1px solid #000000',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							}
						},
						blue: {
							start: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe',
								borderTop: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe',
								borderTop: '1px solid #000000',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							}
						},
						default: {
							start: {
								opacity: 0.65,
								backgroundColor: '#778ca2',
								borderTop: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							middle: {
								backgroundColor: '#778ca2',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#778ca2',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#778ca2',
								borderTop: '1px solid #000000',
								borderBottom: '1px solid #000000',
								borderLeft: '1px solid #000000',
								borderRight: '1px solid #000000'
							}
						}
					}}
					calendarView='month'
					showHeader={true}
					interactMode=''
					showTimeBar={false}
					orientation='vertical'
					newEvent={newEvent => {
						return newEvent;
					}}
					editEvent={newData => {
						return;
					}}
					clickEvent={eventData => {}}
					blockWidth={35}
					blockHeight={20}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Cancel</Button>
				<Button type='primary' danger /*onClick={}*/>
					Confirm
				</Button>
			</Modal.Footer>
		</>
	);
};

const ChangeVendor = props => {
	const context = React.useContext(RequestContext);
	const [changeVendorModal, openModal] = useState(false);
	const openChangeVendorModal = () => openModal(!changeVendorModal);
	return (
		<>
			<PlusCircleOutlined
				className='greyActionIcon'
				onClick={openChangeVendorModal}
			/>
			<Modal
				show={changeVendorModal}
				onHide={openChangeVendorModal}
				size='lg'
				dialogClassName='header-wide-modal'
				aria-labelledby='change-vendor-modal'
				centered
			>
				{changeVendorModal ? (
					<AssignVendorModal
						onHide={openChangeVendorModal}
						request={context.request}
						updateVendors={context.updateVendors}
						updateEmployees={context.updateEmployees}
					/>
				) : (
					''
				)}
			</Modal>
		</>
	);
};

//TODO: Change modal so that you can select the vendor
const AddSchedule = props => {
	const context = React.useContext(RequestContext);
	const [newScheduleModal, openModal] = useState(false);
	const [doPrevent, preventClosing] = useState(false);
	const openNewScheduleModal = () => {
		if (!doPrevent) openModal(!newScheduleModal);
	};

	const afterEvent = event => {
		context.updateWork(event);
		openNewScheduleModal();
		message.success('A new event has been successfully scheduled.');
	};
	return (
		<>
			<PlusCircleOutlined
				className='greyActionIcon'
				onClick={openNewScheduleModal}
			/>
			<Modal
				show={newScheduleModal}
				onHide={openNewScheduleModal}
				size='lg'
				aria-labelledby='change-vendor-modal'
				centered
			>
				{newScheduleModal ? (
					<CreateEventModal
						updateStatus={context.updateStatus}
						updateImages={context.updateImages}
						preventClosing={preventClosing}
						onHide={openNewScheduleModal}
						afterEvent={afterEvent}
						vendors={context.request.vendors}
						employees={context.request.employees}
						tenants={context.request.tenants}
						owners={context.request.owners}
						workers={context.workers}
						job_id={context.request.job_id}
						request={context.request}
						user={context.user}
						doPrevent={doPrevent}
					/>
				) : (
					''
				)}
			</Modal>
		</>
	);
};

export { TenantRequestVendor, EditEventModal };
