import React, { useState, useContext } from 'react';
import {
	Result,
	Space,
	DatePicker,
	Button,
	message,
	Modal,
	Popconfirm,
	Card
} from 'antd';
import { Modal as BootstrapModal } from 'react-bootstrap';
import {
	ReloadOutlined,
	DeleteOutlined,
	LoadingOutlined,
	ExclamationCircleFilled,
	TabletTwoTone
} from '@ant-design/icons';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
import CalendarHeader from '../../Components/BarCalendar/CalendarHeader';
import { ProfileAvatar } from '../../Components/Avatar';
import moment from 'moment';
import axios from 'axios';

import './taskManagement.css';

import ConflictModal from './ConflictModal';

import LoadScreen from '../../Components/LoadScreen';
import AirbnbUpload from '../../Components/AirbnbUpload';
import Moment from 'react-moment';
import { MaintenanceRequest } from '../MaintenanceRequest/MaintenanceRequest';
import { Link } from 'react-router-dom';

import RequestContext from '../MaintenanceRequest/RequestContext';
import { UserStateContext } from '../../Context/UserContext';
import ManualReservation from './ManualReservation';

const cancelToken = axios.CancelToken.source();
const blockSize = 40;

let modalKey = false;

const OpenEventModal = props => {
	const { currEvent } = props;

	return <MaintenanceRequest job_id={currEvent.job_id} mobile={true} />;
};

function propertyName(number, street, unit, city, postalCode) {
	return (
		(number ? number : '') +
		(unit && unit !== 'default' && unit !== 'basic' ? '-' + unit + ' ' : ' ') +
		(street ? street + ' ' : '') +
		(city ? city + ' ' : '') +
		(postalCode ? postalCode : '')
	);
}

function makeDateString(dateObj) {
	let dateString = '';
	if (typeof dateObj === 'object')
		dateString = dateObj.toLocaleDateString('en-US', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
	else {
		let newDate = new Date(dateObj);
		dateString = newDate.toLocaleDateString('en-US', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
	}
	return dateString;
}

function LeaseModal(props) {
	const { occupancyData, isCensored, unitIndex } = props;
	let unitData = occupancyData[unitIndex];
	return (
		<div>
			<Card
				title={
					<>
						<div className='g-text d-inline'>
							Lease for&nbsp;
							{propertyName(
								unitData.propertynumber,
								unitData.propertyStreet,
								unitData.unit_name
							)}
							&nbsp; &nbsp;
						</div>
					</>
				}
				bordered={false}
			>
				<div className='profContent'>
					<div className='profFile'>
						<img
							width={193}
							height={257}
							src='https://via.placeholder.com/193x257/?text=No+Lease+Uploaded'
							alt='Lease Not Uploaded'
						/>
						{unitData.lease_url ? (
							<div className='g-text-sm'>Click to view lease</div>
						) : (
							''
						)}
					</div>
					<div className='profDetails ml-4'>
						<div className='mt-4'>
							<div className='g-text-sm'>Rental Status</div>
							{unitData.ready === 1
								? 'Ready'
								: `Not Ready(${unitData.comments})`}
						</div>
						<div className='mt-4'>
							<div className='g-text-sm'>Lease Type</div> {unitData.lease_type}
						</div>
						<div className='g-text-sm'>Lease Term</div>
						<Moment format='DD/MM/YY'>{unitData.start_date}</Moment>
						{unitData.end_date ? (
							<Moment format=' - DD/MM/YY'>{unitData.end_date}</Moment>
						) : (
							' - Present'
						)}
						<label className='g-text-sm d-block'>Property</label>
						<ProfileAvatar
							name={propertyName(
								unitData.propertynumber,
								unitData.propertyStreet,
								unitData.unit_name,
								unitData.propertyCity,
								unitData.propertyPostalcode
							)}
						/>
						<br />
						<label className='g-text-sm d-block'>Manager</label>
						<ProfileAvatar
							user_id={unitData.PM_id}
							name={
								unitData.PM_fname +
								(unitData.PM_lname ? ' ' + unitData.PM_lname : '')
							}
						/>
					</div>
				</div>
			</Card>
		</div>
	);
}

function EventModal(props) {
	const { unitIndex, subCode, occupancyData } = props;
	const user = useContext(UserStateContext);
	let eventData =
		occupancyData[unitIndex].events[
			occupancyData[unitIndex].events.findIndex(
				findEvent => findEvent.idcalendar_events === subCode
			)
		];
	console.log(eventData);
	return (
		<>
			<BootstrapModal.Header>
				<div className='fs-18 g-text d-inline mr-2'>{eventData.title} </div>
				<DatePicker.RangePicker
					inputReadOnly
					allowClear={false}
					open={false}
					className='mr-2'
					value={[moment(eventData.start), moment(eventData.end)]}
				/>
			</BootstrapModal.Header>
			<BootstrapModal.Body
				style={{ padding: 0, minHeight: '300px', maxHeight: '80vh' }}
			>
				<OpenEventModal
					currEvent={eventData}
					onHide={props.closeDisplayModal}
				/>
			</BootstrapModal.Body>
			<BootstrapModal.Footer>
				<Button onClick={props.closeDisplayModal}>Close</Button>
				{/* {user.type === 'Manager' ? (
					<Link to={'/maintenance/request/' + eventData.job_id}>
						<Button>Go To Full Request</Button>
					</Link>
				) : (
					<Link to={'/propertyMaintenance/request/' + eventData.job_id}>
						<Button>Go To Full Request</Button>
					</Link>
				)} */}
				{user.type === 'Manager' ? (
					<Link to={'/maintenance/request/' + eventData.job_id}>
						<Button>Go To Full Request</Button>
					</Link>
				) : (
					<Link
						to={{
							pathname: '/unitMaintenance/request/propertyUnitJR',
							state: {
								job_id: eventData.job_id,
								unit_id: eventData.unit_id
							}
						}}
					>
						<Button>Go To Full Request</Button>
					</Link>
				)}
			</BootstrapModal.Footer>
		</>
	);
}

function ReservationModal(props) {
	const {
		occupancyData,
		unitIndex,
		subCode,
		userType,
		uploadFromModal,
		delRes,
		isDeleting,
		isCensored
	} = props;
	const [trashHover, setTrashHover] = useState(false);
	let unitData = occupancyData[unitIndex];
	console.log(occupancyData);
	let taskData =
		occupancyData[unitIndex].reservations[
			occupancyData[unitIndex].reservations.findIndex(
				findRes =>
					findRes.reservation_code === subCode ||
					findRes.confirmation_code === subCode
			)
		];
	let resCode = taskData.confirmation_code || taskData.reservation_code;
	let guestName = taskData.guest_name;
	if (isCensored) {
		resCode = resCode.slice(0, 4) + '*'.repeat(resCode.slice(4).length);
		if (guestName) {
			guestName = guestName.split(' ')[0];
		}
	}
	let payoutVal = parseInt(taskData.total_payout);
	if (isNaN(payoutVal)) payoutVal = taskData.total_payout;
	else payoutVal = '$' + payoutVal.toFixed(2);
	if (!taskData.confirmation_code) {
		return (
			<>
				<h2 style={{ textAlign: 'center' }}>
					Reservation - {resCode}
					{!isCensored ? (
						isDeleting ? (
							<>
								&nbsp;
								<LoadingOutlined style={{ color: '#4d7cfe' }} />
							</>
						) : (
							<>
								&nbsp;
								<Popconfirm
									title='Are you sure you want to delete this reservation and data relating to it? This action cannot be reversed.'
									onConfirm={() => {
										delRes(taskData.unit_id, taskData.reservation_code);
									}}
									okText='Yes'
									cancelText='No'
								>
									<DeleteOutlined
										onMouseEnter={() => {
											setTrashHover(true);
										}}
										onMouseLeave={() => {
											setTrashHover(false);
										}}
										style={trashHover ? { color: '#4d7cfe' } : {}}
									/>
								</Popconfirm>
							</>
						)
					) : null}
				</h2>
				<Space direction='vertical'>
					<p>
						<span style={{ fontWeight: 'bold' }}>Unit:</span>{' '}
						{unitData.unit_label}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Listing Name:</span>{' '}
						{unitData.listing_name}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Start:</span>{' '}
						{makeDateString(taskData.start_date)}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>End:</span>{' '}
						{makeDateString(taskData.end_date)}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Cleaning Status:</span>{' '}
						{taskData.cleaning_status}
					</p>
					<p
						onClick={uploadFromModal}
						style={{
							cursor: 'pointer',
							fontStyle: 'italic',
							textDecoration: 'underline'
						}}
					>
						Upload a Reservation Spreadsheet to Add More Detail...
					</p>
				</Space>
			</>
		);
	} else {
		return (
			<>
				<h2 style={{ textAlign: 'center' }}>
					Reservation - {resCode}
					{!isCensored ? (
						isDeleting ? (
							<>
								&nbsp;
								<LoadingOutlined style={{ color: '#4d7cfe' }} />
							</>
						) : (
							<>
								&nbsp;
								<Popconfirm
									title='Are you sure you want to delete this reservation and data relating to it? This action cannot be reversed.'
									onConfirm={() => {
										delRes(taskData.unit_id, taskData.confirmation_code);
									}}
									okText='Yes'
									cancelText='No'
								>
									<DeleteOutlined
										onMouseEnter={() => {
											setTrashHover(true);
										}}
										onMouseLeave={() => {
											setTrashHover(false);
										}}
										style={trashHover ? { color: '#4d7cfe' } : {}}
									/>
								</Popconfirm>
							</>
						)
					) : null}
				</h2>
				<Space direction='vertical'>
					<p>
						<span style={{ fontWeight: 'bold' }}>Unit:</span>{' '}
						{unitData.unit_label}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Listing Name:</span>{' '}
						{unitData.listing_name}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Start:</span>{' '}
						{makeDateString(taskData.start_date)}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>End:</span>{' '}
						{makeDateString(taskData.end_date)}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Cleaning Status:</span>{' '}
						{taskData.cleaning_status}
					</p>
					{!isCensored ? (
						<p>
							<span style={{ fontWeight: 'bold' }}>Guest Name:</span>{' '}
							{guestName}
						</p>
					) : null}
					<p>
						<span style={{ fontWeight: 'bold' }}>Guest Status:</span>{' '}
						{taskData.status}
					</p>
					{!isCensored && taskData.contact_phone ? (
						<p>
							<span style={{ fontWeight: 'bold' }}>Contact Phone:</span>{' '}
							{taskData.contact_phone}
						</p>
					) : null}
					<p>
						<span style={{ fontWeight: 'bold' }}>Number of Adults:</span>{' '}
						{taskData.num_adults}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Number of Children:</span>{' '}
						{taskData.num_children}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Number of Infants:</span>{' '}
						{taskData.num_infants}
					</p>
					<p>
						<span style={{ fontWeight: 'bold' }}>Number of Nights:</span>{' '}
						{taskData.num_nights}
					</p>
					{taskData.booking_date ? (
						<p>
							<span style={{ fontWeight: 'bold' }}>Booking Date:</span>{' '}
							{makeDateString(taskData.booking_date)}
						</p>
					) : null}
					{taskData.cvd19_support_pay ? (
						<p>
							<span style={{ fontWeight: 'bold' }}>COVID 19 Support:</span>{' '}
							{taskData.cvd19_support_pay}
						</p>
					) : null}
					<p>
						<span style={{ fontWeight: 'bold' }}>Total Payout:</span>{' '}
						{payoutVal}
					</p>
					{taskData.reservation_amount ? (
						<p>
							<span style={{ fontWeight: 'bold' }}>Reservation Amount:</span>{' '}
							{taskData.reservation_amount}
						</p>
					) : null}
				</Space>
			</>
		);
	}
}

function InfoDisplayModal(props) {
	switch (props.displayType) {
		case 'reservation':
			return <ReservationModal {...props} />;
		case 'lease':
			return <LeaseModal {...props} />;
		case 'event':
			return <EventModal {...props} />;
	}
}

function unitTypeDisplay(unit) {
	if (unit.type === 'Not Available') {
		if (unit.maintenance_service > 0) return 'Maintenance';
		else if (unit.snow_service > 0) return 'Snow Clearing';
		else return unit.type;
	} else return unit.type;
}

class TaskManagementLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			calLoading: true,
			occupancyData: [],
			scrollDate: new Date(),
			lockOn: true,
			importModal: false,
			displayModal: false,
			displayModalData: null,
			conflictModal: false,
			selectedYear: 2020,
			selectedMonth: 0,
			selectedUnitNum: -1,
			selectedSubCode: '',
			isDeleting: false,
			isCensored: this.props.userType !== 'Manager',
			conflicts: [],
			calSyncData: null,
			modalDisplayType: '',
			manualModal: false
		};
	}

	componentDidMount = () => {
		document.title = 'Task Management';
		let currentYear = new Date();
		axios.get('/getTaskCalendar').then(response => {
			console.log(response.data.res_data);
			let taskData = JSON.parse(JSON.stringify(response.data.res_data));
			taskData.forEach(function (unit, unitIndex) {
				if (unit.lease_id !== null && unit.start_date !== null) {
					taskData[unitIndex].start_date = new Date(unit.start_date);
					taskData[unitIndex].start_date.setHours(15, 0, 0, 0);
					if (unit.end_date !== null) {
						taskData[unitIndex].end_date = new Date(unit.end_date);
						taskData[unitIndex].end_date.setHours(15, 0, 0, 0);
					}
				}
				unit.reservations.forEach(function (res, resIndex) {
					let startDate = new Date(res.start_date);
					startDate.setHours(15, 0, 0, 0);
					taskData[unitIndex].reservations[resIndex].start_date = new Date(
						startDate
					);
					let endDate = new Date(res.end_date);
					endDate.setHours(11, 0, 0, 0);
					taskData[unitIndex].reservations[resIndex].end_date = new Date(
						endDate
					);
				});
				unit.events.forEach((evnt, evntIndex) => {
					if (evnt.terminate !== 1)
						taskData[unitIndex].events[evntIndex].start_date = new Date(
							evnt.start
						);
					if (
						Math.abs(new Date(evnt.end) - new Date(evnt.start)) / 360000 <
						2.4
					) {
						let endingDate = new Date(evnt.start);
						endingDate.setHours(
							endingDate.getHours() + 2,
							endingDate.getMinutes() + 24,
							0,
							0
						);
						taskData[unitIndex].events[evntIndex].end_date = endingDate;
					} else
						taskData[unitIndex].events[evntIndex].end_date = new Date(evnt.end);
				});
			});
			this.setState({
				occupancyData: taskData,
				selectedYear: currentYear.getFullYear(),
				selectedMonth: currentYear.getMonth(),
				calLoading: false
			});
			let allConflicts = [];
			taskData.forEach((unit, unitIndex) => {
				let conflictIndex = 0;
				let dateList = [];
				let conflictList = {};
				let newConflict;
				unit.reservations.forEach((res, resIndex) => {
					newConflict = -1;
					let matchIndex = dateList.findIndex(dateRange => {
						return (
							(res.start_date >= dateRange[0] &&
								res.start_date <= dateRange[1]) ||
							(res.end_date >= dateRange[0] && res.end_date <= dateRange[1])
						);
					});
					if (matchIndex >= 0) {
						let foundConfIndex = dateList[matchIndex][3];
						if (foundConfIndex > -1) {
							newConflict = foundConfIndex;
							conflictList[foundConfIndex].conflicts.push(resIndex);
						} else {
							newConflict = conflictIndex++;
							dateList[matchIndex][3] = newConflict;
							conflictList[newConflict] = {
								conflicts: [dateList[matchIndex][2], resIndex]
							};
						}
					}
					dateList.push([res.start_date, res.end_date, resIndex, newConflict]);
				});
				if (Object.keys(conflictList).length > 0) {
					allConflicts.push({ index: unitIndex, conflicts: conflictList });
				}
			});
			this.setState({ conflicts: allConflicts });
		});
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (
			prevState.calLoading &&
			!this.state.calLoading &&
			this.state.selectedUnitNum > 0
		) {
			this.setState({ displayModal: true });
		}
	};

	reloadCalendar = () => {
		this.setState({ calLoading: true, displayModal: false });
		let currentYear = new Date();
		axios.get('/getTaskCalendar').then(response => {
			let taskData = JSON.parse(JSON.stringify(response.data.res_data));
			taskData.forEach(function (unit, unitIndex) {
				if (unit.lease_id !== null && unit.start_date !== null) {
					taskData[unitIndex].start_date = new Date(unit.start_date);
					taskData[unitIndex].start_date.setHours(15, 0, 0, 0);
					if (unit.end_date !== null) {
						taskData[unitIndex].end_date = new Date(unit.end_date);
						taskData[unitIndex].end_date.setHours(15, 0, 0, 0);
					}
				}
				unit.reservations.forEach(function (res, resIndex) {
					let startDate = new Date(res.start_date);
					startDate.setHours(15, 0, 0, 0);
					taskData[unitIndex].reservations[resIndex].start_date = new Date(
						startDate
					);
					let endDate = new Date(res.end_date);
					endDate.setHours(11, 0, 0, 0);
					taskData[unitIndex].reservations[resIndex].end_date = new Date(
						endDate
					);
				});
				unit.events.forEach((evnt, evntIndex) => {
					let endingDate = new Date(evnt.start);
					taskData[unitIndex].events[evntIndex].start_date = new Date(
						evnt.start
					);
					endingDate.setHours(
						endingDate.getHours() + 2,
						endingDate.getMinutes() + 24,
						0,
						0
					);
					taskData[unitIndex].events[evntIndex].end_date = endingDate;
				});
			});
			this.setState({
				lockOn: true,
				occupancyData: taskData,
				selectedYear: currentYear.getFullYear(),
				selectedMonth: currentYear.getMonth(),
				calLoading: false
			});
			let allConflicts = [];
			taskData.forEach((unit, unitIndex) => {
				if (unit.type === 'Short Term') {
					let conflictIndex = 0;
					let dateList = [];
					let conflictList = {};
					let newConflict;
					unit.reservations.forEach((res, resIndex) => {
						newConflict = -1;
						let matchIndex = dateList.findIndex(dateRange => {
							return (
								(res.start_date >= dateRange[0] &&
									res.start_date <= dateRange[1]) ||
								(res.end_date >= dateRange[0] && res.end_date <= dateRange[1])
							);
						});
						if (matchIndex >= 0) {
							let foundConfIndex = dateList[matchIndex][3];
							if (foundConfIndex > -1) {
								newConflict = foundConfIndex;
								conflictList[foundConfIndex].conflicts.push(resIndex);
							} else {
								newConflict = conflictIndex++;
								dateList[matchIndex][3] = newConflict;
								conflictList[newConflict] = {
									conflicts: [dateList[matchIndex][2], resIndex]
								};
							}
						}
						dateList.push([
							res.start_date,
							res.end_date,
							resIndex,
							newConflict
						]);
					});
					if (Object.keys(conflictList).length > 0) {
						allConflicts.push({ index: unitIndex, conflicts: conflictList });
					}
				}
			});
			this.setState({ conflicts: allConflicts });
		});
	};

	openConflictModal = () => {
		if (!this.state.conflictModal) {
			modalKey = !modalKey;
		}
		this.setState({ conflictModal: !this.state.conflictModal });
	};

	resolveSuccess = () => {
		message.success('Data has been successfully imported.');
		this.openConflictModal();
		this.reloadCalendar();
	};
	resolveError = () => {
		message.error('Resolve error, please try again.');
	};

	importSuccess = () => {
		message.success('Data has been successfully imported.');
		this.openImportModal();
		this.reloadCalendar();
	};

	openImportModal = () => {
		this.setState({ importModal: !this.state.importModal });
	};
	openManualModal = () => {
		this.setState({ manualModal: !this.state.manualModal });
	};

	importSuccess = () => {
		message.success('Data has been successfully imported.');
		this.openImportModal();
		this.reloadCalendar();
	};
	importError = () => {
		message.error('Upload error, please try again.');
	};

	setModalValues = (unitNum, subCode, displayType = '') => {
		this.setState({
			selectedUnitNum: unitNum,
			selectedSubCode: subCode,
			modalDisplayType: displayType
		});
	};

	closeDisplayModal = () => {
		this.setState({
			selectedUnitNum: -1,
			selectedSubCode: '',
			modalDisplayType: '',
			displayModal: false
		});
	};

	pushHistory = url => {
		this.props.history.push(url);
	};

	deleteReservationData = (unitId, resCode) => {
		this.setState({ isDeleting: true });
		axios
			.post('/delAirbnbReservation', { unitId: unitId, resCode: resCode })
			.then(response => {
				if (response.data.reload) {
					this.setModalValues(-1, '');
					this.reloadCalendar();
					message.success('Data has been successfully deleted.');
					this.setState({ isDeleting: false });
				} else {
					this.setState({ isDeleting: false });
					message.error(
						'Error deleting reservation, are you sure you have the correct permissions?'
					);
				}
			})
			.catch(err => {
				this.setState({ isDeleting: false });
				message.error(
					'Error deleting reservation, are you sure you have the correct permissions?'
				);
			});
	};

	updateSyncData = newSyncData => {
		this.setState({ calSyncData: newSyncData });
	};

	render() {
		console.log(this.state.occupancyData);
		let isConflicts = this.state.conflicts.length > 0;
		if (!this.state.calLoading)
			if (this.state.occupancyData.length > 0)
				return (
					<div style={{ padding: '24px' }}>
						<Modal
							zIndex={
								this.state.calSyncData
									? this.state.calSyncData.highestZ + 2
									: 'auto'
							}
							open={this.state.importModal}
							onCancel={this.openImportModal}
							size='sm'
							aria-labelledby='assign-vendor-modal'
							centered
							footer={null}
						>
							{this.state.importModal ? (
								<AirbnbUpload
									success={this.importSuccess}
									importError={this.importError}
									spreadsheetType='reservations'
									title='Import Airbnb Reservations'
									onHide={this.openImportModal}
								/>
							) : (
								''
							)}
						</Modal>
						<Modal
							zIndex={
								this.state.calSyncData
									? this.state.calSyncData.highestZ + 2
									: 'auto'
							}
							open={this.state.conflictModal}
							onCancel={this.openConflictModal}
							size='sm'
							centered
							footer={null}
						>
							{this.state.conflictModal ? (
								<ConflictModal
									modalKey={modalKey}
									resData={this.state.occupancyData}
									conflicts={this.state.conflicts}
									success={this.resolveSuccess}
									error={this.resolveError}
									onHide={this.openConflictModal}
								/>
							) : null}
						</Modal>

						{this.state.manualModal && (
							<ManualReservation
								showModal={this.state.manualModal}
								closeModal={this.openManualModal}
							/>
						)}

						<div
							style={{
								width: '100%',
								position: 'sticky',
								top: '0px',
								zIndex: this.state.calSyncData
									? this.state.calSyncData.highestZ
									: 'auto',
								backgroundColor: '#f8fafb'
							}}
						>
							<div style={{ width: blockSize * 4.5, float: 'left' }}>
								<Button
									style={{ float: 'left', width: '25%', padding: 0 }}
									onClick={e => {
										let thisDate = new Date();
										this.setState({
											scrollDate: thisDate,
											lockOn: true,
											selectedYear: thisDate.getFullYear(),
											selectedMonth: thisDate.getMonth()
										});
									}}
								>
									Today
								</Button>
								<DatePicker
									style={{ float: 'left', width: '75%' }}
									allowClear={false}
									placeholder=''
									onChange={e => {
										if (e !== null) {
											let selectedDate = new Date(
												e._d.getFullYear(),
												e._d.getMonth(),
												1
											);
											this.setState({
												scrollDate: selectedDate,
												lockOn: true,
												selectedYear: e._d.getFullYear(),
												selectedMonth: e._d.getMonth()
											});
										}
									}}
									picker='month'
									value={moment(
										new Date(
											this.state.selectedYear,
											this.state.selectedMonth,
											1
										)
									)}
								/>
								<TabletTwoTone
									style={{
										clear: 'left',
										float: 'left',
										marginTop: 5,
										marginRight: 20,
										marginLeft: 2
										// width: '50%'
										// width: isConflicts ? '50%' : '100%'
									}}
									title='Manual Booking'
									alt='manualBooking'
									onClick={this.openManualModal}
								/>

								<Button
									type='primary'
									style={{
										// clear: 'left',
										float: 'left',
										marginTop: 5,
										// marginLeft: 40,
										width: '50%'
										// width: isConflicts ? '50%' : '100%'
									}}
									onClick={this.openImportModal}
								>
									Upload
								</Button>
								{isConflicts ? (
									<Button
										onClick={this.openConflictModal}
										type='text'
										className='alertButton'
										icon={<ExclamationCircleFilled />}
									></Button>
								) : null}
							</div>

							{this.state.calSyncData ? (
								<div>
									<CalendarHeader
										calendarView='month'
										interactMode='clickable'
										{...this.state.calSyncData}
										blockWidth={blockSize}
										blockHeight={blockSize}
									/>
								</div>
							) : null}
						</div>
						<Modal
							zIndex={
								this.state.calSyncData
									? this.state.calSyncData.highestZ + 1
									: 'auto'
							}
							centered
							size='sm'
							open={this.state.displayModal}
							onCancel={() => {
								this.setState({ displayModal: false });
							}}
							afterClose={() => {
								this.setModalValues(-1, '');
							}}
							footer={null}
						>
							{this.state.selectedUnitNum >= 0 ? (
								<InfoDisplayModal
									displayType={this.state.modalDisplayType}
									occupancyData={this.state.occupancyData}
									unitIndex={this.state.selectedUnitNum}
									subCode={this.state.selectedSubCode}
									userType={this.props.userType}
									uploadFromModal={this.openImportModal}
									delRes={this.deleteReservationData}
									isDeleting={this.state.isDeleting}
									isCensored={this.state.isCensored}
									closeDisplayModal={this.closeDisplayModal}
								/>
							) : null}
						</Modal>
						<div style={{ width: '100%' }}>
							<BarCalendar
								hideHeader
								showTimeBar
								dataSource={[
									this.state.occupancyData.map((unit, unitnum) => {
										//EVENT BLOCKS
										return {
											bars: unit.events.map((evnt, evntindex) => {
												if (evnt.complete === 0) {
													return {
														startDate: evnt.start_date,
														endDate: evnt.end_date,
														eventStyle: 'Event',
														extraEventData: {
															blockType: 'event',
															unitnum: unitnum,
															subcode: evnt.idcalendar_events
														}
													};
												} else {
													return {
														startDate: evnt.start_date,
														endDate: evnt.end_date,
														eventStyle: 'Completed',
														extraEventData: {
															blockType: 'event',
															unitnum: unitnum,
															subcode: evnt.idcalendar_events
														}
													};
												}
											}),
											group_label: (
												<div style={{ padding: '5px' }}>
													<div
														style={{
															width: '60%',
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															float: 'left'
														}}
													>
														{unit.unit_label}
													</div>
													<div
														style={{
															width: '40%',
															float: 'left',
															fontSize: '80%',
															textAlign: 'right'
														}}
													>
														{unitTypeDisplay(unit)}
													</div>
													{unit.listing_name ? (
														<div
															style={{
																width: '50%',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																whiteSpace: 'nowrap'
															}}
														>
															<span style={{ fontSize: '80%' }}>
																{unit.listing_name}
															</span>
														</div>
													) : null}
												</div>
											)
										};
									}),
									this.state.occupancyData.map((unit, unitnum) => {
										//RES BLOCKS
										return {
											bars:
												unit.type === 'Short Term' && unit.ready === 1
													? unit.reservations.map(res => {
															let numPeople =
																'guest_name' in res
																	? res.num_adults +
																	  res.num_children +
																	  res.num_infants
																	: 0;
															let payoutVal = parseInt(res.total_payout);
															if (isNaN(payoutVal))
																payoutVal = res.total_payout;
															else payoutVal = '$' + payoutVal.toFixed(2);
															return {
																startDate: res.start_date,
																endDate: res.end_date,
																eventStyle:
																	res.end_date < Date.now()
																		? 'PastOccupy'
																		: res.cleaning_status,
																caption:
																	numPeople > 0
																		? `${
																				this.state.isCensored
																					? res.guest_name.split(' ')[0]
																					: res.guest_name
																		  } - ${numPeople} ${
																				numPeople > 1 ? 'people' : 'person'
																		  } - ${payoutVal}`
																		: '',
																extraEventData: {
																	blockType: 'reservation',
																	subcode:
																		res.reservation_code ||
																		res.confirmation_code,
																	unitnum: unitnum
																}
															};
													  })
													: unit.start_date !== null && unit.ready === 1
													? [
															{
																startDate: unit.start_date,
																endDate: unit.end_date,
																eventStyle:
																	unit.end_date !== null &&
																	unit.end_date < Date.now()
																		? 'PastOccupy'
																		: 'Lease',
																caption: unit.lease_type,
																extraEventData: {
																	blockType: 'lease',
																	unitnum: unitnum
																}
															}
													  ]
													: unit.ready === 0
													? [
															{
																startDate: unit.start_date,
																endDate: unit.end_date,
																eventStyle:
																	unit.end_date !== null &&
																	unit.end_date < Date.now()
																		? 'PastOccupy'
																		: 'Unit not ready for action',
																caption: unit.lease_type,
																extraEventData: {
																	blockType: 'lease',
																	unitnum: unitnum
																}
															}
													  ]
													: []
										};
									})
								]}
								eventStyles={{
									default: {
										single: {
											opacity: 0.65,
											backgroundColor: '#98a9bc'
										}
									},
									'Not Ready': {
										single: {
											opacity: 0.65,
											backgroundColor: '#ff0079'
										}
									},
									'In Progress': {
										single: {
											opacity: 0.65,
											border: 'solid 1px #6dd230',
											backgroundImage:
												'repeating-linear-gradient(-45deg, #6dd230, #6dd230 20px, #f2f3f6 20px, #f2f3f6 40px)'
										}
									},
									Completed: {
										single: {
											opacity: 0.65,
											backgroundColor: 'rgb(4 181 79)'
										}
									},
									PastOccupy: {
										single: {
											opacity: 0.65,
											backgroundColor: '#98a9bc'
										}
									},
									Event: {
										single: {
											opacity: 0.65,
											backgroundColor: '#2ce5f6'
										}
									},
									Lease: {
										single: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe'
										}
									},
									'Unit not ready for action': {
										single: {
											opacity: 0.65,
											backgroundColor: 'rgb(255 0 0)'
										}
									}
								}}
								calendarView='month'
								interactMode='clickable'
								orientation='horizontal'
								newEvent={newEvent => {}}
								editEvent={newData => {}}
								clickEvent={eventData => {
									this.setModalValues(
										eventData.extraEventData.unitnum,
										eventData.extraEventData.subcode,
										eventData.extraEventData.blockType
									);
									this.setState({ displayModal: true });
								}}
								blockWidth={blockSize}
								blockHeight={blockSize}
								updateLock={e => {
									this.setState({ lockOn: e });
								}}
								lockOn={this.state.lockOn}
								targetDate={this.state.scrollDate}
								updateSyncData={this.updateSyncData}
							/>
						</div>
					</div>
				);
			else
				return (
					<Result
						status='404'
						title='No AirBnB Units Found'
						subTitle='Please contact support if this is incorrect'
					/>
				);
		else
			return (
				<div className='h100'>
					<LoadScreen />
				</div>
			);
	}
}

export default TaskManagementLayout;
