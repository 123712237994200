import React from 'react';
import { Form } from "react-bootstrap";
import {InputNumber,Checkbox,Button, message,Alert} from 'antd'
import Col from 'react-bootstrap/Col'
import axios from "axios";

import LoadScreen from '../../../Components/LoadScreen';
import {vendorProvinces} from '../../../StringData/VendorObjects'
import RequestContext from '../../MaintenanceRequest/RequestContext';
const cancelToken = axios.CancelToken.source();


class PropertyAddressIntake extends React.Component {
	static contextType = RequestContext
	constructor(props) {
		super(props);
		this.state = {
			streetAddress: "",
			streetNum: "",
			city: "",
			province: "",
			postalCode: "",
			type: "",
            totalUnits: 1,
            areTenants:false,
			submitted:false,
			loaded:false
		};
	}
	
	verify = () => {
		if(this.state.streetAddress === '') {
		  this.setState({errorMessage: 'Please enter a street address.'});
		  return false;
		}
		else if(this.state.streetNum === '' || isNaN(parseInt(this.state.streetNum))){
		  this.setState({errorMessage: 'Please enter an street number.'});
		  return false;
		}
		else if(this.state.city === '' ){
		  this.setState({errorMessage: 'Please enter a city.'});
		  return false;
		}
		else if(this.state.province === ''){
			this.setState({errorMessage: 'Please enter a province.'});
			return false;
		}
		else if(this.state.postalCode === ''){
			this.setState({errorMessage: 'Please enter a postal code.'});
			return false;
		}
		else if(this.state.type === ''){
			this.setState({errorMessage: 'Please enter a province.'});
			return false;
		}
		else if(this.state.totalUnits < 1 || this.state.totalUnits > 100){
			this.setState({errorMessage: 'Please enter valid units.'});
			return false;
		}
		
		return true
	}
	
	closeError = () => this.setState({errorMessage:null})

	handleChange = (e) => {
		this.setState({[e.target.name]:e.target.value})
	}
  
	handleChangeSelect = (e) => {
		this.setState({[e.target.name]:e.persist()})
	}
	handleChangeType = (e) => {
		this.resetPropData("type", e.persist());
		this.setState({type: e.persist()})
	}
  //Submitting form
	submitForm = () => {
		if(this.verify()) {
			this.setState({submitted:true})
			axios
			.post("/intake/update/addressDetails", {...this.state,tasks:this.context.tasks,propertyid:this.context.propertyid})
			.then((response) => {
				this.context.updateTaskList(response.data.tasks);
				let property = response.data.property;
				axios.post("/intake/update/propertyUnits",{totalUnits:this.state.totalUnits,propertyid:this.context.propertyid})
				.then((response2) => {
					property.units = response2.data.units
					this.context.updateProperty(property);
					message.success("Property Address has been successfully updated")
					this.props.onCancel();
				})
				.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
		}
	};
	
	render() {

        let disabled = (this.state.streetNum === '' || this.state.streetAddress === '' || this.state.city === '' || this.state.postalCode === '' || this.state.type === '')
        if(this.state.submitted){ return <LoadScreen small className='m-auto'/>}
		return (
		  <div>
			<Form> 
				<Form.Row>
					<Form.Group as={Col} sm={2} md={3}>
						<Form.Label className='form-label'>Street Number </Form.Label>
						<Form.Control
							required
							className='form-text'
							name='streetNum'
							id="streetNum"
							type='text'
							value = {this.state.streetNum}
							onChange={this.handleChange}
						/>
					</Form.Group>
					
					<Form.Group as={Col}>
						<Form.Label className='form-label'>Street</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='streetAddress'
							type='text'
							value = {this.state.streetAddress}
							onChange={this.handleChange}
						/>
					</Form.Group>
				</Form.Row>
				
				<Form.Row>
					<Form.Group as={Col} sm={3} md={4}>
						<Form.Label className='form-label'>City</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='city'
							type='text'
							
							value= {this.state.city}
							onChange={this.handleChange}
						/>
					</Form.Group>
					
					<Form.Group as={Col}>
						<Form.Label className='form-label'>Postal Code</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='postalCode'
							type='text'
							
							value= {this.state.postalCode}
							onChange={this.handleChange}
						/>
					</Form.Group>
						
					<Form.Group as={Col}>
						<Form.Label className='form-label'>Province</Form.Label>
						<select name="province" className="form-control" defaultValue = {this.state.province} onChange={(e) => this.setState({ province: e.target.value })}>
							<option value={""}>Province</option>
							{Object.keys(vendorProvinces).map((province) => (
								<option value={province}>{vendorProvinces[province]}</option>
							))}
						</select>
					</Form.Group>
				</Form.Row>
				
				<Form.Row>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label htmlFor ="type" className='form-label'>Type</Form.Label>
						<select name="type" id="type" className="form-control" defaultValue= {this.state.type} onChange={(e) => {this.setState({ type: e.target.value })}}>
							<option value="" disabled hidden>Select Type</option>
                            <option value="Single Family"> Single Family </option>
							<option value="House with Apartment"> House with Apartment </option>
							<option value="Duplex"> Duplex </option>
							<option value="Multi-unit Residence"> Multi-unit Residence </option>
							<option value="Commerical"> Commerical </option>
						</select>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Total Units</Form.Label>
						<InputNumber min={1} max={100} defaultValue={1} onChange={(e) => this.setState({totalUnits: e})} />
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Checkbox checked={this.state.areTenants} onChange={(e) => {this.setState({areTenants: e.target.checked})}}> There are currently Tenants living in the property </Checkbox>
					</Form.Group>
				</Form.Row>
				
				
				{this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}	
			<br></br>
			<div className='text-right'>
                <Button disabled={disabled} onClick={this.submitForm} type='primary'>Submit</Button>
            </div>
			  
			  

			</Form>
		  </div>
		);
	}	
}

export default PropertyAddressIntake