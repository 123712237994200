import React from "react";
import { Button } from "antd";

import TimePicker from "rc-time-picker"; //TODO: Change to ant 
import moment from "moment";
let format = "hh:mma";

class EndSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOut:
        this.props.endSessionData.timeOut === null
          ? moment()
          : this.props.endSessionData.timeOut,
      totalKM: this.props.endSessionData.totalKM,
      extensionNotes: "",
      disableBtn: false,
    };
    //this.totaldata = this.totaldata.bind(this);
  }
  updateTime = (time) => {
    this.setState({ timeOut: time });
  };
  updatekm = (e) => {
    this.setState({ totalKM: e.target.value });
  };
  updateExtension = (e) => {
    this.setState({ extensionNotes: e.target.value });
  };
  completeSess = () => {
    this.props.updateEndSession(
      this.state.timeOut !== null ? this.state.timeOut : moment(),
      this.state.totalKM,
      this.state.extensionNotes
    );
    this.setState({ disableBtn: true });
  };
  render() {
    let btnTxt;
    if (this.props.nextStatus === "Extend")
      btnTxt = "End and Request Extension";
    else if (this.props.nextStatus === "Continue")
      btnTxt = "Save And Continue Later";
    else if(this.props.nextStatus === 'Approval')
      btnTxt = 'Save Progress and Request Approval'
    else btnTxt = `Complete Work Order`;
    return (
      <div className='endSession'>
        <h4 className='text-center mb-4'>End of Session</h4>
        <br />
        <div className='fixedLine'>
          <label htmlFor='timeOut' className='name txtLabel mr-4'>
            Time out
          </label>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.timeOut}
            className='width39'
            onChange={(time) => this.updateTime(time)}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>
        <br />
        <div className='fixedLine'>
          <label htmlFor='totalKM' className='name mr-4 txtLabel'>
            {" "}
            Total KM
          </label>
          <input
            id='totalKM'
            defaultValue={this.state.totalKM}
            placeholder='0'
            onChange={this.updatekm}
            className='form-control width40 txtInput'
          />
        </div>
        <br />
        {this.props.nextStatus === "Extend" ? (
          <>
            <label>Reason for Extension</label>
            <br />
            <textarea
              onChange={this.updateExtension}
              defaultValue={this.state.extensionNotes}
              rows={5}
              cols={50}
            />
          </>
        ) : (
          ""
        )}
        <br />
        <div className='text-right buttonGroup'>
          <Button className='mr-2 ' onClick={this.props.goExpenses}>
            Enter Expenses
          </Button>
          <Button
            type='primary'
            onClick={this.completeSess}
            disabled={this.state.disableBtn}>
            {btnTxt}
          </Button>
        </div>
      </div>
    );
  }
}

export default EndSession;
