import React from 'react';
import axios from 'axios';

import RequestContext from '../RequestContext';
import LoadScreen from '../../../Components/LoadScreen';
const cancelToken = axios.CancelToken.source();

class ReservationDetails extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super();
		this.state = {
			reservationData: undefined
		};
	}

	componentDidMount = () => {
		// console.log(this.context.request.job_id);
		axios
			.post('/jobRequest/get/reservationDetails', {
				job_id: this.context.request.job_id
			})
			.then(response => {
				this.setState({ reservationData: response.data.reservationData });
			})
			.catch(err => console.log(err));
	};

	render() {
		if (this.state.reservationData === undefined)
			return <LoadScreen className='m-auto' small />;
		else if (this.state.reservationData === null)
			return (
				<div className='g-text-sm'>Reservation data could not be found</div>
			);
		else {
			return (
				<div>
					<span style={{ fontWeight: 'bold' }}>Reservation</span> -{' '}
					{'reservation_code' in this.state.reservationData
						? this.state.reservationData.reservation_code
						: this.state.reservationData.confirmation_code}
					{this.state.reservationData !== null ? (
						<>
							<br />
							<span style={{ fontWeight: 'bold' }}>Listing Name:</span>{' '}
							{this.state.reservationData.listing_name}
						</>
					) : null}
					<br />
					<span style={{ fontWeight: 'bold' }}>Start:</span>{' '}
					{new Date(this.state.reservationData.start_date).toLocaleDateString(
						'en-US',
						{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
					)}
					<br />
					<span style={{ fontWeight: 'bold' }}>End:</span>{' '}
					{new Date(this.state.reservationData.end_date).toLocaleDateString(
						'en-US',
						{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
					)}
					{'guest_name' in this.state.reservationData ? (
						<>
							<br />
							<span style={{ fontWeight: 'bold' }}>Guest:</span>{' '}
							{this.state.reservationData.guest_name}
						</>
					) : null}
				</div>
			);
		}
	}
}

export default ReservationDetails;
