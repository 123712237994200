import React from "react";
import "antd/dist/antd.css";
import { Menu, Modal, Button } from "antd";
import { HomeOutlined, UserOutlined, ProfileOutlined } from "@ant-design/icons";
import FeatureList from "./FeatureList";

const { SubMenu } = Menu;

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: {},
      name: props.name,
      featureListName: "Appliances",
      modalVisible: false,
    };
    this.addNewMenu = props.menu;
    this.addNewFeature = this.addNewFeature.bind(this);
    this.afterOk = this.afterOk.bind(this);
    this.afterCancel = this.afterCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteRoom = this.deleteRoom.bind(this);
    this.deleteFeatureList = this.deleteFeatureList.bind(this);

    if (Object.keys(props.featureLists).length > 0) {
      Object.keys(props.featureLists).map((key) => {
        let newKey = 0;
        Object.keys(this.state.features).length !== 0
          ? (newKey =
              parseInt(
                Object.keys(this.state.features)[
                  Object.keys(this.state.features).length - 1
                ]
              ) + 1)
          : (newKey = 0);
        this.state.features[newKey] = {
          key: newKey,
          featureList: (
            <FeatureList
              features={props.featureLists[key]}
              roomId={this.props.listId}
              deleteMenu={this.props.deleteMenu}
              listId={newKey}
              delete={this.deleteFeatureList}
              roomName={this.props.name}
              selfName={key}
              menu={this.props.menu}
              selected={this.props.selected}
            />
          ),
        };
      });
    }
  }

  addNewFeature() {
    this.setState({ modalVisible: true });
  }

  componentDidMount() {
    this.setState({});
  }

  afterOk() {
    if (Object.keys(this.state.features).length !== 0) {
      let latestKey = Object.keys(this.state.features)[
        Object.keys(this.state.features).length - 1
      ];
      let newKey = parseInt(latestKey) + 1;
      let d = new Date();
      let n = d.getTime();
      this.state.features[newKey] = {
        key: this.state.featureListName + n,
        featureList: (
          <FeatureList
            features={{}}
            roomId={this.props.listId}
            deleteMenu={this.props.deleteMenu}
            listId={newKey}
            delete={this.deleteFeatureList}
            roomName={this.props.name}
            selfName={this.state.featureListName}
            menu={this.props.menu}
            selected={this.props.selected}
          />
        ),
      };
      this.setState({
        features: this.state.features,
        modalVisible: false,
        featureListName: this.state.featureListName,
      });
    } else {
      let d = new Date();
      let n = d.getTime();
      this.state.features[0] = {
        key: this.state.featureListName + n,
        featureList: (
          <FeatureList
            features={{}}
            roomId={this.props.listId}
            deleteMenu={this.props.deleteMenu}
            listId={0}
            delete={this.deleteFeatureList}
            roomName={this.props.name}
            selfName={this.state.featureListName}
            menu={this.props.menu}
            selected={this.props.selected}
          />
        ),
      };
      this.setState({
        features: this.state.features,
        modalVisible: false,
        featureListName: this.state.featureListName,
      });
    }
  }

  afterCancel() {
    this.setState({ modalVisible: false });
  }

  handleChange(event) {
    this.setState({ featureListName: event.target.value });
  }

  deleteRoom() {
    let arr = this.props.getMenus();
    Object.keys(arr).map((menuId) => {
      if (
        arr[menuId].room === this.state.name &&
        arr[menuId].roomId === this.props.listId
      ) {
        this.props.deleteMenu(menuId);
      }
    });
    this.props.delete(this.props.listId);
    this.setState();
  }

  deleteFeatureList(listId, features) {
    Object.keys(features).map((item) => this.props.deleteMenu(item));
    delete this.state.features[listId];
    let arr = this.state.rooms;
    this.setState({ rooms: arr });
  }

  exterior() {
    if (this.state.name == "Exterior") {
      return (
        <form>
          <label>
            Pick a Feature List:
            <select
              value={this.state.featureListName}
              onChange={this.handleChange}
            >
              <option value="Appliances">Appliances</option>
              <option value="Siding">Siding</option>
              <option value="Doors">Doors</option>
            </select>
          </label>
        </form>
      );
    } else {
      return (
        <form>
          <label>
            Pick a Feature List:
            <select
              value={this.state.featureListName}
              onChange={this.handleChange}
            >
              <option value="Appliances">Appliances</option>
              <option value="Walls">Walls</option>
              <option value="Flooring">Flooring</option>
              <option value="Misc. Features">Misc. Features</option>
            </select>
          </label>
        </form>
      );
    }
  }

  render() {
    return (
      <Menu
        mode="inline"
        inlineIndent="10"
        style={{ float: "top", width: "100%", borderRight: "0" }}
        forceSubMenuRender={true}
      >
        <SubMenu
          key={this.state.name}
          icon={<HomeOutlined />}
          title={this.state.name}
        >
          {
            //this.props.deleteAllowed ? <div><Button style={{textAlign:"left", textIndent:8, fontSize:14}} block={true} type="text" onClick={() => this.deleteRoom()}>Remove {this.state.name}</Button></div> : <></>
          }
          {Object.keys(this.state.features).map((feature) => (
            <Menu
              forceSubMenuRender={true}
              key={this.state.features[feature].key}
              style={{borderRight: "0"}}
            >
              {this.state.features[feature].featureList}
            </Menu>
          ))}
          <div>
            <Button
              type="text"
              onClick={this.addNewFeature}
              block={true}
              style={{ textAlign: "left", textIndent: 8, fontSize: 14 }}
            >
              New Feature List
            </Button>
          </div>
        </SubMenu>

        <Modal
          title="Feature List Details"
          visible={this.state.modalVisible}
          onOk={this.afterOk}
          onCancel={this.afterCancel}
        >
          {this.exterior()}
        </Modal>
      </Menu>
    );
  }
}

export default Room;
