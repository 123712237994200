import React,{useState} from "react";
import moment from "moment";
import axios from "axios";

import { Button ,Table, Space, Alert ,message} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {vendorProvinces} from '../../../StringData/VendorObjects'
import LoadScreen from '../../../Components/LoadScreen'
import { Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col'
import {Searchbar} from '../../../Components/Searchbar/Searchbar'
import {createUseStyles} from 'react-jss'
import RequestContext from "../../MaintenanceRequest/RequestContext";
const cancelToken = axios.CancelToken.source();


class AddOwner extends React.Component{
  static contextType = RequestContext;
  constructor(props) {
    super(props);
    this.state ={
      ownerList : [],
      existingOwnerList:null,
      page:'view',
      submitting:false

    }
    this.submitlist = this.submitlist.bind(this)
  }
  
  submitlist(){
    this.setState({submitting:true});
    axios.post("/intake/property/add/Owners",
    {
    ownerList: this.state.ownerList, 
    propertyid: this.context.propertyid,
    tasks: this.context.tasks
    })
    .then((response) => {
      this.context.updateOwners(response.data.ownerList);
      this.context.updateTaskList(response.data.tasks);
      message.success("Owners have been successfully connected")
      this.props.onCancel();
    });
}



  addNewOwner = (newOwner) => {
      if(this.state.page === 'Add'){
        this.setState({ ownerList: [...this.state.ownerList, newOwner ], page:"view" });
      }
      else{ 
        let rows = [... this.state.ownerList];
        rows = rows.filter((o) => o.email === newOwner.email);

        this.setState({ownerList:[...rows, newOwner],page:"View"});

      }
    }

  deleterow = (e) => {
      const email = e.currentTarget.dataset.id;
      this.setState({
       ownerList:this.state.ownerList.filter(e  => e.email !== email)
      });
   }

  editRow =(e) => {
    const email = e.currentTarget.dataset.id;
		const recordChange = this.state.ownerList.find(element => element.email == email);
    this.setState({editOwner:recordChange}, () => this.setState({page:'Edit'}));
  }
  
  openNewOwnerForm = () => this.setState({page:"Add"})
  openEditOwnerForm = () => this.setState({page:"Edit"})
  openExisitngOwnerForm = () => this.setState({page:"Existing"})
  backToView = () => this.setState({page:"view"})
  setExistingOwnerList = (owners) => this.setState({existingOwnerList:owners});

  addExistingOwners = (owner=[]) => {
    if(typeof(owner) !== 	"object") return;
    let newList = owner.concat(this.state.ownerList)
    this.setState({ownerList:newList,page:"view"})
  }


  render() {

    const columns = [
      {title: 'First Name',dataIndex: 'fname',key: 'fname'},  
      {title: 'Last Name',  key: 'lname',     dataIndex: 'lname'},
      {title: 'Email',      key: 'email',     dataIndex: 'email'},
      {
        title: 'Actions',
        key: 'action',
        render: (text,record) => (
          record.type === 'Existing' ? "" :
          <Space size="middle">
            <a data-id={record.email} onClick={this.deleterow}><DeleteOutlined /></a>
            {record.new ? <a data-id={record.email} onClick={this.editRow}><EditOutlined /></a> : ""}
          </Space>
        ),
      },
    ];

    if(this.state.page === 'Add' || this.state.page === 'Edit'){
      return( <AddNewOwner page={this.state.page}  backToView={this.backToView} editOwner={this.state.editOwner || null} addNewOwner={this.addNewOwner} />)
     
    }
    else if(this.state.page === 'Existing'){
      return(
        <SelectExistingOwner backToView={this.backToView} existingOwnerList={this.state.existingOwnerList} setExistingOwnerList={this.setExistingOwnerList} ownerList={this.state.ownerList} addExistingOwners={this.addExistingOwners}/>
      )

    }
		else{
      return(
        <div>
          <div className="text-right mb-2"> 
              <Button type="primary" className='mr-2' onClick ={this.openNewOwnerForm}>
                Add New Owner
              </Button>
              <Button type="primary" onClick ={this.openExisitngOwnerForm}>
                Add Existing Owner
              </Button>
          </div>
          <div>< Table columns={columns} dataSource={this.state.ownerList} pagination={false} size='small' /></div>
          <div  className='mt-4 text-right'>
            <Button onClick={this.submitlist} disabled={this.state.ownerList.length === 0 || this.state.submitting} type='primary'>Submit</Button>
          </div>
        </div>
      )
    }
  }
}



class AddNewOwner extends React.Component{
  static contextType = RequestContext;
  constructor(props){
    super();
    this.state = {
      submitting:false,
      fname:'',
      lname:'',
      email:'',
      mail_province: '',
      mail_number:0,
      mail_city: '',
      mail_country: '',
      mail_street:'',
      phone_number:'',
      mail_postal: '',
      errorMessage:"",

    }
  }

  closeError = () => this.setState({errorMessage:""})


  componentDidMount = () => {
    if(this.props.page === 'Edit'){ 
      this.setState({...this.props.editOwner, ...this.state});
    }
  }

  verify = () => {
    return new Promise((resolve,reject) =>{

    
	  if(this.state.fname === ""){
		  this.setState({errorMessage: "Please enter a first name for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.lname === ""){
		  this.setState({errorMessage: "Please enter a last name for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.email === ""){
		  this.setState({errorMessage: "Please enter an email for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.mail_province === ""){
		  this.setState({errorMessage: "Please enter a province for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.mail_number === 0){
		  this.setState({errorMessage: "Please enter a street number for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.mail_street === ""){
		  this.setState({errorMessage: "Please enter a street for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.mail_city === ""){
		  this.setState({errorMessage: "Please enter a city for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.mail_postal === ""){
		  this.setState({errorMessage: "Please enter a postal code for the Owner"});
		  return resolve(false);
	  }
	  else if(this.state.phone_number === ""){
		  this.setState({errorMessage: "Please enter a phone number for the Owner"});
		  return resolve(false);
    }
    else{
      axios.post("/user/check/existingEmail",{email:this.state.email})
      .then((response) => {
        if(response.data.exists){
          this.setState({errorMessage: "Email already exists"});
          return resolve(false);
        }
        else{
          return resolve(true);
        }
      })
    } 
  })
  }

  submitNewOwner = () => {
    this.setState({submitting:true})
    this.verify().then((verified) => {
      if(verified){ 
        const owner = {
          fname :this.state.fname, 
          lname :this.state.lname,
          email :this.state.email,
          mail_province: this.state.mail_province,
          mail_country:this.state.mail_country,
          mail_city:this.state.mail_city,
          mail_number:parseInt(this.state.mail_number), 
          mail_street:this.state.mail_street,
          phone_number:this.state.phone_number,
          mail_postal:this.state.mail_postal,
          table_key: new Date().getTime(),
          new:true
      }
      this.props.addNewOwner(owner);
    }
    else return this.setState({submitting:false});
  })
}

  render(){
    return(
      <div>
      <Form>
          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <Form.Label>Owner First Name</Form.Label>
                <Form.Control as="input" defaultValue={this.state.fname} 
                value = {this.state.fname} onChange={(e) => this.setState({fname:e.target.value})}/>
        </Form.Group>
        
        <Form.Group as={Col} sm={4}>
          <Form.Label>Owner Last Name</Form.Label>
                <Form.Control as="input" defaultValue={this.state.lname} 
                value = {this.state.lname}  onChange={(e) => this.setState({lname:e.target.value})}/>
            </Form.Group>
      </Form.Row>
      
      <Form.Row>
            <Form.Group as ={Col}>
              <Form.Label>Owner Email</Form.Label>
              <Form.Control as="input" defaultValue={this.state.email} 
              value = {this.state.email} onChange={(e) => this.setState({email:e.target.value})}/>
            </Form.Group>
        <Form.Group as={Col} sm={3} md={4}>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control as="input"  defaultValue={this.state.phone_number} 
              value = {this.state.phone_number} onChange={(e) => this.setState({phone_number:e.target.value})}/>
            </Form.Group>
        
        
      </Form.Row>
        
      <Form.Row>
            <Form.Group as={Col} sm={2} md={3}>
              <Form.Label>Street Number</Form.Label>
              <Form.Control as="input" min={0} type="number" defaultValue={this.state.mail_number} 
              value = {this.state.mail_number} onChange={(e) => this.setState({mail_number:e.target.value})}/>
        </Form.Group>
        <Form.Group as={Col}>
        <Form.Label>Street</Form.Label>
              <Form.Control as="input" defaultValue={this.state.mail_street} 
              value = {this.state.mail_street} onChange={(e) => this.setState({mail_street:e.target.value})}/>
        </Form.Group>
      </Form.Row>
      
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>City</Form.Label>
              <Form.Control as="input" defaultValue={this.state.mail_city} 
              value = {this.state.mail_city} onChange={(e) => this.setState({mail_city:e.target.value})}/>
        </Form.Group>
        
        <Form.Group as={Col}>
        <Form.Label>Postal Code</Form.Label>
              <Form.Control as="input" defaultValue={this.state.mail_postal} 
              value = {this.state.mail_postal} onChange={(e) => this.setState({mail_postal:e.target.value})}/>
        </Form.Group>
        <Form.Group as={Col}>
        <Form.Label className='form-label'>Province</Form.Label>
        <select name="province" className="form-control" defaultValue={this.state.mail_province} onChange={(e) => this.setState({ mail_province: e.target.value})}>
          <option value="" hidden disabled>Province</option>
          {Object.keys(vendorProvinces).map((province) => (
            <option value={province}>{vendorProvinces[province]}</option>
          ))}
        </select>
      </Form.Group>
        
      </Form.Row>
      
      {this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}
      
      </Form>
      <div className="d-flex">    
            <Button  className="mr-2" danger onClick={this.props.backToView}>
              Discard Changes
            </Button>
        <div className="ml-auto"> 
            <Button type="primary" onClick={this.submitNewOwner} disabled={this.state.submitting}>
              Add Owner
            </Button>
        </div>
      </div>
    </div>
    )
  }


}








  const existingOwnerStyles = createUseStyles({

    container:{
      maxHeight:400,
      overflowY:"auto"
    },
    table:{
      maxHeight:300,
      overflowY:"auto"
    },
    ownerCard:{
      padding:4,
      height:30,
    },
    selected:{
      backgroundColor:"#e6f7ff",
      opacity:"50%",
    },
    unselected:{
      cursor:"pointer",
      '&:hover':{
        color:"#4d7cfe"
      }
    },
    height500:{
      height:400
    }
  })
  


  const SelectExistingOwner = (props) => {
    const classes = existingOwnerStyles();
    const {ownerList,existingOwnerList,addExistingOwners,backToView} = props || null;
    const [searchValue,setSearch] = useState("");
    const [selectedOwners,selectOwner] = useState([]);

    
    const submitChanges = () => addExistingOwners(selectedOwners);

    const getOwnerList = () => {
      axios.post("/propertyManager/get/ownerList")
        .then((response) => {
          props.setExistingOwnerList(response.data.ownerList);
        })
    }

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
          selectOwner(selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: ownerList.findIndex( (v) => record.email === v.email) + 1,
        name: record.fname,
      }),
    };
    if(existingOwnerList === null){
      getOwnerList();
      return (
        <div className={classes.height500}>
        <LoadScreen className='m-auto' /> 
        </div>
      )
    }
    else if(existingOwnerList.length === 0){
      return(
        <div className={classes.height500}>
          <div>
            No Owners Found.
          </div>
          <Button onClick={props.backToView} type='primary'>Back</Button>
        </div>
      )
    }
    else if(existingOwnerList.length > 0){
      let renderRows = existingOwnerList.filter((o) => `${o.fname} ${o.lname} ${o.email}`.toLowerCase().includes(searchValue.toLowerCase()));
      return(
        <div className={classes.container}>
          <Searchbar className='mb-2' value={searchValue} onChange={(e)=>setSearch(e.target.value)} />
            <Table 
              className={classes.table}
              dataSource={renderRows}
              columns={columns}
              pagination={false} 
              rowKey={'email'}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
            <div className="d-flex mt-4">
						  <Button onClick ={props.backToView}>Back</Button>
              <div className="ml-auto"> 
                <Button type="primary" onClick={submitChanges}>Add Owners</Button>
              </div>
          </div>
        </div>
      )
    }
  }
  
  const columns = [
      {
          title:'Name',
          dataIndex:'fname',
          key:'title',
          width:'50%',
          render: (e,row) => row.fname+' '+(row.lname ? row.lname : "")
      },
      {
          title:'Email',
          dataIndex:'email',
          key:'email',
          width:'50%'
      },
  ]

  

export default AddOwner;
  