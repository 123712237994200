import React from 'react';
import axios from 'axios';

import { MarketListing, DirectJRListing } from './MarketListing';
import { ListingDetail } from './ListingDetail';
import { DirectJRdetail } from './directJRdetail';
import { Searchbar } from '../Components/Searchbar/Searchbar';
import { Switch, Route } from 'react-router-dom';
import './marketListing.css';

import { Button } from 'antd';
const cancelToken = axios.CancelToken.source();

function Market() {
	return (
		<Switch>
			<Route exact path='/market' component={Marketlist} />
			<Route exact path='/market/ad_:adid' component={ListingDetail} />
			<Route exact path='/market/djr_:djrid' component={DirectJRdetail} />
		</Switch>
	);
}

class Marketlist extends React.Component {
	//static contextType  = useUserState
	constructor(props) {
		super(props);
		this.state = {
			listingview: [],
			allAdListings: [],
			directJRListing: [],
			searchValue: '',
			bidinfo: [],
			showads: true,
			showdirectJR: false,
			onlyMyBids: false
		};
		this.updatesearch = this.updatesearch.bind(this);
		this.updateadsData = this.updateadsData.bind(this);
	}

	componentWillMount() {
		document.title = 'Marketplace';
		this.getData().then(data => {
			this.setState({
				listingview: data.ads,
				allAdListings: data.ads,
				bidinfo: data.bidinfo,
				directJRListing: data.directjr
			});
		});
	}

	updatesearch(e) {
		this.setState({ searchValue: e.target.value });
	}

	updateadsData() {
		let updatelist = this.state.allAdListings.filter(ad => {
			if (ad.user_id === 42 && ad.type == 'job' && ad.bidsno > 0) {
				return true;
			}
			return false;
		});
		this.setState({
			listingview: updatelist,
			showads: true,
			showdirectJR: false,
			onlyMyBids: true
		});
	}

	alldata() {
		this.setState({
			listingview: this.state.allAdListings,
			showads: true,
			showdirectJR: false,
			onlyMyBids: false
		});
	}

	getData() {
		return axios
			.get('/reactGetListings', { crossdomain: true })
			.then(function (response) {
				return response.data;
			});
	}

	handledata = () => {
		this.setState({
			showads: false,
			showdirectJR: true,
			onlyMyBids: false,
			listingview: this.state.directJRListing
		});
	};

	render() {
		return (
			<div className='pt-3' id='market-container'>
				<div id='market-left' className='ml-2'>
					<header id='market-header'>
						<Button
							onClick={this.alldata.bind(this)}
							type={
								!this.state.onlyMyBids && this.state.showads ? 'primary' : ''
							}
							className='mr-2'
						>
							All
						</Button>
						<Button
							type={this.state.onlyMyBids ? 'primary' : ''}
							className='mr-2'
							onClick={this.updateadsData}
						>
							My Bids
						</Button>

						<Button
							onClick={this.handledata}
							type={this.state.showdirectJR ? 'primary' : ''}
						>
							Direct Job Requests
						</Button>
						<div id='market-search'>
							<Searchbar
								onChange={this.updatesearch}
								value={this.state.searchValue}
								className='marketbar'
							/>
						</div>
					</header>
					<br />

					<div id='maintenanceList'>
						{this.state.showads &&
							this.state.listingview
								.filter(ad =>
									(
										ad.service_subcategories +
										ad.service_description +
										ad.location
									)
										.toLowerCase()
										.includes(this.state.searchValue.toLowerCase())
								)
								.map(ad => (
									<MarketListing
										icon='propertyExample1.jpg'
										Category={ad.service_subcategories}
										Description={ad.service_description}
										City={ad.location}
										link={ad.idmarket_ads}
										bidsno={ad.bidsno}
									/>
								))}

						{this.state.showdirectJR &&
							this.state.listingview
								.filter(jr =>
									jr.subcategory
										.toLowerCase()
										.includes(this.state.searchValue.toLowerCase())
								)
								.map(jr => <DirectJRListing request={jr} />)}
					</div>
				</div>
			</div>
		);
	}
}

export default Market;
