import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import InvoiceFormat from './InvoiceFormat';
import InvoiceFile from './InvoiceFile';

const InvoicePDFViewer = props => {
	return props.invoiceData[0].file === null ? (
		<PDFViewer style={{ width: '100%', height: '60vh' }}>
			<InvoiceFormat invoiceData={props.invoiceData} />
		</PDFViewer>
	) : (
		<InvoiceFile invoiceData={props.invoiceData} />
	);
};

export default InvoicePDFViewer;
