import React,{useState} from 'react';
import {Card,Table,Divider,Select,Tooltip,Carousel} from 'antd';
import useWindowDimensions from '../../../../../../Context/WindowsDimensions';
import moment from 'moment';

const { Option } = Select;
const columns = [
  {
    title:"Description",
    dataIndex:'item'

  },
  {
    title:"Quantity",
    dataIndex:'quantity'
  },
  {
    title:"Vendor",
    dataIndex:'vendor'
  },
  {
    title:"Price",
    dataIndex:'est_price'
  }
];
class SessionDisplay extends React.Component {
    render() {
      let tr,action,materials;
      if(this.props.session.status !== ""){
        return(
          
        <TroubleshootDisplay troubleshoot={this.props.session}/>
        )
      }
      if(this.props.session.session.action_desc !== ""){
        action = <ActionDisplay action={[this.props.session.session.action_desc].map((desc,actKey)=>{
          return {
            actionTaken: desc, 
            date: this.props.session.session.time_in ? moment(this.props.session.session.time_in).format('ddd MMM D, [at] h:mma') : "-",
            key:actKey}})} />;
      }
      if(this.props.session.materials.length > 0){
        materials = <MaterialDisplay materials={this.props.session.materials} m_cost={this.props.session.session.total_material_cost} />;
      }
      else materials = <><Divider orientation="left">Materials</Divider><h6>No Materials Needed.</h6></>
      let time = this.props.session.session.time_in ? moment(this.props.session.session.time_out || this.props.session.session.time_in).format('ddd MMM D, [at] h:mma') : "-";
      return (
        <div id='container'>
          <h3>{this.props.backButton}{this.props.session.session.time_out ? `Session completed on ${time}` : `Session started on ${time}`}</h3>
          <Divider dashed/>
          <div>
            <EntryDisplay vendor={this.props.session.vendor_name} time_in={this.props.session.session.time_in} auth={this.props.session.session.auth_type} tenant={this.props.session.session.tenant_name} time_out={this.props.session.session.time_out} total_km={this.props.session.session.total_km  } />
            {tr}
            {action}
            <CostDisplay workOrder={this.props.session.workOrder} labour={this.props.session.labour} />
            {materials}
          </div>
        </div>
      )
    }
  
  }
  
  class CostDisplay extends React.Component {
    render(){
      let rows = [];
      for(let i of this.props.labour){
        rows.push(<LabelCost label={i.label} value={i.startUnit+i.price}/>)
      }
      return (
        <div>
          <Divider orientation="left" >Estimated Cost</Divider>
          <div className='txtInput labelHead'>${this.props.workOrder.total_est_cost}</div>
          {rows}
        </div>
      )
    }
  }
  
  function LabelCost(props){
    return(
      <div className='fixedLine '>
          <div className='txtLabel smallLabel '>{props.label}</div>
          <div className='txtInput smallLabel'>{props.value}</div>                
      </div>
    )
  }
  class ActionDisplay extends React.Component {
    render() {
  
      return (
        <div >
          <Divider orientation="left" >Actions Taken</Divider>
          <Table 
          columns={[
            {
              title:'Action',
              dataIndex:'actionTaken',
              width:'50%',
              key:'action'
            },
            {
              title:'Time',
              dataIndex:'date',
              width:'50%',
              key:'title'
            }
          ]}
          dataSource={this.props.action}
          size='small'
          pagination={false}
          />
        </div>
      );
    }
  }
  
const ImageDisplay = ({ images }) => {
  const { width } = useWindowDimensions();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const  download = ( url) => {
    //axios.get(url).then()
  }
  if (!Array.isArray(images)) return <></>;
  var slidesPer;
  if (width > 1300) slidesPer = 4;
  else if (width < 1300 && width > 1130) slidesPer = 3;
  else if (width < 1130 && width > 760) slidesPer = 2;
  else slidesPer = 1;
  const imgdownload = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };

  return (
    images.length > 0 ?
    (<Carousel
      arrows
      slidesPerRow={slidesPer}
      className="maintenanceCarousel"
      prevArrow={
        <img src={require("../../../../../../icons/chevronLeft.png")} alt="left" />
      }
      nextArrow={
        <img src={require("../../../../../../icons/chevronRight.png")} alt="right" />
      }
    >
      {images.map((i, index) => (
        <div className="imageTab" key={i.id}>
          <a href = {i.url} target='_blank' type='application/pdf' download>
            <Tooltip title={i.event_id}>
              {i.type == 'image' && <img 
                  className="d-block image carouselImages"
                  src={require('../../../../../../images/pdfIcon.PNG')}//i.url
                  alt="First slide" style={{width: 50, height: 50}}
                />}
              {i.type == 'pdf' && <img 
                  className="d-block image carouselImages"
                  src={require('../../../../../../images/pdfIcon.PNG')}
                  alt="First slide" style={{width: 50, height: 50}}
                />} 
            </Tooltip>
          </a>
        </div>
      ))}
    </Carousel>) : 
    ""
  );
};
  class EntryDisplay extends React.Component {
    render() {
      return (
        <div id='EntryDiv' className='d-flex flex-row justify-content-between pl-2'>
          <StandardText label="Vendor" text={this.props.vendor || '-'} />  
          <StandardText label="Time In" text={this.props.time_in ? moment(this.props.time_in).format('ddd MMM D, [at] h:mma') : "-"} />
          <StandardText label="Authority" text={this.props.auth || '-'} />
          <StandardText label="Tenant" text={this.props.tenant || '-'} />
          <StandardText label='Total KM' text={this.props.total_km || 0} />
          <StandardText label='Time Out' text={this.props.time_out ? moment(this.props.time_out).format('ddd MMM D, [at] h:mma') : "-"}/>
        </div>
      )
    }
  }
  
  class TroubleshootDisplay extends React.Component {
    render(){
      return (
        <div >
          <Divider orientation="left" >Troubleshooting</Divider>
          <div>
            <div className='JRSubtitle'>
             Description <br></br>

              
            </div>
            <div className=''>
                {this.props.troubleshoot.causes}
              </div>
            <div className='JRSubtitle'>
              Documents <br></br>
              
            </div>
            <div id='requestImages' className='mt-2'>
            <ImageDisplay images={this.props.troubleshoot.uploads}/>
            </div>
            <div className='JRSubtitle'>
              Estimate Expenses <br></br>
              
            </div>
            <div>


            </div>


          </div>
          {
            <Table 
              columns={columns}
              dataSource={this.props.troubleshoot.estimations}
            >

            </Table>
          }
        </div>
      )
    }
  }
  
  class MaterialDisplay extends React.Component {
  
    render() {
      let rows = [];
      for(let i of this.props.materials){
        rows.push(<Material material={i} />)
      }
      return (
        <div id='materialsDiv'>
          <Divider orientation="left" >Materials</Divider>      
          <table>
            <MaterialHeader/>
            <tbody>
            {rows}
            </tbody>
          </table>
        </div>
        )
    }
  }
  
  class Material extends React.Component {
  
    render() {
      return (
        <tr>
          <td>{this.props.material.material_name}</td>
          <td>{this.props.material.quantity}</td>
          <td>{this.props.material.unit}</td>
          <td>${this.props.material.cost}</td>
          <td>{this.props.material.store}</td>
        </tr>
      )
    }
  
  }
  
  function MaterialHeader(){
    return (
  
        <thead>
          <tr>
            <th style={{width:'30%'}}>Material</th>
            <th style={{width:'20%'}}>Quantity</th>
            <th style={{width:'15%'}}>Unit</th>
            <th style={{width:'15%'}}>Cost</th>
            <th style={{width:'20%'}}>Store</th>
          </tr>
        </thead>
  
    ) 
  } 
  
  function StandardText(props){
    
    return(
    <div className='fixed mr-4 mb-2' >
      <div className='smallLabel'>{props.label}</div>
      <div className='dataFont'>{props.text}</div>
    </div> 
    )
  }
  function StandardTextArray(props){
    return(
    <div className='fixed mr-4 mb-2' >
      <div className='smallLabel'>{props.label}</div>
      {props.text.map((text, textIndex)=>{
        return <div key={textIndex} className='dataFont'>{text}</div>;
      })}
    </div> 
    )
  }


export default SessionDisplay;