import React, { useState } from 'react';

import { Modal } from 'antd';
import { UserStateContext } from '../../../Context/UserContext';
import './company.css';
import {
	PlusCircleOutlined,
	EditOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import {
	Card,
	Avatar,
	Button,
	message,
	Dropdown,
	Menu,
	Select,
	Table,
	Skeleton,
	DatePicker,
	TimePicker
} from 'antd';
import EmployeeIntakeForm from './EmployeeIntake';

class AddEmployee extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addEmployeeModal: false
		};
	}

	openAddEmployee = () => {
		this.setState({ addEmployeeModal: !this.state.addEmployeeModal });
	};
	onSuccess = employees => {
		message.success('Employee successfully added');
		this.props.addEmployees(employees);
		this.openAddEmployee();
	};
	render() {
		return (
			<>
				<PlusCircleOutlined
					className='ml-2'
					style={{ cursor: 'pointer' }}
					onClick={this.openAddEmployee}
				/>

				<Modal
					destroyOnClose={true}
					title='Employee Form'
					visible={this.state.addEmployeeModal}
					onCancel={this.openAddEmployee}
					width='800px'
					footer={false}
				>
					{this.state.addEmployeeModal ? (
						<EmployeeIntakeForm
							onSuccess={this.onSuccess}
							//  onCancel={!this.openAddEmployee}
						/>
					) : (
						''
					)}
				</Modal>
			</>
		);
	}
}

export default AddEmployee;
