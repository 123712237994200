import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { RequestContext } from '../../Context/requestContext';
import LoadScreen from '../../Components/LoadScreen';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import './maintenance.css';
import {
	MaintenanceRequestListing,
	MaintenanceStatus
} from './SelectRequestListing';
import requestStatus from '../../StringData/RequestStatus';
import requestRoutes, { requestLinks } from './requestRoutes';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import NoRequestSVG from '../../SVG/NoRequests';
import axios from 'axios';
const cancelToken = axios.CancelToken.source();

//Default router for request type.
class SelectJobRequests extends React.Component {
	static contextType = RequestContext;
	constructor(props) {
		super();
		this.state = {
			selectjr: [],
			requests: null,
			requestType: 'maintenance',
			mobile: false
		};
	}
	componentDidUpdate(pp, ps) {
		if (
			(!pp.open && this.props.open) ||
			ps.requestType !== this.state.requestType ||
			pp.thread_id !== this.props.thread_id
		)
			this.fetchRequests();
	}
	componentDidMount = () => {
		window.addEventListener('resize', this.debounce(this.resize, 250));
		this.fetchRequests();
	};

	fetchRequests = (refresh = false) => {
		this.setState({ requests: null });
		this.context
			.getRequests(refresh, this.state.requestType, this.props.thread_id)
			.then(requests => {
				this.setState({ requests: requests });
			});
	};

	resize = () => {
		//https://stackoverflow.com/questions/44480053/how-to-detect-if-screen-size-has-changed-to-mobile-in-react
		this.setState({ mobile: window.innerWidth <= 1150 });
	};

	debounce = (fn, delay) => {
		//Throttles the function call so resize is called only once ever 250ms
		var timer = null; //https://remysharp.com/2010/07/21/throttling-function-calls
		return function () {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay);
		};
	};
	setType = type => {
		this.setState({ requestType: type });
	};

	//function used for save jr in to this.state.selectjr
	selectJobRequest = job_id => {
		let newSelect = [...this.state.selectjr];
		let select_index = newSelect.indexOf(job_id);
		if (select_index >= 0) {
			newSelect.splice(select_index, 1);
		} else {
			newSelect.push(job_id);
		}

		this.setState({ selectjr: newSelect });
	};

	//function used for save jr and thread into database
	newConnection = () => {
		let selectedthread = this.props.thread_id;
		let newReq = [...this.state.requests];
		if (this.state.selectjr.length > 0)
			for (let i = 0; i < this.state.selectjr.length; i++) {
				let selectId = this.state.selectjr[i];
				axios
					.post('/jobRequest/post/jrthread', {
						jr_id: this.state.selectjr[i],
						thread_id: selectedthread
					})
					.then(response => {
						let foundReq = newReq.findIndex(currentReq => {
							return currentReq.job_id === selectId;
						});
						newReq.splice(foundReq, 1);
						this.props.reloadJRDisplay();
					});
			}
		this.setState({ selectjr: [], requests: newReq });
	};

	render() {
		return (
			<div className='h100'>
				<Button
					style={{ marginLeft: '25%' }}
					type='primary'
					onClick={() => {
						this.setType('maintenance');
					}}
				>
					Maintenance
				</Button>
				<Button
					style={{ marginRight: '25%', float: 'right' }}
					type='primary'
					onClick={() => {
						this.setType('administrative');
					}}
				>
					Administrative{' '}
				</Button>
				{this.state.mobile ? (
					<RequestMobileLayout
						requestList={this.state.requests}
						selectJobRequest={this.selectJobRequest}
					/>
				) : (
					<RequestDesktopLayout
						requestList={this.state.requests}
						selectJobRequest={this.selectJobRequest}
						selectjr={this.state.selectjr}
						newConnection={this.newConnection}
					/>
				)}
			</div>
		);
	}
}

const RequestMobileLayout = props => {
	const { requestList } = props;

	const request_id = window.location.href.split('/')[5];
	let routeKeys = 0;

	let noLink = isNaN(request_id);

	//Not at the request url
	if (noLink) {
		return <RequestList requestList={requestList} />;
	} else if (noLink === false) {
		return (
			<>
				{requestRoutes.map(({ path, component, key }) => (
					<Route exact key={routeKeys++} path={path} component={component} />
				))}
			</>
		);
	}
};
const RequestDesktopLayout = props => {
	const {
		selectJobRequest,
		requestList,
		request_id,
		selectjr,
		request_type,
		updateMaintenanceStatus,
		newConnection
	} = props;
	if (requestList === null) return <LoadScreen className='m-auto' />;
	return (
		<RequestList
			selectJobRequest={selectJobRequest}
			request_id={request_id}
			requestList={requestList}
			selectjr={selectjr}
			newConnection={newConnection}
		/>
	);
};

//Responsible for rendering the maintenance requests and filtering them.
function RequestList(props) {
	const {
		selectJobRequest,
		requestList,
		request_type,
		selectjr,
		newConnection
	} = props;
	// console.log('============',selectjr)
	const [searchValue, search] = useState('');
	const [statusFilter, setStatusFilter] = useState('');
	const [dropDownVisible, setVisible] = useState(false);

	const changeStatusFilter = status => {
		setStatusFilter(status);
		setVisible(false);
	};

	//request list not loaded yet
	if (requestList == null) {
		return <LoadScreen />;
	}

	//No requests found
	if (requestList.length === 0) return <NoRequests />;
	// oh no, something's wrong. TODO: error handling
	else if (requestList === undefined) {
		return <div>Something went wrong. Please try signing in again.</div>;
	}
	return (
		<div className='h100'>
			<div className='pt-4' id='listHeader'>
				<div>
					<Dropdown
						overlay={<RequestMenu selectStatus={changeStatusFilter} />}
						placement='bottomRight'
						visible={dropDownVisible}
						onClick={() => setVisible(!dropDownVisible)}
					>
						<div>
							{/* <h1>hahah {selectjr}</h1> */}
							<Button onClick={() => setVisible(true)}>
								Status <DownOutlined />
							</Button>
						</div>
					</Dropdown>
				</div>
				<Searchbar
					onChange={e => search(e.target.value)}
					value={searchValue}
					className='maintenancebar'
				/>
			</div>
			<div id='maintenanceListContainer'>
				{requestList
					.filter(request =>
						(
							'JR' +
							request.job_id +
							request.job_category +
							request.job_subcategory +
							request.job_status +
							request.job_title +
							request.propertynumber +
							' ' +
							request.propertyStreet +
							(request.vendor
								? request.vendor.fname + ' ' + request.vendor.lname
								: '')
						)
							.toLowerCase()
							.includes(searchValue.toLowerCase() + statusFilter.toLowerCase())
					)
					.map(request => (
						<MaintenanceRequestListing
							selectJobRequest={selectJobRequest}
							selectjr={selectjr}
							mobileView
							key={request.job_id}
							selected={props.request_id === request.job_id}
							request={request}
							customLink={
								requestLinks[request.job_category.toLowerCase()] +
								String(request.job_id)
							}
							clickable={true}
						/>
					))}
			</div>
			<Button
				onClick={() => {
					newConnection();
				}}
			>
				Connect
			</Button>
		</div>
	);
}

const SelectRequest = () => {
	return (
		<div className='text-center pt-4'>
			<NoRequestSVG />
			<br />
			<div className='g-text'>Select a request to open it here.</div>
		</div>
	);
};

const NoRequests = () => {
	return (
		<div className='text-center mt-4'>
			<br />
			<h6>No Requests found.</h6>
		</div>
	);
};
const RequestMenu = ({ selectStatus }) => {
	return (
		<Menu>
			<Menu.Item onClick={() => selectStatus('')}>All</Menu.Item>
			{requestStatus.map((status, index) => (
				<Menu.Item onClick={() => selectStatus(status)} key={index}>
					<MaintenanceStatus value={status} status={status} />
				</Menu.Item>
			))}
		</Menu>
	);
};

export default SelectJobRequests;

/* old list header
      <div className='mt-4' id='listHeader'>
        <div id='maintenance-left' className='ml-2'>
          <div className='d-inline mr-4'>
            Sort: <b>A-Z</b>
          </div>
          <div className='d-inline'>
            Type: <b>All</b>
          </div>
        </div>
        <div className='flex-70 m-auto'>
          <Searchbar
            onChange={(e) => search(e.target.value)}
            value={searchValue}
          />
        </div>
        <div id='maintenance-right' className=' mr-4'>
          <a className='blueLink fs-14'>Export Spreadsheet</a>
        </div>
      </div>
*/
