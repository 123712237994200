import React from 'react';
import './errorMessage.css'


const ErrorMessage = ({children,otherClass}) => {
  return(
    <div className={otherClass === undefined ? 'errorMessage': 'errorMessage '+String(otherClass) } >
      {children}
    </div>
  )
}

export default ErrorMessage;