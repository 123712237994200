import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../../Context/UserContext';
import { ProfileAvatar } from '../../Components/Avatar';
import { Dropdown, Menu, Button } from 'antd';
import { Modal } from 'react-bootstrap';
import { CaretRightOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

function HeaderProfile({ mobile = false, mobileClose }) {
	const [page, setPage] = useState('profile');
	const [menuModal, openMenu] = useState(false);
	const { user } = useUserState();
	let dispatch = useUserDispatch();
	const signOut = () => {
		setPage('signout');
	};
	const closeBoth = () => {
		openMenu(false);
		mobileClose();
	};
	const changeAccountType = type => {
		let sess = JSON.parse(Cookies.get('session'));
		Cookies.set('session', { session_id: sess.session_id, type: type });
		let newUser = { ...user, type: type };
		dispatch({ state: { user: newUser }, type: 'update' });
		window.location.reload();
	};
	if (page === 'profile') {
		if (mobile) {
			return (
				<>
					<div className='m-auto' onClick={() => openMenu(true)}>
						<ProfileAvatar
							name={user.fname + ' ' + user.lname}
							image={user.imageurl}
						/>
						<CaretRightOutlined width={'19px'} height={'19px'} />
					</div>

					<Modal
						show={menuModal}
						onHide={() => openMenu(!menuModal)}
						size='lg'
						aria-labelledby='assign-vendor-modal'
						centered
					>
						<Modal.Body>
							<ProfileDropDown
								signOut={signOut}
								close={closeBoth}
								user={user}
								changeAccountType={changeAccountType}
								closeMenu={() => openMenu(!menuModal)}
							/>
						</Modal.Body>
					</Modal>
				</>
			);
		} else {
			return (
				<Dropdown
					overlay={
						<ProfileDropDown
							user={user}
							signOut={signOut}
							closeMenu={() => openMenu(false)}
							changeAccountType={changeAccountType}
						/>
					}
					trigger={['click']}
					open={menuModal}
					onOpenChange={() => openMenu(!menuModal)}
					className='flex-15 text-center pr-4'
				>
					<div
						style={{ minWidth: 'fit-content', cursor: 'pointer' }}
						onClick={() => openMenu(true)}
					>
						<ProfileAvatar
							name={user.fname + ' ' + user.lname}
							image={user.imageurl}
						/>
						&#9660;
					</div>
				</Dropdown>
			);
		}
	} else if (page === 'signout') {
		return <Redirect to='/logout' />;
	} else {
		return <div>Error loading profile</div>;
	}
}

function ProfileDropDown({
	signOut,
	close,
	user,
	changeAccountType,
	closeMenu
}) {
	const [accountModal, openAccountModal] = useState(false);

	return (
		<>
			<Menu className='profileHover'>
				<Menu.Item key='0'>
					<Link
						to='/myprofile'
						onClick={
							close !== undefined
								? close
								: () => {
										return;
								  }
						}
					>
						My Profile
					</Link>
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item key='3'>
					<Link to='/settings'>Settings</Link>
				</Menu.Item>
				<Menu.Item key='4'>
					<div
						onClick={() => {
							openAccountModal(true);
						}}
					>
						Change Account
					</div>
				</Menu.Item>
				<Menu.Item key='5'>
					<div onClick={signOut}>Sign Out</div>
				</Menu.Item>
			</Menu>
			<Modal
				show={accountModal}
				onHide={() => openAccountModal(!accountModal)}
				size='lg'
				aria-labelledby='workorder-modal'
				centered
			>
				{accountModal ? (
					<ChangeTypeModal
						onHide={() => openAccountModal(!accountModal)}
						user={user}
						changeAccountType={type => {
							changeAccountType(type);
							closeMenu();
							openAccountModal(false);
						}}
					/>
				) : (
					''
				)}
			</Modal>
		</>
	);
}
const ChangeTypeModal = ({ user, changeAccountType }) => {
	return (
		<>
			<Modal.Header>Change Account Type</Modal.Header>
			<Modal.Body style={{ height: '300px' }}>
				{/* {user.Admin_account === 1 ? (
					<Button
						className='changeAccountBtn'
						onClick={() => changeAccountType('Admin')}
						disabled={user.type === 'Admin'}
					>
						Admin
					</Button>
				) : (
					''
				)} */}
				{user.PM_account === 1 ? (
					<Button
						className='changeAccountBtn'
						onClick={() => changeAccountType('Manager')}
						disabled={user.type === 'Manager'}
					>
						Property Manager
					</Button>
				) : (
					''
				)}
				{user.PO_account === 1 ? (
					<Button
						className='changeAccountBtn'
						onClick={() => changeAccountType('Owner')}
						disabled={user.type === 'Owner'}
					>
						Property Owner
					</Button>
				) : (
					''
				)}
				{user.Vendor_account === 1 ? (
					<Button
						className='changeAccountBtn'
						onClick={() => changeAccountType('Vendor')}
						disabled={user.type === 'Vendor'}
					>
						Vendor
					</Button>
				) : (
					''
				)}
				{user.Tenant_account === 1 ? (
					<Button
						className='changeAccountBtn'
						onClick={() => changeAccountType('Tenant')}
						disabled={user.type === 'Tenant'}
					>
						Tenant
					</Button>
				) : (
					''
				)}
			</Modal.Body>
		</>
	);
};
export default HeaderProfile;
