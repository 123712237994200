import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import moment from 'moment';
class NewMessageList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
      {this.props.threads
        ?
        <div>
        <div id="listingHeader">New Messages</div>
        <ul>
          {(this.props.threads.length > 0 ? 
            (this.props.threads.map((thread) => (
              <ThreadListing
                key={thread.thread_id}
                thread={thread}
                active = {false}
              />)))
              :
            (<div>You have no new messages.</div>))}
        </ul>
      </div>: null}
      </>
    );
  }
}

const ThreadListing = ({ thread,active }) => {
  const getTime = (time) => {
    time = moment(time);
    let days = moment().diff(time, "days");
    if(days > 0) return days+"d"

    let hours = moment().diff(time, "hours");
    if(hours > 0) return hours+'h';

    let minutes = moment().diff(time,"minutes");
    return minutes + 'm'
  }



  const captionThread = (thread) =>{
    if(thread.thread_name == null ){ return "Thread" }
    else{ return thread.thread_name.substr(thread.thread_name.indexOf(",") + 1) }
  }
    
  return (
    <li className='m-auto'>
      <Link
        className={`threadListing ${active ? 'activeThread' : ''}`}
        to={"/communication/" + thread.thread_id}
      >
        <div className="threadLeft">
          <Avatar src={thread.recent_msg_img} />
        </div>
        <div className="threadMain">
          <div className="caption threadName"> {captionThread(thread) } </div>
          <div
            className="unreadTxt txtMsg b-text-sm"
          >
            {thread.message}
          </div>
        </div>
        <div className="threadRight g-text-sm">{getTime(thread.time ? thread.time : thread.create_date)}</div>
      </Link>
    </li>
  );
};

export default NewMessageList;
