import React, { useState } from "react";
import { Card, Avatar, Button ,message,Form,Input,Checkbox} from "antd";
import axios from "axios";

import {createUseStyles} from 'react-jss'
const cancelToken = axios.CancelToken.source();


const NewUserStyles = createUseStyles({

    container:{
      padding:8,
      maxWidth:500
    },
    formLayout:{
        margin:'auto'
    },
    submitBtn:{
        float:'right'
    }
  })
  
const AddNewUser = () => {
    const classes = NewUserStyles();
    
    const onFinish = (user) => {

        axios.post('user/add/newUser',user).then( () => {
            message.success('Account has been created');
        })
        .catch( (err) => {
            message.error(err)
        })

    }

    const onFinishFailed = () => {
        message.error("Form was not submitted successfully")
    }




    return(
        <Card title='Add New User' className={classes.container}>
            <Form
                {...layout}
                className={classes.formLayout}
                name="basic"
                validateMessages={validateMessages}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >


                <Form.Item
                    label="First Name"
                    name={['user', 'fname']}
                    rules={[{ required: true}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name={['user', 'lname']}
                    rules={[{ required: true}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email',required:true }]}>
                    <Input />
                </Form.Item>
                <Form.Item {...tailLayout} name={['user', 'tenant']} valuePropName="checked">
                    <Checkbox>Tenant</Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout} name={['user', 'owner']} valuePropName="checked">
                    <Checkbox>Owner</Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout} name={['user', 'vendor']} valuePropName="checked">
                    <Checkbox>Vendor</Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout} name={['user', 'manager']} valuePropName="checked">
                    <Checkbox>Property Manager</Checkbox>
                </Form.Item>
                <Form.Item className={classes.submitBtn}>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

            </Form>
        </Card>
    )
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
export default AddNewUser