import React, { useState } from "react";
import { Table, Space, DatePicker, Button, Upload, message, Row, Col, Modal } from "antd";
import LoadScreen from "./LoadScreen";
import { ConsoleSqlOutlined, ReloadOutlined } from '@ant-design/icons';
import { Resizable } from "react-resizable";
import moment from "moment";
import Moment from "react-moment";
import axios from "axios";
const cancelToken = axios.CancelToken.source();


const DAYS_PER_MONTH = [
  {name: "January", days: function(year) {return 31}},
  {name: "February", days: function(year) {return year % 4 === 0 ? (year % 100 === 0 ? (year % 400 === 0 ? 29 : 28) : 29) : 28}},
  {name: "March", days: function(year) {return 31}},
  {name: "April", days: function(year) {return 30}},
  {name: "May", days: function(year) {return 31}},
  {name: "June", days: function(year) {return 30}},
  {name: "July", days: function(year) {return 31}},
  {name: "August", days: function(year) {return 31}},
  {name: "September", days: function(year) {return 30}},
  {name: "October", days: function(year) {return 31}},
  {name: "November", days: function(year) {return 30}},
  {name: "December", days: function(year) {return 31}},
]

const DAYS_OF_WEEK = [
  "Sun",
  "Mon",
  "Tues",
  "Weds",
  "Thurs",
  "Fri",
  "Sat"
]

const HEADER_FONT = `normal normal normal 15px "Rubik", sans-serif`;

function getDayHour(hourNum) {
  if (hourNum === 0 || hourNum === 24) return "12 am"
  else if (hourNum < 12) return `${hourNum} am`
  else if (hourNum === 12) return "12 pm"
  else return `${hourNum-12} pm`
}

function getDaysPerYear(yearNum) {
  return yearNum % 4 === 0 ? (yearNum % 100 === 0 ? (yearNum % 400 === 0 ? 366 : 365) : 366) : 365;
}

function getDateDiff(endDate, startDate, blockSize, withTimeOfDay = false) {
  let utcDiff = withTimeOfDay ? Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds())
  - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds(), startDate.getMilliseconds()) : Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  return (utcDiff/86400000) * blockSize;
}

function getDateInt(currentDate, startingYear, blockSize, calendarView, withTimeOfDay = false) {
  let startingDate = new Date(`January 1, ${startingYear} 00:00:00`);
  return getDateDiff(currentDate, startingDate, blockSize, withTimeOfDay);
}

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return Math.round(context.measureText(text).width);
}

class MonthBlock extends React.Component {
  constructor(props) {
    super(props);
    this.monthDiv = React.createRef();
  }
  shouldComponentUpdate(nextProps) {
    let calProps = nextProps.calProps;
    let monthName = DAYS_PER_MONTH[this.props.currentMonth].name;
    let monthPxStart = getDateInt(new Date(nextProps.currentYear, nextProps.currentMonth, 1), calProps.earliestDate.getFullYear(), calProps.blockWidth, nextProps.calendarView);
    let monthPxEnd = monthPxStart + (calProps.blockWidth * nextProps.daysOfMonth) - 1;
    return nextProps.xScroll !== this.props.xScroll && ((calProps.xScroll >= monthPxStart && nextProps.xScroll <= monthPxEnd) || this.monthDiv.current.style.paddingLeft !== "5px");
  }
  render() {
    let calProps = this.props.calProps;
    let monthName = DAYS_PER_MONTH[this.props.currentMonth].name;
    let monthPxStart = getDateInt(new Date(this.props.currentYear, this.props.currentMonth, 1), calProps.earliestDate.getFullYear(), calProps.blockWidth, this.props.calendarView);
    let monthPxEnd = monthPxStart + (calProps.blockWidth * this.props.daysOfMonth) - 1;
    let leftMonthBuffer = 5;
    let monthWidth = getTextWidth(monthName, HEADER_FONT);
    if (calProps.xScroll >= monthPxStart && this.props.xScroll <= monthPxEnd - (monthWidth+12)) {
      leftMonthBuffer += calProps.xScroll - monthPxStart;
    } else if (calProps.xScroll >= monthPxStart && this.props.xScroll <= monthPxEnd) {
      leftMonthBuffer = (calProps.blockWidth * this.props.daysOfMonth) - (monthWidth+8);
    }
    return <div ref={this.monthDiv} draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", paddingLeft: leftMonthBuffer, paddingTop: 10, fontSize: 15, width: calProps.blockWidth * this.props.daysOfMonth, height: 50, color: "#252631", border: "1px solid #e8ecef", display: "inline", float: "left"}}>{monthName}</div>;
  }
}

class YearBlock extends React.Component {
  constructor(props) {
    super(props);
    this.yearDiv = React.createRef();
  }
  shouldComponentUpdate(nextProps) {
    let calProps = nextProps.calProps;
    let yearPxStart = getDateInt(new Date(nextProps.currentYear, 0, 1), calProps.earliestDate.getFullYear(), calProps.blockWidth, nextProps.calendarView);
    let yearPxEnd = yearPxStart + (calProps.blockWidth * nextProps.daysOfYear) - 1;
    return nextProps.xScroll !== this.props.xScroll && ((calProps.xScroll >= yearPxStart && calProps.xScroll <= yearPxEnd - calProps.blockWidth) || this.yearDiv.current.style.paddingLeft !== "5px");
  }
  render() {
    let calProps = this.props.calProps;
    let yearPxStart = getDateInt(new Date(this.props.currentYear, 0, 1), calProps.earliestDate.getFullYear(), calProps.blockWidth, this.props.calendarView);
    let yearPxEnd = yearPxStart + (calProps.blockWidth * this.props.daysOfYear) - 1;
    let leftYearBuffer = 5;
    if (calProps.xScroll >= yearPxStart && calProps.xScroll <= yearPxEnd - calProps.blockWidth) {
      leftYearBuffer += calProps.xScroll - yearPxStart;
    } else if (calProps.xScroll >= yearPxStart && calProps.xScroll <= yearPxEnd) {
      leftYearBuffer = (calProps.blockWidth * this.props.daysOfYear) - (calProps.blockWidth+1);
    }
    return <div ref={this.yearDiv} draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", paddingLeft: leftYearBuffer, paddingTop: 10, fontSize: 15, width: calProps.blockWidth * this.props.daysOfYear, height: 50, color: "#252631", borderLeft: "1px solid #e8ecef", borderRight: "1px solid #e8ecef", borderBottom: "1px solid #e8ecef", display: "inline", float: "left"}}>{this.props.currentYear}</div>;
  }
}

class EventBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickable: this.props.interactMode === "clickable" || this.props.interactMode === "editable"
    };
  }
  shouldComponentUpdate(nextProps) {
    return (this.props.earliestDate !== nextProps.earliestDate) || (this.props.startTime !== nextProps.startTime) || (this.props.endTime !== nextProps.endTime) || (this.props.startDate !== nextProps.startDate) || (this.props.endDate !== nextProps.endDate) || (this.props.blockWidth !== nextProps.blockWidth) || (this.props.blockHeight !== nextProps.blockHeight) || (this.props.eventStyle !== nextProps.eventStyle);
  }
  componentDidUpdate(prevProps) {
    if (this.props.dragData !== null && this.props.dragData.selectedBar.event_id === this.props.event_id && (this.props.startTime !== this.props.dragData.formerData.startTime || this.props.endTime !== this.props.dragData.formerData.endTime || this.props.startDate !== this.props.dragData.formerData.startDate) && this.state.clickable) {
      this.setState({clickable: false});
    } else if (this.props.dragData !== null && this.props.dragData.selectedBar.event_id === this.props.event_id && this.props.startTime === this.props.dragData.formerData.startTime && this.props.endTime === this.props.dragData.formerData.endTime && this.props.startDate === this.props.dragData.formerData.startDate && !this.state.clickable)
      this.setState({clickable: true});
  }
  render() {
    if ((this.props.calendarView === "month" && (this.props.startDate.getFullYear() >= this.props.earliestDate.getFullYear() && this.props.startDate.getFullYear() <= this.props.earliestDate.getFullYear()+3)) || this.props.calendarView !== "month") {
      let barStyles = this.props.eventStyle in this.props.eventStyles ? this.props.eventStyles[this.props.eventStyle] : this.props.eventStyles.default;
      let blockFullLength = this.props.orientation === "horizontal" ? getDateDiff(this.props.endDate, this.props.startDate, this.props.blockWidth, this.props.withTimeOfDay) + 1 : (this.props.endTime-this.props.startTime)*this.props.blockHeight;
      let innerBlockFullLength = this.props.orientation === "vertical" && this.props.interactMode === "editable" && blockFullLength > this.props.blockHeight ? blockFullLength - (this.props.blockHeight * 2) : "100%";
      let currentDatePix = 0;
      if (this.props.calendarView === "month") {
        currentDatePix = getDateInt(this.props.startDate, this.props.earliestDate.getFullYear(), this.props.blockWidth, this.props.calendarView, this.props.withTimeOfDay);
      } else if (this.props.calendarView === "week") {
        currentDatePix = ["Mon","Tues","Weds","Thurs","Fri","Sat","Sun"].indexOf(this.props.startDate) * this.props.blockWidth;
      } else if (this.props.calendarView === "day") {
        currentDatePix = 0;
      }
      return (
        <div onMouseDown={(event) => {
          if (event.button === 2 && this.props.interactMode === "editable")
            this.props.deleteEvent(this.props.sourceIndex, this.props.groupIndex, this.props.blockIndex, this.props.event_id);
          return false;
        }} onMouseUp={(e)=>{
          if (this.state.clickable && e.button === 0 && (this.props.dragData === null || (this.props.dragData !== null && !this.props.dragData.isNew))) {
            this.props.clickEvent(this.props);
          } else if (e.button === 0) {
            this.setState({clickable: true});
          }
        }} draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", pointerEvents: "initial", position: 'absolute', width: this.props.orientation === "vertical" ? this.props.blockWidth : blockFullLength, height: this.props.orientation === "vertical" ? blockFullLength : this.props.blockHeight, left: currentDatePix, top:this.props.orientation === "vertical" ? this.props.startTime * this.props.blockHeight : 0}}>
          {innerBlockFullLength !== "100%" ? <div
            draggable={false}
            onMouseDown={(e)=>{
              if (e.button === 0 && this.props.interactMode === "editable") {
                this.props.startEdit(this.props.sourceIndex, this.props.groupIndex, this.props.blockIndex, this.props.event_id, "A");
              }
            }}
            style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", cursor: "ns-resize", width: "100%", height: this.props.blockHeight, ...barStyles.start}}/> : null}
          <div
            draggable={false}
            onMouseDown={(e)=>{
              if (e.button === 0 && this.props.interactMode === "editable" && blockFullLength === this.props.blockHeight) {
                this.props.startEdit(this.props.sourceIndex, this.props.groupIndex, this.props.blockIndex, this.props.event_id, "B");
              }
            }}
            style={{overflow: "hidden", WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", cursor: this.props.interactMode === "editable" && blockFullLength === this.props.blockHeight ? "ns-resize" : "pointer", width: "100%", height: innerBlockFullLength, ...barStyles[innerBlockFullLength !== "100%" ? "middle" : "single"]}}><div style={"text" in barStyles ? barStyles.text : {}}>{this.props.caption !== undefined ? this.props.caption : null}</div></div>
          {innerBlockFullLength !== "100%" ? <div
            draggable={false}
            onMouseDown={(e)=>{
              if (e.button === 0 && this.props.interactMode === "editable") {
                this.props.startEdit(this.props.sourceIndex, this.props.groupIndex, this.props.blockIndex, this.props.event_id, "B");
              }
            }}
            style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", cursor: "ns-resize", width: "100%", height: this.props.blockHeight, ...barStyles.end}}/> : null}
        </div>
      );
    } else {
      return null;
    }
  }
}

function renderMonthDays(dayArray, calProps) {
  return (dayArray.map((dayObj)=>{
    return (<div draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", paddingTop: Math.round(calProps.blockHeight/(50/10)), fontSize: Math.round(calProps.blockWidth/(50/15)), width: calProps.blockWidth, color: "#252631", border: "1px solid #e8ecef", textAlign: "center", display: "inline", float: "left"}} key={dayObj.currentYear.toString() + "/" + (dayObj.currentMonth + 1).toString() + "/" + dayObj.currentDay.toString()}>{dayObj.weekDay}<br/>{dayObj.currentDay}</div>);
  }));
}
function renderMonths(monthArray, calProps) {
  return (monthArray.map((monthObj)=>{
    return <MonthBlock key={monthObj.currentYear.toString() + "/" + (monthObj.currentMonth + 1).toString()} daysOfMonth={monthObj.daysOfMonth} currentYear={monthObj.currentYear} currentMonth={monthObj.currentMonth} calendarView={calProps.calendarView} xScroll={calProps.xScroll} earliestDate={calProps.earliestDate} calProps={calProps}/>
  }));
}
function renderYears(yearArray, calProps) {
  return (yearArray.map((currentYear)=>{
    return <YearBlock key={currentYear} currentYear={currentYear} daysOfYear={getDaysPerYear(currentYear)} calendarView={calProps.calendarView} xScroll={calProps.xScroll} calProps={calProps}/>
  }));
}

//Returns [headerData, calWidth]
function generateHeaders(earliestDate, blockWidth) {
  let currentYear = 0;
  let yearStart = earliestDate.getFullYear();
  let currentWeekDay = earliestDate.getDay();
  let yearEnd = earliestDate.getFullYear()+2;
  let yearList = [];
  let monthList = [];
  let dayList = [];
  for (let yearIncrement = 0; yearIncrement + yearStart <= yearEnd; yearIncrement++) {
    currentYear = yearStart + yearIncrement;
    for (let currentMonth = 0; currentMonth < 12; currentMonth++) {
      let daysOfMonth = DAYS_PER_MONTH[currentMonth].days(currentYear);
      for (let currentDay = 1; currentDay <= daysOfMonth; currentDay++) {
        let weekDay = DAYS_OF_WEEK[currentWeekDay++];
        dayList.push({
          currentYear: currentYear,
          currentMonth: currentMonth,
          currentDay: currentDay,
          weekDay: weekDay
        });
        if (currentWeekDay > 6)
          currentWeekDay = 0;
      }

      monthList.push({
          currentYear: currentYear,
          currentMonth: currentMonth,
          daysOfMonth: daysOfMonth,
      });
    }

    yearList.push(currentYear);
  }
  return [[yearList,monthList,dayList], dayList.length * blockWidth]
}

class CommsBarCalendar extends React.Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    let yearStart = currentDate.getFullYear();
    let calendarView = this.props.calendarView || "month";
    this.state = {
      todayDate: currentDate,
      earliestDate: new Date(yearStart-1, 0, 1),
      xScroll: calendarView === "month" ? getDateInt(currentDate, yearStart-1, this.props.blockWidth, calendarView) : 0,
      calKey: 0
    }
  }
  updateScroll = (xScroll, earliestDate=null) => {
    if (earliestDate)
      this.setState({xScroll: xScroll, earliestDate: earliestDate});
    else
      this.setState({xScroll: xScroll});
  }
  updateLock = (lockOn) => {
    this.props.updateLock(lockOn);
  }
  render() {
    if (!this.props.loading)
      return (<RouteOne
        key={this.state.calKey + "loaded"}
        dataSource={this.props.dataSource}
        calendarView={this.props.calendarView}
        todayDate={this.state.todayDate}
        scrollTargetDate={this.props.scrollTargetDate}
        showHeader={this.props.showHeader}
        showTimeBar={this.props.showTimeBar}
        interactMode={this.props.interactMode}
        orientation={this.props.orientation}
        newEvent={this.props.newEvent}
        editEvent={this.props.editEvent}
        clickEvent={this.props.clickEvent}
        blockWidth={this.props.blockWidth}
        blockHeight={this.props.blockHeight}
        eventStyles={this.props.eventStyles}
        multipleDataSources={this.props.multipleDataSources}
        earliestDate={this.state.earliestDate}
        xScroll={this.state.xScroll}
        lockOn={this.props.lockOn}
        updateScroll={this.updateScroll}
        updateLock={this.updateLock}
      />);
    else
      return (<RouteOne
        key={this.state.calKey + "loading"}
        dataSource={[]}
        calendarView={this.props.calendarView}
        todayDate={this.state.todayDate}
        scrollTargetDate={this.props.scrollTargetDate}
        showHeader={this.props.showHeader}
        showTimeBar={this.props.showTimeBar}
        interactMode={""}
        orientation={this.props.orientation}
        newEvent={this.props.newEvent}
        editEvent={this.props.editEvent}
        clickEvent={this.props.clickEvent}
        blockWidth={this.props.blockWidth}
        blockHeight={this.props.blockHeight}
        eventStyles={this.props.eventStyles}
        multipleDataSources={this.props.multipleDataSources}
        earliestDate={this.state.earliestDate}
        xScroll={this.state.xScroll}
        lockOn={this.props.lockOn}
        updateScroll={this.updateScroll}
        updateLock={this.updateLock}
        loading={true}
      />);
  }
}

class RouteOne extends React.Component {
  constructor(props) {
    super(props);
    let calendarView = "calendarView" in this.props ? this.props.calendarView : "month";
    let currentDate = new Date();
    let yearStart = currentDate.getFullYear();
    let rowLabelList = [];
    let calFullBlockList = [];
    let calGroupList = [];
    let verticalList = [];
    let horizontalList = [];
    let totalNumDays = 0;
    let eventStyles = typeof this.props.eventStyles === "object" ? this.props.eventStyles : {
      default: "#778ca2"
    };
    let calBlockList;
    let highestId = 0;
    let headerData = [];
    let calWidth;

    for (let i = yearStart-1; i <= yearStart+1; i++) {
      totalNumDays += getDaysPerYear(i);
    }

    if (this.props.orientation === "horizontal") {
      if (this.props.multipleDataSources) {
        this.props.dataSource.forEach((individualSource, individualIndex) => {
          calGroupList = [];
          individualSource.forEach((blockGroup, groupIndex) => {
            calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
              if ("event_id" in barBlock && barBlock.event_id >= highestId)
                highestId = barBlock.event_id+1;
              return {
                startDate: calendarView === "month" ? new Date(barBlock.startDate) : barBlock.startDate,
                endDate: calendarView === "month" ? new Date(barBlock.endDate) : barBlock.endDate,
                event_id: barBlock.event_id,
                withTimeOfDay: true,
                eventStyle: barBlock.eventStyle,
                eventStyles: eventStyles,
                caption: barBlock.caption,
                extraEventData: barBlock.extraEventData
              };
            });
    
            if ("group_label" in blockGroup)
              rowLabelList.push(
                <div draggable={false} key={blockGroup.group_label + groupIndex.toString()} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", fontSize: "90%", height: this.props.blockHeight, width: Math.round(this.props.blockWidth/(50/201)), border: '1px solid #e8ecef', color: "#252631"}}>{blockGroup.group_label}</div>
              );
    
            calGroupList.push(calBlockList);
          });
          calFullBlockList.push(calGroupList);
        });
      } else {
        calGroupList = [];
        this.props.dataSource.forEach((blockGroup, groupIndex) => {
          calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
            if ("event_id" in barBlock && barBlock.event_id >= highestId)
              highestId = barBlock.event_id+1;
            return {
              startDate: calendarView === "month" ? new Date(barBlock.startDate) : barBlock.startDate,
              endDate: calendarView === "month" ? new Date(barBlock.endDate) : barBlock.endDate,
              event_id: barBlock.event_id,
              withTimeOfDay: true,
              eventStyle: barBlock.eventStyle,
              eventStyles: eventStyles,
              caption: barBlock.caption,
              extraEventData: barBlock.extraEventData
            };
          });

          if ("group_label" in blockGroup)
            rowLabelList.push(
              <div draggable={false} key={blockGroup.group_label + groupIndex.toString()} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", fontSize: "90%", height: this.props.blockHeight, width: Math.round(this.props.blockWidth/(50/201)), border: '1px solid #e8ecef', color: "#252631"}}>{blockGroup.group_label}</div>
            );

          calGroupList.push(
            calBlockList
          );
        });
        calFullBlockList.push(calGroupList);
      }
    } else {
      if (this.props.multipleDataSources) {
        this.props.dataSource.forEach((individualSource, individualIndex) => {
          calGroupList = [];
          individualSource.forEach((blockGroup, groupIndex) => {
            calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
              if ("event_id" in barBlock && barBlock.event_id >= highestId)
                highestId = barBlock.event_id+1;
              return {
                startTime: barBlock.startTime,
                endTime: barBlock.endTime,
                event_id: barBlock.event_id,
                startDate: calendarView === "month" ? new Date(barBlock.startDate) : barBlock.startDate,
                withTimeOfDay: true,
                eventStyle: barBlock.eventStyle,
                eventStyles: eventStyles,
                caption: barBlock.caption,
                extraEventData: barBlock.extraEventData
              };
            });
    
            rowLabelList.push(
              <div draggable={false} key={blockGroup.group_label + groupIndex.toString()} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", fontSize: "90%", height: this.props.blockHeight, width: Math.round(this.props.blockWidth/(50/201)), border: '1px solid #e8ecef', color: "#252631"}}>{blockGroup.group_label}</div>
            );
    
            calGroupList.push(calBlockList);
          });
          calFullBlockList.push(calGroupList);
        });
      } else {
        calGroupList = [];
        this.props.dataSource.forEach((blockGroup, groupIndex) => {
          calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
            if ("event_id" in barBlock && barBlock.event_id >= highestId)
              highestId = barBlock.event_id+1;
            return {
              startTime: barBlock.startTime,
              endTime: barBlock.endTime,
              event_id: barBlock.event_id,
              startDate: calendarView === "month" ? new Date(barBlock.startDate) : barBlock.startDate,
              withTimeOfDay: false,
              eventStyle: barBlock.eventStyle,
              eventStyles: eventStyles,
              caption: barBlock.caption,
              extraEventData: barBlock.extraEventData
            };
          });


          calGroupList.push(
            calBlockList
          );
        });
        for (let currentHour = 0; currentHour < 24; currentHour++) {
          rowLabelList.push(
            <div draggable={false} key={currentHour} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", fontSize: "90%", height: this.props.blockHeight, width: Math.round(this.props.blockWidth/(50/201)), border: '1px solid #e8ecef', color: "#252631"}}>{`${getDayHour(currentHour)}`}</div>
          );
        }
        calFullBlockList.push(calGroupList);
      }
    }
    if (calendarView === "month") {
      let generatedHeader = generateHeaders(this.props.earliestDate, this.props.blockWidth);
      headerData = generatedHeader[0];
      calWidth = generatedHeader[1];
    } else if (calendarView === "week") {
      headerData = (["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"].map((day)=>{
        return <div draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", paddingTop: Math.round(this.props.blockHeight/(50/10)), fontSize: Math.round(this.props.blockWidth/(50/15)), width: this.props.blockWidth, color: "#252631", border: "1px solid #e8ecef", textAlign: "center", display: "inline", float: "left"}} key={day}>{day}</div>;
      }));
      calWidth = 7 * this.props.blockWidth;
    } else if (calendarView === "day") {
      calWidth = this.props.blockWidth;
    }

    let boxHeight = this.props.blockHeight * rowLabelList.length;
    let boxWidth = this.props.blockWidth * totalNumDays;
    let blockColor;

    if (calendarView === "month") {
      for (let verticalBlock = 0; verticalBlock < totalNumDays; verticalBlock++) {
        verticalList.push(
          <div draggable={false} key={verticalBlock} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", position: "relative", height: boxHeight, width: this.props.blockWidth, border: `1px solid #e8ecef`, backgroundColor: blockColor}}/>);
      }
      for (let horizontalBlock = 0; horizontalBlock < rowLabelList.length; horizontalBlock++) {
        horizontalList.push(
          <div draggable={false} key={horizontalBlock} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", clear:"both", position: "relative", height: this.props.blockHeight, width: boxWidth, border: `1px solid #e8ecef`}}/>);
      }
    } else if (calendarView === "week") {
      for (let verticalBlock = 0; verticalBlock < 6; verticalBlock++) {
        verticalList.push(
          <div draggable={false} key={verticalBlock} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", position: "relative", height: boxHeight, width: this.props.blockWidth, border: `1px solid #e8ecef`, backgroundColor: blockColor}}/>);
      }
      for (let horizontalBlock = 0; horizontalBlock < rowLabelList.length; horizontalBlock++) {
        horizontalList.push(
          <div draggable={false} key={horizontalBlock} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", clear:"both", position: "relative", height: this.props.blockHeight, width: this.props.blockWidth*7, border: `1px solid #e8ecef`}}/>);
      }
    } else if (calendarView === "day") {
      verticalList = [
        (<div draggable={false} key={0} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", position: "relative", height: boxHeight, width: this.props.blockWidth, border: `1px solid #e8ecef`, backgroundColor: blockColor}}/>)
      ];
      for (let horizontalBlock = 0; horizontalBlock < rowLabelList.length; horizontalBlock++) {
        horizontalList.push(
          <div draggable={false} key={horizontalBlock} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", float: "left", clear:"both", position: "relative", height: this.props.blockHeight, width: this.props.blockWidth, border: `1px solid #e8ecef`}}/>);
      }
    }

    this.state = {
      calendarView: calendarView,
      leftYearBound: getDateInt(new Date(yearStart, 0, 1), yearStart-1, this.props.blockWidth, calendarView),
      rightYearBound: getDateInt(new Date(yearStart+1, 0, 1), yearStart-1, this.props.blockWidth, calendarView),
      currentDate: currentDate,
      currentYear: currentDate.getFullYear(),
      loadedData: {rowLabelList, calFullBlockList, verticalList, horizontalList},
      headerData: headerData,
      openModal: false,
      modalData: null,
      totalNumDays: totalNumDays,
      dragData: null,
      highestId: highestId,
      calWidth: calWidth
    };

    this.topScroll = React.createRef();
    this.calScroll = React.createRef();
    this.sideScroll = React.createRef();
  }
  
  scrollUpdate = (scrollLeft) => {
    if (this.state.calendarView === "month") {
      //Load Earlier Year
      if (scrollLeft < this.state.leftYearBound) {
        let earliestDate = new Date(this.props.earliestDate.getFullYear()-1, 0, 1);
        let generatedHeader = generateHeaders(earliestDate, this.props.blockWidth);
        let headerData = generatedHeader[0];
        let calWidth = generatedHeader[1];
        let yearLength = getDateDiff(this.props.earliestDate, earliestDate, this.props.blockWidth);
        this.setState({leftYearBound: getDateInt(this.props.earliestDate, earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), rightYearBound: getDateInt(new Date(this.props.earliestDate.getFullYear()+1, 0, 1), earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), currentYear: this.props.earliestDate.getFullYear(), headerData: headerData, calWidth: calWidth});
        this.props.updateScroll(scrollLeft + yearLength, earliestDate);
        this.props.updateLock(true);
      //Load Later Year
      } else if (scrollLeft > this.state.rightYearBound) {
        let earliestDate = new Date(this.props.earliestDate.getFullYear()+1, 0, 1);
        let generatedHeader = generateHeaders(earliestDate, this.props.blockWidth);
        let headerData = generatedHeader[0];
        let calWidth = generatedHeader[1];
        let yearLength = getDateDiff(earliestDate, this.props.earliestDate, this.props.blockWidth);
        this.setState({leftYearBound: getDateInt(new Date(this.props.earliestDate.getFullYear()+2, 0, 1), earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), rightYearBound: getDateInt(new Date(this.props.earliestDate.getFullYear()+3, 0, 1), earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), currentYear: this.props.earliestDate.getFullYear(), headerData: headerData, calWidth: calWidth});
        this.props.updateScroll(scrollLeft - yearLength, earliestDate);
        this.props.updateLock(true);
      } else {
        this.props.updateScroll(scrollLeft);
        this.props.updateLock(false);
      }
    }
  }

  onMouseMove = (event) => {
    if (this.state.dragData !== null) {
      let calBox = this.calScroll.current.getBoundingClientRect();
      let xPos = Math.floor((Math.abs(Math.floor(calBox.left) - event.pageX)+this.calScroll.current.scrollLeft)/this.props.blockWidth);
      let targetDate = null;
      if (this.state.calendarView === "month") {
        targetDate = new Date(this.props.earliestDate.valueOf());
        targetDate.setDate(targetDate.getDate()+xPos);
      } else if (this.state.calendarView === "week") {
        targetDate = ["Mon","Tues","Weds","Thurs","Fri","Sat","Sun"][xPos];
      }
      let yPos = Math.floor((Math.abs(Math.floor(calBox.top) - event.pageY)+this.calScroll.current.scrollTop)/this.props.blockHeight);
      if ((this.state.dragData.grabPoint === "A" && this.state.loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex].startTime !== yPos) || (this.state.dragData.grabPoint === "B" && this.state.loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex].endTime !== yPos+1)) {
        let loadedData = {...this.state.loadedData};
        let dragData = {...this.state.dragData};
        if (dragData.grabPoint === "A" && yPos >= loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].endTime) {
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startTime = loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].endTime-1;
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].endTime = yPos+1;
          dragData.grabPoint = "B";
        } else if (dragData.grabPoint === "B" && yPos <= loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.  selectedBar.blockIndex].startTime) {
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].endTime = loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startTime+1;
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startTime = yPos;
          dragData.grabPoint = "A";
        } else if (dragData.grabPoint === "A") {
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startTime = yPos;
        } else {
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].endTime = yPos+1;
        }
        if (loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startDate !== targetDate)
          loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][dragData.selectedBar.groupIndex][dragData.selectedBar.blockIndex].startDate = targetDate;
        this.setState({loadedData: loadedData, dragData: dragData});
      } else if (this.state.loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex].startDate !== targetDate) {
        let loadedData = {...this.state.loadedData};
        loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex].startDate = targetDate;
        this.setState({loadedData: loadedData});
      }
    }
  }
  commitEditData = async (event) => {
    if (event.button === 0 && this.state.dragData !== null) {
      let loadedData = {...this.state.loadedData};
      if (this.state.dragData.isNew) {
        let oldDragData = {...this.state.dragData};
        this.setState({dragData: null});
        let newEditData = "newEvent" in this.props ? await this.props.newEvent({...loadedData.calFullBlockList[oldDragData.selectedBar.sourceIndex][oldDragData.selectedBar.groupIndex][oldDragData.selectedBar.blockIndex]}) : {...loadedData.calFullBlockList[oldDragData.selectedBar.sourceIndex][oldDragData.selectedBar.groupIndex][oldDragData.selectedBar.blockIndex]};
        newEditData.startDate = new Date(newEditData.startDate);
        
        /*//If source is changed
        if (newEditData.sourceIndex !== oldDragData.selectedBar.sourceIndex) {
          //If source is new
          if (newEditData.sourceIndex >= loadedData.calFullBlockList.length) {
            loadedData.calFullBlockList.push([]);
            let newSourceIndex = loadedData.calFullBlockList.length - 1;
            loadedData.calFullBlockList[newSourceIndex].push([]);
            newEditData.sourceIndex = newSourceIndex;
            newEditData.groupIndex = 0;
            newEditData.blockIndex = 0;
            loadedData.calFullBlockList[newSourceIndex][0].push(newEditData);
          }
          //If source exists
          else {
            //If group is new
            if (newEditData.groupIndex >= loadedData.calFullBlockList[newEditData.sourceIndex].length) {
              loadedData.calFullBlockList[newEditData.sourceIndex].push([]);
              let newGroupIndex = loadedData.calFullBlockList[newEditData.sourceIndex].length - 1;
              newEditData.groupIndex = newGroupIndex;
              newEditData.blockIndex = 0;
              loadedData.calFullBlockList[newEditData.sourceIndex][newGroupIndex].push();
            }
          }
        } else*/
          loadedData.calFullBlockList[newEditData.sourceIndex][newEditData.groupIndex][newEditData.blockIndex] = newEditData;
        this.props.editEvent({operation: "new", id: oldDragData.selectedBar.event_id, data: newEditData});
        this.setState({loadedData: loadedData});
      } else {
        let newSourceIndex = this.state.dragData.selectedBar.sourceIndex;
        let newGroupIndex = this.state.dragData.selectedBar.groupIndex;
        let newBlockIndex = this.state.dragData.selectedBar.blockIndex;
        let newEditData = loadedData.calFullBlockList[newSourceIndex][newGroupIndex][newBlockIndex];
        let editObj = {operation: "edit", id: this.state.dragData.selectedBar.event_id, data: loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex]};
        let highestId = this.state.highestId;
        for (let currentBlock = 0; currentBlock < loadedData.calFullBlockList[newSourceIndex][newGroupIndex].length; currentBlock++) {
          let blockObj = loadedData.calFullBlockList[newSourceIndex][newGroupIndex][currentBlock];
          let newId;
          if (newEditData.startDate.toString() === blockObj.startDate.toString() && currentBlock !== newBlockIndex) {
            if (blockObj.startTime >= newEditData.startTime && blockObj.startTime <= newEditData.endTime || blockObj.endTime >= newEditData.startTime && blockObj.endTime <= newEditData.endTime) {
              if (editObj.operation === "edit") {
                if (newEditData.event_id < blockObj.event_id) {
                  newEditData.startTime = newEditData.startTime < blockObj.startTime ? newEditData.startTime : blockObj.startTime;
                  newEditData.endTime = newEditData.endTime > blockObj.endTime ? newEditData.endTime : blockObj.endTime;
                  newId = blockObj.event_id;
                  if (blockObj.event_id+1 === this.state.highestId)
                    highestId--;
                  loadedData.calFullBlockList[newSourceIndex][newGroupIndex].splice(currentBlock, 1);
                  editObj = {operation: "merge", id: editObj.id, delete_ids: [newId], data: newEditData};
                  if (currentBlock > 0)
                    currentBlock--;
                } else {
                  blockObj.startTime = blockObj.startTime < newEditData.startTime ? blockObj.startTime : newEditData.startTime;
                  blockObj.endTime = blockObj.endTime > newEditData.endTime ? blockObj.endTime : newEditData.endTime;
                  newId = blockObj.event_id;
                  if (newEditData.event_id+1 === this.state.highestId)
                    highestId--;
                  newEditData = blockObj;
                  loadedData.calFullBlockList[newSourceIndex][newGroupIndex].splice(newBlockIndex, 1);
                  if (newBlockIndex < currentBlock)
                    currentBlock--;
                  newBlockIndex = currentBlock;
                  editObj = {operation: "merge", id: newId, delete_ids: [editObj.id], data: newEditData};
                }
              }
              else {
                if (newEditData.event_id < blockObj.event_id) {
                  newEditData.startTime = newEditData.startTime < blockObj.startTime ? newEditData.startTime : blockObj.startTime;
                  newEditData.endTime = newEditData.endTime > blockObj.endTime ? newEditData.endTime : blockObj.endTime;
                  newId = blockObj.event_id;
                  if (blockObj.event_id+1 === this.state.highestId)
                    highestId--;
                  loadedData.calFullBlockList[newSourceIndex][newGroupIndex].splice(currentBlock, 1);
                  editObj.delete_ids.push(newId);
                  if (currentBlock > 0)
                    currentBlock--;
                } else {
                  blockObj.startTime = blockObj.startTime < newEditData.startTime ? blockObj.startTime : newEditData.startTime;
                  blockObj.endTime = blockObj.endTime > newEditData.endTime ? blockObj.endTime : newEditData.endTime;
                  newId = blockObj.event_id;
                  if (newEditData.event_id+1 === this.state.highestId)
                    highestId--;
                  newEditData = blockObj;
                  loadedData.calFullBlockList[newSourceIndex][newGroupIndex].splice(newBlockIndex, 1);
                  if (newBlockIndex < currentBlock)
                    currentBlock--;
                  newBlockIndex = currentBlock;
                  editObj.delete_ids.push(editObj.id);
                  editObj.id = newId;
                }
              }
            }
          }
        }
        this.props.editEvent(editObj);
        this.setState({dragData: null, highestId: highestId});
      }
    }
  }
  letGoEditData = () => {
    if (this.state.dragData !== null) {
      let loadedData = {...this.state.loadedData};
      let highestId = this.state.highestId;
      if (!this.state.dragData.isNew)
        loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex][this.state.dragData.selectedBar.blockIndex] = this.state.dragData.formerData;
      else {
        loadedData.calFullBlockList[this.state.dragData.selectedBar.sourceIndex][this.state.dragData.selectedBar.groupIndex].splice(this.state.dragData.selectedBar.blockIndex, 1);
      }
      if (this.state.dragData.selectedBar.event_id+1 === this.state.highestId)
        --highestId;
      this.setState({dragData: null, loadedData: loadedData, highestId: highestId});
    }
  }
  startEdit = (sourceIndex, groupIndex, blockIndex, event_id, grabPoint, isNew = false) => {
    if (this.props.interactMode === "editable"){
      this.setState({dragData: {
        selectedBar: {
          sourceIndex: sourceIndex,
          groupIndex: groupIndex,
          blockIndex: blockIndex,
          event_id: event_id
        },
        grabPoint: grabPoint,
        formerData: {...this.state.loadedData.calFullBlockList[sourceIndex][groupIndex][blockIndex]},
        isNew: isNew
      }});
    }
  }
  deleteEvent = (sourceIndex, groupIndex, blockIndex, event_id) => {
    let loadedData = {...this.state.loadedData};
    let editedData = {operation: "delete", id: event_id, data: null};
    let highestId = this.state.highestId;
    if (event_id+1 === this.state.highestId)
      --highestId;
    loadedData.calFullBlockList[sourceIndex][groupIndex].splice(blockIndex, 1);
    this.setState({loadedData: loadedData, dragData: null, highestId: highestId});
    this.props.editEvent(editedData);
  }

  componentDidMount() {
    this.props.updateLock(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTargetDate && this.props.scrollTargetDate && (prevProps.scrollTargetDate.getTime() !== this.props.scrollTargetDate.getTime())) {
      if (this.state.calendarView === "month") {
        //Load Outside Current Bounds
        let newDatePix = getDateInt(this.props.scrollTargetDate, this.props.earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView)
        if (newDatePix < this.state.leftYearBound || newDatePix > this.state.rightYearBound) {
          let earliestDate = new Date(this.props.scrollTargetDate.getFullYear()-1, 0, 1);
          let generatedHeader = generateHeaders(earliestDate, this.props.blockWidth);
          let headerData = generatedHeader[0];
          let calWidth = generatedHeader[1];
          this.setState({leftYearBound: getDateInt(new Date(earliestDate.getFullYear()+1, 0, 1), earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), rightYearBound: getDateInt(new Date(earliestDate.getFullYear()+2, 0, 1), earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView), currentYear: this.props.scrollTargetDate.getFullYear(), headerData: headerData, calWidth: calWidth});
          newDatePix = getDateInt(this.props.scrollTargetDate, earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView);
          this.props.updateScroll(newDatePix, earliestDate);
          this.props.updateLock(true);
        //Jump Within Bounds
        } else {
          this.props.updateScroll(newDatePix);
          this.props.updateLock(true);
        }
      }
    }
  }

  render() {
    let calHeight = this.props.blockHeight * this.state.loadedData.rowLabelList.length;
    let calOverflowX;
    let scrollBarSize;
    let headerData = [];
    if (this.state.calendarView === "month") {
      scrollBarSize = 17;
      calOverflowX = "scroll";
      headerData = [renderYears(this.state.headerData[0],this.props),renderMonths(this.state.headerData[1],this.props),renderMonthDays(this.state.headerData[2],this.props)];
    } else if (this.state.calendarView === "week") {
      scrollBarSize = 0;
      calOverflowX = "hidden";
      headerData = [this.state.headerData];
    } else if (this.state.calendarView === "day") {
      scrollBarSize = 0;
    }
    if (this.calScroll.current !== null && this.props.showHeader) {
      if (this.props.lockOn) {
        this.topScroll.current.scrollLeft = this.props.xScroll;
        this.calScroll.current.scrollLeft = this.props.xScroll;
      } else {
        if (this.topScroll.current.scrollLeft !== this.props.xScroll)
          this.topScroll.current.scrollLeft = this.props.xScroll;
      }
    }
    let zIndexLayers = this.state.loadedData.calFullBlockList.length + 4;
    return (
      <div draggable={false} style={{cursor: this.state.dragData !== null ? "ns-resize" : "default", maxWidth: Math.round(this.props.blockWidth/(50/201)) + this.state.calWidth}}>
        {this.props.showHeader && !this.props.loading && this.state.headerData.length > 0 ? <div draggable={false} style={{overflowX: "hidden", marginLeft: this.props.blockWidth*4, border: '1px solid #e8ecef', backgroundColor: "white", maxWidth: this.state.calWidth}} ref={this.topScroll}>
          {headerData.map((headerArray, headerIndex)=>{
            return <div key={headerIndex} draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", clear: "right", float: "left", width: this.state.calWidth}}>
              {headerArray}
            </div>;
          })}
        </div> : null}
        <div draggable={false} ref={this.sideScroll} style={{float: "left", backgroundColor: "white"}}>
          {this.state.loadedData.rowLabelList}
        </div>
        <Modal zIndex={zIndexLayers--} centered size="sm" visible={this.state.openModal} onCancel={()=>{this.props.setModalValues(-1, ""); this.setState({openModal: false})}} footer={null}>
          {this.state.modalData}
        </Modal>
        {!this.props.loading ? <div onContextMenu={(event)=>{event.preventDefault();}} draggable={false} onMouseUp={this.commitEditData} onMouseMove={this.onMouseMove} onScroll={() => {this.scrollUpdate(this.calScroll.current.scrollLeft, this.calScroll.current.scrollTop)}} ref={this.calScroll} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", position: "relative", height: (this.state.loadedData.rowLabelList.length > 0 ? this.state.loadedData.rowLabelList.length : 1) * this.props.blockHeight + scrollBarSize, overflowX: calOverflowX, overflowY: "hidden", marginLeft: (this.props.blockWidth*4)+1, backgroundColor: "#f2f3f6"}}>
          <div draggable={false} onMouseLeave={this.letGoEditData} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", width: this.state.calWidth, height: (this.state.loadedData.rowLabelList.length > 0 ? this.state.loadedData.rowLabelList.length : 1) * this.props.blockHeight}}>
            {this.props.showTimeBar && (this.props.todayDate.getFullYear() < this.props.earliestDate.getFullYear()+2 && this.props.todayDate.getFullYear() > this.props.earliestDate.getFullYear()) ?
              <div draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", pointerEvents: "none", position: "absolute", height: calHeight, left: getDateInt(this.state.currentDate, this.props.earliestDate.getFullYear(), this.props.blockWidth, this.state.calendarView, true), width: 2, border: `1px solid #4d7cfe`, zIndex: zIndexLayers--}}></div>
            : null}
            {this.state.loadedData.calFullBlockList.map((currentSource,sourceIndex) => {
                return <div draggable={false} key={"sourcegroup"+sourceIndex} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", pointerEvents: "none", position: "absolute", height: "100%", width: this.state.calWidth, zIndex: zIndexLayers--}}>{currentSource.map((currentGroup, groupIndex)=>{
                  return <div draggable={false} key={"blockgroup"+groupIndex} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", pointerEvents: "none", top: this.props.orientation === "horizontal" ? groupIndex*this.props.blockHeight : 0, width: this.state.calWidth, height: this.props.orientation === "vertical" ? "100%" : this.props.blockHeight, position: "absolute", clear: this.props.orientation === "horizontal" ? "both" : "none"}}>{currentGroup.map((eventBlock, blockIndex) => {
                    return eventBlock !== null && typeof eventBlock.startDate !== "undefined" && (eventBlock.startDate.getFullYear() < this.props.earliestDate.getFullYear()+2 || (eventBlock.endDate && eventBlock.endDate.getFullYear() > this.props.earliestDate.getFullYear())) ? (
                      <EventBlock
                        key={blockIndex}
                        earliestDate={this.props.earliestDate}
                        multipleDataSources={this.props.multipleDataSources}
                        interactMode={this.props.interactMode}
                        orientation={this.props.orientation}
                        blockWidth={this.props.blockWidth}
                        blockHeight={this.props.blockHeight}
                        startDate={eventBlock.startDate}
                        startTime={eventBlock.startTime}
                        endDate={eventBlock.endDate}
                        endTime={eventBlock.endTime}
                        withTimeOfDay={eventBlock.withTimeOfDay}
                        groupIndex={groupIndex}
                        blockIndex={blockIndex}
                        sourceIndex={sourceIndex}
                        event_id={eventBlock.event_id}
                        eventStyle={eventBlock.eventStyle}
                        eventStyles={eventBlock.eventStyles}
                        caption={eventBlock.caption}
                        extraEventData={eventBlock.extraEventData}
                        startEdit={this.startEdit}
                        clickEvent={this.props.clickEvent}
                        deleteEvent={this.deleteEvent}
                        calendarView={this.state.calendarView}
                        dragData={this.state.dragData}
                        scrollTargetDate={this.props.scrollTargetDate}
                      />) : null;
                  })}</div>
                })}</div>;
            })}
            <div draggable={false} onMouseDown={(event)=>{
              if (event.button === 0 && this.props.interactMode === "editable") {
                let eventStyles = typeof this.props.eventStyles === "object" ? this.props.eventStyles : {
                  default: {
                    start: {
                      opacity: 0.65,
                      backgroundColor: "#2ce5f6"
                    },
                    middle: {
                      opacity: 0.65,
                      backgroundColor: "#2ce5f6"
                    },
                    end: {
                      opacity: 0.65,
                      backgroundColor: "#2ce5f6"
                    },
                    single: {
                      opacity: 0.65,
                      backgroundColor: "#2ce5f6"
                    }
                  }
                };
                let calBox = this.calScroll.current.getBoundingClientRect();
                let xPos = Math.floor((Math.abs(Math.floor(calBox.left) - event.pageX)+this.calScroll.current.scrollLeft)/this.props.blockWidth);
                let yPos = Math.floor((Math.abs(Math.floor(calBox.top) - event.pageY)+this.calScroll.current.scrollTop)/this.props.blockHeight);
                let targetDate = null;
                if (this.state.calendarView === "month") {
                  targetDate = new Date(this.props.earliestDate.valueOf());
                  targetDate.setDate(targetDate.getDate()+xPos);
                } else if (this.state.calendarView === "week") {
                  targetDate = ["Mon","Tues","Weds","Thurs","Fri","Sat","Sun"][xPos];
                }
                let loadedData = {...this.state.loadedData};
                let newIndex = loadedData.calFullBlockList[0][0].length;
                let oldEventId = this.state.highestId;
                loadedData.calFullBlockList[0][0].push(
                  {
                    startTime: yPos,
                    endTime: yPos+1,
                    startDate: targetDate,
                    withTimeOfDay: false,
                    groupIndex: 0,
                    blockIndex: loadedData.calFullBlockList[0][0].length,
                    sourceIndex: 0,
                    event_id: oldEventId,
                    eventStyle: "default",
                    eventStyles: eventStyles,
                    isNew: true
                  }
                );
                this.setState({loadedData: loadedData, highestId: this.state.highestId+1});
                this.startEdit(0, 0, newIndex, oldEventId, "B", true);
              }
            }} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", height: calHeight}}>
              <div draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", position: "absolute", height: calHeight, width: this.state.calWidth, zIndex: zIndexLayers--}}>{this.state.loadedData.horizontalList}</div>
              <div draggable={false} style={{WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none", position: "absolute", height: calHeight, width: this.state.calWidth, zIndex: zIndexLayers--}}>{this.state.loadedData.verticalList}</div>
            </div>
          </div>
        </div> : <div style={{height: calHeight, backgroundColor: "#f2f3f6"}}><LoadScreen/></div>}
      </div>
    );
  }
}

export default CommsBarCalendar;
