import React, { Component } from "react";
import { Input } from "antd";

export default class ReworkWorkOrder extends Component {
    constructor(props) {
        super(props);
    }

    updateReworkWOContextTextArea = (e) => {
        this.props.reviewWOContext.updateReworkWOContextTextArea(e.target.value);
    }

    render() {
        return (
            <>
                <div style={{marginTop: '-10px', marginBottom: '5px'}}>
                    What additional work should be done
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <Input.TextArea autoSize={ {minRows: 2, maxRows: 4} } placeholder="Input additional work needed, etc..." onChange={this.updateReworkWOContextTextArea} />
                </div>
            </>
        )
    }
}