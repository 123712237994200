import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function LoadScreen({ className = '', small = false }) {
	if (small) {
		return <Spin indicator={antIcon} className={className} />;
	} else {
		return (
			<div style={{ position: 'relative', width: '100%', height: '100%' }}>
				<img
					src={require('../icons/loader.gif')}
					className={'loadIcon ' + className}
					alt='Loading'
					width={64}
					height={64}
				/>
			</div>
		);
	}
}

export default LoadScreen;
