import React from "react";
import { Form } from "react-bootstrap";
import "./landing-style.css";
import "./landing-management.css";
import { Button, Alert } from "antd";
import { MailOutlined } from '@ant-design/icons';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
const cancelToken = axios.CancelToken.source();

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      submitted:false,
      loading:false,
      errorMessage: ""
    };

    this.recaptchaRef = React.createRef();
  }

  componentWillMount() {
    document.title = "Steady Property Management";
  }
  verify = () => {
    if(this.state.name === '') {
      this.setState({errorMessage: 'Please enter your name.'});
      return false;
    }
    else if(this.state.email === ''){
      this.setState({errorMessage: 'Please enter your email address'});
      return false;
    }
    else if(this.state.phone.length > 0 && this.state.phone.length < 9){
      this.setState({errorMessage: 'Please enter a valid phone number.'});
      return false;
    }
    else if(this.state.message === ''){
      this.setState({errorMessage: 'Please enter a message.'});
      return false;
    }
    else if (this.recaptchaRef.current.getValue() !== null && this.recaptchaRef.current.getValue().length <= 0) {
      this.setState({errorMessage: 'Please complete the reCAPTCHA.'});
      return false;
    }
    return true
  }
  handleChange = (e) => {
    this.setState({[e.target.name]:e.target.value})
  }
  submitForm = () => {
    if(this.verify()) {
      this.setState({loading:true});
      axios.post("/api/landingMessage", {
        crossdomain: true,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
        recaptcha: this.recaptchaRef.current.getValue()
      })
        .then((response) => {
          this.setState({
            submitted:response.data.ok,
            loading: false,
            errorMessage: response.data.ok ? "" : 'reCAPTCHA failed. Please try again.',
            name: response.data.ok ? "" : this.state.name,
            email: response.data.ok ? "" : this.state.email,
            phone: response.data.ok ? "" : this.state.phone,
            subject: response.data.ok ? "" : this.state.subject,
            message: response.data.ok ? "" : this.state.message,
          });
          if (response.data.ok) this.recaptchaRef.current.reset();
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errorMessage: 'Server Error. Please try again.',
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message,
          });
        });
    }
  };
  render() {
    return (
      <div>
        <div className="jumbotron" id="jumbotronSPM">
          <div id="jumboDisplay">
            <h1 className=" mainHeader">We Rent.</h1>
            <h1 className=" mainHeader">&nbsp;We Manage.</h1>
            <h1 className=" mainHeader">&nbsp;&nbsp;&nbsp;We Maintain.</h1>
          </div>
          <div id="jumboPara">
            <h4 className=" font-30">Corner Brook's Premier Property Managers.</h4>
            <h4 className=" font-30">
              Full service commercial and residential property management.
            </h4>
          </div>
        </div>
        <div id="midDisplay">
          <div id="aboutUs">
            <h3 className="fs-16">About us</h3>
            <div className="g-text-lg mb-2">
              Everyone here at Steady Property Management has once upon a time
              rented a home and is now a rental property owner. We know what it
              is like to be on both sides of an investment property. Whether you
              need a rental home, want to purchase a rental property or own one
              and want help: we are here.
            </div>
            <div className="g-text-lg">
              When you trust your rental investment to Steady Property
              Management, you are getting just that: expert advice and
              service from experienced property managers.  We know the issues
              and what it takes to run a successful property.  We are a team of
              experienced rental owners who will treat your property like
              our very own.
            </div>
          </div>
          <div id="empty"></div>
          <div id="contact">
            <Form action="landing_message" method="post">
              <Form.Group>
                <h3>
                  Send us a message
                </h3>
                <Form.Control
                  className="form-text"
                  name="name"
                  type="text"
                  placeholder='Name*'
                  defaultValue={this.state.name}
                  onChange={this.handleChange}
                />
                <Form.Control
                  className="form-text"
                  name="email"
                  placeholder='Email*'
                  type="text"
                  defaultValue={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Control
                  className="form-text"
                  name="phone"
                  type="text"
                  placeholder='Phone Number'
                  defaultValue={this.state.phone}
                  onChange={this.handleChange}
                />
                <Form.Control
                  className="form-text"
                  name="subject"
                  type="text"
                  placeholder='Subject'
                  defaultValue={this.state.subject}
                  onChange={this.handleChange}
                />
                <Form.Control
                  className="form-text"
                  name="message"
                  as="textarea"
                  type="text"
                  placeholder='Message*'
                  defaultValue={this.state.message}
                  onChange={this.handleChange}
                />
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey="6LcsPtMUAAAAAGTfeTtWgysBjk_gY28oJq0uyoWw"
                />
              </Form.Group>
              {this.state.errorMessage.length > 0 ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}
              <Button style={{float: "right"}} type='primary' loading={this.state.loading} disabled={this.state.submitted} onClick={this.submitForm}>Submit</Button>
            </Form>
          </div>
        </div>
        <div id="contactUs">
          <div id="contactInfo">
            <div className="fs-14">
              For further information please call, text, or email us at:
            </div>
            <div className="address">
              <div className="g-text-lg ">Steady Property Management</div>
              <div className="g-text-lg">86 West Street, Suite 3</div>
              <div className="g-text-lg">Corner Brook, NL A2H 2Z3</div>
              <div className="address2">
                <br />
                <img
                  className="ml-2"
                  src={require("../icons/phoneicon.png")}
                  width="28"
                  height="28"
                />
                <div className=" inline g-text-lg">1-709-639-6431</div>
                <br />
                <MailOutlined className="ml-2" style={{
                  fontSize: "22px"
                }}/>
                <div className="inline g-text-lg ml-2 ">
                  info@steadypropertymanagement.com
                </div>
              </div>
            </div>
          </div>
          <div id="empty"></div>
          <div id="googleMaps">
            <div className="map">
              <iframe
                id="gmap"
                src="https://maps.google.com/maps?q=86%20west%20street%2C%20corner%20brook%2C%20NL&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
