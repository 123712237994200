import React from "react";
import Task from "../../../Components/Tasks/Task";
import Moment from "react-moment";
import RequestContext from "../RequestContext";
import ExtensionRequest from '../../../Modals/WorkOrder/ExtensionRequest'
import {message} from 'antd'
import axios from "axios";



export default class PendingExtensionAction extends React.Component {
  static contextType = RequestContext;
  constructor(props) {
    super(props);
    this.state = {
      page: "Loading",
      extensionRequestModal: false,
    };
  }


  openExtensionRequestModal = () => {
    this.setState({ extensionRequestModal: !this.state.extensionRequestModal });
  };

  // Requires the newly created event used to extend the work order.
  submitExtensionAccepted = () => {
    axios
      .post("workOrder/update/status/extend", {
        job_id: this.context.request.job_id,
        wo_id: this.context.currentWorkOrder.wo_id
      })
      .then((response) => {
        this.context.updateRequestWorkOrders(this.context.activeEvent.event_id);
        this.context.updateStatus("Work Order - Scheduled");
        this.context.addActivity(response.data.note, null, true);
        message.success("Work Order has been successfully extended under the new event!");
      })
  }

  submitExtensionDeclined = (inputtedReason) => {
    axios
      .post("workOrder/update/status/declineExtend", {
        job_id: this.context.request.job_id,
        status_reason: inputtedReason,
        wo_id: this.context.currentWorkOrder.wo_id
      })
      .then((response) => {
        this.context.updateRequestWorkOrders(this.context.activeEvent.event_id);
        this.context.addActivity(response.data.note, null, true);
        // On Hold status is temporary until confirmed for now.
        this.context.updateStatus("On Hold");
        message.success("Extension has been successfully declined.", 2.5);
      }) 
      .catch((err) => console.log(err));
  }

  render() {
    if (this.context.currentWorkOrder && this.context.currentWorkOrder.status === "Paused" && 
    (this.context.user.id === this.context.request.PM_id || this.context.user.company_id === this.context.request.PM_id)
    && this.context.user.type === "Manager"
    )
    {
      return (
        <div>
          <Task>
            <Task.Title>Worker has requested an extension</Task.Title>
            <Task.Action onClick={this.openExtensionRequestModal} primary>
              Review Extension Request
            </Task.Action>
          </Task>

          <ExtensionRequest startingPage={"ReviewExtensionRequest"} modalOpen={this.state.extensionRequestModal} onCancel={this.openExtensionRequestModal}
          currentWorkOrder={this.context.currentWorkOrder} submitExtensionAccepted={this.submitExtensionAccepted} submitExtensionDeclined={this.submitExtensionDeclined}
          />
        </div>
      )
    }

    else {
      return null;
    }

    }
}
