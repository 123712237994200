import { Col ,Row,Divider} from "antd";
import React, {useState} from "react";

export const  ColorRow = (props) => {
    return(
        <>
        <Divider style= {{'margin' : '7px'}} ></Divider>
        <Row>
            <Col flex="400px">{props.cols[0]}</Col>
            <Col flex='100px'>{props.cols[1]}</Col>
            <Col flex="auto">{props.cols[2]}</Col>
        </Row>
    </>
    )
  }