import React from 'react';
import {Redirect} from 'react-router-dom'
import {useUserDispatch } from "../../Context/UserContext";

const Logout = () => {
  let dispatch = useUserDispatch();

  dispatch({ state: undefined, type: "signout" });
  window.sessionStorage.clear()
  return(

    <Redirect to='/' />
  )
}

export default Logout