import React from 'react';
import {
	Table,
	Button,
	DatePicker,
	Dropdown,
	message,
	Checkbox,
	Menu,
	Modal
} from 'antd';
import {
	FormOutlined,
	LoadingOutlined,
	FieldTimeOutlined,
	DownloadOutlined,
	UploadOutlined,
	DownOutlined
} from '@ant-design/icons';
import './workLog.css';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Form, Col } from 'react-bootstrap';
import LoadScreen from '../../Components/LoadScreen';
import { SearchbarManual } from '../../Components/Searchbar/Searchbar';
import SummaryWorkLog from './SummaryWorkLog';
import NewLog from './NewLog';
import { UserStateContext } from '../../Context/UserContext';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class ViewWorkLog extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			workLogData: [],
			filterWorkLogData: [],
			selectedFilters: [],
			activeEmployees: [],
			workLog: null,
			rangePickerStart: moment().subtract(2, 'weeks'),
			rangePickerEnd: moment(),
			totalDistanceTravelled: 0,
			totalExpenses: 0,
			totalHours: 0,
			loadingData: true,
			currentTableData: [],
			searchValue: '',
			exportModal: false,
			loadingData: true,
			newLog: false,
			userWorkLogData: []
		};
	}

	componentDidMount = () => {
		axios.get('/workOrder/workdetails').then(response => {
			// console.log(response.data.logData);
			let tempData = response.data.logData;

			this.setState({
				userWorkLogData: tempData,
				userActiveWork: response.data.activeWork
			});
		});
		axios
			.get('/workOrder/workLogDetails')
			.then(response => {
				// console.log(response.data.logData);
				// console.log(response.data.activeEmployees);
				this.setState({
					workLogData: response.data.logData,
					activeEmployees: response.data.activeEmployees,
					activeWork: response.data.activeWork,
					selectedFilters: response.data.activeEmployees.map(employee => {
						return employee.id;
					})
				});
			})

			.catch(err => console.log(err));
	};
	test = () => {};
	openNewLog = () => {
		this.setState({ newLog: !this.state.newLog });
	};
	openExportModal = () => {
		this.setState({ exportModal: !this.state.exportModal });
	};
	// Filter with Range Picker
	setRangePicker = (start, end) => {
		// console.log(start, end);
		return this.setState({ rangePickerStart: start, rangePickerEnd: end });
	};

	/* Called when the data range picker is changed */
	setDateRange = date => {
		let start = date !== null ? date[0] : null;
		let end = date !== null ? date[1] : null;
		this.setRangePicker(start, end);
	};

	getTimeInterval = (startTime, endTime) => {
		var start = moment(startTime);
		var end = moment(endTime);
		var minutes = end.diff(start, 'minutes') % 60;
		var hours = end.diff(start, 'hours');
		if (hours === 0 && minutes === 0) minutes = 1;
		if (minutes < 10) {
			minutes = String('0' + minutes);
		}
		var interval = hours + ':' + minutes;
		return interval;
	};

	updateTotals = () => {
		let totalMinutes = 0,
			totalHours = 0,
			totalDistanceTravelled = 0,
			totalExpenses = 0;

		// console.log(this.state.currentTableData);
		this.state.currentTableData.forEach(row => {
			if (row.total_time) {
				totalMinutes += parseFloat(
					row.total_time.substring(row.total_time.indexOf(':') + 1)
				);
				totalHours += parseFloat(
					row.total_time.substring(0, row.total_time.indexOf(':'))
				);
			}

			if (row.total_distance !== null)
				totalDistanceTravelled += parseFloat(row.total_distance);
			if (row.total_expenses !== null)
				totalExpenses += parseFloat(row.total_expenses);
		});

		totalHours += Math.floor(totalMinutes / 60);
		totalMinutes = totalMinutes % 60;
		totalHours += totalMinutes / 60;

		this.setState({
			totalHours: totalHours.toFixed(2),
			totalDistanceTravelled: totalDistanceTravelled.toFixed(2),
			totalExpenses: totalExpenses.toFixed(2)
		});
	};
	startTime = () => {
		axios
			.post('/workOrder/startCounter')
			.then(response => {
				let data = this.state.userWorkLogData;

				let newData = [response.data.row].concat(data);
				this.setState({
					userWorkLogData: newData,
					userActiveWork: response.data.row
				});
			})
			.catch(err => {
				console.log(err);
			});
	};
	endTime = () => {
		axios
			.post('/workOrder/endCounter', {
				start_time: this.state.activeWork.start_time
			})
			.then(response => {
				let active = this.state.userWorkLogData.findIndex(
					row => row.status === 'Active'
				);
				if (active === -1) return;
				let temp = [...this.state.userWorkLogData];
				temp[active] = {
					...temp[active],
					end_time: response.data.end_time,
					total_time: response.data.total_time,
					status: 'Done'
				};
				return this.setState({ userWorkLogData: temp, userActiveWork: null });
			})
			.catch(err => console.log(err));
	};
	toggleEmployeeFilter = (filter, toggleOn) => {
		if (toggleOn) {
			if (
				filter === 'All' ||
				this.state.selectedFilters.length ===
					this.state.activeEmployees.length - 1
			) {
				this.setState(
					{
						workLog: this.state.workLogData,
						selectedFilters: this.state.activeEmployees.map(employee => {
							return employee.id;
						})
					},
					this.filterData
				);
				console.log(this.state.selectedFilters);
			} else {
				this.setState({ workLog: null });
				let selectedFilters = this.state.selectedFilters.concat(filter);
				console.log(this.state.selectedFilters);
				let filterWorkLog = this.state.workLogData.filter(data => {
					return selectedFilters.includes(data.user_id);
				});
				console.log(filterWorkLog);
				this.setState(
					{
						workLog: filterWorkLog,
						selectedFilters: selectedFilters
					},
					this.filterData
				);
			}
		} else {
			if (filter == 'All') this.setState({ workLog: [], selectedFilters: [] });
			else {
				this.setState({ workLog: null });
				let selectedFilters = this.state.selectedFilters.filter(mainFilter => {
					return mainFilter !== filter;
				});
				let filterWorkLog = this.state.workLogData.filter(data => {
					return selectedFilters.includes(data.user_id);
				});
				console.log(filterWorkLog);
				this.setState(
					{
						workLog: filterWorkLog,

						selectedFilters: selectedFilters
					},
					this.filterData
				);
			}
		}
	};

	render() {
		// console.log(this.context);
		return (
			<div id='worklogReportContainer'>
				<div className='reportHeader mb-4 mt-4 '>
					{this.state.userActiveWork ? (
						<Button
							disabled={this.state.newLog}
							icon={<FieldTimeOutlined />}
							onClick={this.endTime}
							danger
							type='primary'
						>
							Clock Out
						</Button>
					) : (
						<>
							<Button
								disabled={this.state.newLog}
								icon={<FieldTimeOutlined />}
								onClick={this.startTime}
								type='primary'
							>
								Clock In
							</Button>
						</>
					)}
					<div className='worklogReportExpImp'>
						<Button
							icon={<FormOutlined />}
							disabled={this.state.userActiveWork || this.state.newLog}
							onClick={this.openNewLog}
							type='primary'
						>
							New Log
						</Button>
					</div>
				</div>
				<Modal
					destroyOnClose={true}
					title='Add New Log'
					visible={this.state.newLog}
					onCancel={this.openNewLog}
					width='250px'
					footer={false}
				>
					{this.state.newLog ? <NewLog /> : ''}
				</Modal>
				<div className='mb-4 reportHeader'>
					<RangePicker
						width='400px'
						onChange={this.setDateRange}
						defaultValue={[
							this.state.rangePickerStart,
							this.state.rangePickerEnd
						]}
						className='worklogReportRangePicker'
						format='YYYY-MM-DD'
					/>

					{this.context.user.type === 'Manager' ? (
						<Form.Group controlId='exampleForm.ControlSelect1'>
							<Col sm={2} style={{ margin: 'auto' }}>
								<Dropdown
									trigger='click'
									placement='bottomCenter'
									overlay={
										<Menu style={{ padding: '5px' }}>
											<Menu.Item style={{ cursor: 'default' }} disabled key={0}>
												<Checkbox
													checked={
														this.state.activeEmployees.length ==
														this.state.selectedFilters.length
													}
													onChange={e =>
														this.toggleEmployeeFilter('All', e.target.checked)
													}
												>
													All
												</Checkbox>
											</Menu.Item>
											{this.state.activeEmployees.map((employee, index) => (
												<Menu.Item
													style={{ cursor: 'default' }}
													disabled
													key={index + 1}
												>
													<Checkbox
														checked={this.state.selectedFilters.includes(
															employee.id
														)}
														onChange={e =>
															this.toggleEmployeeFilter(
																employee.id,
																e.target.checked
															)
														}
													>
														{employee.fname} {employee.lname}
													</Checkbox>
												</Menu.Item>
											))}
										</Menu>
									}
								>
									<Button>
										Select Employee
										<DownOutlined />
									</Button>
								</Dropdown>
							</Col>
						</Form.Group>
					) : null}
					{/* <Button style={{ marginLeft: '100px' }}>Details</Button>
					<Button>Summary</Button> */}
					<div className='worklogReportExpImp'>
						<Button onClick={this.openExportModal}>
							<DownloadOutlined />
							Export
						</Button>
					</div>
				</div>

				<div id='worklogReportContainer'>
					{this.state.selectedFilters &&
					this.state.rangePickerStart &&
					this.state.rangePickerEnd ? (
						<SummaryWorkLog
							user={this.context.user}
							selectedFilters={this.state.selectedFilters}
							rangePickerStart={this.state.rangePickerStart}
							rangePickerEnd={this.state.rangePickerEnd}
						/>
					) : null}
				</div>

				<Modal
					show={this.state.exportModal}
					onHide={this.openExportModal}
					size='sm'
					aria-labelledby='assign-vendor-modal'
					centered
				>
					{this.state.exportModal ? (
						<ExportModal
							filteredData={this.state.currentTableData}
							onHide={this.openExportModal}
						/>
					) : (
						''
					)}
				</Modal>
			</div>
		);
	}
}
const logColumns = [
	{
		title: 'Employee Name',
		dataIndex: 'employeeName',
		key: 'employee_name'
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date'
	},
	{
		title: 'Start Time',
		dataIndex: 'start_time',
		key: 'start_time'
	},
	{
		title: 'End Time',
		dataIndex: 'end_time',
		key: 'end_time'
	},
	{
		title: 'Total Time',
		dataIndex: 'total_time',
		key: 'total_time'
	},

	{
		title: 'Total Distance Travelled ',
		dataIndex: 'total_distance',
		key: 'total_distance'
	},
	{
		title: 'Total Expenses',
		dataIndex: 'total_expenses',
		key: 'total_texpenses'
	}
];

/* Modal for work log file export */
class ExportModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fileName: '',
			isValid: true,
			defaultName: `worklog_report_${moment().format('MM-DD-YYYY')}`
		};
	}

	setFileName = name => {
		if (name === '') return this.setState({ fileName: name, isValid: true });
		else if (this.isValid(name))
			return this.setState({ fileName: name, isValid: true });
		else return this.setState({ fileName: name, isValid: false });
	};

	isValid = fname => {
		var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
		var rg2 = /^\./; // cannot start with dot (.)
		var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
		return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
	};

	render() {
		return (
			<>
				<Modal.Header closeButton>Export Data</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Label className='fs-12'>File Name</Form.Label>
						<Form.Control
							type='text'
							value={this.state.fileName}
							placeholder={this.state.defaultName}
							style={{ width: '80%', display: 'inline-block' }}
							onChange={e => this.setFileName(e.target.value)}
						/>
						<div className='d-inline g-text-sm' style={{ marginLeft: '3px' }}>
							.csv
						</div>
					</Form>
					<br />
					<div className='text-center'>
						<Button type='primary' size='large' disabled={!this.state.isValid}>
							<CSVLink
								onClick={e => {
									return this.state.isValid;
								}}
								data={this.props.filteredData}
								headers={WorkLogExportHeaders}
								className={this.state.isValid ? '' : 'cursor-not-allowed'}
								filename={`${
									this.state.fileName !== ''
										? this.state.fileName
										: this.state.defaultName
								}.csv`}
								target='_blank'
							>
								<div className='fs-12 w-text-imp'>
									<DownloadOutlined /> Download Data
								</div>
							</CSVLink>
						</Button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onHide}>Close</Button>
				</Modal.Footer>
			</>
		);
	}
}
const compareArrays = (arr1, arr2) => {
	if (arr1.length != arr2.length) return false;

	for (var i = 0, l = arr1.length; i < l; i++) {
		// Check if we have nested arrays
		if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
			// recurse into the nested arrays
			if (!arr1[i].equals(arr2[i])) return false;
		} else if (arr1[i] != arr2[i]) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false;
		}
	}
	return true;
};
/* used in worklog page to xport wor log report data */
const WorkLogExportHeaders = [
	{
		label: 'Employee Name',
		key: 'emp_name'
	},
	{
		label: 'Date',
		key: 'date'
	},
	{
		label: 'Start Time',
		key: 'start_time'
	},
	{
		label: 'End Time',
		key: 'end_time'
	},
	{
		label: 'Total Time',
		key: 'total_time'
	},
	{
		label: 'Total Distance Travelled',
		key: 'total_distance'
	},
	{
		label: 'Total Expenses',
		key: 'total_expenses'
	}
];

export default ViewWorkLog;
