import React, { useState, useEffect } from 'react';

import moment from 'moment';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#E4E4E4',
		fontFamily: 'Times-Roman'
	},
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey'
	},
	text: {
		// margin: 12,
		fontSize: 12,
		textAlign: 'justify',
		fontFamily: 'Times-Roman'
		// marginbottom: 2
	},
	title: {
		// margin: 12,
		fontSize: 14,
		textAlign: 'center',
		fontFamily: 'Times-Roman',

		marginbottom: 0.05
	},
	heading: {
		margin: 12,
		fontSize: 12,
		textAlign: 'justify',
		fontFamily: 'Times-Roman',
		marginbottom: 2
	},
	container: {
		flexDirection: 'row',
		// borderBottomColor: '#3778C2',
		backgroundColor: 'grey',
		color: '#fff',
		// borderBottomWidth: 1,
		alignItems: 'center',
		// height: 12,
		textAlign: 'center',
		fontStyle: 'normal',

		flexGrow: 0.05,
		marginBottom: '2px'
	},
	description: {
		width: '80%',
		textAlign: 'left',
		fontFamily: 'Times-Roman',
		fontSize: 12,
		paddingLeft: 8
	},
	amount: {
		width: '30%',
		textAlign: 'right',
		fontSize: 12,
		fontFamily: 'Times-Roman',
		paddingLeft: 8
	},
	row: {
		flexDirection: 'row',

		alignItems: 'normal',
		// height: 24,
		fontFamily: 'Times-Roman',
		fontStyle: 'bold'
	},
	totalContainer: {
		flexDirection: 'row',

		alignItems: 'normal',
		// height: 24,
		fontFamily: 'Times-Roman',
		fontStyle: 'bold'
	},
	subTotal: {
		width: '100%',
		textAlign: 'right',
		fontStyle: 'bold',
		fontFamily: 'Times-Roman',

		fontSize: 12
	}
});

const InvoiceFormat = props => {
	const [companyDetails, setCompanyDetails] = useState('');
	useEffect(() => {
		axios.get('/mycompany').then(response => {
			setCompanyDetails(response.data.company);
		});
	}, []);

	return (
		<Document>
			<Page size='A4' style={styles.body} wrap>
				<div>
					<Text style={styles.text}>{'Steady Property Management'}</Text>
					<Text style={styles.text}>{'86 West Street, Suite 3'}</Text>
					<Text style={styles.text}>{'Corner Brook, NL'}</Text>
					<Text style={styles.text}>{'A2H 2Z3'}, Canada</Text>
					<Text style={[styles.text, { textAlign: 'right' }]}>
						HST Registration #: {companyDetails.hst_number}
					</Text>
				</div>
				<Text style={{ fontStyle: 'bold', fontSize: 12 }}>Bill To:</Text>

				<Text style={styles.text}>{props.invoiceData[0].charge_to}</Text>
				<Text style={styles.text}>{props.invoiceData[0].property_name}</Text>
				<Text style={styles.text}>
					{props.invoiceData[0].property_city},{' '}
					{props.invoiceData[0].property_province}
				</Text>
				<Text style={styles.text}>
					{props.invoiceData[0].property_postalcode}, Canada
				</Text>

				<Text style={[styles.text, { textAlign: 'right' }]}>
					Invoice Date:{' '}
					{moment(props.invoiceData[0].date).format('MMM Do YYYY')}
				</Text>
				<Text
					style={[styles.text, { textAlign: 'right', marginBottom: '5px' }]}
				>
					Invoice #: {props.invoiceData[0].invoice_number}
				</Text>
				<View style={styles.container}>
					<Text style={styles.description}>Description</Text>
					<Text style={styles.amount}>Amount</Text>
				</View>
				<View style={{ height: '300px' }}>
					{props.invoiceData[0].chargeData.map((item, i) => (
						<View key={'item' + i} style={styles.row}>
							<Text style={styles.description}>{item.charge_description}</Text>
							<Text style={styles.amount}>${item.gross_amount}</Text>
						</View>
					))}
				</View>

				<View style={styles.totalContainer}>
					<Text style={styles.subTotal}>
						Sub Total: $
						{parseFloat(props.invoiceData[0].invoice_amount).toFixed(2)}
					</Text>
				</View>
				<View style={styles.row}>
					<Text style={styles.subTotal}>
						Sales Tax(15%): $
						{parseFloat(props.invoiceData[0].gst_amount).toFixed(2)}
					</Text>
				</View>
				<View style={styles.row}>
					<Text style={styles.subTotal}>
						TOTAL: ${parseFloat(props.invoiceData[0].total_amount).toFixed(2)}
					</Text>
				</View>
			</Page>
		</Document>
	);
};

export default InvoiceFormat;
