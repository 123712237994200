import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Button, message } from "antd";
import "../login.css";
import axios from "axios";

import { useUserDispatch } from "../../../Context/UserContext";
import ErrorMessage from "../../../Components/ErrorMessage/ErrorMessage";
import LoginLayout from "../LoginLayout";
import Cookies from "js-cookie";
const cancelToken = axios.CancelToken.source();

function SignupPage() {
  document.title = "Sign Up";
  let userDispatch = useUserDispatch();
  const [screen, setScreen] = useState("Form");
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState("");

  const sendRegister = () => {
    setScreen("Loading");
    axios
      .post("/auth/register", {
        crossdomain: true,
        fname: fname,
        lname: lname,
        email: email,
        password: password,
        host: window.location.host
      })
      .then(function (response) {

        if (response.data.ok) {
          //email & password correct
          message.success('Your account is created. Please verify your email.')
          userDispatch({
            state: { user: response.data.user },
            type: "initalize",
          });

          Cookies.set("session", {
            session_id: response.data.session_id,
            type: response.data.user.type,
          });
          if (response.data.setPassword)
            //Account not activated
            setScreen("setPassword");
          else setScreen("overview");
        } else {
          setError(
            "The email or password entered is incorrect. Please try again."
          );
          setScreen("Form");
        }
      })
      .catch((error) => {

        setError(
          "Error connecting to the server. Please refresh or try again later."
        );
        setScreen("Form");
      });
  };

  const setsignin = () => {
    setScreen("signIn");
  };

  const verifyemail = (val) => {
    axios
      .post("/auth/validateEmail", {
        crossdomain: true,
        email: val,
      })
      .then(function (response) {
        if (!response.data.ok) setError("Please enter a valid email.");
        else setError(null);
      });
  };

  if (screen === "Form") {
    return (
      <LoginLayout header="Sign Up">
        <Form className="flex-60">
          <Form.Group style={{ display: "inline!important" }}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={fname}
              placeholder="First Name"
              onChange={(e) => setFname(e.target.value)}
            />
            <Form.Control
              size="sm"
              type="text"
              value={lname}
              placeholder="Last Name"
              onChange={(e) => setLname(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                verifyemail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              value={password}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="text-right">
            <a id="recoverpassword" className="blueLink" href="ForgotPassword">
              Recover Password
            </a>
          </Form.Group>
          <Button type="primary" className={"signInBtn"} onClick={sendRegister}>
            Sign Up
          </Button>
          <ErrorMessage otherClass="mt-2">{errorMessage}</ErrorMessage>
        </Form>
        <div className="flex-30 mt-4 signuphere">
          Already have an account?{" "}
          <div className="blueLink" onClick={setsignin}>
            Sign in here
          </div>
        </div>
      </LoginLayout>
    );
  }
  switch (screen) {
    case "signIn": {
      return <Redirect to="/login" />;
    }
    case "verify": {
      return <Verification />;
    }
    default: {
      return <Redirect to="/login" />;
    }
  }
}

function Verification() {
  return <div></div>;
}

export default SignupPage;
