import React, { useState } from 'react';
import { Carousel, Card, Upload, Tooltip } from 'antd';
import { Modal, Button } from 'react-bootstrap';
import {
	SettingOutlined,
	PlusCircleOutlined,
	LeftCircleOutlined,
	PlusOutlined,
	RightCircleOutlined
} from '@ant-design/icons';
import { ProfileAvatar } from '../../Components/Avatar';
import Moment from 'react-moment';
import useWindowDimensions from '../../Context/WindowsDimensions';
import ImageUploadModal from '../../Modals/ImageUploadModal/ImageUploadModal';
import { MaintenanceRequestListing } from '../JobRequests/RequestListing';
import { UnitMaintenanceRequestListing } from '../JobRequests/RequestListing';
import { MaintenanceThread } from '../../Components/Communication/Communication';
import RequestSettings from './actions/RequestSettings';
import { MessageOutlined } from '@ant-design/icons';
import RequestContext from './RequestContext';
import ReservationDetails from './actions/ReservationDetails';
/* Top Card that has job request details */
class RequestDescription extends React.Component {
	static contextType = RequestContext;

	constructor(props) {
		super();
		this.state = {
			settingsModal: false,
			imageModal: false
		};
	}

	setImageModal = () => this.setState({ imageModal: !this.state.imageModal });
	setSettings = () =>
		this.setState({ settingsModal: !this.state.settingsModal });
	render() {
		const { request } = this.context;
		// console.log(request.requester_imageurl);

		return (
			<div className='requestDescription'>
				<Card id='rqDescription' bordered={false}>
					<MaintenanceRequestListing
						updateStatus={this.context.updateStatus}
						request={request}
						clickable={false}
						titleColors={true}
					/>

					<div className='d-inline-block'>
						<ProfileAvatar
							name={request.requester_fname + ' ' + request.requester_lname}
							image={request.requester_imageurl}
							role={request.requester_acctype}
							size={38}
							nameClass={'pl16'}
						/>
						<div className='g-text-sm mt-2 ' id='requestDate'>
							<Moment format='[Request submitted on: ]DD/MM/YY [at] h:mma'>
								{request.jobrequest_time}
							</Moment>
						</div>
						<div className='mt-2'>{request.job_description}</div>
					</div>
					<SettingOutlined
						className='g-text'
						style={{
							cursor: 'pointer',
							fontSize: '20px',
							verticalAlign: 'middle',
							float: 'right'
						}}
						onClick={this.setSettings}
					/>
					<div>
						{this.context.request.job_subcategory === 'Janitorial/Cleaning' ? (
							<ReservationDetails />
						) : null}
					</div>

					<div id='requestImages' className='mt-2'>
						<div className='JRSubtitle'>Documents and Images</div>
						<PlusCircleOutlined
							className='greyActionIcon mb-2'
							onClick={this.setImageModal}
						/>
						{request.images && request.images.length > 0 ? (
							<ImageDisplay images={request.images} />
						) : (
							<div className='g-text-sm'>No documents or images uploaded</div>
						)}
					</div>
				</Card>

				<Modal
					show={this.state.settingsModal}
					onHide={this.setSettings}
					size='sm'
					aria-labelledby='change-vendor-modal'
					centered
				>
					{this.state.settingsModal ? (
						<RequestSettings
							onHide={this.setSettings}
							requestStatus={request.job_status}
						/>
					) : (
						''
					)}
				</Modal>

				<ImageUploadModal
					visible={this.state.imageModal}
					body={{ job_id: request.job_id }}
					route='/jobRequest/add/images'
					onClose={this.setImageModal}
					updateImages={this.context.updateImages}
				/>
			</div>
		);
	}
}

/*
class ChatBubble extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      unreadCount:0,
      chatModal: false,
    };
  }
  componentDidMount= () => {
    axios.get('/communication/requestUnreadMessages/'+this.props.request.job_id).then( (response) => {
      this.setState({unreadCount:response.data.unreadCount});
    }).catch( () => { return })
  }

  openChatModal = () => this.setState({ chatModal: !this.state.chatModal });
  render() {
    return (
      <>
      {this.state.unreadCount > 0 ?
        (<Badge count={this.state.unreadCount} offset={[-25,5]} onClick={this.openChatModal}  style={{cursor:'pointer'}}>
        <MessageOutlined className='mr-4' style={{cursor:'pointer',fontSize:'24px',verticalAlign:'middle'}} onClick={this.openChatModal} size={48} />
        </Badge>) :         

        <MessageOutlined className='mr-4' style={{cursor:'pointer',fontSize:'24px',verticalAlign:'middle'}} onClick={this.openChatModal} size={48} />
      }

        <Modal
          show={this.state.chatModal}
          onHide={this.openChatModal}
          size="lg"
          aria-labelledby="assign-vendor-modal"
          centered
        >
          {this.state.chatModal ? (
            <MaintenanceThread fixedHeight request={this.props.request} />
          ) : (
            ""
          )}
        </Modal>
      </>
    );
  }
}*/

const ImageDisplay = ({ images }) => {
	const { width } = useWindowDimensions();
	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState(false);

	const download = url => {
		//axios.get(url).then()
	};
	if (!Array.isArray(images)) return <></>;
	var slidesPer;
	if (width > 1300) slidesPer = 4;
	else if (width < 1300 && width > 1130) slidesPer = 3;
	else if (width < 1130 && width > 760) slidesPer = 2;
	else slidesPer = 1;
	const imgdownload = (url, name) => {
		if (!url) {
			throw new Error('Resource URL not provided! You need to provide one');
		}
		setFetching(true);
		fetch(url)
			.then(response => response.blob())
			.then(blob => {
				setFetching(false);
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobURL;
				a.style = 'display: none';

				if (name && name.length) a.download = name;
				document.body.appendChild(a);
				a.click();
			})
			.catch(() => setError(true));
	};

	return images.length > 0 ? (
		<Carousel
			arrows
			slidesPerRow={slidesPer}
			className='maintenanceCarousel'
			prevArrow={
				<img src={require('../../icons/chevronLeft.png')} alt='left' />
			}
			nextArrow={
				<img src={require('../../icons/chevronRight.png')} alt='right' />
			}
		>
			{images.map((i, index) => (
				<div className='imageTab' key={i.id}>
					<a href={i.image} target='_blank' type='application/pdf' download>
						<Tooltip title={i.event_id}>
							{i.type == 'image' && (
								<img
									className='d-block image carouselImages'
									src={i.image}
									alt='First slide'
								/>
							)}
							{i.type == 'pdf' && (
								<img
									className='d-block image carouselImages'
									src={require('../../images/pdfIcon.PNG')}
									alt='First slide'
									style={{ width: 50, height: 50 }}
								/>
							)}
						</Tooltip>
					</a>
				</div>
			))}
		</Carousel>
	) : (
		''
	);
};

const UploadImages = () => {
	return;
	/*
          {this.state.imageModal ? (
          <ImageUploadModal
            visible={this.state.imageModal}
            body={{ job_id: this.props.job_id }}
            route="/jobRequest/add/images"
            onClose={this.setImageModal}
            updateImages={this.props.updateImages}
          />
        ) : (
          ""
        )}
        */
};
export default RequestDescription;
