import React from 'react';
import axios from 'axios';

//import { PropertyListing } from "../../Components/PropertyListing";
import './properties.css';
import PropertyListing from './PropertyListing';
import { Searchbar } from '../../Components/Searchbar/Searchbar';
import LoadScreen from '../../Components/LoadScreen';
import CreateIntake from '../../Modals/JobRequest/CreateIntake';
import { Button, message, Menu, Dropdown, Checkbox, Modal } from 'antd';
import { Form, Col } from 'react-bootstrap';
import {
	NewTaskButton,
	updateTask,
	predefinedTasks,
	splitTasks,
	specialTasks,
	limitedTasks
} from '../Requests/RequestListLayout';
import { DownOutlined } from '@ant-design/icons';
import AddNewProperty from './AddNewProperty';
import { useUserState } from '../../Context/UserContext';
import { UserStateContext } from '../../Context/UserContext';
const cancelToken = axios.CancelToken.source();
/*TODO

sort out URL to display property name instead of id
*/

class Properties extends React.Component {
	static contextType = UserStateContext;
	constructor(props) {
		super(props);
		this.state = {
			properties: null,
			searchValue: '',
			userType: '',
			// addIntakeOpen: false,
			filters: {
				active: {
					0: false,
					1: true
				}
			},
			width: 0,
			newPropertyForm: false,
			addLease: false
		};
	}
	/* Used to determine if the view is mobile or desktop */
	updateWidth = () => {
		this.setState({ width: window.innerWidth });
	};
	componentDidMount() {
		document.title = 'My Properties';
		this.updateWidth();
		window.addEventListener('resize', this.updateWidth);
		this.getData().then(data => {
			console.log(data.data.properties);
			this.setState({
				properties: data.data.properties,
				userType: data.data.userType
			});
		});
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateWidth);
	};
	openAddLease = () => {
		this.setState({ addLease: !this.state.addLease });
	};

	getData() {
		return axios
			.get('/getProperties', { crossdomain: true })
			.then(function (response) {
				return response;
			});
	}
	gotoProperty = link => {
		this.setState({ page: 'redirect' });
	};

	setPropertyActive = (propId, toActive) => {
		let newProperties = [...this.state.properties];
		let propIndex = newProperties.findIndex(
			property => property.propertyid == propId
		);
		newProperties[propIndex].loading = true;
		this.setState({ properties: newProperties });
		axios
			.post('/property/update/active', { active: toActive, propertyid: propId })
			.then(response => {
				newProperties = [...this.state.properties];
				if (response.data.ok) newProperties[propIndex].active = toActive;
				else newProperties[propIndex].error = true;
				delete newProperties[propIndex].loading;
				this.setState({ properties: newProperties });
			});
	};

	// switchIntakeOpen = () => {
	// 	this.setState({ addIntakeOpen: !this.state.addIntakeOpen });
	// };
	openNewPropertyForm = () => {
		this.setState({ newPropertyForm: !this.state.newPropertyForm });
	};

	addNewTask = addId => {
		this.setState({ addTaskOpen: !this.state.addTaskOpen });
		let newTaskId = addId in splitTasks ? addId + this.state.userType : addId;
		let splitlessId = addId;
		axios({
			method: 'get',
			url: '/getTasklist'
		}).then(responseA => {
			let newLimitedTasks = JSON.parse(JSON.stringify(this.state.limitedTasks));
			let countAllTasks = function (userTasks, topLevel = false) {
				let currentCount = 0;
				if (topLevel) {
					if ('nonServiceRequest' in userTasks) {
						let nsRequests = Object.keys(userTasks.nonServiceRequest);
						for (
							let currentRequest = 0;
							currentRequest < nsRequests.length;
							currentRequest++
						) {
							let reqObj =
								userTasks.nonServiceRequest[nsRequests[currentRequest]];
							if (
								'subtasks' in reqObj &&
								Object.keys(reqObj.subtasks).length > 0
							) {
								currentCount += countAllTasks(reqObj.subtasks) + 1;
							} else {
								currentCount++;
							}
							if (reqObj.taskId.split('_')[0] in newLimitedTasks)
								newLimitedTasks[reqObj.taskId.split('_')[0]] = true;
						}
					}
					if ('serviceRequest' in userTasks) {
						let sRequests = Object.keys(userTasks.serviceRequest);
						for (
							let currentRequest = 0;
							currentRequest < sRequests.length;
							currentRequest++
						) {
							let reqObj = userTasks.serviceRequest[sRequests[currentRequest]];
							if (
								'subtasks' in reqObj &&
								Object.keys(reqObj.subtasks).length > 0
							) {
								currentCount += countAllTasks(reqObj.subtasks) + 1;
							} else {
								currentCount++;
							}
							if (reqObj.taskId.split('_')[0] in newLimitedTasks)
								newLimitedTasks[reqObj.taskId.split('_')[0]] = true;
						}
					}
				} else {
					let subtaskList = Object.keys(userTasks);
					for (
						let currentRequest = 0;
						currentRequest < subtaskList.length;
						currentRequest++
					) {
						let reqObj = userTasks[subtaskList[currentRequest]];
						if ('subtasks' in reqObj && Object.keys(reqObj.subtasks).length > 0)
							currentCount += countAllTasks(reqObj.subtasks) + 1;
						else currentCount++;
						if (reqObj.taskId.split('_')[0] in newLimitedTasks)
							newLimitedTasks[reqObj.taskId.split('_')[0]] = true;
					}
				}
				return currentCount;
			};
			let totalCount =
				'Item' in responseA.data.taskData
					? countAllTasks(responseA.data.taskData.Item, true)
					: 0;
			let updateTaskData = (updTaskData, isNewTopTask = false) => {
				let taskData = JSON.parse(JSON.stringify(updTaskData));
				let userTasks = responseA.data.taskData.Item;
				let taskListData = [];
				if ('nonServiceRequest' in userTasks) {
					Object.keys(userTasks.nonServiceRequest)
						.map(function (nsTask, nsIndex) {
							return [nsTask.split('_')[0], nsIndex];
						})
						.sort(function (a, b) {
							try {
								return parseInt(a[0]) - parseInt(b[0]);
							} catch (err) {
								return a[0].toString().localeCompare(b[0].toString());
							}
						})
						.forEach(function (nsTask) {
							let newTask =
								userTasks.nonServiceRequest[
									Object.keys(userTasks.nonServiceRequest)[nsTask[1]]
								];
							newTask.taskObjectName = Object.keys(userTasks.nonServiceRequest)[
								nsTask[1]
							];
							newTask.servOrNo = 'nonServiceRequest';
							taskListData.push(newTask);
						});
				}
				if ('serviceRequest' in userTasks) {
					Object.keys(userTasks.serviceRequest)
						.map(function (sTask, sIndex) {
							return [sTask.split('_')[0], sIndex];
						})
						.sort(function (a, b) {
							try {
								return parseInt(a[0]) - parseInt(b[0]);
							} catch (err) {
								return a[0].toString().localeCompare(b[0].toString());
							}
						})
						.forEach(function (sTask) {
							let newTask =
								userTasks.serviceRequest[
									Object.keys(userTasks.serviceRequest)[sTask[1]]
								];
							newTask.taskObjectName = Object.keys(userTasks.serviceRequest)[
								sTask[1]
							];
							newTask.servOrNo = 'serviceRequest';
							taskListData.push(newTask);
						});
				}

				if ('topLayerId' in taskData) delete taskData.topLayerId;

				let doUpdate = updateTask(taskData, taskListData);
				if ((!isNewTopTask && !doUpdate[3]) || isNewTopTask)
					taskListData = doUpdate[0];

				let nonServiceRequests = {};
				taskListData.forEach(function (currentTask, taskIndex) {
					if (currentTask.servOrNo === 'nonServiceRequest') {
						nonServiceRequests[currentTask.taskObjectName] = currentTask;
						delete nonServiceRequests[currentTask.taskObjectName].servOrNo;
						if ('topLayerId' in nonServiceRequests[currentTask.taskObjectName])
							delete nonServiceRequests[currentTask.taskObjectName].topLayerId;
						delete nonServiceRequests[currentTask.taskObjectName]
							.taskObjectName;
					}
				});
				let serviceRequests = {};
				taskListData.forEach(function (currentTask, taskIndex) {
					if (currentTask.servOrNo === 'serviceRequest') {
						serviceRequests[currentTask.taskObjectName] = currentTask;
						delete serviceRequests[currentTask.taskObjectName].servOrNo;
						if ('topLayerId' in serviceRequests[currentTask.taskObjectName])
							delete serviceRequests[currentTask.taskObjectName].topLayerId;
						delete serviceRequests[currentTask.taskObjectName].taskObjectName;
					}
				});

				var url = '/updateTasklist';
				axios
					.post(url, { nonServiceRequests, serviceRequests })
					.then(responseB => {});
			};
			let generateTask = (
				taskData,
				taskKey,
				firstLayer = true,
				taskObjects = {}
			) => {
				let totalCount = 0;
				let firstTaskId;
				totalCount++;
				if (firstLayer) {
					firstTaskId = [];
					let singleTaskData =
						typeof taskData === 'object'
							? JSON.parse(JSON.stringify(taskData))
							: JSON.parse(JSON.stringify(predefinedTasks[taskData]));
					singleTaskData.taskObjectName =
						(taskKey + totalCount).toString() + '_' + singleTaskData.taskId;
					singleTaskData.taskId += '_' + (taskKey + totalCount).toString();
					if (
						'subtasks' in singleTaskData &&
						Object.keys(singleTaskData.subtasks).length > 0
					) {
						let subList = generateTask(
							null,
							taskKey + totalCount,
							false,
							singleTaskData.subtasks
						);
						singleTaskData.subtasks = subList[0];
						totalCount += subList[1];
						firstTaskId.push(subList[2]);
						if (!('numSteps' in singleTaskData))
							singleTaskData.numSteps = Object.keys(
								singleTaskData.subtasks
							).length;
					} else {
						firstTaskId.push(singleTaskData.taskId);
						if (!('numSteps' in singleTaskData)) {
							singleTaskData.numSteps = 1;
						}
					}
					if (!('numStepsCompleted' in singleTaskData))
						singleTaskData.numStepsCompleted = 0;
					return [singleTaskData, totalCount - 1, firstTaskId[0]];
				} else {
					firstTaskId = '';
					let taskObjectList = Object.keys(taskObjects).sort();
					for (
						let taskListKey = 0;
						taskListKey < taskObjectList.length;
						taskListKey++
					) {
						let singleTaskData = JSON.parse(
							JSON.stringify(taskObjects[taskObjectList[taskListKey]])
						);
						singleTaskData.taskId += '_' + (taskKey + totalCount).toString();
						if (
							'subtasks' in singleTaskData &&
							Object.keys(singleTaskData.subtasks).length > 0
						) {
							let subList = generateTask(
								null,
								taskKey + totalCount,
								false,
								singleTaskData.subtasks
							);
							singleTaskData.subtasks = subList[0];
							if (firstTaskId.length === 0) firstTaskId = subList[2];
							totalCount += subList[1];
							if (!('numSteps' in singleTaskData))
								singleTaskData.numSteps = Object.keys(
									singleTaskData.subtasks
								).length;
						} else {
							if (firstTaskId.length === 0) firstTaskId = singleTaskData.taskId;
							totalCount++;
							if (!('numSteps' in singleTaskData)) singleTaskData.numSteps = 1;
						}
						if (!('numStepsCompleted' in singleTaskData))
							singleTaskData.numStepsCompleted = 0;
						taskObjects[taskObjectList[taskListKey]] = singleTaskData;
					}
					return [taskObjects, totalCount, firstTaskId];
				}
			};
			if (newTaskId in predefinedTasks) {
				if (predefinedTasks[newTaskId].taskId in newLimitedTasks)
					newLimitedTasks[predefinedTasks[newTaskId].taskId] = true;

				if (splitlessId in specialTasks) {
					var url = '/handleSpecialTask/Add';
					axios
						.post(url, {
							newTaskName: splitlessId,
							newTaskData: predefinedTasks[newTaskId]
						})
						.then(responseC => {
							if (responseC.data.ok) {
								let newTask = generateTask(
									responseC.data.newTaskData[splitlessId],
									totalCount
								);
								updateTaskData(newTask[0], true);
								message.success(
									<>
										<p>Task Added Successfully.</p>
										<p
											style={{ cursor: 'pointer', color: '#1890ff' }}
											onClick={event => {
												this.props.history.push('/tasks/' + newTask[2]);
											}}
										>
											{' '}
											Click here to open it.
										</p>
									</>,
									1
								);
							}
							this.setState({ limitedTasks: newLimitedTasks });
						});
				} else {
					let newTask = generateTask(newTaskId, totalCount);
					updateTaskData(newTask[0], true);
					message.success(
						<>
							<p>Task Added Successfully.</p>
							<p
								style={{ cursor: 'pointer', color: '#1890ff' }}
								onClick={event => {
									this.props.history.push('/tasks/' + newTask[2]);
								}}
							>
								{' '}
								Click here to open it.
							</p>
						</>,
						1
					);
					this.setState({ limitedTasks: newLimitedTasks });
				}
			} else {
				message.error('This task is currently unavailable');
				this.setState({ limitedTasks: newLimitedTasks });
			}
		});
	};

	render() {
		console.log(this.context);
		return (
			<div id='propertyPage'>
				<div style={{ position: 'sticky' }}>
					<Form.Group
						controlId='exampleForm.ControlSelect1'
						style={{ float: 'left', marginTop: '15px' }}
					>
						<Col sm={2} style={{ margin: 'auto' }}>
							<Dropdown
								trigger='click'
								placement='bottomCenter'
								overlay={
									<Menu style={{ padding: '5px' }}>
										<Menu.Item style={{ cursor: 'default' }} disabled key={1}>
											<Checkbox
												checked={this.state.filters.active[1]}
												onChange={e => {
													let newFilters = { ...this.state.filters };
													newFilters.active[1] = e.target.checked;
													this.setState({ filters: newFilters });
												}}
											>
												Active
											</Checkbox>
										</Menu.Item>
										<Menu.Item style={{ cursor: 'default' }} disabled key={2}>
											<Checkbox
												checked={this.state.filters.active[0]}
												onChange={e => {
													let newFilters = { ...this.state.filters };
													newFilters.active[0] = e.target.checked;
													this.setState({ filters: newFilters });
												}}
											>
												Inactive
											</Checkbox>
										</Menu.Item>
									</Menu>
								}
							>
								<Button>
									Status
									<DownOutlined />
								</Button>
							</Dropdown>
						</Col>
					</Form.Group>
					<div id='searchBarContainer' className='pt-3'>
						<Searchbar
							onChange={e => this.setState({ searchValue: e.target.value })}
							value={this.state.searchValue}
							className='propSearchbar mb-3'
						/>
					</div>
					<div>
						{this.context.user.type === 'Manager' ? (
							<Button
								type='primary'
								onClick={this.openNewPropertyForm}
								style={{
									float: 'right',

									marginRight: '50px'
								}}
							>
								Add New Property
							</Button>
						) : (
							''
						)}

						{/* <Button
							type='primary'
							style={{
								float: 'right',

								marginRight: '10px'
							}}
							onClick={this.openAddLease}
						>
							Add New Lease
						</Button> */}
					</div>

					<Modal
						destroyOnClose={true}
						title='Add New Property'
						visible={this.state.newPropertyForm}
						onCancel={this.openNewPropertyForm}
						width='800px'
						footer={false}
					>
						{this.state.newPropertyForm ? <AddNewProperty /> : ''}
					</Modal>
					{/* <Modal
						destroyOnClose={true}
						title='Add New Lease'
						visible={this.state.addLease}
						onCancel={this.openAddLease}
						width='500px'
						footer={false}
					>
						{this.state.addLease ? <AddLease /> : ''}
					</Modal> */}
					{/* <Modal
						size='lg'
						aria-labelledby='contained-modal-title-vcenter'
						centered
						show={this.state.addIntakeOpen}
						onHide={this.switchIntakeOpen}
						id='jobRequestModal'
					>
						<CreateIntake
							type='intake'
							backToSelect={this.switchIntakeOpen}
							openRequest={this.switchIntakeOpen}
						/>
					</Modal> */}
				</div>

				<br />
				<br />
				<div id='listContainer'>
					{this.state.properties ? (
						this.state.properties.length > 0 ? (
							this.state.properties
								.filter(
									property =>
										String(
											property.propertynumber +
												property.propertyStreet +
												property.propertyCity +
												property.propertyProvince +
												property.propertyType
										)
											.replace(/\s+/g, '')
											.toLowerCase()
											.includes(
												this.state.searchValue
													.toLowerCase()
													.replace(/\s+/g, '')
													.replace(/,/g, '')
											) && this.state.filters.active[property.active]
								)
								.sort((a, b) => {
									a = a.propertyStreet || '';
									b = b.propertyStreet || '';
									return a.localeCompare(b);
								})
								.map((property, index) => (
									<PropertyListing
										icon='propertyExample1.jpg'
										address={
											property.propertynumber +
											' ' +
											property.propertyStreet +
											', ' +
											property.propertyCity +
											', ' +
											property.propertyProvince +
											' '
										}
										units={property.total_units}
										type={property.propertyType}
										owner={property.PO_fname + ' ' + property.PO_lname}
										key={index}
										id={property.propertyid}
										active={property.active}
										link={true}
										onclick={false}
										userType={this.props.userType}
										setPropertyActive={this.setPropertyActive}
										loading={property.loading}
										mobile={this.state.width <= 1130}
										isPortfolio
									/>
								))
						) : (
							<div className='centeredText'>
								<h3>Cannot find any properties.</h3>
								<div className='caption'>
									If you think this is an error, please try contacting your
									property manager.
								</div>
							</div>
						)
					) : (
						<LoadScreen className='m-auto' />
					)}
				</div>
			</div>
		);
	}
}

/* <div className="blueLink fs-14 mr-4 d-inline">New Property</div>
            <div className="blueLink fs-14 d-inline">Export Spreadsheet</div>*/

export default Properties;
