import React from 'react';
import {
	Table,
	Button,
	DatePicker,
	TimePicker,
	Input,
	Modal,
	message
} from 'antd';
import {
	FormOutlined,
	LoadingOutlined,
	FieldTimeOutlined,
	WindowsFilled
} from '@ant-design/icons';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

class AddNewLog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startTime: '',
			endTime: '',
			totalTime: '',
			totalDistance: '',
			totalExpenses: ''
		};
	}
	handleChange = e => {
		if (e.target.name === 'totalDistance') {
			this.setState({ totalDistance: e.target.value });
		} else if (e.target.name === 'totalExpenses') {
			this.setState({ totalExpenses: e.target.value });
		}
	};

	onSubmit = () => {
		axios
			.post('/workOrder/saveEmployeeWorklog', {
				employeeId: this.props.employeeId,
				start_time: this.state.startTime,
				end_time: this.state.endTime,
				total_distance: this.state.totalDistance,
				total_expenses: this.state.totalExpenses
			})
			.then(response => {
				this.setState({ submitted: true });
				window.location.reload();
			});
	};

	render() {
		// if (this.state.submitted) {
		// 	return <h4>Log Added!</h4>;
		// }
		return (
			<div style={{ margin: 'auto', width: '50%' }}>
				<Form>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'> Start Time </Form.Label>
							<DatePicker
								format='YYYY-MM-DD h:mm A'
								showTime={true}
								style={{ marginLeft: 8 }}
								onChange={(date, dateString) => {
									this.setState({ startTime: moment(dateString).toString() });
								}}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label className='form-label'> End Time</Form.Label>
							<DatePicker
								format='YYYY-MM-DD h:mm A'
								showTime={true}
								style={{ marginLeft: 8 }}
								onChange={(date, dateString) => {
									this.setState({ endTime: moment(dateString).toString() });
								}}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className='d-flex'>
						<Form.Group>
							<Form.Label className='form-label'>
								Total Distance Travelled(Kms){' '}
							</Form.Label>

							<Form.Control
								required
								className='form-text'
								name='totalDistance'
								type='text'
								value={this.state.totalDistance || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group>
							<Form.Label className='form-label'>Total Expenses($) </Form.Label>
							<Form.Control
								required
								className='form-text'
								name='totalExpenses'
								type='text'
								value={this.state.totalExpenses || ''}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</Form.Row>

					<br></br>
					<div className='text-right'>
						<Button onClick={this.onSubmit} type='primary'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

// class NewLogCell extends React.Component {
// 	constructor(props) {
// 		super(props);
// 	}

// 	changeDate = date => {
// 		let newDate = moment(date.format('YYYY-MM-DD'))
// 			.hour(date.hour())
// 			.minute(date.minute())
// 			.second(date.second());
// 		console.log(newDate);
// 		let startDate = moment(new Date(this.props.rowData.start_time_data));
// 		let endDate = moment(new Date(this.props.rowData.end_time_data));
// 		if (endDate) {
// 			if (this.props.cellType == 'start') {
// 				if (newDate >= endDate) {
// 					newDate = moment(endDate.format('YYYY-MM-DD'))
// 						.hour(endDate.hour())
// 						.minute(endDate.minute())
// 						.second(endDate.second() - 1);
// 				}
// 			} else if (newDate <= startDate) {
// 				newDate = moment(startDate.format('YYYY-MM-DD'))
// 					.hour(startDate.hour())
// 					.minute(startDate.minute())
// 					.second(startDate.second() + 1);
// 			}
// 		}

// 		this.props.editDate(newDate, this.props.cellType);
// 	};

// 	// changeTime = time => {
// 	// 	let newTime = moment(time.format('YYYY-MM-DD'))
// 	// 		.hour(time.hour())
// 	// 		.minute(time.minute())
// 	// 		.second(time.second());
// 	// 	console.log(newTime);
// 	// 	let startDate = moment(new Date(this.props.rowData.start_time_data));
// 	// 	let endDate = moment(new Date(this.props.rowData.end_time_data));
// 	// 	if (endDate) {
// 	// 		if (this.props.cellType == 'start') {
// 	// 			if (newTime >= endDate) {
// 	// 				newTime = moment(endDate.format('YYYY-MM-DD'))
// 	// 					.hour(endDate.hour())
// 	// 					.minute(endDate.minute())
// 	// 					.second(endDate.second() - 1);
// 	// 			}
// 	// 		} else if (newTime <= startDate) {
// 	// 			newTime = moment(startDate.format('YYYY-MM-DD'))
// 	// 				.hour(startDate.hour())
// 	// 				.minute(startDate.minute())
// 	// 				.second(startDate.second() + 1);
// 	// 		}
// 	// 	}
// 	// 	this.props.editDate(newTime, this.props.cellType);
// 	// };

//

// 	render() {
// 		let thisDate = moment(
// 			new Date(this.props.rowData[this.props.cellType + '_time_data'])
// 		);
// 		if (this.props.rowData[this.props.cellType + '_time']) {
// 			return <>{this.props.cellData}</>;
// 		} else {
// 			return (
// 				<>
// 					<DatePicker
// 						disabled={this.props.rowData.uploadingNewLog}
// 						inputReadOnly
// 						use12Hours
// 						hideDisabledOptions
// 						allowClear={false}
// 						renderExtraFooter={null}
// 						onSelect={this.changeDate}
// 						defaultValue={thisDate}
// 						disabledDate={this.disabledDate}
// 						format='YYYY-MM-DD h:mm A'
// 						showTime={true}
// 					/>
// 					{/* <TimePicker
// 						disabled={this.props.rowData.uploadingNewLog}
// 						use12Hours
// 						inputReadOnly
// 						hideDisabledOptions
// 						allowClear={false}
// 						format='h:mm a'
// 						renderExtraFooter={null}
// 						onSelect={this.changeTime}
// 						defaultValue={thisDate}
// 						disabledHours={this.disabledHours}
// 						disabledMinutes={this.disabledMinutes}
// 						disabledSeconds={this.disabledSeconds}
// 						showNow={false}
// 					/> */}
// 				</>
// 			);
// 		}
// 	}
// }

export default AddNewLog;
