import React from 'react';
import '../JobRequests/maintenance.css';
import LoadScreen from '../../Components/LoadScreen';
import axios from 'axios';

import RequestActions from './RequestActions';
import './maintenanceRequest.css';
import ActivityLog from './RequestActivityLog';
import RequestDescription from './RequestDescription';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { TenantRequestVendor } from './TenantRequestVendor';
import { RequestVendor } from './RequestVendor';
import NoAccess from '../../Components/NoAccess';
import WorkOrderDisplay from './actions/modals/WorkOrderDisplay/WorkOrderDisplay';
import ReservationDetails from './actions/ReservationDetails';
import { MaintenanceRequestListing } from '../JobRequests/RequestListing';
import RequestContext from './RequestContext';
import { UserStateContext } from '../../Context/UserContext';
import Modal from 'antd/lib/modal/Modal';
import BarCalendar from '../../Components/BarCalendar/BarCalendar';
const cancelToken = axios.CancelToken.source();

export class TenantMaintenanceRequest extends React.Component {
	static contextType = UserStateContext;

	constructor(props) {
		super();
		this.state = {
			//both id & request retrieved before page loads
			request_id: null,
			request: null,
			access: null,
			//Determines whole request render. Loading/error/request
			page: 'Loading',
			errorMessage: '',
			newAvailability: false,
			calData: [],
			workers: [],

			updateStatus: status => {
				this.setState({
					request: { ...this.state.request, job_status: status }
				});
				//this.props.updateMaintenanceStatus(this.props.job_id,status);
			},
			terminateVendor: (vendor_id, endDate) => {
				let temp = [...this.state.request.vendors];
				let currVendor = temp.findIndex(v => v.vendor_id === vendor_id);
				if (currVendor === -1) {
					console.log('cant find vendor');
					return;
				}
				temp[currVendor] = {
					...temp[currVendor],
					endDate: endDate,
					terminate: 1
				};

				this.setState({ request: { ...this.state.request, vendors: temp } });
				return;
			},

			//Schedule New Event
			scheduleWork: event => {
				let currentSchedule =
					this.state.request.workSchedule === undefined
						? []
						: [...this.state.request.workSchedule];
				currentSchedule.push(event);
				this.setState({
					request: { ...this.state.request, workSchedule: currentSchedule }
				});
				return;
			},
			//Edits the details of one event
			editEvent: updatedEvent => {
				let events = [...this.state.request.workSchedule];
				let index = events.findIndex(
					event => event.idcalendar_events === updatedEvent.idcalendar_events
				);
				events.splice(index, 1, updatedEvent);
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			//Deletes a scheduled event
			cancelEvent: id => {
				let events = this.state.request.workSchedule;
				let index = events.findIndex(event => event.idcalendar_events === id);
				events[index].terminate = 1;
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			completeEvent: id => {
				let events = this.state.request.workSchedule;
				let index = events.findIndex(event => event.idcalendar_events === id);
				events[index].complete = 1;
				this.setState({
					request: { ...this.state.request, workSchedule: events }
				});
			},
			/* Adds new activity logs to the activity logs list list */
			addActivity: (activity = [], count = -1, newLog = false) => {
				if (!Array.isArray(activity)) return;
				if (this.state.request.logs == null) {
					return this.setState({
						request: { ...this.state.request, logs: activity }
					});
				}
				let newLogs = newLog
					? activity.concat(this.state.request.logs)
					: this.state.request.logs.concat(activity);

				if (count !== -1 && count !== null)
					this.setState({
						request: { ...this.state.request, logs: newLogs, logCount: count }
					});
				else
					this.setState({ request: { ...this.state.request, logs: newLogs } });
			},
			/* Used when adding an image to the request */
			updateImages: images => {
				let currentRequest = this.state.request;
				let currentImages = Array.isArray(currentRequest.images)
					? images.concat(currentRequest.images)
					: images;
				currentRequest.images = currentImages;
				this.setState({ request: currentRequest });
			},

			//Upate title
			updateTitle: title => {
				this.setState({ request: { ...this.state.request, job_title: title } });
			},
			//Update description
			updateDescription: description => {
				this.setState({
					request: { ...this.state.request, job_description: description }
				});
			},
			updateVendors: (vendors = []) => {
				if (vendors.length == 0) return;
				let updatedVendors = vendors.concat(this.state.request.vendors);
				let updatedActiveVendors = vendors.concat(
					this.state.request.activeVendors
				);
				this.setState({
					request: {
						...this.state.request,
						vendors: updatedVendors,
						activeVendors: updatedActiveVendors
					}
				});
			},

			setError: (error_name, error_desc) => {
				this.setState({
					page: 'Error',
					errorMessage: [error_name, error_desc]
				});
				return;
			}
		};
	}
	openNewAvailability = () =>
		this.setState({ newAvailability: !this.state.newAvailability });

	UNSAFE_componentWillMount() {
		let route = window.location.href;
		let request = this.props.job_id || route.split('/')[5];
		let request_id = parseInt(request);
		this.setState({ request_id: request_id, user: this.context.user });
		this.getRequest(request_id);
		this.getAvailability();
	}

	componentDidUpdate = prevProps => {
		if (prevProps.job_id !== this.props.job_id) {
			this.setState({ page: 'Loading' });
			this.getRequest(this.props.job_id);
		}
	};

	getRequest = request_id => {
		axios
			.get('jobRequest/get/maintenanceRequest/' + request_id)
			.then(response => {
				document.title = response.data.request.job_title;
				if (response.data.error) {
					this.setState({ page: 'Error', errorMessage: response.data.error });
				} else if (!response.data.access) {
					this.setState({
						access: response.data.access,
						page: 'NoAccess',
						access_permission: true
					});
				} else
					this.setState({
						request: response.data.request,
						page: 'Request',
						action_permission: true,
						workers: response.data.workers || []
					});
			})
			.catch(err => {
				if (err)
					this.setState({
						errorMessage: 'Something Went Wrong.',
						page: 'Error'
					});
			});
	};
	getAvailability = () => {
		axios
			.post('/post/loadjravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				)
			})
			.then(response => {
				this.setState({ calData: response.data.availability });
				return;
			});
	};
	createEvent(newEvent) {
		//let eventID= new Date().getTime()
		axios.post('/post/addjravailability', {
			jobuserID: Number(
				String(this.context.user.id) + String(this.props.job_id)
			),
			eventID: newEvent.event_id,
			// jobID:this.props.job_id,
			// userID:this.context.user_id,
			startTime: newEvent.startTime,
			endTime: newEvent.endTime,
			startDate: newEvent.startDate
		});
	}
	changeEvent = newData => {
		if (newData.operation == 'delete') {
			axios.post('/deletejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id
				// userID:this.context.user.id,
				// jobID:this.context.user_id,
			});
		}
		if (newData.operation == 'edit') {
			axios.post('/updatejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id,
				// userID:this.props.job_id,
				// jobID:this.context.user_id,

				NewEndTime: newData.data.endTime,
				NewStartTime: newData.data.startTime,
				NewStartDate: newData.data.startDate
			});
		}
		if (newData.operation == 'merge') {
			for (var i = 0; i < newData.delete_ids.length; i++) {
				axios.post('/deletejravailability', {
					jobuserID: Number(
						String(this.context.user.id) + String(this.props.job_id)
					),
					eventID: newData.id
				});
			}
			axios.post('/updatejravailability', {
				jobuserID: Number(
					String(this.context.user.id) + String(this.props.job_id)
				),
				eventID: newData.id,
				NewEndTime: newData.data.endTime,
				NewStartTime: newData.data.startTime,
				NewStartDate: newData.data.startDate
			});
		}
	};

	render() {
		//Still fetching the data
		if (this.state.page === 'Loading') {
			return <LoadScreen className='m-auto' />;
		}

		//Some error occurred
		else if (this.state.page === 'Error') {
			return <ErrorPage errorMessage={this.state.errorMessage} />;
		}
		//No access to this job request
		else if (this.state.page === 'NoAccess') {
			return <NoAccess />;
		} else if (this.state.page === 'Request') {
			//TODO: Add light color to the jr tiles
			return (
				<div
					id='jobRequestShell'
					className={`${this.props.invertedColors ? 'invertColors' : ''}`}
				>
					<RequestContext.Provider value={this.state}>
						<RequestDescription mobile={this.props.mobile} />

						<Button onClick={this.openNewAvailability}>Availability</Button>
						<Modal
							visible={this.state.newAvailability}
							onCancel={this.openNewAvailability}
							footer={false}
						>
							<BarCalendar
								dataSource={[
									{
										bars: this.state.calData
									}
								]}
								eventStyles={{
									pink: {
										start: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#fe4d97',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									},
									blue: {
										start: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#4d7cfe',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									},
									default: {
										start: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderTop: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										middle: {
											backgroundColor: '#778ca2',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										end: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										},
										single: {
											opacity: 0.65,
											backgroundColor: '#778ca2',
											borderTop: '1px solid #000000',
											borderBottom: '1px solid #000000',
											borderLeft: '1px solid #000000',
											borderRight: '1px solid #000000'
										}
									}
								}}
								calendarView='month'
								showHeader={true}
								interactMode='editable'
								allowNewEvents
								showTimeBar={false}
								orientation='vertical'
								newEvent={newEvent => {
									this.createEvent(newEvent);
									return newEvent;
								}}
								editEvent={newData => {
									this.changeEvent(newData);
									return;
								}}
								clickEvent={eventData => {}}
								blockWidth={35}
								blockHeight={20}
							/>
						</Modal>

						{!(
							this.state.request.job_status === 'Job Request' ||
							this.state.request.job_status === 'Complete' ||
							this.state.request.job_status === 'Rejected' ||
							this.state.request.job_status === 'Cancelled'
						) ? (
							<TenantRequestVendor user={this.context.user} />
						) : (
							''
						)}

						{(this.state.request.job_status === 'Work Order - Work Done' ||
							this.state.request.job_status === 'Invoicing' ||
							this.state.request.job_status === 'Work Order - Review' ||
							this.state.request.job_status === 'Complete') &&
						this.state.request.workOrders ? (
							<WorkOrderDisplay />
						) : (
							''
						)}
					</RequestContext.Provider>
				</div>
			);
		}
	}
}

function ErrorPage({ errorMessage }) {
	return (
		<Result
			status='500'
			title='500'
			subTitle={errorMessage}
			extra={
				<Link to='/maintenance'>
					<Button type='primary'>Back to Maintenance Requests</Button>
				</Link>
			}
		/>
	);
}

export default TenantMaintenanceRequest;
