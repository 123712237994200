import React from "react";
import axios from "axios";

import TaskDisplay from "./TaskDisplay.js";
import LoadScreen from "../../Components/LoadScreen";
import { Layout, Menu, Button, Popover, Popconfirm, Modal, message, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import {
  BarsOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  MinusCircleFilled,
} from '@ant-design/icons';
import "./RequestListLayout.css";
const cancelToken = axios.CancelToken.source();

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class SRequest extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const colorTypes = {
      complete: "#69b7ff",
      inProgress: "#1890ff",
      notStarted: "#cccccc"
    };
    let completeVal = "Not Started";
    let topColor = colorTypes.notStarted;
    let bottomColor = colorTypes.notStarted;
    let isCompleted = this.props.isCompleted === "true" || this.props.isCompleted === true ? true : false;
    if (this.props.checkOverride || this.props.overrideNum >= this.props.numSteps || (this.props.checkOverride === null && (this.props.numStepsCompleted >= this.props.numSteps || isCompleted))) {
      completeVal = "Complete";
      topColor = colorTypes.complete;
      bottomColor = colorTypes.complete;
    } else if (this.props.inProgress) {
      completeVal = "In Progress";
      bottomColor = colorTypes.inProgress;
    }
    let stateTaskLink = "/tasks" + (typeof this.props.taskId !== "undefined" ? "/" + this.props.taskId : "");
    let displayData = (
      <div className="site_tasklist-display-line" style={{width: "100%"}}>
        <div style={{position: "absolute", zIndex: 1, height: "100%", width: "100%"}} onClick={(event) => {if (!this.props.isMenu) this.props.updateChangeTask(this.props.taskId, this.props.topLayerId, this.props.selectedTaskId, stateTaskLink)}}></div>
        <p className="site-tasklist-name" style={this.props.boldFlag ? { fontWeight: "bold", paddingTop: "1%", paddingBottom: "1%", width: "60%", display: "inline" } : { fontWeight: "normal", paddingTop: "1%", paddingBottom: "1%", width: "60%", display: "inline" }}>
          {this.props.taskName}
        </p>
        <div className="site-tasklist-tasks">
          <Tooltip title={<span style={{color: "#595959"}}>{completeVal}</span>} color={"white"}>
            <svg width="20" height="20" style={{marginTop: 10} }>
              <clipPath id="topHalfClip">
                <rect width="20" height="10"></rect>
              </clipPath>
              <circle clipPath="url(#topHalfClip)" cx="10" cy="10" r="8" stroke={topColor} strokeWidth="1" fill={topColor} />
              <clipPath id="bottomHalfClip">
                <rect y="10" width="20" height="10"></rect>
              </clipPath>
              <circle clipPath="url(#bottomHalfClip)" cx="10" cy="10" r="8" stroke={bottomColor} strokeWidth="1" fill={bottomColor} />
            </svg>
          </Tooltip>
          {this.props.topLevel && !("notDeletable" in this.props) ? <Popconfirm
            title="Are you sure?"
            okText="Yes"
            key={this.props.taskId}
            onConfirm={() => {this.props.deleteTaskData(this.props.taskId, true)}}
            icon={<QuestionCircleOutlined style={{ color: '#1890ff' }} />}
            cancelText="No">
              <DeleteOutlined
                style={{ paddingLeft: 10, marginBottom: 10}}/>
            </Popconfirm> : null}
        </div>
      </div>
    );
    return <div className={this.props.className}>{displayData}</div>;
  }
}

const predefinedTasks = {
  "addNonServiceRegistrationPropertySpreadsheetOwner": {
    "checklist": "",
    "description": "",
    "dueDate": "",
    "isCompleted": "false",
    "priority": "",
    "subtasks": {
      "1_spreadsheetUpload": {
        "checklist": "",
        "description": "Upload Spreadsheets",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_uploadProperties": {
            "checklist": "",
            "completionFlag": "propertyRequiredFilled",
            "description":
              "When uploading properties, please keep this in mind:///NEWLINE///"
              + '-All you need to do is upload the spreadsheet and drag each of your columns to match with our side///NEWLINE///'
              + '-You can hit the preview button to see if the data you are giving us fits with the formatting of our own data///NEWLINE///'
              + '-If a column has too much data in it for one of our columns, try opening the edit function using the arrow on the side///NEWLINE///'
              + '///TAB///-Press the left and right arrows to move the splitting cursor, and the divison button on the end to create new columns///NEWLINE///'
              + '-Only files with the extensions ".xlsx" and ".csv" are supported///NEWLINE///'
              + '-Ensure there are no extra rows such as rows containing totals and summaries///NEWLINE///'
              + "-It is recommended to use spreadsheets with only one set of data per column:///NEWLINE///"
              + "///IMAGE///",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "uploadProperties",
            "taskName": "Properties",
            "type": "intake/spreadsheet/upload/property"
          },
          "2_uploadTenants": {
            "checklist": "",
            "completionFlag": "tenantRequiredFilled",
            "description":
              "When uploading tenants, please keep this in mind:///NEWLINE///"
              + '-All you need to do is upload the spreadsheet and drag each of your columns to match with our side///NEWLINE///'
              + '-You can hit the preview button to see if the data you are giving us fits with the formatting of our own data///NEWLINE///'
              + '-If a column has too much data in it for one of our columns, try opening the edit function using the arrow on the side///NEWLINE///'
              + '///TAB///-Press the left and right arrows to move the splitting cursor, and the divison button on the end to create new columns///NEWLINE///'
              + '-Only files with the extensions ".xlsx" and ".csv" are supported///NEWLINE///'
              + '-Ensure there are no extra rows such as rows containing totals and summaries///NEWLINE///'
              + "-It is recommended to use spreadsheets with only one set of data per column:///NEWLINE///"
              + "///IMAGE///",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "uploadTenants",
            "taskName": "Tenants",
            "type": "intake/spreadsheet/upload/tenant"
          }
        },
        "taskId": "spreadsheetUpload",
        "taskName": "Upload Spreadsheets",
        "type": "intake/spreadsheet/upload"
      },
      "2_spreadsheetConnect": {
        "checklist": "",
        "description": "Connect Spreadsheets",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_connectTenants": {
            "checklist": "",
            "completionFlag": "tenantConnectionComplete",
            "description": "On this screen, you may attach each tenant (as well as the tenant's rental data) with each properties you have given in your spreadsheets.///NEWLINE///"
              + "By default, we will detect connections between your tenant spreadsheet and property spreadsheet.///NEWLINE///"
              + "If we detect matches, we will show them to you. If none of them matches for you, please connect the sheets manually///NEWLINE///"
              + "by pressing the Manual button. You can switch back to Automatic at any time, but this will erase any progress you have made///NEWLINE///"
              + "on the Manual screen.",
            "dueDate": "",
            "isCompleted": "false",
            "prerequisiteTasks": "uploadProperties uploadTenants",
            "priority": "",
            "subtasks": {

            },
            "taskId": "connectTenants",
            "taskName": "Connect Tenants",
            "type": "intake/spreadsheet/connect/tenant"
          }
        },
        "taskId": "spreadsheetConnect",
        "taskName": "Connect Spreadsheets",
        "type": "intake/spreadsheet/connect"
      },
      "3_spreadsheetSubmit": {
        "checklist": "",
        "description": "Submit Spreadsheet Data",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_approveTenants": {
            "checklist": "",
            "completionFlag": "tenantApproval",
            "description": "This table will preview how tenants and properties will be connected. If you press the plus button, you will see///NEWLINE///"
              + "every tenant associated with that respective property. Press the Approve button to approve this data for uploading.",
            "dueDate": "",
            "isCompleted": "false",
            "prerequisiteTasks": "uploadProperties uploadTenants connectTenants",
            "priority": "",
            "subtasks": {

            },
            "taskId": "approveTenants",
            "taskName": "Approve Tenants",
            "type": "intake/spreadsheet/submit/tenant"
          },
          "2_submitSpreadsheetData": {
            "checklist": "",
            "completionFlag": "spreadsheetSubmitComplete",
            "description": "Once you have approved all of your data, press the Submit button to complete the upload process.///NEWLINE///"
              + "If you get an error, please review your data to ensure it is complete and matches the our database.///NEWLINE///",
            "dueDate": "",
            "prerequisiteTasks": "uploadProperties uploadOwners connectOwners approveOwners",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "submitSpreadsheetData",
            "taskName": "Submit",
            "type": "intake/spreadsheet/submit/submit"
          }
        },
        "taskId": "spreadsheetSubmit",
        "taskName": "Submit Spreadsheet Data",
        "type": "intake/spreadsheet/submit"
      }
    },
    "taskId": "bulkPropertyUpload",
    "taskName": "Bulk Property Upload",
    "type": "intake/spreadsheet",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationPropertySpreadsheetManager": {
    "checklist": "",
    "description": "",
    "dueDate": "",
    "isCompleted": "false",
    "priority": "",
    "subtasks": {
      "1_spreadsheetUpload": {
        "checklist": "",
        "description": "Upload Spreadsheets",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_uploadProperties": {
            "checklist": "",
            "completionFlag": "propertyRequiredFilled",
            "description":
              "When uploading properties, please keep this in mind:///NEWLINE///"
              + '-All you need to do is upload the spreadsheet and drag each of your columns to match with our side///NEWLINE///'
              + '-You can hit the preview button to see if the data you are giving us fits with the formatting of our own data///NEWLINE///'
              + '-If a column has too much data in it for one of our columns, try opening the edit function using the arrow on the side///NEWLINE///'
              + '///TAB///-Press the left and right arrows to move the splitting cursor, and the divison button on the end to create new columns///NEWLINE///'
              + '-Only files with the extensions ".xlsx" and ".csv" are supported///NEWLINE///'
              + '-Ensure there are no extra rows such as rows containing totals and summaries///NEWLINE///'
              + "-It is recommended to use spreadsheets with only one set of data per column:///NEWLINE///"
              + "///IMAGE///",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "uploadProperties",
            "taskName": "Properties",
            "type": "intake/spreadsheet/upload/property"
          },
          "2_uploadOwners": {
            "checklist": "",
            "completionFlag": "ownerRequiredFilled",
            "description":
              "When uploading owners, please keep this in mind:///NEWLINE///"
              + '-All you need to do is upload the spreadsheet and drag each of your columns to match with our side///NEWLINE///'
              + '-You can hit the preview button to see if the data you are giving us fits with the formatting of our own data///NEWLINE///'
              + '-If a column has too much data in it for one of our columns, try opening the edit function using the arrow on the side///NEWLINE///'
              + '///TAB///-Press the left and right arrows to move the splitting cursor, and the divison button on the end to create new columns///NEWLINE///'
              + '-Only files with the extensions ".xlsx" and ".csv" are supported///NEWLINE///'
              + '-Ensure there are no extra rows such as rows containing totals and summaries///NEWLINE///'
              + "-It is recommended to use spreadsheets with only one set of data per column:///NEWLINE///"
              + "///IMAGE///",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "uploadOwners",
            "taskName": "Owners",
            "type": "intake/spreadsheet/upload/owner"
          },
          "3_uploadTenants": {
            "checklist": "",
            "completionFlag": "tenantRequiredFilled",
            "description":
              "When uploading tenants, please keep this in mind:///NEWLINE///"
              + '-All you need to do is upload the spreadsheet and drag each of your columns to match with our side///NEWLINE///'
              + '-You can hit the preview button to see if the data you are giving us fits with the formatting of our own data///NEWLINE///'
              + '-If a column has too much data in it for one of our columns, try opening the edit function using the arrow on the side///NEWLINE///'
              + '///TAB///-Press the left and right arrows to move the splitting cursor, and the divison button on the end to create new columns///NEWLINE///'
              + '-Only files with the extensions ".xlsx" and ".csv" are supported///NEWLINE///'
              + '-Ensure there are no extra rows such as rows containing totals and summaries///NEWLINE///'
              + "-It is recommended to use spreadsheets with only one set of data per column:///NEWLINE///"
              + "///IMAGE///",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "uploadTenants",
            "taskName": "Tenants",
            "type": "intake/spreadsheet/upload/tenant"
          }
        },
        "taskId": "spreadsheetUpload",
        "taskName": "Upload Spreadsheets",
        "type": "intake/spreadsheet/upload"
      },
      "2_spreadsheetConnect": {
        "checklist": "",
        "description": "Connect Spreadsheets",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_connectOwners": {
            "checklist": "",
            "completionFlag": "ownerConnectionComplete",
            "description": "On this screen, you may attach each owner with each properties you have given in your spreadsheets.///NEWLINE///"
              + "By default, we will detect connections between your owner spreadsheet and property spreadsheet.///NEWLINE///"
              + "If we detect matches, we will show them to you. If none of them matches for you, please connect the sheets manually///NEWLINE///"
              + "by pressing the Manual button. You can switch back to Automatic at any time, but this will erase any progress you have made///NEWLINE///"
              + "on the Manual screen.",
            "dueDate": "",
            "isCompleted": "false",
            "priority": "",
            "prerequisiteTasks": "uploadProperties uploadOwners",
            "subtasks": {

            },
            "taskId": "connectOwners",
            "taskName": "Connect Owners",
            "type": "intake/spreadsheet/connect/owner"
          },
          "2_connectTenants": {
            "checklist": "",
            "completionFlag": "tenantConnectionComplete",
            "description": "On this screen, you may attach each tenant (as well as the tenant's rental data) with each properties you have given in your spreadsheets.///NEWLINE///"
              + "By default, we will detect connections between your tenant spreadsheet and property spreadsheet.///NEWLINE///"
              + "If we detect matches, we will show them to you. If none of them matches for you, please connect the sheets manually///NEWLINE///"
              + "by pressing the Manual button. You can switch back to Automatic at any time, but this will erase any progress you have made///NEWLINE///"
              + "on the Manual screen.",
            "dueDate": "",
            "isCompleted": "false",
            "prerequisiteTasks": "uploadProperties uploadTenants",
            "priority": "",
            "subtasks": {

            },
            "taskId": "connectTenants",
            "taskName": "Connect Tenants",
            "type": "intake/spreadsheet/connect/tenant"
          }
        },
        "taskId": "spreadsheetConnect",
        "taskName": "Connect Spreadsheets",
        "type": "intake/spreadsheet/connect"
      },
      "3_spreadsheetSubmit": {
        "checklist": "",
        "description": "Submit Spreadsheet Data",
        "dueDate": "",
        "isCompleted": "false",
        "priority": "",
        "subtasks": {
          "1_approveOwners": {
            "checklist": "",
            "completionFlag": "ownerApproval",
            "description": "This table will preview how owners and properties will be connected. If you press the plus button, you will see///NEWLINE///"
              + "every owner associated with that respective property. Press the Approve button to approve this data for uploading.",
            "dueDate": "",
            "isCompleted": "false",
            "prerequisiteTasks": "uploadProperties uploadOwners connectOwners",
            "priority": "",
            "subtasks": {

            },
            "taskId": "approveOwners",
            "taskName": "Approve Owners",
            "type": "intake/spreadsheet/submit/owner"
          },
          "2_approveTenants": {
            "checklist": "",
            "completionFlag": "tenantApproval",
            "description": "This table will preview how tenants and properties will be connected. If you press the plus button, you will see///NEWLINE///"
              + "every tenant associated with that respective property. Press the Approve button to approve this data for uploading.",
            "dueDate": "",
            "isCompleted": "false",
            "prerequisiteTasks": "uploadProperties uploadTenants connectTenants",
            "priority": "",
            "subtasks": {

            },
            "taskId": "approveTenants",
            "taskName": "Approve Tenants",
            "type": "intake/spreadsheet/submit/tenant"
          },
          "3_submitSpreadsheetData": {
            "checklist": "",
            "completionFlag": "spreadsheetSubmitComplete",
            "description": "Once you have approved all of your data, press the Submit button to complete the upload process.///NEWLINE///"
              + "If you get an error, please review your data to ensure it is complete and matches the our database.///NEWLINE///",
            "dueDate": "",
            "prerequisiteTasks": "uploadProperties uploadOwners connectOwners approveOwners",
            "isCompleted": "false",
            "priority": "",
            "subtasks": {

            },
            "taskId": "submitSpreadsheetData",
            "taskName": "Submit",
            "type": "intake/spreadsheet/submit/submit"
          }
        },
        "taskId": "spreadsheetSubmit",
        "taskName": "Submit Spreadsheet Data",
        "type": "intake/spreadsheet/submit"
      }
    },
    "taskId": "bulkPropertyUpload",
    "taskName": "Bulk Property Upload",
    "type": "intake/spreadsheet",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationPropertyNewPropertyOwner": {
    "checklist": "",
    "description": "Getting all the property info",
    "dueDate": "",
    "isCompleted": "",
    "priority": "",
    "subtasks": {
      "1_registerAddress": {
        "checklist": "",
        "description": "Getting property name",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {          },
        "taskId": "GeneralPropertyInfo",
        "taskName": "Register Property Infomation",
        "type": "intake/PropertyReg/GeneralPropertyInfo"
      },
      "2_registerQuestionaire": {
        "checklist": "",
        "description": "Questionaire for owners",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {          },
        "taskId": "registerQuestionaire",
        "taskName": "Register Questionaire",
        "type": "intake/PropertyReg/registerQuestionaire"
      }
    },
    "taskId": "propertyRegistration",
    "taskName": "Registering a property",
    "type": "intake/property",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationPropertyNewPropertyManager": {
    "checklist": "",
    "description": "Getting all the property info",
    "dueDate": "",
    "isCompleted": "",
    "priority": "",
    "subtasks": {
      "1_registerOwners": {
        "checklist": "",
        "description": "Setting up owners",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {          },
        "taskId": "registerOwners",
        "taskName": "Register Owners",
        "type": "intake/PropertyReg/registerOwners"
      },
      "2_registerAddress": {
        "checklist": "",
        "description": "Getting property name",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {          },
        "taskId": "GeneralPropertyInfo",
        "taskName": "Register Property Address",
        "type": "intake/PropertyReg/GeneralPropertyInfo"
      }
    },
    "taskId": "propertyRegistration",
    "taskName": "Registering a property",
    "type": "intake/property",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationCompanyRegisterCompany": {
    "checklist": "",
    "description": "Getting all the company info",
    "dueDate": "",
    "isCompleted": "",
    "priority": "",
    "subtasks": {
      "1_bankingInfo": {
        "checklist": "",
        "description": "Provide banking information!",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "bankingInfo",
        "taskName": "Banking Info",
        "type": "intake/banking",
        "servOrNo": "nonServiceRequest"
      },
      "2_companyInfo": {
        "checklist": "",
        "description": "Provide company information",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "companyInfo",
        "taskName": "Company Info",
        "type": "intake/company",
        "servOrNo": "nonServiceRequest"
      },
      "3_employeeInfo": {
        "checklist": "",
        "description": "Provide banking information",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "employeeInfo",
        "taskName": "Employee Info",
        "type": "intake/employee",
        "servOrNo": "nonServiceRequest"
      },
      "4_insuranceInfo": {
        "checklist": "",
        "description": "Provide insurance information",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "insuranceInfo",
        "taskName": "Insurance Info",
        "type": "intake/insurance",
        "servOrNo": "nonServiceRequest"
      }
    },
    "taskId": "companyRegistration",
    "taskName": "Registering a company",
    "type": "intake/property",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationServiceSelfVendor": {
    "checklist": "",
    "description": "Getting all the vendor info",
    "dueDate": "",
    "isCompleted": "",
    "priority": "",
    "subtasks": {
      "1_vendorInfo": {
        "checklist": "",
        "description": "Provide vendor information",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "vendorInfo",
        "taskName": "Vendor Info",
        "type": "intake/vendor",
        "servOrNo": "nonServiceRequest"
      },
      "2_bankingInfo": {
        "checklist": "",
        "description": "Provide banking information!",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "bankingInfo",
        "taskName": "Banking Info",
        "type": "intake/banking",
        "servOrNo": "nonServiceRequest"
      },
      "3_insuranceInfo": {
        "checklist": "",
        "description": "Provide insurance information",
        "dueDate": "",
        "isCompleted": "",
        "priority": "",
        "subtasks": "",
        "taskId": "insuranceInfo",
        "taskName": "Insurance Info",
        "type": "intake/insurance",
        "servOrNo": "nonServiceRequest"
      }
    },
    "taskId": "vendorRegistration",
    "taskName": "Registering self as vendor",
    "type": "intake/property",
    "servOrNo": "nonServiceRequest"
  },
  "addNonServiceRegistrationUser": {
    "checklist": "",
    "description": "uploading user info",
    "dueDate": "",
    "isCompleted": "",
    "priority": "",
    "subtasks": {
      "registerGeneralInfo": {
        "checklist": "",
        "description": "Get info like address and DOB",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {
        },
        "taskId": "registerGeneralInfo",
        "taskName": "Register Basic Info",
        "type": "intake/initialReg/registerUserInfo"
      },
      "registerID": {
        "checklist": "",
        "description": "Get info like address and DOB",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {
        },
        "taskId": "registerID",
        "taskName": "Register User ID",
        "type": "intake/initialReg/registerID"
      },
      "registerSelfie": {
        "checklist": "",
        "description": "Get info like address and DOB",
        "dueDate": "",
        "isCompleted": "False",
        "priority": "",
        "subtasks": {
        },
        "taskId": "registerSelfie",
        "taskName": "Register User Selfie",
        "type": "intake/initialReg/registerSelfie"
      }
    },
    "taskId": "registerUserInfo",
    "taskName": "Register User Info",
    "type": "intake/initialReg/registerUserInfo"
  }
}
const splitTasks = {
  "addNonServiceRegistrationPropertySpreadsheet": true,
  "addNonServiceRegistrationPropertyNewProperty": true
}

const limitedTasks = {
  "bulkPropertyUpload": false
}

const specialTasks = {
  "addNonServiceRegistrationPropertyNewProperty": true,
  "propertyRegistration": true
}

const updateOnCompleteTasks = {
  "propertyRegistration": true,
  "companyRegistration": true,
  "vendorRegistration": true,
}

// Methods of recursively handling task lists.
function locateTask(id, taskList, completedTasks, topLayerId, firstLayer = true) {
  if (firstLayer) {
    for (let taskIndex = 0; taskIndex < taskList.length; taskIndex++) {
      let task = taskList[taskIndex];
      if ("taskId" in task && task.taskId === id) {
        let newTask = JSON.parse(JSON.stringify(task));
        newTask.topLayerId = task.taskId;
        return newTask;
      }
      else if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
        let getTask = locateTask(id, task.subtasks, Object.keys(completedTasks).length > 0 ? completedTasks[task.taskId] : {}, task.taskId, false);
        if (getTask !== null)
          return getTask;
      }
    }
    return null;
  } else {
    for (let taskIndex = 0; taskIndex < Object.keys(taskList).length; taskIndex++) {
      let task = taskList[Object.keys(taskList)[taskIndex]];
      if ("taskId" in task && task.taskId === id) {
        if ("prerequisiteTasks" in task) {
          let preSplit = task.prerequisiteTasks.split(" ");
          if (preSplit.some(function(preTaskId){
            if (!(preTaskId in completedTasks))
              return true
            else
              return false
          })) {
            return null;
          }
        }
        let newTask = JSON.parse(JSON.stringify(task));
        newTask.topLayerId = topLayerId;
        return newTask;
      }
      else if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
        if ("prerequisiteTasks" in task) {
          let preSplit = task.prerequisiteTasks.split(" ");
          if (preSplit.some(function(preTaskId){
            if (!(preTaskId in completedTasks))
              return true
          })) {
            return null;
          } else {
            let getTask = locateTask(id, task.subtasks, completedTasks, topLayerId, false);
            if (getTask !== null)
              return getTask;
          }
        } else {
          let getTask = locateTask(id, task.subtasks, completedTasks, topLayerId, false);
          if (getTask !== null)
            return getTask;
        }
      }
    }
    return null;
  }
}
function updateTask(updatedTask, taskList, embedTask = "", totalCount = 0, firstLayer = true, checkForCompletion = false) {
  let currentTask = JSON.parse(JSON.stringify(updatedTask));
  let topLayerId = "";
  if ("topLayerId" in currentTask) {
    topLayerId = currentTask.topLayerId;
    delete currentTask.topLayerId;
  }
  let currentCount = 0;
  let taskAdded = false;
  let fullyCompleted = true;
  if (firstLayer) {
    let taskUpdated = false;
    let newTaskList = taskList.slice(0);
    let originalLength = newTaskList.length;
    for (let taskIndex = 0; taskIndex < originalLength; taskIndex++) {
      let task = newTaskList[taskIndex];
      let strippedId = newTaskList[taskIndex].taskId.split("_")[0];
      currentCount++;
      if ("taskId" in task && task.taskId == currentTask.taskId) {
        newTaskList[taskIndex] = currentTask;
        let comboCount = totalCount + currentCount;
        newTaskList[taskIndex].taskObjectName = comboCount.toString() + "_" + strippedId;
        newTaskList[taskIndex].taskId = strippedId + "_" + comboCount.toString();
        taskUpdated = true;
        if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
          let getTask = updateTask(currentTask, task.subtasks, embedTask === task.taskId ? "ADD||TASKS||HERE" : embedTask, comboCount, false, task.taskId === topLayerId);
          if (!getTask[4] && task.taskId === topLayerId)
            fullyCompleted = false;
          newTaskList[taskIndex].subtasks = getTask[0];
          if (getTask[1])
            taskUpdated = true;
          if (getTask[3])
            taskAdded = true;
          newTaskList[taskIndex].numSteps = Object.keys(getTask[0]).length;
          currentCount += getTask[2];
        }
      }
      else if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
        let comboCount = totalCount + currentCount;
        newTaskList[taskIndex].taskObjectName = comboCount.toString() + "_" + strippedId;
        let getTask = updateTask(currentTask, task.subtasks, embedTask === task.taskId ? "ADD||TASKS||HERE" : embedTask, comboCount, false, task.taskId === topLayerId);
        if (!getTask[4] && task.taskId === topLayerId)
          fullyCompleted = false;
        newTaskList[taskIndex].subtasks = getTask[0];
        if (getTask[1])
          taskUpdated = true;
        if (getTask[3])
          taskAdded = true;
        newTaskList[taskIndex].numSteps = Object.keys(getTask[0]).length;
        newTaskList[taskIndex].taskId = strippedId + "_" + comboCount.toString();
        currentCount += getTask[2];
      }
    }
    if (!taskUpdated && embedTask.length === 0) {
      let newTaskUpdated = JSON.parse(JSON.stringify(currentTask));
      if (!('taskObjectName' in newTaskUpdated))
        newTaskUpdated.taskObjectName = newTaskUpdated.taskId;
      newTaskList.push(newTaskUpdated);
      taskAdded = true;
    }
    return [newTaskList, true, currentCount, taskAdded, fullyCompleted];
  } else {
    let foundTask = false;
    let newTaskList = JSON.parse(JSON.stringify(taskList));
    if (embedTask === "ADD||TASKS||HERE") {
      let strippedTaskName = currentTask.taskObjectName.split("_")[1];
      let oldTaskKeyList = Object.keys(taskList).map(function(taskObj, taskObjIndex){return [taskObj.split("_")[0], taskObjIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}});
      let embedOrderNum = parseInt(currentTask.taskObjectName.split("_")[0]);
      let nowPushing = false;
      for (let taskListIndex = 0; taskListIndex < Object.keys(taskList).length; taskListIndex++) {
        let objName = Object.keys(taskList)[oldTaskKeyList[taskListIndex][1]];
        if (objName.split("_")[1] !== strippedTaskName) {
          let innerKeyVal = parseInt(objName.split("_")[0]);
          if (embedOrderNum === innerKeyVal) {
            nowPushing = true;
          }
          if (nowPushing && innerKeyVal >= embedOrderNum) {
            let movedTask = JSON.parse(JSON.stringify(taskList[objName]));
            newTaskList[(innerKeyVal + 1).toString() + "_" + objName.split("_")[1]] = movedTask;
            delete newTaskList[objName];
          }
        } else
          taskAdded = true;
      }
      newTaskList[currentTask.taskObjectName] = currentTask;
      delete newTaskList[currentTask.taskObjectName].taskObjectName;
    }
    let taskKeyList = Object.keys(newTaskList).map(function(taskObj, taskObjIndex){return [taskObj.split("_")[0], taskObjIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}});
    for (let taskIndex = 0; taskIndex < Object.keys(newTaskList).length; taskIndex++) {
      let task = newTaskList[Object.keys(newTaskList)[taskKeyList[taskIndex][1]]];
      let strippedId = task.taskId.split("_")[0];
      currentCount++;
      let comboCount = totalCount + currentCount;
      newTaskList[Object.keys(newTaskList)[taskKeyList[taskIndex][1]]].taskId = strippedId + "_" + comboCount.toString();
      if ("taskId" in task && task.taskId == currentTask.taskId) {
        newTaskList[Object.keys(newTaskList)[taskKeyList[taskIndex][1]]] = currentTask;
        foundTask = true;
        if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
          let getTask = updateTask(currentTask, task.subtasks, embedTask === task.taskId ? "ADD||TASKS||HERE" : embedTask, comboCount, false, checkForCompletion);
          newTaskList[Object.keys(newTaskList)[taskKeyList[taskIndex][1]]].subtasks = getTask[0];
          currentCount += getTask[2];
          if (checkForCompletion && !getTask[4])
            fullyCompleted = false;
        } else if (checkForCompletion && currentTask.isCompleted !== "true") {
          fullyCompleted = false;
        }
      }
      else if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
        let getTask = updateTask(currentTask, task.subtasks, embedTask === task.taskId ? "ADD||TASKS||HERE" : embedTask, comboCount, false, checkForCompletion);
        if (!getTask[4] && checkForCompletion)
          fullyCompleted = false;
        newTaskList[Object.keys(newTaskList)[taskKeyList[taskIndex][1]]].subtasks = getTask[0];
        if (getTask[1])
          foundTask = true;
        currentCount += getTask[2];
      } else if (checkForCompletion && task.isCompleted !== "true") {
        fullyCompleted = false;
      }
    }
    return [newTaskList, foundTask, currentCount, taskAdded, fullyCompleted];
  }
}
function deleteTask(resetAddressBar, currentTaskId, taskId, taskList, totalCount = 0, firstLayer = true, subTaskHigher = "", deleteSubHere = false) {
  let currentCount = 0;
  if (firstLayer) {
    let newTaskList = taskList.slice(0);
    for (let taskIndex = 0; taskIndex < newTaskList.length; taskIndex++) {
      let task = newTaskList[taskIndex];
      let strippedId = newTaskList[taskIndex].taskId.split("_")[0];
      if ("taskId" in task && task.taskId === taskId && subTaskHigher.length <= 0) {
        newTaskList.splice(taskIndex, 1);
        taskIndex--;
      } else if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
        currentCount++;
        let comboCount = totalCount + currentCount;
        let oldTaskId = newTaskList[taskIndex].taskId;
        newTaskList[taskIndex].taskObjectName = comboCount.toString() + "_" + strippedId;
        newTaskList[taskIndex].taskId = strippedId + "_" + comboCount.toString();
        let getTask = deleteTask(resetAddressBar, currentTaskId, taskId, task.subtasks, comboCount, false, subTaskHigher === oldTaskId ? "" : subTaskHigher,
          subTaskHigher === oldTaskId ? true : false);
        newTaskList[taskIndex].subtasks = getTask[0];
        newTaskList[taskIndex].numSteps = Object.keys(getTask[0]).length;
        currentCount += getTask[1];
        if (currentTaskId === oldTaskId)
          resetAddressBar(newTaskList[taskIndex].taskId);
      } else {
        currentCount++;
        let comboCount = totalCount + currentCount;
        newTaskList[taskIndex].taskObjectName = comboCount.toString() + "_" + strippedId;
        newTaskList[taskIndex].taskId = strippedId + "_" + comboCount.toString();
      }
    }
    return [newTaskList, currentCount];
  } else {
    let newTaskList = {};
    let taskKeyList = Object.keys(taskList).map(function(taskObj, taskObjIndex){return [taskObj.split("_")[0], taskObjIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}});
    let subTaskRemoved = false;
    for (let taskIndex = 0; taskIndex < Object.keys(taskList).length; taskIndex++) {
      if (deleteSubHere && Object.keys(taskList)[taskKeyList[taskIndex][1]].split("_")[1] === taskId) {
        subTaskRemoved = true;
      } else {
        let task = taskList[Object.keys(taskList)[taskKeyList[taskIndex][1]]];
        let innerKeyVal = parseInt(Object.keys(taskList)[taskKeyList[taskIndex][1]].split("_")[0]);
        let strippedId = task.taskId.split("_")[0];
        if (subTaskRemoved)
          innerKeyVal = innerKeyVal - 1;
        let newObjectName = innerKeyVal + "_" + strippedId;
        newTaskList[newObjectName] = JSON.parse(JSON.stringify(task));
        currentCount++;
        let comboCount = totalCount + currentCount;
        if ("subtasks" in task && Object.keys(task.subtasks).length > 0) {
          let getTask = deleteTask(resetAddressBar, currentTaskId, taskId, task.subtasks, comboCount, false);
          newTaskList[newObjectName].subtasks = getTask[0];
          currentCount += getTask[1];
        }
        newTaskList[newObjectName].taskId = strippedId + "_" + comboCount.toString();
        if (task.taskId === currentTaskId)
          resetAddressBar(newTaskList[newObjectName].taskId);
      }
    }
    return [newTaskList, currentCount];
  }
}
function taskList(taskId, taskObjects, deleteTaskData, updateChangeTask, saveData, subTaskKey, topLayerId = "") {
  let topLayerReturn = "";
  let numTasks = subTaskKey !== null ? Object.keys(taskObjects).length : taskObjects.length;
  let taskListData = [];
  let taskListKey = 0;
  let completeCount = 0;
  let totalCount = 0;
  let taskObjectList = subTaskKey !== null ? Object.keys(taskObjects).map(function(taskObj, taskObjIndex){return [taskObj.split("_")[0], taskObjIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}) : null;
  let completedTasksObject = {};
  let subMenuTree = [];
  let selectedTaskKey = "";
  let hasCorrectTask = false;
  let limitedTaskArray = [];
  let inProgress = false;
  while (taskListKey < numTasks) {
    totalCount++;
    let currentTask = subTaskKey !== null ? taskObjects[Object.keys(taskObjects)[taskObjectList[taskListKey][1]]] : taskObjects[taskListKey];
    let objectKey = subTaskKey !== null ? subTaskKey + "sub" + taskListKey.toString() : taskListKey.toString();
    if (currentTask.taskId === taskId) {
      topLayerReturn = topLayerId.length > 0 ? topLayerId : currentTask.taskId;
      hasCorrectTask = true;
      selectedTaskKey = objectKey;
    }
    if (typeof currentTask.taskId !== "undefined" && currentTask.taskId !== null && currentTask.taskId.split("_")[0] in limitedTasks)
      limitedTaskArray.push(currentTask.taskId.split("_")[0]);
    if ("subtasks" in currentTask && Object.keys(currentTask.subtasks).length > 0) {
      let nextList = taskList(taskId, currentTask.subtasks, deleteTaskData, updateChangeTask, saveData, objectKey, topLayerId.length > 0 ? topLayerId : currentTask.taskId);
      if (!inProgress && nextList[9])
        inProgress = true;
      if (nextList[8].length > 0)
        topLayerReturn = nextList[8];
      if (subTaskKey === null)
        completedTasksObject[currentTask.taskId] = nextList[7];
      else 
        Object.assign(completedTasksObject, nextList[7]);
      limitedTaskArray.concat(nextList[6]);
      let topOpenFlag = subTaskKey === null && nextList[3];
      if (nextList[3]) {
        hasCorrectTask = true;
        subMenuTree = nextList[4];
        subMenuTree.unshift(objectKey);
        selectedTaskKey = nextList[5];
      }
      totalCount += nextList[2];
      let checkOverride = null;
      if (saveData !== null && 'completionFlag' in currentTask) {
        let taskCompleteCount = 0;
        let completionFlags = currentTask.completionFlag.split(" ");
        for (let flagIndex = 0; flagIndex < completionFlags.length; flagIndex++) {
          if (saveData[completionFlags[flagIndex]])
            taskCompleteCount++;
        }
        if (completionFlags.length > 0 && taskCompleteCount >= completionFlags.length)
          checkOverride = true;
        else
          checkOverride = false;
      }
      if ((currentTask.isCompleted === "true" && checkOverride === null) || checkOverride || nextList[1] >= currentTask.numSteps) {
        completeCount += 1;
        if (subTaskKey !== null)
          completedTasksObject[currentTask.taskId.split("_")[0]] = 1;
      }
      taskListData.push(
        <SubMenu
          key={objectKey}
          title={
            <SRequest
              key={currentTask.taskId}
              className="site-tasklist-submenu"
              {...currentTask}
              checkOverride={checkOverride}
              overrideNum={nextList[1]}
              saveData={saveData}
              topLevel={subTaskKey === null}
              topLayerId={topLayerId.length > 0 ? topLayerId : currentTask.taskId}
              isMenu={true}
              boldFlag={topOpenFlag}
              deleteTaskData={deleteTaskData}
              completedTasksObject={completedTasksObject}
              inProgress={nextList[9]}
              selectedTaskId={taskId}
            />
          }
        >
          {nextList[0]}
        </SubMenu>
      );
    } else {
      let checkOverride = null;
      if (saveData !== null && 'completionFlag' in currentTask) {
        let taskCompleteCount = 0;
        let completionFlags = currentTask.completionFlag.split(" ");
        for (let flagIndex = 0; flagIndex < completionFlags.length; flagIndex++) {
          if (saveData[completionFlags[flagIndex]])
            taskCompleteCount++;
        }
        if (completionFlags.length > 0 && taskCompleteCount >= completionFlags.length)
          checkOverride = true;
        else
          checkOverride = false;
      }
      if (checkOverride || (checkOverride === null && currentTask.isCompleted === "true"))
        inProgress = true;
      if ((currentTask.isCompleted === "true" && checkOverride === null) || checkOverride) {
        completeCount += 1;
        if (subTaskKey !== null)
          completedTasksObject[currentTask.taskId.split("_")[0]] = 1;
      }
      taskListData.push(
        <Menu.Item
          key={currentTask.taskId}
        >
          <SRequest
            key={currentTask.taskId}
            className="site-tasklist-task"
            {...currentTask}
            checkOverride={checkOverride}
            overrideNum={checkOverride ? 1 : 0}
            saveData={saveData}
            topLevel={subTaskKey === null}
            topLayerId={topLayerId.length > 0 ? topLayerId : currentTask.taskId}
            isMenu={false}
            deleteTaskData={deleteTaskData}
            updateChangeTask={updateChangeTask}
            completedTasksObject={completedTasksObject}
            inProgress={false}
            selectedTaskId={taskId}
          />
        </Menu.Item>
      );
    }
    taskListKey++;
  }
  return [taskListData,
    completeCount, totalCount, hasCorrectTask, subMenuTree,
    selectedTaskKey, limitedTaskArray, completedTasksObject,
    topLayerReturn, inProgress];
}
function generateTask(taskData, taskKey, jrId, firstLayer = true, taskObjects = {}) {
  let totalCount = 0;
  totalCount++;
  if (firstLayer) {
    let singleTaskData = typeof taskData === "object" ? JSON.parse(JSON.stringify(taskData)) : JSON.parse(JSON.stringify(predefinedTasks[taskData]));
    singleTaskData.jobID = jrId;
    singleTaskData.taskObjectName = (taskKey + totalCount).toString() + "_" + singleTaskData.taskId;
    singleTaskData.taskId += "_" + (taskKey + totalCount).toString();
    if ("subtasks" in singleTaskData && Object.keys(singleTaskData.subtasks).length > 0) {
      let subList = generateTask(null, (taskKey + totalCount), jrId, false, singleTaskData.subtasks);
      singleTaskData.subtasks = subList[0];
      totalCount += subList[1];
      if (!("numSteps" in singleTaskData))
        singleTaskData.numSteps = Object.keys(singleTaskData.subtasks).length;
    } else if (!("numSteps" in singleTaskData)) {
      singleTaskData.numSteps = 1;
    }
    if (!("numStepsCompleted" in singleTaskData))
      singleTaskData.numStepsCompleted = 0;
    return [singleTaskData, totalCount-1];
  } else {
    let taskObjectList = Object.keys(taskObjects).sort();
    for (let taskListKey = 0; taskListKey < taskObjectList.length; taskListKey++) {
      let singleTaskData = JSON.parse(JSON.stringify(taskObjects[taskObjectList[taskListKey]]));
      singleTaskData.jobID = jrId;
      singleTaskData.taskId += "_" + (taskKey + totalCount).toString();
      if ("subtasks" in singleTaskData && Object.keys(singleTaskData.subtasks).length > 0) {
        let subList = generateTask(null, (taskKey + totalCount), jrId, false, singleTaskData.subtasks);
        singleTaskData.subtasks = subList[0];
        totalCount += subList[1];
        if (!("numSteps" in singleTaskData))
          singleTaskData.numSteps = Object.keys(singleTaskData.subtasks).length;
      } else {
        totalCount++;
        if (!("numSteps" in singleTaskData))
          singleTaskData.numSteps = 1;
      }
      if (!("numStepsCompleted" in singleTaskData))
        singleTaskData.numStepsCompleted = 0;
      taskObjects[taskObjectList[taskListKey]] = singleTaskData;
    }
    return [taskObjects, totalCount];
  }
}

class NewTaskButton extends React.Component {
  render() {
    let searchId = this.props.id in splitTasks ? this.props.id + this.props.userType : this.props.id;
    let disableFlag = searchId in predefinedTasks ? this.props.limitTaskList[predefinedTasks[searchId].taskId] ? true : false : false;
    if (!disableFlag) {
      return (
        <Button onClick={() => this.props.clickEvent(this.props.id)}>
          {this.props.children}
        </Button>
      );
    } else {
      return (
        <Popover title={null} content={this.props.children.props.children + " is already in progress."} mouseEnterDelay={0.5}>
          <Button disabled={true}>
            {this.props.children}
          </Button>
        </Popover>
      );
    }
  }
}

class RequestInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullTaskList: null,
      currentTaskId: "",
      currentTopLayer: "",
      loaded: false,
      saveData: {},
      addTaskOpen: false,
      currentAddTask: "chooseTaskType",
      backTaskTree: [],
      taskAdded: false,
      numberOfTasks: 0,
      limitedTasks: JSON.parse(JSON.stringify(limitedTasks)),
      userType: '',
    };

    this.updateSaveData = this.updateSaveData.bind(this);
    this.updateTaskData = this.updateTaskData.bind(this);
    this.deleteTaskData = this.deleteTaskData.bind(this);
    this.switchTaskOpen = this.switchTaskOpen.bind(this);
    this.updateChangeTask = this.updateChangeTask.bind(this);
    this.changeCurrentAdd = this.changeCurrentAdd.bind(this);
    this.backAddPage = this.backAddPage.bind(this);
    this.addNewTask = this.addNewTask.bind(this);
    this.resetAddressBar = this.resetAddressBar.bind(this);
    this.taskReference = React.createRef();
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: '/getTasklist'
    })
    .then((response) => {
      if (response !== null
        && response.data.taskData !== null
        && typeof response.data.taskData.Item !== "undefined"
        && ("nonServiceRequest" in response.data.taskData.Item
          || "serviceRequest" in response.data.taskData.Item))
      {
        let userTasks = response.data.taskData.Item;
        let taskListData = [];
        if ("nonServiceRequest" in userTasks) {
          Object.keys(userTasks.nonServiceRequest).map(function(nsTask, nsIndex){return [nsTask.split("_")[0], nsIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(nsTask) {
            let newTask = userTasks.nonServiceRequest[Object.keys(userTasks.nonServiceRequest)[nsTask[1]]];
            newTask.taskObjectName = Object.keys(userTasks.nonServiceRequest)[nsTask[1]];
            taskListData.push(newTask);
          });
        }
        if ("serviceRequest" in userTasks) {
          Object.keys(userTasks.serviceRequest).map(function(sTask, sIndex){return [sTask.split("_")[0], sIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(sTask) {
            let newTask = userTasks.serviceRequest[Object.keys(userTasks.serviceRequest)[sTask[1]]];
            newTask.taskObjectName = Object.keys(userTasks.serviceRequest)[sTask[1]];
            taskListData.push(newTask);
          });
        }
        let newTaskList = taskList(this.props.taskId, taskListData, function(){}, function(){}, this.state.saveData, null);
        let taskListCount = newTaskList[2];

        let newLimitedTasks = JSON.parse(JSON.stringify(this.state.limitedTasks));
        newTaskList[6].forEach(function(currentLimited){
          newLimitedTasks[currentLimited] = true;
        });

        this.setState({fullTaskList: taskListData, numberOfTasks: taskListCount, limitedTasks: newLimitedTasks,
          loaded: true, currentTaskId: this.props.taskId, currentTopLayer: newTaskList[8], userType: response.data.userType});
      }
      else {
        this.setState({fullTaskList: [], loaded: true, userType: response.data.userType});
      }
    });
  }

  resetAddressBar(taskId = "") {
    this.props.history.push(typeof taskId !== "undefined" ? taskId !== null ? taskId.length > 0 ? '/tasks' + '/' + taskId : '/tasks' : '/tasks' : '/tasks');
  }

  updateSaveData(data, updateMode = "save") {
    if (updateMode === "save") {
      let saveData = JSON.parse(JSON.stringify(this.state.saveData));
      Object.keys(data).forEach(function(value){
        saveData[value] = data[value];
      });
      this.setState({saveData});
    } else if (updateMode === "delete") {
      let saveData = JSON.parse(JSON.stringify(this.state.saveData));
      data.forEach(function(value){
        if (value in saveData)
          delete saveData[value];
      });
      this.setState({saveData});
    }
  }

  updateTaskData(updTaskData, embedTask = "", embedTaskData = [], taskDeleteList = [], isNewTopTask = false) {
    let taskData = JSON.parse(JSON.stringify(updTaskData));
    axios({
      method: 'get',
      url: '/getTasklist'
    })
    .then((response) => {
      let userTasks = response.data.taskData.Item;
      let taskListData = [];
      if ("nonServiceRequest" in userTasks) {
        Object.keys(userTasks.nonServiceRequest).map(function(nsTask, nsIndex){return [nsTask.split("_")[0], nsIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(nsTask) {
          let newTask = userTasks.nonServiceRequest[Object.keys(userTasks.nonServiceRequest)[nsTask[1]]];
          newTask.taskObjectName = Object.keys(userTasks.nonServiceRequest)[nsTask[1]];
          newTask.servOrNo = "nonServiceRequest";
          taskListData.push(newTask);
        });
      }
      if ("serviceRequest" in userTasks) {
        Object.keys(userTasks.serviceRequest).map(function(sTask, sIndex){return [sTask.split("_")[0], sIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(sTask) {
          let newTask = userTasks.serviceRequest[Object.keys(userTasks.serviceRequest)[sTask[1]]];
          newTask.taskObjectName = Object.keys(userTasks.serviceRequest)[sTask[1]];
          newTask.servOrNo = "serviceRequest";
          taskListData.push(newTask);
        });
      }

      let topLayer = "topLayerId" in taskData ? taskData.topLayerId.split("_")[0] : "";
      
      let doUpdate = updateTask(taskData, taskListData);
      if ((!isNewTopTask && !doUpdate[3]) || isNewTopTask)
        taskListData = doUpdate[0];
      let totalCount = this.state.numberOfTasks;

      let fullTaskComplete = doUpdate[4];
      
      if (embedTask.length > 0) {
        if (taskDeleteList.length) {
          for (let deleteTaskIndex = 0; deleteTaskIndex < taskDeleteList.length; deleteTaskIndex++) {
            doUpdate = deleteTask(this.resetAddressBar, this.state.currentTaskId, taskDeleteList[deleteTaskIndex], taskListData, 0, true, embedTask);
            taskListData = doUpdate[0];
            totalCount = doUpdate[1];
          }
        }
        for (let embedTaskIndex = 0; embedTaskIndex < embedTaskData.length; embedTaskIndex++) {
          doUpdate = updateTask(embedTaskData[embedTaskIndex], taskListData, embedTask);
          taskListData = doUpdate[0];
          totalCount += (+doUpdate[3]);
        }
      }

      let nonServiceRequests = {};
      taskListData.forEach(function(currentTask, taskIndex){
        if (currentTask.servOrNo === "nonServiceRequest") {
          nonServiceRequests[currentTask.taskObjectName] = currentTask;
          delete nonServiceRequests[currentTask.taskObjectName].servOrNo;
          if ("topLayerId" in nonServiceRequests[currentTask.taskObjectName])
            delete nonServiceRequests[currentTask.taskObjectName].topLayerId;
          delete nonServiceRequests[currentTask.taskObjectName].taskObjectName;
        }
      });
      let serviceRequests = {};
      taskListData.forEach(function(currentTask, taskIndex){
        if (currentTask.servOrNo === "serviceRequest") {
          serviceRequests[currentTask.taskObjectName] = currentTask;
          delete serviceRequests[currentTask.taskObjectName].servOrNo;
          if ("topLayerId" in serviceRequests[currentTask.taskObjectName])
            delete serviceRequests[currentTask.taskObjectName].topLayerId;
          delete serviceRequests[currentTask.taskObjectName].taskObjectName;
        }
      });
      
      var url = "/updateTasklist";
      axios.post(url, {nonServiceRequests, serviceRequests, fullTaskComplete, isSpecial: topLayer in updateOnCompleteTasks, topLayer, updateSpecialData: taskData})
      .then((response) => {
        if (embedTask.length > 0)
          this.setState({fullTaskList: taskListData, numberOfTasks: totalCount});
        else
          this.setState({fullTaskList: taskListData});
      });
    });
  }

  deleteTaskData(deleteVal, fromDeleteButton = true) {
    let taskId = deleteVal;
    let removedTask = JSON.parse(JSON.stringify(locateTask(taskId, this.state.fullTaskList, {}, "")));
    axios({
      method: 'get',
      url: '/getTasklist'
    })
    .then((response) => {
      let userTasks = response.data.taskData.Item;
      let taskListData = [];
      if ("nonServiceRequest" in userTasks) {
        Object.keys(userTasks.nonServiceRequest).map(function(nsTask, nsIndex){return [nsTask.split("_")[0], nsIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(nsTask) {
          let newTask = userTasks.nonServiceRequest[Object.keys(userTasks.nonServiceRequest)[nsTask[1]]];
          newTask.taskObjectName = Object.keys(userTasks.nonServiceRequest)[nsTask[1]];
          newTask.servOrNo = "nonServiceRequest";
          taskListData.push(newTask);
        });
      }
      if ("serviceRequest" in userTasks) {
        Object.keys(userTasks.serviceRequest).map(function(sTask, sIndex){return [sTask.split("_")[0], sIndex]}).sort(function(a, b) {try{return parseInt(a[0])-parseInt(b[0])} catch(err) {return a[0].toString().localeCompare(b[0].toString())}}).forEach(function(sTask) {
          let newTask = userTasks.serviceRequest[Object.keys(userTasks.serviceRequest)[sTask[1]]];
          newTask.taskObjectName = Object.keys(userTasks.serviceRequest)[sTask[1]];
          newTask.servOrNo = "serviceRequest";
          taskListData.push(newTask);
        });
      }
      taskListData = deleteTask(this.resetAddressBar, this.state.currentTaskId, taskId, taskListData);

      let nonServiceRequests = {};
      taskListData[0].forEach(function(currentTask, taskIndex){
        if (currentTask.servOrNo === "nonServiceRequest") {
          nonServiceRequests[currentTask.taskObjectName] = currentTask;
          delete nonServiceRequests[currentTask.taskObjectName].servOrNo;
          delete nonServiceRequests[currentTask.taskObjectName].taskObjectName;
        }
      });
      let serviceRequests = {};
      taskListData[0].forEach(function(currentTask, taskIndex){
        if (currentTask.servOrNo === "serviceRequest") {
          serviceRequests[currentTask.taskObjectName] = currentTask;
          delete serviceRequests[currentTask.taskObjectName].servOrNo;
          delete serviceRequests[currentTask.taskObjectName].taskObjectName;
        }
      });

      let stateObj = {fullTaskList: taskListData[0], numberOfTasks: taskListData[1]};
      let strippedId = taskId.split("_")[0];
      if (strippedId in limitedTasks) {
        let newLimitedTasks = JSON.parse(JSON.stringify(this.state.limitedTasks));
        newLimitedTasks[strippedId] = false;
        stateObj.limitedTasks = newLimitedTasks;
      }
      this.setState(stateObj);
      if (taskId === this.state.currentTopLayer)
        this.resetAddressBar();

      if (fromDeleteButton && strippedId in specialTasks) {
        var url = "/handleSpecialTask/Delete";
        axios.post(url, {newTaskName: strippedId, newTaskData: removedTask})
        .then((response) => {
          url = "/updateTasklist";
          axios.post(url, {nonServiceRequests, serviceRequests})
          .then(() => {
          });
        });
      } else {
        url = "/updateTasklist";
        axios.post(url, {nonServiceRequests, serviceRequests})
        .then(() => {
        });
      }
    });
  }

  switchTaskOpen() {
    if (!this.state.addTaskOpen && this.state.taskAdded)
      this.setState({addTaskOpen: true, currentAddTask: "chooseTaskType", backTaskTree: [], taskAdded: false});
    else
      this.setState({addTaskOpen: !this.state.addTaskOpen});
  }

  changeCurrentAdd(newId) {
    let newTree = this.state.backTaskTree.slice();
    newTree.push(this.state.currentAddTask)
    this.setState({currentAddTask: newId, backTaskTree: newTree});
  }
  backAddPage() {
    if (this.state.backTaskTree.length > 0) {
      let newTree = this.state.backTaskTree.slice();
      let newPage = newTree.pop();
      this.setState({currentAddTask: newPage, backTaskTree: newTree});
    } else {
      this.setState({addTaskOpen: false});
    }
  }

  addNewTask(newId) {
    /* 
    Add the code here for inserting into mysql and get the insertion id
    */
    this.setState({addTaskOpen: !this.state.addTaskOpen, taskAdded: true});
    let jobRequestId = 0;
    let newState = {};
    let newNumberOfTasks = this.state.numberOfTasks;
    let newTaskId = newId in splitTasks ? newId + this.state.userType : newId;
    let splitlessId = newId;
    if (newTaskId in predefinedTasks) {
      if (predefinedTasks[newTaskId].taskId in limitedTasks) {
        let newLimitedTasks = JSON.parse(JSON.stringify(this.state.limitedTasks));
        newLimitedTasks[predefinedTasks[newTaskId].taskId] = true;
        newState.limitedTasks = newLimitedTasks;
      }
      
      if (splitlessId in specialTasks) {
        var url = "/handleSpecialTask/Add";
        axios.post(url, {newTaskName: splitlessId, newTaskData: predefinedTasks[newTaskId]})
        .then((response) => {
          if (response.data.ok) {
            let newTask = generateTask(response.data.newTaskData[splitlessId], newNumberOfTasks, jobRequestId);
            this.updateTaskData(newTask[0], "", [], [], true);
            newNumberOfTasks += newTask[1];
            newState.numberOfTasks = newNumberOfTasks;
          }
          this.setState(newState);
        });
      } else {
        let newTask = generateTask(newTaskId, newNumberOfTasks, jobRequestId);
        this.updateTaskData(newTask[0], "", [], [], true);
        newNumberOfTasks += newTask[1];
        newState.numberOfTasks = newNumberOfTasks;
        this.setState(newState);
      }
    } else {
      message.error('This task is currently unavailable');
      this.setState(newState);
    }
  }

  updateChangeTask(taskId, topLayerId, selectedTaskId, link) {
    if (selectedTaskId !== taskId) {
      this.props.history.push(link);
      this.setState({currentTaskId: taskId, currentTopLayer: topLayerId});
    } else {
      this.props.history.push("/tasks");
      this.setState({currentTaskId: "", currentTopLayer: ""});
    }
  }

  render() {
    //console.log(this.state.fullTaskList);
    /*if (true) {
      let taskListData = 
      [{taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {
        task1: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}},
        task2: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}},
        task3: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {
          task4: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}},
          task5: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}}
        }},
        task6: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {
          task7: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}},
          task8: {taskName: "TEST", isCompleted: true, numSteps: "4", numStepsCompleted: "3", subtasks: {}}
        }}
      }}];*/
    if (this.state.loaded) {
      let newTaskList = null;
      let selectedTask = null;
      let renderData = null;
      
      if (this.state.fullTaskList !== null) {
        newTaskList = taskList(this.props.taskId, this.state.fullTaskList, this.deleteTaskData, this.updateChangeTask, this.state.saveData, null);
        renderData = newTaskList[0];

        selectedTask = this.props.taskId !== null ? locateTask(this.props.taskId, this.state.fullTaskList, newTaskList[7], "") : null;
      }
      return (
        <>
          <Header style={{ backgroundColor: 'transparent' }}>
            <Button type="primary" onClick={this.switchTaskOpen} style={{float:"right", marginTop: '15px', padding: '10px 25px 40px 25px', fontSize:"20px"}}>
              Add
            </Button>
            <Modal
              visible={this.state.addTaskOpen}
              title={<Button type="primary" onClick={this.backAddPage}>Back</Button>}
              onCancel={this.switchTaskOpen}
              footer={null}
            >
              {{
                chooseTaskType:
                <>
                  <NewTaskButton id={"addService"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}>Service</NewTaskButton>
                  <NewTaskButton id={"addNonService"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}>Non-Service</NewTaskButton>
                </>,
                  addService:
                  <>
                    <NewTaskButton id={"addServiceProperties"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}><span id={"addServiceProperties"}>Properties</span></NewTaskButton>
                  </>,
                    addServiceProperties:
                    <>
                      <NewTaskButton id={"addServicePropertiesJob"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addServicePropertiesJob"}>Create Job Report</span></NewTaskButton>
                    </>,
                      addServicePropertiesJob:
                      <>
                        Create Job Report
                      </>,
                  addNonService:
                  <>
                    <NewTaskButton id={"addNonServiceRegistration"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}><span id={"addNonServiceRegistration"}>Registration</span></NewTaskButton>
                  </>,
                    addNonServiceRegistration:
                    <>
                      <NewTaskButton id={"addNonServiceRegistrationProperty"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}><span id={"addNonServiceRegistrationProperty"}>Property</span></NewTaskButton>
                      <NewTaskButton id={"addNonServiceRegistrationService"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}><span id={"addNonServiceRegistrationService"}>Service</span></NewTaskButton>
                      <NewTaskButton id={"addNonServiceRegistrationCompany"} limitTaskList={this.state.limitedTasks} clickEvent={this.changeCurrentAdd}><span id={"addNonServiceRegistrationCompany"}>Company</span></NewTaskButton>
                      <NewTaskButton id={"addNonServiceRegistrationUser"} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationUser"}>User</span></NewTaskButton>
                    </>,
                      addNonServiceRegistrationProperty:
                      <>
                        <NewTaskButton id={"addNonServiceRegistrationPropertyNewProperty"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationPropertyNewProperty"}>Register New Property</span></NewTaskButton>
                        <NewTaskButton id={"addNonServiceRegistrationPropertyNewUnit"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationPropertyNewUnit"}>Register New Unit</span></NewTaskButton>
                        <NewTaskButton id={"addNonServiceRegistrationPropertyNewPeople"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationPropertyNewPeople"}>Add New Tenants/Owners</span></NewTaskButton>
                        <NewTaskButton id={"addNonServiceRegistrationPropertySpreadsheet"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationPropertySpreadsheet"}>Multiple Properties (Spreadsheets)</span></NewTaskButton>
                      </>,
                        addNonServiceRegistrationPropertyNewProperty:
                        <>
                          Register New Property
                        </>,
                        addNonServiceRegistrationPropertyNewUnit:
                        <>
                          Register New Unit
                        </>,
                        addNonServiceRegistrationPropertyNewPeople:
                        <>
                          Add New Tenants/Owners
                        </>,
                        addNonServiceRegistrationPropertySpreadsheet:
                        <>
                          Multiple Properties (Spreadsheets)
                        </>,
                      addNonServiceRegistrationService:
                      <>
                        <NewTaskButton id={"addNonServiceRegistrationServiceSelfVendor"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationServiceSelfVendor"}>Register Self as Vendor</span></NewTaskButton>
                      </>,
                        addNonServiceRegistrationServiceSelfVendor:
                        <>
                          Register Self as Vendor
                        </>,
                      addNonServiceRegistrationCompany:
                      <>
                        <NewTaskButton id={"addNonServiceRegistrationCompanyRegisterCompany"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationCompanyRegisterCompany"}>Register Company</span></NewTaskButton>
                        <NewTaskButton id={"addNonServiceRegistrationCompanyRegisterEmployees"} userType={this.state.userType} limitTaskList={this.state.limitedTasks} clickEvent={this.addNewTask}><span id={"addNonServiceRegistrationCompanyRegisterEmployees"}>Register New Employees</span></NewTaskButton>
                      </>,
                        addNonServiceRegistrationCompanyRegisterCompany:
                        <>
                          Register Company
                        </>,
                        addNonServiceRegistrationCompanyRegisterEmployees:
                        <>
                          Register New Employees
                        </>,
                      addNonServiceRegistrationUser:
                      <>
                        Register User Info
                      </>,
              }
              [this.state.currentAddTask]}
            </Modal>
          </Header>
          <Layout style={{ backgroundColor: 'transparent' }}>
          {newTaskList !== null ? 
            <Sider width={selectedTask !== null ? '40%' : '100%'} className="cardOutline menuSider" style={{textAlign: 'center', backgroundColor: 'white', marginRight:'10px', overflowY:'auto', overflowX:'hidden', direction: 'rtl'}}>
              {renderData.length > 0 ? 
              <Menu
                theme="light"
                selectable="false"
                selectedKeys={[this.props.taskId !== null ? this.props.taskId.length > 0 ? this.props.taskId : null : null]}
                defaultOpenKeys={newTaskList[4]}
                mode="inline"
                style={{direction: 'ltr'}}
              >
                {renderData}
              </Menu> :
              <Button type="primary" onClick={this.switchTaskOpen} style={{display: 'inline', marginTop: '280px', padding: '10px 25px 40px 25px', fontSize:"20px"}}>
                Add
              </Button>}
            </Sider> :
          null}
            {selectedTask !== null ? <Content className='requestTaskCard cardOutline'>
              <TaskDisplay
                key={this.props.taskId}
                saveData={this.state.saveData}
                updateSaveData={this.updateSaveData}
                updateTaskData={this.updateTaskData}
                deleteTaskData = {this.deleteTaskData}
                displayTask={selectedTask}
                completedTasks={newTaskList[7]}
                userType={this.state.userType}
                taskLayerHistory={newTaskList[4]}
              />
            </Content> : null}
          </Layout>
        </>
      );
    } else
      return <div style={{marginTop: '450px'}}><LoadScreen/></div>;
  }
}

class RequestListLayout extends React.Component {
  componentDidMount() {
    document.title = "Tasks";
  }
  render() {
    let taskId = typeof this.props.match.params.req_ID !== "undefined" ? this.props.match.params.req_ID : null;
    return <div>
      {/*<Header style={{ backgroundColor: 'transparent' }}>Task List</Header>*/}
      <RequestInterface taskId={taskId} history={this.props.history}/>
    </div>;
  }
}

export { RequestListLayout, NewTaskButton, updateTask, predefinedTasks, splitTasks, specialTasks, limitedTasks };
