import React, { Component } from "react";
import { Button } from "antd";

class BtnUnder500 extends Component {
  constructor(props) {
    super(props);
    this.nextAction = this.nextAction.bind(this);
    this.pause = this.pause.bind(this);
  }

  nextAction() {
    this.props.goNext();
  }
  pause() {
    this.props.goEnd();
  }
  render() {
    return (
      <div>
        <div className="g-text text-center mb-2">
          If you are ready to complete the job, please select below to continue
          the work order.
        </div>
        <div className="text-center">
          <Button
            type="primary"
            className="woBtnFixed"
            onClick={this.nextAction}
          >
            Continue
          </Button>
        </div>
        <br />
        <br />
        <div className="g-text text-center mb-2">
          If you require materials for the job, please select below to pause the
          work order.
        </div>
        <div className="text-center">
          <Button type="primary" className="woBtnFixed" onClick={this.pause}>
            Pause to Purchase Materials
          </Button>
        </div>
      </div>
    );
  }
}

class BtnOver500 extends Component {
  constructor(props) {
    super(props);
    this.getApproval = this.getApproval.bind(this);
  }
  getApproval() {
    //TODO: Send request
    this.props.goEnd();
  }
  render() {
    return (
      <div className='text-center'>
        <p>
          An estimated cost of ${this.props.totalCost} requires the property
          manager's approval.
        </p>
        <p>
          Please click below to end this session and sent the request for
          approval.
        </p>
        <div className="text-center mt-4">
          <Button type="primary" onClick={this.getApproval}>
            End Session and Send Request for Approval
          </Button>
        </div>
      </div>
    );
  }
}

class CostOptions extends Component {
  constructor(props) {
    super(props);
    this.state = { totalCost: this.props.totalCost };
    this.goEnd = this.goEnd.bind(this);
    this.goNext = this.goNext.bind(this);
  }
  goEnd() {
    this.props.goEnd();
  }
  goNext() {
    this.props.goNext();
  }
  render() {
    let toRender;
    if (this.props.totalCost >= 500) {
      toRender = (
        <BtnOver500
          totalCost={this.state.totalCost}
          goEnd={this.goEnd}
          goNext={this.goNext}
        />
      );
    } else if (this.state.totalCost < 500) {
      toRender = (
        <BtnUnder500
          totalCost={this.state.totalCost}
          goEnd={this.goEnd}
          goNext={this.goNext}
        />
      );
    }

    return (
      <div>
        <h3 className="text-center">Please select your next action</h3>
        <br />
        {toRender}
      </div>
    );
  }
}

export default CostOptions;
