import React,{useState} from "react";
import moment from "moment";
import axios from "axios";

import { Button ,Table, Space, Alert ,message,DatePicker,Popconfirm} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {vendorProvinces} from '../../../StringData/VendorObjects'
import LoadScreen from '../../../Components/LoadScreen'
import { Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col'
import {Searchbar} from '../../../Components/Searchbar/Searchbar'
import {createUseStyles} from 'react-jss'
import RequestContext from "../../MaintenanceRequest/RequestContext";
const cancelToken = axios.CancelToken.source();

/*
update update lease
update delete lease

*/

class AddLeases extends React.Component{
	static contextType = RequestContext;
	constructor(props) {
		super(props);
		this.state ={
		leaseList : [],
		page:'view',
		submitting:false,
		unitList:[],
		loaded:false
		}
	}
	

	componentDidMount = () => {
		axios.post("intake/get/leaseIntake",{propertyid:this.context.propertyid})
		.then((response) => {
			this.setState({unitList:response.data.units});

			let existingLeases = response.data.units.filter((u) => u.lease_id !== null);
			this.setState({leaseList:existingLeases,loaded:true});

		})
	}

	submitlist = () => {
		this.setState({submitting:true});
		let updatedTasks = {...this.context.tasks, leaseIntake:1 }

		axios.post("/intake/update/taskList",
		{
			propertyid: this.context.propertyid,
			updatedTasks: updatedTasks
		})
		.then((response) => {
			this.context.updateTaskList(response.data.tasks);
			message.success("Leases have been successfully connected")
			this.props.onCancel();
		});
	}
	
	
	
	addNewLease = (newLease) => {
		if(this.state.page === 'Add'){
			this.setState({ leaseList: [...this.state.leaseList, newLease ], page:"view" });
		}
		else{ 
			let rows = [... this.state.leaseList];
			rows = rows.filter((o) => o.unit_id !== newLease.unit_id);
	
			this.setState({leaseList:[...rows, newLease],page:"View"});
	
		}
	}
	
	deleterow = (unit_id) => {
		this.setState({
			leaseList:this.state.leaseList.filter(e  => e.unit_id !== unit_id)
		});
	}
	
	editRow =(unit_id) => {
		const lease = this.state.leaseList.find((lease) => lease.unit_id === unit_id);
		this.setState({editLease:lease}, () => this.setState({page:'Edit'}));
	}
	
	openNewLeaseForm = () => this.setState({page:"Add"})
	openEditLeaseForm = () => this.setState({page:"Edit"})
	openExisitngLeaseForm = () => this.setState({page:"Existing"})
	backToView = () => this.setState({page:"view"})
	setExistingleaseList = (owners) => this.setState({existingleaseList:owners});
	
	render() {

		if(this.state.loaded === false){
			return( <div className='text-center'><LoadScreen small/> </div>)
		}
		
		let doneUnits = [];
		this.state.leaseList.forEach((l) => doneUnits.push(parseInt(l.unit_id)));
		const freeUnits = this.state.unitList.filter((u) => !doneUnits.includes(u.unit_id))
	
		const columns = [
		{title: 'Unit',dataIndex: 'unit_id',key: 'unit_id',
		 render:((t,record)=> this.state.unitList.find( u => u.unit_id === parseInt(t)).unit_name)
		
		},  
		{title: 'Type',key: 'lease_type',dataIndex: 'lease_type'},
		{title: 'Rent',key: 'rent',dataIndex: 'rent'},
		{title: 'Deposit',key: 'deposit',dataIndex: 'deposit'},
		{title: 'Start',key: 'start_date',dataIndex: 'start_date',render: t => moment(t).format("MM/DD/YYYY")},
		{title: 'End',key: 'end_date',dataIndex: 'end_date',render: t => moment(t).format("MM/DD/YYYY")},
		{
			title: 'Actions',
			key: 'action',
			render: (text,record) => (
			<Space size="middle">
				<a data-id={record.email} onClick={() => this.editRow(record.unit_id)}><EditOutlined /></a>
				<Popconfirm
					title="Delete this lease?"
					onConfirm={() => this.deleterow(record.unit_id)}
					okText="Confirm"
					cancelText="Cancel"
				>
					<DeleteOutlined />
				</Popconfirm>
			</Space>
			),
		},
		];
	
		if(this.state.page === 'Add' || this.state.page === 'Edit'){
		return( <AddNewLease editLease={this.state.editLease || null} unitList={freeUnits} page={this.state.page} backToView={this.backToView} addNewLease={this.addNewLease} />)
		
		}
		else{
			return(
				<div>
				<div className="text-right mb-2"> 
					<Button type="primary" onClick ={this.openNewLeaseForm} disabled={freeUnits.length === 0}>
						Add New Lease
					</Button>
				</div>
				<div>< Table columns={columns} dataSource={this.state.leaseList} pagination={false} size='small' /></div>
				<div  className='mt-4 text-right'>
					<Button onClick={this.submitlist} disabled={this.state.leaseList.length === 0 || this.state.submitting} type='primary'>Submit</Button>
				</div>
				</div>
			)
		}
	}
}



class AddNewLease extends React.Component{
	static contextType = RequestContext;
	constructor(props){
		super(props);
		this.state = {
			lease_id:null,
			unit_id: null,
			lease_type: "", 
			movein_date:null,
			start_date:null, 
			end_date:null, 
			rent:null, 
			deposit:null, 	
		}
	}	
	submitLease = () => {
		let lease = { ...this.state};

		if(this.props.page == 'Edit'){
			axios.post("/intake/property/update/lease",
			{
				lease: lease, 
			})
			.then((response) => {
				this.props.addNewLease(response.data.lease);
			})
			.catch((err) => console.log(err));
		}
		else{	
			axios.post("/intake/property/add/lease",
			{
				lease: lease, 
			})
			.then((response) => {
				this.props.addNewLease(response.data.lease);
			})
			.catch((err) => console.log(err));
		}
	}

	componentDidMount = () => {
		if(this.props.page === 'Edit'){
			this.setState({...this.props.editLease});
		}
	}

	render(){
		let verified = this.state.unit_id && this.state.start_date && this.state.end_date 
		&& this.state.movein_date && this.state.lease_type !== "" && this.state.rent && this.state.deposit;

		return(
			<div>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label> Lease Start Date </Form.Label>
						<DatePicker onChange={(e) => this.setState({start_date: e})} value={this.state.start_date} />
					</Form.Group>
					
					<Form.Group as={Col}>
						<Form.Label> Lease End Date </Form.Label>
						<DatePicker onChange={(e) => this.setState({end_date: e})} value={this.state.end_date} />
					</Form.Group>
					
					<Form.Group as={Col}>	
						<Form.Label> Move in Date </Form.Label>
						<DatePicker onChange={(e) => this.setState({movein_date: e})} value={this.state.movein_date} />

					</Form.Group>
				</Form.Row>
				
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label> Payment Type </Form.Label>
						<select  className="form-control" onChange={(e) => this.setState({ lease_type: e.target.value})} value = {this.state.lease_type}>
							<option value="">Lease Type</option>
							<option value="Fixed Term"> Fixed Term </option>
							<option value="Month-to-Month"> Month-to-Month </option>
						</select>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label> Rent Amount </Form.Label>
						<Form.Control type="number" min={0} placeholder={0} defaultValue={this.state.rent} 
						onChange={(e) => this.setState({rent:e.target.value})}/>						
					</Form.Group>

					<Form.Group as={Col}>					
						<Form.Label> Deposit Amount </Form.Label>
						<Form.Control type="number" min={0} placeholder={0} defaultValue={this.state.deposit} 
						onChange={(e) => this.setState({deposit:e.target.value})}/>
					</Form.Group>
				</Form.Row>
				
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label> Unit </Form.Label>
						<select className="form-control" onChange={(e) => this.setState({ unit_id: e.target.value  })} value={this.state.unit_id}>
							<option value={null} hidden>Select Unit</option>
							{this.props.unitList.map((u) => (
								<option value={u.unit_id}>{u.unit_name}</option>
							))}
						</select>
					</Form.Group>
				</Form.Row>
				<div className='d-flex justify-content-between'>

					<Button type='secondary' onClick={this.props.backToView} danger>Discard Changes</Button>
					<Button type='primary' onClick={this.submitLease} disabled={!verified}>Save Lease</Button>
				</div>
			</div>
		)
	}

}

export default AddLeases;