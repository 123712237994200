import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import Col from 'react-bootstrap/Col';
import {
	Result,
	Space,
	DatePicker,
	Button,
	message,
	Modal,
	Popconfirm,
	Card
} from 'antd';

const ManualReservation = props => {
	const [guestName, setGuestName] = useState('');
	const [phone, setPhone] = useState('');
	const [adultsNum, setAdultsNum] = useState('');
	const [childrenNum, setChildrenNum] = useState('');
	const [infantsNum, setInfantsNum] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [nightsNum, setNightsNum] = useState('');
	const [bookingDate, setBookingDate] = useState('');
	const [unit, setUnit] = useState('');
	const [unitId, setUnitId] = useState('');
	const [totalPay, setTotalPay] = useState('');
	const [airBnbUnits, setAirBnbUnits] = useState([]);

	useEffect(() => {
		axios.post('/getAirBnbUnit').then(res => {
			console.log(res.data.shortTermUnits);
			setAirBnbUnits(res.data.shortTermUnits);
		});
	}, []);

	const code = length => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};
	const numberFormat = value => {
		let newNumber = '';
		let phoneNumber = ('' + value).replace(/[^\d]/g, '');
		for (let i = 0; i < phoneNumber.length; i++) {
			if (i == 0) {
				newNumber = '(';
			} else if (i == 3) {
				newNumber = newNumber + ')';
			} else if (i == 6) {
				newNumber = newNumber + '-';
			}

			newNumber = newNumber + phoneNumber[i];
		}
		return newNumber;
	};

	const handleSubmit = () => {
		axios
			.post('/addManualReservation', {
				ConfirmationCode: code(10),
				GuestName: guestName,
				Phone: phone,
				NumOfAdults: adultsNum,
				NumOfChildren: childrenNum,
				NumOfInfants: infantsNum,
				StartDate: startDate,
				EndDate: endDate,
				NumOfNights: nightsNum,
				BookingDate: bookingDate,
				UnitName: unit,
				TotalPay: totalPay
			})
			.then(res => {
				if (res.data.ok) {
					alert('Successfully booked.');
					window.location.reload();
				}
			});
	};

	return (
		<Modal
			destroyOnClose={true}
			width={530}
			title='Manual AirBnb Reservation'
			open={props.showModal}
			onCancel={props.closeModal}
			footer={false}
		>
			<Form>
				<Form.Row>
					<Form.Group as={Col} md={4}>
						<Form.Label className='form-label'>Guest Name</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='guestName'
							id='guestName'
							type='text'
							value={guestName}
							onChange={e => setGuestName(e.target.value)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label className='form-label'>Phone Number</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='phone'
							id='phone'
							maxlength='13'
							type='text'
							value={phone}
							onChange={e => setPhone(numberFormat(e.target.value))}
						/>
					</Form.Group>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Booking Date</Form.Label>
						&nbsp;&nbsp;
						<DatePicker
							onChange={(date, dateString) => {
								setBookingDate(dateString);
							}}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Start Date</Form.Label>
						&nbsp;&nbsp;
						<DatePicker
							onChange={(date, dateString) => {
								setStartDate(dateString);
							}}
						/>
					</Form.Group>

					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>End Date</Form.Label>
						&nbsp;&nbsp;
						<DatePicker
							onChange={(date, dateString) => {
								setEndDate(dateString);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Number of Nights</Form.Label>
						<Form.Control
							editable='true'
							required
							className='form-text'
							name='nightsNum'
							id='nightsNum'
							type='text'
							value={nightsNum}
							onChange={e => setNightsNum(e.target.value)}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>No. of Adults </Form.Label>
						<Form.Control
							required
							className='form-text'
							name='adultsNum'
							id='adultsNum'
							value={adultsNum}
							onChange={e => setAdultsNum(e.target.value)}
						/>
					</Form.Group>

					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>No. of children</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='childrenNum'
							type='text'
							value={childrenNum}
							onChange={e => setChildrenNum(e.target.value)}
						/>
					</Form.Group>

					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>No. of infants</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='infantsNum'
							type='text'
							value={infantsNum}
							onChange={e => setInfantsNum(e.target.value)}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Unit</Form.Label>
						<select
							name='province'
							className='form-control'
							defaultValue={unit}
							onChange={e => {
								setUnit(e.target.value);
							}}
						>
							<option value={''}>Select Unit</option>
							{airBnbUnits.map(airBnbUnit => (
								<option
									value={airBnbUnit.listing_name}
									key={airBnbUnit.unit_id}
								>
									{airBnbUnit.listing_name}
								</option>
							))}
						</select>
					</Form.Group>
					<Form.Group as={Col} sm={2} md={4}>
						<Form.Label className='form-label'>Total Pay</Form.Label>
						<Form.Control
							required
							className='form-text'
							name='totalPay'
							type='text'
							value={totalPay}
							onChange={e => setTotalPay(e.target.value)}
						/>
					</Form.Group>
				</Form.Row>

				<br></br>
				<div className='text-right'>
					<Button
						style={{ marginRight: 8 }}
						onClick={props.closeModal}
						type='primary'
					>
						Cancel
					</Button>
					<Button onClick={handleSubmit} type='primary'>
						Submit
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default ManualReservation;
