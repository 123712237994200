import React, { useEffect, useState } from 'react';
import { UserStateContext } from '../../Context/UserContext';
import { Modal, ModalTitle } from 'react-bootstrap';
import { Modal as AntModal } from 'antd';
import axios from 'axios';

import { Button, Avatar, Input, Tooltip, Badge } from 'antd';
import LoadScreen from '../../Components/LoadScreen';
import './communication.css';
import { Link } from 'react-router-dom';
import {
	CalendarOutlined,
	PaperClipOutlined,
	CloseCircleTwoTone,
	FileTwoTone,
	LeftCircleTwoTone,
	RightCircleTwoTone,
	FileTextTwoTone,
	FilePdfTwoTone,
	FileExcelTwoTone,
	PlusCircleTwoTone,
	ToolOutlined,
	CheckCircleOutlined,
	CheckCircleFilled
} from '@ant-design/icons';
import Moment from 'react-moment';
import 'moment-timezone';
import { CommNotifications } from '../../layout/MainLayout/MainLayout';
import CommsBarCalendar from '../CommsBarCalendar';
import CommsAvailability from '../../Pages/Profile/MyProfile/Components/CommsAvailability';
import SelectJobRequests from '../../Pages/JobRequests/SelectJobRequests';
import CommsMaintenanceRequest from '../../Modals/JobRequest/CommsMaintenanceRequest';
const cancelToken = axios.CancelToken.source();
const k = new Date();
let displayDate = (
	k.getFullYear() +
	'-' +
	('0' + (k.getMonth() + 1)).slice(-2) +
	'-' +
	('0' + k.getDate()).slice(-2)
).toString();

class AttachmentsUploadDisplay extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let docLink = window.URL.createObjectURL(this.props.file);
		let renderData;
		switch (this.props.file.type.split('/')[0]) {
			case 'image':
				renderData = (
					<img style={{ display: 'block', height: '75px' }} src={docLink} />
				);
				break;
			case 'text':
				renderData = (
					<FileTextTwoTone
						style={{
							position: 'relative',
							top: '10px',
							display: 'block',
							fontSize: '50px'
						}}
					/>
				);
				break;
			default:
				switch (this.props.file.type.split('/')[1]) {
					case 'pdf':
						renderData = (
							<FilePdfTwoTone
								twoToneColor='#fe4d97'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.ms-excel':
						renderData = (
							<FileTextTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						renderData = (
							<FileExcelTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					default:
						renderData = (
							<FileTwoTone
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
				}
				break;
		}
		return (
			<Tooltip title={this.props.file.name}>
				<div
					style={{
						float: 'left',
						display: 'inline',
						width: '75px',
						height: '75px',
						border: '1px solid grey',
						borderRadius: '5px',
						overflow: 'hidden',
						backgroundColor: 'white'
					}}
				>
					<div
						style={{
							position: 'relative',
							zIndex: 1,
							top: '1px',
							left: '55px'
						}}
					>
						<CloseCircleTwoTone
							onClick={() => {
								this.props.removeFile(this.props.fileIndex);
							}}
						/>
					</div>
					<div
						onClick={() => {
							this.props.file.url = docLink;
							this.props.viewAttachment(this.props.file);
						}}
						style={{ position: 'relative', zIndex: 0, bottom: '22px' }}
					>
						{renderData}
					</div>
				</div>
			</Tooltip>
		);
	}
}
class AttachmentsDisplay extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let renderData;
		switch (this.props.file.type.split('/')[0]) {
			case 'image':
				renderData = (
					<img
						style={{ display: 'block', height: '75px' }}
						src={this.props.file.url}
					/>
				);
				break;
			case 'text':
				renderData = (
					<FileTextTwoTone
						style={{
							position: 'relative',
							top: '10px',
							display: 'block',
							fontSize: '50px'
						}}
					/>
				);
				break;
			default:
				switch (this.props.file.type.split('/')[1]) {
					case 'pdf':
						renderData = (
							<FilePdfTwoTone
								twoToneColor='#fe4d97'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.ms-excel':
						renderData = (
							<FileTextTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						renderData = (
							<FileExcelTwoTone
								twoToneColor='#6dd230'
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
					default:
						renderData = (
							<FileTwoTone
								style={{
									position: 'relative',
									top: '10px',
									display: 'block',
									fontSize: '50px'
								}}
							/>
						);
						break;
				}
				break;
		}
		return (
			<Tooltip title={this.props.file.name}>
				<div
					onClick={() => {
						this.props.viewAttachment(this.props.file);
					}}
					style={{
						float: this.props.side,
						display: 'inline',
						width: '75px',
						height: '75px',
						border: '1px solid grey',
						borderRadius: '5px',
						overflow: 'hidden',
						backgroundColor: 'white'
					}}
				>
					<div style={{ zIndex: 0, bottom: '22px' }}>{renderData}</div>
				</div>
			</Tooltip>
		);
	}
}
class AttachmentsFullDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			url: this.props.file.url
		};
	}

	componentDidMount() {
		if (
			(this.props.file.type.split('/')[0] !== 'text' &&
				this.props.file.type.split('/')[1] !== 'vnd.ms-excel') ||
			this.props.file.url.indexOf('blob:') === 0
		) {
			this.setState({ isLoading: false });
		} else {
			axios
				.post('/getAttachmentBlob', { url: this.props.file.url })
				.then((response, err) => {
					if (err) throw err;
					this.setState({
						isLoading: false,
						url: window.URL.createObjectURL(new Blob([response.data.blobData]))
					});
				});
		}
	}

	render() {
		let renderData;
		if (!this.state.isLoading) {
			switch (this.props.file.type.split('/')[0]) {
				case 'image':
					renderData = <img src={this.state.url} />;
					break;
				case 'text':
					renderData = (
						<object
							height='700'
							type='text/plain'
							data={this.state.url}
						></object>
					);
					break;
				default:
					switch (this.props.file.type.split('/')[1]) {
						case 'pdf':
							renderData = (
								<object
									height='700'
									type='application/pdf'
									data={this.state.url}
								></object>
							);
							break;
						case 'vnd.ms-excel':
							renderData = (
								<object
									height='700'
									type='text/plain'
									data={this.state.url}
								></object>
							);
							break;
						case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							renderData = (
								<FileExcelTwoTone
									twoToneColor='#6dd230'
									style={{
										paddingTop: '200px',
										paddingBottom: '200px',
										fontSize: '200px'
									}}
								/>
							);
							break;
						default:
							renderData = (
								<FileTwoTone
									style={{
										paddingTop: '200px',
										paddingBottom: '200px',
										fontSize: '200px'
									}}
								/>
							);
							break;
					}
					break;
			}
		} else renderData = <LoadScreen />;
		return (
			<>
				<Modal.Header closeButton>{this.props.file.name}</Modal.Header>
				{renderData}
				<Modal.Footer>
					<a href={this.props.file.url} download>
						Download
					</a>
				</Modal.Footer>
			</>
		);
	}
}

class Messenger extends React.Component {
	static contextType = UserStateContext;
	k = new Date();
	constructor(props) {
		super(props);
		this.state = {
			thread: null,
			people: null,
			messages: null,
			fake_id: -100,
			displayDate: (
				this.k.getFullYear() +
				'-' +
				('0' + (this.k.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + this.k.getDate()).slice(-2)
			).toString(),
			messsageTemp: Date(),
			calendarModal: false,
			taskarray: [],
			tenant: [],
			uploadFiles: [],
			attachmentLeft: 0,
			fileUpdate: false,
			messageSending: false,
			viewedAttachment: null,
			attachmentViewer: false,
			jrListModal: false,
			newJR: false,
			maintenanceModal: false, //if the modal is open or not
			serviceModal: false
		};
		this.fileUpload = React.createRef();
		this.attachmentsScroll = React.createRef();
	}

	componentDidMount = () => {
		this.props.history.push(`/communication/${this.props.thread_id}`);
		this.getThread();
		this.props.updateThreads();
		this.getTenantDetails(parseInt(this.props.thread_id));

		this.scrollToBottom();
	};
	getTenantDetails = details => {
		axios
			.post('/communication/get/threadtenant', { thread_id: details })
			.then(response => {
				if (response.data.requests > 0) {
					this.setState({ tenant: response.data.requests[0] });
				}
			});
	};
	getAvailability = userId => {
		axios.post('/get/useravailability', { userID: userId }).then(response => {
			this.setState({ taskarray: response.data.Items });
			return response.data.Items;
		});
	};

	openCalendarModal = () => {
		this.setState({ calendarModal: !this.state.calendarModal });
	};

	getThread = () => {
		axios
			.get('/communication/getThread/' + this.props.thread_id)
			.then(response => {
				if (!response.data.invalid) {
					console.log(response.data.thread);
					this.setState({
						people: response.data.people,
						messages: response.data.messages,
						thread: response.data.thread,
						uploadFiles: []
					});
					// console.log((new Date( response.data.messages[0].time)))
					// let k = ( response.data.messages[0].time).toString()
					// console.log(k)

					if (response.data.removeUnreadNotification) {
						window.sessionStorage.setItem(
							'commNotification',
							window.sessionStorage.getItem('commNotification') - 1
						);
					}
				} else {
					this.props.history.push('/communication');
				}
			});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.thread_id !== this.props.thread_id) {
			this.setState({ messages: null });
			this.getThread();
			this.props.history.push(`/communication/${this.props.thread_id}`);
			this.getTenantDetails(parseInt(this.props.thread_id));
		}
		if (
			prevState.attachmentLeft === this.state.attachmentLeft &&
			prevState.viewedAttachment === this.state.viewedAttachment &&
			prevState.attachmentViewer === this.state.attachmentViewer &&
			prevState.uploadFiles.length === this.state.uploadFiles.length
		) {
			this.scrollToBottom();
		}
		if (this.state.fileUpdate) {
			this.setState({ fileUpdate: false });
		}
		if (prevState.uploadFiles.length !== this.state.uploadFiles.length) {
			this.setState({ fileUpdate: true });
		}
	}
	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
	};

	sendMessage = message => {
		axios
			.post('/communication/sendMessage', {
				message: message,
				thread_id: this.props.thread_id,
				host: window.location.host
			})
			.then(response => {
				//stacks for sender
				console.log(response.data.newMessage);
				if (
					response.data.newMessage.user_id == this.context.user.id &&
					response.data.newMessage.thread_id == this.props.thread_id
				) {
					this.setState({
						currentMessage: '',
						messages: [...this.state.messages, response.data.newMessage]
					});
					this.props.updateThreads();
					// if (response.data.newMessage.message === 'Is this closed?') {
					// 	setTimeout(
					// 		() => console.log(response.data.newMessage.msg_id + 1),
					// 		this.closedMessage(response.data.newMessage.msg_id + 1),
					// 		15000
					// 	);
					// }
				}
			})
			.catch(err => console.log(err));
	};

	// closedMessage = msg_id => {
	// 	axios
	// 		.post('/communication/getMessage', {
	// 			messageId: msg_id
	// 		})
	// 		.then(response => {
	// 			console.log(response.data.message);
	// 			this.setState({
	// 				messages: [...this.state.messages, response.data.message]
	// 			});
	// 		});
	// };

	sendMessageWithAttach = message => {
		let form = new FormData();
		for (var x = 0; x < this.state.uploadFiles.length; x++) {
			if ('url' in this.state.uploadFiles[x])
				delete this.state.uploadFiles[x].url;
			form.append('file', this.state.uploadFiles[x]);
		}
		this.setState({ messageSending: true, uploadFiles: [] });

		form.append('message', message || '');
		form.append('thread_id', this.props.thread_id);
		form.append('host', window.location.host);
		axios
			.post('/communication/sendMessageWithAttach', form)
			.then(response => {
				//stacks for sender
				if (
					response.data.newMessage.user_id == this.context.user.id &&
					response.data.newMessage.thread_id == this.props.thread_id
				) {
					this.setState({
						currentMessage: '',
						messages: [...this.state.messages, response.data.newMessage],
						messageSending: false
					});
				}
			})
			.catch(err => console.log(err));
	};

	updateDisplayDate = val => {
		displayDate = val;
		return;
	};

	titleComms = thread => {
		if (thread == null || thread.thread_name == null) {
			return '';
		} else {
			return thread.thread_name.substr(thread.thread_name.indexOf(',') + 1);
		}
	};

	setFiles = files => {
		if (this.state.uploadFiles.length < 10) {
			let fileList = [];
			for (
				let i = 0;
				i < files.length &&
				fileList.length + this.state.uploadFiles.length < 10;
				i++
			) {
				if (
					!this.state.uploadFiles.some(file => files.item(i).name === file.name)
				)
					fileList.push(files.item(i));
			}
			this.setState({ uploadFiles: [...this.state.uploadFiles, ...fileList] });
		}
	};

	removeFile = fileIndex => {
		let newFileList = [...this.state.uploadFiles];
		newFileList.splice(fileIndex, 1);
		this.setState({ uploadFiles: newFileList });
	};

	viewAttachment = attachment => {
		this.setState({ viewedAttachment: attachment, attachmentViewer: true });
	};

	//======================================================================

	openJRModal = () =>
		this.setState({ maintenanceModal: !this.state.maintenanceModal });
	openJRSModal = () =>
		this.setState({ serviceModal: !this.state.serviceModal });
	openNewJR = () => this.setState({ newJR: !this.state.newJR });
	openJRList = () => this.setState({ jrListModal: !this.state.jrListModal });
	finishRequest = () => {
		let stateObj = {};
		if (this.state.newJR) stateObj.newJR = false;
		if (this.state.maintenanceModal) stateObj.maintenanceModal = false;
		if (this.state.serviceModal) stateObj.serviceModal = false;
		this.setState(stateObj);
		this.props.reloadJRDisplay();
	};
	reloadJRDisplay = () => {
		this.setState({ jrListModal: !this.state.jrListModal });
		this.props.reloadJRDisplay();
	};

	render() {
		console.log(this.state.thread);
		console.log(
			this.state.thread ? this.state.thread.thread_name : 'Opening Thread...'
		);
		if (this.state.messages === null)
			return (
				<div
					className={`messengerContainer ${
						this.props.fixedHeight ? 'commModalFixedHeightMessenger' : ''
					}`}
				>
					<Modal.Header
						closeButton={this.props.modal}
						className='messengerHeader'
					>
						{this.props.backButton ? (
							this.props.goBack === undefined ? (
								<Link to='/communication'>
									<img
										alt='back'
										src={require('../../icons/backIcon.png')}
										className='backbtnComms mr-2'
									/>
								</Link>
							) : (
								<img
									alt='back'
									onClick={this.props.goBack}
									style={{ cursor: 'pointer' }}
									src={require('../../icons/backIcon.png')}
									className='backbtnComms mr-2'
								/>
							)
						) : (
							''
						)}
						<ModalTitle className='fs-16 lh28 modalTitleComms'>
							{this.state.thread
								? this.state.thread.thread_name.split(' ')[0]
								: 'Opening Thread...'}
						</ModalTitle>
					</Modal.Header>
					<Modal.Body className='messengerBody'>
						<LoadScreen className='m-auto' />
						<div
							ref={el => {
								this.messagesEnd = el;
							}}
						></div>
					</Modal.Body>
					<Modal.Footer className='messengerFooter'></Modal.Footer>
				</div>
			);
		let leftAttachmentButton =
			this.attachmentsScroll.current !== null &&
			this.attachmentsScroll.current.scrollLeft > 0;
		let rightAttachmentButton =
			this.attachmentsScroll.current !== null &&
			this.attachmentsScroll.current.scrollLeft <
				this.attachmentsScroll.current.scrollLeftMax &&
			this.state.uploadFiles.length > 1;
		return (
			<div
				className={`messengerContainer ${
					this.props.fixedHeight ? 'commModalFixedHeightMessenger' : ''
				}`}
			>
				<Modal
					show={this.state.attachmentViewer}
					size='lg'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					onHide={() => {
						this.setState({ attachmentViewer: false });
					}}
				>
					<AttachmentsFullDisplay file={this.state.viewedAttachment} />
				</Modal>
				<Modal.Header
					closeButton={this.props.modal}
					className='messengerHeader'
				>
					{this.props.backButton ? (
						this.props.goBack === undefined ? (
							<Link to='/communication'>
								<img
									src={require('../../icons/backIcon.png')}
									className='backbtnComms mr-2'
								/>
							</Link>
						) : (
							<img
								onClick={this.props.goBack}
								style={{ cursor: 'pointer' }}
								src={require('../../icons/backIcon.png')}
								className='backbtnComms mr-2'
							/>
						)
					) : (
						''
					)}
					<ModalTitle className='fs-16 lh28 modalTitleComms'>
						{this.state.thread
							? this.state.thread.thread_name.split(' ')[0]
							: 'Opening Thread...'}
					</ModalTitle>
				</Modal.Header>
				<Modal.Body className='messengerBody'>
					<ul className='chatBoxfixedBottom'>
						{this.state.messages.length > 0 ? (
							this.state.messages.map((message, messageIndex) => (
								<div key={messageIndex}>
									<DateDisplay message={message} />

									<MessageDisplay
										key={message.msg_id}
										message={message}
										thisUser={
											this.context.user.id === message.user_id &&
											this.context.user.type === message.user_acctype
										}
										nextUser={
											messageIndex < this.state.messages.length - 1
												? {
														id: this.state.messages[messageIndex + 1].user_id,
														unread: this.state.messages[messageIndex + 1].unread
												  }
												: {
														id: -1,
														id: null
												  }
										}
										viewAttachment={this.viewAttachment}
									/>
								</div>
							))
						) : (
							<li className='text-center g-text'>
								Type in the textbox below to send a message{' '}
							</li>
						)}
					</ul>
					<div
						style={{ float: 'left', clear: 'both' }}
						ref={ele => {
							this.messagesEnd = ele;
						}}
					></div>
				</Modal.Body>
				<div style={{ height: '75px', maxHeight: '75px' }}>
					<LeftCircleTwoTone
						onClick={() => {
							if (leftAttachmentButton) {
								this.attachmentsScroll.current.scrollLeft =
									this.attachmentsScroll.current.scrollLeft -= 75;
								this.setState({
									attachmentLeft: this.attachmentsScroll.current.scrollLeft
								});
							}
						}}
						style={{
							cursor: leftAttachmentButton ? 'pointer' : 'default',
							position: 'relative',
							zIndex: 3,
							fontSize: '20px',
							left: '22px',
							top: '28px',
							opacity: leftAttachmentButton ? 1 : 0
						}}
					/>
					<div
						ref={this.attachmentsScroll}
						style={{
							height: '75px',
							overflowX: 'hidden',
							overflowY: 'hidden',
							position: 'relative',
							zIndex: 2,
							left: '20px',
							bottom: '24px'
						}}
					>
						<div
							style={{
								width: `${
									75 * this.state.uploadFiles.length +
									(this.state.uploadFiles.length > 0 ? 75 : 0)
								}px`
							}}
						>
							{this.state.uploadFiles.map((file, fileIndex) => (
								<AttachmentsUploadDisplay
									key={fileIndex}
									file={file}
									fileIndex={fileIndex}
									removeFile={this.removeFile}
									viewAttachment={this.viewAttachment}
								/>
							))}
						</div>
					</div>
					<RightCircleTwoTone
						onClick={() => {
							if (rightAttachmentButton) {
								this.attachmentsScroll.current.scrollLeft += 75;
								this.setState({
									attachmentLeft: this.attachmentsScroll.current.scrollLeft
								});
							}
						}}
						style={{
							cursor: rightAttachmentButton ? 'pointer' : 'default',
							position: 'relative',
							zIndex: 3,
							fontSize: '20px',
							float: 'right',
							right: '2px',
							bottom: '70px',
							opacity: rightAttachmentButton ? 1 : 0
						}}
					/>
				</div>

				<Modal.Footer className='messengerFooter'>
					<MessageField
						finishRequest={this.finishRequest}
						openJRSModal={this.openJRSModal}
						openJRModal={this.openJRModal}
						openNewJR={this.openNewJR}
						serviceModal={this.state.serviceModal}
						maintenanceModal={this.state.maintenanceModal}
						newJR={this.state.newJR}
						openJRList={this.openJRList}
						jrListModal={this.state.jrListModal}
						reloadJRDisplay={this.reloadJRDisplay}
						sendMessage={this.sendMessage}
						ownProfile={this.context.user.id}
						people={this.state.people}
						getTenant={this.getTenantDetails}
						tenant={this.state.tenant}
						calendarModal={this.state.calendarModal}
						openCalendarModal={this.openCalendarModal}
						getAvailability={this.getAvailability}
						taskarray={this.state.taskarray}
						thread_id={this.props.thread_id}
						sendMessageWithAttach={this.sendMessageWithAttach}
						fileUpload={this.fileUpload}
						setFiles={this.setFiles}
						uploadFiles={this.state.uploadFiles}
						messageSending={this.state.messageSending}
						userType={this.props.userType}
					/>
				</Modal.Footer>
			</div>
		);
	}
}

const MessageField = props => {
	const {
		finishRequest,
		openJRSModal,
		openJRModal,
		openNewJR,
		serviceModal,
		maintenanceModal,
		newJR,
		openJRList,
		jrListModal,
		reloadJRDisplay,
		openCalendarModal,
		userType
	} = props;
	const [message, setMessage] = useState('');
	const [resolveMessage, setResolveMessage] = useState(false);
	const [inactiveUserId, setInactiveUserId] = useState([]);
	let userId = 0;
	useEffect(() => {
		axios.post('/communication/get/inactiveEmployeeId').then(response => {
			console.log(response.data.inactiveEmployeesId);
			setInactiveUserId(response.data.inactiveEmployeesId);
		});
	}, []);
	const checkForOnlyWhite = () => {
		let checkText = message;
		checkText = checkText.replace(/^\s\s*/, '').replace(/\s+$/, '');
		return checkText === '';
	};

	const handleKeyDown = evt => {
		let isOnlyWhite = checkForOnlyWhite();
		if (props.uploadFiles.length > 0 || !isOnlyWhite) {
			if (evt.key == 'Enter' && evt.shiftKey) {
				evt.preventDefault();
				if (props.uploadFiles.length > 0) {
					props.sendMessageWithAttach(isOnlyWhite ? null : message);
					setMessage('');
				} else if (props.uploadFiles.length === 0 && message.length > 0) {
					props.sendMessage(message);
					setMessage('');
				}
			}
		}
	};

	if (props.people[0].user_id != props.ownProfile) {
		userId = props.people[0].user_id;
	} else {
		userId = props.people[1].user_id;
	}
	// console.log(userId);
	const inactiveUser = inactiveUserId.some(id => {
		console.log(userId);
		if (id.user_id === userId) {
			return true;
		}
	});
	const autoMessage = () => {
		setResolveMessage(true);
		props.sendMessage('Is this closed?');
		console.log(resolveMessage);
		setTimeout(() => props.sendMessage('closed'), 15000);
	};

	// console.log(inactiveUser);
	return (
		<div id='searchContainer'>
			<input
				multiple
				type='file'
				ref={props.fileUpload}
				style={{ display: 'none' }}
				onChange={event => {
					event.stopPropagation();
					event.preventDefault();
					props.setFiles(event.target.files);
				}}
			/>
			{!inactiveUser ? (
				<>
					{userType === 'Manager' ? (
						<>
							{/*<Tooltip title="Link to existing Job Request"><Button onClick={openJRList} type="text" icon={<ToolOutlined/>}></Button></Tooltip>*/}
							<Tooltip title='New Job Request'>
								<Button
									onClick={openNewJR}
									type='text'
									icon={<PlusCircleTwoTone />}
								></Button>
							</Tooltip>
						</>
					) : null}

					<Tooltip title='Attach Files'>
						<Button
							onClick={() => {
								props.fileUpload.current.click();
							}}
							type='text'
							icon={<PaperClipOutlined />}
						></Button>
					</Tooltip>
					<Input.TextArea
						autoSize={{ minRows: 1, maxRows: 6 }}
						placeholder='Aa'
						value={message}
						onChange={e => setMessage(e.target.value)}
						onKeyDown={handleKeyDown}
						id='commTextArea'
					/>
					<Button
						disabled={props.messageSending}
						className='ml-2'
						type='primary'
						id='commSendMessage'
						onClick={() => {
							let isOnlyWhite = checkForOnlyWhite();
							if (props.uploadFiles.length > 0 || !isOnlyWhite) {
								if (props.uploadFiles.length > 0) {
									props.sendMessageWithAttach(isOnlyWhite ? null : message);
									setMessage('');
								} else if (message.length > 0) {
									props.sendMessage(message);
									setResolveMessage(false);
									setMessage('');
								}
							}
						}}
					>
						Send
					</Button>
					{userType === 'Manager' ? (
						<Button
							disabled={resolveMessage}
							className='ml-2'
							type='primary'
							id='commSendMessage'
							onClick={autoMessage}
						>
							Resolution
						</Button>
					) : (
						''
					)}
				</>
			) : (
				<li className='text-center g-text'>
					This Employee is not active. Please select another Manager to Chat.
				</li>
			)}

			<AntModal
				title='Job Request'
				visible={newJR}
				onCancel={openNewJR}
				footer={false}
			>
				<Button
					className='maintrequest-btn width50 btnLabel'
					type='primary'
					onClick={openJRModal}
				>
					New Maintenance Request
				</Button>

				<AntModal
					title='Maintenance Request'
					visible={maintenanceModal}
					onCancel={openJRModal}
					footer={false}
				>
					<CommsMaintenanceRequest
						type='Maintenance Request'
						people={props.people}
						finishRequest={finishRequest}
						openRequest={true}
						t={props.thread_id}
					/>
				</AntModal>
				<br></br>
				<Button
					className='servrequest-btn width50 btnLabel'
					type='primary'
					onClick={openJRSModal}
				>
					New Service Request
				</Button>

				<AntModal
					title='Service Request'
					visible={serviceModal}
					onCancel={openJRSModal}
					footer={false}
				>
					<CommsMaintenanceRequest
						type='Service Request'
						people={props.people}
						finishRequest={finishRequest}
						openRequest={true}
						t={props.thread_id}
					/>
				</AntModal>

				<br></br>
				<Button
					className='linkrequest-btn width50 btnLabel'
					type='primary'
					onClick={openJRList}
				>
					Link to Existing Job Request
				</Button>
			</AntModal>

			<AntModal
				title='Select Job Request'
				visible={jrListModal}
				onCancel={openJRList}
				footer={false}
			>
				<SelectJobRequests
					thread_id={props.thread_id}
					reloadJRDisplay={reloadJRDisplay}
					open={jrListModal}
				/>
			</AntModal>
			<Modal
				show={props.calendarModal}
				onHide={props.openCalendarModal}
				size='lg'
				//aria-labelledby="change-vendor-modal"
				centered
			>
				<CommsAvailability userId={userId} />
			</Modal>
		</div>
	);
};
function createEvent(event, user) {
	axios.post('/post/employeeavailability', {
		userID: user,
		taksID: event.event_id,
		endTime: event.endTime,
		startTime: event.startTime,
		startDate: event.startDate
	});
}
function changeEvent(newData, user) {
	if (newData.operation == 'delete') {
		axios.post('/deleteemployeeavailability', {
			userID: this.context.user.id,
			taksID: parseInt(newData.id)
		});
	}
	if (newData.operation == 'edit') {
		axios.post('/updateemployeeavailability', {
			userID: user,
			taksID: newData.id,
			NewEndTime: newData.data.endTime,
			NewStartTime: newData.data.startTime,
			NewStartDate: newData.data.startDate
		});
	}
	if (newData.operation == 'merge') {
		for (var i = 0; i < newData.delete_ids.length; i++) {
			axios.post('/deleteemployeeavailability', {
				userID: user,
				taksID: parseInt(newData.delete_ids[i])
			});
		}
		axios.post('/updateemployeeavailability', {
			userID: user,
			taksID: newData.id,
			NewEndTime: newData.data.endTime,
			NewStartTime: newData.data.startTime,
			NewStartDate: newData.data.startDate
		});
	}
}

const CalendarModal = props => {
	const [lockOn, updateLock] = useState(false);
	return (
		<>
			<Modal.Header>Availability </Modal.Header>
			<Modal.Body>
				<CommsBarCalendar
					dataSource={[
						{
							bars: props.taskarray
						}
					]}
					eventStyles={{
						pink: {
							start: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#fe4d97'
							}
						},
						blue: {
							start: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#4d7cfe'
							}
						},
						default: {
							start: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							}
						}
					}}
					calendarView='month'
					showHeader={true}
					interactMode=''
					orientation='vertical'
					newEvent={newEvent => {
						createEvent(newEvent, props.tenant.user_id);
						return newEvent;
					}}
					editEvent={newData => {
						changeEvent(newData, props.tenant.user_id);
						return;
					}}
					clickEvent={eventData => {}}
					blockWidth={35}
					blockHeight={20}
					lockOn={lockOn}
					updateLock={updateLock}
					//loading={!this.state.showCalendar}
					// updateLock={(e)=>{this.setState({lockOn: e})}} lockOn={false}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</>
	);
};
const DateDisplay = ({ message }) => {
	if (message.time.toString().slice(0, 10) !== displayDate) {
		displayDate = message.time.toString().slice(0, 10);
		return (
			<div className='container'>
				<div>
					<Moment
						tz='America/St_Johns'
						format='MMM Do YYYY'
						className='g-text myMessageTime'
					>
						{message.time}
					</Moment>{' '}
				</div>
			</div>
		);
	} else {
		return (
			<div className='container'>
				<div></div>
			</div>
		);
	}
};

const MessageDisplay = ({ message, thisUser, viewAttachment, nextUser }) => {
	if (thisUser)
		return (
			<li key={message.msg_id} className='msgContainer floatRight'>
				<div className='msgTextContainer txtContainerSelf'>
					{'attachments' in message ? (
						<div>
							{message.attachments.map((attachment, attachIndex) => {
								return (
									<AttachmentsDisplay
										side={message.message ? 'left' : 'right'}
										key={attachIndex}
										file={attachment}
										viewAttachment={viewAttachment}
									/>
								);
							})}
						</div>
					) : null}
					{message.message ? (
						<div className='myMessage msgText'>{message.message}</div>
					) : null}
					<Moment
						format='HH:mm'
						tz='America/St_Johns'
						className='g-text myMessageTime'
					>
						{message.time}
					</Moment>
				</div>
				<div className=' msgAvatar'>
					<Tooltip title={message.name}>
						{message.user_id != nextUser.id ||
						message.unread != nextUser.unread ? (
							<Badge
								offset={[45, 5]}
								style={{ position: 'absolute' }}
								count={
									message.unread == 1 ? (
										<CheckCircleOutlined style={{ color: '#4d7cfe' }} />
									) : (
										<CheckCircleFilled style={{ color: '#4d7cfe' }} />
									)
								}
							/>
						) : null}
						<Avatar src={message.imageurl} />
					</Tooltip>
				</div>
			</li>
		);
	else
		return (
			<li key={message.msg_id} className='msgContainer'>
				<div className='msgAvatar'>
					<Tooltip title={message.name}>
						<Avatar src={message.imageurl} />
					</Tooltip>
				</div>
				<div className='msgTextContainer txtContainerOTher '>
					{'attachments' in message ? (
						<div>
							{message.attachments.map((attachment, attachIndex) => {
								return (
									<AttachmentsDisplay
										side={message.message ? 'right' : 'left'}
										key={attachIndex}
										file={attachment}
										viewAttachment={viewAttachment}
									/>
								);
							})}
						</div>
					) : null}

					{message.message ? (
						<div className='otherMessage msgText'>{message.message}</div>
					) : null}

					<Moment format='H:mm' className='g-text otherMessageTime'>
						{message.time}
					</Moment>
				</div>
			</li>
		);
};
export default Messenger;
