import React from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

import LoadScreen from "../../../Components/LoadScreen";
import { Button, Alert } from "antd";
const cancelToken = axios.CancelToken.source();

class BankingTask extends React.Component {
  constructor(props) {
    super();
    this.state = {
      branchName: "",
      institutionNumber: "",
      transitNumber: "",
      accountNumber: "",
      errorMessage:null,
      submitted:false,
      loaded:false
    };
  }

  verify = () => {
    if(this.state.branchName === '') {
      this.setState({errorMessage: 'Please enter a branch name.'});
      return false;
    }
    else if(this.state.institutionNumber === ''){
      this.setState({errorMessage: 'Please enter an institution number.'});
      return false;
    }
    else if(this.state.transitNumber === '' || this.state.transitNumber.length < 9){
      this.setState({errorMessage: 'Please enter a transit number.'});
      return false;
    }
    else if(this.state.accountNumber === ''){
      this.setState({errorMessage: 'Please enter your account number.'});
      return false;
    }
    return true
  }
  closeError = () => this.setState({errorMessage:null})

  handleChange = (e) => {
    this.setState({[e.target.name]:e.target.value})
  }

  

  submitForm = () => {
      if(!this.verify()) return
      this.setState({submitted:true})
      axios
      .post("/bankingComplete", {
        branchName: this.state.branchName,
        institutionNumber: this.state.institutionNumber,
        transitNumber: this.state.transitNumber,
        accountNumber: this.state.accountNumber,
        errorMessage: null,
      })
      .then((response) => {
        //next page
      })
      .catch((err) => console.log(err));
  };
  componentDidMount(){
		axios.post("/getBanking",{data: this.props.taskData}).then((response) => {
			this.setState({loaded:true})
			this.setState({ branchName: response.data.branchName || "", institutionNumber: response.data.institutionNum || "", transitNumber: response.data.transitNum || "", accountNumber: response.data.accountNum || ""})
			
		})
	}
  render() {
    if(!this.state.loaded) return (<LoadScreen className='m-auto' />)
    
    return (
      <div className="taskContainer">
        <h3 className="title">Banking Info</h3>
        <Form>
          <Form.Group>
            <p>
              Description: Please enter the following information to update your
              account.
            </p>
            <Form.Label className="form-label">Branch Name:</Form.Label>
            <Form.Control
              className="form-text"
              name="branchName"
              type="text"
              placeholder="Branch Name"
              defaultValue={this.state.branchName}
              onChange={this.handleChange}
            />
            <br />
            <Form.Label className="form-label">Institution Number:</Form.Label>
            <Form.Control
              className="form-text"
              name="institutionNumber"
              type="text"
              placeholder="Institution Number"
              defaultValue={this.state.institutionNumber}
              onChange={this.handleChange}
            />
            <br />
            <Form.Label className="form-label">Transit Number:</Form.Label>
            <Form.Control
              className="form-text"
              name="transitNumber"
              type="text"
              placeholder="Transit Number"
              defaultValue={this.state.transitNumber}
              onChange={this.handleChange}
            />
            <br />
            <Form.Label className="form-label">Account Number:</Form.Label>
            <Form.Control
              className="form-text"
              name="accountNumber"
              type="text"
              placeholder="Account Number"
              defaultValue={this.state.accountNumber}
              onChange={this.handleChange}
            />
          </Form.Group>
          {this.state.errorMessage ? <Alert message={this.state.errorMessage} type="error" className='pointer' showIcon onClick={this.closeError} closable /> : ''}
            <br />
            <br></br>
          <Button type='primary' disabled={this.state.submitted} onClick={this.submitForm}>Submit</Button>
        </Form>
      </div>
    );
  }
}

export default BankingTask;
