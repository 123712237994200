import React from 'react';

import scrollbarSizeHook from '../../hooks/scrollbarSizeHook';

//A list of months, is used to get month names and even number of days per month depending on the year
const DAYS_PER_MONTH = [
	{
		name: 'January',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'February',
		days: function (year) {
			return year % 4 === 0
				? year % 100 === 0
					? year % 400 === 0
						? 29
						: 28
					: 29
				: 28;
		}
	},
	{
		name: 'March',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'April',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'May',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'June',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'July',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'August',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'September',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'October',
		days: function (year) {
			return 31;
		}
	},
	{
		name: 'November',
		days: function (year) {
			return 30;
		}
	},
	{
		name: 'December',
		days: function (year) {
			return 31;
		}
	}
];

//A list of week names to be displayed on the calendar
const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'];

//Font used for calculating header width
const HEADER_FONT = `normal normal normal 15px "Rubik", sans-serif`;

//Function for displaying the hours of the day on the side of the vertical calendar
function getDayHour(hourNum) {
	if (hourNum === 0 || hourNum === 24) return '12 am';
	else if (hourNum < 12) return `${hourNum} am`;
	else if (hourNum === 12) return '12 pm';
	else return `${hourNum - 12} pm`;
}

//Function for getting the number of days in a year
function getDaysPerYear(yearNum) {
	return yearNum % 4 === 0
		? yearNum % 100 === 0
			? yearNum % 400 === 0
				? 366
				: 365
			: 366
		: 365;
}

//Function for getting the numerical difference between two dates
function getDateDiff(endDate, startDate, blockSize, withTimeOfDay = false) {
	let utcDiff = withTimeOfDay
		? Date.UTC(
				endDate.getFullYear(),
				endDate.getMonth(),
				endDate.getDate(),
				endDate.getHours(),
				endDate.getMinutes(),
				endDate.getSeconds(),
				endDate.getMilliseconds()
		  ) -
		  Date.UTC(
				startDate.getFullYear(),
				startDate.getMonth(),
				startDate.getDate(),
				startDate.getHours(),
				startDate.getMinutes(),
				startDate.getSeconds(),
				startDate.getMilliseconds()
		  )
		: Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
		  Date.UTC(
				startDate.getFullYear(),
				startDate.getMonth(),
				startDate.getDate()
		  );
	return (utcDiff / 86400000) * blockSize;
}

//Function for getting the number of pixels from the start of the calendar that a point in time should be
function getDateInt(
	currentDate,
	startingYear,
	blockSize,
	calendarView,
	withTimeOfDay = false
) {
	let startingDate = new Date(`January 1, ${startingYear} 00:00:00`);
	return getDateDiff(currentDate, startingDate, blockSize, withTimeOfDay);
}

//Function for getting the pixel width of a length of text based on text and font
function getTextWidth(text, font) {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	context.font = font || getComputedStyle(document.body).font;

	return Math.round(context.measureText(text).width);
}

//Function for clamping a mathematical value between min and max
function clampVal(min, val, max) {
	return Math.min(Math.max(val, min), max);
}

//Component for displaying a month header block
class MonthBlock extends React.Component {
	constructor(props) {
		super(props);
		this.monthDiv = React.createRef();
	}
	shouldComponentUpdate(nextProps) {
		//Decides if component should update based on calendar horizontal position
		let calProps = nextProps.calProps;
		let monthPxStart = getDateInt(
			new Date(nextProps.currentYear, nextProps.currentMonth, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			nextProps.calendarView
		);
		let monthPxEnd =
			monthPxStart + calProps.blockWidth * nextProps.daysOfMonth - 1;
		return (
			nextProps.xScroll !== this.props.xScroll &&
			((calProps.xScroll >= monthPxStart && nextProps.xScroll <= monthPxEnd) ||
				this.monthDiv.current.style.paddingLeft !== '5px')
		);
	}
	render() {
		//Calculates positioning of text based on cal horizontal position and text width
		let calProps = this.props.calProps;

		let monthName = DAYS_PER_MONTH[this.props.currentMonth].name;
		let monthPxStart = getDateInt(
			new Date(this.props.currentYear, this.props.currentMonth, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			this.props.calendarView
		);
		let monthPxEnd =
			monthPxStart + calProps.blockWidth * this.props.daysOfMonth - 1;
		let leftMonthBuffer = 5;
		let monthWidth = getTextWidth(
			monthName + ' ' + this.props.currentYear,
			HEADER_FONT
		);
		if (
			calProps.xScroll >= monthPxStart &&
			this.props.xScroll <= monthPxEnd - (monthWidth + 12)
		) {
			leftMonthBuffer += calProps.xScroll - monthPxStart;
		} else if (
			calProps.xScroll >= monthPxStart &&
			this.props.xScroll <= monthPxEnd
		) {
			leftMonthBuffer =
				calProps.blockWidth * this.props.daysOfMonth - (monthWidth + 8);
		}
		return (
			<div
				ref={this.monthDiv}
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingLeft: leftMonthBuffer,
					paddingTop: 5,
					fontSize: 15,
					width: calProps.blockWidth * this.props.daysOfMonth,
					height: 35,
					color: '#252631',
					border: '1px solid #e8ecef',
					display: 'inline',
					float: 'left'
				}}
			>
				{monthName}&nbsp;
				{this.props.currentYear}
			</div>
		);
	}
}

//Component for a year header block (DEPRECATED SINCE YEAR WAS ADDED TO MONTH BLOCK, BUT SHOULD STILL WORK AS LONG AS THE PROPER PROPS ARE PASSED)
class YearBlock extends React.Component {
	constructor(props) {
		super(props);
		this.yearDiv = React.createRef();
	}
	shouldComponentUpdate(nextProps) {
		let calProps = nextProps.calProps;
		let yearPxStart = getDateInt(
			new Date(nextProps.currentYear, 0, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			nextProps.calendarView
		);
		let yearPxEnd =
			yearPxStart + calProps.blockWidth * nextProps.daysOfYear - 1;
		return (
			nextProps.xScroll !== this.props.xScroll &&
			((calProps.xScroll >= yearPxStart &&
				calProps.xScroll <= yearPxEnd - calProps.blockWidth) ||
				this.yearDiv.current.style.paddingLeft !== '5px')
		);
	}
	render() {
		let calProps = this.props.calProps;
		let yearPxStart = getDateInt(
			new Date(this.props.currentYear, 0, 1),
			calProps.earliestDate.getFullYear(),
			calProps.blockWidth,
			this.props.calendarView
		);
		let yearPxEnd =
			yearPxStart + calProps.blockWidth * this.props.daysOfYear - 1;
		let leftYearBuffer = 5;
		if (
			calProps.xScroll >= yearPxStart &&
			calProps.xScroll <= yearPxEnd - calProps.blockWidth
		) {
			leftYearBuffer += calProps.xScroll - yearPxStart;
		} else if (
			calProps.xScroll >= yearPxStart &&
			calProps.xScroll <= yearPxEnd
		) {
			leftYearBuffer =
				calProps.blockWidth * this.props.daysOfYear - (calProps.blockWidth + 1);
		}
		return (
			<div
				ref={this.yearDiv}
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingLeft: leftYearBuffer,
					paddingTop: 10,
					fontSize: 15,
					width: calProps.blockWidth * this.props.daysOfYear,
					height: 50,
					color: '#252631',
					borderLeft: '1px solid #e8ecef',
					borderRight: '1px solid #e8ecef',
					borderBottom: '1px solid #e8ecef',
					display: 'inline',
					float: 'left'
				}}
			>
				{this.props.currentYear}
			</div>
		);
	}
}

//Component for bars/blocks being displayed on the calendar
class EventBlock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clickable:
				this.props.interactMode === 'clickable' ||
				this.props.interactMode === 'editable',
			endDate: this.props.endDate
				? this.props.endDate
				: new Date(this.props.earliestDate.getFullYear() + 3, 11, 31, 23, 59)
		};
	}
	shouldComponentUpdate(nextProps) {
		//Decides to update block based on changes made to various props
		return (
			this.props.earliestDate !== nextProps.earliestDate ||
			this.props.startTime !== nextProps.startTime ||
			this.props.endTime !== nextProps.endTime ||
			this.props.startDate !== nextProps.startDate ||
			this.props.endDate !== nextProps.endDate ||
			this.props.blockWidth !== nextProps.blockWidth ||
			this.props.blockHeight !== nextProps.blockHeight ||
			this.props.eventStyle !== nextProps.eventStyle
		);
	}
	componentDidUpdate(prevProps) {
		//Makes changes to clickability and end date based on prop changes
		let newState = {};
		if (
			this.props.dragData !== null &&
			this.props.dragData.selectedBar.event_id === this.props.event_id &&
			(this.props.startTime !== this.props.dragData.formerData.startTime ||
				this.props.endTime !== this.props.dragData.formerData.endTime ||
				this.props.startDate !== this.props.dragData.formerData.startDate) &&
			this.state.clickable
		) {
			newState.clickable = false;
		} else if (
			this.props.dragData !== null &&
			this.props.dragData.selectedBar.event_id === this.props.event_id &&
			this.props.startTime === this.props.dragData.formerData.startTime &&
			this.props.endTime === this.props.dragData.formerData.endTime &&
			this.props.startDate === this.props.dragData.formerData.startDate &&
			!this.state.clickable
		)
			newState.clickable = true;
		if (this.props.earliestDate !== prevProps.earliestDate) {
			if (this.props.endDate === null) {
				newState.endDate = new Date(
					this.props.earliestDate.getFullYear() + 3,
					11,
					31,
					23,
					59
				);
			} else if (this.props.endDate !== prevProps.endDate)
				newState.endDate = this.props.endDate;
		}
		this.setState(newState);
	}
	render() {
		//Only renders if in visible range
		if (
			(this.props.calendarView === 'month' &&
				(this.props.startDate.getFullYear() >=
					this.props.earliestDate.getFullYear() ||
					this.props.endDate === null) &&
				this.props.startDate.getFullYear() <=
					this.props.earliestDate.getFullYear() + 3) ||
			this.props.calendarView !== 'month'
		) {
			//Grabs a bar style or uses the default one if the defined style is missing from the style list
			let barStyles =
				this.props.eventStyle in this.props.eventStyles
					? this.props.eventStyles[this.props.eventStyle]
					: this.props.eventStyles.default;
			//Calculates length of a block based on orientation, dates, and times
			let blockFullLength =
				this.props.orientation === 'horizontal'
					? getDateDiff(
							this.state.endDate,
							this.props.startDate,
							this.props.blockWidth,
							this.props.withTimeOfDay
					  ) + 1
					: (this.props.endTime - this.props.startTime) *
					  this.props.blockHeight;
			//Calculates length of the inner block, used to differentiate between the center of a block and the grab points if editing
			let innerBlockFullLength =
				this.props.orientation === 'vertical' &&
				this.props.interactMode === 'editable' &&
				blockFullLength > this.props.blockHeight
					? blockFullLength - this.props.blockHeight * 2
					: '100%';
			//Decides position of block based on start of calendar date and start date of this event block
			let currentDatePix = 0;
			if (this.props.calendarView === 'month') {
				currentDatePix = getDateInt(
					this.props.startDate,
					this.props.earliestDate.getFullYear(),
					this.props.blockWidth,
					this.props.calendarView,
					this.props.withTimeOfDay
				);
			} else if (this.props.calendarView === 'week') {
				currentDatePix =
					['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'].indexOf(
						this.props.startDate
					) * this.props.blockWidth;
			} else if (this.props.calendarView === 'day') {
				currentDatePix = 0;
			}
			return (
				<div
					onMouseDown={event => {
						//Deletes an event on right click
						if (event.button === 0 && this.props.interactMode === 'editable') {
							this.props.deleteEvent(
								this.props.sourceIndex,
								this.props.groupIndex,
								this.props.blockIndex,
								this.props.event_id
							);
						}
						return false;
					}}
					onMouseUp={e => {
						//Click Event defined by Bar Calendar props
						if (
							'clickEvent' in this.props &&
							this.state.clickable &&
							e.button === 0 &&
							(this.props.dragData === null ||
								(this.props.dragData !== null && !this.props.dragData.isNew))
						) {
							this.props.clickEvent(this.props);
						} else if (e.button === 0) {
							this.setState({ clickable: true });
						}
					}}
					draggable={false}
					style={{
						WebkitUserSelect: 'none',
						msUserSelect: 'none',
						userSelect: 'none',
						pointerEvents: this.props.interactMode !== 'none' ? 'auto' : 'none',
						position: 'absolute',
						width:
							this.props.orientation === 'vertical'
								? this.props.blockWidth
								: blockFullLength,
						height:
							this.props.orientation === 'vertical'
								? blockFullLength
								: this.props.blockHeight,
						left: currentDatePix,
						top:
							this.props.orientation === 'vertical'
								? this.props.startTime * this.props.blockHeight
								: 0
					}}
				>
					{innerBlockFullLength !== '100%' ? (
						<div
							draggable={false}
							onMouseDown={e => {
								//Starting to edit an event from the top anchor of event
								if (e.button === 0 && this.props.interactMode === 'editable') {
									this.props.startEdit(
										this.props.sourceIndex,
										this.props.groupIndex,
										this.props.blockIndex,
										this.props.event_id,
										'A'
									);
								}
							}}
							style={{
								WebkitUserSelect: 'none',
								msUserSelect: 'none',
								userSelect: 'none',
								cursor: 'ns-resize',
								width: '100%',
								height: this.props.blockHeight,
								...(barStyles.start || barStyles.single)
							}}
						/>
					) : null}
					<div
						draggable={false}
						onMouseDown={e => {
							//Starting to edit an event from the bottom anchor of event
							if (
								e.button === 0 &&
								this.props.interactMode === 'editable' &&
								blockFullLength === this.props.blockHeight
							) {
								this.props.startEdit(
									this.props.sourceIndex,
									this.props.groupIndex,
									this.props.blockIndex,
									this.props.event_id,
									'B'
								);
							}
						}}
						style={{
							overflow: 'hidden',
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							cursor:
								this.props.interactMode === 'editable' &&
								blockFullLength === this.props.blockHeight
									? 'ns-resize'
									: this.props.interactMode === 'clickable'
									? 'pointer'
									: 'auto',
							width: '100%',
							height: innerBlockFullLength,
							...barStyles[
								innerBlockFullLength !== '100%' && 'barStyles'
									? 'middle'
									: 'single'
							]
						}}
					>
						<div
							style={
								'text' in barStyles
									? {
											overflow: 'hidden',
											overflowWrap: 'break-word',
											hyphens: 'auto',
											...barStyles.text
									  }
									: {
											overflow: 'hidden',
											overflowWrap: 'break-word',
											hyphens: 'auto'
									  }
							}
						>
							{this.props.caption !== undefined ? this.props.caption : null}
						</div>
					</div>
					{innerBlockFullLength !== '100%' ? (
						<div
							draggable={false}
							onMouseDown={e => {
								//Starting to edit an event from the top anchor of event
								if (e.button === 0 && this.props.interactMode === 'editable') {
									this.props.startEdit(
										this.props.sourceIndex,
										this.props.groupIndex,
										this.props.blockIndex,
										this.props.event_id,
										'B'
									);
								}
							}}
							style={{
								WebkitUserSelect: 'none',
								msUserSelect: 'none',
								userSelect: 'none',
								cursor: 'ns-resize',
								width: '100%',
								height: this.props.blockHeight,
								...(barStyles.end || barStyles.single)
							}}
						/>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

//Function for rendering a header of days of the month
function renderMonthDays(dayArray, calProps) {
	return dayArray.map(dayObj => {
		return (
			<div
				draggable={false}
				style={{
					WebkitUserSelect: 'none',
					msUserSelect: 'none',
					userSelect: 'none',
					paddingTop: Math.round(calProps.blockHeight / (50 / 10)),
					fontSize: Math.round(calProps.blockWidth / (50 / 15)),
					width: calProps.blockWidth,
					color: '#252631',
					border: '1px solid #e8ecef',
					textAlign: 'center',
					display: 'inline',
					float: 'left'
				}}
				key={
					dayObj.currentYear.toString() +
					'/' +
					(dayObj.currentMonth + 1).toString() +
					'/' +
					dayObj.currentDay.toString()
				}
			>
				{dayObj.weekDay}
				<br />
				{dayObj.currentDay}
			</div>
		);
	});
}
//Function for rendering a header of months
function renderMonths(monthArray, calProps) {
	return monthArray.map(monthObj => {
		return (
			<MonthBlock
				key={
					monthObj.currentYear.toString() +
					'/' +
					(monthObj.currentMonth + 1).toString()
				}
				daysOfMonth={monthObj.daysOfMonth}
				currentYear={monthObj.currentYear}
				currentMonth={monthObj.currentMonth}
				calendarView={calProps.calendarView}
				xScroll={calProps.xScroll}
				earliestDate={calProps.earliestDate}
				calProps={calProps}
			/>
		);
	});
}
//Function for rendering a header of years (DEPRECATED SINCE YEAR WAS ADDED TO MONTH BLOCK, BUT SHOULD STILL WORK AS LONG AS THE PROPER PROPS ARE PASSED)
function renderYears(yearArray, calProps) {
	return yearArray.map(currentYear => {
		return (
			<YearBlock
				key={currentYear}
				currentYear={currentYear}
				daysOfYear={getDaysPerYear(currentYear)}
				calendarView={calProps.calendarView}
				xScroll={calProps.xScroll}
				calProps={calProps}
			/>
		);
	});
}

//Function that builds headerData and returns [headerData, calWidth] based on earliestDate and blockWidth
function generateHeaders(earliestDate, blockWidth) {
	let currentYear = 0;
	let yearStart = earliestDate.getFullYear();
	let currentWeekDay = earliestDate.getDay();
	let yearEnd = earliestDate.getFullYear() + 2;
	let yearList = [];
	let monthList = [];
	let dayList = [];
	for (
		let yearIncrement = 0;
		yearIncrement + yearStart <= yearEnd;
		yearIncrement++
	) {
		currentYear = yearStart + yearIncrement;
		for (let currentMonth = 0; currentMonth < 12; currentMonth++) {
			let daysOfMonth = DAYS_PER_MONTH[currentMonth].days(currentYear);
			for (let currentDay = 1; currentDay <= daysOfMonth; currentDay++) {
				let weekDay = DAYS_OF_WEEK[currentWeekDay++];
				dayList.push({
					currentYear: currentYear,
					currentMonth: currentMonth,
					currentDay: currentDay,
					weekDay: weekDay
				});
				if (currentWeekDay > 6) currentWeekDay = 0;
			}

			monthList.push({
				currentYear: currentYear,
				currentMonth: currentMonth,
				daysOfMonth: daysOfMonth
			});
		}

		yearList.push(currentYear);
	}
	return [[yearList, monthList, dayList], dayList.length * blockWidth];
}

//The top level Bar Calendar component
/*
	Props:
		dataSource - Pass either a list of group objects or a list of lists of group objects (used for layering bars on top of each other, earliest list is displayed on top and later lists are displayed underneath)
		-For now, dataSource is used to build the data used for the bar calendar's bars. However, changes to dataSource are not currently reflected due to the quirks of how I coded this. Might be worth rebuilding maybe? Would probably be easier to use if changes to this prop were directly reflected on the calendar, but for now the bandaid solution is to use the shouldRebuild boolean and onRebuild function when you make changes to dataSource you want to see reflected
			-Groups should contain
				-bars - list of bar objects
					-bars should contain
						-startDate - unix timestamp integer or short day of week string ('Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'), for horizontal position of bar
						-endDate (optional, required if horizontal, unused if vertical) - unix timestamp integer for width of bar when horizontal and width view
						-startTime (optional, unused if horizontal, required if vertical) - time integer from 0 to 23 representing the vertical start of a bar
						-endTime (optional, unused if horizontal, required if vertical) - time integer from 0 to 23 representing the vertical end of a bar
						-eventStyle - string name of style provided to bar calendar in eventStyle prop (if not found, will default to a greyish color)
						-caption - string caption to display on the bar
						-extraEventData (optional) - object that contains props of extra data that should be used if calendar is in clickable mode
						-interactMode (optional, defaults to whatever the calendar is set to) - string that indicates the interact mode of a specific bar
				-group_label (optional) - a string or component to be displayed on the left side of the calendar
		calendarView (optional, defaults to month) - a string that indicates what scope the calendar should view (month, week, day)
		
		targetDate (optional, defaults to current date) - a date object for a point in time that the calendar should skip to
		hideHeader (optional) -  a boolean that indicates if header (display of days/months/years on top) should be hidden
		showTimeBar (optional) - a boolean that indicates if the current date/time should be drawn as a blue line on the calendar
		interactMode (optional, defaults to clickable) - a string that indicates what interaction mode to use for calendar (clickable, editable, none)
		orientation (optional, defaults to horizontal unless interactMode is editable, then vertical is required) -  a string that indicates the direction that bars should flow (horizontal, vertical)
		newEvent (optional) - a function that is performed upon drawing a new event, used to potentially modify the event if the parent component needs to
		editEvent (optional) -  a function that is performed upon any changes to the calendar with edit mode, used to send changes made back to the parent component
		clickEvent (optional) - a function that is performed upon clicking a bar during click mode, passes identifying info and extraEventData to the parent
		blockWidth - an integer defining the width of a block that makes up the calendar !!!!Careful with the width or text might not display well
		blockHeight - an integer defining the height of a block that makes up the calendar !!!!Careful with the height or text might not display well
		eventStyles (optional) - an object that contains properties with names that inform the calendar about sets of styles used for bars
			-styles should contain these props, which contain an object with a bunch of styles formatted the same way as components usually use styles (etc backgroundColor: "#000000")
				-single - style rules used either by default or when not in edit mode
				-text (optional) - style rules used for the text for bar captions
				-start (optional) - style rules used for the top of a bar
				-middle (optional) - style rules used for the midsection of a bar
				-end (optional) - style rules used for the end of a bar
		lockOn - a boolean to indicate if the calendar should move to a specific point in time indicated by the targetDate prop. For now, this is a required prop, you must provide your own lockOn variable in the parent component for barcalendar to function properly. Might be worth changing up so barcalendar uses it's own lockon if none is given
		updateLock - a function used to update the parent's lockOn boolean. For now, this is a required prop, you must provide your own updateLock function in the parent component for barcalendar to function properly. Might be worth changing up so barcalendar uses it's own updateLock if none is given
		
		allowNewEvent - a boolean that allows new bars to be drawn
		restrictToReference - an object that contains props named with unix timestamps for the start date of each reference
			-each unix timestamp-labelled prop should hold an array of reference objects, it's recommended that you merge all references that are touching or overlapping before passing as a prop or else the restriction might not work correctly
				-each reference object should contain these props
					-startTime - time integer from 0 to 23 representing the vertical start of a reference
					-endTime - time integer from 0 to 23 representing the vertical end of a reference
		
		shouldRebuild (optional) - a boolean used to "refresh" the calendar with new dataSource
		onRebuild (optional) - a function that is called once the calendar has rebuilt
		updateSyncData (optional) - a function used to share scrolling data with the parent component
 */
class BarCalendar extends React.Component {
	constructor(props) {
		super(props);
		let currentDate = new Date();
		let yearStart = currentDate.getFullYear();
		let calendarView = this.props.calendarView || 'month';

		//variable for horizontal scroll position
		let xScroll =
			calendarView === 'month'
				? getDateInt(
						currentDate,
						yearStart - 1,
						this.props.blockWidth,
						calendarView
				  )
				: 0;
		let earliestDate = new Date(yearStart - 1, 0, 1);

		//Decides whether there are multiple sources
		let isMultiple =
			this.props.dataSource.length > 0 &&
			Array.isArray(this.props.dataSource[0]);

		//Decides the highest zIndex
		let highestZ = isMultiple ? this.props.dataSource.length + 4 : 5;
		this.state = {
			isMultiple: isMultiple,
			highestZ: highestZ,
			willSync: 'updateSyncData' in this.props,
			todayDate: currentDate,
			earliestDate: earliestDate,
			xScroll: xScroll,
			calKey: 0
		};

		//Used for sending some synchronization data (for separate header and max zindex used)
		if ('updateSyncData' in this.props)
			this.props.updateSyncData({
				xScroll: xScroll,
				earliestDate: earliestDate,
				highestZ: highestZ
			});
	}

	//Function for updating the scroll position
	updateScroll = (xScroll, earliestDate = null) => {
		if (earliestDate) {
			if (this.state.willSync)
				this.props.updateSyncData({
					xScroll: xScroll,
					earliestDate: earliestDate,
					highestZ: this.state.highestZ
				});
			this.setState({ xScroll: xScroll, earliestDate: earliestDate });
		} else {
			if (this.state.willSync)
				this.props.updateSyncData({
					xScroll: xScroll,
					earliestDate: this.state.earliestDate,
					highestZ: this.state.highestZ
				});
			this.setState({ xScroll: xScroll });
		}
	};

	//Function for locking the bar calendar on a specific date, usually used with a Today button or a mini-calendar component
	updateLock = lockOn => {
		if ('updateLock' in this.props) this.props.updateLock(lockOn);
	};
	componentDidUpdate(prevProps) {
		//Code for detecting if the parent component requests the bar calendar to refresh/rebuild itself due to exterior changes
		if (this.props.shouldRebuild && !prevProps.shouldRebuild) {
			this.setState({ calKey: this.state.calKey === 0 ? 1 : 0 });
			if (this.props.onRebuild) {
				this.props.updateLock(false);
				this.props.onRebuild();
			}
		}
	}
	render() {
		let calOrientation =
			this.props.interactMode === 'editable'
				? 'vertical'
				: this.props.orientation || 'horizontal';
		return (
			<RouteOne
				key={this.state.calKey}
				dataSource={this.props.dataSource}
				calendarView={this.props.calendarView || 'month'}
				todayDate={this.state.todayDate}
				targetDate={this.props.targetDate}
				hideHeader={this.props.hideHeader || false}
				showTimeBar={this.props.showTimeBar}
				interactMode={this.props.interactMode || 'clickable'}
				orientation={calOrientation}
				newEvent={this.props.newEvent}
				editEvent={this.props.editEvent}
				clickEvent={this.props.clickEvent}
				blockWidth={this.props.blockWidth}
				blockHeight={this.props.blockHeight}
				eventStyles={this.props.eventStyles}
				earliestDate={this.state.earliestDate}
				xScroll={this.state.xScroll}
				lockOn={this.props.lockOn}
				updateScroll={this.updateScroll}
				updateLock={this.updateLock}
				scrollHeight={this.props.scrollHeight}
				scrollWidth={this.props.scrollWidth}
				allowNewEvents={this.props.allowNewEvents}
				isMultiple={this.state.isMultiple}
				restrictToReference={this.props.restrictToReference}
			/>
		);
	}
}

//Lower level calendar component (was originally going to be used to make multiple separate calendar components for different purposes depending on props passed)
//This idea just got left behind, but might be worth a revisit later to clean up the Bar Calendar code.
class RouteOne extends React.Component {
	constructor(props) {
		super(props);
		let calendarView = this.props.calendarView;
		let currentDate = new Date();
		let yearStart = currentDate.getFullYear();
		let rowLabelList = [];
		let calFullBlockList = [];
		let calGroupList = [];
		let verticalList = [];
		let horizontalList = [];
		let totalNumDays = 0;
		let eventStyles =
			typeof this.props.eventStyles === 'object'
				? this.props.eventStyles
				: {
						default: {
							start: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							middle: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							end: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							},
							single: {
								opacity: 0.65,
								backgroundColor: '#778ca2'
							}
						}
				  };
		let calBlockList;
		let highestId = 0;
		let headerData = [];
		let calWidth;

		//Calculates the total days of the three years currently loaded in memory
		for (let i = yearStart - 1; i <= yearStart + 1; i++) {
			totalNumDays += getDaysPerYear(i);
		}

		//Decides what kind of calendar data to build, depending on if horizontal or vertical and if there are multiple sources
		if (this.props.orientation === 'horizontal') {
			if (this.props.isMultiple) {
				//Builds a list of individual sources within the data source
				this.props.dataSource.forEach((individualSource, individualIndex) => {
					calGroupList = [];
					//Builds a list of groups within the individual source
					individualSource.forEach((blockGroup, groupIndex) => {
						//Maps the calendar bars to a list to be displayed
						calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
							if ('event_id' in barBlock && barBlock.event_id >= highestId)
								highestId = barBlock.event_id + 1;
							return {
								...barBlock,
								startDate:
									calendarView === 'month'
										? new Date(barBlock.startDate)
										: barBlock.startDate,
								endDate:
									barBlock.endDate !== null
										? calendarView === 'month'
											? new Date(barBlock.endDate)
											: barBlock.endDate
										: null,
								event_id: barBlock.event_id,
								withTimeOfDay: true,
								eventStyle: barBlock.eventStyle,
								eventStyles: eventStyles,
								caption: barBlock.caption,
								extraEventData: barBlock.extraEventData
							};
						});

						//Decides whether or not to add a group label on the side of the calendar
						if ('group_label' in blockGroup)
							rowLabelList.push(
								<div
									draggable={false}
									key={groupIndex.toString()}
									style={{
										WebkitUserSelect: 'none',
										msUserSelect: 'none',
										userSelect: 'none',
										fontSize: '90%',
										height: this.props.blockHeight,
										border: '1px solid #e8ecef',
										color: '#252631'
									}}
								>
									{blockGroup.group_label}
								</div>
							);

						calGroupList.push(calBlockList);
					});
					calFullBlockList.push(calGroupList);
				});
			} else {
				calGroupList = [];
				//Builds a list of groups within the data source
				this.props.dataSource.forEach((blockGroup, groupIndex) => {
					//Maps the calendar bars to a list to be displayed
					calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
						if ('event_id' in barBlock && barBlock.event_id >= highestId)
							highestId = barBlock.event_id + 1;
						return {
							...barBlock,
							startDate:
								calendarView === 'month'
									? new Date(barBlock.startDate)
									: barBlock.startDate,
							endDate:
								barBlock.endDate !== null
									? calendarView === 'month'
										? new Date(barBlock.endDate)
										: barBlock.endDate
									: null,
							event_id: barBlock.event_id,
							withTimeOfDay: true,
							eventStyle: barBlock.eventStyle,
							eventStyles: eventStyles,
							caption: barBlock.caption,
							extraEventData: barBlock.extraEventData
						};
					});

					//Decides whether or not to add a group label on the side of the calendar
					if ('group_label' in blockGroup)
						rowLabelList.push(
							<div
								draggable={false}
								key={blockGroup.group_label + groupIndex.toString()}
								style={{
									WebkitUserSelect: 'none',
									msUserSelect: 'none',
									userSelect: 'none',
									fontSize: '90%',
									height: this.props.blockHeight,
									border: '1px solid #e8ecef',
									color: '#252631'
								}}
							>
								{blockGroup.group_label}
							</div>
						);

					calGroupList.push(calBlockList);
				});
				calFullBlockList.push(calGroupList);
			}
		} else {
			if (this.props.isMultiple) {
				//Builds a list of individual sources within the data source
				this.props.dataSource.forEach((individualSource, individualIndex) => {
					calGroupList = [];
					//Builds a list of groups within the individual source
					individualSource.forEach((blockGroup, groupIndex) => {
						//Maps the calendar bars to a list to be displayed
						calBlockList = blockGroup.bars.map((barBlock, blockIndex) => {
							if ('event_id' in barBlock && barBlock.event_id >= highestId)
								highestId = barBlock.event_id + 1;
							return {
								...barBlock,
								startTime: barBlock.startTime,
								endTime: barBlock.endTime,
								event_id: barBlock.event_id,
								startDate:
									calendarView === 'month'
										? new Date(barBlock.startDate)
										: barBlock.startDate,
								withTimeOfDay: true,
								eventStyle: barBlock.eventStyle,
								eventStyles: eventStyles,
								caption: barBlock.caption,
								extraEventData: barBlock.extraEventData
							};
						});

						calGroupList.push(calBlockList);
					});
					calFullBlockList.push(calGroupList);
				});
			} else {
				//Builds a list of groups within the data source
				calGroupList = [];
				this.props.dataSource.forEach((blockGroup, groupIndex) => {
					//Maps the calendar bars to a list to be displayed
					calBlockList = blockGroup.bars?.map((barBlock, blockIndex) => {
						if ('event_id' in barBlock && barBlock.event_id >= highestId)
							highestId = barBlock.event_id + 1;
						return {
							...barBlock,
							startTime: barBlock.startTime,
							endTime: barBlock.endTime,
							event_id: barBlock.event_id,
							startDate:
								calendarView === 'month'
									? new Date(barBlock.startDate)
									: barBlock.startDate,
							withTimeOfDay: false,
							eventStyle: barBlock.eventStyle,
							eventStyles: eventStyles,
							caption: barBlock.caption,
							extraEventData: barBlock.extraEventData
						};
					});

					calGroupList.push(calBlockList);
				});
				calFullBlockList.push(calGroupList);
			}
			//Builds a list of times of day to display on the side of the calendar
			for (let currentHour = 0; currentHour < 24; currentHour++) {
				rowLabelList.push(
					<div
						draggable={false}
						key={currentHour}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							fontSize: '90%',
							height: this.props.blockHeight,
							lineHeight: `${this.props.blockHeight}px`,
							color: '#252631',
							textAlign: 'right'
						}}
					>
						{getDayHour(currentHour)}
						<span style={{ color: '#e8ecef', textDecoration: 'line-through' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</div>
				);
			}
		}
		if (calendarView === 'month') {
			//Builds headers for month formatted calendar
			let generatedHeader = generateHeaders(
				this.props.earliestDate,
				this.props.blockWidth
			);
			headerData = generatedHeader[0];
			calWidth = generatedHeader[1];
		} else if (calendarView === 'week') {
			//Builds headers for week formatted calendar
			headerData = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'].map(
				day => {
					return (
						<div
							draggable={false}
							style={{
								WebkitUserSelect: 'none',
								msUserSelect: 'none',
								userSelect: 'none',
								paddingTop: Math.round(this.props.blockHeight / (50 / 10)),
								fontSize: Math.round(this.props.blockWidth / (50 / 15)),
								width: this.props.blockWidth,
								color: '#252631',
								border: '1px solid #e8ecef',
								textAlign: 'center',
								display: 'inline',
								float: 'left'
							}}
							key={day}
						>
							{day}
						</div>
					);
				}
			);
			calWidth = 7 * this.props.blockWidth;
		} else if (calendarView === 'day') {
			//Prepares for a day formatted calendar
			calWidth = this.props.blockWidth;
		}

		let boxHeight = this.props.blockHeight * rowLabelList.length;
		let boxWidth = this.props.blockWidth * totalNumDays;
		let blockColor;

		//Builds the background grid for the calendar depending on which view format is selected
		if (calendarView === 'month') {
			for (
				let verticalBlock = 0;
				verticalBlock < totalNumDays;
				verticalBlock++
			) {
				verticalList.push(
					<div
						draggable={false}
						key={verticalBlock}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							float: 'left',
							position: 'relative',
							height: boxHeight,
							width: this.props.blockWidth,
							border: `1px solid #e8ecef`,
							backgroundColor: blockColor
						}}
					/>
				);
			}
			for (
				let horizontalBlock = 0;
				horizontalBlock < rowLabelList.length;
				horizontalBlock++
			) {
				horizontalList.push(
					<div
						draggable={false}
						key={horizontalBlock}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							float: 'left',
							clear: 'both',
							position: 'relative',
							height: this.props.blockHeight,
							width: boxWidth,
							border: `1px solid #e8ecef`
						}}
					/>
				);
			}
		} else if (calendarView === 'week') {
			for (let verticalBlock = 0; verticalBlock < 6; verticalBlock++) {
				verticalList.push(
					<div
						draggable={false}
						key={verticalBlock}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							float: 'left',
							position: 'relative',
							height: boxHeight,
							width: this.props.blockWidth,
							border: `1px solid #e8ecef`,
							backgroundColor: blockColor
						}}
					/>
				);
			}
			for (
				let horizontalBlock = 0;
				horizontalBlock < rowLabelList.length;
				horizontalBlock++
			) {
				horizontalList.push(
					<div
						draggable={false}
						key={horizontalBlock}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							float: 'left',
							clear: 'both',
							position: 'relative',
							height: this.props.blockHeight,
							width: this.props.blockWidth * 7,
							border: `1px solid #e8ecef`
						}}
					/>
				);
			}
		} else if (calendarView === 'day') {
			verticalList = [
				<div
					draggable={false}
					key={0}
					style={{
						WebkitUserSelect: 'none',
						msUserSelect: 'none',
						userSelect: 'none',
						float: 'left',
						position: 'relative',
						height: boxHeight,
						width: this.props.blockWidth,
						border: `1px solid #e8ecef`,
						backgroundColor: blockColor
					}}
				/>
			];
			for (
				let horizontalBlock = 0;
				horizontalBlock < rowLabelList.length;
				horizontalBlock++
			) {
				horizontalList.push(
					<div
						draggable={false}
						key={horizontalBlock}
						style={{
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							float: 'left',
							clear: 'both',
							position: 'relative',
							height: this.props.blockHeight,
							width: this.props.blockWidth,
							border: `1px solid #e8ecef`
						}}
					/>
				);
			}
		}

		this.state = {
			leftYearBound: getDateInt(
				new Date(yearStart, 0, 1),
				yearStart - 1,
				this.props.blockWidth,
				calendarView
			),
			rightYearBound: getDateInt(
				new Date(yearStart + 1, 0, 1),
				yearStart - 1,
				this.props.blockWidth,
				calendarView
			),
			currentDate: currentDate,
			currentYear: currentDate.getFullYear(),
			loadedData: {
				rowLabelList,
				calFullBlockList,
				verticalList,
				horizontalList
			},
			headerData: headerData,
			modalData: null,
			totalNumDays: totalNumDays,
			dragData: null,
			highestId: highestId,
			calWidth: calWidth
		};

		this.topScroll = React.createRef();
		this.calScroll = React.createRef();
		this.sideScroll = React.createRef();
	}

	//Function for updating scroll, mainly used for loading a new set of years if moving past the bounds of currently loaded years
	//Basically, bar calendar loads 3 years worth of background tiles and headers at once.
	//The calendar loads a new set of these upon leaving the currently focused year.
	//If scrolling through 2022 and you scroll past January or December, the new sets of data get loaded.
	scrollUpdate = scrollLeft => {
		if (this.props.calendarView === 'month') {
			//Load Earlier Year
			if (scrollLeft < this.state.leftYearBound) {
				let earliestDate = new Date(
					this.props.earliestDate.getFullYear() - 1,
					0,
					1
				);
				let generatedHeader = generateHeaders(
					earliestDate,
					this.props.blockWidth
				);
				let headerData = generatedHeader[0];
				let calWidth = generatedHeader[1];
				let yearLength = getDateDiff(
					this.props.earliestDate,
					earliestDate,
					this.props.blockWidth
				);
				this.setState({
					leftYearBound: getDateInt(
						this.props.earliestDate,
						earliestDate.getFullYear(),
						this.props.blockWidth,
						this.props.calendarView
					),
					rightYearBound: getDateInt(
						new Date(this.props.earliestDate.getFullYear() + 1, 0, 1),
						earliestDate.getFullYear(),
						this.props.blockWidth,
						this.props.calendarView
					),
					currentYear: this.props.earliestDate.getFullYear(),
					headerData: headerData,
					calWidth: calWidth
				});
				this.props.updateScroll(scrollLeft + yearLength, earliestDate);
				this.props.updateLock(true);
			}
			//Load Later Year
			else if (scrollLeft > this.state.rightYearBound) {
				let earliestDate = new Date(
					this.props.earliestDate.getFullYear() + 1,
					0,
					1
				);
				let generatedHeader = generateHeaders(
					earliestDate,
					this.props.blockWidth
				);
				let headerData = generatedHeader[0];
				let calWidth = generatedHeader[1];
				let yearLength = getDateDiff(
					earliestDate,
					this.props.earliestDate,
					this.props.blockWidth
				);
				this.setState({
					leftYearBound: getDateInt(
						new Date(this.props.earliestDate.getFullYear() + 2, 0, 1),
						earliestDate.getFullYear(),
						this.props.blockWidth,
						this.props.calendarView
					),
					rightYearBound: getDateInt(
						new Date(this.props.earliestDate.getFullYear() + 3, 0, 1),
						earliestDate.getFullYear(),
						this.props.blockWidth,
						this.props.calendarView
					),
					currentYear: this.props.earliestDate.getFullYear(),
					headerData: headerData,
					calWidth: calWidth
				});
				this.props.updateScroll(scrollLeft - yearLength, earliestDate);
				this.props.updateLock(true);
			} else {
				this.props.updateScroll(scrollLeft);
				this.props.updateLock(false);
			}
		}
	};

	//Function mainly used for displaying the bar "extending" or moving across days upon moving the mouse
	onMouseMove = event => {
		if (this.state.dragData !== null) {
			//Buffer for that tiny bit of space you see on top of the vertical calendar. This was a bandaid solution to line up the times of day with the calendar grid
			let topBuffer =
				this.props.orientation === 'vertical' ? this.props.blockHeight / 2 : 0;
			//Gets block horizontal position of the mouse
			let calBox = this.calScroll.current.getBoundingClientRect();
			let xPos = Math.floor(
				(Math.abs(Math.floor(calBox.left) - event.pageX) +
					this.calScroll.current.scrollLeft) /
					this.props.blockWidth
			);

			//Calculates the date based on h position of drawing
			//(can't move across dates when restricting to reference, allowing this made the UI feel clunky and had potential issues)
			let targetDate = null;
			if (!this.props.restrictToReference) {
				if (this.props.calendarView === 'month') {
					targetDate = new Date(this.props.earliestDate.valueOf());
					targetDate.setDate(targetDate.getDate() + xPos);
				} else if (this.props.calendarView === 'week') {
					targetDate = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'][
						xPos
					];
				}
			} else {
				targetDate =
					this.state.loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					].startDate;
			}

			//Calculates vertical block position based on cursor
			let yPos = Math.floor(
				(Math.abs(Math.floor(calBox.top + topBuffer) - event.pageY) +
					this.calScroll.current.scrollTop) /
					this.props.blockHeight
			);
			//Code to execute if we move cursor vertically
			if (
				(this.state.dragData.grabPoint === 'A' &&
					this.state.loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					].startTime !== yPos) ||
				(this.state.dragData.grabPoint === 'B' &&
					this.state.loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					].endTime !==
						yPos + 1)
			) {
				let loadedData = { ...this.state.loadedData };
				let dragData = { ...this.state.dragData };
				//Code to switch grab points if you move cursor past the one end of the block while dragging the opposite end of the block
				if (
					dragData.grabPoint === 'A' &&
					yPos >=
						loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
							dragData.selectedBar.groupIndex
						][dragData.selectedBar.blockIndex].endTime
				) {
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].startTime =
						loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
							dragData.selectedBar.groupIndex
						][dragData.selectedBar.blockIndex].endTime - 1;
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].endTime = yPos + 1;
					dragData.grabPoint = 'B';
				} else if (
					dragData.grabPoint === 'B' &&
					yPos <=
						loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
							dragData.selectedBar.groupIndex
						][dragData.selectedBar.blockIndex].startTime
				) {
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].endTime =
						loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
							dragData.selectedBar.groupIndex
						][dragData.selectedBar.blockIndex].startTime + 1;
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].startTime = yPos;
					dragData.grabPoint = 'A';
				}
				//Changes start time or end time to the vertical cursor position depending on which grab point we have grabbed
				else if (dragData.grabPoint === 'A') {
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].startTime = yPos;
				} else {
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].endTime = yPos + 1;
				}
				//Changes date based on horizontal position
				//(unlikely to happen unless the user drags while perfectly changing both vertical and horizontal block position at the same time)
				//(This code is a failsafe in the unlikely event scenario)
				if (
					!this.props.restrictToReference &&
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].startDate !== targetDate
				)
					loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
						dragData.selectedBar.groupIndex
					][dragData.selectedBar.blockIndex].startDate = targetDate;
				//Code for clamping what you draw to the reference layer based on an event id assigned to the newly dragged event up starting a drag
				if (this.props.restrictToReference) {
					let editedEvent =
						loadedData.calFullBlockList[dragData.selectedBar.sourceIndex][
							dragData.selectedBar.groupIndex
						][dragData.selectedBar.blockIndex];
					let refEvent =
						this.props.restrictToReference[targetDate.valueOf()][
							editedEvent.refIndex
						];
					loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					].startTime = clampVal(
						refEvent.startTime,
						editedEvent.startTime,
						refEvent.endTime - 1
					);
					loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					].endTime = clampVal(
						refEvent.startTime + 1,
						editedEvent.endTime,
						refEvent.endTime
					);
				}
				this.setState({ loadedData: loadedData, dragData: dragData });
			}
			//Code to execute if we move cursor horizontally (doesn't execute if restricted for previously stated reasons)
			else if (
				!this.props.restrictToReference &&
				this.state.loadedData.calFullBlockList[
					this.state.dragData.selectedBar.sourceIndex
				][this.state.dragData.selectedBar.groupIndex][
					this.state.dragData.selectedBar.blockIndex
				].startDate.valueOf() !== targetDate.valueOf()
			) {
				let loadedData = { ...this.state.loadedData };
				loadedData.calFullBlockList[
					this.state.dragData.selectedBar.sourceIndex
				][this.state.dragData.selectedBar.groupIndex][
					this.state.dragData.selectedBar.blockIndex
				].startDate = targetDate;
				this.setState({ loadedData: loadedData });
			}
		}
	};
	//Function to execute upon letting go of the left mouse button when dragging
	commitEditData = async event => {
		if (event.button === 0 && this.state.dragData !== null) {
			let loadedData = { ...this.state.loadedData };
			//Code to run if the event being dragged is new
			if (this.state.dragData.isNew) {
				let oldDragData = { ...this.state.dragData };
				let highestId = this.state.highestId;
				this.setState({ dragData: null });
				//Removing the reference value we use to know which reference to clamp to
				delete loadedData.calFullBlockList[oldDragData.selectedBar.sourceIndex][
					oldDragData.selectedBar.groupIndex
				][oldDragData.selectedBar.blockIndex].refIndex;

				//Passing the new event data to the parent component for potential added extra data that the parent might want to add
				let newEditData =
					'newEvent' in this.props
						? await this.props.newEvent({
								...loadedData.calFullBlockList[
									oldDragData.selectedBar.sourceIndex
								][oldDragData.selectedBar.groupIndex][
									oldDragData.selectedBar.blockIndex
								]
						  })
						: {
								...loadedData.calFullBlockList[
									oldDragData.selectedBar.sourceIndex
								][oldDragData.selectedBar.groupIndex][
									oldDragData.selectedBar.blockIndex
								]
						  };
				newEditData.startDate = new Date(newEditData.startDate);

				/*//If source is changed
        if (newEditData.sourceIndex !== oldDragData.selectedBar.sourceIndex) {
          //If source is new
          if (newEditData.sourceIndex >= loadedData.calFullBlockList.length) {
            loadedData.calFullBlockList.push([]);
            let newSourceIndex = loadedData.calFullBlockList.length - 1;
            loadedData.calFullBlockList[newSourceIndex].push([]);
            newEditData.sourceIndex = newSourceIndex;
            newEditData.groupIndex = 0;
            newEditData.blockIndex = 0;
            loadedData.calFullBlockList[newSourceIndex][0].push(newEditData);
          }
          //If source exists
          else {
            //If group is new
            if (newEditData.groupIndex >= loadedData.calFullBlockList[newEditData.sourceIndex].length) {
              loadedData.calFullBlockList[newEditData.sourceIndex].push([]);
              let newGroupIndex = loadedData.calFullBlockList[newEditData.sourceIndex].length - 1;
              newEditData.groupIndex = newGroupIndex;
              newEditData.blockIndex = 0;
              loadedData.calFullBlockList[newEditData.sourceIndex][newGroupIndex].push();
            }
          }
        } else*/
				loadedData.calFullBlockList[newEditData.sourceIndex][
					newEditData.groupIndex
				][newEditData.blockIndex] = newEditData;

				//Code for detecting if there should be merging/editing done instead of making a new event
				let shouldMerge = false;
				let newMergeIds = [];
				let oldEditData = null;
				//Checking through the new event's group for blocks to edit/merge with
				for (
					let currentBlock = 0;
					currentBlock <
					loadedData.calFullBlockList[newEditData.sourceIndex][
						newEditData.groupIndex
					].length;
					currentBlock++
				) {
					let blockObj =
						loadedData.calFullBlockList[newEditData.sourceIndex][
							newEditData.groupIndex
						][currentBlock];
					//Checking if the current block being examined has the same date value as the new event
					if (
						newEditData.startDate.toString() ===
							blockObj.startDate.toString() &&
						currentBlock !== newEditData.blockIndex
					) {
						//Check for overlapping blocks
						if (
							(blockObj.startTime >= newEditData.startTime &&
								blockObj.startTime <= newEditData.endTime) ||
							(blockObj.endTime >= newEditData.startTime &&
								blockObj.endTime <= newEditData.endTime) ||
							(newEditData.startTime >= blockObj.startTime &&
								newEditData.startTime <= blockObj.endTime) ||
							(newEditData.endTime >= blockObj.startTime &&
								newEditData.endTime <= blockObj.endTime)
						) {
							//Upon initial overlap, we simply remove the new event and combine the two events' data, then apply that data to the overlapped data as an edit operation
							if (!shouldMerge) {
								if (newEditData.event_id + 1 === this.state.highestId)
									highestId--;
								shouldMerge = true;
								oldEditData = newEditData;
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								].splice(newEditData.blockIndex, 1);
								newEditData = {
									...oldEditData,
									...blockObj,
									startTime: Math.min(
										oldEditData.startTime,
										blockObj.startTime
									),
									endTime: Math.max(oldEditData.endTime, blockObj.endTime),
									blockIndex: currentBlock
								};
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								][currentBlock].startTime = newEditData.startTime;
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								][currentBlock].endTime = newEditData.endTime;
							}
							//Upon further overlaps, we remove these new overlapping events, combine the prior event's info with the latter and add the removed id to the list of merged ids
							else {
								if (blockObj.event_id + 1 === this.state.highestId) highestId--;
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								].splice(currentBlock, 1);
								newEditData.startTime = Math.min(
									blockObj.startTime,
									newEditData.startTime
								);
								newEditData.endTime = Math.max(
									blockObj.endTime,
									newEditData.endTime
								);
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								][newEditData.blockIndex].startTime = newEditData.startTime;
								loadedData.calFullBlockList[newEditData.sourceIndex][
									newEditData.groupIndex
								][newEditData.blockIndex].endTime = newEditData.endTime;
								currentBlock--;
								newMergeIds.push(blockObj.event_id);
							}
						}
					}
				}

				this.setState({ loadedData: loadedData, highestId: highestId });

				//Proceeds with a different edit operation depending on how merge is compiled
				if ('editEvent' in this.props)
					this.props.editEvent(
						shouldMerge
							? newMergeIds.length > 0
								? {
										operation: 'merge',
										id: newEditData.event_id,
										delete_ids: newMergeIds,
										data: newEditData
								  }
								: {
										operation: 'edit',
										id: newEditData.event_id,
										data: newEditData
								  }
							: {
									operation: 'new',
									id: oldDragData.selectedBar.event_id,
									data: newEditData
							  }
					);
			}
			//Code to run if the event being dragged is previously drawn
			else {
				let newSourceIndex = this.state.dragData.selectedBar.sourceIndex;
				let newGroupIndex = this.state.dragData.selectedBar.groupIndex;
				let newBlockIndex = this.state.dragData.selectedBar.blockIndex;
				//Removing the reference value we use to know which reference to clamp to
				delete loadedData.calFullBlockList[newSourceIndex][newGroupIndex][
					newBlockIndex
				].refIndex;
				let newEditData =
					loadedData.calFullBlockList[newSourceIndex][newGroupIndex][
						newBlockIndex
					];
				let editObj = {
					operation: 'edit',
					id: this.state.dragData.selectedBar.event_id,
					data: loadedData.calFullBlockList[
						this.state.dragData.selectedBar.sourceIndex
					][this.state.dragData.selectedBar.groupIndex][
						this.state.dragData.selectedBar.blockIndex
					]
				};
				let highestId = this.state.highestId;
				//Checking through the new event's group for blocks to merge with
				for (
					let currentBlock = 0;
					currentBlock <
					loadedData.calFullBlockList[newSourceIndex][newGroupIndex].length;
					currentBlock++
				) {
					let blockObj =
						loadedData.calFullBlockList[newSourceIndex][newGroupIndex][
							currentBlock
						];
					let newId;
					//Checking if the current block being examined has the same date value as the new event
					if (
						newEditData.startDate.toString() ===
							blockObj.startDate.toString() &&
						currentBlock !== newBlockIndex
					) {
						//Check for overlapping blocks
						if (
							(blockObj.startTime >= newEditData.startTime &&
								blockObj.startTime <= newEditData.endTime) ||
							(blockObj.endTime >= newEditData.startTime &&
								blockObj.endTime <= newEditData.endTime) ||
							(newEditData.startTime >= blockObj.startTime &&
								newEditData.startTime <= blockObj.endTime) ||
							(newEditData.endTime >= blockObj.startTime &&
								newEditData.endTime <= blockObj.endTime)
						) {
							//On initial merge we combine the two event datas into the whichever event has the earliest event_id and changes the operation
							if (editObj.operation === 'edit') {
								if (newEditData.event_id < blockObj.event_id) {
									newEditData.startTime = Math.min(
										newEditData.startTime,
										blockObj.startTime
									);
									newEditData.endTime = Math.max(
										newEditData.endTime,
										blockObj.endTime
									);
									newId = blockObj.event_id;
									if (blockObj.event_id + 1 === this.state.highestId)
										highestId--;
									loadedData.calFullBlockList[newSourceIndex][
										newGroupIndex
									].splice(currentBlock, 1);
									editObj = {
										operation: 'merge',
										id: editObj.id,
										delete_ids: [newId],
										data: newEditData
									};
									if (currentBlock > 0) currentBlock--;
								} else {
									blockObj.startTime = Math.min(
										newEditData.startTime,
										blockObj.startTime
									);
									blockObj.endTime = Math.max(
										newEditData.endTime,
										blockObj.endTime
									);
									newId = blockObj.event_id;
									if (newEditData.event_id + 1 === this.state.highestId)
										highestId--;
									newEditData = blockObj;
									loadedData.calFullBlockList[newSourceIndex][
										newGroupIndex
									].splice(newBlockIndex, 1);
									if (newBlockIndex < currentBlock) currentBlock--;
									newBlockIndex = currentBlock;
									editObj = {
										operation: 'merge',
										id: newId,
										delete_ids: [editObj.id],
										data: newEditData
									};
								}
							}
							//On further merges we combine the two event datas into the whichever event has the earliest event_id
							else {
								if (newEditData.event_id < blockObj.event_id) {
									newEditData.startTime = Math.min(
										newEditData.startTime,
										blockObj.startTime
									);
									newEditData.endTime = Math.max(
										newEditData.endTime,
										blockObj.endTime
									);
									newId = blockObj.event_id;
									if (blockObj.event_id + 1 === this.state.highestId)
										highestId--;
									loadedData.calFullBlockList[newSourceIndex][
										newGroupIndex
									].splice(currentBlock, 1);
									editObj.delete_ids.push(newId);
									if (currentBlock > 0) currentBlock--;
								} else {
									blockObj.startTime = Math.min(
										newEditData.startTime,
										blockObj.startTime
									);
									blockObj.endTime = Math.max(
										newEditData.endTime,
										blockObj.endTime
									);
									newId = blockObj.event_id;
									if (newEditData.event_id + 1 === this.state.highestId)
										highestId--;
									newEditData = blockObj;
									loadedData.calFullBlockList[newSourceIndex][
										newGroupIndex
									].splice(newBlockIndex, 1);
									if (newBlockIndex < currentBlock) currentBlock--;
									newBlockIndex = currentBlock;
									editObj.delete_ids.push(editObj.id);
									editObj.id = newId;
								}
							}
						}
					}
				}
				this.setState({ dragData: null, highestId: highestId });
				if ('editEvent' in this.props) this.props.editEvent(editObj);
			}
		}
	};
	//Function to execute upon cursor leaving the calendar
	letGoEditData = () => {
		//Executes if there is dragging data present
		if (this.state.dragData !== null) {
			let loadedData = { ...this.state.loadedData };
			let highestId = this.state.highestId;
			//If the event being dragged is new, we simply erase it completely
			if (!this.state.dragData.isNew)
				loadedData.calFullBlockList[
					this.state.dragData.selectedBar.sourceIndex
				][this.state.dragData.selectedBar.groupIndex][
					this.state.dragData.selectedBar.blockIndex
				] = this.state.dragData.formerData;
			//If the event being dragged is one that was created previously and is simply being edited, we reset it's data back to what it was
			else {
				loadedData.calFullBlockList[
					this.state.dragData.selectedBar.sourceIndex
				][this.state.dragData.selectedBar.groupIndex].splice(
					this.state.dragData.selectedBar.blockIndex,
					1
				);
			}
			if (this.state.dragData.selectedBar.event_id + 1 === this.state.highestId)
				--highestId;
			this.setState({
				dragData: null,
				loadedData: loadedData,
				highestId: highestId
			});
		}
	};
	//Function to execute when we initially click to start
	startEdit = (
		sourceIndex,
		groupIndex,
		blockIndex,
		event_id,
		grabPoint,
		isNew = false
	) => {
		//Only starts editing if the interaction mode is set to editable
		if (this.props.interactMode === 'editable') {
			let loadedData = { ...this.state.loadedData };
			if (this.props.restrictToReference) {
				//Locates a proper reference event and assigns a refIndex to the event being edited so the editing code knows what reference event to restrict editing to
				let eventData =
					loadedData.calFullBlockList[sourceIndex][groupIndex][blockIndex];
				let targetDateVal = eventData.startDate.valueOf();
				if (targetDateVal in this.props.restrictToReference) {
					let possibleRefs = this.props.restrictToReference[targetDateVal];
					possibleRefs.some((refEvent, refIndex) => {
						if (
							eventData.startTime >= refEvent.startTime &&
							eventData.startTime <= refEvent.endTime - 1
						) {
							loadedData.calFullBlockList[sourceIndex][groupIndex][
								blockIndex
							].refIndex = refIndex;
							return true;
						}
					});
				}

				this.setState({ loadedData: loadedData });
			}
			//Sets up the dragData used throughout editing code
			this.setState({
				dragData: {
					selectedBar: {
						sourceIndex: sourceIndex,
						groupIndex: groupIndex,
						blockIndex: blockIndex,
						event_id: event_id
					},
					grabPoint: grabPoint,
					formerData: {
						...this.state.loadedData.calFullBlockList[sourceIndex][groupIndex][
							blockIndex
						]
					},
					isNew: isNew
				}
			});
		}
	};
	//Function for deleting events from the calendar
	deleteEvent = (sourceIndex, groupIndex, blockIndex, event_id) => {
		//Removes the event from the calendar and informs the parent that a deletion has been made as well as what event id was deleted
		let loadedData = { ...this.state.loadedData };
		let editedData = { operation: 'delete', id: event_id, data: null };
		let highestId = this.state.highestId;
		if (event_id + 1 === this.state.highestId) --highestId;
		loadedData.calFullBlockList[sourceIndex][groupIndex].splice(blockIndex, 1);
		this.setState({
			loadedData: loadedData,
			dragData: null,
			highestId: highestId
		});
		if ('editEvent' in this.props) this.props.editEvent(editedData);
	};

	//Locking on when mounting the component
	componentDidMount() {
		this.props.updateLock(true);
	}

	componentDidUpdate(prevProps) {
		//If the target date changes, we potentially load new background tile data as well as new header data
		if (
			prevProps.targetDate &&
			this.props.targetDate &&
			prevProps.targetDate.getTime() !== this.props.targetDate.getTime()
		) {
			if (this.props.calendarView === 'month') {
				//Load New Data from Outside Current Bounds
				let newDatePix = getDateInt(
					this.props.targetDate,
					this.props.earliestDate.getFullYear(),
					this.props.blockWidth,
					this.props.calendarView
				);
				if (
					newDatePix < this.state.leftYearBound ||
					newDatePix > this.state.rightYearBound
				) {
					let earliestDate = new Date(
						this.props.targetDate.getFullYear() - 1,
						0,
						1
					);
					let generatedHeader = generateHeaders(
						earliestDate,
						this.props.blockWidth
					);
					let headerData = generatedHeader[0];
					let calWidth = generatedHeader[1];
					this.setState({
						leftYearBound: getDateInt(
							new Date(earliestDate.getFullYear() + 1, 0, 1),
							earliestDate.getFullYear(),
							this.props.blockWidth,
							this.props.calendarView
						),
						rightYearBound: getDateInt(
							new Date(earliestDate.getFullYear() + 2, 0, 1),
							earliestDate.getFullYear(),
							this.props.blockWidth,
							this.props.calendarView
						),
						currentYear: this.props.targetDate.getFullYear(),
						headerData: headerData,
						calWidth: calWidth
					});
					newDatePix = getDateInt(
						this.props.targetDate,
						earliestDate.getFullYear(),
						this.props.blockWidth,
						this.props.calendarView
					);
					this.props.updateScroll(newDatePix, earliestDate);
					this.props.updateLock(true);
					//Jump Within Bounds
				} else {
					this.props.updateScroll(newDatePix);
					this.props.updateLock(true);
				}
			}
		}
	}

	render() {
		let calHeight =
			this.props.blockHeight * this.state.loadedData.rowLabelList.length;
		let calOverflowX;
		let scrollBarSize;
		let headerData = [];

		//Sets up these variables with different values depeding on the type of calendar view as well as orientation
		if (this.props.calendarView === 'month') {
			scrollBarSize =
				this.props.scrollHeight +
				(this.props.orientation === 'vertical'
					? this.props.blockHeight / 2
					: 0);
			calOverflowX = 'scroll';
			headerData = [
				// renderYears(this.state.headerData[0], this.props),
				renderMonths(this.state.headerData[1], this.props),
				renderMonthDays(this.state.headerData[2], this.props)
			];
		} else if (this.props.calendarView === 'week') {
			scrollBarSize = 0;
			calOverflowX = 'hidden';
			headerData = [this.state.headerData];
		} else if (this.props.calendarView === 'day') {
			scrollBarSize = 0;
		}

		//Code for locking on to a specific position in time, but only able to run if the references for the calendar elements are available
		if (this.calScroll.current !== null) {
			if (this.props.lockOn)
				this.calScroll.current.scrollLeft = this.props.xScroll;
		}
		if (this.topScroll.current !== null) {
			if (
				this.props.lockOn ||
				this.topScroll.current.scrollLeft !== this.props.xScroll
			)
				this.topScroll.current.scrollLeft = this.props.xScroll;
		}

		//Defines the layer numbers for zIndex, in order to ensure all calendar events are placed on top of each other correctly
		let zIndexLayers = this.state.loadedData.calFullBlockList.length + 4;

		return (
			<div
				draggable={false}
				style={{
					cursor: this.state.dragData !== null ? 'ns-resize' : 'default',
					maxWidth: this.props.blockWidth * 4.5 + this.state.calWidth
				}}
			>
				{/* Show header unless hidden or empty*/}
				{!this.props.hideHeader && this.state.headerData.length > 0 ? (
					<div
						draggable={false}
						style={{
							overflowX: 'hidden',
							marginLeft: this.props.blockWidth * 4.5,
							border: '1px solid #e8ecef',
							backgroundColor: 'white',
							maxWidth: this.state.calWidth
						}}
						ref={this.topScroll}
					>
						{headerData.map((headerArray, headerIndex) => {
							return (
								<div
									key={headerIndex}
									draggable={false}
									style={{
										WebkitUserSelect: 'none',
										msUserSelect: 'none',
										userSelect: 'none',
										clear: 'right',
										float: 'left',
										width: this.state.calWidth
									}}
								>
									{headerArray}
								</div>
							);
						})}
					</div>
				) : null}

				{/* Displays the rows on the left side of the calendar*/}
				<div
					draggable={false}
					ref={this.sideScroll}
					style={{
						float: 'left',
						backgroundColor: 'white',
						width: this.props.blockWidth * 4.5
					}}
				>
					{this.state.loadedData.rowLabelList}
				</div>

				{/* Calendar display */}
				<div
					onContextMenu={event => {
						//Disables context menu since right clicking is already used for the delete function
						event.preventDefault();
					}}
					draggable={false}
					onMouseUp={this.commitEditData}
					onMouseMove={this.onMouseMove}
					onScroll={() => {
						this.scrollUpdate(
							this.calScroll.current.scrollLeft,
							this.calScroll.current.scrollTop
						);
					}}
					ref={this.calScroll}
					style={{
						WebkitUserSelect: 'none',
						msUserSelect: 'none',
						userSelect: 'none',
						position: 'relative',
						height:
							(this.state.loadedData.rowLabelList.length > 0
								? this.state.loadedData.rowLabelList.length
								: 1) *
								this.props.blockHeight +
							scrollBarSize,
						overflowX: calOverflowX,
						overflowY: 'hidden',
						// marginLeft: this.props.blockWidth * 4.5 + 1,
						backgroundColor: '#f2f3f6'
					}}
				>
					<div
						draggable={false}
						onMouseLeave={this.letGoEditData}
						style={{
							marginTop:
								this.props.orientation === 'vertical'
									? this.props.blockHeight / 2
									: 0,
							WebkitUserSelect: 'none',
							msUserSelect: 'none',
							userSelect: 'none',
							width: this.state.calWidth,
							height:
								(this.state.loadedData.rowLabelList.length > 0
									? this.state.loadedData.rowLabelList.length
									: 1) * this.props.blockHeight
						}}
					>
						{/* The blue line that shows the current date/time */}
						{this.props.showTimeBar &&
						this.props.todayDate.getFullYear() <
							this.props.earliestDate.getFullYear() + 2 &&
						this.props.todayDate.getFullYear() >
							this.props.earliestDate.getFullYear() ? (
							<div
								draggable={false}
								style={{
									WebkitUserSelect: 'none',
									msUserSelect: 'none',
									userSelect: 'none',
									pointerEvents: 'none',
									position: 'absolute',
									height: calHeight,
									left: getDateInt(
										this.state.currentDate,
										this.props.earliestDate.getFullYear(),
										this.props.blockWidth,
										this.props.calendarView,
										true
									),
									width: 2,
									backgroundColor: `#4d7cfe`,
									zIndex: zIndexLayers--
								}}
							></div>
						) : null}

						{/* Builds all the event block components to be displayed */}
						{this.state.loadedData.calFullBlockList.map(
							(currentSource, sourceIndex) => {
								return (
									<div
										draggable={false}
										key={'sourcegroup' + sourceIndex}
										style={{
											WebkitUserSelect: 'none',
											msUserSelect: 'none',
											userSelect: 'none',
											pointerEvents: 'none',
											position: 'absolute',
											height: '100%',
											width: this.state.calWidth,
											zIndex: zIndexLayers--
										}}
									>
										{currentSource.map((currentGroup, groupIndex) => {
											return (
												<div
													draggable={false}
													key={'blockgroup' + groupIndex}
													style={{
														WebkitUserSelect: 'none',
														msUserSelect: 'none',
														userSelect: 'none',
														pointerEvents: 'none',
														top:
															this.props.orientation === 'horizontal'
																? groupIndex * this.props.blockHeight
																: 0,
														width: this.state.calWidth,
														height:
															this.props.orientation === 'vertical'
																? '100%'
																: this.props.blockHeight,
														position: 'absolute',
														clear:
															this.props.orientation === 'horizontal'
																? 'both'
																: 'none'
													}}
												>
													{currentGroup?.map((eventBlock, blockIndex) => {
														return eventBlock !== null &&
															typeof eventBlock.startDate !== 'undefined' &&
															(eventBlock.startDate.getFullYear() <
																this.props.earliestDate.getFullYear() + 2 ||
																!eventBlock.endDate ||
																eventBlock.endDate.getFullYear() >
																	this.props.earliestDate.getFullYear()) ? (
															<EventBlock
																key={blockIndex}
																earliestDate={this.props.earliestDate}
																interactMode={
																	eventBlock.interactMode ||
																	this.props.interactMode
																}
																orientation={this.props.orientation}
																blockWidth={this.props.blockWidth}
																blockHeight={this.props.blockHeight}
																startDate={eventBlock.startDate}
																startTime={eventBlock.startTime}
																endDate={eventBlock.endDate}
																endTime={eventBlock.endTime}
																withTimeOfDay={eventBlock.withTimeOfDay}
																groupIndex={groupIndex}
																blockIndex={blockIndex}
																sourceIndex={sourceIndex}
																event_id={eventBlock.event_id}
																eventStyle={eventBlock.eventStyle}
																eventStyles={eventBlock.eventStyles}
																caption={eventBlock.caption}
																extraEventData={eventBlock.extraEventData}
																startEdit={this.startEdit}
																clickEvent={this.props.clickEvent}
																deleteEvent={this.deleteEvent}
																calendarView={this.props.calendarView}
																dragData={this.state.dragData}
																targetDate={this.props.targetDate}
															/>
														) : null;
													})}
												</div>
											);
										})}
									</div>
								);
							}
						)}

						{/* Displays the background grid for the calendar */}
						<div
							draggable={false}
							onMouseDown={event => {
								//Starting to draw a new event
								if (
									event.button === 0 &&
									this.props.interactMode === 'editable' &&
									this.props.allowNewEvents
								) {
									let topBuffer =
										this.props.orientation === 'vertical'
											? this.props.blockHeight / 2
											: 0;
									let eventStyles =
										typeof this.props.eventStyles === 'object'
											? this.props.eventStyles
											: {
													default: {
														start: {
															opacity: 0.65,
															backgroundColor: '#778ca2'
														},
														middle: {
															opacity: 0.65,
															backgroundColor: '#778ca2'
														},
														end: {
															opacity: 0.65,
															backgroundColor: '#778ca2'
														},
														single: {
															opacity: 0.65,
															backgroundColor: '#778ca2'
														}
													}
											  };

									//Finds the block position of the mouse cursor
									let calBox = this.calScroll.current.getBoundingClientRect();
									let xPos = Math.floor(
										(Math.abs(Math.floor(calBox.left) - event.pageX) +
											this.calScroll.current.scrollLeft) /
											this.props.blockWidth
									);
									let yPos = Math.floor(
										(Math.abs(
											Math.floor(calBox.top + topBuffer) - event.pageY
										) +
											this.calScroll.current.scrollTop) /
											this.props.blockHeight
									);
									let targetDate = null;
									let shouldStart = true;
									let possibleRefIndex = -1;

									//Gets date/time based on type of calendar view
									if (this.props.calendarView === 'month') {
										targetDate = new Date(this.props.earliestDate.valueOf());
										targetDate.setDate(targetDate.getDate() + xPos);
										if (this.props.restrictToReference) {
											shouldStart = false;
											let targetDateVal = targetDate.valueOf();
											if (targetDateVal in this.props.restrictToReference) {
												let possibleRefs =
													this.props.restrictToReference[targetDateVal];
												shouldStart = possibleRefs.some(
													(refEvent, refIndex) => {
														if (
															yPos >= refEvent.startTime &&
															yPos <= refEvent.endTime - 1
														) {
															possibleRefIndex = refIndex;
															return true;
														}
													}
												);
											}
										}
									} else if (this.props.calendarView === 'week') {
										targetDate = [
											'Mon',
											'Tues',
											'Weds',
											'Thurs',
											'Fri',
											'Sat',
											'Sun'
										][xPos];
									}

									//Only start a new event if allowed (used on ref restriction)
									if (shouldStart) {
										let loadedData = { ...this.state.loadedData };
										let newIndex = loadedData.calFullBlockList[0][0].length;
										let oldEventId = this.state.highestId;
										loadedData.calFullBlockList[0][0].push({
											startTime: yPos,
											endTime: yPos + 1,
											startDate: targetDate,
											withTimeOfDay: false,
											groupIndex: 0,
											blockIndex: loadedData.calFullBlockList[0][0].length,
											sourceIndex: 0,
											event_id: oldEventId,
											eventStyle: 'default',
											eventStyles: eventStyles,
											isNew: true,
											refIndex: possibleRefIndex
										});
										this.setState({
											loadedData: loadedData,
											highestId: this.state.highestId + 1
										});
										this.startEdit(0, 0, newIndex, oldEventId, 'B', true);
									}
								}
							}}
							style={{
								WebkitUserSelect: 'none',
								msUserSelect: 'none',
								userSelect: 'none',
								height: calHeight
							}}
						>
							<div
								draggable={false}
								style={{
									WebkitUserSelect: 'none',
									msUserSelect: 'none',
									userSelect: 'none',
									position: 'absolute',
									height: calHeight,
									width: this.state.calWidth,
									zIndex: zIndexLayers--
								}}
							>
								{this.state.loadedData.horizontalList}
							</div>
							<div
								draggable={false}
								style={{
									WebkitUserSelect: 'none',
									msUserSelect: 'none',
									userSelect: 'none',
									position: 'absolute',
									height: calHeight,
									width: this.state.calWidth,
									zIndex: zIndexLayers--
								}}
							>
								{this.state.loadedData.verticalList}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default scrollbarSizeHook(BarCalendar);
